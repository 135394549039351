import styled from 'styled-components';
import { PHONE_INPUT_PADDING_RIGHT } from '../../constants';

export const Wrapper = styled.div`
  height: 112px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 36px 0 ${PHONE_INPUT_PADDING_RIGHT}px;
  .client-name {
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--Text-400-Paragraph, #4d4950);
    font-weight: 450;
    line-height: 16px;
  }
`;

export const AutoCompleteFieldWrapper = styled.div`
  margin-left: 8px;
  width: 100%;
  .ant-select-selector {
    padding: 0 !important;
    .ant-select-selection-search {
      left: 0;
      width: 100%;
      &-input {
        font-weight: 450;
        color: var(--Text-600-Headers, #1a181b);
      }
      + .ant-select-selection-placeholder {
        color: var(--Text-300-Component-2, #66616b);
      }
    }
  }

  /* dropdown */
  .ant-select-dropdown {
    top: 90px !important;
    left: 0px !important;
    padding: 6px 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-color: var(--Stroke-100-Default, #e5e4e7);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    .ant-select-item {
      padding: 6px 14px;
    }
  }
`;

export const NumberAutoCompleteOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-avatar {
    background: none;
    border: none;
    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }
  > span {
    color: var(--Text-500-Component, #333036);
    > span,
    > mark {
      font-size: 14px;
      font-weight: 420;
      line-height: 20px;
    }
    &.number {
      > span,
      > mark {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
`;

export const BannerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 4px 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  background: var(--Error-400, #e62e2e);
  color: white;
  > svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
  > span {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    display: inline-block;
  }
  &.info {
    background: var(--Link-400, #006ee6);
  }
`;
