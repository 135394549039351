import styled from 'styled-components';

export const FormContainer = styled.form`
  .ant-input.name-input {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none;
    border: none;
    height: 28px !important;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #3d3358;
    font-family: Manrope;
    &::placeholder {
      font-size: 20px;
      line-height: 28px;
      color: #857f96;
    }
  }
`;
