import React, { createContext, useMemo, useState } from 'react';

import { Icon } from 'components/atoms';
import {
  CheckCircleFill,
  InformationCircle,
  PhoneDownFill,
  PhoneFill,
} from 'components/atoms/icon';
import { Banner, BannerType } from '../types';
import { useCallWidgetHandler } from '../hooks/useCallWidgetHandler';
import { KYC_UNVERIFIED_MESSAGE } from '../constants';

export const CallWidgetContext = createContext<any>({});

export const CallWidgetProvider = ({ children }: any) => {
  const [banner, setBanner] = useState<Banner>({});
  const [kycBanner, setKycBanner] = useState<Banner>({});
  const {
    channelInfo,
    channels: allChannels,
    loadingChannels,
    channelsQueryCalled,
  } = useCallWidgetHandler();

  const voipChannels = allChannels?.filter(
    (channel: any) => channel?.phoneNumberType !== 'NonVoip',
  );

  const [selectedChannel, setSelectedChannel] = useState<any>(null);

  const handleChannelSelect = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    setSelectedChannel(voipChannels?.find((channel: any) => channel?.id === e));
  };

  const showAlertBanner = (text: string, type?: BannerType) => {
    setBanner?.({
      title: text,
      closable: false,
      type: type || BannerType.error,
      icon: <InformationCircle />,
      showIcon: true,
    });
  };

  const showBannerLikeToast = (text: string, type: BannerType, callback?: () => void) => {
    const mapIcon: Record<string, React.ReactNode> = {
      info: <CheckCircleFill className='w-4.5 h-4.5' />,
      error: <InformationCircle />,
    };

    setBanner?.({
      title: text,
      closable: false,
      type: type || BannerType.info,
      icon: mapIcon?.[type],
      showIcon: true,
      alignContent: 'center',
    });

    if (callback) setTimeout(() => callback?.(), 3000);
  };

  const showIncomingCallBanner = (clientName?: string, callback?: () => void) => {
    setBanner?.({
      title: `Incoming Call ${clientName || ''}`,
      closable: false,
      type: BannerType.success,
      icon: <PhoneFill className='w-4.5 h-4.5' />,
      showIcon: true,
      alignContent: 'center',
      onClick: callback,
    });
  };

  const showCallEndedBanner = (endTime: string) => {
    setBanner?.({
      title: `Call Ended ${endTime || ''}`,
      closable: false,
      type: BannerType.error,
      icon: <PhoneDownFill className='w-4.5 h-4.5' />,
      showIcon: true,
      alignContent: 'center',
    });
  };

  const showOngoingCallBanner = (callDurationTime?: string, callback?: () => void) => {
    setBanner?.({
      title: `Ongoing Call - ${callDurationTime}`,
      closable: false,
      type: BannerType.success,
      icon: <PhoneFill className='w-4.5 h-4.5' />,
      showIcon: true,
      alignContent: 'center',
      onClick: callback,
    });
  };

  const showKycUnverifiedBanner = () => {
    setKycBanner?.({
      title: KYC_UNVERIFIED_MESSAGE,
      closable: true,
      type: BannerType.warning,
      icon: <Icon name='exclamation-triangle' />,
      showIcon: true,
    });
  };

  const value = useMemo(() => {
    return {
      channelInfo,
      channels: voipChannels,
      loadingChannels,
      channelsQueryCalled,
      banner,
      kycBanner,
      selectedChannel,
      setSelectedChannel,
      setBanner,
      showAlertBanner,
      showBannerLikeToast,
      showIncomingCallBanner,
      showCallEndedBanner,
      showOngoingCallBanner,
      handleChannelSelect,
      showKycUnverifiedBanner,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channelInfo,
    voipChannels,
    loadingChannels,
    channelsQueryCalled,
    banner,
    kycBanner,
    selectedChannel,
  ]);
  return <CallWidgetContext.Provider value={value}>{children}</CallWidgetContext.Provider>;
};
