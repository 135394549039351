export default function NotificationBell() {
  return (
    <svg width='75' height='61' viewBox='0 0 75 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4504_81578)'>
        <path
          d='M36.9548 60.7754C41.6383 60.7754 45.435 56.9821 45.435 52.3029C45.435 47.6236 41.6383 43.8303 36.9548 43.8303C32.2713 43.8303 28.4746 47.6236 28.4746 52.3029C28.4746 56.9821 32.2713 60.7754 36.9548 60.7754Z'
          fill='url(#paint0_linear_4504_81578)'
        />
        <path
          d='M35.4563 58.7097C38.9966 58.7097 41.8665 55.8423 41.8665 52.3053C41.8665 48.7683 38.9966 45.901 35.4563 45.901C31.9161 45.901 29.0462 48.7683 29.0462 52.3053C29.0462 55.8423 31.9161 58.7097 35.4563 58.7097Z'
          fill='#FFF492'
          fillOpacity='0.55'
        />
        <path
          d='M34.8938 56.9121C35.3984 56.2201 35.0458 55.1048 34.1064 54.4211C33.1669 53.7373 31.9963 53.7441 31.4917 54.4362C30.9871 55.1283 31.3396 56.2436 32.2791 56.9273C33.2185 57.611 34.3892 57.6042 34.8938 56.9121Z'
          fill='#FEFFA2'
          fillOpacity='0.66'
        />
        <path
          d='M52.0457 46.7945H23.9894V53.2941H52.0457V46.7945Z'
          fill='#911A00'
          fillOpacity='0.12'
        />
        <path
          d='M36.9107 0.00323486C39.6052 0.00323486 41.7933 2.34273 41.7933 5.22451C41.7933 8.10629 39.6052 10.4458 36.9107 10.4458C34.2157 10.4458 32.028 8.10629 32.028 5.22451C32.028 2.34273 34.2157 0.00323486 36.9107 0.00323486ZM36.9107 2.45632C38.3388 2.45632 39.4988 3.69671 39.4988 5.22417C39.4988 6.75163 38.3388 7.99202 36.9107 7.99202C35.4818 7.99202 34.3222 6.75163 34.3222 5.22417C34.3222 3.69671 35.4818 2.45632 36.9107 2.45632Z'
          fill='#F9E44F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37.2251 0.973572C39.6431 0.973572 41.6068 2.96782 41.6068 5.42402C41.6068 7.88022 39.6431 9.87447 37.2251 9.87447C34.8065 9.87447 32.8435 7.88022 32.8435 5.42402C32.8435 2.96782 34.8068 0.973572 37.2251 0.973572ZM37.2251 3.06478C38.5069 3.06478 39.5478 4.12199 39.5478 5.42437C39.5478 6.72674 38.5069 7.78395 37.2251 7.78395C35.9431 7.78395 34.9021 6.72674 34.9021 5.42437C34.9021 4.12199 35.9431 3.06478 37.2251 3.06478Z'
          fill='#F4CB50'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37.1678 2.13364C38.8378 2.13364 40.1939 3.46406 40.1939 5.10268C40.1939 6.7413 38.8378 8.07172 37.1678 8.07172C35.4974 8.07172 34.1416 6.7413 34.1416 5.10268C34.1416 3.46406 35.4974 2.13364 37.1678 2.13364ZM37.1678 3.52847C38.0529 3.52847 38.7717 4.23385 38.7717 5.10233C38.7717 5.97116 38.0529 6.6762 37.1678 6.6762C36.2823 6.6762 35.5635 5.97082 35.5635 5.10233C35.5635 4.23385 36.2823 3.52847 37.1678 3.52847Z'
          fill='#E8AE4F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.4546 4.85095C35.116 4.39489 38.773 4.37342 42.3843 4.8506L42.3387 6.14363L30.4624 6.528L31.4546 4.85095Z'
          fill='#BD761A'
          fillOpacity='0.37'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.912 2.94573C32.5548 3.81392 33.4753 3.83233 33.8592 3.39421C34.4058 2.77062 35.2684 2.01564 36.2576 1.70109C37.4904 1.30899 37.3781 -0.0235583 35.4705 0.68655C34.4066 1.08279 33.2588 2.10239 32.912 2.94573Z'
          fill='#FFFBA0'
          fillOpacity='0.62'
        />
        <path
          d='M13.3013 52.3058C11.0071 52.3058 9.14468 50.4447 9.14468 48.1529V47.0274C9.14468 45.6757 9.82467 44.5644 10.7948 43.7151C12.7293 42.0218 17.2187 37.9727 18.4423 34.1317C20.0462 29.0991 17.7273 19.4645 19.8065 14.0899C22.3605 7.48773 26.8099 5.02889 36.8742 4.92018V4.91934H36.9505V4.92018C47.0145 5.02889 51.4638 7.48815 54.0179 14.0899C56.097 19.4645 53.7786 29.0991 55.382 34.1317C56.6057 37.9727 61.095 42.0214 63.03 43.7151C63.9997 44.564 64.6801 45.6757 64.6801 47.0274V48.1529C64.6801 50.4447 62.8173 52.3058 60.5234 52.3058H13.3013Z'
          fill='url(#paint1_linear_4504_81578)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.7896 52.3054C10.778 52.3054 9.14447 50.4443 9.14447 48.1525V47.027C9.14447 45.6753 9.74066 44.564 10.5916 43.7147C12.288 42.0214 16.2246 37.9723 17.2981 34.1313C18.7048 29.0987 16.6711 19.4641 18.4947 14.0895C20.7343 7.48733 24.6363 5.0285 33.462 4.91978V4.91895H33.5287V4.91978C42.3544 5.0285 46.7108 7.45359 48.9504 14.0553C50.774 19.43 48.5798 29.0558 49.9861 34.0884C51.0592 37.9294 54.7022 42.021 56.3987 43.7143C57.2492 44.5632 57.8458 45.6749 57.8458 47.0266V48.1521C57.8458 50.4439 56.2123 52.305 54.2007 52.305H12.7896V52.3054Z'
          fill='url(#paint2_linear_4504_81578)'
        />
        <path
          d='M15.4484 42.2901C17.7072 40.0524 19.9184 37.1545 20.5232 34.1313C21.5297 29.0987 20.0742 19.4641 21.3795 14.0895C22.9826 7.48729 29.4542 4.65149 32.0917 4.91974V4.9189L32.116 4.91932L32.1399 4.9189V4.91974C38.4567 5.02846 41.2491 7.48771 42.8522 14.0895C44.1572 19.4641 42.7019 29.0987 43.7084 34.1313C44.3535 37.3557 46.4429 40.7276 47.8155 42.7287L48.5512 43.6934C49.1529 44.5398 49.5441 45.6849 49.5441 47.0266V48.1521C49.5441 50.4439 48.3748 52.305 46.935 52.305H15.8765C14.4367 52.305 13.2678 50.4439 13.2678 48.1521V47.0266C13.2678 45.6849 13.688 44.5802 14.2898 43.7338C14.6349 43.2415 15.0185 42.7599 15.4484 42.2901Z'
          fill='url(#paint3_linear_4504_81578)'
        />
        <mask id='mask0_4504_81578' maskUnits='userSpaceOnUse' x='18' y='4' width='32' height='49'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.8848 42.2883C22.8558 40.0507 24.2984 37.554 24.8259 34.5315C25.7042 29.4989 24.621 19.2853 25.76 13.9107C27.1592 7.30855 31.5959 4.64061 37.6954 4.91802V4.91719L37.7164 4.91761L37.7373 4.91719V4.91802C43.2499 5.02674 42.3052 7.48599 43.7041 14.0877C44.843 19.4624 43.5729 29.0969 44.4513 34.1296C45.0142 37.354 46.8376 40.7259 48.0356 42.727L48.6777 43.6917C49.2028 44.5381 49.5442 45.6832 49.5442 47.0248V48.1503C49.5442 50.4421 48.5241 52.3032 47.2676 52.3032H21.259C20.0025 52.3032 18.9824 50.4421 18.9824 48.1503V47.0248C18.9824 45.6832 19.3493 44.5785 19.8744 43.7321C20.1746 43.2397 20.5094 42.7582 20.8848 42.2883Z'
            fill='url(#paint4_linear_4504_81578)'
          />
        </mask>
        <g mask='url(#mask0_4504_81578)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.8847 42.2891C22.8557 40.0515 24.2982 37.5547 24.8257 34.5323C25.7041 29.4997 24.6209 19.2861 25.7598 13.9115C27.1591 7.30934 31.5958 4.6414 37.6953 4.91882V4.91798L37.7162 4.9184L37.7371 4.91798V4.91882C43.2497 5.02753 42.305 7.48679 43.7039 14.0885C44.8429 19.4632 43.5727 29.0977 44.4511 34.1304C45.0141 37.3548 46.8375 40.7267 48.0355 42.7278L48.6775 43.6925C49.2026 44.5389 49.5441 45.684 49.5441 47.0256V48.1511C49.5441 50.4429 48.5239 52.304 47.2674 52.304H21.2589C20.0024 52.304 18.9822 50.4429 18.9822 48.1511V47.0256C18.9822 45.684 19.3491 44.5793 19.8742 43.7329C20.1745 43.2405 20.5093 42.759 20.8847 42.2891Z'
            fill='url(#paint5_linear_4504_81578)'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M34.6534 57.986C21.5922 59.7563 10.1616 61.2001 9.14516 60.9785C6.6249 60.4295 4.67706 59.3723 3.24078 57.986H34.6534ZM70.2506 43.0518H1.79408V56.2349C-3.75467 47.7125 4.23263 31.2008 16.4875 34.4273C28.2175 46.5133 61.477 39.9269 51.258 8.29565C65.9426 11.7371 68.7209 27.3645 70.2506 43.0518Z'
          fill='#B05000'
          fillOpacity='0.05'
        />
        <path
          d='M74.9981 50.5498H1.79376V56.2331H74.9981V50.5498Z'
          fill='#B05000'
          fillOpacity='0.12'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M52.0427 46.0847C52.0427 45.0594 51.2093 44.2271 50.1827 44.2271H12.5788C11.5525 44.2271 10.7191 45.0594 10.7191 46.0847V46.277C10.7191 47.3024 11.5525 48.1346 12.5788 48.1346H50.1827C51.2093 48.1346 52.0427 47.3024 52.0427 46.277V46.0847Z'
          fill='#FFFA9B'
          fillOpacity='0.59'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.9455 6.14376C37.5379 5.80062 35.4507 14.1555 32.5211 19.1186C29.4161 24.3804 20.1862 27.6856 19.956 22.0717C19.7329 16.6281 20.6089 13.5021 23.2299 10.344C25.4285 7.69482 28.6631 6.34576 31.9455 6.14376Z'
          fill='#F9FF9B'
          fillOpacity='0.43'
        />
        <path
          d='M53.3352 19.4358C58.4127 19.4358 62.5287 15.3234 62.5287 10.2506C62.5287 5.17772 58.4127 1.06537 53.3352 1.06537C48.2578 1.06537 44.1417 5.17772 44.1417 10.2506C44.1417 15.3234 48.2578 19.4358 53.3352 19.4358Z'
          fill='url(#paint6_linear_4504_81578)'
        />
        <path
          d='M52.0572 16.943C56.6921 16.943 60.4494 13.1499 60.4494 8.47085C60.4494 3.79182 56.6921 -0.00128174 52.0572 -0.00128174C47.4223 -0.00128174 43.665 3.79182 43.665 8.47085C43.665 13.1499 47.4223 16.943 52.0572 16.943Z'
          fill='#FFD1B3'
          fillOpacity='0.24'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M47.3019 10.7113C46.988 6.41721 50.9764 3.6028 54.4853 4.29461C54.8192 4.36048 55.1446 4.14291 55.2105 3.8089C55.2764 3.47489 55.0587 3.15023 54.7244 3.08437C50.5004 2.25147 45.6929 5.63192 46.0702 10.801C46.0953 11.1405 46.3909 11.3959 46.7311 11.3712C47.0714 11.3466 47.3266 11.0508 47.3019 10.7113Z'
          fill='#FFC794'
          fillOpacity='0.5'
        />
        <path
          d='M53.0305 13.8212C52.9518 13.8212 52.8952 13.8008 52.8391 13.7503C52.783 13.6997 52.7604 13.6491 52.7604 13.5782V8.35133L51.9719 8.84767C51.9268 8.88804 51.8706 8.89823 51.803 8.89823C51.7128 8.89823 51.6341 8.86764 51.5891 8.7971L51.1046 8.2298C51.0595 8.18943 51.0485 8.13843 51.0485 8.08786C51.0485 8.0067 51.0821 7.94593 51.1722 7.88516L52.783 6.80113C52.8616 6.75056 52.9633 6.73016 53.0645 6.73016H54.0785C54.1572 6.73016 54.2138 6.75056 54.2699 6.80113C54.3261 6.8517 54.3486 6.90227 54.3486 6.97323V13.5773C54.3486 13.6483 54.3261 13.6988 54.2699 13.7494C54.2138 13.8 54.1572 13.8204 54.0785 13.8204H53.0305V13.8212Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4504_81578'
          x1='29.3579'
          y1='52.303'
          x2='45.548'
          y2='52.303'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F9E144' />
          <stop offset='1' stopColor='#EAB75B' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4504_81578'
          x1='2.00736'
          y1='28.6128'
          x2='71.9228'
          y2='28.6128'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4C22D' />
          <stop offset='0.68' stopColor='#EBB44B' />
          <stop offset='1' stopColor='#F0BF2B' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4504_81578'
          x1='33.4777'
          y1='6.7165'
          x2='33.4777'
          y2='57.5656'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FAD74E' />
          <stop offset='0.31' stopColor='#FAD547' />
          <stop offset='0.72' stopColor='#F9D237' />
          <stop offset='1' stopColor='#FAD74E' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4504_81578'
          x1='13.3165'
          y1='28.6041'
          x2='42.6348'
          y2='28.6041'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFFCAD' />
          <stop offset='0.71' stopColor='#FAE46B' />
          <stop offset='1' stopColor='#FFFCAD' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_4504_81578'
          x1='34.2606'
          y1='10.5816'
          x2='34.2606'
          y2='42.955'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B5B5B5' />
          <stop offset='0.73' stopColor='#B5B5B5' />
          <stop offset='1' stopColor='#B5B5B5' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_4504_81578'
          x1='34.2604'
          y1='10.5824'
          x2='34.2604'
          y2='42.9558'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F9E95D' />
          <stop offset='0.73' stopColor='#F8D91D' />
          <stop offset='1' stopColor='#FBEE69' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_4504_81578'
          x1='45.3059'
          y1='10.2504'
          x2='60.468'
          y2='10.2504'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DA3942' />
          <stop offset='1' stopColor='#FF5540' />
        </linearGradient>
        <clipPath id='clip0_4504_81578'>
          <rect width='75' height='61' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
