import React, { ReactNode, useEffect } from 'react';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { Switch, ToastMessage, Tooltip } from 'components/atoms';
import { RiInformationFill } from 'react-icons/ri';
import { DisabledTooltip } from '../disabled-tooltip';

interface IOptionsCard {
  id?: string;
  icon?: ReactNode;
  title: string | React.ReactElement;
  tooltipTitle?: string;
  tooltipPlacement?: TooltipPlacement;
  description: string | React.ReactElement;
  messageOnEnable?: string | React.ReactElement;
  messageOnDisable?: string | React.ReactElement;
  initialValue?: boolean;
  onChange?: SwitchChangeEventHandler;
  size?: 'small' | '';
  hasAdminAccess?: boolean;
  disableTooltipMessage?: string;
  disabled?: boolean;
}

export default function OptionsCard({
  id,
  icon,
  title,
  tooltipTitle,
  tooltipPlacement,
  description,
  messageOnEnable,
  messageOnDisable,
  initialValue,
  onChange,
  size,
  hasAdminAccess,
  disableTooltipMessage,
  disabled,
}: IOptionsCard) {
  const [checked, setChecked] = React.useState<boolean>();

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const handleChoice = (isEnabled: boolean) => {
    ToastMessage({
      content: isEnabled ? messageOnEnable : messageOnDisable,
      type: isEnabled ? 'success' : 'danger',
    });
  };
  const handleChange = (value: boolean, event: any) => {
    setChecked(value);
    if (onChange) {
      onChange(value, event);
      /* handleChoice(value); */ // feature not available
    } else {
      handleChoice(value);
    }
  };
  return (
    <>
      {hasAdminAccess ||
      ![
        'auto-record-calls',
        'auto-voicemail-transcription',
        'international-calls-messages',
        'email-notifications',
      ].includes(id as any) ? (
        <div className='flex items-center'>
          {icon && (
            <div className='max-md:hidden h-12.5 w-12.5  p-3 bg-primary-50 flex-shrink-0 text-2xl border border-solid border-primary-100 rounded-10'>
              {icon}
            </div>
          )}
          <div className='lg:ml-3.5'>
            <h6
              className='flex  gap-x-1.5 text-15 text-gray-600 font-semibold leading-normal mb-1'
              data-cy='tooltip'
            >
              {title}
              {tooltipTitle && (
                <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
                  <RiInformationFill
                    size={16}
                    color='#1A63F4'
                    className='relative top-0.75 cursor-pointer'
                  />
                </Tooltip>
              )}
            </h6>
            <p className='text-sm text-gray font-normal leading-normal'>{description}</p>
          </div>
          <div className='ml-auto' data-cy='toggle'>
            <Switch onChange={handleChange} checked={checked ?? initialValue} disabled={disabled} />
          </div>
        </div>
      ) : (
        <DisabledTooltip wrapperClass='disabled-general-settings'>
          <div className='flex items-center'>
            <div className='h-12.5 w-12.5 p-3 bg-primary-50 flex-shrink-0 text-2xl border border-solid border-primary-100 rounded-10'>
              {icon}
            </div>
            <div className='ml-3.5'>
              <h6 className='text-15 text-gray-600 font-semibold leading-normal'>{title}</h6>
              <p className='text-sm text-gray font-normal leading-normal'>{description}</p>
            </div>
            <div className='ml-auto' data-cy='toggle'>
              <Switch disabled checked={checked ?? initialValue} />
            </div>
          </div>
        </DisabledTooltip>
      )}
    </>
  );
}
