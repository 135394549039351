import styled from 'styled-components';

export const SearchFieldWrapper = styled.div`
  .search-tag {
    height: 36px;
    background: #ffffff;
    border: 1px solid #d7cce4;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #6e6681;
    padding: 8px 16px;
    .ant-input,
    .ant-input::placeholder {
      font-family: Heebo;
      font-weight: normal;
    }
    .ant-input::placeholder {
      color: #9e99ab;
    }
  }
`;

export const ListContainer = styled.div`
  max-height: 136px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .avatar {
    border-radius: 6px;
  }
`;

export const EmptyTagsContainer = styled.div`
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
