import { Controller } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { BiMinus } from 'react-icons/bi';

import { OtpInputProps } from './types';

const OtpInputField: React.FC<OtpInputProps> = props => {
  const {
    control,
    name,
    onChange,
    separator,
    numInputs = 6,
    containerStyle,
    inputStyle,
    value,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <OtpInput
            {...field}
            containerStyle={containerStyle}
            inputStyle={inputStyle}
            value={field.value ?? value}
            onChange={onChange}
            numInputs={numInputs}
            renderSeparator={
              separator ?? (
                <span>
                  <BiMinus size={20} className='ml-2 -mr-2 otp-separator' color='#6E6681' />
                </span>
              )
            }
            renderInput={inputProps => <input {...inputProps} />}
          />
        );
      }}
    />
  );
};

export default OtpInputField;
