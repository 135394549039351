import { css } from 'styled-components';

export const heights = css`
  .h {
    &-auto {
      height: auto;
    }
    &-10 {
      height: 10px;
    }
    &-20 {
      height: 20px;
    }
    &-40 {
      height: 40px;
    }
    &-48 {
      height: 48px;
    }
    &-60 {
      height: 60px;
    }
    &-200 {
      height: 200px;
    }
    &-100 {
      height: 100%;
    }
    &-100vh {
      height: 100vh;
    }
  }
  .min-h {
    &-48 {
      min-height: 48px;
    }
  }
`;
