import { css } from 'styled-components';

export const ButtonCSS = css`
  border: none;
  span,
  svg {
    color: #ffffff;
  }
`;

export const SuccessButtonCSS = css`
  &.ant-btn {
    &,
    &:hover,
    &:focus {
      ${ButtonCSS}
      background: #4c9610;
    }
  }
`;

export const InfoButtonCSS = css`
  &.ant-btn {
    &,
    &:hover,
    &:focus {
      ${ButtonCSS}
      background: #1a63f4;
    }
  }
`;

export const TertiaryButtonCSS = css`
  &.ant-btn {
    ${ButtonCSS}
    background: #f5f2f8;
    span,
    svg {
      color: #390179;
    }
    &:hover,
    &:focus {
      background: #ece6f2;
    }
  }
`;

export const WarningButtonCSS = css`
  &.ant-btn {
    ${ButtonCSS}
    &,
    &:hover,&:focus {
      background: ${({ theme }) => theme.colors.warning.default};
    }
  }
`;

export const GrayButtonCSS = css`
  &.ant-btn {
    ${ButtonCSS}
    &,
    &:hover,&:focus {
      background: #6e6681;
    }
  }
`;

export const LightGrayButtonCSS = css`
  &.ant-btn {
    ${ButtonCSS}
    &,
    &:hover,&:focus {
      background: #f3f2f4;
      span {
        color: #6e6681;
      }
    }
  }
`;
export const OutlineButtonCSS = css`
  &.ant-btn {
    ${ButtonCSS}
    &[variant='outline'] {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.primary.default};
      &,
      &:hover,
      &:focus {
        background-color: transparent;
        span {
          color: #6e6681;
        }
      }
      span,
      svg {
        color: ${({ theme }) => theme.colors.primary.default};
      }
      &[disabled],
      &[disabled]:hover {
        background: none !important;
        border-color: #cfccd5;
      }
    }
  }
`;
