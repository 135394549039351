import { gql } from '@apollo/client';
import { USER_PROFILE_FRAGMENT } from './fragments';

export const USER_PROFILE_SUBSCRIPTION = gql`
  subscription userProfile($user: ShortId!) {
    userProfile(user: $user) {
      event
      message {
        ...UserProfile
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
`;
