import React from 'react';
import { Radio as AntRadio } from 'antd';
import { Controller } from 'react-hook-form';
import { RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio/interface';

import { Error } from 'components/atoms';
import { FlexColumn } from './styled';

import { RadioProps } from './types';

export type RadioGroupProps = AntRadioGroupProps;

export const Radio: React.FC<RadioProps> = props => {
  const { id, name, children, control, ...rest } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <AntRadio
              {...field}
              {...(rest as any)}
              id={id}
              checked={field.value}
              value={field.value}
              className='radio'
            >
              {children}
            </AntRadio>
          );
        }}
      />
    </>
  );
};

Radio.displayName = 'Radio';
export default Radio;

// eslint-disable-next-line react/display-name
export const RadioGroup: React.FC<RadioProps> = props => {
  const { id, name, control, children, errors, ...rest } = props;
  return (
    <FlexColumn>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <AntRadio.Group {...field} id={id} {...(rest as any)}>
              {children}
            </AntRadio.Group>
          );
        }}
      />
      {errors?.[name] && <Error>{errors[name].message}</Error>}
    </FlexColumn>
  );
};
