import { useTranslation } from 'react-i18next';

import ActionModal from 'components/organisms/modals/actions/ActionsModal';
import { MessageLimited } from 'components/atoms/vectors';

interface SmsLimitModalProps {
  visible: boolean;
  onOk: () => void;
  toggle: () => void;
}

const SmsLimitModal = ({ visible, toggle, onOk }: SmsLimitModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      {visible && (
        <ActionModal
          type='primary'
          onOk={onOk}
          isModalOpen={visible}
          onModalCancel={toggle}
          onCancel={toggle}
          width={349}
          icon={<MessageLimited />}
          title={t('messageLimited', 'Message Limited')}
          info={t(
            'modal.info.accountLimitedVerifyIdentity',
            'Your account is limited, please verify your identity.',
          )}
          loading={false}
          btnText={t('modal.btnText.contactSupport', 'Contact Support')}
          className='code-verification-modal'
          cancelBtnText={t('modal.cancelBtnText.cancel', 'Cancel')}
        />
      )}
    </>
  );
};

export default SmsLimitModal;
