import styled from 'styled-components';

export const Title = styled.div`
  margin-bottom: 8px;
  .edit-field-wrapper {
    .ant-input,
    p {
      font-family: 'Manrope', sans-serif;
      font-weight: 600;
      font-size: 18px !important;
      line-height: 28px !important;
      height: 28px;
      color: #564d6d;
      padding: 0 10px !important;
      background: transparent;
      border: none;
      border-radius: 5px;
      text-align: center;
      width: 100%;
      max-width: 208px;
      &:focus {
        box-shadow: 0 0 0 1px #d7cce4;
      }
      &:hover,
      &:focus {
        background: #f5f2f8;
        cursor: text;
      }
    }
    p {
      min-width: 208px;
    }
  }
`;
