import { ReactElement, useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import iEmpty from 'lodash.isempty';
import { useTwilioContext } from 'lib/twilio';

import { TextAreaInput } from 'components/molecules/fields';
import { NOTES_MAX_LENGTH } from 'schema-validation';
import { schema } from './schema';
import * as S from './Styles';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

interface INoteAddProps {
  handleClose: () => void;
}

export function CampaignNoteAdd({ handleClose }: INoteAddProps): ReactElement {
  const { t } = useTranslation();
  const {
    state: { connection },
  } = useTwilioContext();

  const handleCloseBtnClick = () => {
    handleClose();
  };

  const {
    campaignCallNote,
    handleCampaignNoteSave,
    loadingCampaignSaveNote,
  } = useCallWidgetContext();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<{ callNote: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      callNote: campaignCallNote?.note || '',
    },
  });

  const formSubmit = async (val: any) => {
    const { callNote } = val;
    const campaignId = connection?.customParameters?.get('campaignId');
    const campaignConversationId = connection?.customParameters?.get('campaignConversationId');
    if (!iEmpty(errors)) return;
    let payload = {};
    const { id } = campaignCallNote || {};
    if (id) {
      payload = {
        callNote,
        id,
      };
      handleCampaignNoteSave?.(payload, 'edit');
      return;
    }
    payload = {
      callNote,
      campaignId,
      campaignConversationId,
    };
    handleCampaignNoteSave?.(payload, 'create');
  };

  useEffect(() => {
    if (campaignCallNote) {
      setValue('callNote', campaignCallNote?.note);
    }
  }, [campaignCallNote, setValue]);

  return (
    <S.FormContainer onSubmit={handleSubmit(formSubmit)}>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-center '>
          Notes
        </p>
        <button
          type='submit'
          className='text-success font-manrope text-xs leading-3 font-semibold'
          disabled={loadingCampaignSaveNote}
        >
          Save
        </button>
      </div>
      <div className='pt-3 pb-2.5 px-4 text-left'>
        <div className=''>
          <TextAreaInput
            name='callNote'
            id='noteBox'
            control={control}
            placeholder={t('saveNotes', 'Save notes here..')}
            maxLength={NOTES_MAX_LENGTH}
          />
        </div>
      </div>
    </S.FormContainer>
  );
}
