import * as yup from 'yup';
import { inputs, MIN_LENGTH } from './common';

export const MAX_LENGTH = 46;
export const MAX_ADDRESS_LENGTH = 100;
export const NOTES_MAX_LENGTH = 200;

export const contactInputs = {
  name: yup.string().trim().max(MAX_LENGTH, `Shouldn't exceed ${MAX_LENGTH} characters`),
  number: yup.string().required('Required'),
  email: inputs.email,
  company: yup.string().trim().max(MAX_LENGTH, `Shouldn't exceed ${MAX_LENGTH} characters`),
  address: yup
    .string()
    .trim()
    .max(MAX_ADDRESS_LENGTH, `Shouldn't exceed ${MAX_ADDRESS_LENGTH} characters`),
  note: yup
    .string()
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(NOTES_MAX_LENGTH, `Shouldn't exceed ${NOTES_MAX_LENGTH} characters`),
};
