import { Avatar } from 'antd';
import { Button } from 'components/atoms';
import styled from 'styled-components';

export const ProfilePicture = styled(Avatar)<any>`
  cursor: pointer;
  background: transparent;
`;

export const ProfileContainer = styled.div`
  width: 80px;
  height: 80px;
  span.ant-upload {
    position: relative;
    background: #f5f2f8;
    border: 1px dashed ${({ theme }) => theme.colors.text.t4};
    border-radius: 20px;
    width: 80px;
    height: 80px;
    display: block;
    > div,
    > span {
      position: absolute;
      top: -1px;
      left: -1px;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
  }
  &:hover {
    .edit-pic {
      display: block;
    }
  }
  &.user-pic-upload {
    span.ant-upload {
      border: 1px solid #e7e6eb;
      background: #e7e6eb;
      border-radius: 28px;
    }
  }
  /* size variant */
  &.small {
    width: 64px;
    height: 64px;
    span.ant-upload {
      width: 64px;
      height: 64px;
    }
    &.user-pic-upload {
      span.ant-upload {
        border-radius: 200px;
      }
    }
  }
`;

export const PlusIcon = styled.div`
  svg {
    position: absolute;
    border-radius: 9px;
    bottom: 0px;
    right: 0px;
    font-size: 14px;
    background: ${({ theme }) => theme.colors.info.default};
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white;
    width: 20px;
    height: 20px;
  }
  .small & svg {
    bottom: 2px;
    right: 4px;
    box-shadow: 0 0 0 2.5px white;
  }
`;

export const NoImage = styled.div`
  padding: 19px 0px;
  text-align: center;
  width: 100%;
  height: 100%;
  svg {
    margin: 0 auto;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.text.t4};
  }
  h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    color: #3d3358;
    margin-top: 4px;
  }
  &.edit-pic {
    position: absolute;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    display: none;
    svg {
      color: #b7b3c1;
    }
    h6 {
      color: #ffffff;
    }
  }
  .small & {
    padding: 10px 0;
  }
`;

export const RemovePicButton = styled(Button)`
  &.ant-btn {
    font-family: Heebo;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.info.default};
    padding: 0;
    margin: 0 auto;
    height: 24px;
    &:hover {
      background: none;
    }
  }
`;
