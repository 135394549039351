export default function PhoneDownFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <g clipPath='url(#clip0_1393_2826)'>
        <path
          d='M1.53022 11.5655C1.30832 11.2093 1.20735 10.791 1.24233 10.3728C1.2379 9.8629 1.3357 9.35725 1.52995 8.88574C1.72421 8.41423 2.01097 7.98643 2.37332 7.6276L2.55325 7.44766C3.34021 6.73768 4.25897 6.18921 5.25736 5.83342C5.49898 5.74602 5.73032 5.65862 6.00793 5.55581C7.24996 5.21085 8.53345 5.03788 9.82249 5.04172C11.442 4.98952 13.0573 5.23522 14.5881 5.76659C15.4257 6.05346 16.2133 6.46977 16.9221 7.00041C17.6912 7.52305 18.2857 8.26446 18.6288 9.12873C18.7415 9.56506 18.7934 10.0149 18.7831 10.4654C18.7938 10.6806 18.7606 10.8958 18.6854 11.0977C18.6082 11.3079 18.4893 11.5002 18.3358 11.6632C18.1486 11.8146 17.933 11.9272 17.7017 11.9943C17.4704 12.0614 17.2281 12.0816 16.9889 12.0539C16.4902 12.0282 15.9607 12.0539 15.4415 12.0385L14.7989 12.0436C14.4395 12.0676 14.085 11.9494 13.8118 11.7146C13.5777 11.4819 13.4384 11.1704 13.4211 10.8407C13.398 10.6203 13.386 10.399 13.3851 10.1775L13.38 9.95642C13.3848 9.83465 13.3619 9.71338 13.3131 9.6017C13.262 9.49761 13.1882 9.40627 13.0972 9.33437C12.7909 9.1933 12.4578 9.11966 12.1205 9.11845C11.5035 9.02592 10.9432 8.96936 10.2954 8.88711C9.24422 8.81153 8.18775 8.91231 7.16978 9.18528C7.05675 9.1968 6.94868 9.23755 6.85618 9.30352C6.77528 9.36279 6.71431 9.44527 6.68138 9.54C6.59092 9.84133 6.55779 10.157 6.58371 10.4705C6.62526 10.8752 6.50516 11.2799 6.24956 11.5964C6.11174 11.7331 5.94795 11.8408 5.76783 11.9132C5.58771 11.9856 5.39491 12.0212 5.20081 12.0179C4.98187 12.0345 4.76212 12.0379 4.54277 12.0282L4.22404 12.0179L3.91044 12.0231C3.71509 12.0231 3.53516 12.0282 3.33981 12.0179C3.02836 12.0411 2.71561 12.0411 2.40416 12.0179C2.06798 11.9769 1.75777 11.8164 1.53022 11.5655Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1393_2826'>
          <rect width='20' height='20' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
}
