/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from 'react-icons/ri';

import { FallbackSpinner } from 'components/atoms';
import { HASHED_COUNTRIES, COUNTRIES } from 'constants/countries';
import { parsePhoneNumber } from 'components/utils/phone-lib';

import { selectedCountry, updatedDialCode, useNumberSearchVars } from '../phone-input/useVars';
import { DropdownContainer } from './Styles';
import CountriesList from './CountriesList';

const CountrySelect = ({ contactNumber }: { contactNumber?: string }) => {
  const [visibleDropdown, setVisibleDropdown] = useState(() => false);
  const { t } = useTranslation();
  const defaultCountryCode = localStorage.getItem('_dialer-default-country');
  const [countries, setCountries] = useState(() => COUNTRIES);
  const selectedCountryData: any = useReactiveVar(selectedCountry);
  const { setSelectedCountry } = useNumberSearchVars(selectedCountry);
  const { setUpdatedDialCode } = useNumberSearchVars(updatedDialCode);
  const findCountry = (keyword: string) =>
    countries?.find(country => country?.alphaTwoCode === keyword);

  const searchCountry = useCallback(
    (searchedKeyword: string) =>
      COUNTRIES?.filter(
        country =>
          country?.name?.toLowerCase().includes(searchedKeyword.toLowerCase()) ||
          country?.dialingCode?.includes(searchedKeyword),
      ),
    [],
  );
  useEffect(() => {
    const keyword = defaultCountryCode?.toUpperCase() || 'US';
    /** Display recently dialed number's country flag from localstorage or display US flag if not available */
    let defaultCountry = findCountry(keyword);
    if (contactNumber) {
      const number = contactNumber?.replace(/\s/g, '');
      const { country = 'US' } = parsePhoneNumber(number);
      defaultCountry = findCountry(country);
    }
    setSelectedCountry(defaultCountry);
    return () => {
      setSelectedCountry(null);
      setUpdatedDialCode(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountryCode, contactNumber]);

  const handleCountrySelect = (selected: any) => {
    const keyword = selected.key;
    const selectedCountryObj = findCountry(keyword);
    setUpdatedDialCode(selectedCountryObj?.dialingCode);
    setVisibleDropdown(false);
    setSelectedCountry(selectedCountryObj);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchedKeyword = e.target.value;
    const searchResults = searchCountry(searchedKeyword);
    setCountries(searchResults);
  };

  const handleDropdownVisibility = (flag: boolean) => {
    setVisibleDropdown(flag);
    if (!flag) setCountries(COUNTRIES);
  };

  const selectedCountryCode: any = selectedCountryData?.alphaTwoCode;
  const countryDetail = HASHED_COUNTRIES[selectedCountryCode];

  return (
    <React.Suspense fallback={<FallbackSpinner />}>
      <span data-cy='country-select'>
        <DropdownContainer
          overlay={
            <CountriesList
              handleSelect={handleCountrySelect}
              handleSearch={onSearch}
              placeholder={t('searchByNameOrCode', 'Search by name or code')}
              options={countries}
              handleDropdownVisibility={handleDropdownVisibility}
            />
          }
          onVisibleChange={handleDropdownVisibility}
          visible={visibleDropdown}
          trigger={['click']}
          getPopupContainer={triggerNode => triggerNode}
        >
          <a onClick={e => e.preventDefault()} className='relative flex items-center m-0 min-w-38'>
            <img src={countryDetail?.flagUrl} alt={countryDetail?.alphaTwoCode} />
            <RiArrowDownSLine className='absolute right-0 text-gray-500' size={18} />
          </a>
        </DropdownContainer>
      </span>
    </React.Suspense>
  );
};
export default React.memo(CountrySelect);
