import { Avatar } from 'antd';
import styled from 'styled-components';
import { RiArrowDownSLine } from 'react-icons/ri';

import { Channel } from 'generated/graphql';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { Tooltip } from 'components/atoms';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .avatar-container {
    width: 36px;
    height: 36px;
    display: flex;
    padding: 11px 8px;
    border-radius: 50%;
    .avatar {
      width: 100%;
      height: 14px;
    }
  }
  /* svg.dropdown {
    color: #ffffff;
    font-size: 24px;
    padding: 2px;
    border-radius: 50%;
    .ant-dropdown-open & {
      background: #301c58;
    }
  } */
  &.dialer-numbers-dropdown {
    .ant-menu-item:hover & .avatar-container,
    .ant-menu-item-selected & .avatar-container {
      background: #ece6f2;
    }
  }
`;
interface Props {
  channel: Channel | null;
  className?: string;
  isListItem?: boolean;
}
export default function DialerNumberCard({ channel, className, isListItem }: Props) {
  const { number = '', name = '' } = channel || {};
  const { phoneFormatIntl, flagUrl } = parsePhoneNumber(number);

  return (
    <Wrapper className={className}>
      <div className='flex items-center cursor-pointer'>
        <p
          data-cy='n-select-title'
          className='ml-4 mr-6 text-11 text-gray-600 leading-3 font-medium font-manrope w-10.5'
        >
          Calling Number
        </p>

        <div className='bg-primary-100 avatar-container mr-2' data-cy='n-select-avt'>
          <Avatar shape='square' className='avatar' src={flagUrl} />
        </div>
        {/* <div className='number-details'> */}
        <div>
          <h6 data-cy='n-select-name' className='text-primary text-11.5 leading-3 truncate w-30'>
            {name}
          </h6>
          <p data-cy='n-select-num' className='text-gray-600 text-11.5 leading-2.75 mt-0.5'>
            {phoneFormatIntl}
          </p>
        </div>
      </div>
      <RiArrowDownSLine className='dropdown mx-4.5' color='#390179' />
    </Wrapper>
  );
}
