import styled from 'styled-components';

export const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .title {
    color: ${({ theme }) => theme.colors.text.t9};
    font-weight: ${({ theme }) => theme.text.weight.bold};
    font-size: ${({ theme }) => theme.text.size.heading.h4};
    line-height: 32px;
    margin: 40px 0 16px;
  }
  .note {
    max-width: 412px;
    font-size: ${({ theme }) => theme.text.size.body.text.primary};
    font-weight: ${({ theme }) => theme.text.weight.light};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.text.t6};
    margin-bottom: 24px;
    text-align: center;
  }
  .ant-btn {
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    height: 48px;
    padding: 0 24px;
  }
  &.not-found {
    img {
      max-width: 512px;
    }
  }
  &.server-error {
    img {
      max-width: 443px;
    }
  }
  img {
    width: 100%;
    max-width: 400px;
  }
`;
