import { Route, Redirect } from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import * as path from 'constants/routes';
import useLocalStorage from 'hooks/useLocalStorage';
import { roles, getRoleId } from 'constants/roles';

const ProtectedRoute = ({ roleList, ...routeProps }: any) => {
  const [tokens] = useLocalStorage('_tokens', '');
  const accessToken = tokens?.accessToken;
  const accessTokenDecoded = accessToken && jwtDecode<JwtPayload>(accessToken);
  const ROLE: string = accessTokenDecoded?.user_claims?.role;

  if (roleList?.indexOf?.(getRoleId(ROLE)) < 0) {
    switch (getRoleId(ROLE)) {
      case roles.OWNER:
      case roles.ADMIN:
      case roles.MEMBER:
        return <Redirect to={path.DASHBOARD} />;
      case roles.SUPPORT:
        return <Redirect to={path.PURCHASE} />;
      case roles.DEVELOPER:
        return <Redirect to={path.PLANSETUP} />;
      default:
        return <Route {...routeProps} />;
    }
  }

  return <Route {...routeProps} />;
};

export default ProtectedRoute;
