import { ReactElement } from 'react';
import { ManIcon } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { HASHED_COUNTRIES } from 'constants/countries';

import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import { WidgetAvatar } from './Styles';

export function ClientInfo(): ReactElement {
  const { clientInfo } = useCallWidgetContext();
  const { phoneFormatIntl, country = '' } = parsePhoneNumber(clientInfo?.number ?? '');
  const countryName = HASHED_COUNTRIES[country]?.name || '';
  return (
    <div className='flex flex-col items-center  h-61.5'>
      <p className='mt-6 text-gray text-base leading-3.5 font-medium '>Incoming call</p>
      <div className='mt-15'>
        <WidgetAvatar
          shape='square'
          size={80}
          icon={<ManIcon />}
          src={clientInfo?.profilePicture}
        />
      </div>
      <p className='mt-6 text-gray-900 text-base leading-4 font-semibold font-manrope truncate w-48'>
        {clientInfo?.name || phoneFormatIntl}
      </p>
      <p className='mt-3 text-gray-700 text-13 leading-4 font-semibold truncate w-50'>
        {countryName}
      </p>
    </div>
  );
}
