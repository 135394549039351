import styled from 'styled-components';
import { AudioSettingsDropdownCSS } from '../../common/Styles';

export const FieldWrapper = styled.div`
  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      background: transparent;
      padding: 4px 14px;
      border-radius: var(--Radius, 10px);
      + .ant-select-arrow {
        top: 42%;
      }
      > span {
        color: var(--Text-500-Component, #333036);
        font-family: Heebo;
        font-size: 15px;
        font-weight: 420;
        line-height: 32px;
      }
    }

    .ant-select-dropdown {
      ${AudioSettingsDropdownCSS}
    }
  }
`;
