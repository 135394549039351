/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import isEmpty from 'lodash.isempty';

import { MemberNode } from 'generated/graphql';
import { BadgedAvatar } from 'components/atoms/avatar';
import { Tooltip } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';
import { CallTransferFill } from 'components/atoms/icon';
import { PhoneInput } from 'components/molecules/fields';

import { useAgentTransferHandler } from './useAgentTransferHandler';
import { StyledRadioGroup, Wrapper } from './Styles';
import { useExternalTransferHandler } from './useExternalTransferHandler';
import { WidgetActionButton } from '../Styles';

const CallTransfer = () => {
  const [memberToAssign, setMemberToAssign] = useState(undefined);
  const {
    elementRef,
    onlineMembers,
    handleOptionSelection,
    onLoadMore,
  } = useAgentTransferHandler();
  const hasMembersOnline = onlineMembers && onlineMembers?.length > 0;
  const EXTERNAL_NUMBER = 'external-number';
  const isExternalCallTransfer = memberToAssign === EXTERNAL_NUMBER;

  const onRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    setMemberToAssign(value);
  };

  const { formMethods, handleFormSubmit, loadingCallTransfer } = useExternalTransferHandler();

  const {
    control,
    watch,
    formState: { errors },
  } = formMethods;
  const hasNumber = !!watch('number');

  const NAME_CHAR_LIMIT = 24;
  const handleForwardCall = () => {
    if (isExternalCallTransfer) {
      handleFormSubmit();
      return;
    }
    const member = onlineMembers?.find((agent: MemberNode) => agent?.id === memberToAssign);
    handleOptionSelection(member);
  };

  const disableForwardCallButton =
    !memberToAssign ||
    (isExternalCallTransfer ? loadingCallTransfer || !isEmpty(errors) : !hasMembersOnline);

  return (
    <Wrapper>
      <StyledRadioGroup onChange={onRadioChange} defaultValue={memberToAssign}>
        <h3>External Number</h3>
        <form className='pl-4 flex items-center'>
          <PhoneInput
            name='number'
            id='number'
            control={control}
            errors={errors}
            containerClass='add-new-contact-phone'
            disableDropdown={true}
            placeholder='Enter phone number'
          />

          <Radio value={EXTERNAL_NUMBER} disabled={!hasNumber} />
        </form>
        <h3>Team Members</h3>
        <section className='overflow-y-auto h-57'>
          {onlineMembers?.map((agent: MemberNode) => {
            const fullname = `${agent?.firstname} ${agent?.lastname}`;
            const showNameInTooltip = fullname.length > NAME_CHAR_LIMIT;
            return (
              <Radio key={agent?.id} value={agent?.id}>
                <div className='flex items-center'>
                  <div className='mr-2.5'>
                    <BadgedAvatar
                      isOnline={agent?.online}
                      src={agent?.profilePicture}
                      avatarSize={24}
                      dotBoxShadowColor='white'
                      dotBoxShadowWidth={2}
                      avatarShape='circle'
                    />
                  </div>

                  <Tooltip title={showNameInTooltip ? fullname : ''}>
                    <h6 className='text-gray-600 font-semibold text-sm leading-3.5 capitalize'>
                      {nameElipsis(fullname ?? '', NAME_CHAR_LIMIT)}
                    </h6>
                  </Tooltip>
                </div>
              </Radio>
            );
          })}
          <div ref={elementRef} onClick={onLoadMore} className='h-1' />
        </section>
      </StyledRadioGroup>
      <div className='px-5'>
        <WidgetActionButton
          className='w-full'
          type='primary'
          icon={<CallTransferFill className='w-5 h-5' />}
          onClick={handleForwardCall}
          disabled={disableForwardCallButton}
        >
          Forward Call
        </WidgetActionButton>
      </div>
    </Wrapper>
  );
};

export default CallTransfer;
