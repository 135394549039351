import { Avatar as AntAvatar } from 'antd';
import styled from 'styled-components';

type AntAvatarProps = React.ComponentProps<typeof AntAvatar>;
interface AvatarProps extends AntAvatarProps {
  avatarStringColor?: string;
}

const UserAvatar = styled(AntAvatar)<AvatarProps>`
  &.ant-avatar {
    background: #e7e6eb;
    &-square {
      border-radius: 12px;
    }

    > .ant-avatar-string {
      font-family: 'Manrope', sans-serif;
      font-weight: 600;
      text-transform: capitalize;
      transform: translate(-50%, -50%) !important;
      top: 50%;
      color: ${props => props.avatarStringColor ?? '#cfccd5'};
    }
  }
`;
export default UserAvatar;
