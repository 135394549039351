export default function CreditIcon() {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.0625 4.74707H3.51562C1.57711 4.74707 0 6.32418 0 8.2627V27.0127C0 28.9375 1.55469 30.506 3.47445 30.5281C3.48242 30.5282 3.49031 30.5283 3.49828 30.5283H34.0625C36.001 30.5283 37.5781 28.9512 37.5781 27.0127V8.2627C37.5781 6.32418 36.001 4.74707 34.0625 4.74707Z'
        fill='#33BEE8'
      />
      <path
        d='M37.5781 8.2627V27.0127C37.5781 28.9518 36.0008 30.5283 34.0625 30.5283H21.1719V4.74707H34.0625C36.0008 4.74707 37.5781 6.32441 37.5781 8.2627Z'
        fill='#2BA6E3'
      />
      <path d='M0 10.5283H37.5781V17.7939H0V10.5283Z' fill='#1B95E0' />
      <path d='M21.1719 10.5283H37.5781V17.7939H21.1719V10.5283Z' fill='#167ECC' />
      <path
        d='M8.20312 22.7158H5.9375C5.29031 22.7158 4.76562 23.2405 4.76562 23.8877C4.76562 24.5349 5.29031 25.0596 5.9375 25.0596H8.20312C8.85031 25.0596 9.375 24.5349 9.375 23.8877C9.375 23.2405 8.85031 22.7158 8.20312 22.7158Z'
        fill='#2BA6E3'
      />
      <path
        d='M15.2344 22.7158H12.9688C12.3216 22.7158 11.7969 23.2405 11.7969 23.8877C11.7969 24.5349 12.3216 25.0596 12.9688 25.0596H15.2344C15.8816 25.0596 16.4062 24.5349 16.4062 23.8877C16.4062 23.2405 15.8816 22.7158 15.2344 22.7158Z'
        fill='#2BA6E3'
      />
      <path
        d='M39.848 33.5055L29.302 14.7678C28.8537 13.9715 27.7059 13.9718 27.2587 14.7693L16.7463 33.5069C16.3087 34.2871 16.8737 35.2522 17.7684 35.2522H38.8268C39.7223 35.2522 40.2871 34.2856 39.848 33.5055Z'
        fill='#FEDD33'
      />
      <path
        d='M38.8268 35.2524H28.237V14.1727C28.6495 14.1571 29.0698 14.3555 29.3018 14.768L39.8479 33.5055C40.287 34.286 39.7221 35.2524 38.8268 35.2524Z'
        fill='#FBBD43'
      />
      <path
        d='M28.2371 21.8887C27.5899 21.8887 27.0652 22.4134 27.0652 23.0605V26.1934C27.0652 26.8405 27.5899 27.3652 28.2371 27.3652C28.8843 27.3652 29.409 26.8405 29.409 26.1934V23.0605C29.409 22.4134 28.8844 21.8887 28.2371 21.8887Z'
        fill='#FBBD43'
      />
      <path
        d='M29.4088 23.0605V26.1934C29.4088 26.8402 28.8846 27.3652 28.237 27.3652V21.8887C28.8846 21.8887 29.4088 22.4137 29.4088 23.0605Z'
        fill='#F19637'
      />
      <path
        d='M28.2371 30.8815C28.8648 30.8815 29.3737 30.3727 29.3737 29.745C29.3737 29.1173 28.8648 28.6084 28.2371 28.6084C27.6094 28.6084 27.1005 29.1173 27.1005 29.745C27.1005 30.3727 27.6094 30.8815 28.2371 30.8815Z'
        fill='#FBBD43'
      />
      <path
        d='M29.3737 29.7441C29.3737 30.3723 28.8651 30.8809 28.237 30.8809V28.6074C28.8651 28.6074 29.3737 29.1168 29.3737 29.7441Z'
        fill='#F19637'
      />
    </svg>
  );
}
