import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const CAMPAIGN_CALL_HOLD = gql`
  mutation holdCampaignConversation($data: HoldCampaignConversationInput!)
  @api(name: "salesDialer") {
    holdCampaignConversation(data: $data) {
      error {
        ...Error
      }
      data {
        conversationSid
        onHold
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const CAMPAIGN_CALL_RECORDING = gql`
  mutation recordCampaignConversation($input: CampaignConversationRecordInput!)
  @api(name: "salesDialer") {
    recordCampaignConversation(input: $input) {
      error {
        ...Error
      }
      data {
        conversationSid
        status
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
