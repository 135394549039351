import { createContext, useState } from 'react';

type IAppContext = {
  chooseFreeNumberVisible?: boolean;
  unSavedCallWidgetAction?: boolean;
  setChooseFreeNumberVisible?: (val: boolean) => void;
  setUnSavedCallWidgetAction?: (val: boolean) => void;
};
const AppContext = createContext<IAppContext>({
  chooseFreeNumberVisible: false,
});

function AppProvider({ children }: any) {
  const [chooseFreeNumberVisible, setChooseFreeNumberVisible] = useState(false);
  const [unSavedCallWidgetAction, setUnSavedCallWidgetAction] = useState(false);

  return (
    <AppContext.Provider
      value={{
        chooseFreeNumberVisible,
        unSavedCallWidgetAction,
        setChooseFreeNumberVisible,
        setUnSavedCallWidgetAction,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
