import { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ALL_TAGS, ADD_TAG } from 'graphql/client';
import { Tag } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { setTag } from '../utils';

const useTagOperations = () => {
  const [tagChange, setTagChange] = useState<boolean>(false);
  const [isTagFieldLoading, setIsTagFieldLoading] = useState(false);
  const [tagFieldOptions, setTagFieldOptions] = useState<Tag[] | []>([]);
  const [showTagColorOptions, setShowTagColorOptions] = useState(false);
  const [tagFieldValue, setTagFieldValue] = useState<any>([]);
  const [newlyCreatedTagValue, setNewlyCreatedTagValue] = useState<any>(undefined);
  const { contactId } = useParams<Record<string, string | undefined>>();

  const tagSelectRef = useRef<any>(undefined);

  const [loadTags, { called, data: tagsData }] = useLazyQuery(ALL_TAGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      const { data: tagList, error } = tagsData?.allTags ?? {};
      if (error === null && tagsData) {
        const tags = tagList.map((tag: Tag) => ({
          ...setTag(tag),
        }));
        setTagFieldOptions([...tags]);
      }
    },
  });
  const [addTag] = useMutation(ADD_TAG);

  useEffect(() => {
    loadTags({});
  }, [called, loadTags, contactId]);
  return {
    tagChange,
    setTagChange,
    setTagFieldValue,
    setIsTagFieldLoading,
    setShowTagColorOptions,
    setNewlyCreatedTagValue,
    isTagFieldLoading,
    tagFieldOptions,
    showTagColorOptions,
    tagFieldValue,
    newlyCreatedTagValue,
    addTag,
    tagSelectRef,
  };
};

export default useTagOperations;
