import { useMemo, useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { GET_ALL_CONTACTS } from 'graphql/client';
import { selectedTags } from 'hooks/useContactVars';

const sortedContacts = (contacts = []) => {
  return [...contacts]?.sort((a: any, b: any) => {
    const item1 = a?.name;
    const item2 = b?.name;
    return item1?.localeCompare(item2, undefined, {
      sensitivity: 'base',
      numeric: true,
      ignorePunctuation: true,
    });
  });
};
function groupedContact(contacts: any) {
  const sorted = sortedContacts(contacts);
  return sorted?.reduce((acc: any, el: any) => {
    const group = el?.name?.[0]?.toUpperCase() || 'U';
    if (acc[group]) {
      return {
        ...acc,
        [group]: acc[group].concat([el]),
      };
    }
    return { ...acc, [group]: [el] };
  }, {});
}

export function generateContacts(contacts: any) {
  const allContacts: any = groupedContact(contacts);
  const groups: any = Object.keys(allContacts);

  const items: any = groups.reduce((acc: any, val: any): any => {
    const contact: any = allContacts[val];
    return acc.concat([{ type: 'group', symbol: val, id: val }, ...contact]);
  }, []);

  return items;
}
export function useContactsQuery() {
  const filteredTags = useReactiveVar<any>(selectedTags);

  const [getAllContacts, { data, loading, called }] = useLazyQuery(GET_ALL_CONTACTS, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    getAllContacts({
      variables: {
        ...(filteredTags?.length > 0 && { tags: filteredTags }),
      },
    });
  }, [filteredTags, getAllContacts]);

  const contacts = useMemo(() => data?.newContacts?.data ?? [], [data]);
  const hasContact = contacts.length > 0;
  const counts = contacts?.length ?? 0;
  const contactList: any = useMemo(() => {
    if (contacts) {
      return generateContacts(contacts);
    }
    return [];
  }, [contacts]);

  return {
    contacts,
    hasContact,
    loading,
    contactList,
    counts,
    called,
    filteredTags,
  };
}
