export default function MicrophoneMuteFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9856 14.7759C13.8491 14.7775 13.7149 14.7409 13.5982 14.6702C13.4815 14.5994 13.3869 14.4975 13.3252 14.3757C13.2328 14.2031 13.2125 14.001 13.2688 13.8134C13.325 13.6259 13.4533 13.4684 13.6254 13.3752C14.0501 13.1472 14.3981 12.7992 14.626 12.3746C14.6713 12.2885 14.733 12.2122 14.8079 12.15C14.8827 12.0878 14.969 12.0409 15.0619 12.0121C15.1548 11.9833 15.2526 11.9731 15.3494 11.9821C15.4463 11.9911 15.5405 12.019 15.6266 12.0644C15.7985 12.1578 15.9271 12.3147 15.985 12.5016C16.043 12.6885 16.0256 12.8907 15.9367 13.065C15.5744 13.7521 15.0129 14.3136 14.3258 14.6759C14.2223 14.7367 14.1055 14.771 13.9856 14.7759Z'
        fill='currentColor'
      />
      <path
        d='M19.4086 10.6436C19.4039 11.729 19.165 12.8007 18.7083 13.7854C18.242 14.7618 17.5693 15.6254 16.7366 16.3164C15.9039 17.0074 14.9312 17.5093 13.8855 17.7876C13.5088 17.889 13.1238 17.956 12.735 17.9877V20.4991H15.6266C15.8256 20.4991 16.0164 20.5782 16.1572 20.7189C16.2979 20.8597 16.377 21.0505 16.377 21.2496C16.377 21.4486 16.2979 21.6395 16.1572 21.7802C16.0164 21.9209 15.8256 22 15.6266 22H8.34248C8.14345 22 7.95252 21.9209 7.81178 21.7802C7.67105 21.6395 7.59206 21.4486 7.59206 21.2496C7.59206 21.0505 7.67105 20.8597 7.81178 20.7189C7.95252 20.5782 8.14345 20.4991 8.34248 20.4991H11.2341V17.9877C11.0429 17.9743 10.8525 17.9509 10.6637 17.9177C10.4674 17.8819 10.2933 17.7695 10.1798 17.6053C10.0663 17.4411 10.0226 17.2386 10.0584 17.0422C10.0942 16.8458 10.2067 16.6717 10.3708 16.5582C10.535 16.4447 10.7375 16.401 10.9339 16.4369C11.2279 16.4888 11.5258 16.5155 11.8244 16.5169H12.1546C12.6076 16.509 13.058 16.4452 13.4954 16.3268C14.333 16.1048 15.1117 15.7018 15.7766 15.1461C16.4437 14.592 16.9805 13.8977 17.3489 13.1125C17.7172 12.3274 17.908 11.4708 17.9078 10.6036C17.9078 10.4045 17.9869 10.2137 18.1276 10.0729C18.2684 9.93221 18.4592 9.85315 18.6582 9.85315C18.8572 9.85315 19.0482 9.93221 19.1889 10.0729C19.3296 10.2137 19.4086 10.4045 19.4086 10.6036V10.6436Z'
        fill='currentColor'
      />
      <path
        d='M20.5192 4.56021L16.1568 8.91263L10.5336 14.5358L4.53028 20.5392C4.38735 20.6764 4.19806 20.755 3.99995 20.7593C3.80099 20.7591 3.61034 20.6799 3.46975 20.5392C3.39895 20.4702 3.34267 20.3877 3.30425 20.2967C3.26583 20.2056 3.24599 20.1077 3.24599 20.0089C3.24599 19.91 3.26583 19.8121 3.30425 19.7211C3.34267 19.63 3.39895 19.5475 3.46975 19.4786L6.93165 16.0166L6.7515 15.8165C6.06488 15.1391 5.51952 14.3322 5.14683 13.4425C4.77414 12.5529 4.5816 11.5982 4.58035 10.6336C4.57903 10.5351 4.59711 10.4372 4.63361 10.3456C4.6701 10.2541 4.72424 10.1706 4.793 10.1C4.86175 10.0294 4.9438 9.973 5.03434 9.93408C5.12489 9.89516 5.22221 9.8745 5.32076 9.8732C5.51898 9.87579 5.70835 9.95568 5.84852 10.0959C5.9887 10.236 6.06859 10.4254 6.07118 10.6236C6.08499 12.1942 6.72204 13.695 7.8422 14.7959L7.98229 14.956L8.83274 14.1156C8.58434 13.8872 8.3658 13.6283 8.18235 13.3451C7.81557 12.781 7.61136 12.1267 7.59206 11.454V5.85092C7.62958 5.29957 7.7773 4.76136 8.0265 4.26812C8.2757 3.77488 8.62127 3.33663 9.04282 2.97931C9.89367 2.34353 10.9274 2 11.9895 2C13.0517 2 14.0853 2.34353 14.9361 2.97931C15.7675 3.68628 16.2855 4.6934 16.377 5.78087V6.57129L19.4687 3.47959C19.5377 3.40879 19.6201 3.35251 19.7112 3.31409C19.8022 3.27567 19.9002 3.25589 19.999 3.25589C20.0979 3.25589 20.1957 3.27567 20.2868 3.31409C20.3779 3.35251 20.4603 3.40879 20.5293 3.47959C20.6012 3.55026 20.6582 3.6347 20.6968 3.7279C20.7354 3.8211 20.7549 3.92114 20.754 4.02201C20.7531 4.12288 20.7318 4.22255 20.6915 4.31502C20.6511 4.40749 20.5925 4.49089 20.5192 4.56021Z'
        fill='currentColor'
      />
    </svg>
  );
}
