import { ReactElement, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { RiCloseLine } from 'react-icons/ri';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { activeCallConversationData, smsLimitExceededWarning } from 'services/apollo/reactiveVars';
import { useTwilioContext } from 'lib/twilio';
import { Icon } from 'components/atoms';
import uuid from 'components/utils/uuid';
import { SMS_MAX_LENGTH } from 'schema-validation/common';
import { useWorkspaceCredit } from 'components/organisms/workspace/useWorkspaceCredit';
import { useSmsHelper } from 'components/organisms/conversation/client-to-agent/conversation-box/hooks/useSmsHelper';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import { usePhoneMutation } from '../../hooks/usePhoneMutation';
import * as S from './Styles';
import { messageTemplates } from './constants';

type IMessageInputForm = {
  message: string;
};

interface IProps {
  handleClose: () => void;
}

export function RejectWithMessage({ handleClose }: IProps): ReactElement {
  const {
    state: { connection },
  } = useTwilioContext();
  const callerChannelId = connection?.customParameters?.get('channel_sid');
  const { channelInfo } = useCallWidgetContext();
  const { t } = useTranslation();
  const { sufficientCredit } = useWorkspaceCredit();
  const { isContactSmsSupported, isAgentSmsSupported, isSmsSupportedIntl } = useSmsHelper({
    channelId: callerChannelId,
  });
  const smsLimitWarningVisible = useReactiveVar<any>(smsLimitExceededWarning);

  const showSmsLimitWarning = () => {
    // Show sms limit exceed warning modal for kyc unverified users
    smsLimitExceededWarning(!smsLimitWarningVisible);
  };

  const { updateRejectConversation, sendMessage } = usePhoneMutation({
    showSmsLimitWarning,
  });

  const conversationDataRef: any = useRef();
  const conversationData = useReactiveVar(activeCallConversationData);
  conversationDataRef.current = conversationData;

  const messageInput = {
    message: yup.string().required('Custom Message is required'),
  };

  const schema = yup.object().shape({
    message: messageInput.message,
  });

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<IMessageInputForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });

  const onSelectMessageTemplate = (message: string) => {
    setValue('message', message);
    clearErrors();
  };

  const handleMessageSubmit = async (val: IMessageInputForm) => {
    const message = val?.message?.trim();
    if (!message) {
      setError('message', {
        type: 'manual',
        message: 'Required',
      });
      return;
    }
    const callerNumber = `+${connection?.customParameters
      ?.get('from')
      ?.trim()
      ?.replace(/\+/g, '')}`;
    const isCreditSufficient = await sufficientCredit();
    if (!isCreditSufficient) return;
    if (!isAgentSmsSupported(channelInfo)) return;
    if (!isContactSmsSupported(callerNumber)) return;
    if (!isSmsSupportedIntl(callerNumber, channelInfo?.number)) return;
    connection?.reject();
    if (conversationDataRef.current?.id) updateRejectConversation(conversationDataRef.current.id);
    await sendMessage({
      variables: {
        data: {
          conversationType: 'Message',
          content: {
            body: message,
            files: [],
          },
          contact: callerNumber,
          channel: callerChannelId,
        },
      },
    });
    handleClose();
  };

  const handleCloseBtnClick = () => {
    handleClose();
  };

  return (
    <div>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700'>
          Reject with Message
        </p>
      </div>
      <div className='px-5 py-3 text-left'>
        <div>
          <div className='mb-3 text-gray-600 text-sm leading-3.5 font-normal '>
            {messageTemplates.map((text: string, idx: number) => (
              <div
                key={uuid()}
                role='presentation'
                onClick={() => onSelectMessageTemplate(text)}
                className={`pb-3.75 ${idx !== 0 && 'pt-3'} border-b border-gray-50 cursor-pointer`}
              >
                {text}
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit(handleMessageSubmit)}>
            <div className='flex items-center relative'>
              <S.InputField
                name='message'
                id='message'
                control={control}
                onPressEnter={e => {
                  if (!e.shiftKey) {
                    e.preventDefault();
                  }
                }}
                placeholder={t('customMessage...', 'Custom message...')}
                autoSize
                maxLength={SMS_MAX_LENGTH}
              />
              <S.SendButton
                htmlType='submit'
                className='absolute right-0'
                disabled={!!errors?.message}
              >
                <Icon name='widget_send' />
              </S.SendButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
