export default function Headphone({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.9975 11.9078V18.5078C22.0225 18.9691 21.9533 19.4308 21.794 19.8644C21.6347 20.2981 21.3887 20.6948 21.071 21.0302C20.7533 21.3657 20.3705 21.6328 19.9461 21.8154C19.5217 21.998 19.0645 22.0922 18.6025 22.0922C18.1405 22.0922 17.6833 21.998 17.2589 21.8154C16.8345 21.6328 16.4517 21.3657 16.134 21.0302C15.8163 20.6948 15.5703 20.2981 15.411 19.8644C15.2517 19.4308 15.1825 18.9691 15.2075 18.5078V15.8678C15.2071 15.252 15.3743 14.6477 15.6914 14.1198C16.0085 13.5919 16.4634 13.1604 17.0072 12.8715C17.551 12.5826 18.1633 12.4473 18.7782 12.4802C19.3932 12.513 19.9875 12.7127 20.4975 13.0578V11.9078C20.4975 9.6535 19.602 7.49149 18.0079 5.89743C16.4138 4.30337 14.2518 3.40784 11.9975 3.40784C9.74316 3.40784 7.58115 4.30337 5.98709 5.89743C4.39303 7.49149 3.4975 9.6535 3.4975 11.9078V13.0578C4.05426 12.6776 4.71332 12.4754 5.38751 12.4778C6.2866 12.4778 7.14886 12.835 7.78461 13.4707C8.42036 14.1065 8.7775 14.9687 8.7775 15.8678V18.5078C8.7775 18.953 8.68983 19.3938 8.51947 19.8051C8.34911 20.2164 8.0994 20.5901 7.78461 20.9049C7.46982 21.2197 7.09611 21.4694 6.68481 21.6398C6.27352 21.8101 5.83269 21.8978 5.38751 21.8978C4.94233 21.8978 4.5015 21.8101 4.09021 21.6398C3.67892 21.4694 3.30521 21.2197 2.99042 20.9049C2.67563 20.5901 2.42592 20.2164 2.25555 19.8051C2.08519 19.3938 1.9975 18.953 1.9975 18.5078V11.9078C1.9975 9.25567 3.05109 6.71213 4.92645 4.83676C6.80182 2.9614 9.34533 1.90784 11.9975 1.90784C14.6497 1.90784 17.1932 2.9614 19.0686 4.83676C20.9439 6.71213 21.9975 9.25567 21.9975 11.9078Z'
        fill='currentColor'
      />
    </svg>
  );
}
