import * as yup from 'yup';
import { pattern } from 'constants/regex';

const BILL_TO_MAX_LENGTH = 42;
const ADDRESS_MAX_LENGTH = 42;
const MIN_LENGTH = 2;
const TAX_MIN_LENGTH = 0;
const TAX_MAX_LENGTH = 30;

export const billInfoInputs = {
  billTo: yup
    .string()
    .trim()
    .required('Required')
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(BILL_TO_MAX_LENGTH, `Shouldn't exceed ${BILL_TO_MAX_LENGTH} characters`),
  taxId: yup
    .string()
    .min(TAX_MIN_LENGTH, `Should contain atleast ${TAX_MIN_LENGTH} characters`)
    .max(TAX_MAX_LENGTH, `Shouldn't exceed ${TAX_MAX_LENGTH} characters`)
    .trim(),
  country: yup.string().required('Required'),
  address: yup
    .string()
    .trim()
    .required('Required')
    .matches(pattern.alphaNumeric, `Should only contain alphanumeric characters`)
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(ADDRESS_MAX_LENGTH, `Shouldn't exceed ${ADDRESS_MAX_LENGTH} characters`),
  recipients: yup
    .array()
    .of(yup.string().email('Email must be a valid email'))
    .required('Required'),
};
