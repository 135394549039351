import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';

const SPACE = '3px';
const SWITCH_HEIGHT = '24px';
const SWITCH_HANDLE_SIZE = '18px';
const SMALL_SWITCH_HEIGHT = '20px';
const SMALL_SWITCH_HANDLE_SIZE = '14px';

export const Switch = styled(AntSwitch)`
  &.ant-switch {
    min-width: 40px;
    height: ${SWITCH_HEIGHT};
    line-height: ${SWITCH_HEIGHT};
    background-color: #cfccd5;
    :focus {
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    }
    .ant-switch-handle {
      width: ${SWITCH_HANDLE_SIZE};
      height: ${SWITCH_HANDLE_SIZE};
      top: ${SPACE};
      left: ${SPACE};
    }
    .ant-switch-inner {
      .ant-switch-inner-checked {
        margin-inline-start: calc(6px - 100% + ${SWITCH_HEIGHT});
        margin-inline-end: calc(18px + 100% - ${SWITCH_HEIGHT});
      }
      .ant-switch-inner-unchecked {
        margin-top: -${SWITCH_HEIGHT};
        margin-inline-start: 18px;
        margin-inline-end: 6px;
      }
    }
    &.ant-switch-checked {
      background-color: #390179;
      .ant-switch-handle {
        left: calc(100% - ${SWITCH_HANDLE_SIZE} - ${SPACE});
      }
      .ant-switch-inner {
        .ant-switch-inner-checked {
          margin-inline-start: 6px;
          margin-inline-end: 18px;
        }
        .ant-switch-inner-unchecked {
          margin-inline-start: calc(18px + 100% - ${SWITCH_HEIGHT});
          margin-inline-end: calc(6px - 100% + ${SWITCH_HEIGHT});
        }
      }
    }
  }
  &.ant-switch {
    &.ant-switch-small {
      min-width: 36px;
      height: ${SMALL_SWITCH_HEIGHT};
      line-height: ${SMALL_SWITCH_HEIGHT};
      .ant-switch-handle {
        width: ${SMALL_SWITCH_HANDLE_SIZE};
        height: ${SMALL_SWITCH_HANDLE_SIZE};
      }
      .ant-switch-inner {
        .ant-switch-inner-checked {
          margin-inline-start: calc(6px - 100% + ${SMALL_SWITCH_HEIGHT});
          margin-inline-end: calc(18px + 100% - ${SMALL_SWITCH_HEIGHT});
        }
        .ant-switch-inner-unchecked {
          margin-top: -${SMALL_SWITCH_HEIGHT};
          margin-inline-start: 18px;
          margin-inline-end: 6px;
        }
      }
      &.ant-switch-checked {
        .ant-switch-handle {
          left: calc(100% - ${SMALL_SWITCH_HANDLE_SIZE} - ${SPACE});
        }
        .ant-switch-inner {
          .ant-switch-inner-checked {
            margin-inline-start: 6px;
            margin-inline-end: 18px;
          }
          .ant-switch-inner-unchecked {
            margin-inline-start: calc(18px + 100% - ${SMALL_SWITCH_HEIGHT});
            margin-inline-end: calc(6px - 100% + ${SMALL_SWITCH_HEIGHT});
          }
        }
      }
    }
  }
`;

export default Switch;
