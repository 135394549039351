import { css } from 'styled-components';

export const grids = css`
  .grid-col-2 {
    grid-column: 2;
  }
  .d-flex {
    display: flex;
  }
  .flex {
    &-row {
      flex-direction: row;
    }
    &-column {
      flex-direction: column;
    }
  }
  .align-center {
    align-items: center;
  }
  .align-flex-end {
    align-items: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .text-center {
    text-align: center;
  }
`;
