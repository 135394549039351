import { createContext } from 'react';
import { useParams } from 'react-router-dom';

import { useContactQuery } from './useContactQuery';

export const ContactContext = createContext({});

export const ContactProvider = ({ children }: any) => {
  const { contactId } = useParams<Record<string, string | undefined>>();
  const { contactData, contactNumber, loading } = useContactQuery({ contactId });

  return (
    <ContactContext.Provider
      value={{
        contactData,
        contactNumber,
        loading,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
