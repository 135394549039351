import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { produce } from 'immer';
import { GET_TEAMS, UPDATE_TEAM, ADD_TEAM, REMOVE_TEAM_MEMBER } from 'graphql/organization/team';

import { ToastMessage } from 'components/atoms';

import { CHANGE_WORKSPACE_MEMBER_ROLE } from 'graphql/foundation';

import { TeamsContext } from '../context/TeamsContext';

export function useTeamMutation(props?: any) {
  const { t } = useTranslation();
  const { teamId } = useParams<Record<string, string | undefined>>();
  const { teams, setTeams } = useContext<any>(TeamsContext);

  const [updateTeam, { loading: updatingTeam }] = useMutation(UPDATE_TEAM, {
    onCompleted: response => {
      const { error, data: updatedTeamData } = response?.updateTeam;
      if (error) {
        ToastMessage({ content: error.message, type: 'danger' });
        props?.callback?.();
        return;
      }
      const filteredTeams = teams?.filter((data: any) => data.id !== updatedTeamData?.id);
      setTeams([...filteredTeams, ...[updatedTeamData]]);
      ToastMessage({
        content: t('toast.teamDetailUpdate', 'Team Detail updated'),
        type: 'success',
      });
      props?.callback?.();
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
      props?.callback?.();
    },
  });
  const updateTeamDetail = async (payload: any) => {
    await updateTeam({
      variables: {
        id: teamId,
        data: payload,
      },
    });
  };

  const [addTeamMutation, { loading: addingTeam }] = useMutation(ADD_TEAM, {
    onCompleted: response => {
      const { error, data: newTeam } = response.addTeam;
      try {
        if (error) {
          ToastMessage({ content: error.message, type: 'danger' });
          props?.callback?.();
          return;
        }

        setTeams([...teams, ...[newTeam]]);
        ToastMessage({
          content: t('toast.teamAddSuccess', 'Team Added successfully.'),
          type: 'success',
        });

        props?.callback?.();
      } catch (err) {
        console.log(err, 'error');
      }
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
      props?.callback?.();
    },
  });
  const addTeam = async (val: any) => {
    await addTeamMutation({
      variables: {
        data: { ...val },
      },
      update(cache, { data: { addTeam: result } }) {
        const cachedTeams: any = cache.readQuery({ query: GET_TEAMS });
        if (result?.data) {
          const updatedTeams = produce(cachedTeams, (draft: any) => {
            if (draft?.teams?.data) {
              draft.teams.data.push({
                ...result.data,
                __typename: 'Teams',
              });
            }
          });
          cache.writeQuery({
            query: GET_TEAMS,
            data: updatedTeams,
          });
        }
      },
    });
  };

  const [removeMember, { loading: loadingMemberDelete }] = useMutation(REMOVE_TEAM_MEMBER, {
    onCompleted: response => {
      const { error } = response.removeMember;
      if (error === null) {
        props?.toggleDeleteConfirm?.();
        ToastMessage({
          content: t('toast.memberRemoved', 'Member Removed'),
          type: 'success',
        });
        return;
      }
      ToastMessage({ content: error.message, type: 'danger' });
    },
    onError: () => {
      props?.closeDeleteModal?.();
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
  });

  const deleteTeamMember = async (id: any) => {
    await removeMember({
      variables: {
        id: teamId,
        data: {
          member: id,
        },
      },
      update(cache, { data: { removeMember: removedMemberData } }) {
        if (removedMemberData?.data?.id) {
          const activeTeam = teams?.find((data: any) => data.id === teamId);
          const updatedData = produce(activeTeam, (draft: any) => {
            if (draft?.teamMembers?.length) {
              const memberIndex = draft.teamMembers.findIndex((data: any) => data?.id === id);
              if (memberIndex !== -1) {
                draft.teamMembers.splice(memberIndex, 1);
              }
            }
          });
          const filteredTeams = teams?.filter((data: any) => data.id !== teamId);
          setTeams([...filteredTeams, ...[updatedData]]);
        }
      },
    });
  };

  const [changeRoleMutation] = useMutation(CHANGE_WORKSPACE_MEMBER_ROLE, {
    onCompleted: response => {
      const { data, error } = response.editMemberRole;
      if (error === null) {
        ToastMessage({
          content: `${props?.member?.firstname} 's role changed to ${data.role}`,
          type: 'success',
        });
        return;
      }
      ToastMessage({
        content: error.message,
        type: 'danger',
      });
    },
    onError: () =>
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' }),
  });

  const changeMemberRole = async (id: string, role: string) => {
    await changeRoleMutation({
      variables: {
        uid: id,
        data: {
          role,
        },
      },
      update(cache, { data: { editMemberRole } }) {
        if (editMemberRole?.data?.id) {
          const memberId = editMemberRole?.data?.id;
          const activeTeam = teams?.find((data: any) => data.id === teamId);
          const updatedData: any = produce(activeTeam, (draft: any) => {
            if (draft?.teamMembers?.length) {
              const index = draft.teamMembers.findIndex((data: any) => data?.id === memberId);
              draft.teamMembers[index] = {
                ...draft?.teamMembers?.[index],
                role,
              };
            }
          });
          const filteredTeams = teams?.filter((data: any) => data.id !== updatedData?.id);
          setTeams([...filteredTeams, ...[updatedData]]);
        }
      },
    });
  };
  return {
    addTeam,
    updateTeamDetail,
    deleteTeamMember,
    loadingMemberDelete,
    changeMemberRole,
    updatingTeam,
    addingTeam,
  };
}
