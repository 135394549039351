import { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ACTIONS } from 'lib/twilio/constants';

import { Button, Tooltip } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';
import DialpadIcon from 'components/atoms/icon/DialpadIcon';
import useTwilioContext from 'lib/twilio/hooks/useTwilioContext';

const DialerButton = styled(Button)`
  @keyframes ripple {
    0% {
      opacity: 1;
      box-shadow: 0px 0px 0px 0px #afc9fe;
    }
    100% {
      opacity: 0;
      box-shadow: 0px 0px 0px 12px #afc9fe;
    }
  }
  &.ant-btn {
    overflow: visible;
    position: relative;
    padding: 0 10px 0 8px;
    &,
    &::after {
      width: 75px;
      height: 28px;
    }
    > span {
      font-family: 'Heebo';
      font-weight: 500 !important;
      line-height: 15px;
    }
    svg {
      height: 16px;
      width: 16px;
      flex-shrink: 0;
      margin-right: 4px;
    }
    &.subscription-cancelled {
      color: ${({ theme }) => theme.colors.text.t5};
      background-color: ${({ theme }) => theme.colors.text.t1};
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px;
      animation: ripple 2.25s infinite;
    }
    &[disabled],
    &.subscription-cancelled {
      &::after {
        display: none;
      }
    }
  }
`;

export default function Dialer() {
  const { t } = useTranslation();
  const { isSubscriptionExpired } = useContext(AuthContext);

  const {
    state: { liveCall, showPhoneWidget },
    dispatch,
  } = useTwilioContext();

  const toggleDialer = () => {
    if (!liveCall?.params) {
      // setVisibleDialer(prevState => !prevState);
      dispatch({
        type: ACTIONS.SHOW_DIALER,
        data: {},
      });
    }
  };

  return (
    <>
      <Tooltip
        title={liveCall?.params ? t('dialerDisabled', 'Live call is under progress') : ''}
        placement='rightTop'
      >
        <DialerButton
          className={isSubscriptionExpired ? 'subscription-cancelled' : ''}
          onClick={toggleDialer}
          icon={<DialpadIcon />}
          disabled={showPhoneWidget}
          variant='info'
        >
          Dialer
        </DialerButton>
      </Tooltip>
    </>
  );
}
