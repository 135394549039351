import { useContext, useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd';

import { AuthContext } from 'contexts/auth/AuthContext';
import { ACTIONS } from 'lib/twilio/constants';
import { useTwilioContext } from 'lib/twilio';
import { privilegeList, usePrivilege } from 'hooks/usePrivilege';
import { activeCoachingLiveCallData } from 'services/apollo/reactiveVars';

import { LiveCallsContext } from './LiveCallsContext';
import { useDashboardMutation } from '../../hooks/useDashboardMutation';

enum LiveCallActionsEnum {
  LISTEN = 'live_listening',
  WHISPER = 'call_whisper',
  BARGE = 'call_barge',
}
export const { LISTEN, WHISPER, BARGE } = LiveCallActionsEnum;
type LiveCallAddOnType = typeof LISTEN | typeof WHISPER | typeof BARGE;
export interface CoachingProps {
  record?: Record<string, any>;
}

export const useCoaching = ({ record }: CoachingProps) => {
  const { selectedRowId, setSelectedRowId } = useContext(LiveCallsContext);
  const {
    handleLiveListening,
    dispatch,
    state: { liveCall, showPhoneWidget },
  } = useTwilioContext();
  const { removeCoachInfo } = useDashboardMutation();
  const conversationId = record?.node?.conversation;
  const [showNotifyAgentConfirmation, setShowNotifyAgentConfirmation] = useState<boolean>(false);
  const [showCoachActions, setShowCoachActions] = useState<boolean>(false);
  const [showCoachingInProgressModal, setShowCoachingInProgressModal] = useState(false);
  const toggleCoachingInProgressModal = () => setShowCoachingInProgressModal(prev => !prev);

  const [accessLiveListening] = usePrivilege(
    'dashboard',
    'main_screen',
    privilegeList.dashboard.main_screen.listen_live_call,
  );
  const [accessLiveWhispering] = usePrivilege(
    'dashboard',
    'main_screen',
    privilegeList.dashboard.main_screen.whisper_live_call,
  );
  const [accessLiveBarging] = usePrivilege(
    'dashboard',
    'main_screen',
    privilegeList.dashboard.main_screen.barge_live_call,
  );

  const allowLiveCallActions = accessLiveListening || accessLiveWhispering || accessLiveBarging;
  const isListening = !!liveCall?.params?.sid;
  const recordSid = record?.node?.sid;
  const listenedSid = liveCall?.params?.sid;
  const isAnotherCoachingInProgress = isListening && recordSid !== listenedSid;
  const isSelectedRowActive = recordSid === selectedRowId;

  const LIVE_CALL_ACTIONS_OPTIONS = [
    { label: 'Listen', value: LISTEN, icon: 'call-listen', disabled: !accessLiveListening },
    { label: 'Whisper', value: WHISPER, icon: 'call-whisper', disabled: !accessLiveWhispering },
    {
      label: 'Barge',
      value: BARGE,
      icon: 'call-barge',
      disabled: !accessLiveWhispering || !accessLiveBarging,
    },
  ];
  const [liveCallAction, setLiveCallAction] = useState(LIVE_CALL_ACTIONS_OPTIONS[0].value);

  useEffect(() => {
    setShowNotifyAgentConfirmation(!showCoachActions && isSelectedRowActive);
  }, [showCoachActions, isSelectedRowActive]);

  useEffect(() => {
    if (isListening) setShowCoachActions(isSelectedRowActive);
  }, [isListening, showNotifyAgentConfirmation, isSelectedRowActive]);

  const { loggedInMemberId } = useContext(AuthContext);

  const disconnectLiveCall = () => {
    liveCall?.call?.disconnect();
    dispatch({
      type: ACTIONS.SET_LIVECALL,
      data: {},
    });
  };

  const triggerLiveCallAction = (addons: LiveCallAddOnType) => {
    disconnectLiveCall();
    setShowNotifyAgentConfirmation(false);
    setShowCoachActions(true);
    const { callerInfo, channelInfo, sid, direction, workspace } = record?.node;
    handleLiveListening({
      To: direction === 'Incoming' ? channelInfo.number : callerInfo.contact,
      From: direction === 'Incoming' ? callerInfo.contact : channelInfo?.number,
      workspace_sid: workspace,
      channel_sid: channelInfo.id,
      agent_id: loggedInMemberId,
      addons,
      sid,
      conversation_direction: direction,
    });
  };

  const handleCoachClick = (rowId: string) => () => setSelectedRowId(rowId);

  const confirmLiveCallAction = (addon: LiveCallAddOnType) => () => {
    if (addon === WHISPER) console.log('notify agent'); // todo: notify agent & whisper
    setShowCoachActions(true);
    setLiveCallAction(addon);
    activeCoachingLiveCallData(record);
    triggerLiveCallAction(addon);
  };

  const handleLeaveCoaching = () => {
    setShowCoachActions(false);
    setShowNotifyAgentConfirmation(false);
    setSelectedRowId('');
    removeCoachInfo(conversationId);
    disconnectLiveCall();
  };

  const onLiveCallActionChange = ({ target }: RadioChangeEvent) => {
    const value: LiveCallAddOnType = target?.value;
    setLiveCallAction(value);
    triggerLiveCallAction(value);
  };

  return {
    showCoachActions,
    liveCallAction,
    onLiveCallActionChange,
    handleLeaveCoaching,
    showNotifyAgentConfirmation,
    handleCoachClick,
    showCoachingInProgressModal,
    toggleCoachingInProgressModal,
    liveCall,
    showPhoneWidget,
    allowLiveCallActions,
    accessLiveWhispering,
    LIVE_CALL_ACTIONS_OPTIONS,
    isAnotherCoachingInProgress,
    confirmLiveCallAction,
    recordSid,
  };
};
