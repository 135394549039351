export default function User3Icon({ bgColor = '#ECE6F2', color = '#857F96', className = '' }) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`w-full h-full ${className}`}
    >
      <rect width='36' height='36' rx='14' fill={bgColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9056 18.122C22.599 18.122 22.3315 17.8962 22.2873 17.5837C22.2398 17.2412 22.4773 16.9254 22.8198 16.8779C23.6865 16.7562 24.3406 16.0045 24.3423 15.1279C24.3423 14.2587 23.719 13.5237 22.8598 13.3829C22.5198 13.327 22.289 13.0054 22.3448 12.6645C22.4015 12.3237 22.7206 12.0954 23.0631 12.1495C24.5281 12.3904 25.5923 13.6437 25.5923 15.1295C25.589 16.6254 24.4715 17.9087 22.9923 18.1162C22.9631 18.1204 22.934 18.122 22.9056 18.122'
        fill={color}
      />
      <mask
        id='mask0_5064_70610'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='23'
        y='19'
        width='4'
        height='5'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M23.8151 19.668H26.9767V23.4427H23.8151V19.668Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_5064_70610)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M25.3845 23.4427C25.1328 23.4427 24.8953 23.2893 24.8003 23.0402C24.677 22.7177 24.8395 22.356 25.162 22.2335C25.727 22.0185 25.727 21.781 25.727 21.6668C25.727 21.3018 25.2628 21.0468 24.3478 20.9102C24.007 20.8585 23.7711 20.541 23.822 20.1985C23.8728 19.8568 24.1895 19.6293 24.5336 19.6735C26.5528 19.976 26.977 20.9243 26.977 21.6668C26.977 22.1202 26.7986 22.9477 25.607 23.4018C25.5336 23.4293 25.4586 23.4427 25.3845 23.4427'
          fill={color}
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9055 21.2617C15.9255 21.2617 13.5647 21.505 13.5647 22.6633C13.5647 23.8308 15.9255 24.0758 17.9055 24.0758C19.8855 24.0758 22.2455 23.8333 22.2455 22.6775C22.2455 21.5075 19.8855 21.2617 17.9055 21.2617M17.9055 25.3258C16.523 25.3258 12.3147 25.3258 12.3147 22.6633C12.3147 20.0117 16.523 20.0117 17.9055 20.0117C19.288 20.0117 23.4955 20.0117 23.4955 22.6775C23.4955 25.3258 19.4347 25.3258 17.9055 25.3258'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9056 12.5833C16.4956 12.5833 15.3489 13.7308 15.3489 15.1408C15.3464 15.8241 15.6081 16.4633 16.0872 16.9458C16.5664 17.4283 17.2047 17.6958 17.8847 17.6983L17.9056 18.3233V17.6983C19.3156 17.6983 20.4631 16.5516 20.4631 15.1408C20.4631 13.7308 19.3156 12.5833 17.9056 12.5833M17.9056 18.9483H17.8831C16.8664 18.945 15.9139 18.5466 15.2006 17.8266C14.4856 17.1075 14.0947 16.1525 14.0989 15.1383C14.0989 13.0416 15.8064 11.3333 17.9056 11.3333C20.0056 11.3333 21.7131 13.0416 21.7131 15.1408C21.7131 17.24 20.0056 18.9483 17.9056 18.9483'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9047 18.1221C12.8764 18.1221 12.8472 18.1204 12.818 18.1162C11.3389 17.9087 10.2222 16.6254 10.2189 15.1312C10.2189 13.6437 11.283 12.3904 12.748 12.1496C13.0989 12.0946 13.4097 12.3254 13.4664 12.6646C13.5222 13.0054 13.2914 13.3271 12.9514 13.3829C12.0922 13.5237 11.4689 14.2587 11.4689 15.1296C11.4705 16.0046 12.1247 16.7571 12.9905 16.8779C13.333 16.9254 13.5705 17.2412 13.523 17.5837C13.4789 17.8962 13.2114 18.1221 12.9047 18.1221'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4267 23.4426C10.3525 23.4426 10.2775 23.4293 10.2042 23.4018C9.01171 22.9468 8.83337 22.1193 8.83337 21.6668C8.83337 20.9251 9.25754 19.976 11.2775 19.6735C11.6217 19.6301 11.9367 19.8568 11.9884 20.1985C12.0392 20.541 11.8034 20.8585 11.4625 20.9101C10.5475 21.0468 10.0834 21.3018 10.0834 21.6668C10.0834 21.781 10.0834 22.0176 10.6492 22.2335C10.9717 22.356 11.1342 22.7176 11.0109 23.0401C10.9159 23.2893 10.6784 23.4426 10.4267 23.4426'
        fill={color}
      />
    </svg>
  );
}
