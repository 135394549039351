import {
  WORKSPACE_DETAIL_BAR_WIDTH,
  WORKSPACE_DETAIL_BAR_WIDTH_MOBILE,
  WORKSPACE_LIST_WIDTH,
} from 'constants/commons';
import useScreenType from 'hooks/useScreenType';
import React from 'react';
import styled from 'styled-components';

export interface AuxProps {
  children: React.ReactNode;
}

const Wrapper = styled.div<Record<string, any>>`
  height: inherit;
  width: ${props => props.sidebarwidth}px;
  background: ${({ theme }) => theme.colors.primary.p0};
  z-index: 99;
  position: fixed;
  & + main,
  & + div.server-error {
    min-width: calc(100vw - ${props => props.widthtocutoff}px);
    margin-left: ${props => props.sidebarwidth}px;
  }

  .content-wrapper {
    overflow-x: hidden;
  }
  .sideWorkspaceBar-label {
    margin-left: 3px;
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
  }
`;

const SidebarWrapper = ({ children }: AuxProps) => {
  const { isLaptop, isTablet, isMobile } = useScreenType();
  const sidebarwidth =
    isLaptop || isTablet || isMobile
      ? WORKSPACE_DETAIL_BAR_WIDTH_MOBILE
      : WORKSPACE_DETAIL_BAR_WIDTH;

  let widthToCutOffFromMain = WORKSPACE_LIST_WIDTH + WORKSPACE_DETAIL_BAR_WIDTH;

  if (isLaptop || isTablet || isMobile) widthToCutOffFromMain = WORKSPACE_LIST_WIDTH;
  return (
    <Wrapper sidebarwidth={sidebarwidth} widthtocutoff={widthToCutOffFromMain}>
      {children}
    </Wrapper>
  );
};

export default SidebarWrapper;
