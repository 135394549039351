import { ReactElement } from 'react';
import { ManIcon } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { UserAvatar } from 'components/atoms/avatar';

import { useTwilioContext } from 'lib/twilio';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

export function ClientInfo(): ReactElement {
  const { clientInfo } = useCallWidgetContext();
  const { phoneFormatIntl } = parsePhoneNumber(clientInfo?.number ?? '');
  const {
    state: { connection },
  } = useTwilioContext();

  // campaign call contact name
  const contactName = connection?.customParameters?.get('contactName');
  return (
    <div className='flex flex-col items-center'>
      <div className='mt-10'>
        <UserAvatar
          shape='square'
          size={43}
          icon={<ManIcon />}
          src={clientInfo?.profilePicture}
          style={{ minWidth: 36, borderRadius: 14 }}
        />
      </div>
      <p className='mt-5 text-gray-900 text-lg leading-4.25 font-semibold font-manrope truncate max-w-200 mb-0'>
        {contactName || clientInfo?.name || phoneFormatIntl || 'Unknown'}
      </p>
    </div>
  );
}
