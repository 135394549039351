import { KeyButtonContainer } from './Styles';
import { KeyPadButtonType } from './constants';

interface IKeypadButtonProps {
  onClick?: (...args: any[]) => void;
  variant?: 'primary-success' | 'default' | 'tertiary';
  className?: string;
  character?: KeyPadButtonType;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function KeypadButton({
  onClick,
  variant = 'default',
  className = '',
  character,
  children,
  disabled = false,
}: IKeypadButtonProps) {
  return (
    <KeyButtonContainer
      type='button'
      className={`${variant} ${className ?? ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {character ? (
        <div>
          <h3>{character?.digit}</h3>
          {(character?.alphabet || character?.symbol) && (
            <p className='mt-0.5'>{character?.alphabet || character?.symbol}</p>
          )}
        </div>
      ) : (
        children
      )}
    </KeyButtonContainer>
  );
}
