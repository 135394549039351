import { useContext } from 'react';
import { SalesDialerContext } from '../SalesDialerContext';

interface ISalesDialerContext {
  visibleInsufficientCredit?: boolean;
  toggleInsufficientCredit?: () => void;
}

export default function useSalesDialerContext() {
  const widgetState = useContext<ISalesDialerContext>(SalesDialerContext);
  if (!widgetState) {
    throw new Error('useSalesDialerContext must be used within a SalesDialerProvider');
  }
  return widgetState;
}
