import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const REMOVE_CARD = gql`
  mutation removeCard($data: RemoveCardInputData!) {
    removeCard(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const MAKE_CARD_PRIMARY = gql`
  mutation makePrimaryCard($data: makePrimaryCardInputData!) {
    makePrimaryCard(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ADD_CARD = gql`
  mutation addCard($data: AddCardInput!) {
    addCard(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RECHARGE_CREDIT = gql`
  mutation purchaseCredit($data: PurchaseCreditInput!) {
    purchaseCredit(data: $data) {
      status
      data {
        amount
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($data: PaymentIntentInputData!) {
    createPaymentIntent(data: $data) {
      status
      data {
        id
        gatewayAccountId
        amount
        createdAt
        currencyCode
        expiresAt
        gateway
        modifiedAt
        object
        paymentMethodType
        status
        referenceId
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const NUMBER_PAYMENT_INTENT = gql`
  mutation numberPaymentIntent($data: NumberPaymentIntentData!) {
    numberPaymentIntent(data: $data) {
      status
      data {
        id
        gatewayAccountId
        amount
        createdAt
        currencyCode
        expiresAt
        gateway
        modifiedAt
        object
        paymentMethodType
        status
        referenceId
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
