import React from 'react';
import { Controller } from 'react-hook-form';
import { RiCloseLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import { Label, MultiEmailChip, Error } from 'components/atoms';
import { InputFieldWrapper, FlexColumn } from './styled';
import { InputProps } from './types';

export interface ChipControllerProps {
  name: string;
  onChange: (emails: string[]) => void;
  value?: string | string[];
}
// const EmailChip = React.forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
const EmailChip: React.FC<InputProps> = props => {
  const { label, id, name, placeholder, required = false, errors, control, className } = props;
  const { t } = useTranslation();
  return (
    <>
      <InputFieldWrapper>
        <FlexColumn>
          {label && (
            <Label id={id || name} required={required}>
              {label}
            </Label>
          )}
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <MultiEmailChip
                  {...field}
                  emails={field.value as string[]}
                  placeholder={placeholder}
                  id={id || name}
                  className={className}
                  getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number, isDisabled: boolean) => void,
                  ) => {
                    return (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index, false)}
                          aria-hidden='true'
                        >
                          <RiCloseLine />
                        </span>
                      </div>
                    );
                  }}
                />
              );
            }}
          />
          {errors?.[name] && <Error>{t(`constants:${errors[name].message}`)}</Error>}
        </FlexColumn>
      </InputFieldWrapper>
    </>
  );
};

EmailChip.displayName = 'EmailChip';
export default React.memo(EmailChip);
