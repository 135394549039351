export default function Document({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0963 14.1445H7.07959C6.73459 14.1445 6.45459 13.8645 6.45459 13.5195C6.45459 13.1745 6.73459 12.8945 7.07959 12.8945H13.0963C13.4413 12.8945 13.7213 13.1745 13.7213 13.5195C13.7213 13.8645 13.4413 14.1445 13.0963 14.1445Z'
        fill='#4882F6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0963 10.6543H7.07959C6.73459 10.6543 6.45459 10.3743 6.45459 10.0293C6.45459 9.6843 6.73459 9.4043 7.07959 9.4043H13.0963C13.4413 9.4043 13.7213 9.6843 13.7213 10.0293C13.7213 10.3743 13.4413 10.6543 13.0963 10.6543Z'
        fill='#4882F6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.37542 7.17383H7.07959C6.73459 7.17383 6.45459 6.89383 6.45459 6.54883C6.45459 6.20383 6.73459 5.92383 7.07959 5.92383H9.37542C9.72042 5.92383 10.0004 6.20383 10.0004 6.54883C10.0004 6.89383 9.72042 7.17383 9.37542 7.17383Z'
        fill='#4882F6'
      />
      <mask
        id='mask0_17660_174677'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='2'
        y='1'
        width='16'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.5 1.66602H17.6372V18.2575H2.5V1.66602Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_17660_174677)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.257 2.91602L6.84951 2.91935C4.90951 2.93102 3.74951 4.13102 3.74951 6.13018V13.7935C3.74951 15.806 4.92035 17.0077 6.87951 17.0077L13.287 17.0052C15.227 16.9935 16.387 15.7918 16.387 13.7935V6.13018C16.387 4.11768 15.217 2.91602 13.257 2.91602ZM6.88034 18.2577C4.26034 18.2577 2.49951 16.4635 2.49951 13.7935V6.13018C2.49951 3.43602 4.20535 1.68518 6.84535 1.66935L13.2562 1.66602H13.257C15.877 1.66602 17.637 3.46018 17.637 6.13018V13.7935C17.637 16.4868 15.9312 18.2385 13.2912 18.2552L6.88034 18.2577Z'
          fill='#4882F6'
        />
      </g>
    </svg>
  );
}
