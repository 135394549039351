import { useState } from 'react';
import { Upload, Progress } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { getParsedCSV } from 'components/utils/helpers';
import * as S from 'components/organisms/common/Styles';

import { Icon } from 'components/atoms';
import { ImportModal, DownloadCSV } from './Styles';
import { ConfirmImportModal } from './ConfirmImportModal';

const { Dragger } = Upload;

interface IModalProps {
  isModalOpen: boolean;
  onModalCancel: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  width?: number | string;
}

const ImportContactModal = ({ isModalOpen, onModalCancel, width }: IModalProps) => {
  const { t } = useTranslation();
  const [importedContacts, setImportedContact] = useState<Record<string, string> | unknown>({});

  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [fileInfo, setFileInfo] = useState<Record<string, string>>();
  const [csvFile, setCsvFile] = useState<any>();

  const resetFileUpload = () => {
    setIsUploaded(false);
    setUploadPercent(0);
    setFileInfo({});
  };

  const uploadProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: async (info: any) => {
      const { status, percent } = info?.file;
      setUploadPercent(percent);
      setFileInfo(info?.file);
      // index to new uploaded file
      const fileIndex = info.fileList.length - 1;
      const data = await getParsedCSV(info?.fileList[fileIndex]?.originFileObj);
      setImportedContact(data);
      setCsvFile(info?.fileList[fileIndex]?.originFileObj);
      if (status === 'done') {
        setIsUploaded(true);
      }
      if (percent === 100) onModalCancel();
    },

    multiple: false,
    accept: '.csv, text/csv',
  };
  const dummyRequest = (props: any) => {
    setTimeout(() => {
      props?.onSuccess('ok');
    }, 2000);
  };

  return (
    <>
      <ImportModal
        width={width ?? 537}
        centered
        destroyOnClose
        visible={isModalOpen}
        closable={false}
        footer={false}
        onCancel={onModalCancel}
      >
        <h5 className='text-xl text-gray-700 font-bold leading-7 w-full text-center'>
          {t('uploadFile', 'Upload File')}
        </h5>
        <S.UploadArea className='mt-5 '>
          <Dragger showUploadList={false} {...uploadProps} customRequest={dummyRequest}>
            <Icon name='folder' />
            {fileInfo && Object.keys(fileInfo).length > 0 ? (
              <>
                <div>
                  <h3>{fileInfo?.name} </h3>
                  <S.UploadInstruction>
                    {t('uploadingStatus', 'Uploading file. please wait...')}
                  </S.UploadInstruction>
                </div>
                <Progress type='line' percent={uploadPercent} showInfo={false} />
              </>
            ) : (
              <S.UploadInstruction>
                {t(
                  'uploadContactInstruction',
                  'Drag and drop your CSV file here or click here to browse a file',
                )}
              </S.UploadInstruction>
            )}
          </Dragger>
        </S.UploadArea>
        <DownloadCSV
          className='mt-3 mx-auto'
          href={`${process.env.PUBLIC_URL}/files/sample.csv`}
          download
          target='_blank'
        >
          <Icon name='download-csv' />
          <span>
            <Trans i18nKey='downloadExampleCSV'>Download an example CSV file</Trans>
          </span>
        </DownloadCSV>
      </ImportModal>
      <ConfirmImportModal
        isUploaded={isUploaded}
        contacts={importedContacts}
        csvFile={csvFile}
        resetFileUpload={resetFileUpload}
      />
    </>
  );
};

export default ImportContactModal;
