import React from 'react';
import styled from 'styled-components';
import { LogoIcon } from '../icon';

const LoaderCentered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoaderWrapper = styled.div`
  .loader {
    border-radius: 8px;
    background: #fff;
    animation: rotate-vertical 5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  @keyframes rotate-vertical {
    0%,
    100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

  &.large {
    transform: scale(1);
  }
  &.small {
    transform: scale(0.5);
  }
`;

const CustomLoader = ({ children, size }: any) => (
  <LoaderWrapper className={` ${size}`}>
    <LogoIcon className='loader' />
    {children}
  </LoaderWrapper>
);

interface SpinnerProps {
  size?: 'small' | 'large' | 'default';
  children?: React.ReactNode;
  unmountContainer?: boolean;
  className?: string;
}

function Spinner({ size = 'large', children, unmountContainer = false, className }: SpinnerProps) {
  if (children) {
    return <CustomLoader size={size}>{children}</CustomLoader>;
  }
  if (unmountContainer) {
    return <CustomLoader size={size} />;
  }
  return (
    <>
      <LoaderCentered className={className}>
        <CustomLoader size={size} />
      </LoaderCentered>
    </>
  );
}
export default React.memo(Spinner);
