import { gql } from '@apollo/client';

export const FETCH_PLAN_DATA = gql`
  query planRestrictionData {
    planRestrictionData {
      status
      error {
        code
        message
      }
      data
    }
  }
`;
