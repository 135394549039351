import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const ADD_CHANNEL = gql`
  mutation addChannel($data: ChannelInputData!) {
    addChannel(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const RE_ORDER_CHANNEL = gql`
  mutation channelOrder($data: ChannelOrderInputData!) {
    channelOrder(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const SET_CHANNEL_DND = gql`
  mutation setChannelDnd($id: ShortId!, $minutes: Int) {
    setChannelDnd(id: $id, minutes: $minutes) {
      status
      data {
        dndEnabled
        dndEndtime
        dndRemainingTime
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const REMOVE_CHANNEL_DND = gql`
  mutation removeChannelDnd($id: ShortId!) {
    removeChannelDnd(id: $id) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHANNEL_CONVERSATION_SEEN = gql`
  mutation channelConversationSeen($channel: ShortId!) {
    channelConversationSeen(channel: $channel) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
