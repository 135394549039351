import { Maybe, Tag } from 'generated/graphql';
import { Icon } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';
import { hexToStringForTags } from 'components/utils/hexToStringForTags';

import { Checkbox } from 'components/molecules/fields';
import * as S from './Styles';

interface TagListProps {
  tags?: Maybe<Tag>[];
  handleTagSelect: (tag: Maybe<Tag>) => void;
  selectedTags?: Maybe<Tag>[];
}
export default function TagList({ tags, handleTagSelect, selectedTags }: TagListProps) {
  const TITLE_CHAR_LIMIT = 18;

  return (
    <>
      {(tags || []).length > 0 ? (
        <S.ListContainer>
          {tags?.map((tag: Maybe<Tag>) => {
            const { colorCode, title = '', id } = tag || {};
            const checked = selectedTags?.filter((data: Maybe<Tag>) => data?.id === tag?.id)
              ?.length;
            return (
              <div key={id} className='py-1.75'>
                <div className='flex '>
                  <div
                    className={`h-4.5 w-4.5 mr-2.5 rounded bg-tags-${
                      colorCode && hexToStringForTags(colorCode)
                    }`}
                  />
                  <h6 className='text-gray-600 font-semibold text-13 leading-4 capitalize truncate w-57'>
                    {nameElipsis(title ?? '', TITLE_CHAR_LIMIT)}
                  </h6>
                  <Checkbox
                    id={id}
                    name={title}
                    onChange={() => handleTagSelect(tag)}
                    checked={!!checked}
                  />
                </div>
              </div>
            );
          })}
        </S.ListContainer>
      ) : (
        <S.EmptyTagsContainer>
          <div>
            <Icon name='box-empty' />
          </div>
          <h4 className='text-gray-700 font-semibold text-sm leading-4.5 mt-3.5'>No Tags</h4>
        </S.EmptyTagsContainer>
      )}
    </>
  );
}
