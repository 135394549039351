import { gql } from '@apollo/client';

export const CONVERSATION_NODE = gql`
  fragment ConversationFields on ConversationNode {
    id
    contactClosed
    conversationStatus
    conversationType
    direction
    content {
      body
      duration
      transferedAudio
      transcript
    }
    createdAt
    modifiedAt
    clientNumber
    sms
    clientCountry
    status
    clientUnseenMsgCount
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
    }
    agentInfo {
      agentId
      firstname
      lastname
      profilePicture
    }
    personalizedInfo {
      pinned
      seen
      favourite
    }
    errorMessage
    medias {
      url
      size
      type
      name
    }
    smsForwardedNumber
    commentThread {
      id
      agentId
      comment
      status
      createdAt
      updatedAt
    }
  }
`;

export const RECENT_CONVERSATION_NODE = gql`
  fragment RecentConversationFields on RecentConversationNode {
    id
    contactClosed
    contactPinned
    clientNumber
    clientCountry
    conversationType
    conversationStatus
    createdAt
    direction
    content {
      body
      duration
      transferedAudio
      transcript
    }
    sms
    status
    clientUnseenMsgCount
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
      dndInfo {
        dndDuration
        dndEnabled
        dndEndtime
      }
      blocked
    }
    agentInfo {
      agentId
      firstname
    }
    medias {
      url
      size
      type
      name
    }
  }
`;

export const CONTACT_PINNED_CONVERSATION_NODE = gql`
  fragment PinnedConversationFields on PinnedConversationNode {
    id
    contactClosed
    contactPinned
    clientNumber
    clientCountry
    conversationType
    conversationStatus
    createdAt
    direction
    content {
      body
      duration
      transferedAudio
      transcript
    }
    sms
    status
    clientUnseenMsgCount
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
      dndInfo {
        dndDuration
        dndEnabled
        dndEndtime
      }
      blocked
    }
    agentInfo {
      agentId
      firstname
    }
  }
`;
export const PINNED_CONVERSATION_NODE = gql`
  fragment PinnedConversationFields on ConversationNode {
    id
    conversationStatus
    conversationType
    direction
    content {
      body
      duration
      transferedAudio
      transcript
    }
    createdAt
    clientNumber
    sms
    clientInfo {
      id
      number
      name
      country
      createdBy
      profilePicture
    }
    agentInfo {
      agentId
      firstname
      lastname
      profilePicture
    }
    medias {
      url
      size
      type
      name
    }
  }
`;

export const AGENT_NODE = gql`
  fragment AgentFields on Agent {
    id
    firstname
    email
    role
    lastname
    photo
  }
`;
export const NUMBER_NODE = gql`
  fragment NumberFields on NumbersNode {
    id
    name
    number
    agents {
      id
      firstname
      lastname
      photo
    }
    callStrategy
    numberCheckoutPrice {
      setUpFee
      monthlyFee
      basePrice
    }
  }
`;
export const PENDING_NUMBER_NODE = gql`
  fragment PendingNumberResponse on PendingNumber {
    name
    number
    country
    setUpFee
    monthlyFee
    purchaseStatus
    purchase_status
    addressRequirement
    regulationSid
    call
    sms
    mms
    type
  }
`;
export const CHANNEL_NODE = gql`
  fragment ChannelFields on Channel {
    id
    countryLogo
    country
    countryCode
    number
    name
    dndEndtime
    dndEnabled
    dndRemainingTime
    dndOn
    dndDuration
    unseenMessageCount
    sms
    call
    mms
    numberCheckoutPrice {
      basePrice
    }
  }
`;
export const NONVOIP_CHANNEL_NODE = gql`
  fragment ChannelFields on Channel {
    id
    countryLogo
    country
    countryCode
    number
    name
    dndEndtime
    dndEnabled
    dndRemainingTime
    dndOn
    dndDuration
    unseenMessageCount
    sms
    call
    mms
    phoneNumberType
    numberCheckoutPrice {
      basePrice
    }
  }
`;

export const NUMBER_ABILITIES_NODE = gql`
  fragment NumberAbilitiesFields on NumberAbilities {
    call
    sms
    mms
  }
`;

export const CHECKOUT_NUMBER = gql`
  fragment CheckoutNumberFields on CheckoutNumber {
    name
    number
    country
    address
    setUpFee
    monthlyFee
    sms
    purchaseStatus
    mms
    call
    type
    status
  }
`;

export const NUMBER_CHECKOUT_PRICE = gql`
  fragment NumberCheckoutPriceFields on NumberCheckoutPrice {
    setUpFee
    monthlyFee
    basePrice
  }
`;
