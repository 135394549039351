import * as React from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: string | undefined;
}

const Icon: React.FC<IconProps> = ({ name, ...rest }): JSX.Element | null => {
  const ImportedIconRef = React.useRef<React.FC<React.SVGProps<SVGSVGElement>> | never>();
  const [loading, setLoading] = React.useState(false);
  // using useLayoutEffect to avoid flickering
  React.useLayoutEffect((): void => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current =
          name &&
          (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!assets/icons/${name}.svg`)).default;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }
  return null;
};
export default Icon;
