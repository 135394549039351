import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { DND_STATUS } from 'constants/commons';
import { ToastMessage } from 'components/atoms';
import { UPDATE_CLIENT_DND } from 'graphql/client';
import { NUMBERS } from 'constants/routes';
import { getAllUrlParams } from 'components/organisms/common/utils';

export default function useDNDMutation() {
  const { t } = useTranslation();
  const history = useHistory();
  const location: any = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const { channelId } = useParams<Record<string, string | undefined>>();
  const [updateClientDnd] = useMutation(UPDATE_CLIENT_DND, {
    onCompleted: args => {
      const { error, data } = args?.updateClientDND || {};
      if (error === null) {
        ToastMessage({
          content: `${t('toast.clientDndSuccess', 'Successfully updated DND status.')}`,
          type: 'success',
        });

        if (data?.id && urlParams?.phone === data?.number)
          history.push(`${NUMBERS}/${channelId}/${data.id}`);
        return;
      }
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
    onError: () => {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const selectDND = useCallback(
    async (dndValue: any, clientNumber: string) => {
      const dndMinutes = DND_STATUS[dndValue];
      await updateClientDnd({
        variables: {
          data: {
            contact: clientNumber,
            removeFromDND: dndValue === 'OFF',
            ...(dndValue !== 'UNTIL_I_RESUME' && { minutes: dndMinutes }),
          },
        },
        update(cache, { data }) {
          const cacheId = data?.updateClientDND?.data && cache.identify(data.updateClientDND.data);
          if (cacheId) {
            cache.modify({
              fields: {
                contact: () => {},
                recentConversationHistory: () => {},
              },
            });
          }
        },
      });
    },
    [updateClientDnd],
  );

  return { selectDND };
}
