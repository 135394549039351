import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const GET_VOICEMAIL_DROPS_LIST = gql`
  query getCampaignVoiceMailList @api(name: "salesDialer") {
    getCampaignVoiceMailList {
      status
      data {
        id
        ttsSource
        ttsVoice
        ttsAccent
        recordingType
        recordingUrl
        createdOn
        createdBy
        name
        isDefault
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
