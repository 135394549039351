import { gql } from '@apollo/client';
import { CHAT_NODE } from './fragments';

export const CHAT_SUBSCRIPTION = gql`
  subscription workspaceChat {
    workspaceChat {
      event
      message {
        ...ChatFields
      }
    }
  }
  ${CHAT_NODE}
`;
