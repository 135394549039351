import { css } from 'styled-components';

export const antMenu = css`
  .ant-menu > .ant-menu {
    background: #ffffff;
    border: 1px solid #e7e6eb;
    box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
    border-radius: 8px;
    padding: 10px 0;
  }
  .ant-menu .ant-menu-submenu {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    svg {
      color: #6e6681;
    }
  }
  .ant-menu ul {
    margin: 0;
  }

  .ant-menu > .ant-menu-item,
  .ant-menu-submenu > .ant-menu-submenu-title {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #6e6681;
    padding: 9px 16px;
    height: auto;
    margin: 0 !important;
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      background: #ffffff;
      color: #6e6681;
    }
    &:hover {
      background: #f3f2f4;
      color: #564d6d;
    }
    &.resume {
      color: #1a63f4;
    }
    > svg {
      display: inline;
    }
    > span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: auto;
  }

  .ant-menu .ant-menu-item-selected.active-dnd {
    background: #f3f2f4;
    color: #564d6d;
  }

  .ant-dropdown-menu .active-dnd,
  .ant-menu-sub .active-dnd {
    background: #f3f2f4;
    color: #564d6d;
  }

  .ant-dropdown-menu-submenu > .ant-dropdown-menu-submenu-title {
    > svg {
      display: inline;
      margin-right: 10px;
    }
  }

  .ant-menu > .ant-menu-item-divider {
    margin: 4px 0;
    box-shadow: inset 0px 0.5px 0px #cfccd5;
    background: none;
  }
`;
