export default function Logouticon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='14' fill='none' viewBox='0 0 15 14'>
      <path
        fill='#6E6681'
        fillRule='evenodd'
        d='M6.547 13.667H3.289A2.96 2.96 0 01.334 10.71V3.29A2.96 2.96 0 013.289.334h3.25a2.96 2.96 0 012.958 2.958v.621a.5.5 0 01-1 0v-.621a1.96 1.96 0 00-1.958-1.958H3.29a1.958 1.958 0 00-1.955 1.958v7.419c0 1.08.877 1.957 1.955 1.957h3.258c1.074 0 1.95-.875 1.95-1.95v-.628a.5.5 0 011 0v.628a2.953 2.953 0 01-2.95 2.95'
        clipRule='evenodd'
      />
      <mask
        id='mask0_360:1586'
        style={{ maskType: 'alpha' }}
        width='11'
        height='2'
        x='4'
        y='6'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#fff' fillRule='evenodd' d='M4.998 6.5h9.027v1H4.998v-1z' clipRule='evenodd' />
      </mask>
      <g mask='url(#mask0_360:1586)'>
        <path
          fill='#6E6681'
          fillRule='evenodd'
          d='M13.525 7.5H5.498a.5.5 0 010-1h8.027a.5.5 0 010 1'
          clipRule='evenodd'
        />
      </g>
      <mask
        id='mask1_360:1586'
        style={{ maskType: 'alpha' }}
        width='4'
        height='6'
        x='11'
        y='4'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M11.073 4.557h2.952v4.887h-2.952V4.557z'
          clipRule='evenodd'
        />
      </mask>
      <g mask='url(#mask1_360:1586)'>
        <path
          fill='#6E6681'
          fillRule='evenodd'
          d='M11.573 9.444a.5.5 0 01-.353-.854L12.816 7 11.22 5.41a.5.5 0 11.706-.707l1.952 1.943a.497.497 0 010 .708l-1.952 1.944a.498.498 0 01-.353.146'
          clipRule='evenodd'
        />
      </g>
    </svg>
  );
}
