/**
 * * @function debounce
 * * @template T
 * * @param {T} callback - The callback function to be debounced
 * * @param {number} waitFor - The wait time in milliseconds before the callback is executed
 * * @returns {(...args: Parameters<T>) => ReturnType<T>} - A debounced version of the callback function
 * * @description This function creates a debounced version of a callback function. When the debounced
 * * function is invoked, it will only execute the callback after a specified wait time has passed
 * * since the last invocation. If the debounced function is invoked multiple times before the wait
 * * time has passed, the timer will be reset, and the callback will only be invoked once the wait
 * * time has passed since the last invocation.
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 */

const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};
export default debounce;
