import React from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';

const Input = styled(AntInput)`
  background: #f5f2f8;
  border: 1px solid #d7cce4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 16px;
  &:focus {
    box-shadow: none;
    background: none;
    border-color: #390179;
  }
`;
export default React.memo(Input);
