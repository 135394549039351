import { useEffect, useRef } from 'react';
import { notification } from 'antd';
import { RiWifiFill, RiWifiOffFill } from 'react-icons/ri';
import { useReactiveVar } from '@apollo/client';

import { isNetworkAvailable, userOnlineStatus } from 'services/apollo/reactiveVars';

export default function withNetworkDetector(ComposedComponent: React.ComponentType) {
  const NetworkDetector = (props: any) => {
    const internetAvailableRef: any = useRef();
    const internetAvailable = useReactiveVar(isNetworkAvailable);
    internetAvailableRef.current = internetAvailable;
    const pingUrl = process.env.REACT_APP_PING_API_ENDPOINT || '/';
    const checkOnlineStatus = async () => {
      try {
        const response = await fetch(pingUrl, {
          cache: 'no-store',
          mode: 'no-cors',
          method: 'HEAD',
        });
        const successResponse = response.ok || response.type === 'opaque';
        if (successResponse !== internetAvailableRef.current) {
          notification.open({
            message: 'Connection successfully restored.',
            icon: <RiWifiFill />,
            className: 'connection-online',
            placement: 'bottomLeft',
            closeIcon: <>no</>,
          });
        }
        isNetworkAvailable(successResponse);
      } catch (error) {
        if (internetAvailableRef.current === true) {
          notification.open({
            message: 'KrispCall is trying to re-connect',
            icon: <RiWifiOffFill />,
            className: 'connection-offline',
            placement: 'bottomLeft',
            closeIcon: <></>,
          });
          isNetworkAvailable(false);
          userOnlineStatus(false);
        }
      }
    };

    useEffect(() => {
      const intervalId = setInterval(checkOnlineStatus, 5000);
      return () => clearInterval(intervalId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ComposedComponent {...props} />;
  };

  return NetworkDetector;
}
