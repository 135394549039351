import { memo, useContext, useEffect } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import { useTwilioContext } from 'lib/twilio';
import { AuthContext } from 'contexts/auth/AuthContext';
import { WIDGET_HEADER_HEIGHT, WIDGET_HEIGHT, CAMPAIGN_WIDGET_HEIGHT } from './constants';
import { WidgetWrapper } from './Styles';
import { useCallWidgetDragger } from './hooks/useCallWidgetDragger';
import WidgetHeader from './widget-header';
import WidgetBody from './WidgetBody';
import useCallWidgetContext from './hooks/useCallWidgetContext';

const Wrapper = styled.div<any>`
  /* min-height: ${props => {
    return props.salesDialerWidget
      ? `calc(${WIDGET_HEIGHT}px + ${CAMPAIGN_WIDGET_HEIGHT}px) !important`
      : `${WIDGET_HEIGHT}px !important`;
  }}; */
  > section {
    display: flex;
    height: ${props => {
      return props.salesDialerWidget
        ? `calc(${WIDGET_HEIGHT}px + ${CAMPAIGN_WIDGET_HEIGHT}px - ${WIDGET_HEADER_HEIGHT}px)`
        : `calc(${WIDGET_HEIGHT}px - ${WIDGET_HEADER_HEIGHT}px)`;
    }};
  }
`;

function Widget() {
  const {
    state: { salesDialerWidget },
  } = useTwilioContext();
  const { draggableProps } = useCallWidgetDragger();

  const { showKycUnverifiedBanner } = useCallWidgetContext();

  const { isKYCVerified } = useContext(AuthContext);

  useEffect(() => {
    if (!isKYCVerified) {
      showKycUnverifiedBanner?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKYCVerified]);

  return (
    <Draggable {...draggableProps}>
      <WidgetWrapper className='overflow-hidden' salesDialerWidget={salesDialerWidget}>
        <Wrapper
          data-cy='d-wrapper'
          className='relative h-full'
          salesDialerWidget={salesDialerWidget}
        >
          <WidgetHeader />
          <WidgetBody />
        </Wrapper>
      </WidgetWrapper>
    </Draggable>
  );
}

export default memo(Widget);
