import { ReactNode } from 'react';

export enum BannerType {
  info = 'info',
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export type Banner = {
  title?: string;
  type?: BannerType;
  icon?: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
};
