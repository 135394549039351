export default function useOptions() {
  const styles = {
    base: {
      color: '#3d3358',
      fontWeight: '400',
      fontFamily: 'Heebo, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',

      ':focus': {
        boxShadow: 'none',
        background: 'none',
        borderColor: '#390179',
      },

      '::placeholder': {
        color: '#9293AB',
        fontSize: '14px',
      },

      ':focus::placeholder': {
        color: '#666',
      },
    },
    invalid: {
      color: 'red',

      ':focus': {
        color: 'red',
      },
      '::placeholder': {
        color: 'red',
      },
    },
  };
  const classes = {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  };

  return {
    styles,
    classes,
  };
}
