import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTwilioContext } from 'lib/twilio';

import { GET_CLIENT_INFO_BY_PHONE } from 'graphql/client';

interface IProps {
  number?: string;
}

export function useClientQuery({ number }: IProps) {
  const {
    state: { connection, status, outgoingCallParams },
  } = useTwilioContext();

  const [getClientInfo, { data }] = useLazyQuery(GET_CLIENT_INFO_BY_PHONE, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (status === 'initiated' && number) {
      getClientInfo({
        variables: {
          number,
        },
      });
    }
  }, [getClientInfo, status, connection, number]);

  const { data: clientDetail } = data?.clientDetail || {};
  const contactNumber = connection?.customParameters?.get('contact_number');
  const unknownClientInfo = {
    number: contactNumber || number || outgoingCallParams?.To,
  };

  const clientInfo = clientDetail?.number ? clientDetail : unknownClientInfo;

  return {
    clientInfo,
  };
}
