import styled from 'styled-components';
import { Avatar } from 'antd';

export const ProfilePicture = styled(Avatar)<any>`
  border-radius: inherit;
  cursor: pointer;
`;

export const ProfileContainer = styled.div`
  width: 72px;
  height: 72px;
  background: ${({ theme }) => theme.colors.primary.p0};
  border-radius: 25px;
  position: relative;

  &.team-profile-pic,
  &.general-profile-pic {
    width: 48px;
    height: 48px;
    border-radius: 18px;
    padding: 9px;
    .ant-avatar {
      border-radius: 12px;
    }
  }
`;
export const BannerInfo = styled.div`
  position: relative;
  .icon {
    @media screen and (max-width: 767px) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;
export const ProfileExtra = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.primary.p1};
  border: 1px dashed ${({ theme }) => theme.colors.primary.default};
  border-radius: 9px;
  bottom: -4px;
  right: -4px;
  svg {
    width: 24px;
    height: 24px;
    padding: 6px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary.default};
  }
  &.team-profile-extra {
    right: -9px;
  }
`;
