import { ReactElement } from 'react';
import { Avatar } from 'antd';
import { RiCloseLine } from 'react-icons/ri';

import { parsePhoneNumber } from 'components/utils/phone-lib';
import { Tooltip } from 'components/atoms';
import { useTwilioContext } from 'lib/twilio';
import { useCampaignHandlers } from 'components/pages/sales-dialer/campaigns/hooks/useCampaignHandlers';
import { ACTIONS, CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { ChannelInfoWrapper } from './Styles';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

export default function WidgetHeader(): ReactElement {
  const { channelInfo } = useCallWidgetContext();
  const {
    state: { campaignStatus, connection },
    dispatch,
  } = useTwilioContext();
  const { handleCampaignRun } = useCampaignHandlers();
  const { number = '', name = '' } = channelInfo || {};
  const { flagUrl, alphaTwoCode } = parsePhoneNumber(number);
  const campaignId = connection?.customParameters?.get('campaignId');
  const { PAUSED } = CALL_WIDGET_STATUS;

  const handleClose = () => {
    if (campaignId) {
      dispatch({
        type: ACTIONS.CAMPAIGN_STOPPED,
      });
      // handleCampaignRun(campaignId, CAMPAIGN_ACTIONS.end);
    }
  };
  return (
    <ChannelInfoWrapper className='pl-12 pr-4 h-10 bg-primary flex items-center text-white w-80 -m-px mb-0 rounded-t-xl'>
      <div className=' flex flex-1 items-center'>
        <div className='avatar-container mr-1.5'>
          <Avatar shape='square' className='avatar' src={flagUrl} alt={alphaTwoCode} />
        </div>
        <h5 className='text-gray-100 text-13 leading-5 font-semibold truncate max-w-200'>{name}</h5>
      </div>
      {/* show close icon only if the voice call or campaign call is paused */}
      {campaignStatus && [PAUSED].includes(campaignStatus) && (
        <Tooltip title='Close' placement='top'>
          <RiCloseLine onClick={handleClose} className='cursor-pointer' />
        </Tooltip>
      )}
    </ChannelInfoWrapper>
  );
}
