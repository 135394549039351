import { Dispatch, SetStateAction, useContext } from 'react';
import { CallWidgetDrawerContext } from '../context/CallWidgetDrawerContext';

interface ICallWidgetDrawerContext {
  selectedDrawer: string | null;
  setSelectedDrawer: Dispatch<SetStateAction<string | null>>;
  closeDrawer: () => void;
  saveButtonLabel: string;
  setSaveButtonLabel: Dispatch<SetStateAction<string | undefined>>;
  onSaveChangeSuccess: () => void;
}

export default function useCallWidgetDrawerContext() {
  const widgetDrawerState = useContext<ICallWidgetDrawerContext>(CallWidgetDrawerContext);
  if (!widgetDrawerState) {
    throw new Error('useCallWidgetDrawerContext must be used within a CallWidgetProvider');
  }
  return widgetDrawerState;
}
