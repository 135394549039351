import { useState, useEffect } from 'react';
import { activeCallDurationVar } from 'services/apollo/reactiveVars';

interface IProps {
  startTimer: boolean;
  stopTimer: boolean;
}

export function useCallDurationTimer({ startTimer, stopTimer }: IProps) {
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setminutes] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (startTimer && !stopTimer) {
        setSeconds((second: number) => second + 1);
        setDuration((second: number) => second + 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [startTimer, stopTimer]);

  if (seconds >= 60) {
    setSeconds(0);
    setminutes(minutes + 1);
  }

  const resetCallDurationTime = () => {
    setSeconds(0);
    setminutes(0);
    setDuration(0);
  };

  useEffect(() => {
    if (duration) {
      activeCallDurationVar(duration);
    }
    return () => {
      activeCallDurationVar(0);
    };
  }, [duration]);

  const minStr = minutes < 10 ? `0${minutes}` : minutes;
  const secStr = seconds < 10 ? `0${seconds}` : seconds;
  const callDurationTime = `${minStr}:${secStr}`;

  return { callDurationTime, seconds, minutes, duration, resetCallDurationTime };
}
