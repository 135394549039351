import { ReactElement, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ContactInputData } from 'generated/graphql';
import { Input } from 'components/molecules/fields';
import { useAddContactMutation } from 'components/organisms/contact/modals/useAddContactMutation';
import { NUMBERS } from 'constants/routes';
import { ToastMessage } from 'components/atoms';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { MAX_LENGTH } from 'schema-validation';
import UploadPic from 'components/molecules/upload-pic';
import { BriefCase, Email, Location, Phone, UserSquareStroke } from 'components/atoms/icon';
import { customEncodeURIComponent } from 'components/utils/url';

import { schema } from './schema';
import * as S from './Styles';
import SaveDiscardActionBlock from '../save-discard-action';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import { BannerType } from '../../types';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

interface IContactAddProps {
  defaultNumber?: string | null;
}

export default function ContactAdd({ defaultNumber }: IContactAddProps): ReactElement {
  const [imageFileObj, setImageFileObj] = useState<any>(null);
  const childRef: any = useRef(null);

  const onImgUpload = (img: any) => {
    setImageFileObj(img);
  };

  const { showBannerLikeToast } = useCallWidgetContext();
  const { closeDrawer, saveButtonLabel, onSaveChangeSuccess } = useCallWidgetDrawerContext();
  const { clientInfo, channelInfo } = useActiveCallWidgetContext();
  const { number } = clientInfo || {};
  const history = useHistory();
  const location = useLocation();
  const phoneNumber = defaultNumber?.replace(/\+/g, '') || number?.replace(/\+/g, '') || '';
  const { phoneFormatIntl } = parsePhoneNumber(phoneNumber);

  const onAddContact = (contact: Client) => {
    onSaveChangeSuccess();
    showBannerLikeToast?.('New contact added.', BannerType.info);
    const { pathname } = location;
    if (
      pathname.includes(
        `${channelInfo?.id}/new/?phone=${customEncodeURIComponent(clientInfo?.number)}`,
      )
    ) {
      history.push(`${NUMBERS}/${channelInfo?.id}/${contact?.id}`);
    }
  };
  const onAddContactError = (message?: string) => {
    showBannerLikeToast?.(message ?? '', BannerType.error);
  };

  const { addContact, loading } = useAddContactMutation({
    onAddContact,
    onAddContactError,
    imageFile: imageFileObj,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ContactInputData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: null,
      email: '',
      company: '',
      address: '',
    },
  });

  const formSubmit = async (val: any) => {
    const data = {
      name: val.name || `+${phoneNumber}`,
      email: val.email,
      number: val.number || phoneNumber,
      company: val.company,
      address: val.address,
      visibility: true,
    };
    if (val.name?.trim()?.length > MAX_LENGTH) {
      ToastMessage({
        content: `Shouldn't exceed ${MAX_LENGTH} characters`,
        type: 'danger',
      });
      return;
    }
    await addContact(data, setError);
  };
  const FORM_ID = 'add-contact-form';
  const phoneNumberToDisplay = phoneFormatIntl || `+${phoneNumber.replace(/\+/g, '')}`;

  return (
    <S.FormContainer
      className='flex flex-col h-full px-4 pb-4'
      id={FORM_ID}
      onSubmit={handleSubmit(formSubmit)}
    >
      <UploadPic
        data-cy='contacts-add-upload-pic'
        ref={childRef}
        position='center'
        onImgUpload={onImgUpload}
        type='user'
        size='small'
        remove={false}
      />
      <div className='mt-4'>
        <Input
          prefix={<UserSquareStroke />}
          name='name'
          id='name'
          placeholder='Contact Name'
          control={control}
          errors={errors}
        />

        <S.DisabledInputBox>
          <Phone />
          <p>{phoneNumberToDisplay}</p>
        </S.DisabledInputBox>

        <Input
          prefix={<Email />}
          name='email'
          id='email'
          placeholder='Email Address'
          control={control}
          errors={errors}
        />
        <Input
          prefix={<BriefCase />}
          name='company'
          id='company'
          placeholder='Company'
          control={control}
          errors={errors}
        />
        <Input
          prefix={<Location />}
          name='address'
          id='address'
          placeholder='Address'
          control={control}
          errors={errors}
        />
      </div>
      <SaveDiscardActionBlock
        onDiscard={closeDrawer}
        htmlType='submit'
        formId={FORM_ID}
        saveButtonLabel={saveButtonLabel}
        isSaveLoading={loading}
      />
    </S.FormContainer>
  );
}
