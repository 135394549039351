/* eslint-disable no-restricted-syntax */

export type AvailableAvatarSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';

export type AvailableShape = 'circle' | 'square';

type MapSizeToProp = {
  offset: [number, number];
  avatarSize: number;
  dotSize: number;
  shadowWidth: number;
  fontSize: number;
};

export const mapSizeToProp: Record<string, MapSizeToProp> = {
  sm: {
    offset: [-4, 21],
    avatarSize: 24,
    dotSize: 6,
    shadowWidth: 2,
    fontSize: 12,
  },
  md: {
    offset: [-4, 27],
    avatarSize: 32,
    dotSize: 8,
    shadowWidth: 3,
    fontSize: 14,
  },
  lg: {
    offset: [-4, 32],
    avatarSize: 36,
    dotSize: 9,
    shadowWidth: 3,
    fontSize: 14,
  },
  xl: {
    offset: [-4, 35],
    avatarSize: 40,
    dotSize: 10,
    shadowWidth: 4,
    fontSize: 16,
  },
  '2xl': {
    offset: [-4, 43],
    avatarSize: 48,
    dotSize: 10,
    shadowWidth: 5,
    fontSize: 17,
  },
  '3xl': {
    offset: [-7, 57],
    avatarSize: 64,
    dotSize: 16,
    shadowWidth: 5,
    fontSize: 23,
  },
  '4xl': {
    offset: [-9, 70],
    avatarSize: 80,
    dotSize: 20,
    shadowWidth: 8,
    fontSize: 34,
  },
};

export type AvatarBgColor =
  | 'redGradient'
  | 'orangeGradient'
  | 'yellowGradient'
  | 'greenGradient'
  | 'turqoise'
  | 'blueGradient'
  | 'krispcallGradientA'
  | 'krispcallGradientB'
  | 'krispcallGradientC'
  | 'pinkGradient';

export const mapBgToProp: Record<string, string> = {
  redGradient: '#EF473A',
  orangeGradient: 'linear-gradient(180deg, #F27121 0%, #E95E40 100%)',
  yellowGradient: 'linear-gradient(180deg, #FDC830 0%, #FF884F 100%)',
  greenGradient: 'linear-gradient(180deg, #38EF7D 0%, #32CDC1 100%)',
  turqoiseGradient: 'linear-gradient(180deg, #A6FFCB 0%, #67C0FF 100%)',
  blueGradient: 'linear-gradient(180deg, #00C6FF 0%, #58A3FF 100%)',
  krispcallGradientA: 'linear-gradient(180deg, #8E2DE2 0%, #6927ED 100%)',
  krispcallGradientB: 'linear-gradient(180deg, #DD7DFF 0%, #A23DDF 100%)',
  krispcallGradientC: 'linear-gradient(360deg, #A64CED 0%, #FE63AE 100%)',
  pinkGradient: 'linear-gradient(180deg, #FFA9FD 0%, #ED84EC 100%)',
};

export const getColorForCharacter = (char?: string) => {
  const charToColor: any = {
    redGradient: 'ABC',
    orangeGradient: 'DEF',
    yellowGradient: 'GHI',
    greenGradient: 'JKL',
    turqoiseGradient: 'MNO',
    blueGradient: 'PQR',
    krispcallGradientA: 'STU',
    krispcallGradientB: 'VWX',
    krispcallGradientC: 'YZ',
  };
  if (!char) return 'pinkGradient';
  for (const color in charToColor) {
    if (charToColor[color].includes(char.toUpperCase())) {
      return color;
    }
  }
  return 'pinkGradient';
};
