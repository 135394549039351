import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { CONTACT } from 'graphql/client';
import { getAllUrlParams } from 'components/organisms/common/utils';

interface IProps {
  contactId?: string;
}

export function useContactQuery({ contactId }: IProps) {
  const location: any = useLocation();
  const urlParams = getAllUrlParams(location?.search);
  const phoneNumberInUrlParams = decodeURIComponent(urlParams?.phone);

  const [loadContact, { data, loading }] = useLazyQuery(CONTACT, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (contactId && contactId !== 'new' && contactId !== 'new-conversation') {
      loadContact({
        variables: {
          id: contactId,
        },
      });
    }
  }, [contactId, loadContact]);

  const unknownContactInfo = {
    number: phoneNumberInUrlParams,
    name: phoneNumberInUrlParams,
  };

  const knownContactInfo = useMemo(() => data?.contact?.data ?? {}, [data]);
  const contactData =
    urlParams?.phone || contactId === 'new-conversation' ? unknownContactInfo : knownContactInfo;
  const contactNumber =
    contactId !== 'new-conversation' &&
    (urlParams?.phone ? phoneNumberInUrlParams : data?.contact?.data?.number);

  return {
    contactData,
    contactNumber,
    loading,
  };
}
