import { useState, useEffect } from 'react';

interface IProps {
  time: any;
}

export function useCoolOffTimer({ time }: IProps) {
  const [seconds, setSeconds] = useState(time);
  const [startTimer, setStartTimer] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0 && startTimer) {
        setSeconds((second: any) => second - 1);
        return;
      }
      setStartTimer(false);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, startTimer]);

  const startCoolOffTimer = () => {
    setStartTimer(true);
  };

  const resetCoolOffTime = (newTime: any) => {
    setStartTimer(false);
    setSeconds(newTime);
  };
  return { seconds, startCoolOffTimer, resetCoolOffTime };
}
