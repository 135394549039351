import { css } from 'styled-components';

export const antSubmenu = css`
  /* Screen-inbox/dropdown, submenu of Do not Disturb */

  /* Numbers dropdown */
  &.dnd-menu {
    padding-left: 20px;
    &--enabled {
      > .ant-menu {
        width: 250px !important;
      }
      .ant-divider {
        margin: 12px 0 4px 0;
      }
      .ant-menu-submenu-expand-icon {
        position: absolute !important;
        top: 17px !important;
      }
      .dnd-submenu--enabled {
        padding: 0;
        padding-left: 20px;
        box-shadow: none;
        top: 85px !important;
      }
    }
    .submenu--time {
      margin: 0;
      padding: 7px 0;
      svg {
        color: transparent;
        position: relative;
        top: 2px;
      }
      &.ant-menu-item-selected > svg {
        color: ${({ theme }) => theme.colors.text.t8};
      }
    }
  }

  /* Screen-settings/members, Submenu of Assign Numbers */
  .ant-menu-submenu-title {
    &:active {
      background: transparent;
      color: ${({ theme }) => theme.colors.text.t8};
    }
  }
  .assign-numbers--submenu {
    svg.ant-menu-submenu-expand-icon {
      position: absolute !important;
      top: 17px !important;
    }
  }

  /* New design codes */
  .ant-menu-submenu .ant-menu-submenu > .ant-menu-submenu-title {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #6e6681;
    padding: 9px 16px;
    &:hover {
      background: #f3f2f4;
      color: #564d6d;
    }
  }
`;
