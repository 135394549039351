import styled from 'styled-components';

const Container = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  column-gap: 1px;
  height: 16px;
  .box {
    height: 100%;
    width: 2px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.text.t7};
    transform: scaleX(0.56);
    animation: 2s wave ease-in-out infinite;
  }
  .delay-1 {
    animation-delay: 0.5s;
  }
  .delay-2 {
    animation-delay: 1s;
  }
  .delay-3 {
    animation-delay: 1.5s;
  }
  .delay-4 {
    animation-delay: 0.25s;
  }
  .delay-5 {
    animation-delay: 1.75s;
  }
  @keyframes wave {
    0% {
      transform: scale(0.56, 1);
    }
    50% {
      transform: scale(0.56, 0.18);
    }
    100% {
      transform: scale(0.56, 1);
    }
  }
`;
function AudioWave() {
  return (
    <>
      <Container>
        <div className='box' />
        <div className='box delay-1' />
        <div className='box delay-2' />
        <div className='box delay-3' />
        <div className='box delay-4' />
        <div className='box delay-2' />
        <div className='box delay-5' />
      </Container>
    </>
  );
}
export default AudioWave;
