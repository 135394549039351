import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 12px 16px;
`;

export const KeyButtonContainer = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in;
  :active,
  :focus {
    background: var(--Background-400, #edebee);
    box-shadow: inset 0px 0px 5px #cfccd5;
  }
  > div {
    > h3 {
      color: var(--Text-500-Component, #333036);
      font-size: 24px;
      font-weight: 450;
      line-height: 24px;
    }
    > p {
      color: var(--Text-300-Component-2, #66616b);
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 1.2px;
    }
  }

  /* variants */
  &.default,
  &.tertiary {
    background: var(--Background-300, #f5f4f6);
  }
  &.primary-success {
    background: var(--Success-300-Default, #10bc3b);
    color: white;
  }
  &.tertiary {
    color: #66616b;
  }
`;
