import { message } from 'antd';
import {
  RiCheckboxCircleFill,
  RiErrorWarningFill,
  RiNotification4Fill,
  RiNotificationOffFill,
} from 'react-icons/ri';

interface IToaster {
  content?: React.ReactNode | undefined | never;
  icon?: string | React.ReactNode | undefined | never;
  type?: string;
}

const getToasterFnAndIcon = (type: string) => {
  const toaster: any = {
    success: {
      toast: message.success,
      Icon: RiCheckboxCircleFill,
    },
    danger: {
      toast: message.error,
      Icon: RiErrorWarningFill,
    },
    'notification-success': {
      toast: message.success,
      Icon: RiNotification4Fill,
    },
    'notification-danger': {
      toast: message.error,
      Icon: RiNotificationOffFill,
    },
    warning: {
      toast: message.warning,
      Icon: RiErrorWarningFill,
    },
  };
  return toaster[type];
};
const ToastMessage = ({ content, icon, type }: IToaster) => {
  if (type) {
    const { toast, Icon } = getToasterFnAndIcon(type);
    return toast?.({
      content,
      icon: (
        <span>
          <Icon />
        </span>
      ),
    });
  }
  return message.info({
    content,
    icon,
  });
};

export default ToastMessage;
