function UserAddIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20'>
      <mask
        id='mask0_492:5738'
        style={{ maskType: 'alpha' }}
        width='14'
        height='7'
        x='2'
        y='12'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M2.167 12.046h13.128v6.116H2.167v-6.116z'
          clipRule='evenodd'
        />
      </mask>
      <g mask='url(#mask0_492:5738)'>
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M8.73 13.296c-3.525 0-5.313.606-5.313 1.8 0 1.205 1.788 1.816 5.314 1.816s5.314-.606 5.314-1.798c0-1.207-1.788-1.818-5.314-1.818m0 4.866c-1.623 0-6.564 0-6.564-3.066 0-2.734 3.746-3.05 6.564-3.05 1.623 0 6.564 0 6.564 3.068 0 2.732-3.746 3.048-6.564 3.048'
          clipRule='evenodd'
        />
      </g>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M8.73 2.917a3.185 3.185 0 00-3.18 3.181c-.003.85.323 1.645.92 2.246.596.6 1.39.933 2.238.936l.023.625V9.28a3.186 3.186 0 003.182-3.182A3.186 3.186 0 008.73 2.917m0 7.613h-.026a4.392 4.392 0 01-3.122-1.305A4.39 4.39 0 014.3 6.095c0-2.44 1.988-4.428 4.43-4.428a4.437 4.437 0 014.433 4.431A4.437 4.437 0 018.73 10.53M16.503 11.191a.625.625 0 01-.625-.625V7.224a.625.625 0 011.25 0v3.342c0 .345-.28.625-.625.625'
        clipRule='evenodd'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M18.208 9.52H14.8a.625.625 0 010-1.25h3.408a.625.625 0 010 1.25'
        clipRule='evenodd'
      />
    </svg>
  );
}

export default UserAddIcon;
