import styled from 'styled-components';

export const CampaignWidgetContainer = styled.div`
  img {
    max-width: 16px;
    max-height: 9px;
    width: 14px;
    min-width: 16px;
    height: 9px;
    border-radius: 1px;
    &[alt='NP'] {
      margin-right: 4px;
      height: 12px;
      width: 9px;
      min-width: 9px;
      max-height: 12px;
      mix-blend-mode: darken;
      border-radius: 0px;
    }
  }
  .skip-btn {
    font-family: 'Manrope';
    font-weight: 500;
    line-height: 16px;
    color: #6e6681;
  }
`;
