import styled from 'styled-components';

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  > svg {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 22px;
    stroke: #66616b;
    border: 1px solid var(--Stroke-100-Default, #e5e4e7);
  }
  > h4 {
    color: var(--Text-500-Component, #333036);
    font-size: 15px;
    font-weight: 450;
    line-height: 160%;
  }
`;
