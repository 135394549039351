export const KrispcallLight = ({ className }: { className?: string }) => (
  <svg
    width='140'
    height='50'
    viewBox='0 0 140 50'
    fill='none'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.81418 22.4282L16.3363 32.556H11.4608L4.02063 23.4331V32.556H0V12.6442H4.02063V21.5953L11.4875 12.6442H16.3363L7.81418 22.4282Z'
      fill='white'
    />
    <path
      d='M24.9248 17.5627C25.8999 16.9942 26.9819 16.7033 28.1707 16.7033V20.9607H27.0086C24.0566 20.9607 22.5872 22.3093 22.5872 25.0197V32.556H18.5666V16.9281H22.5872V19.9161C23.175 18.9245 23.9497 18.1444 24.9248 17.5627Z'
      fill='white'
    />
    <path d='M34.4222 16.928V32.5692H30.4015V16.928H34.4222Z' fill='white' />
    <path
      d='M48.1406 18.1709C49.3027 19.1361 50.024 20.4053 50.3045 22.0052H46.5376C46.3907 21.2515 46.0434 20.6566 45.5091 20.1938C44.9748 19.7443 44.2802 19.5195 43.4253 19.5195C42.7441 19.5195 42.2098 19.6782 41.8224 19.9955C41.4484 20.3128 41.2481 20.7491 41.2481 21.2912C41.2481 21.7275 41.395 22.0713 41.7022 22.3357C42.0094 22.6001 42.3834 22.8117 42.8376 22.9571C43.2918 23.1026 43.9463 23.2877 44.8012 23.4992C46.0033 23.7636 46.9784 24.0413 47.7265 24.3322C48.4745 24.623 49.129 25.0858 49.6633 25.7204C50.211 26.3551 50.4781 27.2013 50.4781 28.2722C50.4781 29.6076 49.9438 30.6918 48.9019 31.5247C47.8467 32.3577 46.4308 32.7676 44.6409 32.7676C42.5838 32.7676 40.9408 32.3048 39.7119 31.3925C38.4697 30.4802 37.7216 29.1713 37.4545 27.4789H41.3015C41.395 28.2458 41.7423 28.854 42.33 29.3035C42.9177 29.7398 43.6925 29.9646 44.6409 29.9646C45.3221 29.9646 45.8431 29.8059 46.2037 29.4754C46.5644 29.1448 46.7514 28.7217 46.7514 28.1929C46.7514 27.7433 46.5911 27.3731 46.2839 27.0955C45.9766 26.8178 45.5759 26.5931 45.1084 26.4476C44.6409 26.3022 43.9864 26.1171 43.1315 25.9055C41.956 25.6411 40.9943 25.3767 40.2596 25.099C39.5249 24.8214 38.8971 24.3851 38.3762 23.7901C37.8552 23.1819 37.5881 22.3621 37.5881 21.3044C37.5881 19.9558 38.109 18.8452 39.1509 17.999C40.1928 17.1528 41.6354 16.7297 43.4921 16.7297C45.4423 16.7297 46.9785 17.2057 48.1406 18.1709Z'
      fill='white'
    />
    <path
      d='M66.5339 17.695C67.6025 18.3297 68.4307 19.2552 69.0318 20.4716C69.6329 21.688 69.9267 23.1159 69.9267 24.7422C69.9267 26.3816 69.6329 27.8096 69.0318 29.0127C68.4307 30.2291 67.6025 31.1546 66.5339 31.7893C65.4653 32.4239 64.2765 32.7545 62.9407 32.7545C61.605 32.7545 60.4696 32.4371 59.5345 31.8025C58.5995 31.1679 57.9183 30.3349 57.5175 29.2772V39.9999H53.4969V16.9282H57.5175V20.1807C57.9183 19.123 58.5862 18.29 59.5345 17.6686C60.4696 17.0472 61.605 16.7431 62.9407 16.7431C64.2631 16.7299 65.4653 17.0472 66.5339 17.695ZM58.6663 21.4368C57.8782 22.2565 57.4775 23.3671 57.4775 24.7554C57.4775 26.1304 57.8782 27.2278 58.6663 28.0476C59.4544 28.8673 60.4562 29.2772 61.6718 29.2772C62.9274 29.2772 63.9292 28.8805 64.6906 28.074C65.4519 27.2807 65.826 26.1701 65.826 24.7554C65.826 23.3407 65.4519 22.23 64.6906 21.4235C63.9292 20.617 62.9274 20.2071 61.6718 20.2071C60.4696 20.2071 59.4544 20.617 58.6663 21.4368Z'
      fill='white'
    />
    <path
      d='M32.4988 14.6804C33.6349 14.6804 34.5559 13.7688 34.5559 12.6443C34.5559 11.5198 33.6349 10.6082 32.4988 10.6082C31.3627 10.6082 30.4418 11.5198 30.4418 12.6443C30.4418 13.7688 31.3627 14.6804 32.4988 14.6804Z'
      fill='white'
    />
    <path
      d='M107.813 17.687C108.802 18.3348 109.496 19.2075 109.91 20.2916V16.9201H114.065V33.1695H109.91V29.7715C109.496 30.8689 108.788 31.7415 107.813 32.4026C106.825 33.0637 105.636 33.381 104.26 33.381C102.871 33.381 101.629 33.0505 100.533 32.3894C99.4248 31.7283 98.5566 30.7631 97.9421 29.5071C97.3143 28.251 97.0071 26.7702 97.0071 25.0646C97.0071 23.359 97.3143 21.8782 97.9421 20.6222C98.5699 19.3661 99.4248 18.4009 100.533 17.7399C101.642 17.0788 102.884 16.7482 104.26 16.7482C105.636 16.7086 106.825 17.0391 107.813 17.687ZM102.417 21.5873C101.629 22.4335 101.228 23.5838 101.228 25.0514C101.228 26.519 101.629 27.6693 102.417 28.5022C103.205 29.3352 104.26 29.7451 105.556 29.7451C106.825 29.7451 107.867 29.322 108.682 28.4758C109.496 27.6296 109.91 26.4926 109.91 25.0646C109.91 23.6235 109.496 22.4732 108.682 21.6138C107.867 20.7676 106.825 20.3445 105.556 20.3445C104.247 20.3181 103.205 20.7412 102.417 21.5873Z'
      fill='#B7B3C1'
    />
    <path d='M122.026 11.4595V33.1561H117.845V11.4595H122.026Z' fill='#B7B3C1' />
    <path d='M130 11.4595V33.1561H125.819V11.4595H130Z' fill='#B7B3C1' />
    <path
      d='M86.5334 15.9573C84.1471 18.1483 82.6537 21.2788 82.6537 24.7546C82.6537 28.2462 84.1607 31.3894 86.5661 33.5817L89.4139 30.7629C87.7348 29.2943 86.676 27.1469 86.676 24.7546C86.676 22.3782 87.7209 20.2433 89.3807 18.7756L86.5334 15.9573Z'
      fill='#B7B3C1'
    />
    <path
      d='M80.5146 10C76.336 13.5747 73.6918 18.8591 73.6918 24.7548C73.6918 30.6664 76.3502 35.9634 80.5483 39.5385L83.4026 36.7132C79.928 33.8678 77.7141 29.5672 77.7141 24.7548C77.7141 19.9583 79.9134 15.6702 83.3683 12.8247L80.5146 10Z'
      fill='#B7B3C1'
    />
    <path
      d='M90.4911 24.7708C90.4911 25.8939 91.4109 26.8043 92.5456 26.8043C93.6803 26.8043 94.6001 25.8939 94.6001 24.7708C94.6001 23.6476 93.6803 22.7372 92.5456 22.7372C91.4109 22.7372 90.4911 23.6476 90.4911 24.7708Z'
      fill='#B7B3C1'
    />
  </svg>
);
