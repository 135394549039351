export default function Moonnight() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='34' fill='none' viewBox='0 0 36 34'>
      <path
        fill='#FFE02F'
        d='M31.676 21.863c-4.925 2.209-10.712-.011-12.892-4.958-2.128-4.829.018-10.527 4.803-12.751a9.727 9.727 0 012.917-.837c.607-.075.677-.928.09-1.098a13.405 13.405 0 00-9.23.642 13.426 13.426 0 00-6.797 17.779c3.037 6.795 11.01 9.835 17.798 6.79A13.405 13.405 0 0035 20.955c.261-.548-.423-1.063-.88-.662a9.72 9.72 0 01-2.445 1.57z'
      />
      <path
        fill='#F9C301'
        d='M24.006 28.564a5.76 5.76 0 00-2.38-4.158 4.034 4.034 0 00-3.909-3.874A8.376 8.376 0 009.49 16.77c.16 1.31.516 2.62 1.085 3.888 2.405 5.363 7.888 8.381 13.43 7.906z'
      />
      <path
        fill='#EFF3F5'
        d='M19.73 25.355a2.273 2.273 0 00-2.974-2.906 6.626 6.626 0 00-12.712 2.618 4.044 4.044 0 100 8.088h14.188a4.044 4.044 0 001.498-7.8z'
      />
      <path
        fill='#EFF3F5'
        d='M19.73 25.355a2.273 2.273 0 00-2.974-2.906 6.626 6.626 0 00-12.712 2.618 4.044 4.044 0 100 8.088h14.188a4.044 4.044 0 001.498-7.8z'
      />
      <path
        fill='#C9E2E7'
        d='M19.73 25.355a2.273 2.273 0 00-2.538-3.032 9.442 9.442 0 01-5.759 10.831h6.8a4.044 4.044 0 001.498-7.8z'
      />
      <path
        fill='#EFF3F5'
        d='M34.293 11.514A1.523 1.523 0 0032.3 9.567a4.44 4.44 0 00-8.518 1.754 2.71 2.71 0 000 5.42h9.507a2.71 2.71 0 001.004-5.227z'
      />
      <path
        fill='#EFF3F5'
        d='M34.293 11.514A1.523 1.523 0 0032.3 9.567a4.44 4.44 0 00-8.518 1.754 2.71 2.71 0 000 5.42h9.507a2.71 2.71 0 001.004-5.227z'
      />
      <path
        fill='#C9E2E7'
        d='M34.294 11.514a1.523 1.523 0 00-1.701-2.031 6.327 6.327 0 01-3.859 7.258h4.556a2.71 2.71 0 001.004-5.227z'
      />
      <path
        fill='#FFE02F'
        d='M3.053 8.675l.35.709c.061.125.18.21.318.23l.782.114c.346.05.484.476.234.72L4.171 11a.422.422 0 00-.122.373l.134.78a.422.422 0 01-.612.445l-.7-.368a.421.421 0 00-.393 0l-.7.368a.422.422 0 01-.612-.445l.134-.78A.422.422 0 001.179 11l-.567-.552a.422.422 0 01.234-.72l.783-.113a.422.422 0 00.317-.231l.35-.71a.422.422 0 01.757 0zM31.874 29.236l.255.517c.062.124.18.21.318.23l.57.083c.346.05.484.476.233.72l-.412.402a.422.422 0 00-.121.373l.097.568a.422.422 0 01-.612.445l-.51-.268a.421.421 0 00-.393 0l-.51.268a.422.422 0 01-.611-.445l.097-.568a.422.422 0 00-.121-.373l-.413-.402a.422.422 0 01.234-.72l.57-.083a.422.422 0 00.318-.23l.254-.517a.422.422 0 01.757 0zM9.292 1.002l.305.618c.04.083.12.14.212.154l.682.099c.23.033.322.317.155.48l-.493.48a.281.281 0 00-.08.25l.116.679a.281.281 0 01-.409.296l-.61-.32a.281.281 0 00-.261 0l-.61.32a.281.281 0 01-.408-.296l.116-.68a.281.281 0 00-.08-.249l-.494-.48a.281.281 0 01.156-.48l.682-.1a.281.281 0 00.212-.153l.305-.618a.281.281 0 01.504 0z'
      />
    </svg>
  );
}
