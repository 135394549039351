import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

export type MessageIdPayload = {
  channelId?: string;
  contactNumber?: string;
};

const useDraftCallNotes = () => {
  const createMessageId = ({ channelId, contactNumber }: MessageIdPayload) => {
    if (!channelId || !contactNumber) return null;
    return `${channelId}-${contactNumber?.replace(/\+/g, '')}`;
  };

  const getDraftMessageList = () => {
    try {
      const data = ls.get('_dmsgs_call_notes') ?? [];
      const draftMessages = typeof data === 'string' ? JSON.parse(data) : [];
      return draftMessages;
    } catch (e) {
      return [];
    }
  };

  const saveDraftMessage = async (messageId: string, draftMessage: string) => {
    const draftMessages = getDraftMessageList();
    const messageIndex = draftMessages.findIndex(
      (eachMessage: any) => eachMessage.messageId === messageId,
    );
    const eachDraftMessage: {
      messageId: string;
      draftMessage: string;
      id?: string;
    } = {
      messageId,
      draftMessage,
    };
    if (messageIndex === -1) {
      draftMessages.push(eachDraftMessage);
    } else {
      draftMessages[messageIndex] = eachDraftMessage;
    }
    ls.set('_dmsgs_call_notes', JSON.stringify(draftMessages));
  };

  const removeDraftMessage = async (messageId?: string | null) => {
    if (!messageId) return;
    const draftMessages = getDraftMessageList();
    const messageIndex = draftMessages.findIndex(
      (eachMessage: any) => eachMessage.messageId === messageId,
    );
    draftMessages.splice(messageIndex, 1);
    ls.set('_dmsgs_call_notes', JSON.stringify(draftMessages));
  };

  const getDraftMessage = (messageId?: string) => {
    if (!messageId) return [];
    const draftMessages = getDraftMessageList();
    const messageIndex = draftMessages.findIndex(
      (eachMessage: any) => eachMessage.messageId === messageId,
    );
    if (messageIndex === -1) {
      return '';
    }
    return draftMessages[messageIndex].draftMessage;
  };

  return {
    createMessageId,
    saveDraftMessage,
    getDraftMessage,
    removeDraftMessage,
  };
};

export default useDraftCallNotes;
