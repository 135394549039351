import { useEffect, useState } from 'react';

import {
  BookmarkPlusFill,
  CallTransferFill,
  KeypadIcon,
  MessageDotsRoundFill,
  MicrophoneFill,
  MicrophoneMuteFill,
  ThreeDotsIcon,
  NoteWithTextFill,
  NoteTextFill,
  PauseFill,
  RecordIcon,
  SpeakerIcon,
  UserSquareFill,
  PlayFill,
  DeafenedIcon,
  PhoneFill,
} from 'components/atoms/icon';
import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { featureList, useFeatureByPlan } from 'hooks/useFeatureByPlan';

import { useCallActionStates } from './useCallActionStates';
import { useQuickSms } from './useQuickSms';
import { ICallActionProps } from '../call-action';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import useCallWidgetNavContext from '../../hooks/useCallWidgetNavContext';
import { BannerType } from '../../types';
import { CALL_ACTIVE_SCREENS } from '../../constants';

export const useCallActions = () => {
  const [showMoreActions, setShowMoreActions] = useState(false);
  const {
    ADD_TAG,
    ADD_NOTE,
    ADD_CONTACT,
    KEYPAD,
    CALL_TRANSFER,
    CALL_SCRIPT,
    ADD_CAMPAIGN_NOTE,
  } = CALL_ACTIVE_SCREENS;
  const [hasCallRecordAccess] = useFeatureByPlan(featureList['call-recordings-and-storage']);
  const [hasCallTransferAccess] = useFeatureByPlan(featureList['call-transfer']);
  const {
    state: { status = 'initiated', callInProgress, connection, campaignStatus },
  } = useTwilioContext();
  const isTransferredCall = connection?.customParameters?.get('after_transfer') === 'True';
  const { showAlertBanner, showOngoingCallBanner, banner } = useCallWidgetContext();
  const { selectedDrawer, setSelectedDrawer } = useCallWidgetDrawerContext();
  const { disableEndCall } = useCallActionStates();
  const {
    mute,
    isSpeakerMute,
    recording,
    hold,
    loadingCallRecord,
    autoRecordingEnabled,
    loadingCallHold,
    handleToggleMute,
    handleToggleMuteSpeaker,
    handleToggleRecording,
    handleToggleHold,
    callDurationTime,
  } = useActiveCallWidgetContext();
  const { handleRedirectToMainScreen } = useCallWidgetNavContext();

  // To show updated call duration timer
  useEffect(() => {
    if (selectedDrawer && banner?.title?.includes('Ongoing Call')) {
      showOngoingCallBanner?.(callDurationTime, handleRedirectToMainScreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrawer, callDurationTime]);

  const handleClickCallAction = (action: string) => () => {
    setSelectedDrawer(action);
    if (campaignStatus === CALL_WIDGET_STATUS.PAUSED) return;
    showOngoingCallBanner?.(callDurationTime, handleRedirectToMainScreen);
  };

  const handleTransferClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!hasCallTransferAccess) {
      showAlertBanner?.('This is a premium feature.', BannerType.info);
      return;
    }
    handleClickCallAction(CALL_TRANSFER)?.();
  };

  const { handleOpenConversation } = useQuickSms();

  const INITIAL_CALL_ACTIONS: ICallActionProps[] = [
    {
      label: recording ? 'Recording' : 'Record',
      icon: <RecordIcon />,
      variant: recording ? 'danger' : 'primary',
      onClick: handleToggleRecording,
      disabled:
        !hasCallRecordAccess ||
        !callInProgress ||
        loadingCallRecord ||
        isTransferredCall ||
        !autoRecordingEnabled,
    },
    {
      label: !mute ? 'Microphone' : 'Muted',
      icon: !mute ? <MicrophoneFill /> : <MicrophoneMuteFill />,
      variant: !mute ? 'primary' : 'danger',
      onClick: handleToggleMute,
      disabled: !callInProgress,
    },
    // new feature: speaker
    {
      label: !isSpeakerMute ? 'Speaker' : 'Deafened',
      icon: !isSpeakerMute ? <SpeakerIcon /> : <DeafenedIcon />,
      variant: !isSpeakerMute ? 'primary' : 'danger',
      onClick: handleToggleMuteSpeaker,
      disabled: !callInProgress,
    },
    {
      label: hold ? 'Resume' : 'Hold',
      icon: hold ? <PlayFill /> : <PauseFill />,
      variant: hold ? 'info' : 'primary',
      onClick: handleToggleHold,
      disabled: !callInProgress || loadingCallHold,
    },
    {
      label: 'Keypad',
      icon: <KeypadIcon />,
      onClick: handleClickCallAction(KEYPAD),
      disabled: !callInProgress,
    },
    {
      label: 'More',
      icon: <ThreeDotsIcon />,
      onClick: () => setShowMoreActions(true),
    },
  ];

  const MORE_CALL_ACTIONS: ICallActionProps[] = [
    {
      label: 'Add Tag',
      icon: <BookmarkPlusFill />,
      onClick: handleClickCallAction(ADD_TAG),
    },
    {
      label: 'Add Note',
      icon: <NoteWithTextFill />,
      onClick: handleClickCallAction(ADD_NOTE),
    },
    {
      label: 'Add Contact',
      icon: <UserSquareFill />,
      onClick: handleClickCallAction(ADD_CONTACT),
    },
    {
      label: 'Quick SMS',
      icon: <MessageDotsRoundFill />,
      onClick: handleOpenConversation,
    },
    {
      label: 'Call Transfer',
      icon: <CallTransferFill />,
      disabled:
        !callInProgress ||
        !!hold ||
        isTransferredCall ||
        status === CALL_WIDGET_STATUS.TRANSFERRING,
      onClick: handleTransferClick,
    },
    {
      label: 'Less',
      icon: <ThreeDotsIcon />,
      onClick: () => setShowMoreActions(false),
    },
  ];

  // POWER DIALER CALL ACTIONS
  const CAMPAIGN_CALL_ACTIONS: ICallActionProps[] = [
    {
      label: recording ? 'Recording' : 'Record',
      icon: <RecordIcon />,
      variant: recording ? 'danger' : 'primary',
      onClick: handleToggleRecording,
      disabled:
        !hasCallRecordAccess ||
        !callInProgress ||
        loadingCallRecord ||
        isTransferredCall ||
        !autoRecordingEnabled,
    },
    {
      label: hold ? 'Resume' : 'Hold',
      icon: hold ? <PlayFill /> : <PauseFill />,
      variant: hold ? 'info' : 'primary',
      onClick: handleToggleHold,
      disabled: !callInProgress || loadingCallHold,
    },
    {
      label: !mute ? 'Microphone' : 'Muted',
      icon: !mute ? <MicrophoneFill /> : <MicrophoneMuteFill />,
      variant: !mute ? 'primary' : 'danger',
      onClick: handleToggleMute,
      disabled: !callInProgress,
    },
    {
      label: 'Notes',
      icon: <NoteWithTextFill />,
      onClick: handleClickCallAction(ADD_CAMPAIGN_NOTE),
    },
    {
      label: 'Script',
      icon: <NoteTextFill />,
      onClick: handleClickCallAction(CALL_SCRIPT),
    },
    {
      label: 'Keypad',
      icon: <KeypadIcon />,
      onClick: handleClickCallAction(KEYPAD),
      disabled: !callInProgress,
    },
  ];

  const callActions = showMoreActions ? MORE_CALL_ACTIONS : INITIAL_CALL_ACTIONS;
  const campaignCallActions = CAMPAIGN_CALL_ACTIONS;

  return {
    callActions,
    disableEndCall,
    campaignCallActions,
  };
};
