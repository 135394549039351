import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from 'components/atoms/fields';
import { Label, Error } from 'components/atoms';
import { InputFieldWrapper, FlexColumn } from './styled';
import { InputProps } from './types';

interface AutoCompleteProps extends InputProps {
  onSearch?: any;
  defaultValue?: any;
  onSelect?: any;
  options?: any;
  dropdownMatchSelectWidth?: boolean;
  dropdownClassName?: string;
  open?: boolean;
  dropdownStyle?: React.CSSProperties;
  listHeight?: number;
  onPopupScroll?: any;
}

const AutoCompleteField: React.FC<AutoCompleteProps> = props => {
  const {
    label,
    id,
    name,
    placeholder,
    required = false,
    errors,
    onSearch,
    onSelect,
    control,
    className,
    children,
    options,
    dropdownClassName,
    dropdownStyle,
    open,
    listHeight,
    onPopupScroll,
    ...rest
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <InputFieldWrapper data-cy='number-auto-complete-dropdown'>
        <FlexColumn>
          {label && (
            <Label id={id || name} required={required}>
              {label}
            </Label>
          )}
          {!control && (
            <AutoComplete
              placeholder={placeholder}
              id={id || name}
              className={className}
              onSearch={onSearch}
              onSelect={onSelect}
              open={open}
              dropdownStyle={dropdownStyle}
              listHeight={listHeight}
              onPopupScroll={onPopupScroll}
              {...rest}
            />
          )}
          {control && (
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <AutoComplete
                    {...rest}
                    {...field}
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentNode as HTMLElement
                    }
                    dropdownClassName={dropdownClassName}
                    placeholder={placeholder}
                    id={id || name}
                    value={field.value}
                    onSearch={onSearch}
                    onSelect={onSelect}
                    className={className}
                    options={options}
                    open={open}
                    dropdownStyle={dropdownStyle}
                    listHeight={listHeight}
                    onPopupScroll={onPopupScroll}
                  >
                    {children}
                  </AutoComplete>
                );
              }}
            />
          )}
          {errors?.[name] && <Error>{t(`constants:${errors[name].message}`)}</Error>}
        </FlexColumn>
      </InputFieldWrapper>
    </>
  );
};

export default AutoCompleteField;
