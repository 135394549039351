import { PropsWithChildren } from 'react';

import { Avatar, AvatarProps } from 'antd';
import { useTheme } from 'styled-components';

import { ManIcon as UserIcon } from 'components/atoms/icon';

import {
  AvailableAvatarSize,
  AvailableShape,
  AvatarBgColor,
  mapBgToProp,
  mapSizeToProp,
} from './common';

export interface UserAvatarProps extends AvatarProps {
  customShape?: AvailableShape;
  customSize?: AvailableAvatarSize;
  bgColorType?: AvatarBgColor;
}

const UserAvatar = (props: PropsWithChildren<UserAvatarProps>) => {
  const theme = useTheme();
  const { customSize = 'md', customShape = 'circle', bgColorType = '', children, ...rest } = props;
  const style = {
    border: 'none',
    fontWeight: 550,
    fontSize: mapSizeToProp[customSize]?.fontSize,
    background: mapBgToProp[bgColorType] || '#edebee',
    color: bgColorType ? '#fff' : '#4d4950',
    // TODO: Need to map the colors from the theme after integrating v3 theme
    // background: mapBgToProp[bgColorType] || theme.colors.background.b4,
    // color: bgColorType ? theme.colors.text.t1 : theme.colors.text.t4,
    ...rest?.style,
  };
  const avatarSize = mapSizeToProp[customSize]?.avatarSize;

  if (children)
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Avatar shape={customShape} size={avatarSize || rest?.size} {...rest} style={style}>
        {children}
      </Avatar>
    );

  return (
    <Avatar
      shape={customShape}
      size={avatarSize || rest?.size}
      icon={<UserIcon /> || rest?.icon}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      style={style}
    />
  );
};

UserAvatar.defaultProps = {
  customSize: 'md',
  bgColorType: '',
  customShape: 'cirlce',
};

export default UserAvatar;
