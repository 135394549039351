export default function MessageLimited({ className }: { className?: string }) {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.0004 37.9314C29.9034 37.9314 37.9314 29.9034 37.9314 20.0004C37.9314 10.0973 29.9034 2.06934 20.0004 2.06934C10.0973 2.06934 2.06934 10.0973 2.06934 20.0004C2.06934 29.9034 10.0973 37.9314 20.0004 37.9314Z'
        fill='#ECF0F1'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5996 9.65527H28.9651C30.4887 9.65527 31.7237 10.8903 31.7237 12.4139V24.138C31.7237 25.6616 30.4887 26.8967 28.9651 26.8967H28.841L11.5996 9.65527Z'
        fill='#390179'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.82713 10.7725C8.46731 11.2438 8.27335 11.8209 8.27541 12.4138V24.138C8.27541 25.6615 9.51048 26.8966 11.034 26.8966H12.4133V31.7173C12.4141 31.9814 12.5637 32.2226 12.7999 32.3407C13.0362 32.4588 13.3188 32.4338 13.5306 32.2759L20.6892 26.8966H24.9513L8.82713 10.7725Z'
        fill='#390179'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.5863 14.4828H13.7932C13.4123 14.4828 13.1035 14.1741 13.1035 13.7932C13.1035 13.4123 13.4123 13.1035 13.7932 13.1035H27.5863C27.9672 13.1035 28.2759 13.4123 28.2759 13.7932C28.2759 14.1741 27.9672 14.4828 27.5863 14.4828Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.5867 18.6205H12.4143C12.0334 18.6205 11.7246 18.3118 11.7246 17.9309C11.7246 17.55 12.0334 17.2412 12.4143 17.2412H27.5867C27.9676 17.2412 28.2763 17.55 28.2763 17.9309C28.2763 18.3118 27.9676 18.6205 27.5867 18.6205Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.5867 22.7582H12.4143C12.0334 22.7582 11.7246 22.4494 11.7246 22.0686C11.7246 21.6877 12.0334 21.3789 12.4143 21.3789H27.5867C27.9676 21.3789 28.2763 21.6877 28.2763 22.0686C28.2763 22.4494 27.9676 22.7582 27.5867 22.7582Z'
        fill='white'
      />
      <path
        d='M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM29.9103 34.0966C26.6094 36.4329 22.5879 37.525 18.5586 37.1793C10.1876 36.4496 3.55039 29.8124 2.82069 21.4414C2.47161 17.4118 3.56404 13.3891 5.90345 10.0897C6.13993 9.76447 6.50724 9.55948 6.90816 9.52895C7.30909 9.49841 7.7032 9.64541 7.98621 9.93103L30.069 32.0138C30.3546 32.2968 30.5016 32.6909 30.4711 33.0918C30.4405 33.4928 30.2355 33.8601 29.9103 34.0966V34.0966ZM34.0966 29.9103C33.8601 30.2355 33.4928 30.4405 33.0918 30.4711C32.6909 30.5016 32.2968 30.3546 32.0138 30.069L9.93103 7.98621C9.64541 7.7032 9.49841 7.30909 9.52895 6.90816C9.55948 6.50724 9.76447 6.13993 10.0897 5.90345C13.3906 3.56711 17.4121 2.47499 21.4414 2.82069C29.8124 3.55039 36.4496 10.1876 37.1793 18.5586C37.5284 22.5882 36.436 26.6109 34.0966 29.9103V29.9103Z'
        fill='#FF5364'
      />
    </svg>
  );
}
