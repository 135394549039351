import { gql } from '@apollo/client';

export const MEMBER_STATUS_SUBSCRIPTION = gql`
  subscription onlineMemberStatus($workspace: ShortId!) {
    onlineMemberStatus(workspace: $workspace) {
      event
      message {
        id
        online
      }
    }
  }
`;
