import { Checkbox, Radio } from 'antd';
import { RiSearchLine } from 'react-icons/ri';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useCallback, useState } from 'react';

import { Tag } from 'generated/graphql';
import { ALL_TAGS } from 'graphql/client';
import { SearchInput } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';
import { selectedTags, useQVars } from 'hooks/useContactVars';

import * as S from './ColumnStyles';
import { Tag as TagBlock } from './Tag';

export const UserTagsDropdown = () => {
  const { t } = useTranslation();
  const { setSelectedTags } = useQVars(selectedTags);
  const { activeWorkspaceId } = useContext(AuthContext);

  const [tags, setTags] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [checkedList, setCheckedList] = useState<string[]>(() => []);

  const [loadTags, { called, data }] = useLazyQuery(ALL_TAGS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!called || activeWorkspaceId) loadTags({});
  }, [loadTags, activeWorkspaceId, called]);

  useEffect(() => {
    if (data?.allTags?.data) {
      setTags(data?.allTags?.data);
    }
  }, [data]);

  const onTagChecked = useCallback(
    (evt: any) => {
      evt.preventDefault();
      const selected: any = evt.target.value;
      const isChecked = evt.target.checked;
      if (isChecked) {
        setIsAllSelected(false);
      }
      setCheckedList(prev =>
        isChecked ? [...prev, selected] : [...prev].filter(idx => idx !== selected),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (checkedList?.length > 0) {
      setSelectedTags(checkedList);
    }
    return () => {
      setSelectedTags([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  const onSearch = (evt: any) => {
    const keyword = evt.target.value;
    const tokens = keyword
      .toLowerCase()
      .split(' ')
      .filter((token: string) => {
        return token.trim() !== '';
      });
    const searchTermRegex = new RegExp(tokens.join('|'), 'gim');

    const filtered = data?.allTags?.data?.filter((tag: any) => tag?.title?.match(searchTermRegex));
    setTags(filtered?.length ? filtered : data?.allTags?.data);
  };
  const handleAllTagSelect = (evt: any) => {
    const checked = evt?.target?.checked;
    setIsAllSelected(true);
    if (checked) {
      setSelectedTags([]);
      setCheckedList([]);
    }
  };
  return (
    <S.FilterDropdown className='tags-filter'>
      <div className='px-4 pt-3.5 pb-3'>
        <SearchInput
          className='search-tags'
          placeholder={t('searchTags', 'Search Tags...')}
          prefix={<RiSearchLine />}
          onChange={onSearch}
          type=''
        />
      </div>
      <Radio onChange={handleAllTagSelect} checked={isAllSelected}>
        {t('allTags', 'All Tags')}
      </Radio>
      <S.TagsScrollList>
        <h6 className='uppercase'>Tags</h6>
        {tags?.map((tag: Tag) => {
          const { id, title, colorCode } = tag || {};
          const isChecked = checkedList?.includes(id);
          return (
            <Checkbox value={id} onChange={onTagChecked} key={id} checked={isChecked}>
              <TagBlock title={title} colorCode={colorCode as string} />
            </Checkbox>
          );
        })}
      </S.TagsScrollList>
    </S.FilterDropdown>
  );
};
