import { css } from 'styled-components';

import text from './text';

const { font, size, weight } = text;

const typography = css`
  font-family: ${font.body};
  font-weight: ${weight.regular};
  h1 {
    font-size: ${size.heading.h1}px;
  }
  h2 {
    font-size: ${size.heading.h2}px;
  }
  h3 {
    font-size: ${size.heading.h3}px;
  }
  h4 {
    font-size: ${size.heading.h4}px;
  }
  h5 {
    font-size: ${size.heading.h5}px;
  }
  h6 {
    font-size: ${size.heading.h6}px;
  }
`;
export default typography;
