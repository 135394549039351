export default function Renew({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='64' height='64' rx='20' fill='#F3F7FE' />
      <g clipPath='url(#clip0_16981_167891)'>
        <path
          d='M23.7102 20.0885H12.8048C12.3603 20.0885 12 20.4489 12 20.8934V41.8189C12 42.2634 12.3603 42.6238 12.8048 42.6238H23.7103V20.0885H23.7102Z'
          fill='#EAF6FF'
        />
        <path
          d='M14.7364 41.8189V20.8934C14.7364 20.4488 15.0967 20.0885 15.5412 20.0885H12.8048C12.3603 20.0885 12 20.4489 12 20.8934V41.8189C12 42.2634 12.3603 42.6238 12.8048 42.6238H15.5412C15.0967 42.6238 14.7364 42.2634 14.7364 41.8189Z'
          fill='#D8ECFE'
        />
        <path
          d='M51.1953 20.0885H40.2898V42.6238H51.1953C51.6398 42.6238 52.0001 42.2634 52.0001 41.8189V20.8934C52.0001 20.4489 51.6398 20.0885 51.1953 20.0885Z'
          fill='#D8ECFE'
        />
        <path d='M40.2898 20.0885H43.0262V42.6238H40.2898V20.0885Z' fill='#C4E2FF' />
        <path
          d='M40.0216 21.7787H23.9786L23.6299 21.8591V44.2334C23.6299 44.6779 23.9902 45.0382 24.4347 45.0382H39.5655C40.01 45.0382 40.3704 44.6779 40.3704 44.2334V21.8591L40.0216 21.7787Z'
          fill='#EAF6FF'
        />
        <path
          d='M26.8492 44.2334V21.8592L27.1979 21.7788H23.9786L23.6299 21.8592V44.2334C23.6299 44.678 23.9902 45.0383 24.4347 45.0383H27.654C27.2096 45.0382 26.8492 44.6779 26.8492 44.2334Z'
          fill='#D8ECFE'
        />
        <path
          d='M40.3704 21.8591H23.6299V17.835C23.6299 17.3905 23.9902 17.0302 24.4347 17.0302H39.5655C40.01 17.0302 40.3704 17.3905 40.3704 17.835V21.8591Z'
          fill='#390179'
        />
        <path
          d='M27.654 17.0302H24.4347C23.9902 17.0302 23.6299 17.3905 23.6299 17.835V21.8591H26.8492V17.835C26.8492 17.3905 27.2096 17.0302 27.654 17.0302Z'
          fill='#390179'
        />
        <path
          d='M35.5412 46.9698H28.4587C27.792 46.9698 27.2515 46.4294 27.2515 45.7626V44.3139C27.2515 43.6472 27.7919 43.1066 28.4587 43.1066H35.5412C36.2079 43.1066 36.7485 43.6471 36.7485 44.3139V45.7626C36.7485 46.4293 36.2079 46.9698 35.5412 46.9698Z'
          fill='#97D729'
        />
        <path
          d='M30.4708 45.7626V44.3139C30.4708 43.6472 31.0112 43.1066 31.678 43.1066H28.4587C27.792 43.1066 27.2515 43.6471 27.2515 44.3139V45.7626C27.2515 46.4293 27.7919 46.9698 28.4587 46.9698H31.678C31.0112 46.9698 30.4708 46.4293 30.4708 45.7626Z'
          fill='#8BC727'
        />
        <path
          d='M37.1911 25.8254H31.4768C31.1532 25.8254 30.8909 25.563 30.8909 25.2395C30.8909 24.9159 31.1532 24.6535 31.4768 24.6535H37.1911C37.5147 24.6535 37.777 24.9159 37.777 25.2395C37.777 25.563 37.5147 25.8254 37.1911 25.8254Z'
          fill='#8AC9FE'
        />
        <path
          d='M37.1911 27.9582H31.4768C31.1532 27.9582 30.8909 27.6959 30.8909 27.3723C30.8909 27.0487 31.1532 26.7863 31.4768 26.7863H37.1911C37.5147 26.7863 37.777 27.0487 37.777 27.3723C37.777 27.6959 37.5147 27.9582 37.1911 27.9582Z'
          fill='#8AC9FE'
        />
        <path
          d='M37.1911 30.0909H31.4768C31.1532 30.0909 30.8909 29.8286 30.8909 29.505C30.8909 29.1814 31.1532 28.9191 31.4768 28.9191H37.1911C37.5147 28.9191 37.777 29.1814 37.777 29.505C37.777 29.8286 37.5147 30.0909 37.1911 30.0909Z'
          fill='#8AC9FE'
        />
        <path
          d='M37.1912 33.3102H28.7808C28.4572 33.3102 28.1948 33.0479 28.1948 32.7243C28.1948 32.4007 28.4572 32.1384 28.7808 32.1384H37.1912C37.5148 32.1384 37.7772 32.4007 37.7772 32.7243C37.7772 33.0479 37.5148 33.3102 37.1912 33.3102Z'
          fill='#8AC9FE'
        />
        <path
          d='M37.1912 35.8857H28.7808C28.4572 35.8857 28.1948 35.6234 28.1948 35.2998C28.1948 34.9762 28.4572 34.7138 28.7808 34.7138H37.1912C37.5148 34.7138 37.7772 34.9762 37.7772 35.2998C37.7772 35.6234 37.5148 35.8857 37.1912 35.8857Z'
          fill='#8AC9FE'
        />
        <path
          d='M37.1912 38.4612H28.7808C28.4572 38.4612 28.1948 38.1988 28.1948 37.8752C28.1948 37.5516 28.4572 37.2893 28.7808 37.2893H37.1912C37.5148 37.2893 37.7772 37.5516 37.7772 37.8752C37.7772 38.1988 37.5148 38.4612 37.1912 38.4612Z'
          fill='#8AC9FE'
        />
        <path
          d='M37.1912 41.0366H28.7808C28.4572 41.0366 28.1948 40.7743 28.1948 40.4507C28.1948 40.1271 28.4572 39.8648 28.7808 39.8648H37.1912C37.5148 39.8648 37.7772 40.1271 37.7772 40.4507C37.7772 40.7743 37.5148 41.0366 37.1912 41.0366Z'
          fill='#8AC9FE'
        />
        <path
          d='M26.9699 33.3102H26.8088C26.4852 33.3102 26.2229 33.0479 26.2229 32.7243C26.2229 32.4007 26.4852 32.1384 26.8088 32.1384H26.9699C27.2934 32.1384 27.5558 32.4007 27.5558 32.7243C27.5558 33.0479 27.2934 33.3102 26.9699 33.3102Z'
          fill='#8AC9FE'
        />
        <path
          d='M26.9699 35.8857H26.8088C26.4852 35.8857 26.2229 35.6234 26.2229 35.2998C26.2229 34.9762 26.4852 34.7138 26.8088 34.7138H26.9699C27.2934 34.7138 27.5558 34.9762 27.5558 35.2998C27.5558 35.6234 27.2934 35.8857 26.9699 35.8857Z'
          fill='#8AC9FE'
        />
        <path
          d='M26.9699 38.4612H26.8088C26.4852 38.4612 26.2229 38.1988 26.2229 37.8752C26.2229 37.5516 26.4852 37.2893 26.8088 37.2893H26.9699C27.2934 37.2893 27.5558 37.5516 27.5558 37.8752C27.5558 38.1988 27.2934 38.4612 26.9699 38.4612Z'
          fill='#8AC9FE'
        />
        <path
          d='M26.9699 41.0366H26.8088C26.4852 41.0366 26.2229 40.7743 26.2229 40.4507C26.2229 40.1271 26.4852 39.8648 26.8088 39.8648H26.9699C27.2934 39.8648 27.5558 40.1271 27.5558 40.4507C27.5558 40.7743 27.2934 41.0366 26.9699 41.0366Z'
          fill='#8AC9FE'
        />
        <path
          d='M34.5755 45.6241H29.4246C29.101 45.6241 28.8386 45.3618 28.8386 45.0382C28.8386 44.7146 29.101 44.4523 29.4246 44.4523H34.5755C34.8991 44.4523 35.1614 44.7146 35.1614 45.0382C35.1614 45.3618 34.8991 45.6241 34.5755 45.6241Z'
          fill='#78A91F'
        />
        <path
          d='M49.3844 36.5698H44.8372C44.5136 36.5698 44.2512 36.3075 44.2512 35.9839C44.2512 35.6603 44.5136 35.398 44.8372 35.398H49.3844C49.708 35.398 49.9704 35.6603 49.9704 35.9839C49.9704 36.3075 49.708 36.5698 49.3844 36.5698Z'
          fill='#8AC9FE'
        />
        <path
          d='M43.0263 36.5698H42.8652C42.5416 36.5698 42.2793 36.3075 42.2793 35.9839C42.2793 35.6603 42.5416 35.398 42.8652 35.398H43.0263C43.3498 35.398 43.6122 35.6603 43.6122 35.9839C43.6122 36.3075 43.3498 36.5698 43.0263 36.5698Z'
          fill='#8AC9FE'
        />
        <path
          d='M49.3844 39.1453H44.8372C44.5136 39.1453 44.2512 38.883 44.2512 38.5594C44.2512 38.2358 44.5136 37.9734 44.8372 37.9734H49.3844C49.708 37.9734 49.9704 38.2358 49.9704 38.5594C49.9704 38.883 49.708 39.1453 49.3844 39.1453Z'
          fill='#8AC9FE'
        />
        <path
          d='M43.0263 39.1453H42.8652C42.5416 39.1453 42.2793 38.883 42.2793 38.5594C42.2793 38.2358 42.5416 37.9734 42.8652 37.9734H43.0263C43.3498 37.9734 43.6122 38.2358 43.6122 38.5594C43.6122 38.883 43.3498 39.1453 43.0263 39.1453Z'
          fill='#8AC9FE'
        />
        <path
          d='M49.3844 33.9944H44.8372C44.5136 33.9944 44.2512 33.732 44.2512 33.4084C44.2512 33.0848 44.5136 32.8225 44.8372 32.8225H49.3844C49.708 32.8225 49.9704 33.0848 49.9704 33.4084C49.9704 33.732 49.708 33.9944 49.3844 33.9944Z'
          fill='#8AC9FE'
        />
        <path
          d='M43.0263 33.9944H42.8652C42.5416 33.9944 42.2793 33.732 42.2793 33.4084C42.2793 33.0848 42.5416 32.8225 42.8652 32.8225H43.0263C43.3498 32.8225 43.6122 33.0848 43.6122 33.4084C43.6122 33.732 43.3498 33.9944 43.0263 33.9944Z'
          fill='#8AC9FE'
        />
        <path
          d='M49.3844 31.3384H44.8372C44.5136 31.3384 44.2512 31.0761 44.2512 30.7525C44.2512 30.4289 44.5136 30.1666 44.8372 30.1666H49.3844C49.708 30.1666 49.9704 30.4289 49.9704 30.7525C49.9704 31.0761 49.708 31.3384 49.3844 31.3384Z'
          fill='#8AC9FE'
        />
        <path
          d='M43.0263 31.3384H42.8652C42.5416 31.3384 42.2793 31.0761 42.2793 30.7525C42.2793 30.4289 42.5416 30.1666 42.8652 30.1666H43.0263C43.3498 30.1666 43.6122 30.4289 43.6122 30.7525C43.6122 31.0761 43.3498 31.3384 43.0263 31.3384Z'
          fill='#8AC9FE'
        />
        <path
          d='M46.1248 28.1407C47.527 28.1407 48.6638 27.0039 48.6638 25.6016C48.6638 24.1994 47.527 23.0626 46.1248 23.0626C44.7225 23.0626 43.5857 24.1994 43.5857 25.6016C43.5857 27.0039 44.7225 28.1407 46.1248 28.1407Z'
          fill='#FED402'
        />
        <path
          d='M45.8016 25.6016C45.8016 24.5966 46.3856 23.7281 47.2327 23.3166C46.8979 23.154 46.522 23.0626 46.1248 23.0626C44.7225 23.0626 43.5857 24.1994 43.5857 25.6016C43.5857 27.0039 44.7225 28.1407 46.1248 28.1407C46.522 28.1407 46.8979 28.0493 47.2327 27.8866C46.3857 27.4751 45.8016 26.6066 45.8016 25.6016Z'
          fill='#FAC600'
        />
        <path
          d='M21.1347 36.5698H16.5874C16.2638 36.5698 16.0015 36.3075 16.0015 35.9839C16.0015 35.6603 16.2638 35.398 16.5874 35.398H21.1347C21.4583 35.398 21.7207 35.6603 21.7207 35.9839C21.7207 36.3075 21.4583 36.5698 21.1347 36.5698Z'
          fill='#8AC9FE'
        />
        <path
          d='M14.7767 36.5698H14.6157C14.2921 36.5698 14.0298 36.3075 14.0298 35.9839C14.0298 35.6603 14.2921 35.398 14.6157 35.398H14.7767C15.1003 35.398 15.3626 35.6603 15.3626 35.9839C15.3626 36.3075 15.1003 36.5698 14.7767 36.5698Z'
          fill='#8AC9FE'
        />
        <path
          d='M21.1347 39.1453H16.5874C16.2638 39.1453 16.0015 38.883 16.0015 38.5594C16.0015 38.2358 16.2638 37.9734 16.5874 37.9734H21.1347C21.4583 37.9734 21.7207 38.2358 21.7207 38.5594C21.7207 38.883 21.4583 39.1453 21.1347 39.1453Z'
          fill='#8AC9FE'
        />
        <path
          d='M14.7767 39.1453H14.6157C14.2921 39.1453 14.0298 38.883 14.0298 38.5594C14.0298 38.2358 14.2921 37.9734 14.6157 37.9734H14.7767C15.1003 37.9734 15.3626 38.2358 15.3626 38.5594C15.3626 38.883 15.1003 39.1453 14.7767 39.1453Z'
          fill='#8AC9FE'
        />
        <path
          d='M21.1347 33.9944H16.5874C16.2638 33.9944 16.0015 33.732 16.0015 33.4084C16.0015 33.0848 16.2638 32.8225 16.5874 32.8225H21.1347C21.4583 32.8225 21.7207 33.0848 21.7207 33.4084C21.7207 33.732 21.4583 33.9944 21.1347 33.9944Z'
          fill='#8AC9FE'
        />
        <path
          d='M14.7767 33.9944H14.6157C14.2921 33.9944 14.0298 33.732 14.0298 33.4084C14.0298 33.0848 14.2921 32.8225 14.6157 32.8225H14.7767C15.1003 32.8225 15.3626 33.0848 15.3626 33.4084C15.3626 33.732 15.1003 33.9944 14.7767 33.9944Z'
          fill='#8AC9FE'
        />
        <path
          d='M21.1347 31.3384H16.5874C16.2638 31.3384 16.0015 31.0761 16.0015 30.7525C16.0015 30.4289 16.2638 30.1666 16.5874 30.1666H21.1347C21.4583 30.1666 21.7207 30.4289 21.7207 30.7525C21.7207 31.0761 21.4583 31.3384 21.1347 31.3384Z'
          fill='#8AC9FE'
        />
        <path
          d='M14.7767 31.3384H14.6157C14.2921 31.3384 14.0298 31.0761 14.0298 30.7525C14.0298 30.4289 14.2921 30.1666 14.6157 30.1666H14.7767C15.1003 30.1666 15.3626 30.4289 15.3626 30.7525C15.3626 31.0761 15.1003 31.3384 14.7767 31.3384Z'
          fill='#8AC9FE'
        />
        <path
          d='M17.8752 28.1407C19.2775 28.1407 20.4143 27.0039 20.4143 25.6016C20.4143 24.1994 19.2775 23.0626 17.8752 23.0626C16.473 23.0626 15.3362 24.1994 15.3362 25.6016C15.3362 27.0039 16.473 28.1407 17.8752 28.1407Z'
          fill='#26A6FE'
        />
        <path
          d='M17.5521 25.6016C17.5521 24.5966 18.1361 23.7281 18.9832 23.3166C18.6484 23.154 18.2725 23.0626 17.8752 23.0626C16.473 23.0626 15.3362 24.1994 15.3362 25.6016C15.3362 27.0039 16.473 28.1407 17.8752 28.1407C18.2725 28.1407 18.6484 28.0493 18.9832 27.8866C18.1361 27.4751 17.5521 26.6066 17.5521 25.6016Z'
          fill='#0593FC'
        />
        <path
          d='M36.668 19.9502H27.332C27.0084 19.9502 26.7461 19.6878 26.7461 19.3642C26.7461 19.0406 27.0084 18.7783 27.332 18.7783H36.668C36.9916 18.7783 37.2539 19.0406 37.2539 19.3642C37.2539 19.6878 36.9916 19.9502 36.668 19.9502Z'
          fill='white'
        />
        <path
          d='M30.0105 27.925C30.0105 27.1374 29.3698 26.4967 28.5822 26.4967H27.6512C27.5098 26.4967 27.3948 26.3817 27.3948 26.2403C27.3948 26.0989 27.5098 25.9839 27.6512 25.9839H29.2916C29.6152 25.9839 29.8775 25.7216 29.8775 25.398C29.8775 25.0744 29.6152 24.812 29.2916 24.812H28.7027V24.5334C28.7027 24.2098 28.4403 23.9475 28.1167 23.9475C27.7931 23.9475 27.5308 24.2098 27.5308 24.5334V24.8181C26.7995 24.8796 26.2229 25.4934 26.2229 26.2403C26.2229 27.0279 26.8636 27.6686 27.6512 27.6686H28.5822C28.7236 27.6686 28.8386 27.7836 28.8386 27.925C28.8386 28.0664 28.7236 28.1814 28.5822 28.1814H26.9418C26.6182 28.1814 26.3559 28.4438 26.3559 28.7673C26.3559 29.0909 26.6182 29.3533 26.9418 29.3533H27.5307V29.6318C27.5307 29.9554 27.7931 30.2177 28.1167 30.2177C28.4402 30.2177 28.7026 29.9554 28.7026 29.6318V29.3471C29.4339 29.2858 30.0105 28.672 30.0105 27.925Z'
          fill='#78A91F'
        />
      </g>
      <defs>
        <clipPath id='clip0_16981_167891'>
          <rect width='40' height='40' fill='white' transform='translate(12 12)' />
        </clipPath>
      </defs>
    </svg>
  );
}
