import styled from 'styled-components';

export const DetailsContainer = styled.div<any>`
  .details-form {
    width: 100%;
    .edit-field-wrapper {
      display: grid;
      grid-template-columns: 100px auto;
      align-items: center;
      margin-bottom: 4px;
      grid-column-gap: 2px;
      & > div,
      p {
        margin: 0;
      }

      /* label */
      p {
        max-width: 198px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 28px;
        border-radius: 5px;
        width: 100%;
        padding: 3px 8px;
        color: #6e6681;
        &.input-placeholder {
          color: #9e99ab;
        }
        &:hover {
          background: #f5f2f8;
          cursor: text;
        }
        &.client-id {
          &::before {
            content: '#';
          }
        }
      }
      .word-break {
        font-weight: 500;
        font-size: 14px;
        color: #6e6681;
      }
      .details-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #857f96;
      }

      .phone-number-wrapper {
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding-left: 9px;
        &:hover {
          background: #f5f2f8;
        }
        p {
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          color: #6e6681;
          padding-top: 4px;
        }
        .flag-wrapper {
          position: relative;
          margin-right: 18px;
          .country-flag {
            top: -6px;
            width: 20px;
            min-width: 20px;
            height: 12px;
            position: absolute;
            border-radius: 2px;
            object-fit: cover;
          }
          img[title='NP'] {
            width: 13px;
            min-width: 13px;
            height: 15px;
            border-radius: 0 !important;
            top: -8px;
            object-fit: contain;
            mix-blend-mode: darken;
          }
        }
      }

      .ant-input,
      .ant-select-selector {
        background: transparent;
        padding: 6px 8px; /* must be same as p above */
        margin: 0;
        outline: none;
        border: none;
        height: fit-content !important;
        border-radius: 5px;
        font-size: 14px;
        color: #564d6d;
        line-height: 16px;
        &:hover,
        &:focus {
          background: #f5f2f8;
        }
        &:focus {
          box-shadow: 0 0 0 1px #d7cce4;
          z-index: 1;
        }
        &::placeholder {
          font-size: 14px;
          line-height: 20px;
          color: #857f96;
        }
      }
    }
    .ant-select {
      background: transparent !important;
      height: 28px !important;
      border: none;
      .ant-select-selector {
        background: transparent !important;
        height: 100% !important;
        &:hover,
        &:focus {
          background: #f5f2f8 !important;
        }
      }
      &-focused {
        > .ant-select-selector {
          box-shadow: 0 0 0 1px #d7cce4 !important;
          background: #f5f2f8 !important;
        }
      }
      .ant-select-selection-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #6e6681 !important;
        font-family: 'Heebo', sans-serif !important;
      }
    }

    .ant-dropdown-trigger.trigger > div {
      /* avoid margin on inputfield */
      margin: 0;
    }
    /* error message */
    .ant-input + span {
      margin-left: 5px;
    }

    /* visibility */
    .visibility {
      .ant-select-arrow {
        display: none;
      }
    }

    .tag-select__control {
      padding: 0;
      min-height: 44px;
      border-radius: 8px;

      .tag-select__value-container {
        padding: 6px 8px;
      }
      .tag-select__multi-value {
        padding: 5px 8px;
        margin: 4px;
      }
    }
  }
`;

export const PhoneInputStyles = styled.div`
  & * {
    margin-bottom: 0 !important;
  }
  .react-tel-input {
    height: 28px !important;
    border: none;
    .form-control.krisp-form-control {
      background: transparent !important;
      border-radius: 5px !important;
      border: none;
      padding: 0 0 0 35px !important;
      font-family: 'Heebo', sans-serif;
      font-size: 14px;
      color: #6e6681;
      font-weight: 500;
      &:hover,
      &:focus {
        background: #f5f2f8 !important;
      }
      &:focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.text.t2};
      }
    }
    .krisp-form-control,
    .flag-dropdown {
      background: transparent !important;
      height: 100% !important;
    }
    .flag-dropdown {
      width: fit-content;
      left: 16px;
      border: none;
    }
    .selected-flag {
      width: 24px;
      padding: 0;
      &:hover {
        background: transparent !important;
      }
      .flag {
        left: -6px;
      }
      .arrow {
        display: none;
      }
    }
    .country-list {
      right: -162px;
    }

    .country-flag {
    }
  }
`;
