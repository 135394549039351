import useScreenType from 'hooks/useScreenType';
import { useTwilioContext } from 'lib/twilio';
import { useChatBot } from 'hooks/useChatBot';
import * as S from '../Styles';

const DeviceOfflineNotification = () => {
  const { isTablet, isMobile } = useScreenType();
  const { openChat } = useChatBot();
  const { loadingVoiceToken } = useTwilioContext();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={
          <>
            <p className='lg:inline-block loading'>
              Device is offline.
              {!loadingVoiceToken && (
                <>
                  {' '}
                  {isMobile ? '' : 'Please'}
                  <button type='button' onClick={handleReload} className='mr-2'>
                    Reload
                  </button>
                  or{' '}
                  <button type='button' onClick={openChat} className='mr-2'>
                    Contact Support
                  </button>
                </>
              )}
            </p>
          </>
        }
        type='error'
        banner
        className={`has-bordered-btn ${isTablet || isMobile ? 'alert-sm' : ''}`}
      />
    </S.AlertWrapper>
  );
};

export default DeviceOfflineNotification;
