import styled from 'styled-components';

export const StyledTagOptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 8px;
  border-radius: 6px;
  border: 1px solid var(--Stroke-100-Default, #e5e4e7);
  color: var(--Text-300-Component-2, #66616b);
  font-weight: 450;
  line-height: 14px; /* 100% */

  > #dot {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
`;

export const CheckboxWrapper = styled.div`
  .ant-checkbox-group {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    label.ant-checkbox-wrapper {
      .ant-checkbox {
        display: none;
        + span {
          padding: 0;
          > div #dot {
            position: relative;
            ::before {
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              position: absolute;
              top: 0;
              left: 0;
              content: '';
              background-color: var(--Text-200-Disabled, #99949e);
            }
          }
        }
      }
      &.ant-checkbox-group-item {
        margin: 0;
      }

      &-checked {
        .ant-checkbox + span > div {
          color: var(--Text-500-Component, #333036);
          #dot {
            ::before {
              display: none;
            }
          }
        }
      }
    }
  }
`;
