import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { errorKeys } from 'constants/errors';
import LikeIcon from 'assets/icons/like-icon.svg';
import { ADD_NEW_CONTACT } from 'graphql/client';
import { ToastMessage } from 'components/atoms';
import { parsePhoneNumber, isValidPhoneNumber } from 'components/utils/phone-lib';
import { HASHED_COUNTRIES } from 'constants/countries';

export function useAddContactMutation(props?: any) {
  const { t } = useTranslation();
  const [addContactMutation, { loading }] = useMutation(ADD_NEW_CONTACT, {
    onCompleted: response => {
      const { error, data } = response.addNewContact;
      if (error === null) {
        props?.onModalCancel?.();
        props?.onAddContact?.(data);
        props?.reset?.({});
        ToastMessage({
          content: t('toast.addNewContactSuccess', 'New contact added successfully.'),
          icon: (
            <span>
              <img src={LikeIcon} alt='like icon' />
            </span>
          ),
        });
        return;
      }
      if (response?.addNewContact?.error?.message) {
        ToastMessage({
          content: t(`${response?.addNewContact?.error?.message}`),
          type: 'danger',
        });
        props?.onAddContactError?.(error?.message);
        return;
      }

      const err = errorKeys.find(item => item.errorKey === error?.errorKey);
      ToastMessage({
        content: err?.message || t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
      props?.onAddContactError?.(err?.message || 'Something went wrong');
    },
    onError: () => {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      props?.onAddContactError?.('Something went wrong');
    },
  });

  const addContact = async (val: any, setError?: any) => {
    const phone = parsePhoneNumber(val?.number);
    const hasOneCharacterName = val?.name?.length === 1;
    if (hasOneCharacterName) {
      setError('name', { type: 'manual', message: 'Should have atleast 2 characters.' });
      return;
    }
    if (!isValidPhoneNumber(val?.number)) {
      if (setError) {
        setError('number', {
          type: 'manual',
          message: t('toast.invalidNumber', 'Invalid Phone Number'),
        });
      }
      ToastMessage({ content: t('error.invalidPhone', 'Invalid Phone Number'), type: 'danger' });
      return;
    }

    const payload = {
      ...val,
      number: phone.number,
      country: phone.country && HASHED_COUNTRIES[phone.country].uid,
      ...(val.email?.length > 0 ? { email: val.email } : { email: null }),
      ...(props?.imageFile && { photoUpload: props?.imageFile }),
    };

    await addContactMutation({
      variables: {
        data: { ...payload },
      },
      update(cache, { data }) {
        const cacheId = data?.addNewContact?.data && cache.identify(data.addNewContact.data);
        if (cacheId) {
          cache.modify({
            fields: {
              newContacts: () => {},
              newCount: () => {},
              clientDetail: () => {},
              recentConversationHistory: () => {},
              contactPinnedConversationOpenClosed: () => {},
            },
          });
        }
      },
    });
  };

  return {
    addContact,
    loading,
  };
}
