import styled from 'styled-components';

export const Disabled = styled.div`
  color: ${({ theme }) => theme.colors.text.t4};
  & * {
    cursor: auto;
  }
  .ant-btn {
    background: ${({ theme }) => theme.colors.text.t0} !important;
    color: #857f96 !important;
    border: none;
    pointer-events: none;

    > span {
      color: inherit;
    }
    > svg {
      color: #857f96 !important;
    }
  }
  .ant-switch-disabled {
    pointer-events: none;
    background: #f1f0f2;
    opacity: 1;
    .ant-switch-handle::before {
      background: #d3d1d9;
      box-shadow: none;
    }
  }
  .ant-radio-wrapper,
  .ant-radio-group > .ant-radio-wrapper {
    pointer-events: none;
    .ant-radio {
      & + span:last-child {
        color: ${({ theme }) => theme.colors.text.t4};
      }
      .ant-radio-inner {
        background: ${({ theme }) => theme.colors.text.t0};
        border: 1px solid ${({ theme }) => theme.colors.text.t4};
        &::after {
          background: ${({ theme }) => theme.colors.text.t4};
        }
      }
      &-checked {
        .ant-radio-inner {
          background: ${({ theme }) => theme.colors.text.t1} !important;
          border-color: ${({ theme }) => theme.colors.text.t1} !important;
        }
      }
    }
  }
  &.disabled-general-settings {
    .icon,
    .title {
      color: ${({ theme }) => theme.colors.text.t4};
    }
  }
  &.disabled-share-access {
    section h3 {
      color: ${({ theme }) => theme.colors.text.t4} !important;
    }
    .content-container {
      border-color: ${({ theme }) => theme.colors.text.t0};
      > div:first-child {
        background: ${({ theme }) => theme.colors.text.t0};
      }
    }
    .body {
      span {
        color: ${({ theme }) => theme.colors.text.t3};
      }
    }
    .ant-avatar-group {
      opacity: 0.5;
    }
  }

  .ant-btn.edit-team-disabled,
  .disabled-receipt-action,
  .box {
    pointer-events: none;
    opacity: 0.3;
    color: ${({ theme }) => theme.colors.text.t3} !important;
    background: ${({ theme }) => theme.colors.text.t0} !important;
    .ant-table-row:hover & {
      opacity: 1;
      background: ${({ theme }) => theme.colors.text.t1} !important;
      svg {
        border-radius: 10px;
        color: ${({ theme }) => theme.colors.text.t5} !important;
      }
    }
  }

  // billings and plans
  .remove {
    color: ${({ theme }) => theme.colors.text.t4} !important;
    pointer-events: none;
    margin: 0;
  }
  // receipts
  .box {
    tr.ant-table-row:hover > td &,
    &.ant-table-row-selected > td & {
      svg {
        opacity: 1;
        background: ${({ theme }) => theme.colors.text.t1};
        color: ${({ theme }) => theme.colors.text.t5} !important;
      }
    }
  }

  // languages
  label.ant-radio-wrapper {
    .label--left,
    .label--right {
      color: ${({ theme }) => theme.colors.text.t3};
    }
    .flag-container {
      opacity: 0.5;
    }
  }
`;
