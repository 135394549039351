import styled from 'styled-components';

import { Modal } from 'antd';

export const ElementsWrapper = styled.div`
  & > div + div {
    margin-top: 20px;
  }
  .card_input {
    border: 1px solid ${({ theme }) => theme.colors.primary.p2};
    background-color: ${({ theme }) => theme.colors.primary.p0};
    border-radius: 10px;
    padding: 12px 16px;
    margin-top: 3px;
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    color: ${({ theme }) => theme.colors.text.t8};
    transition: all 0.15s ease;
    height: 44px;
    cursor: text;
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
  .card_input.invalid {
    border-color: #db312b;
  }
  .card_input:focus {
    border-color: ${({ theme }) => theme.colors.primary.default};
    &,
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
  .ant-row > .ant-col {
    padding: 0 !important;
    > div {
      margin-top: 3px;
    }
    &:first-child {
      width: 179px;
    }
    &:last-child {
      width: 139px;
    }
  }
  &.registration {
    .card_input {
      height: 48px;
      padding: 15px 16px;
    }
    .ant-row > .ant-col {
      &:first-child {
        width: 205px;
      }
      &:last-child {
        width: 205px;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    form > div {
      margin-bottom: 24px;
      label {
        font-family: 'Heebo', 'sans-serif';
        color: #564d6d;
      }
    }
  }
  .ant-input {
    margin-top: 0;
    line-height: 16px;
    font-family: 'Heebo', 'sans-serif';
    font-weight: 400;
    color: #3d3358;
    padding: 14px 16px;
    border: 1px solid #d7cce4;
  }
  .btn--ok--primary {
    background: #390179;
    border: none;
    min-width: 160px;
  }
`;
export const ModalBottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  .cancel {
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    line-height: 14px;
    color: ${({ theme }) => theme.colors.text.t6};
    margin-right: 27px;
  }
`;

export const AddCardPaymentMethodWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    border-color: ${({ theme }) => theme.colors.primary.p2};
    &.active {
      border-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;
