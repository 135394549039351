/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';

export const selectedCountry: any = makeVar(null);

export const updatedDialCode: any = makeVar(null);

export function useNumberSearchVars(qVars: any) {
  const setSelectedCountry = (country: any) => {
    qVars(country);
  };
  const setUpdatedDialCode = (dialCode: any) => {
    qVars(dialCode);
  };

  return {
    setSelectedCountry,
    setUpdatedDialCode,
  };
}
