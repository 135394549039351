import * as PapaParse from 'papaparse';
import { notification } from 'antd';

export function blobToImage(blob: Blob) {
  if (blob) {
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl;
  }
  return '';
}
export function getBase64(
  img: File | Blob,
  callback: (args: string | Blob | ArrayBuffer | null) => void,
) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function beforeUploadChecks(file: File, sizeLimit = 2, validMimeType: Array<string> = []) {
  // const isValidMimeType =
  //   file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
  const isValidMimeType = validMimeType.length > 0 ? validMimeType.includes(file.type) : true;
  if (isValidMimeType) {
    notification.open({
      message: 'Invalid file type',
      placement: 'topLeft',
    });
  }
  const isLimitCrossed = file.size / 1024 / 1024 < sizeLimit;
  if (!isLimitCrossed) {
    notification.open({
      message: `File must be smaller than ${sizeLimit} MB!`,
      placement: 'topRight',
    });
  }
  return isValidMimeType && isLimitCrossed;
}

export function nameElipsis(name: string, len = 10): string {
  if (name?.length > len) {
    return `${name.substring(0, len)}...`;
  }
  return name;
}
export function snakeCaseToCapitalCase(str: string) {
  const splitString = str.split('_');
  const concatString = `${
    splitString[0].charAt(0).toUpperCase() + splitString[0].substr(1)
  } ${splitString[1].toUpperCase()}`;
  return concatString;
}

export function getParsedCSV(file: Blob) {
  const parserOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, '_'),
  } as PapaParse.ParseConfig;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () =>
      resolve(
        PapaParse.parse(
          reader.result as string,
          Object.assign(parserOptions, {
            encoding: 'UTF-8',
          }),
        ),
      );
    reader.onerror = error => reject(error);
  });
}

export function compare(a: Record<string, string>, b: Record<string, string>, keyToSort = 'name') {
  // Use toUpperCase() to ignore character casing
  const itemA = a[keyToSort].toUpperCase();
  const itemB = b[keyToSort].toUpperCase();

  let comparison = 0;
  if (itemA > itemB) {
    comparison = 1;
  } else if (itemA < itemB) {
    comparison = -1;
  }
  return comparison;
}

export const camelCase = (str: string) => {
  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
  const string: string = str
    .toLowerCase()
    .replace(/[^A-Za-z0-9]/g, ' ')
    .split(' ')
    .reduce((result, word) => result + capitalize(word.toLowerCase()));
  return string.charAt(0).toLowerCase() + string.slice(1);
};
export function pick(obj: any, ...props: any) {
  return props.reduce((result: any, prop: any) => {
    // eslint-disable-next-line no-param-reassign
    result[prop] = obj[prop];
    return result;
  }, {});
}
export function omit(obj: any, ...props: any) {
  const result = { ...obj };
  props.forEach((prop: any) => {
    delete result[prop];
  });
  return result;
}

export function prependZero(args: number | string | undefined | null) {
  const number = Number(args ?? 0);
  if (number <= 9) return `0${number}`;
  return number;
}

export function uniqueArray(item: Array<any>) {
  return Array.from(new Set(item));
}

export function isEmptyObject(obj: Record<string, unknown>): boolean {
  return Object.keys(obj).length === 0;
}

// export const refreshPage = () => window.location.replace(window.location.href);
export const refreshPage = () => {
  console.log('refreshPage');
  window.location.reload();
};

export async function getFileFromUrl(url: string) {
  if (typeof window === 'undefined') return; // make sure we are in the browser
  try {
    const response = await fetch(url, { method: 'GET', mode: 'cors' });
    const data = await response.blob();
    const metadata = {
      type: response.headers.get('content-type') || 'audio/mpeg',
    };
    // eslint-disable-next-line consistent-return
    return new File([data], 'audio.mp3', metadata);
  } catch (e) {
    // catch error
  }
}

export const getTooltipTitle = (title: string, MAX_CHAR_LENGTH = 15) =>
  title?.length > MAX_CHAR_LENGTH ? title : '';

export const isNumber = (value: string) => {
  return /^[0-9]*$/.test(value);
};

export const isMobileIos: () => boolean = () => {
  const { userAgent } = navigator;
  return /iPad|iPhone|iPod/.test(userAgent);
};

export const convertToCent = (val: number | string) => {
  return Math.round(Number(val) * 100);
};

export const getNameInitialsForAvatar = (name: string) => {
  if (!name) return null;
  const nameSplit = name?.split(' ');
  const firstName = nameSplit[0];
  const lastName = nameSplit?.[nameSplit?.length - 1] ?? '';
  if (nameSplit?.length <= 1 || !lastName) return firstName[0];
  return firstName[0] + lastName[0];
};
