const EmptyConversationVector = () => (
  <svg
    width='200'
    height='200'
    viewBox='0 0 200 200'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='100' cy='100' r='100' fill='#F3F7FE' />
    <path
      d='M131.367 102.991V138.141C131.367 139.168 130.535 139.999 129.509 139.999H70.5837C69.5575 139.999 68.7256 139.168 68.7256 138.141V102.991C68.7256 102.447 68.9639 101.931 69.3775 101.578L98.8403 76.4311C99.5351 75.8381 100.558 75.8381 101.253 76.4311L130.715 101.578C131.129 101.931 131.367 102.447 131.367 102.991V102.991Z'
      fill='#7B8DCD'
    />
    <path
      d='M88.5957 103.688C88.5957 101.31 90.5232 99.3828 92.9007 99.3828C95.2782 99.3828 97.2057 101.31 97.2057 103.688C97.2057 106.065 95.2782 107.993 92.9007 107.993C90.5232 107.993 88.5957 106.065 88.5957 103.688Z'
      fill='#5B5555'
    />
    <path
      d='M131.368 102.992V138.141C131.368 139.169 130.536 139.999 129.51 139.999H125.151V105.779C125.151 105.235 124.913 104.718 124.499 104.365L95.3047 79.4483L98.8406 76.4308C99.5355 75.8382 100.558 75.8382 101.252 76.4308L130.716 101.578C131.128 101.931 131.368 102.448 131.368 102.992V102.992Z'
      fill='#5B5555'
    />
    <path
      d='M117.473 102.954C118.41 103.89 117.747 105.49 116.424 105.49H100.047C91.1501 105.49 83.6119 99.6707 81.0347 91.629L98.8406 76.4318C99.5355 75.8391 100.557 75.8391 101.252 76.4318L119.06 91.6309C118.079 94.6893 116.381 97.4262 114.164 99.6429L117.473 102.954Z'
      fill='#5B5555'
    />
    <path
      d='M95.4575 121.086L69.3664 139.546C69.3478 139.529 69.3311 139.512 69.3144 139.497C68.9521 139.157 68.7253 138.676 68.7253 138.141V103.102L92.9008 119.365L95.4575 121.086Z'
      fill='#7ABEF7'
    />
    <path
      d='M131.367 103.102V138.141C131.367 138.676 131.14 139.157 130.778 139.497C130.761 139.512 130.744 139.529 130.726 139.546L104.635 121.086L107.191 119.365L131.367 103.102Z'
      fill='#7ABEF7'
    />
    <path
      d='M131.366 103.102V138.141C131.366 138.676 131.139 139.157 130.777 139.497C130.76 139.512 130.744 139.529 130.725 139.546L125.151 135.601V107.282L131.366 103.102Z'
      fill='#67B5F5'
    />
    <path
      d='M100.046 113.955C99.5263 113.955 99.0235 114.141 98.628 114.478L69.3145 139.498C69.3312 139.513 69.3479 139.53 69.3665 139.546C69.6916 139.829 70.1171 140 70.5835 140H129.509C129.975 140 130.401 139.829 130.726 139.546C130.744 139.53 130.761 139.513 130.778 139.498L101.464 114.478C101.069 114.141 100.566 113.955 100.046 113.955Z'
      fill='#9DD3FF'
    />
    <path
      d='M88.5957 99.93C88.5957 97.5523 90.5232 95.625 92.9007 95.625C95.2782 95.625 97.2057 97.5525 97.2057 99.93C97.2057 102.307 95.2782 104.235 92.9007 104.235C90.5232 104.235 88.5957 102.308 88.5957 99.93Z'
      fill='#BCEA73'
    />
    <path
      d='M90.7173 103.64C92.4073 103.25 93.6663 101.738 93.6663 99.9297C93.6663 98.1236 92.4092 96.6087 90.7193 96.2192C91.3587 95.8414 92.1043 95.625 92.901 95.625C95.2776 95.625 97.2056 97.553 97.2056 99.9295C97.2056 102.308 95.2776 104.236 92.901 104.236C92.1043 104.236 91.3567 104.02 90.7173 103.64Z'
      fill='#99D53B'
    />
    <path
      d='M123.954 67.1243C123.954 64.7467 125.881 62.8193 128.259 62.8193C130.636 62.8193 132.564 64.7468 132.564 67.1243C132.564 69.5018 130.636 71.4293 128.259 71.4293C125.881 71.4293 123.954 69.502 123.954 67.1243Z'
      fill='#BCEA73'
    />
    <path
      d='M126.075 70.8342C127.765 70.4446 129.024 68.9318 129.024 67.1239C129.024 65.3178 127.767 63.8029 126.077 63.4134C126.717 63.0356 127.462 62.8193 128.259 62.8193C130.636 62.8193 132.563 64.7473 132.563 67.1239C132.563 69.5023 130.636 71.4303 128.259 71.4303C127.462 71.4303 126.715 71.2139 126.075 70.8342'
      fill='#99D53B'
    />
    <path
      d='M67.4363 83.3806C67.4363 81.2692 69.1478 79.5576 71.2592 79.5576C73.3707 79.5576 75.0822 81.2692 75.0822 83.3806C75.0822 85.492 73.3707 87.2036 71.2592 87.2036C69.1478 87.2036 67.4363 85.492 67.4363 83.3806'
      fill='#A8E7EF'
    />
    <path
      d='M69.3203 86.675C70.8209 86.329 71.9391 84.9856 71.9391 83.3801C71.9391 81.7764 70.8227 80.4311 69.322 80.0853C69.8898 79.7498 70.5519 79.5576 71.2595 79.5576C73.37 79.5576 75.082 81.2696 75.082 83.3801C75.082 85.4923 73.37 87.2043 71.2595 87.2043C70.5519 87.2043 69.8881 87.0121 69.3203 86.675'
      fill='#70D9E6'
    />
    <path
      d='M129.966 88.6805L131.103 87.5433C131.575 87.0719 131.575 86.3075 131.103 85.836C130.632 85.3646 129.867 85.3646 129.396 85.836L128.259 86.9732L127.121 85.836C126.65 85.3646 125.886 85.3646 125.414 85.836C124.942 86.3074 124.942 87.0718 125.414 87.5433L126.551 88.6805L125.414 89.8177C124.942 90.2891 124.942 91.0535 125.414 91.525C125.65 91.7608 125.959 91.8786 126.268 91.8786C126.576 91.8786 126.886 91.7607 127.121 91.525L128.258 90.3878L129.396 91.525C129.631 91.7608 129.94 91.8786 130.249 91.8786C130.558 91.8786 130.867 91.7607 131.103 91.525C131.574 91.0536 131.574 90.2893 131.103 89.8177L129.966 88.6805Z'
      fill='#64B9FC'
    />
    <path
      d='M75.1264 69.133C74.655 68.6616 73.8906 68.6616 73.419 69.133L72.7659 69.7861L72.1128 69.133C71.6414 68.6614 70.877 68.6614 70.4054 69.133C69.9339 69.6044 69.9339 70.3687 70.4054 70.8403L71.0586 71.4934L70.4054 72.1466C69.9339 72.6181 69.9339 73.3823 70.4054 73.8539C70.6412 74.0897 70.9501 74.2075 71.259 74.2075C71.5679 74.2075 71.877 74.0895 72.1126 73.8539L72.7657 73.2008L73.4189 73.8539C73.6546 74.0897 73.9636 74.2075 74.2725 74.2075C74.5814 74.2075 74.8904 74.0895 75.1261 73.8539C75.5976 73.3825 75.5976 72.6181 75.1261 72.1466L74.4729 71.4934L75.1261 70.8403C75.5979 70.3687 75.5979 69.6044 75.1264 69.133V69.133Z'
      fill='#BCEA73'
    />
    <path
      d='M100 100C111.046 100 120 91.0457 120 80C120 68.9543 111.046 60 100 60C88.9543 60 80 68.9543 80 80C80 91.0457 88.9543 100 100 100Z'
      fill='#F5906F'
    />
    <path
      d='M120 80.0001C120 91.0464 111.045 100.001 100.001 100.001C98.6585 100.001 97.3462 99.8693 96.0771 99.6155C105.243 97.7905 112.149 89.7013 112.149 80.0004C112.149 70.2994 105.243 62.2126 96.0771 60.3877C97.3462 60.1338 98.6585 60.002 100.001 60.002C111.045 60.0014 120 68.9538 120 80.0001Z'
      fill='#DD7D5F'
    />
    <path
      d='M95.567 82.4506C96.0322 82.4506 96.4994 82.61 96.8812 82.9357C97.6167 83.5634 98.6704 83.9252 99.7727 83.9287C99.7777 83.9287 99.7827 83.9287 99.7877 83.9287C100.884 83.9287 101.934 83.5736 102.67 82.9533C103.526 82.2319 104.803 82.3402 105.525 83.1958C106.246 84.0511 106.137 85.3291 105.282 86.0504C103.806 87.2956 101.856 87.9805 99.788 87.9805H99.7602C97.6825 87.9742 95.7259 87.277 94.2502 86.0176C93.3994 85.2913 93.2982 84.0126 94.0245 83.1617C94.4257 82.6918 94.9946 82.4506 95.567 82.4506V82.4506Z'
      fill='#D4F2F6'
    />
    <path
      d='M90.4967 80.033C89.3776 80.033 88.4604 79.126 88.4604 78.0072C88.4604 76.8884 89.3572 75.9814 90.476 75.9814H90.4964C91.6155 75.9814 92.5222 76.8884 92.5222 78.0072C92.5222 79.126 91.6155 80.033 90.4967 80.033V80.033Z'
      fill='#D4F2F6'
    />
  </svg>
);

export default EmptyConversationVector;
