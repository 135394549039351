import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useTwilioContext } from 'lib/twilio';
import { isValidPhoneNumber } from 'components/utils/phone-lib';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';

const schema = yup.object().shape({
  number: yup.string().required('Required'),
});

export const useExternalTransferHandler = () => {
  const { closeDrawer } = useCallWidgetDrawerContext();
  const { t } = useTranslation();
  const { handleCallTransfer, loadingCallTransfer } = useActiveCallWidgetContext();
  const {
    state: { connection, direction },
  } = useTwilioContext();

  const methods = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { setError, handleSubmit } = methods;

  const handleFormSubmit = (number: string) => {
    const payload = {
      destination: number,
      callerId:
        direction === 'Incoming'
          ? connection?.parameters?.From
          : connection?.customParameters?.get('To'),
      direction,
      externalNumber: true,
    };
    handleCallTransfer?.(payload, number);
    closeDrawer();
  };

  const formSubmit = async (val: any) => {
    const isValid = isValidPhoneNumber(`+${val.number}`);
    if (!isValid) {
      if (setError) {
        setError('number', {
          type: 'focus',
          message: t('toast.invalidNumber', 'Invalid Phone Number'),
        });
      }
      return;
    }
    handleFormSubmit(`+${val.number}`);
  };

  return { formMethods: methods, handleFormSubmit: handleSubmit(formSubmit), loadingCallTransfer };
};
