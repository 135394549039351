import useScreenType from 'hooks/useScreenType';
import { useChatBot } from 'hooks/useChatBot';

import * as S from '../Styles';

const WebRtcTestFailedNotification = () => {
  const { isTablet, isMobile } = useScreenType();
  const { openChat } = useChatBot();

  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={
          <>
            <p className='lg:inline-block'>
              Call not supported. Some third party tools might be affecting your network
              connectivity. Try removing it or{' '}
              <button type='button' onClick={openChat} className='mr-2'>
                Contact Support
              </button>
            </p>
          </>
        }
        type='error'
        banner
        className={`has-bordered-btn ${isTablet || isMobile ? 'alert-sm' : ''}`}
      />
    </S.AlertWrapper>
  );
};

export default WebRtcTestFailedNotification;
