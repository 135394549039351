const EmptyLogs = () => (
  <svg
    width='140'
    height='140'
    viewBox='0 0 140 140'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='70' cy='70' r='70' fill='#F3F7FE' />
    <path
      d='M98.1704 83.9015V90.9362C98.1704 91.7724 97.4936 92.449 96.6576 92.449H42.5133C41.6773 92.449 41.0005 91.7723 41.0005 90.9362V83.9015C41.0005 83.741 41.0254 83.5816 41.0767 83.4294L48.7369 60.1606C48.9402 59.5397 49.5195 59.1211 50.1736 59.1211H88.9973C89.6515 59.1211 90.2306 59.5397 90.4341 60.1606L98.0943 83.4293C98.1454 83.5816 98.1704 83.741 98.1704 83.9015Z'
      fill='#50C8E2'
    />
    <path
      d='M98.1703 83.9015V90.9362C98.1703 91.7724 97.4935 92.449 96.6575 92.449H87.5617C87.5308 81.7251 87.6961 64.0258 87.7449 59.1211H88.9972C89.6514 59.1211 90.2305 59.5397 90.434 60.1606L98.0942 83.4293C98.1453 83.5816 98.1703 83.741 98.1703 83.9015Z'
      fill='#C4DCF0'
    />
    <path
      d='M41 83.9015V90.9362C41 91.7724 41.6768 92.449 42.5128 92.449H51.6086C51.6395 81.7251 51.4742 64.0258 51.4254 59.1211H50.1732C49.519 59.1211 48.9399 59.5397 48.7364 60.1606L41.0762 83.4294C41.0251 83.5816 41 83.741 41 83.9015Z'
      fill='#C4DCF0'
    />
    <path
      d='M92.4228 83.4282L87.7581 69.2593C87.5539 68.6389 86.9746 68.2197 86.3214 68.2197H52.8492C52.1961 68.2197 51.6168 68.6389 51.4125 69.2593L46.7479 83.4282C46.6977 83.5809 46.6721 83.7405 46.6721 83.9012V90.936C46.6721 91.7713 47.3494 92.4485 48.1847 92.4485H90.9862C91.8215 92.4485 92.4988 91.7713 92.4988 90.936V83.9012C92.4986 83.7405 92.4731 83.5808 92.4228 83.4282Z'
      fill='#E4EFF8'
    />
    <path
      d='M69.5853 80.7586C75.6921 80.7586 80.6427 79.3667 80.6427 77.6498C80.6427 75.9329 75.6921 74.541 69.5853 74.541C63.4784 74.541 58.5278 75.9329 58.5278 77.6498C58.5278 79.3667 63.4784 80.7586 69.5853 80.7586Z'
      fill='#7F7575'
    />
    <path
      d='M92.4986 83.9016V90.9365C92.4986 91.7714 91.821 92.449 90.986 92.449H85.7239C86.5589 92.449 87.2364 91.7714 87.2364 90.9365V83.9016C87.2364 83.7413 87.2108 83.581 87.1609 83.4282L82.4962 69.2588C82.292 68.6387 81.7127 68.2197 81.0593 68.2197H86.3214C86.9749 68.2197 87.5541 68.6387 87.7583 69.2588L92.423 83.4282C92.4729 83.581 92.4986 83.7413 92.4986 83.9016Z'
      fill='#7BADD7'
    />
    <path
      d='M98.1706 85.3622V98.4878C98.1706 99.3232 97.4934 100 96.6581 100H42.5126C41.6772 100 41 99.3232 41 98.4878V85.3622C41 84.5268 41.6772 83.8496 42.5126 83.8496H53.6803C54.2582 83.8496 54.7857 84.179 55.0394 84.6984L57.2339 89.1916C57.4875 89.711 58.0149 90.0404 58.593 90.0404H80.5776C81.1555 90.0404 81.683 89.711 81.9367 89.1916L84.1312 84.6984C84.3848 84.179 84.9123 83.8496 85.4903 83.8496H96.6581C97.4934 83.8496 98.1706 84.5268 98.1706 85.3622Z'
      fill='#549AD4'
    />
    <path
      d='M98.1707 85.3622V98.4878C98.1707 99.3228 97.4931 100 96.6581 100H90.8987C91.7336 100 92.4112 99.3228 92.4112 98.4878V85.3622C92.4112 84.5273 91.7336 83.8496 90.8987 83.8496H96.6581C97.493 83.8496 98.1707 84.5273 98.1707 85.3622Z'
      fill='#3588CB'
    />
    <path
      d='M48.815 96.7751H45.5509C45.0582 96.7751 44.6589 96.3757 44.6589 95.8831C44.6589 95.3906 45.0583 94.9912 45.5509 94.9912H48.815C49.3077 94.9912 49.7069 95.3906 49.7069 95.8831C49.7069 96.3757 49.3077 96.7751 48.815 96.7751Z'
      fill='#F8F3F1'
    />
    <path
      d='M48.815 93.989H45.5509C45.0582 93.989 44.6589 93.5897 44.6589 93.097C44.6589 92.6043 45.0583 92.2051 45.5509 92.2051H48.815C49.3077 92.2051 49.7069 92.6045 49.7069 93.097C49.7071 93.5897 49.3077 93.989 48.815 93.989Z'
      fill='#F8F3F1'
    />
    <path
      d='M69.5853 78.0305C75.3013 78.0305 79.9351 73.3968 79.9351 67.6808C79.9351 61.9648 75.3013 57.3311 69.5853 57.3311C63.8693 57.3311 59.2356 61.9648 59.2356 67.6808C59.2356 73.3968 63.8693 78.0305 69.5853 78.0305Z'
      fill='#FFCA28'
    />
    <path
      d='M79.9348 67.6811C79.9348 73.3974 75.3009 78.0314 69.5858 78.0314C68.891 78.0314 68.2119 77.963 67.5552 77.8317C72.2983 76.8873 75.8723 72.7012 75.8723 67.6811C75.8723 62.6609 72.2983 58.4761 67.5552 57.5317C68.2119 57.4004 68.891 57.332 69.5858 57.332C75.3009 57.332 79.9348 61.9647 79.9348 67.6811Z'
      fill='#ECB200'
    />
    <path
      d='M71.6419 71.6542C71.4371 71.6542 71.2313 71.584 71.0632 71.4407C70.6549 71.0922 70.0728 70.8913 69.4657 70.8894C69.463 70.8894 69.4602 70.8894 69.4574 70.8894C68.8535 70.8894 68.2737 71.0867 67.8652 71.4311C67.4886 71.7488 66.9258 71.7009 66.6083 71.3242C66.2907 70.9476 66.3385 70.3849 66.7152 70.0674C67.4543 69.4439 68.4348 69.1033 69.4712 69.1055C70.5094 69.1086 71.486 69.4561 72.2215 70.0838C72.5962 70.4036 72.6407 70.9667 72.3209 71.3413C72.1443 71.548 71.8939 71.6542 71.6419 71.6542Z'
      fill='#8A6800'
    />
    <path
      d='M64.6667 67.5427C64.6651 67.5427 64.6634 67.5427 64.6617 67.5427C64.169 67.5401 63.7666 67.1386 63.7693 66.6459C63.772 66.1532 64.1681 65.7618 64.6609 65.7588H64.6715C65.1642 65.7615 65.5613 66.163 65.5587 66.6556C65.556 67.1465 65.157 67.5427 64.6667 67.5427Z'
      fill='#8A6800'
    />
    <path
      d='M74.6667 67.5427C74.6651 67.5427 74.6634 67.5427 74.6617 67.5427C74.169 67.5401 73.7666 67.1386 73.7693 66.6459C73.772 66.1532 74.1681 65.7618 74.6609 65.7588H74.6715C75.1642 65.7615 75.5613 66.163 75.5587 66.6556C75.556 67.1465 75.157 67.5427 74.6667 67.5427Z'
      fill='#8A6800'
    />
    <path
      d='M51.9971 49.4572C51.9971 47.7004 53.4211 46.2764 55.1779 46.2764C56.9347 46.2764 58.3586 47.7004 58.3586 49.4572C58.3586 51.214 56.9346 52.6381 55.1778 52.6381C53.421 52.6381 51.9971 51.214 51.9971 49.4572Z'
      fill='#FFCA28'
    />
    <path
      d='M53.5647 52.1982C54.8133 51.9104 55.7436 50.7927 55.7436 49.4569C55.7436 48.1224 54.8147 47.0032 53.5661 46.7154C54.0385 46.4362 54.5894 46.2764 55.1781 46.2764C56.9341 46.2764 58.3585 47.7009 58.3585 49.4569C58.3585 51.2142 56.934 52.6388 55.1781 52.6388C54.5894 52.6388 54.0371 52.4788 53.5647 52.1982Z'
      fill='#ECB200'
    />
    <path
      d='M65.498 40.2613C65.1497 39.9129 64.585 39.9129 64.2366 40.2613L63.5155 40.9824L62.7944 40.2613C62.4462 39.913 61.8813 39.913 61.533 40.2613C61.1846 40.6096 61.1846 41.1744 61.533 41.5227L62.2541 42.2438L61.533 42.9648C61.1846 43.3131 61.1846 43.878 61.533 44.2263C61.7072 44.4005 61.9355 44.4875 62.1637 44.4875C62.3919 44.4875 62.6203 44.4004 62.7944 44.2263L63.5155 43.5052L64.2366 44.2263C64.4107 44.4004 64.639 44.4875 64.8673 44.4875C65.0956 44.4875 65.3238 44.4004 65.498 44.2263C65.8464 43.878 65.8464 43.3131 65.498 42.9648L64.7769 42.2438L65.498 41.5227C65.8464 41.1743 65.8464 40.6096 65.498 40.2613Z'
      fill='#64B9FC'
    />
    <path
      d='M84.951 50.9966L85.6721 50.2756C86.0205 49.9273 86.0205 49.3624 85.6721 49.0141C85.3238 48.6659 84.7589 48.6659 84.4106 49.0141L83.6896 49.7352L82.9685 49.0141C82.6202 48.6659 82.0554 48.6659 81.7071 49.0141C81.3587 49.3624 81.3587 49.9273 81.7071 50.2756L82.4282 50.9966L81.7071 51.7177C81.3587 52.066 81.3587 52.6308 81.7071 52.9791C81.8812 53.1532 82.1095 53.2403 82.3378 53.2403C82.5661 53.2403 82.7944 53.1532 82.9685 52.9791L83.6896 52.258L84.4106 52.9791C84.5848 53.1532 84.8131 53.2403 85.0413 53.2403C85.2696 53.2403 85.4979 53.1532 85.6721 52.9791C86.0205 52.6308 86.0205 52.066 85.6721 51.7177L84.951 50.9966Z'
      fill='#64B9FC'
    />
    <path
      d='M69.539 52.8444C69.5381 52.8444 69.5373 52.8444 69.5363 52.8444C69.0437 52.843 68.6456 52.4424 68.647 51.9498L68.6548 49.2936C68.6562 48.8019 69.0554 48.4043 69.5468 48.4043H69.5494C70.0421 48.4057 70.4402 48.8064 70.4388 49.2989L70.4309 51.9551C70.4295 52.4468 70.0304 52.8444 69.539 52.8444Z'
      fill='#FFCA28'
    />
    <path
      d='M65.5788 53.9771C65.2755 53.9771 64.9798 53.8224 64.8127 53.5434L63.4326 51.2392C63.1794 50.8165 63.3168 50.2688 63.7394 50.0156C64.1621 49.7624 64.7097 49.8999 64.9629 50.3224L66.3431 52.6266C66.5962 53.0493 66.4588 53.597 66.0363 53.8502C65.8927 53.9361 65.7347 53.9771 65.5788 53.9771Z'
      fill='#FFCA28'
    />
    <path
      d='M73.4923 54.0124C73.3346 54.0124 73.1749 53.9705 73.0303 53.8828C72.6093 53.6272 72.4751 53.0785 72.7307 52.6574L74.1245 50.3609C74.3801 49.9397 74.9286 49.8057 75.3498 50.0613C75.7709 50.3168 75.9051 50.8655 75.6495 51.2866L74.2556 53.5831C74.0878 53.8596 73.7937 54.0124 73.4923 54.0124Z'
      fill='#FFCA28'
    />
    <path
      d='M77.7273 47.0756C79.1227 47.0756 80.2538 45.9444 80.2538 44.549C80.2538 43.1537 79.1227 42.0225 77.7273 42.0225C76.3319 42.0225 75.2007 43.1537 75.2007 44.549C75.2007 45.9444 76.3319 47.0756 77.7273 47.0756Z'
      fill='#A8E7EF'
    />
    <path
      d='M80.2536 44.5487C80.2536 45.9446 79.1222 47.0761 77.7274 47.0761C77.2598 47.0761 76.821 46.9491 76.4458 46.7263C77.4376 46.4977 78.1766 45.6098 78.1766 44.5487C78.1766 43.4887 77.4387 42.5997 76.447 42.3711C76.8222 42.1494 77.2598 42.0225 77.7274 42.0225C79.1222 42.0223 80.2536 43.1539 80.2536 44.5487Z'
      fill='#70D9E6'
    />
  </svg>
);
export default EmptyLogs;
