import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import * as path from 'constants/routes';
import { ToastMessage } from 'components/atoms';
import {
  ARCHIVE_CAMPAIGN,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CONTROL_CAMPAIGN,
} from 'graphql/sales-dialer/campaign';
import cache from 'services/apollo/cache';
import { ACTIONS, CAMPAIGN_STATUS_RESPONSE } from 'lib/twilio/constants';
import { useTwilioContext } from 'lib/twilio';
import {
  activeCallCampaignStatus,
  activeCampaignCallsDurationVar,
  activeCampaignDurationVar,
} from 'services/apollo/reactiveVars';
import { ERROR_MESSAGES } from 'constants/errors';

export interface CampaignInput {
  campaignName: string;
  skipCsvUpload?: boolean;
  isContactListHidden?: boolean;
  contactListId?: string;
  file?: any;
  assigneeId: string;
  assigneeName: string;
  dialingNumber: string;
  dataCenter: string;
  isVoicemailEnabled: boolean;
  voiceMailId?: string;
  isCallRecordingEnabled?: boolean;
  isCoolOffPeriodEnabled?: boolean;
  isAttemptsPerCallEnabled?: boolean;
  coolOffPeriod?: number;
  callAttemptCount?: number;
  callAttemptGap?: number;
  isCallScriptEnabled?: boolean;
  callScriptId?: string;
  dialingNumberId?: string;
  createdByName?: string;
  id?: string;
}

export interface ArchiveCampaign {
  id: string;
  isArchived: boolean;
}

export const useCampaignMutation = (props?: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatch } = useTwilioContext();
  const { PAUSED, INPROGRESS, ENDED } = CAMPAIGN_STATUS_RESPONSE;

  const [createCampaignMutation, { loading: createCampaignLoading }] = useMutation(
    CREATE_CAMPAIGN,
    {
      onCompleted: response => {
        const { error, data } = response.createCampaigns || {};
        const {
          totalContactCount,
          validContactCount,
          invalidContactCount,
          duplicatesContactCount,
        } = data || {};
        if (error === null) {
          let toastMsg = '';
          if (totalContactCount < 1) {
            toastMsg = 'No contacts found in the uploaded file';
          } else {
            toastMsg = `Out of ${totalContactCount} contact , ${validContactCount} added, ${duplicatesContactCount} duplicate and ${invalidContactCount} invalid contact.`;
          }
          ToastMessage({
            content: `Campaign created successfully. ${totalContactCount !== null ? toastMsg : ''}`,
            type: 'success',
          });
          cache.modify({
            fields: {
              getCampaignsList: () => {},
            },
          });
          history.push(`${path.SALES_DIALER}${path.CAMPAIGNS}`);
          return;
        }
        ToastMessage({
          content: error?.message ?? t('error.unspecific', 'Something went wrong.'),
          type: 'danger',
        });
      },
      onError: () => {
        ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      },
    },
  );

  const createCampaign = async (payload: CampaignInput) => {
    await createCampaignMutation({
      variables: { data: payload },
    });
  };

  // archive
  const [archiveCampaignMutation, { loading: archiveCampaignLoading }] = useMutation(
    ARCHIVE_CAMPAIGN,
    {
      onCompleted: response => {
        const { error } = response.archiveCampaign || {};
        if (error === null) {
          ToastMessage({
            content: 'Campaign archived successfully',
            type: 'success',
          });
          cache.modify({
            fields: {
              getCampaignsList: () => {},
            },
          });
          history.push(`${path.SALES_DIALER}${path.CAMPAIGNS}`);
          return;
        }
        ToastMessage({
          content: error?.message ?? t('error.unspecific', 'Something went wrong.'),
          type: 'danger',
        });
      },
      onError: () => {
        ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      },
    },
  );

  const archiveCampaign = async (payload: ArchiveCampaign) => {
    await archiveCampaignMutation({
      variables: { data: payload },
    });
  };

  // update
  const [updateCampaignMutation, { loading: updateCampaignLoading }] = useMutation(
    UPDATE_CAMPAIGN,
    {
      onCompleted: response => {
        const { error, data } = response?.updateCampaign || {};
        const {
          totalContactCount,
          validContactCount,
          invalidContactCount,
          duplicatesContactCount,
        } = data || {};
        if (error === null) {
          let toastMsg = '';
          if (totalContactCount < 1) {
            toastMsg = 'No contacts found in the uploaded file';
          } else {
            toastMsg = `Out of ${totalContactCount} contact , ${validContactCount} added, ${duplicatesContactCount} duplicate and ${invalidContactCount} invalid contact.`;
          }
          ToastMessage({
            content: `Campaign updated successfully. ${totalContactCount !== null ? toastMsg : ''}`,
            type: 'success',
          });
          cache.modify({
            fields: {
              getCampaignsList: () => {},
            },
          });
          history.push(`${path.SALES_DIALER}${path.CAMPAIGNS}`);
          return;
        }
        ToastMessage({
          content: error?.message ?? t('error.unspecific', 'Something went wrong.'),
          type: 'danger',
        });
      },
      onError: () => {
        ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      },
    },
  );

  const updateCampaign = async (payload: CampaignInput) => {
    await updateCampaignMutation({
      variables: { data: payload },
    });
  };

  const onCampaignPaused = () => {
    activeCallCampaignStatus(PAUSED);
    dispatch({
      type: ACTIONS.CAMPAIGN_PAUSED,
    });
  };

  const onCampaignInprogress = () => {
    activeCallCampaignStatus(INPROGRESS);
    dispatch({
      type: ACTIONS.CAMPAIGN_INPROGRESS,
    });
  };

  const onCampaignEnded = () => {
    activeCallCampaignStatus(ENDED);
    dispatch({
      type: ACTIONS.CAMPAIGN_STOPPED,
    });
    activeCampaignDurationVar(0); // reset campaign duration on campaign end
    activeCampaignCallsDurationVar(0); // reset campaign dialed calls duration on campaign end
    ToastMessage({
      content: ERROR_MESSAGES.campaignEnded,
      type: 'danger',
    });
  };

  // update
  const [controlCampaign, { loading: runCampaignLoading }] = useMutation(CONTROL_CAMPAIGN, {
    onCompleted: response => {
      const { error } = response?.controlCampaign || {};
      const { message: errMessage } = error || {};
      if (error === null) {
        cache.modify({
          fields: {
            getCampaignsList: () => {},
            campaignDetails: () => {},
          },
        });
        const { status } = response?.controlCampaign?.data || {};
        switch (status) {
          case PAUSED:
            onCampaignPaused();
            break;
          case INPROGRESS:
            onCampaignInprogress();
            break;
          case ENDED:
            onCampaignEnded();
            break;
          default:
            activeCallCampaignStatus(status);
            break;
        }
        return;
      }
      ToastMessage({
        content: errMessage || t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
    onError: () => {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const runCampaign = async (payload: any) => {
    await controlCampaign({
      variables: { data: payload },
    });
  };

  return {
    createCampaign,
    createCampaignLoading,
    archiveCampaign,
    archiveCampaignLoading,
    updateCampaign,
    updateCampaignLoading,
    runCampaign,
    runCampaignLoading,
    onCampaignPaused,
    onCampaignInprogress,
    onCampaignEnded,
  };
};
