import { KeypadButton } from './KeypadButton';
import { Wrapper } from './Styles';
import { keyPadButtons, KeyPadButtonType } from './constants';

interface IKeypadProps {
  handleKeypadClick?: (...args: any[]) => void;
}

export default function Keypad({ handleKeypadClick }: IKeypadProps) {
  return (
    <Wrapper>
      {keyPadButtons.map((char: KeyPadButtonType) => (
        <KeypadButton
          key={char?.digit}
          character={char}
          onClick={() => handleKeypadClick?.(char?.digit, char?.tone)}
        />
      ))}
    </Wrapper>
  );
}
