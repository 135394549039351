interface IManIcon {
  id?: string;
  bgColor?: string;
  color?: string;
  className?: string;
}

export default function ManIcon({
  id,
  bgColor = '#E7E6EB',
  color = '#CFCCD5',
  className = '',
}: IManIcon) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`w-full h-full ${className}`}
    >
      <rect width='20' height='20' fill={bgColor} />
      <mask
        id={id ?? 'man-icon'}
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='2'
        width='18'
        height='18'
      >
        <path
          d='M17.9901 19.5859L17.5901 17.9859C17.4003 17.2098 16.8249 16.5855 16.0667 16.3333L13.1332 15.356C12.4179 15.058 12.0906 13.906 12.0199 13.4686C12.5649 13.0154 12.9157 12.3705 12.9999 11.6666C12.9879 11.5464 13.0162 11.4256 13.0806 11.3233C13.1845 11.2973 13.2696 11.2228 13.3092 11.1233C13.5012 10.6585 13.6216 10.1674 13.6666 9.66663C13.6667 9.63942 13.6633 9.61232 13.6566 9.58598C13.6088 9.39138 13.4944 9.21967 13.3332 9.10064V7.3333C13.3332 6.2593 13.0052 5.81865 12.6599 5.56331C12.5939 5.046 12.0399 4 9.99985 4C8.18989 4.07287 6.73929 5.52343 6.66641 7.33333V9.10067C6.50522 9.2197 6.39081 9.39142 6.34306 9.58601C6.33631 9.61235 6.33297 9.63948 6.33306 9.66667C6.37794 10.1677 6.49841 10.659 6.69041 11.124C6.71929 11.2182 6.79501 11.2907 6.89042 11.3153C6.92776 11.334 6.99777 11.4307 6.99777 11.6667C7.08249 12.3726 7.43525 13.0189 7.98314 13.472C7.91313 13.9087 7.58778 15.06 6.89245 15.3507L3.93303 16.3333C3.17541 16.5855 2.6004 17.2091 2.41033 17.9847L2.01032 19.5847C1.96504 19.7631 2.07298 19.9445 2.25142 19.9897C2.27808 19.9965 2.30549 20 2.33299 20H17.6667C17.8508 19.9999 18 19.8507 18 19.6666C18 19.6393 17.9966 19.6123 17.9901 19.5859Z'
          fill='#E7E6EB'
        />
      </mask>
      <g mask={`url(#${id ?? 'man-icon'})`}>
        <rect width='20' height='20' fill={color} />
      </g>
    </svg>
  );
}
