import { Icon } from 'components/atoms';
import { createContext, useState } from 'react';
import { useCallWidgetHandler } from './hooks/useCallWidgetHandler';
import { Banner, BannerType } from './types';

export const CallWidgetContext = createContext({});

export const CallWidgetProvider = ({ children }: any) => {
  const [banner, setBanner] = useState<Banner>({});

  const showAlertBanner = (text: string, type?: BannerType) => {
    setBanner?.({
      title: text,
      closable: true,
      type: type || BannerType.error,
      icon: <Icon name='exclamation' />,
      showIcon: true,
    });
  };

  const {
    callDurationTime,
    mute,
    recording,
    recordingTime,
    loadingCallRecord,
    autoRecordingEnabled,
    hold,
    loadingCallHold,
    clientInfo,
    channelInfo,
    keypadNumberInput,
    note,
    loadingSaveNote,
    loadingCallTransfer,
    selectedMoreOption,
    loadingCampaignSaveNote,
    campaignCallNote,
    handleCallAnswer,
    handleCallReject,
    handleToggleRecording,
    handleToggleHold,
    handleToggleMute,
    handleDialKeypad,
    handleNoteSave,
    handleCallTransfer,
    handleSelectMoreAction,
    handleExecuteCampaignAction,
    handleCampaignNoteSave,
    handleSkipCampaignCallQueue,
    handleResetCallWidget,
    handleDropVoicemail,
    handleClearCampaignNote,
  } = useCallWidgetHandler({ setBanner });

  return (
    <CallWidgetContext.Provider
      value={{
        callDurationTime,
        mute,
        recording,
        recordingTime,
        loadingCallRecord,
        autoRecordingEnabled,
        hold,
        loadingCallHold,
        clientInfo,
        channelInfo,
        keypadNumberInput,
        note,
        loadingSaveNote,
        loadingCallTransfer,
        selectedMoreOption,
        loadingCampaignSaveNote,
        campaignCallNote,
        banner,
        setBanner,
        showAlertBanner,
        handleCallAnswer,
        handleCallReject,
        handleToggleRecording,
        handleToggleHold,
        handleToggleMute,
        handleDialKeypad,
        handleNoteSave,
        handleCallTransfer,
        handleSelectMoreAction,
        handleExecuteCampaignAction,
        handleCampaignNoteSave,
        handleSkipCampaignCallQueue,
        handleResetCallWidget,
        handleDropVoicemail,
        handleClearCampaignNote,
      }}
    >
      {children}
    </CallWidgetContext.Provider>
  );
};
