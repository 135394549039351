export default function MemberIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.86994 8.15232C6.79494 8.14482 6.70494 8.14482 6.62244 8.15232C5.76159 8.1231 4.94592 7.75995 4.34816 7.13979C3.7504 6.51964 3.41749 5.69116 3.41994 4.82982C3.41994 2.99232 4.90494 1.49982 6.74994 1.49982C7.63211 1.48391 8.48448 1.81909 9.11952 2.43163C9.75457 3.04417 10.1203 3.8839 10.1362 4.76607C10.1521 5.64825 9.81692 6.50062 9.20438 7.13566C8.59184 7.77071 7.75211 8.13641 6.86994 8.15232Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3075 3C13.7625 3 14.9325 4.1775 14.9325 5.625C14.9325 7.0425 13.8075 8.1975 12.405 8.25C12.3402 8.2425 12.2747 8.2425 12.21 8.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.12004 10.92C1.30504 12.135 1.30504 14.115 3.12004 15.3225C5.18254 16.7025 8.56504 16.7025 10.6275 15.3225C12.4425 14.1075 12.4425 12.1275 10.6275 10.92C8.57254 9.5475 5.19004 9.5475 3.12004 10.92V10.92Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.7549 15C14.2949 14.8875 14.8049 14.67 15.2249 14.3475C16.3949 13.47 16.3949 12.0225 15.2249 11.145C14.8124 10.83 14.3099 10.62 13.7774 10.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
