import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { REQUEST_NON_VOIP_NUMBER } from 'graphql/channel/buy/mutation';
import { ToastMessage } from 'components/atoms';

export function useRequestNonVoipNumbers(props: any) {
  const { setVisibleRequest, setCurrent, setProcessing, onModalCancel, isModalOpen } = props;
  const { t } = useTranslation();
  const [requestNonVoipMutation] = useMutation(REQUEST_NON_VOIP_NUMBER, {
    onCompleted: response => {
      const { error } = response.requestNonVoipNumbers;
      if (error === null) {
        setVisibleRequest(true);
        setCurrent(2);
        if (!isModalOpen) {
          onModalCancel?.();
        }
        return;
      }
      setVisibleRequest(false);
      setProcessing(false);
      ToastMessage({
        content: error?.message || 'Something went wrong',
        type: 'danger',
      });
    },
    onError: () => {
      setVisibleRequest(false);
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const requestNonVoipNumber = async (payloads: any) => {
    await requestNonVoipMutation({
      variables: {
        data: {
          ...payloads,
        },
      },
    });
  };
  return {
    requestNonVoipNumber,
  };
}
