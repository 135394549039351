export type PaymentIntent = {
  id: string;
  gatewayAccountId: string;
  amount: number;
  createdAt: number;
  currencyCode: string;
  expiresAt: string;
  gateway: string;
  modifiedAt: number;
  object: string;
  paymentMethodType: string;
  status: string;
  referenceId: string;
};

export const mapPaymentIntentData = (paymentIntent: PaymentIntent) => {
  const paymentIntentData = {
    amount: paymentIntent?.amount,
    created_at: paymentIntent?.createdAt,
    currency_code: paymentIntent?.currencyCode,
    expires_at: paymentIntent?.expiresAt,
    gateway: paymentIntent?.gateway,
    gateway_account_id: paymentIntent?.gatewayAccountId,
    id: paymentIntent?.id,
    modified_at: paymentIntent?.modifiedAt,
    object: paymentIntent?.object,
    payment_method_type: paymentIntent?.paymentMethodType,
    status: paymentIntent?.status,
    reference_id: paymentIntent?.referenceId || undefined,
  };

  return paymentIntentData;
};
