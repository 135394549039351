import { useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_TEAMS } from 'graphql/organization/team';
import { AuthContext } from 'contexts/auth/AuthContext';

export function useTeamsQuery() {
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { activeWorkspaceId } = useContext(AuthContext);

  const [loadTeams, { called, loading }] = useLazyQuery(GET_TEAMS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (arg: any) => {
      setTeams(arg?.teams?.data ?? []);
      setFilteredTeams(arg?.teams?.data ?? []);
    },
  });

  useEffect(() => {
    loadTeams({});
    return () => {
      setTeams([]);
      setFilteredTeams([]);
    };
  }, [loadTeams, activeWorkspaceId]);

  const filterListObject = (query: string, list: any) => {
    const lowSearch = query?.toLowerCase();
    return list?.filter?.((item: any) => item?.title?.toLowerCase()?.includes(lowSearch));
  };

  const onSearch = (evt: any) => {
    const keyword = evt.target.value;
    const filteredList = filterListObject(keyword, teams);
    setSearchTerm(keyword);
    setFilteredTeams(keyword ? filteredList : teams);
  };

  const hasTeams = teams.length > 0;
  const count = searchTerm ? filteredTeams.length : teams.length;
  return {
    teams,
    filteredTeams,
    hasTeams,
    count,
    loading,
    called,
    onSearch,
    searchTerm,
    setTeams,
    setSearchTerm,
  };
}
