import { activeSocket } from 'services/apollo';

type IPingArgs = {
  memberList: any;
  loggedInMemberId: string;
  isUserOnline: boolean;
  accessToken: string;
};

const sendPingMessage = ({
  memberList,
  loggedInMemberId,
  isUserOnline,
  accessToken,
}: IPingArgs) => {
  if (isUserOnline) {
    const loggedInMemberIndex = memberList?.findIndex((member: any) => {
      return member?.node?.id === loggedInMemberId;
    });

    if (loggedInMemberIndex !== -1) {
      const currentUser = memberList[loggedInMemberIndex];
      const subscriptionPayload = {
        source: 'web',
        timestamp: Math.round(new Date().getTime()),
        stayOnline: isUserOnline,
        onlineConnection: currentUser?.node?.online,
        accessToken: currentUser?.node?.online ? '' : accessToken,
      };

      activeSocket?.send(
        JSON.stringify({ id: '_ids', type: 'ping', payload: subscriptionPayload }),
      );
    }
  }
};

export default sendPingMessage;
