export default function CampaignVector() {
  return (
    <svg
      width='200'
      height='200'
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='100' cy='100' r='100' fill='#E9F0FE' />
      <g clipPath='url(#clip0_15466_150649)'>
        <path
          d='M91.25 64.375H136.25C138.327 64.375 140 66.0475 140 68.125V105C140 107.078 138.327 108.75 136.25 108.75H91.25C89.1725 108.75 87.5 107.078 87.5 105V68.125C87.5 66.0475 89.1725 64.375 91.25 64.375Z'
          fill='#E4EFF8'
        />
        <path
          d='M87.5 70V63.75C87.5 61.6725 89.1725 60 91.25 60H136.25C138.327 60 140 61.6725 140 63.75V70H87.5Z'
          fill='#FFCA28'
        />
        <path
          d='M68.75 90C66.6726 90 65 91.6725 65 93.75V130H130V93.75C130 91.6725 128.327 90 126.25 90H68.75Z'
          fill='#E4EFF8'
        />
        <path
          d='M75 90V116.25C75 118.328 76.6725 120 78.75 120H128.75C129.191 120 129.608 119.912 130 119.773V93.7498C130 91.6723 128.327 89.9998 126.25 89.9998L75 90Z'
          fill='#C4DCF0'
        />
        <path
          d='M130 105.947C121.764 122.158 78.043 125.469 65 126.101V130H130V105.947Z'
          fill='#C4DCF0'
        />
        <path
          d='M73.75 95C71.6726 95 70 96.6725 70 98.75V135H125V98.75C125 96.6725 123.328 95 121.25 95H73.75Z'
          fill='#549AD4'
        />
        <path
          d='M75 95V116.25C75 118.328 76.6725 120 78.75 120H125V98.75C125 96.6725 123.327 95 121.25 95H75Z'
          fill='#3588CB'
        />
        <path
          d='M125 111.753C112.386 121.652 84.2826 124.805 70 125.796V135H125V111.753Z'
          fill='#3588CB'
        />
        <path
          d='M80 71.25H130C132.077 71.25 133.75 72.9225 133.75 75V116.25C133.75 118.328 132.077 120 130 120H80C77.9225 120 76.25 118.328 76.25 116.25V75C76.25 72.9225 77.9225 71.25 80 71.25Z'
          fill='#E4EFF8'
        />
        <path
          d='M131.375 71.5161C131.142 109.848 89.0472 115.319 76.25 116.101V116.25C76.25 118.327 77.9225 120 80 120H130C132.077 120 133.75 118.327 133.75 116.25V75C133.75 73.4095 132.766 72.0631 131.375 71.5161V71.5161Z'
          fill='#C4DCF0'
        />
        <path
          d='M76.25 81.25V75C76.25 72.9225 77.9225 71.25 80 71.25H130C132.077 71.25 133.75 72.9225 133.75 75V81.25H76.25Z'
          fill='#427096'
        />
        <path
          d='M135 130V132.5C135 136.642 131.642 140 127.5 140H67.5C63.3579 140 60 136.642 60 132.5V130H135Z'
          fill='#ACD5F7'
        />
        <path
          d='M130 130V132.5C130 136.642 126.642 140 122.5 140H127.5C131.642 140 135 136.642 135 132.5V130H130Z'
          fill='#C4DCF0'
        />
        <path
          d='M106.25 130V132.5C106.25 133.885 105.135 135 103.75 135H92.5C91.115 135 90 133.885 90 132.5V130H106.25Z'
          fill='#427096'
        />
        <path
          d='M82.5 76.25C82.5 76.9404 81.9404 77.5 81.2501 77.5C80.5597 77.5 80 76.9404 80 76.25C80 75.5597 80.5597 75 81.2501 75C81.9404 75 82.5 75.5597 82.5 76.25Z'
          fill='#E4EFF8'
        />
        <path
          d='M87.5 76.25C87.5 76.9404 86.9404 77.5 86.25 77.5C85.5597 77.5 85.0001 76.9404 85.0001 76.25C85.0001 75.5597 85.5597 75 86.25 75C86.9404 75 87.5 75.5597 87.5 76.25Z'
          fill='#E4EFF8'
        />
        <path
          d='M92.5 76.25C92.5 76.9404 91.9403 77.5 91.25 77.5C90.5596 77.5 90 76.9404 90 76.25C90 75.5597 90.5596 75 91.25 75C91.9403 75 92.5 75.5597 92.5 76.25Z'
          fill='#E4EFF8'
        />
        <path d='M94.9994 71.2482V81.2506H97.5V71.2482H94.9994Z' fill='#E4EFF8' />
        <path
          d='M93.75 65C93.75 65.6904 93.1904 66.25 92.5 66.25C91.8096 66.25 91.25 65.6904 91.25 65C91.25 64.3097 91.8096 63.75 92.5 63.75C93.1904 63.75 93.75 64.3096 93.75 65Z'
          fill='#E4EFF8'
        />
        <path
          d='M98.75 65C98.75 65.6904 98.1903 66.25 97.5 66.25C96.8096 66.25 96.25 65.6904 96.25 65C96.25 64.3096 96.8096 63.75 97.5 63.75C98.1904 63.75 98.75 64.3097 98.75 65Z'
          fill='#E4EFF8'
        />
        <path
          d='M103.75 65C103.75 65.6904 103.19 66.25 102.5 66.25C101.81 66.25 101.25 65.6904 101.25 65C101.25 64.3097 101.81 63.75 102.5 63.75C103.19 63.75 103.75 64.3097 103.75 65Z'
          fill='#E4EFF8'
        />
        <path d='M106.248 60V70.0025H108.748V60H106.248Z' fill='#E4EFF8' />
        <path
          d='M102.501 92.4988C100.834 92.4988 100.834 94.9994 102.501 94.9994H126.252C127.92 94.9994 127.92 92.4988 126.252 92.4988H102.501Z'
          fill='#427096'
        />
        <path
          d='M102.52 87.4976C100.85 87.4945 100.85 90.0012 102.52 89.9982H119.996C121.666 90.0012 121.666 87.4945 119.996 87.4976H102.52Z'
          fill='#427096'
        />
        <path
          d='M95 110C95 111.385 93.885 112.5 92.5 112.5H86.25C84.865 112.5 83.75 111.385 83.75 110V105C83.75 103.615 84.8958 102.21 86.25 102.5L95 102.5V110Z'
          fill='white'
        />
        <path
          d='M95 93.75C95 95.135 93.885 96.25 92.5 96.25H86.25C84.865 96.25 83.75 95.135 83.75 93.75V88.7501C83.75 87.3651 84.8958 85.9599 86.25 86.2501L95 86.25V93.75Z'
          fill='white'
        />
        <path
          d='M102.501 110.001C100.834 110.001 100.834 112.501 102.501 112.501H126.252C127.92 112.501 127.92 110.001 126.252 110.001H102.501Z'
          fill='#365B7A'
        />
        <path
          d='M102.52 104.999C100.85 104.996 100.85 107.503 102.52 107.5H119.996C121.666 107.503 121.666 104.996 119.996 104.999H102.52Z'
          fill='#427096'
        />
        <path
          d='M113.455 105C112.084 105.905 110.675 106.735 109.241 107.5H119.995C121.699 107.538 121.699 104.962 119.995 105H113.455Z'
          fill='#365B7A'
        />
        <path
          d='M94.9292 102.5C94.1104 110.446 85.6423 111.169 84.0869 111.235C84.5169 111.988 85.3178 112.5 86.25 112.5H92.5C93.885 112.5 95 111.385 95 110V102.5H94.9292Z'
          fill='#F8F8F8'
        />
        <path
          d='M130.295 71.2793C126.771 77.1058 115.043 79.9006 104.084 81.25H133.75V75C133.75 73.0239 132.231 71.4317 130.295 71.2793Z'
          fill='#365B7A'
        />
        <path
          d='M136.545 60.0293C133.021 65.8558 121.293 68.6506 110.334 70H140V63.75C140 61.7739 138.481 60.1817 136.545 60.0293Z'
          fill='#ECB200'
        />
        <path
          d='M94.9292 86.25C94.1104 94.1963 85.6423 94.9194 84.0869 94.9854C84.5169 95.738 85.3178 96.25 86.25 96.25H92.5C93.885 96.25 95 95.135 95 93.75V86.25H94.9292Z'
          fill='#F8F8F8'
        />
      </g>
      <defs>
        <clipPath id='clip0_15466_150649'>
          <rect width='80' height='80' fill='white' transform='translate(60 60)' />
        </clipPath>
      </defs>
    </svg>
  );
}
