export default function Monitoricon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 9.75042H4.32C4.59906 9.75057 4.87255 9.82856 5.10972 9.97563C5.34688 10.1227 5.53832 10.333 5.6625 10.5829L6.33 11.9254C6.75 12.7504 7.5 12.7504 7.68 12.7504H10.3275C10.6066 12.7503 10.8801 12.6723 11.1172 12.5252C11.3544 12.3781 11.5458 12.1678 11.67 11.9179L12.3375 10.5754C12.4617 10.3255 12.6531 10.1152 12.8903 9.96813C13.1274 9.82106 13.4009 9.74307 13.68 9.74292H16.485'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
