import { ServerError } from './Errors';

export default function ErrorFallback({ resetErrorBoundary }: any) {
  const sendFeedback = () => {
    // TODO: HANDLE SEND FEEDBACK
    console.log('Send feedback');
  };

  return <ServerError action={resetErrorBoundary} hasFeedback onReport={sendFeedback} />;
}
