export default function NonVoip() {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_209_26687)'>
        <path
          d='M18.75 24.125H5.25C4.85218 24.125 4.47064 23.967 4.18934 23.6857C3.90804 23.4044 3.75 23.0228 3.75 22.625V5.375L8.25 0.875H18.75C19.1478 0.875 19.5294 1.03304 19.8107 1.31434C20.092 1.59564 20.25 1.97718 20.25 2.375V22.625C20.25 23.0228 20.092 23.4044 19.8107 23.6857C19.5294 23.967 19.1478 24.125 18.75 24.125Z'
          fill='#637991'
        />
        <path
          d='M6.375 24.125C5.97718 24.125 5.59564 23.967 5.31434 23.6857C5.03304 23.4044 4.875 23.0228 4.875 22.625V5.84075L8.71575 2H18.75C19.1478 2 19.5294 2.15804 19.8107 2.43934C20.092 2.72064 20.25 3.10218 20.25 3.5V22.625C20.25 23.0228 20.092 23.4044 19.8107 23.6857C19.5294 23.967 19.1478 24.125 18.75 24.125H6.375Z'
          fill='#4A6078'
        />
        <path
          d='M16.5 11H7.5C6.87868 11 6.375 11.5037 6.375 12.125V21.125C6.375 21.7463 6.87868 22.25 7.5 22.25H16.5C17.1213 22.25 17.625 21.7463 17.625 21.125V12.125C17.625 11.5037 17.1213 11 16.5 11Z'
          fill='#FFDB43'
        />
        <path
          d='M15.375 15.7812C15.4496 15.7812 15.5211 15.7516 15.5739 15.6989C15.6266 15.6461 15.6562 15.5746 15.6562 15.5V13.5312H17.625V12.9688H13.4062V11H12.8438V12.9688H8.90625V11H8.34375V13.25C8.34375 13.3246 8.37338 13.3961 8.42613 13.4489C8.47887 13.5016 8.55041 13.5312 8.625 13.5312H10.5938V15.2188H6.375V15.7812H10.5938V22.25H11.1562V20.2812H17.625V19.7188H11.1562V18.0312H17.625V17.4688H11.1562V15.7812H15.375ZM11.1562 13.5312H15.0938V15.2188H11.1562V13.5312Z'
          fill='#F4CC21'
        />
        <path
          d='M8.625 17.4688H6.375V18.0312H8.34375V22.25H8.90625V17.75C8.90625 17.6754 8.87662 17.6039 8.82387 17.5511C8.77113 17.4984 8.69959 17.4688 8.625 17.4688Z'
          fill='#F4CC21'
        />
        <path
          d='M8.25 7.53125C8.17541 7.53125 8.10387 7.50162 8.05113 7.44887C7.99838 7.39613 7.96875 7.32459 7.96875 7.25V4.25C7.96875 4.17541 7.99838 4.10387 8.05113 4.05113C8.10387 3.99838 8.17541 3.96875 8.25 3.96875C8.32459 3.96875 8.39613 3.99838 8.44887 4.05113C8.50162 4.10387 8.53125 4.17541 8.53125 4.25V7.25C8.53125 7.32459 8.50162 7.39613 8.44887 7.44887C8.39613 7.50162 8.32459 7.53125 8.25 7.53125Z'
          fill='#FFDB43'
        />
        <path
          d='M9.75 7.53125C9.67541 7.53125 9.60387 7.50162 9.55113 7.44887C9.49838 7.39613 9.46875 7.32459 9.46875 7.25V4.25C9.46875 4.17541 9.49838 4.10387 9.55113 4.05113C9.60387 3.99838 9.67541 3.96875 9.75 3.96875C9.82459 3.96875 9.89613 3.99838 9.94887 4.05113C10.0016 4.10387 10.0312 4.17541 10.0312 4.25V7.25C10.0312 7.32459 10.0016 7.39613 9.94887 7.44887C9.89613 7.50162 9.82459 7.53125 9.75 7.53125Z'
          fill='#FFDB43'
        />
        <path
          d='M11.25 7.53125C11.1754 7.53125 11.1039 7.50162 11.0511 7.44887C10.9984 7.39613 10.9688 7.32459 10.9688 7.25V4.25C10.9688 4.17541 10.9984 4.10387 11.0511 4.05113C11.1039 3.99838 11.1754 3.96875 11.25 3.96875C11.3246 3.96875 11.3961 3.99838 11.4489 4.05113C11.5016 4.10387 11.5312 4.17541 11.5312 4.25V7.25C11.5312 7.32459 11.5016 7.39613 11.4489 7.44887C11.3961 7.50162 11.3246 7.53125 11.25 7.53125Z'
          fill='#FFDB43'
        />
        <path
          d='M12.75 7.53125C12.6754 7.53125 12.6039 7.50162 12.5511 7.44887C12.4984 7.39613 12.4688 7.32459 12.4688 7.25V4.25C12.4688 4.17541 12.4984 4.10387 12.5511 4.05113C12.6039 3.99838 12.6754 3.96875 12.75 3.96875C12.8246 3.96875 12.8961 3.99838 12.9489 4.05113C13.0016 4.10387 13.0312 4.17541 13.0312 4.25V7.25C13.0312 7.32459 13.0016 7.39613 12.9489 7.44887C12.8961 7.50162 12.8246 7.53125 12.75 7.53125Z'
          fill='#FFDB43'
        />
        <path
          d='M14.25 7.53125C14.1754 7.53125 14.1039 7.50162 14.0511 7.44887C13.9984 7.39613 13.9688 7.32459 13.9688 7.25V4.25C13.9688 4.17541 13.9984 4.10387 14.0511 4.05113C14.1039 3.99838 14.1754 3.96875 14.25 3.96875C14.3246 3.96875 14.3961 3.99838 14.4489 4.05113C14.5016 4.10387 14.5312 4.17541 14.5312 4.25V7.25C14.5312 7.32459 14.5016 7.39613 14.4489 7.44887C14.3961 7.50162 14.3246 7.53125 14.25 7.53125Z'
          fill='#FFDB43'
        />
        <path
          d='M15.75 7.53125C15.6754 7.53125 15.6039 7.50162 15.5511 7.44887C15.4984 7.39613 15.4688 7.32459 15.4688 7.25V4.25C15.4688 4.17541 15.4984 4.10387 15.5511 4.05113C15.6039 3.99838 15.6754 3.96875 15.75 3.96875C15.8246 3.96875 15.8961 3.99838 15.9489 4.05113C16.0016 4.10387 16.0312 4.17541 16.0312 4.25V7.25C16.0312 7.32459 16.0016 7.39613 15.9489 7.44887C15.8961 7.50162 15.8246 7.53125 15.75 7.53125Z'
          fill='#FFDB43'
        />
      </g>
      <defs>
        <clipPath id='clip0_209_26687'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
