import { css } from 'styled-components';

export const paddings = css`
  .px {
    &-8 {
      padding-right: 8px;
      padding-left: 8px;
    }
    &-10 {
      padding-right: 10px;
      padding-left: 10px;
    }
    &-12 {
      padding-right: 12px;
      padding-left: 12px;
    }
    &-14 {
      padding-right: 14px;
      padding-left: 14px;
    }
    &-15 {
      padding-right: 15px;
      padding-left: 15px;
    }
    &-16 {
      padding-right: 16px;
      padding-left: 16px;
    }
    &-20 {
      padding-right: 20px;
      padding-left: 20px;
    }
    &-24 {
      padding-right: 24px;
      padding-left: 24px;
    }
    &-25 {
      padding-right: 25px;
      padding-left: 25px;
    }
    &-31 {
      padding-right: 31px;
      padding-left: 31px;
    }
    &-32 {
      padding-right: 32px;
      padding-left: 32px;
    }
  }
  .py {
    &-8 {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &-9 {
      padding-top: 9px;
      padding-bottom: 9px;
    }
    &-10 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &-12 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    &-18 {
      padding-top: 18px;
      padding-bottom: 18px;
    }
    &-24 {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  .pr {
    &-8 {
      padding-right: 8px;
    }
    &-12 {
      padding-right: 12px;
    }
    &-15 {
      padding-right: 15px;
    }
    &-16 {
      padding-right: 16px;
    }
    &-20 {
      padding-right: 20px;
    }
    &-24 {
      padding-right: 24px;
    }
    &-32 {
      padding-right: 32px;
    }
  }
  .pl {
    &-8 {
      padding-left: 8px;
    }
    &-12 {
      padding-left: 12px;
    }
    &-15 {
      padding-left: 15px;
    }
    &-16 {
      padding-left: 16px;
    }
    &-20 {
      padding-left: 20px;
    }
    &-24 {
      padding-left: 24px;
    }
    &-32 {
      padding-left: 32px;
    }
    &-60 {
      padding-left: 60px;
    }
  }
  .pb {
    &-8 {
      padding-bottom: 8px;
    }
    &-12 {
      padding-bottom: 12px;
    }
    &-15 {
      padding-bottom: 15px;
    }
    &-16 {
      padding-bottom: 16px;
    }
    &-19 {
      padding-bottom: 19px;
    }
    &-20 {
      padding-bottom: 20px;
    }
    &-24 {
      padding-bottom: 24px;
    }
    &-32 {
      padding-bottom: 32px;
    }
    &-40 {
      padding-bottom: 40px;
    }
  }
`;
