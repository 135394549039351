import React from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';

const TextAreaInput = styled(AntInput.TextArea)`
  border: 1px solid #f6f8fb;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.primary.p0};
  min-height: 38px !important;
  margin-top: 3px;
  transition: 0s;
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.text.font.body};
  color: ${({ theme }) => theme.colors.text.t8};
  .ant-input {
    width: 100%;
  }
  &.ant-input-textarea-show-count {
    border: transparent !important;
    background-color: transparent !important;
    textarea {
      background: #f5f2f8;
      border: 1px solid #d7cce4;
      border-radius: 8px;
      margin-bottom: 12px;
    }
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.p2};
  }
`;
export default React.memo(TextAreaInput);
