const KycVerification = ({ className }: { className?: string }) => (
  <svg
    width='200'
    height='200'
    viewBox='0 0 200 200'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <circle cx='100' cy='100' r='100' fill='#E9F0FE' />
    <path
      d='M134.483 123.449H65.5172C62.4702 123.449 60 120.979 60 117.932V76.5523C60 73.5053 62.4702 71.0352 65.5172 71.0352H134.483C137.53 71.0352 140 73.5053 140 76.5523V117.932C140 120.979 137.53 123.449 134.483 123.449Z'
      fill='#F5FBFF'
    />
    <path
      d='M83.4482 106.896C76.5921 106.896 71.0344 112.454 71.0344 119.31C71.0344 120.764 71.2971 122.152 71.756 123.448H95.1405C95.5993 122.152 95.8621 120.764 95.8621 119.31C95.8619 112.454 90.3043 106.896 83.4482 106.896Z'
      fill='#D5DCED'
    />
    <path
      d='M99.9999 117.93H66.8965C66.1347 117.93 65.5171 117.312 65.5171 116.551V77.9302C65.5171 77.1684 66.1347 76.5508 66.8965 76.5508H99.9999C100.762 76.5508 101.379 77.1684 101.379 77.9302V116.551C101.379 117.312 100.762 117.93 99.9999 117.93Z'
      fill='#AFB9D2'
    />
    <path
      d='M71.1152 117.931H95.7812C95.0945 111.725 89.8376 106.896 83.4482 106.896C77.0588 106.896 71.8021 111.725 71.1152 117.931Z'
      fill='#959CB5'
    />
    <path
      d='M94.4828 112.413C95.2445 112.413 95.8621 111.795 95.8621 111.034V107.505C95.8621 106.317 95.1023 105.263 93.9759 104.888L88.5273 103.071C87.9651 102.884 87.5854 102.358 87.5842 101.766L87.579 99.313C88.4401 98.8826 89.0145 98.0343 89.0943 97.0748L89.3957 93.7902C89.9728 93.2848 90.3448 92.5507 90.3448 91.7232V84.8266C90.3448 84.0649 89.7271 83.4473 88.9654 83.4473H80.6895C78.4042 83.4473 76.5515 85.2999 76.5515 87.5852V91.7232C76.5515 92.5509 76.9236 93.2848 77.5006 93.7902L77.802 97.0748C77.8818 98.0341 78.4564 98.8826 79.3173 99.313L79.3121 101.766C79.3109 102.359 78.9312 102.884 78.369 103.071L72.9204 104.888C71.794 105.263 71.0342 106.317 71.0342 107.505V111.034C71.0342 111.795 71.6518 112.413 72.4136 112.413H94.4828Z'
      fill='#E4EAF8'
    />
    <path
      d='M126.207 80.6904H106.896C106.134 80.6904 105.517 80.0727 105.517 79.311C105.517 78.5493 106.134 77.9316 106.896 77.9316H126.207C126.969 77.9316 127.586 78.5493 127.586 79.311C127.586 80.0727 126.969 80.6904 126.207 80.6904Z'
      fill='#7F8499'
    />
    <path
      d='M133.103 86.206H106.896C106.134 86.206 105.517 85.5884 105.517 84.8266C105.517 84.0649 106.134 83.4473 106.896 83.4473H133.103C133.866 83.4473 134.483 84.0649 134.483 84.8266C134.483 85.5885 133.866 86.206 133.103 86.206Z'
      fill='#707487'
    />
    <path
      d='M133.103 105.517H115.172C114.41 105.517 113.793 104.899 113.793 104.137C113.793 103.375 114.41 102.758 115.172 102.758H133.103C133.866 102.758 134.483 103.375 134.483 104.137C134.483 104.899 133.866 105.517 133.103 105.517Z'
      fill='#959CB5'
    />
    <path
      d='M109.655 105.517H106.896C106.134 105.517 105.517 104.899 105.517 104.137C105.517 103.375 106.134 102.758 106.896 102.758H109.655C110.417 102.758 111.034 103.375 111.034 104.137C111.034 104.899 110.418 105.517 109.655 105.517Z'
      fill='#7F8499'
    />
    <path
      d='M128.965 111.034H115.172C114.41 111.034 113.793 110.416 113.793 109.655C113.793 108.893 114.41 108.275 115.172 108.275H128.965C129.728 108.275 130.345 108.893 130.345 109.655C130.345 110.416 129.728 111.034 128.965 111.034Z'
      fill='#959CB5'
    />
    <path
      d='M109.655 111.034H106.896C106.134 111.034 105.517 110.416 105.517 109.655C105.517 108.893 106.134 108.275 106.896 108.275H109.655C110.417 108.275 111.034 108.893 111.034 109.655C111.034 110.416 110.418 111.034 109.655 111.034Z'
      fill='#7F8499'
    />
    <path
      d='M128.965 116.552H115.172C114.41 116.552 113.793 115.934 113.793 115.172C113.793 114.411 114.41 113.793 115.172 113.793H128.965C129.728 113.793 130.345 114.411 130.345 115.172C130.345 115.934 129.728 116.552 128.965 116.552Z'
      fill='#959CB5'
    />
    <path
      d='M109.655 116.552H106.896C106.134 116.552 105.517 115.934 105.517 115.172C105.517 114.411 106.134 113.793 106.896 113.793H109.655C110.417 113.793 111.034 114.411 111.034 115.172C111.034 115.934 110.418 116.552 109.655 116.552Z'
      fill='#7F8499'
    />
    <path
      d='M109.655 97.9317C111.56 97.9317 113.103 96.3879 113.103 94.4834C113.103 92.579 111.56 91.0352 109.655 91.0352C107.751 91.0352 106.207 92.579 106.207 94.4834C106.207 96.3879 107.751 97.9317 109.655 97.9317Z'
      fill='#9BF57D'
    />
    <path
      d='M120.69 97.9317C122.594 97.9317 124.138 96.3879 124.138 94.4834C124.138 92.579 122.594 91.0352 120.69 91.0352C118.785 91.0352 117.241 92.579 117.241 94.4834C117.241 96.3879 118.785 97.9317 120.69 97.9317Z'
      fill='#FFDC64'
    />
    <path
      d='M131.724 97.9317C133.629 97.9317 135.172 96.3879 135.172 94.4834C135.172 92.579 133.629 91.0352 131.724 91.0352C129.82 91.0352 128.276 92.579 128.276 94.4834C128.276 96.3879 129.82 97.9317 131.724 97.9317Z'
      fill='#FFDC64'
    />
    <path
      d='M93.7689 112.414C91.5418 109.087 87.7518 106.896 83.4484 106.896C79.145 106.896 75.355 109.088 73.1279 112.414H93.7689Z'
      fill='#D5DCED'
    />
    <path
      d='M83.4484 128.965C88.7808 128.965 93.1035 124.642 93.1035 119.309C93.1035 113.977 88.7808 109.654 83.4484 109.654C78.116 109.654 73.7932 113.977 73.7932 119.309C73.7932 124.642 78.116 128.965 83.4484 128.965Z'
      fill='#9BF57D'
    />
    <path
      d='M82.5283 123.448C82.2089 123.448 81.8899 123.338 81.6313 123.116L78.4133 120.357C77.8342 119.862 77.7667 118.991 78.2638 118.412C78.7594 117.834 79.631 117.768 80.2074 118.263L82.4124 120.153L86.5691 115.619C87.0836 115.057 87.9552 115.02 88.5182 115.534C89.0799 116.049 89.1177 116.922 88.603 117.483L83.545 123.001C83.2731 123.297 82.9014 123.448 82.5283 123.448Z'
      fill='white'
    />
  </svg>
);
export default KycVerification;
