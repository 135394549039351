export const errorKeys = [
  {
    errorKey: 'email_exists',
    message: 'Email already exists',
    translationKey: 'error.emailAreadyExist',
  },
  {
    errorKey: 'phone_number_exists',
    message: 'Phone Number already exists',
    translationKey: 'error.phoneAreadyExist',
  },
  {
    errorKey: 'internal_server_error',
    message: 'Internal Server Error',
    translationKey: 'error.internalServerError',
  },
  {
    errorKey: 'invalid_input',
    message: 'Invalid Input',
    translationKey: 'error.invalidInput',
  },
  {
    errorKey: 'limit_exceed',
    message: 'The upload limit is 200 contacts at a time.',
    translationKey: 'error.contactUploadlimitExceed',
  },
  {
    errorKey: 'forbidden',
    message: 'Forbidden',
    translationKey: 'error.forbidden',
  },
  {
    errorKey: 'toggle_recharge_time_limit_exceed',
    message: 'Wait for 60 seconds before attempting again',
    translationKey: 'error.toggleRechargeLimitExceed',
  },
];

export const ERROR_MESSAGES = {
  numberNotAvailable: "You don't have any numbers on this workspace",
  invalidContactName: 'Choose an existing contact or dial',
  invalidPhoneNumber: 'Invalid phone number',
  emergencyCalls: 'For emergency calls contact support',
  noMicPermission: 'Permission needed for microphone',
  accountSuspended: 'Your KrispCall Account is currently suspended due to lack of credits',
  deletedWorkspace: 'Your current workspace is deleted',
  underReview: 'Your KrispCall account is under review, Please contact support!',
  subscriptionCancelled: 'Your KrispCall subscription has been cancelled',
  subscriptionExpired: 'Your KrispCall subscription has expired',
  intlCallMsgDisabled: 'International Calls and Messages Disabled',
  selfAssignedNumber: 'Number is assigned to one of your channel',
  callNotSupported: 'Call not supported',
  deviceOffline: 'Device is offline',
  numberSubscriptionExpired: 'Number subscription has been expired',
  creditInsufficient: 'Insufficient Credit',
  campaignEnded: 'Campaign ended',
  default: 'Something went wrong',
};
