import React from 'react';
import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import {
  GrayButtonCSS,
  InfoButtonCSS,
  LightGrayButtonCSS,
  SuccessButtonCSS,
  TertiaryButtonCSS,
  WarningButtonCSS,
  OutlineButtonCSS,
} from './variants';

type AntButtonProps = React.ComponentProps<typeof AntButton>;
interface ButtonProps extends AntButtonProps {
  variant?: 'success' | 'warning' | 'info' | 'tertiary' | 'gray' | 'outline' | 'light-gray';
}

const Button = styled(AntButton)<ButtonProps>`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  span {
    font-weight: 600 !important;
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    line-height: 16px;
  }

  &.ant-btn {
    height: 40px;
    svg {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
    &.ant-btn-icon-only {
      > svg {
        margin: 0;
      }
    }
  }

  &.ant-btn-primary {
    border: none;
    &:hover {
      background-color: #2c0068;
    }
    &:focus {
      background-color: #200057;
    }
  }

  &.ant-btn-dashed {
    color: ${({ theme }) => theme.colors.primary.default};
    background: ${({ theme }) => theme.colors.primary.p1};
    border: 1px dashed ${({ theme }) => theme.colors.primary.default};
    border-radius: 8px;
  }

  // sizes
  &.ant-btn-lg {
    height: 44px;
    line-height: 16px;
    padding: 0 28px;
  }
  &.ant-btn-sm {
    height: 32px;
  }

  &.ant-btn-icon-only.ant-btn-lg {
    border-radius: 8px;
  }

  &::before {
    display: none !important;
  }

  &.btn-primary-ghost {
    border: 1px solid #613494;
    box-sizing: border-box;
    span,
    svg {
      color: #390179;
    }
  }

  ${props => props.variant === 'success' && SuccessButtonCSS}
  ${props => props.variant === 'tertiary' && TertiaryButtonCSS}
  ${props => props.variant === 'warning' && WarningButtonCSS}
  ${props => props.variant === 'info' && InfoButtonCSS}
  ${props => props.variant === 'gray' && GrayButtonCSS}
  ${props => props.variant === 'light-gray' && LightGrayButtonCSS}
  ${props => props.variant === 'outline' && OutlineButtonCSS}

  &.ant-btn-dangerous {
    background: ${({ theme }) => theme.colors.danger.default} !important;
    border: none;
    text-shadow: none;
    box-shadow: none;
    span {
      color: white !important;
    }
    &.sm-icon {
      width: 32px !important;
      svg {
        font-size: 16px;
        margin-right: 0 !important;
      }
    }
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover {
    background: #e7e6eb !important;
    span,
    svg {
      color: #857f96 !important;
    }
  }
`;

export default Button;
