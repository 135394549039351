import * as yup from 'yup';

import { profileInputs } from 'schema-validation';

export const changeEmailSchema = yup.object().shape({
  email: profileInputs.email,
  password: profileInputs.password,
});

export const editNameSchema = yup.object().shape({
  firstName: profileInputs.firstName,
  lastName: profileInputs.lastName,
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: profileInputs.oldPassword,
  newPassword: profileInputs.newPassword,
  newPasswordConfirm: profileInputs.newPasswordConfirm,
});
