import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { DND_STATUS } from 'constants/commons';
import { ToastMessage } from 'components/atoms';
import { SET_USER_DND } from 'graphql/user';
import { cache } from 'services/apollo';
import { AuthContext } from 'contexts/auth/AuthContext';
import { userOnlineStatus } from 'services/apollo/reactiveVars';
import useMemberOnlineStatus from '../workspace-detail-sidebar/members/useMemberOnlineStatus';

export default function useDND(props?: any) {
  const { t } = useTranslation();
  const { loggedInMemberId } = useContext(AuthContext);
  const { updateMemberOnlineStatus } = useMemberOnlineStatus();
  const { teamId } = useParams<Record<string, string | undefined>>();

  const [setUserDnd] = useMutation(SET_USER_DND, {
    onCompleted: args => {
      const {
        error,
        data: { dndEnabled },
      } = args?.setUserDND || {};
      if (error === null) {
        // updateMemberOnlineStatus(loggedInMemberId, !dndEnabled, teamId); //this local update is commented , as update is done on memberStatus subscription response
        // userOnlineStatus(!dndEnabled); //this local update is commented , as update is done on profile subscription response
        props?.callback();
        if (!dndEnabled) {
          try {
            localStorage.removeItem('hideStatusChangePrompt');
          } catch (e) {
            console.log(e);
          }
        }
        cache.modify({
          fields: {
            // profile: () => {}, // profile API is not refetched, waiting for userProfile subscription to set stayOnline flag on reactiveVar
          },
        });
        return;
      }
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
    onError: () => {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const selectDND = useCallback(
    async (dndValue: string) => {
      const dndMinutes = dndValue === 'UNTIL_I_RESUME' ? 0 : DND_STATUS[dndValue];
      await setUserDnd({
        variables: {
          data: {
            removeFromDND: dndValue === 'OFF',
            minutes: dndMinutes,
          },
        },
      });
    },
    [setUserDnd],
  );

  return { selectDND };
}
