export default function UserSquare({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.02246 20.5037C6.30552 19.1097 6.99625 18.3664 8.09763 17.4661C9.19901 16.566 10.5778 16.0742 12.0002 16.0742C13.4227 16.0742 14.8014 16.566 15.9028 17.4661C17.0042 18.3664 17.7854 19.1097 18.0684 20.5037'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9999 13.0278C13.8277 13.0278 15.3093 11.5462 15.3093 9.71838C15.3093 7.89062 13.8277 6.40894 11.9999 6.40894C10.1721 6.40894 8.69043 7.89062 8.69043 9.71838C8.69043 11.5462 10.1721 13.0278 11.9999 13.0278Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='2.75'
        y='2.75'
        width='18.5'
        height='18.5'
        rx='6'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
}
