import { Avatar } from 'antd';
import styled from 'styled-components';
import { RiArrowDownSLine } from 'react-icons/ri';

import { Channel } from 'generated/graphql';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { Tooltip } from 'components/atoms';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .avatar-container {
    width: 36px;
    height: 36px;
    display: flex;
    padding: 11px 8px;
    border-radius: 50%;
    .avatar {
      width: 100%;
      height: 14px;
    }
  }
  svg.dropdown {
    color: #ffffff;
    font-size: 24px;
    padding: 2px;
    border-radius: 50%;
    .ant-dropdown-open & {
      background: #301c58;
    }
  }
  &.dialer-numbers-dropdown {
    .ant-menu-item:hover & .avatar-container,
    .ant-menu-item-selected & .avatar-container {
      background: #ece6f2;
    }
  }
`;
interface Props {
  channel: Channel | null;
  arrow?: boolean;
  className?: string;
  isListItem?: boolean;
}
export default function NumberCardWithDropdown({ channel, arrow, className, isListItem }: Props) {
  const { number = '', name = '' } = channel || {};
  const { phoneFormatIntl, flagUrl } = parsePhoneNumber(number);

  return (
    <Wrapper className={className}>
      <div className='flex items-center'>
        <div
          className={`${
            arrow ? 'bg-white bg-opacity-15' : 'bg-primary-50'
          } avatar-container mr-2.5`}
        >
          <Avatar shape='square' className='avatar' src={flagUrl} />
        </div>
        <div className='number-details'>
          <Tooltip title={name}>
            <h6
              className={`${
                arrow ? 'text-white mb-0.5' : 'text-gray-600'
              } text-13 leading-5 font-semibold truncate w-40`}
            >
              {name}
            </h6>
          </Tooltip>
          <p
            className={`${
              arrow ? 'text-gray-300' : 'text-gray-500'
            } font-heebo font-medium text-13 leading-4.5`}
          >
            {phoneFormatIntl}
          </p>
        </div>
      </div>
      {arrow && <RiArrowDownSLine className='dropdown' />}
    </Wrapper>
  );
}
