import { createContext } from 'react';
import { useTouchTone } from './useTouchTone';

export const TouchToneContext = createContext({});

export const TouchToneProvider = ({ children }: any) => {
  const { play } = useTouchTone();

  return (
    <TouchToneContext.Provider
      value={{
        play,
      }}
    >
      {children}
    </TouchToneContext.Provider>
  );
};
