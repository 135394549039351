import { css } from 'styled-components';

export const AudioSettingsDropdownCSS = css`
  border-radius: var(--Horizontal, 12px);
  border: 1px solid var(--Stroke-100-Default, #e5e4e7);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  .ant-select-item {
    font-weight: 420;
    color: var(--Text-500-Component, #333036);
    min-height: 40px;
    padding: 0 14px;
    &-option-selected {
      background: var(--Background-300, #f5f4f6);
    }
  }
`;
