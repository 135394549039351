export default function Send({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4457 7.5543L7.16568 11.2202L1.643 9.37907C1.25751 9.25031 0.997796 8.88863 1.00001 8.4823C1.00226 8.07597 1.26495 7.71651 1.65193 7.59224L15.7715 3.04521C16.1072 2.93732 16.4756 3.02586 16.7249 3.27518C16.9742 3.5245 17.0627 3.89287 16.9548 4.22851L12.4078 18.3481C12.2835 18.7351 11.9241 18.9978 11.5177 19C11.1114 19.0022 10.7497 18.7425 10.621 18.357L8.77089 12.8076L12.4457 7.5543Z'
        fill='currentColor'
      />
    </svg>
  );
}
