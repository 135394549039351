import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon, Tooltip } from 'components/atoms';
import { useHistory } from 'react-router-dom';

import UserLockIcon from 'components/atoms/icon/UserLockIcon';
import { StyledModal } from './Styles';

interface OneUserLoginModalProps {
  open: boolean;
  loading: boolean;
  handleUseHere: () => void;
}

const OneUserLoginModal = ({ open, handleUseHere, loading }: OneUserLoginModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <StyledModal
      width={348}
      centered
      visible={open}
      maskClosable={false}
      keyboard={false}
      zIndex={2500}
      className='one-user-login-modal'
      footer={[
        <Button
          variant='light-gray'
          key='back'
          type='link'
          size='large'
          className='btn min-w-120'
          onClick={() => history.push('/')}
        >
          {t('common.action.cancel', 'Cancel')}
        </Button>,
        <Button
          data-cy='login-modal-submit'
          key='submit'
          type='primary'
          className='capitalize btn min-w-164'
          size='large'
          disabled={loading}
          loading={loading}
          onClick={handleUseHere}
        >
          {t('signinHere', 'Sign in here')}
        </Button>,
      ]}
      closable={false}
    >
      <div className='bg-primary-50 h-16 w-16 flex items-center justify-center rounded-20 mx-auto mb-6'>
        <UserLockIcon />
      </div>
      <h5 className='text-center text-xl text-gray-700 leading-7 font-bold mb-2'>
        <Trans i18nKey='singleLoginOnly'>Single login only</Trans>
      </h5>
      <p className='text-15 leading-5.5 text-gray text-center'>
        <Trans i18nKey='duplicateLoginText'>
          This account is logged in to another device. Sign in here instead?
        </Trans>
        <Tooltip
          placement='right'
          title='Only one session is allowed at a time. You will be signed out of the other device.'
          getPopupContainer={(trigger: any) => trigger.parentNode}
        >
          <Icon name='info' className='info-icon' />
        </Tooltip>
      </p>
    </StyledModal>
  );
};

export default OneUserLoginModal;
