import React, { ReactElement } from 'react';

import { Icon } from 'components/atoms';
import { ButtonPrimary, ButtonSecondary } from './Styles';

interface IAction {
  action: string;
  icon: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  disabled?: boolean;
  isFeatureAvailable?: boolean;
  type?: string;
}

export function Action({
  action,
  icon,
  onClick,
  className,
  disabled,
  isFeatureAvailable = true,
  type = 'primary',
}: IAction): ReactElement {
  return (
    <>
      {type === 'primary' ? (
        <ButtonPrimary
          onClick={onClick}
          className={`${className} ${disabled && 'disabled'}`}
          disabled={disabled || !isFeatureAvailable}
        >
          <div className='icon'>
            <Icon name={icon} />
          </div>
          <p className='text-gray-600 text-xs leading-3'>{action}</p>
        </ButtonPrimary>
      ) : (
        <ButtonSecondary
          onClick={onClick}
          className={`${className} ${disabled && 'disabled'}`}
          disabled={disabled || !isFeatureAvailable}
        >
          <div className='icon'>
            <Icon name={icon} />
          </div>
          <p className='text-gray-600 text-xs leading-3 font-semibold font-manrope'>{action}</p>
        </ButtonSecondary>
      )}
    </>
  );
}
