export default function MegaPhone() {
  return (
    <svg width='73' height='73' viewBox='0 0 73 73' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4504_80164)'>
        <path
          d='M60.0469 7.69362C60.8128 8.45954 60.8128 9.7014 60.0469 10.4673L55.0181 15.4962C54.2522 16.2621 53.0103 16.2621 52.2444 15.4962C51.4784 14.7302 51.4784 13.4884 52.2444 12.7225L57.2732 7.69362C58.0391 6.92769 59.281 6.92769 60.0469 7.69362Z'
          fill='url(#paint0_linear_4504_80164)'
        />
        <path
          d='M67.7406 25.5998C67.7406 26.6831 66.8625 27.5612 65.7794 27.5612H58.6674C57.5842 27.5612 56.7062 26.6831 56.7062 25.6C56.7062 24.5167 57.5843 23.6387 58.6674 23.6387H65.7794C66.8625 23.6384 67.7406 24.5166 67.7406 25.5998Z'
          fill='url(#paint1_linear_4504_80164)'
        />
        <path
          d='M42.1408 11.0346C41.0576 11.0346 40.1794 10.1564 40.1794 9.07319V1.96141C40.1794 0.878147 41.0576 0 42.1408 0C43.2241 0 44.1023 0.878147 44.1023 1.96141V9.07333C44.1021 10.1564 43.2241 11.0346 42.1408 11.0346Z'
          fill='url(#paint2_linear_4504_80164)'
        />
        <path
          d='M18.4429 56.2386L23.7089 61.5047C25.4937 63.2894 26.5894 65.648 26.8021 68.1631L27.2415 70.956C27.3454 72.1843 28.4254 73.0959 29.6537 72.992L34.9558 72.5435C36.184 72.4396 37.0957 71.3597 36.9917 70.1314L36.3125 64.5041C36.0599 61.5174 34.7588 58.7165 32.6394 56.5971L25.362 49.3197L18.4429 56.2386Z'
          fill='url(#paint3_linear_4504_80164)'
        />
        <path
          d='M35.0844 70.6377C35.0317 70.6904 34.9405 70.7555 34.8054 70.7669L29.5033 71.2153C29.3502 71.2283 29.2408 71.1657 29.1758 71.111C29.111 71.0564 29.0311 70.9589 29.0181 70.8059L29.0128 70.742L28.5728 67.9469C28.3116 65.0409 27.0336 62.3078 24.9697 60.2439L20.9645 56.2387L25.3618 51.8413L31.3784 57.858C33.1977 59.6772 34.3189 62.091 34.5357 64.6543L34.5422 64.7177L35.2162 70.3017C35.2235 70.4715 35.1376 70.5844 35.0844 70.6377Z'
          fill='url(#paint4_linear_4504_80164)'
        />
        <path
          d='M30.6307 70.4487C30.0818 70.4951 29.5903 70.1096 29.5047 69.5654L29.2369 67.8644C28.9573 64.812 27.6104 61.9385 25.4427 59.7708L22.6531 56.9812C22.2429 56.571 22.2429 55.906 22.6531 55.4958L24.6194 53.5295C25.0296 53.1192 25.6947 53.1192 26.1049 53.5295L30.9059 58.3304C32.6136 60.0382 33.6661 62.3038 33.8697 64.7104L33.8772 64.7851L34.3905 69.0388C34.4618 69.6294 34.029 70.1612 33.4362 70.2113L30.6307 70.4487Z'
          fill='url(#paint5_linear_4504_80164)'
        />
        <path
          d='M25.2168 5.8549C24.5279 6.54382 24.4777 7.80071 24.9704 9.46711C25.2513 14.8621 24.3918 30.6854 7.51562 47.5615L20.1597 60.2056C37.0358 43.3293 52.8589 42.4699 58.2539 42.7508C59.9203 43.2435 61.1771 43.1933 61.8661 42.5043C64.4091 39.9614 58.2662 29.6957 48.1459 19.5753C38.0253 9.45481 27.7597 3.31192 25.2168 5.8549Z'
          fill='url(#paint6_linear_4504_80164)'
        />
        <path
          d='M48.2054 19.6352L39.6701 19.6304C35.0195 19.6304 31.2495 23.4005 31.2495 28.0511C31.2495 32.7017 35.0196 36.4717 39.6701 36.4717H61.1572C59.0194 31.9905 54.3493 25.7889 48.2054 19.6352Z'
          fill='url(#paint7_linear_4504_80164)'
        />
        <path
          d='M48.1466 19.5745C58.2664 29.6943 64.4097 39.9605 61.866 42.5042C61.4295 42.9408 60.7644 43.1204 59.9102 43.0669C59.6548 38.9534 54.0032 30.4744 45.625 22.0961C37.2467 13.7179 28.7677 8.06622 24.6542 7.81086C24.6007 6.95662 24.7803 6.2916 25.2169 5.85504C27.7606 3.31134 38.0268 9.45465 48.1466 19.5745Z'
          fill='url(#paint8_linear_4504_80164)'
        />
        <path
          d='M61.1572 36.4717C59.0195 31.9906 54.3493 25.7889 48.2054 19.6352L43.0741 19.6323C43.9223 20.4241 44.7741 21.2453 45.6251 22.0963C50.7139 27.1851 54.7938 32.3094 57.2726 36.4718L61.1572 36.4717Z'
          fill='url(#paint9_linear_4504_80164)'
        />
        <path
          d='M39.6701 36.4717C44.3207 36.4717 48.0907 32.7016 48.0907 28.0511C48.0907 23.4005 44.3207 19.6304 39.6701 19.6304C35.0196 19.6304 31.2495 23.4005 31.2495 28.0511C31.2495 32.7016 35.0196 36.4717 39.6701 36.4717Z'
          fill='url(#paint10_linear_4504_80164)'
        />
        <path
          d='M43.9976 20.8264C44.7568 22.0911 45.1936 23.5714 45.1936 25.1538C45.1936 29.8044 41.4236 33.5745 36.773 33.5745C35.1905 33.5745 33.7102 33.1376 32.4456 32.3784C33.9174 34.8305 36.602 36.4716 39.6701 36.4716C44.3207 36.4716 48.0907 32.7015 48.0907 28.0509C48.0907 24.9829 46.4497 22.2984 43.9976 20.8264Z'
          fill='url(#paint11_linear_4504_80164)'
        />
        <path
          d='M39.6701 35.8121C43.9564 35.8121 47.4311 32.3374 47.4311 28.0511C47.4311 23.7647 43.9564 20.29 39.6701 20.29C35.3838 20.29 31.9091 23.7647 31.9091 28.0511C31.9091 32.3374 35.3838 35.8121 39.6701 35.8121Z'
          fill='url(#paint12_linear_4504_80164)'
        />
        <path
          d='M13.68 62.4617C18.3306 62.4617 22.1006 58.6916 22.1006 54.041C22.1006 49.3905 18.3306 45.6204 13.68 45.6204C9.02944 45.6204 5.2594 49.3905 5.2594 54.041C5.2594 58.6916 9.02944 62.4617 13.68 62.4617Z'
          fill='url(#paint13_linear_4504_80164)'
        />
        <path
          d='M11.6315 45.8725C13.0626 46.2299 14.4182 46.9678 15.5373 48.0867C18.8257 51.3751 18.8257 56.7068 15.5373 59.9953C14.4182 61.1143 13.0627 61.852 11.6315 62.2096C14.4062 62.9027 17.4647 62.1648 19.6343 59.9953C22.9227 56.7068 22.9227 51.3751 19.6343 48.0867C17.4647 45.9173 14.4062 45.1796 11.6315 45.8725Z'
          fill='url(#paint14_linear_4504_80164)'
        />
        <path
          d='M13.68 61.8021C17.9663 61.8021 21.441 58.3273 21.441 54.041C21.441 49.7547 17.9663 46.28 13.68 46.28C9.39368 46.28 5.91895 49.7547 5.91895 54.041C5.91895 58.3273 9.39368 61.8021 13.68 61.8021Z'
          fill='url(#paint15_linear_4504_80164)'
        />
        <path
          d='M32.6394 56.5971L25.362 49.3197L18.4429 56.2386L22.7609 60.5567H35.3213C34.6901 59.0901 33.7856 57.7433 32.6394 56.5971Z'
          fill='url(#paint16_linear_4504_80164)'
        />
        <path
          d='M38.9378 28.7832C46.3153 36.1606 53.7714 41.4262 58.2554 42.7517C52.8638 42.4695 37.0371 43.3269 20.1594 60.2047L7.51636 47.5617C24.3941 30.6839 25.2515 14.8572 24.9694 9.46567C26.2948 13.9496 31.5588 21.4042 38.9378 28.7832Z'
          fill='url(#paint17_linear_4504_80164)'
        />
        <path
          d='M10.9933 44.4606L23.2605 56.7277C24.2123 57.6796 24.2123 59.2229 23.2605 60.1748C22.3086 61.1266 20.7653 61.1266 19.8136 60.1748L7.54642 47.9076C6.59455 46.9558 6.59455 45.4125 7.54642 44.4606C8.49828 43.5087 10.0414 43.5087 10.9933 44.4606Z'
          fill='url(#paint18_linear_4504_80164)'
        />
        <path
          d='M61.7998 42.5673C60.7643 43.5555 59.204 43.9148 57.2875 43.7194L57.2859 43.7178L57.2812 43.7194C52.0062 43.1819 48.9992 44.7265 35.9968 31.7242C22.9945 18.7219 24.539 15.7147 24.0017 10.4399L24.0032 10.4351L24.0017 10.4336C23.8063 8.51709 24.1656 6.95675 25.1538 5.9213C24.525 6.62267 24.4919 7.85351 24.9694 9.4658L24.971 9.46738V9.47053C26.2996 13.9543 31.562 21.4075 38.9379 28.7833C46.3122 36.1576 53.7668 41.4216 58.2507 42.7502H58.2538L58.2554 42.7518C59.8676 43.2292 61.0985 43.1961 61.7998 42.5673Z'
          fill='url(#paint19_linear_4504_80164)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4504_80164'
          x1='55.8418'
          y1='11.2965'
          x2='58.6952'
          y2='14.1499'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7044' />
          <stop offset='1' stopColor='#F82814' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4504_80164'
          x1='62.2233'
          y1='25.1429'
          x2='62.2233'
          y2='29.1788'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7044' />
          <stop offset='1' stopColor='#F82814' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4504_80164'
          x1='42.5976'
          y1='5.51723'
          x2='38.5617'
          y2='5.51723'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7044' />
          <stop offset='1' stopColor='#F82814' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4504_80164'
          x1='24.5607'
          y1='61.1595'
          x2='36.1817'
          y2='61.1594'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD945' />
          <stop offset='0.3043' stopColor='#FFCD3E' />
          <stop offset='0.8558' stopColor='#FFAD2B' />
          <stop offset='1' stopColor='#FFA325' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_4504_80164'
          x1='35.3849'
          y1='58.8788'
          x2='20.9662'
          y2='65.4971'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD945' />
          <stop offset='0.3043' stopColor='#FFCD3E' />
          <stop offset='0.8558' stopColor='#FFAD2B' />
          <stop offset='1' stopColor='#FFA325' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_4504_80164'
          x1='25.0662'
          y1='61.8368'
          x2='35.5989'
          y2='61.8368'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD945' />
          <stop offset='0.3043' stopColor='#FFCD3E' />
          <stop offset='0.8558' stopColor='#FFAD2B' />
          <stop offset='1' stopColor='#FFA325' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_4504_80164'
          x1='50.7405'
          y1='27.6594'
          x2='28.679'
          y2='31.7566'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8F6FB' />
          <stop offset='1' stopColor='#EFDCFB' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_4504_80164'
          x1='52.5362'
          y1='27.8759'
          x2='33.6265'
          y2='29.3468'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EFDCFB' stopOpacity='0' />
          <stop offset='0.2889' stopColor='#C7B6DF' stopOpacity='0.289' />
          <stop offset='0.5915' stopColor='#A495C7' stopOpacity='0.592' />
          <stop offset='0.8395' stopColor='#8E80B7' stopOpacity='0.84' />
          <stop offset='1' stopColor='#8679B2' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_4504_80164'
          x1='53.8034'
          y1='22.275'
          x2='41.3572'
          y2='24.5865'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD945' />
          <stop offset='0.3043' stopColor='#FFCD3E' />
          <stop offset='0.8558' stopColor='#FFAD2B' />
          <stop offset='1' stopColor='#FFA325' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_4504_80164'
          x1='54.3984'
          y1='27.3615'
          x2='44.3133'
          y2='30.408'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F35227' stopOpacity='0' />
          <stop offset='1' stopColor='#F35227' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_4504_80164'
          x1='36.4641'
          y1='24.8451'
          x2='43.253'
          y2='31.6341'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7044' />
          <stop offset='1' stopColor='#F82814' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_4504_80164'
          x1='41.1121'
          y1='29.0393'
          x2='55.9722'
          y2='24.6803'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F82814' stopOpacity='0' />
          <stop offset='1' stopColor='#C0272D' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_4504_80164'
          x1='41.2654'
          y1='30.6892'
          x2='31.1606'
          y2='13.9799'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7044' stopOpacity='0' />
          <stop offset='1' stopColor='#FFA325' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_4504_80164'
          x1='9.13275'
          y1='54.0408'
          x2='18.7324'
          y2='54.0408'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8A7889' />
          <stop offset='1' stopColor='#6E566E' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_4504_80164'
          x1='15.3843'
          y1='53.7199'
          x2='22.8086'
          y2='40.132'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6E566E' stopOpacity='0' />
          <stop offset='0.2191' stopColor='#685168' stopOpacity='0.219' />
          <stop offset='1' stopColor='#564357' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_4504_80164'
          x1='16.6591'
          y1='54.778'
          x2='-2.29861'
          y2='50.1086'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8A7889' stopOpacity='0' />
          <stop offset='1' stopColor='#9D8B91' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_4504_80164'
          x1='32.3111'
          y1='58.9917'
          x2='17.7742'
          y2='51.8611'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F35227' stopOpacity='0' />
          <stop offset='1' stopColor='#F35227' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_4504_80164'
          x1='21.7422'
          y1='34.0294'
          x2='34.8253'
          y2='47.1125'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FCE6A4' />
          <stop offset='1' stopColor='#EFDCFB' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_4504_80164'
          x1='13.3891'
          y1='54.3697'
          x2='18.1706'
          y2='49.5882'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8A7889' />
          <stop offset='1' stopColor='#6E566E' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_4504_80164'
          x1='34.0487'
          y1='22.3156'
          x2='54.4067'
          y2='42.6736'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFD945' />
          <stop offset='0.3043' stopColor='#FFCD3E' />
          <stop offset='0.8558' stopColor='#FFAD2B' />
          <stop offset='1' stopColor='#FFA325' />
        </linearGradient>
        <clipPath id='clip0_4504_80164'>
          <rect width='73' height='73' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
