export default function BinVector2() {
  return (
    <svg
      width='105'
      height='105'
      viewBox='0 0 105 105'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5849_64461)'>
        <path
          d='M100.603 68.0344C100.649 67.8591 100.692 67.6826 100.736 67.5059C100.693 67.6826 100.648 67.8585 100.603 68.0344Z'
          fill='#BFE5FE'
        />
        <path
          d='M52.9999 6.74805C34.0948 6.74805 22.4501 17.4108 22.418 17.4289C21.6884 20.7967 20.4193 25.684 20.4193 29.2702C20.4193 56.4019 43.2995 78.3913 70.4312 78.3913C81.9935 78.3913 92.617 73.0078 101.009 66.3237C101.043 66.1143 102.126 61.8009 102.126 55.8745C102.126 28.7428 80.1317 6.74805 52.9999 6.74805V6.74805Z'
          fill='#F5F2F8'
        />
        <path
          d='M70.4271 77.0054C43.2954 77.0054 21.3007 55.0106 21.3007 27.8789C21.3007 24.2927 21.6886 20.7975 22.4181 17.4297C22.417 17.4305 22.4158 17.4315 22.4148 17.4324C11.1167 26.4332 3.87354 40.3074 3.87354 55.8753C3.87354 74.5203 14.2603 90.7392 29.5634 99.0615L80.4658 96.6099C90.2961 89.9666 97.5923 79.8572 100.603 68.0356C100.648 67.8597 100.693 67.6838 100.736 67.5071C100.756 67.423 100.941 66.6358 101.009 66.3243C92.617 73.0088 81.9894 77.0054 70.4271 77.0054Z'
          fill='#F5F2F8'
        />
        <path
          d='M54.243 39.2949V103.166C61.1089 102.995 67.6243 103.233 73.5117 100.523C75.9427 99.4043 78.2667 98.0925 80.4641 96.6073L83.012 39.2949H54.243Z'
          fill='#4572E3'
        />
        <path
          d='M73.5117 100.523H63.9793C59.0172 100.523 54.9945 96.5002 54.9945 91.5381V39.2949H26.9065L29.5636 99.0605C36.5291 102.849 44.5132 105.001 52.9999 105.001C60.3228 105.001 67.2697 103.396 73.5117 100.523Z'
          fill='#3342AE'
        />
        <path
          d='M51.7752 18.8144L29.4903 41.0993L18.5093 30.1183C15.7236 27.3326 15.7236 22.7997 18.5093 20.014L30.6899 7.8334C33.4756 5.04772 38.0085 5.04772 40.7942 7.8334L51.7752 18.8144ZM29.4903 32.8113L43.4872 18.8144L36.6501 11.9773C36.1493 11.4766 35.3346 11.4766 34.8338 11.9773L22.6532 24.1579C22.1525 24.6587 22.1525 25.4734 22.6532 25.9742L29.4903 32.8113Z'
          fill='#3342AE'
        />
        <path
          d='M24.457 30.4023L54.8543 0.00506249L64.6013 9.75208L34.204 40.1494L24.457 30.4023Z'
          fill='#4572E3'
        />
        <path
          d='M10.6724 44.1836L25.7644 29.0915L35.5114 38.8385L20.4194 53.9306L10.6724 44.1836Z'
          fill='#1A396B'
        />
        <path
          d='M54.9593 100.559C53.0712 100.559 51.5408 99.028 51.5408 97.14V55.1959C51.5408 53.3078 53.0714 51.7773 54.9593 51.7773C56.8475 51.7773 58.3779 53.308 58.3779 55.1959V97.14C58.3779 99.028 56.8475 100.559 54.9593 100.559Z'
          fill='#1A396B'
        />
        <path
          d='M41.3505 100.559C39.5427 100.559 38.0328 99.1409 37.9391 97.315L35.7902 55.3709C35.6936 53.4853 37.1439 51.8784 39.0294 51.7818C40.919 51.6861 42.5219 53.1357 42.6185 55.021L44.7674 96.9651C44.864 98.8508 43.4137 100.458 41.5282 100.554C41.4686 100.557 41.4092 100.559 41.3505 100.559Z'
          fill='#1A396B'
        />
        <path
          d='M68.9005 100.559C68.8414 100.559 68.7824 100.558 68.7228 100.554C66.8371 100.458 65.3871 98.8508 65.4834 96.9654L67.6323 55.0213C67.7289 53.1356 69.3343 51.6855 71.2214 51.7819C73.1071 51.8785 74.5572 53.4855 74.4608 55.371L72.3119 97.3151C72.2182 99.1412 70.7081 100.559 68.9005 100.559Z'
          fill='#1A396B'
        />
        <path
          d='M65.4464 18.7411L62.0083 24.05C61.5099 24.8196 60.6555 25.2844 59.7386 25.2844C57.5248 25.2844 56.249 27.7994 57.5566 29.5857L60.188 33.1803C60.8106 34.0306 61.8703 34.442 62.9034 34.234L69.023 33.0024C69.6366 32.8789 70.2738 32.972 70.8263 33.2658L75.9935 36.0139C77.4466 36.7867 79.2473 36.0932 79.8066 34.5451L82.1893 27.949C82.6576 26.6524 82.0757 25.2112 80.8385 24.6032L77.0061 22.7204C76.3385 22.3924 75.8355 21.8039 75.6154 21.0934L74.663 18.0182C74.2186 16.5829 72.6892 15.7852 71.2578 16.242L66.8938 17.6348C66.2981 17.825 65.7865 18.2161 65.4464 18.7411V18.7411Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_5849_64461'>
          <rect width='105' height='105' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
