export default function RecordIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3.20825'
        y='3.20801'
        width='21.5833'
        height='21.5833'
        rx='10.7917'
        stroke='currentColor'
        strokeWidth='2'
      />
      <rect
        x='8.16675'
        y='8.16699'
        width='11.6667'
        height='11.6667'
        rx='5.83333'
        fill='currentColor'
      />
    </svg>
  );
}
