import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  CREATE_CAMPAIGN_CALL_NOTE,
  EDIT_CAMPAIGN_CALL_NOTE,
} from 'graphql/sales-dialer/call-notes';
import { ToastMessage } from 'components/atoms';

const useCampaignNoteMutation = (props?: any) => {
  const { t } = useTranslation();

  const [addNote, { loading: loadingNoteAdd }] = useMutation(CREATE_CAMPAIGN_CALL_NOTE, {
    onCompleted(resData) {
      const { error, data } = resData?.createCampaignCallNote || {};

      if (error === null) {
        ToastMessage({
          content: `${t('toast.addNoteSuccess', 'Note added successfully.')}`,
          type: 'success',
        });
        props?.onNoteSave?.(data);
        return;
      }
      props?.onNoteSaveError?.();
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
    onError() {
      props?.onNoteSaveError?.();
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const createCampaignCallNote = async (payload: any) => {
    await addNote({
      variables: {
        data: payload,
      },
    });
  };

  const [editNote, { loading: loadingNoteEdit }] = useMutation(EDIT_CAMPAIGN_CALL_NOTE, {
    onCompleted(resData) {
      const { error, data } = resData?.updateCampaignCallNote || {};
      if (error === null) {
        ToastMessage({
          content: `${t('toast.editNoteSuccess', 'Note updated successfully.')}`,
          type: 'success',
        });
        props?.onNoteSave?.(data);
        return;
      }
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
    onError() {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const editCampaignCallNote = async (payload: any) => {
    await editNote({
      variables: {
        data: payload,
      },
    });
  };
  return {
    createCampaignCallNote,
    editCampaignCallNote,
    loadingCampaignSaveNote: loadingNoteAdd || loadingNoteEdit,
  };
};

export default useCampaignNoteMutation;
