import { Button } from 'antd';
import styled from 'styled-components';
import { AudioSettingsDropdownCSS } from '../common/Styles';

export const ActiveCallActions = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 80px);
  gap: 16px;
`;

// Note: keeping CallActionWithSelect incase if CallAction needs select dropdown in future
export const CallActionWithSelect = styled.div`
  position: relative;
  line-height: 0;
  > div {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 4px #fff;
    border-radius: 50%;
    .ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: none;
        height: 20px;
        width: 20px;
        padding: 0;
        border-radius: 50%;
        + .ant-select-arrow {
          right: 0px !important;
          top: 7px !important;
          transition: all 0.15s ease-in;
        }
        > .ant-select-selection-item {
          display: none;
        }
      }
      &.ant-select-open .ant-select-arrow {
        transform: rotate(180deg);
      }
      &.ant-select-disabled {
        .ant-select-selector {
          background: var(--Error-100, #ffefef);
          + .ant-select-arrow > svg {
            color: var(--Error-300-Default, #f33);
          }
        }
      }

      .ant-select-dropdown {
        ${AudioSettingsDropdownCSS}
      }
    }
  }
`;

export const StyledOptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  > span {
    flex-shrink: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--Stroke-200-Hover, #cbc9cf);
    background: var(--Background-100, #fff);
    .ant-select-item-option-selected & {
      box-sizing: border-box;
      border: 5px solid var(--Primary-Vibrant, #5301b1);
    }
  }
`;

export const WidgetActionButton = styled(Button)`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 15px;
    font-weight: 450;
    line-height: 20px;
    height: 40px;
    padding: 0 13px;
    border-radius: var(--Radius, 11px);
    width: 100%;
    &-default {
      border: 1px solid var(--Stroke-100-Default, #e5e4e7);
      background: var(--Background-100, #fff);
      color: var(--Text-400-Paragraph, #4d4950);
    }

    &-primary {
      background: var(--Primary-300-Default, #5301b1);
      min-width: 173px;
      &:disabled {
        border: none;
      }
    }
    &-dangerous,
    &-primary,
    &.success {
      border: none;
      color: var(--Text-100-Always-White, #fff);
    }
    &-dangerous {
      background: var(--Error-300-Default, #f33);
    }

    &.success {
      background: var(--Success-300-Default, #10bc3b);
    }
    &.tertiary {
      background: var(--Background-300, #f5f4f6);
      border: none;
      color: var(--Text-300-Component-2, #66616b);
      :focus {
        background: var(--Background-400, #edebee);
      }
    }

    &:disabled {
      background: var(--Background-300, #f5f4f6);
      color: var(--Text-200-Disabled, #99949e);
    }
  }
`;
