/* eslint-disable no-debugger */
import { useMutation, useReactiveVar } from '@apollo/client';
import { initializeApp } from 'firebase/app';
import { FIREBASE_CONFIG } from 'constants/firebaseConfig';
import { getToken, deleteToken, getMessaging } from 'firebase/messaging';

import { REGISTER_DEVICE_FCM } from 'graphql/user';
import useLocalStorage from 'hooks/useLocalStorage';
import { fcmMessagingInstance } from 'services/apollo/reactiveVars';
import { cleanupSWAndCaches } from 'services/cleanupSWAndCaches';
import { datadogLogs } from '@datadog/browser-logs';
import { isMobileDevice } from 'components/organisms/common/utils';

export function useFcm() {
  const [, setFcmDeviceId] = useLocalStorage('fcmDeviceId', null);
  const fcmInstance = useReactiveVar<any>(fcmMessagingInstance);

  const [registerDeviceMutation] = useMutation(REGISTER_DEVICE_FCM, {
    onCompleted: resData => {
      const { error: registerDeviceError, data } = resData?.deviceRegister || {};
      if (registerDeviceError === null) {
        setFcmDeviceId(data?.id);
      }
    },
    onError: err => {
      console.error('fcmDeviceRegisterError', err);
    },
  });

  const registerDeviceHandler = async (token: string) => {
    await registerDeviceMutation({
      variables: {
        data: {
          fcmToken: token ?? 'null',
          platform: 'Web',
        },
      },
    });
  };

  const initializeFCM = () => {
    navigator.serviceWorker
      .getRegistration()
      .then(async reg => {
        try {
          const firebaseApp = initializeApp(FIREBASE_CONFIG);
          const messaging = getMessaging(firebaseApp);
          // Needed for registering service worker inorder to delete existing fcm token
          await getToken(messaging, {
            serviceWorkerRegistration: reg,
          });
          // Delete or unsubscribe existing fcm token each time
          try {
            await deleteToken(messaging);
          } catch (err) {
            if (process.env.REACT_APP_DEBUG) console.log('[FCM ERROR]:', err);
          }
          // Generate new fcm token for device register
          const token = await getToken(messaging, {
            serviceWorkerRegistration: reg,
          });
          fcmMessagingInstance(messaging);
          if (process.env.REACT_APP_DEBUG) {
            console.log('[FCM Token]', token);
          }
          console.info('%c message supported', 'background: #222; color: #bada55');
          registerDeviceHandler(token);
        } catch (error) {
          if (process.env.REACT_APP_DEBUG) console.log('[FCM ERROR]:', error);
          registerDeviceHandler('null');
          datadogLogs.logger.warn(`[FCM ERROR]`, {
            dataDogError: error,
            context: 'fcm',
          });
        }
      })
      .catch((err: any) => {
        if (process.env.REACT_APP_DEBUG) console.log('[FCM ERROR]:', err);
        console.info('%c notification not supported', 'background: #900603; color: #fff');
        datadogLogs.logger.warn(`[FCM ERROR]`, {
          dataDogError: err,
          context: 'fcm',
        });
      });
  };

  const registerDevice = async () => {
    if (isMobileDevice()) return;
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
      if (!('Notification' in window)) {
        // Check if the browser supports notifications
        console.info('%c notification not supported', 'background: #900603; color: #fff');
        return;
      }
      if (Notification.permission === 'granted') {
        // Check whether notification permissions have already been granted;
        initializeFCM();
        return;
        // …
      }
      if (Notification.permission === 'denied') {
        console.info('%c notification not supported', 'background: #900603; color: #fff');
        return;
      }
      // We need to ask the user for permission
      Notification.requestPermission().then(permission => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          initializeFCM();
        }
      });
      return;
    }
    console.info('%c notification not supported', 'background: #900603; color: #fff');
  };

  const clearFcmInstance = async () => {
    if (fcmInstance) {
      try {
        await deleteToken(fcmInstance);
      } catch (e) {
        console.error('fcmError', e);
        // Unregister service worker inorder to create a new instance of fcm messaging when user relogins
        cleanupSWAndCaches();
      }
    }
    fcmMessagingInstance(null);
  };

  return { registerDevice, clearFcmInstance };
}
