import { createContext, useCallback, useMemo, useState } from 'react';

export const SalesDialerContext = createContext<any>({});

export const SalesDialerProvider = ({ children }: any) => {
  const [visibleInsufficientCredit, setVisibleInsufficientCredit] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleInsufficientCredit = useCallback(
    () => setVisibleInsufficientCredit(prev => !prev),
    [],
  );

  const value = useMemo(() => ({ visibleInsufficientCredit, toggleInsufficientCredit }), [
    visibleInsufficientCredit,
    toggleInsufficientCredit,
  ]);

  return <SalesDialerContext.Provider value={value}>{children}</SalesDialerContext.Provider>;
};
