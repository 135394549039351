import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { LAST_CONTACTED } from 'graphql/client';

interface IProps {
  contact?: string;
}

export function useContactMeta({ contact }: IProps) {
  const [loadMeta, { data }] = useLazyQuery(LAST_CONTACTED, {
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (contact) {
      loadMeta({
        variables: {
          contact,
        },
      });
    }
  }, [contact, loadMeta]);

  const lastContacted = data?.lastContactedTime?.data?.createdAt;

  return {
    lastContacted,
  };
}
