/**
 * Cleans up and unregisters service workers and clears caches in the browser.
 * @function cleanupSWAndCaches
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 */
export function cleanupSWAndCaches() {
  // Check if service workers are supported
  if ('serviceWorker' in navigator) {
    // Get all service worker registrations
    navigator.serviceWorker.getRegistrations().then(registrations => {
      // Unregister each service worker
      registrations.forEach(registration => registration.unregister());
    });
  }
  // Check if the browser supports caches
  if ('caches' in window) {
    // Get all cache keys
    caches.keys().then(keyList => {
      // Delete all caches
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key);
        }),
      );
    });
  }
  // Reload the current page after 3 seconds
  setTimeout(() => {
    console.log('clean cache reload');
    // window.location.replace(window.location.href);
    window.location.reload();
  }, 3000);
}
