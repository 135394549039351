import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ALL_WORKSPACE_NUMBERS } from 'graphql/channel';

export function useAllWorkspaceNumbers() {
  const [fetchAllNumbers, { data, loading, called }] = useLazyQuery(ALL_WORKSPACE_NUMBERS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    fetchAllNumbers();
  }, [fetchAllNumbers]);

  const numbers: never[] = useMemo(() => data?.allWorkspaceNumbers?.data ?? [], [data]);

  return {
    numbers,
    loading,
    called,
  };
}
