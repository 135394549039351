import { ReactElement } from 'react';

import { useTwilioContext } from 'lib/twilio';

import CallScript from './CallScript';

export default function Script(): ReactElement {
  const {
    state: { connection },
  } = useTwilioContext();
  const { customParameters } = connection || {};
  const callScriptId = customParameters?.get('callScriptsId');

  const contact = {
    name: customParameters?.get('contactName') || '',
    number: customParameters?.get('contact_number') || '',
  };

  return <CallScript callScriptId={callScriptId} contact={contact} />;
}
