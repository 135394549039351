import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const ADD_WORKSPACE = gql`
  mutation addWorkspace($data: WorkspaceInputData!) {
    addWorkspace(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        memberId
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const CREATE_WORKSPACE_AND_SUBSCRIBE = gql`
  mutation workspaceSubscription($data: WorkspaceSubscriptionInputData!) {
    workspaceSubscription(data: $data) {
      status
      error {
        ...Error
      }
      data {
        workspaceId
        memberId
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHECK_COUPON_CODE = gql`
  mutation checkCouponCode($data: CouponInputData!) {
    checkCouponCode(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        name
        currencyCode
        discountAmount
        discountType
        discountPercentage
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const CREATE_PAYPAL_APRROVE_URL = gql`
  mutation createPaypalApprovalUrl($data: CouponInputData!) {
    createPaypalApprovalUrl(data: $data) {
      status
      error {
        ...Error
      }
      data {
        tokenId
        approvalUrl
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const VALIDATE_PROMO_CODE = gql`
  mutation validatePromoCode($data: PromoCodeInputData!) {
    validatePromoCode(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        name
        currencyCode
        discountAmount
        discountType
        discountPercentage
        countryIso
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const EDIT_WORKSPACE = gql`
  mutation editWorkspace($data: EditWorkspaceInputData!) {
    editWorkspace(data: $data) {
      status
      data {
        id
        title
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RE_ORDER_WORKSPACE = gql`
  mutation workspaceOrder($data: WorkspaceOrderInputData!) {
    workspaceOrder(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const REMOVE_WORKSPACE = gql`
  mutation removeWorkspace($id: ShortId!) {
    removeWorkspace(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const INVITE_MEMBER = gql`
  mutation inviteMember($data: InviteMemberInputData!) {
    inviteMember(data: $data) {
      status
      error {
        ...Error
      }
      data {
        message
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RESEND_VERIFICATION = gql`
  mutation resendEmailVerificationLink($data: EmailVerificationInputData!) {
    resendEmailVerificationLink(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const ACCEPT_INVITATION = gql`
  mutation acceptInvite($data: InviteAcceptInput!) {
    acceptInvite(data: $data) {
      data {
        authToken
        accessToken
        refreshToken
        workspace
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
// TODO: need to add member_id in below mutation after schema is updated in backend
export const ACCEPT_USER_INVITATION = gql`
  mutation acceptUserInvite($data: UserInviteAcceptInput!) {
    acceptUserInvite(data: $data) {
      data {
        accessToken
        refreshToken
        workspace
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const CHANGE_DEFAULT_LANGUAGE = gql`
  mutation changeDefaultLanguage($data: ChangeDefaultLanguage!) {
    changeDefaultLanguage(data: $data) {
      status
      data {
        defaultLanguage
        defaultWorkspace
        email
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHANGE_DEFAULT_TIMEZONE = gql`
  mutation changeDefaultTimezone($data: ChangeDefaultTimezone!) {
    changeDefaultTimezone(data: $data) {
      status
      data {
        defaultLanguage
        defaultTimezone
        defaultWorkspace
        email
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHANGE_WORKSPACE_MEMBER_STATUS = gql`
  mutation editMemberStatus($uid: ShortId!, $data: EditMemberStatusInputData!) {
    editMemberStatus(id: $uid, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHANGE_WORKSPACE_MEMBER_ROLE = gql`
  mutation editMemberRole($uid: ShortId!, $data: EditMemberRoleInputData!) {
    editMemberRole(id: $uid, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        role
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RESEND_INVITATION = gql`
  mutation resendInvite($data: InviteCode!) {
    resendInvite(data: $data) {
      data {
        message
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const DELETE_INVITATION = gql`
  mutation deleteInvitation($link: String!) {
    deleteInvitation(link: $link) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHANGE_WORKSPACE_PICTURE = gql`
  mutation changeWorkspacePicture($photoUpload: Upload!) {
    changeWorkspacePicture(photoUpload: $photoUpload) {
      status
      data {
        id
        title
        photo
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const RESTORE_WORKSPACE = gql`
  mutation restoreWorkspace($id: ShortId!) {
    restoreWorkspace(id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
        title
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_KYC_STATUS = gql`
  mutation updatekycVerificationStatus($data: KycVerificationInputData!) {
    updatekycVerificationStatus(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
