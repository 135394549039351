import { ReactElement, useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import iEmpty from 'lodash.isempty';

import { TextAreaInput } from 'components/molecules/fields';
import { NOTES_MAX_LENGTH } from 'schema-validation';
import { schema } from './schema';
import * as S from './Styles';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

interface INoteAddProps {
  handleClose: () => void;
}

export function NoteAdd({ handleClose }: INoteAddProps): ReactElement {
  const { t } = useTranslation();

  const handleCloseBtnClick = () => {
    handleClose();
  };

  const { note, handleNoteSave, loadingSaveNote } = useCallWidgetContext();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<{ title: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      title: note?.title || '',
    },
  });

  const formSubmit = async (val: any) => {
    const { title } = val;
    if (!iEmpty(errors)) return;
    handleNoteSave?.(title);
  };

  useEffect(() => {
    if (note) {
      setValue('title', note?.title);
    }
  }, [note, setValue]);

  return (
    <S.FormContainer onSubmit={handleSubmit(formSubmit)}>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-center '>
          New Notes
        </p>
        <button
          type='submit'
          className='text-success font-manrope text-xs leading-3 font-semibold'
          disabled={loadingSaveNote}
        >
          Save
        </button>
      </div>
      <div className='pt-3 pb-2.5 px-4 text-left'>
        <div className=''>
          <TextAreaInput
            name='title'
            id='noteBox'
            control={control}
            placeholder={t('writeNotes', 'Write notes...')}
            maxLength={NOTES_MAX_LENGTH}
          />
        </div>
      </div>
    </S.FormContainer>
  );
}
