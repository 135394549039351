export const SuitCase = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_20039_185205)'>
      <path
        d='M39.4729 6.42258C39.3755 6.34828 38.4736 5.67738 36.8671 5.0318C34.7975 4.2 32.4227 3.76031 29.9996 3.76031C27.5765 3.76031 25.2018 4.2 23.1319 5.0318C21.5255 5.67738 20.6236 6.34828 20.5261 6.4227C20.037 6.7957 19.7499 7.37566 19.7499 7.99078V11.0846L21.7218 12.1393L23.6938 11.0846V9.10746C24.776 8.55516 26.9403 7.70414 29.9994 7.70414C33.0587 7.70414 35.2228 8.55516 36.3051 9.10746V11.0845L38.277 12.1392L40.249 11.0845V7.99078C40.2493 7.37555 39.9621 6.79559 39.4729 6.42258Z'
        fill='#E8834D'
      />
      <path d='M19.75 11.0846V13.262L21.722 14.3167L23.6941 13.262V11.0846H19.75Z' fill='#D66E41' />
      <path
        d='M40.2492 11.0846H36.3053V13.262L38.2773 14.3167L40.2492 13.262V11.0846Z'
        fill='#D66E41'
      />
      <path
        d='M1.55237 34.9554V52.474C1.55237 54.5537 3.23835 56.2397 5.31807 56.2397H54.6819C56.7616 56.2397 58.4476 54.5537 58.4476 52.474V34.9554H1.55237Z'
        fill='#365E7D'
      />
      <path
        d='M1.55237 32.778V34.9555C4.68081 37.4394 12.9491 42.2613 30 42.2613C47.0509 42.2613 55.3192 37.4394 58.4476 34.9554V32.7779H1.55237V32.778Z'
        fill='#2B4D66'
      />
      <path
        d='M1.38891 31.524C4.38715 33.965 12.6439 38.9612 30 38.9612C47.3561 38.9612 55.6127 33.965 58.6111 31.524C59.49 30.8085 60 28.6815 60 27.5482V17.0277C60 14.948 58.314 13.262 56.2343 13.262H3.7657C1.68598 13.262 0 14.948 0 17.0277V27.5482C0 28.6815 0.51 30.8085 1.38891 31.524Z'
        fill='#407093'
      />
      <path
        d='M58.6111 30.4693C55.6129 32.9104 47.3561 37.9065 30 37.9065C12.6439 37.9065 4.38727 32.9102 1.38891 30.4693C0.51 29.7538 0 28.6815 0 27.5482V29.7257C0 30.859 0.51 31.9313 1.38891 32.6468C4.38715 35.0878 12.6439 40.084 30 40.084C47.3561 40.084 55.6127 35.0878 58.6111 32.6468C59.49 31.9313 60 30.859 60 29.7257V27.5482C60 28.6815 59.49 29.7538 58.6111 30.4693Z'
        fill='#365E7D'
      />
      <path
        d='M29.9996 43.7079C31.9478 43.7079 33.527 42.2613 33.527 39.1259V36.9872C33.527 36.2939 32.9649 35.732 32.2718 35.732H27.7275C27.0342 35.732 26.4723 36.294 26.4723 36.9872V39.1262C26.4722 42.2613 28.0515 43.7079 29.9996 43.7079Z'
        fill='#FFE27A'
      />
      <path
        d='M29.9996 42.6532C28.0515 42.6532 26.4723 41.074 26.4723 39.1259V41.3033C26.4723 43.2514 28.0515 44.8307 29.9996 44.8307C31.9478 44.8307 33.527 43.2514 33.527 41.3033V39.1259C33.527 41.0739 31.9478 42.6532 29.9996 42.6532Z'
        fill='#F9CF58'
      />
    </g>
    <defs>
      <clipPath id='clip0_20039_185205'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SuitCase;
