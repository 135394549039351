import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash.isempty';

import { useTwilioContext } from 'lib/twilio';
import { NOTES_MAX_LENGTH } from 'schema-validation';
import useAppContext from 'hooks/useAppContext';

import { StyledTextAreaInput } from './Styles';
import { schema } from './schema';
import SaveDiscardActionBlock from '../save-discard-action';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';

export default function CampaignNoteAdd(): ReactElement {
  const { setUnSavedCallWidgetAction } = useAppContext();
  const { t } = useTranslation();
  const { closeDrawer, saveButtonLabel } = useCallWidgetDrawerContext();
  const {
    campaignCallNote,
    handleCampaignNoteSave,
    loadingCampaignSaveNote,
  } = useActiveCallWidgetContext();
  const {
    state: { connection },
  } = useTwilioContext();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm<{ callNote: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      callNote: campaignCallNote?.note || '',
    },
  });
  const hasErrors = !isEmpty(errors);
  const disableSaveButton = hasErrors || !watch('callNote');

  const formSubmit = async (val: any) => {
    const { callNote } = val;
    const campaignId = connection?.customParameters?.get('campaignId');
    const campaignConversationId = connection?.customParameters?.get('campaignConversationId');
    if (!isEmpty(errors)) return;
    let payload = {};
    const { id } = campaignCallNote || {};
    if (id) {
      payload = {
        callNote,
        id,
      };
      handleCampaignNoteSave?.(payload, 'edit');
      return;
    }
    payload = {
      callNote,
      campaignId,
      campaignConversationId,
    };
    handleCampaignNoteSave?.(payload, 'create');
  };

  useEffect(() => {
    if (campaignCallNote) {
      setValue('callNote', campaignCallNote?.note);
    }
  }, [campaignCallNote, setValue]);

  useEffect(() => {
    setUnSavedCallWidgetAction?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setUnSavedCallWidgetAction?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FORM_ID = 'add-campaign-notes-form';

  return (
    <form
      className='flex flex-col h-full px-4 pb-4'
      id={FORM_ID}
      onSubmit={handleSubmit(formSubmit)}
    >
      <StyledTextAreaInput
        name='callNote'
        id='noteBox'
        control={control}
        errors={errors}
        placeholder={t('saveNotes', 'Save notes here..')}
        maxLength={NOTES_MAX_LENGTH}
        autoSize={{ minRows: 10 }}
      />

      <SaveDiscardActionBlock
        disabled={disableSaveButton}
        isSaveLoading={loadingCampaignSaveNote}
        onDiscard={closeDrawer}
        formId={FORM_ID}
        htmlType='submit'
        saveButtonLabel={saveButtonLabel}
      />
    </form>
  );
}
