import React, { Ref } from 'react';
import styled from 'styled-components';
import { Input as AntInput, InputProps, InputRef } from 'antd';

const Input = styled<typeof AntInput>(AntInput)`
  border: none;
  padding: 0;
  background: none;
  .ant-input,
  .ant-input::placeholder {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #857f96;
  }
  &.ant-input-affix-wrapper {
    border: none;
    box-shadow: none;
  }
  &:hover {
    border-color: transparent;
  }

  .ant-input-affix-wrapper-lg {
    font-size: ${({ theme }) => theme.text.size.body.text.caption};
    color: ${({ theme }) => theme.colors.text.t6};
  }
  .ant-input-prefix {
    margin-right: 8px;
    svg {
      color: ${({ theme }) => theme.colors.text.t6};
    }
  }
  .ant-input-suffix {
    font-size: ${({ theme }) => theme.text.size.body.text.secondary};
    cursor: pointer;
  }
  &.sidebar-search {
    background-color: ${({ theme }) => theme.colors.primary.p1};
    height: 40px;
    .ant-input {
      background-color: ${({ theme }) => theme.colors.primary.p1};
    }
    svg {
      font-size: 14px;
    }
  }
  &.conversation-search {
    max-height: 40px;
    padding: 8px 14px;
    svg {
      font-size: 16px;
    }
  }
  &.contact-list-search {
    &.ant-input-affix-wrapper {
      .ant-input-prefix {
        margin-right: 12px;
        svg {
          font-size: 20px;
          color: #857f96;
        }
      }
      .ant-input {
        padding: 0;
      }
      &-sm {
        .ant-input-prefix {
          svg {
            font-size: 16px;
          }
        }
      }
    }
  }
  &.contact-logs-search {
    border-color: transparent;
    border-radius: 8px;
    background: #f5f2f8;
    border: 1px solid #d7cce4;
    padding: 6px 15px;
    height: 40px;
    svg {
      font-size: 14px;
      color: #6e6681;
    }
    .ant-input {
      background-color: inherit;
      font-weight: 500;
      line-height: 16px;
      color: #3d3358;
      &::placeholder {
        font-family: 'Heebo';
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #9e99ab;
      }
    }
  }
`;
const Search = React.forwardRef(({ ...rest }: InputProps, ref: Ref<InputRef> | undefined) => (
  <Input {...rest} ref={ref} />
));
export default React.memo(Search);
