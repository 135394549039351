export default function MissedIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_21574_191560)'>
        <path
          d='M7.10604 3.30801L6.84997 3.05194V3.41407V5.50001C6.84997 5.69331 6.69325 5.85003 6.49995 5.85003H5.49997C5.30669 5.85003 5.15 5.69333 5.15 5.50001V1.00001C5.15 0.53057 5.53055 0.150023 5.99998 0.150023H10.5C10.6933 0.150023 10.85 0.306741 10.85 0.50004V1.50002C10.85 1.69332 10.6933 1.85004 10.5 1.85004H8.41405H8.05192L8.30798 2.10611L11.8939 5.69205L12 5.79811L12.1061 5.69205L17.5455 0.252527C17.6822 0.115824 17.9038 0.115824 18.0405 0.252527L18.7475 0.959497C18.8842 1.0962 18.8842 1.31777 18.7475 1.45447L12.6009 7.60096L12.6009 7.60098C12.4348 7.76705 12.2176 7.85 11.9999 7.85C11.7823 7.85 11.5651 7.76705 11.399 7.60097L7.10604 3.30801Z'
          fill='#3D3358'
          stroke='white'
          strokeWidth='0.3'
        />
        <path
          d='M22.8911 15.2067L22.8911 15.2067C19.9901 12.1559 16.1205 10.4671 11.9996 10.4671C7.87905 10.4671 4.00945 12.156 1.10885 15.2067L1.10883 15.2068C0.36384 15.9904 0.363537 17.2498 1.10904 18.0332L3.17363 20.2056L3.17406 20.2061C3.93553 21.0059 5.25953 21.0059 6.021 20.2061L6.02134 20.2057C6.66102 19.5329 7.38504 18.9813 8.16861 18.5659C8.80371 18.2368 9.22816 17.5635 9.24398 16.8718L9.49134 15.0073C11.2927 14.4922 12.7277 14.4922 14.5095 15.0059L14.7522 16.8148C14.77 17.5408 15.1639 18.2089 15.8082 18.5631L15.8082 18.5632L15.8179 18.5683C16.6068 18.986 17.3316 19.5379 17.9724 20.2109C18.3459 20.6046 18.8544 20.8254 19.3968 20.8254C19.9387 20.8254 20.4469 20.6046 20.8201 20.2119C20.8202 20.2119 20.8202 20.2118 20.8202 20.2118L22.8911 18.0335L22.3838 17.5512L22.8912 18.0335C23.6363 17.2497 23.6363 15.9905 22.8911 15.2067Z'
          stroke='#3D3358'
          strokeWidth='1.4'
        />
      </g>
      <defs>
        <clipPath id='clip0_21574_191560'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
