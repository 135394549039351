import styled from 'styled-components';
import { TextAreaInput } from 'components/molecules/fields';

export const StyledTextAreaInput = styled(TextAreaInput)`
  &.ant-input {
    margin: 0;
    border-radius: var(--Horizontal, 12px);
    border: 1px solid var(--Stroke-100-Default, #e5e4e7);
    background: var(--Background-200, #faf9fb);
    resize: none;
    font-size: 15px;
    font-weight: 420;
    line-height: 145%; /* 21.75px */
    padding: 14px 16px;
    color: var(--Text-500-Component, #333036);
    &::placeholder {
      color: var(--Text-500-Component, #333036);
    }
  }
`;
