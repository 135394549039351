import { css } from 'styled-components';

export const widths = css`
  .w {
    &-50 {
      width: 50%;
    }
    &-100 {
      width: 100%;
    }
    &-75 {
      width: 75%;
    }
  }
`;
