import { useHistory } from 'react-router-dom';

import { useTwilioContext } from 'lib/twilio';
import * as path from 'constants/routes';
import * as S from '../Styles';

const PurchaseNumber = () => {
  const { loadingVoiceToken } = useTwilioContext();
  const history = useHistory();

  const handlePurchase = () => {
    history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`);
  };

  return (
    <>
      {!loadingVoiceToken && (
        <S.AlertWrapper className='z-20'>
          <S.CustomAlert
            message={
              <>
                <p data-cy='no-number-title' className='lg:inline-block loading'>
                  You don't have any number on this workspace.
                  <>
                    <button
                      data-cy='p-number-button'
                      type='button'
                      onClick={handlePurchase}
                      className='mr-2  border rounded '
                    >
                      Purchase Number
                    </button>
                  </>
                </p>
              </>
            }
            type='info'
            banner
            className='has-bordered-btn'
          />
        </S.AlertWrapper>
      )}
    </>
  );
};

export default PurchaseNumber;
