import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { FETCH_CALL_SCRIPT_BY_ID } from 'graphql/sales-dialer/call-script';

interface IProps {
  callScriptId?: string;
}

export function useCallScriptQuery({ callScriptId }: IProps) {
  const [callScriptTemplate, { data }] = useLazyQuery(FETCH_CALL_SCRIPT_BY_ID, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (callScriptId) {
      callScriptTemplate({
        variables: {
          callScriptId,
        },
      });
    }
  }, [callScriptTemplate, callScriptId]);

  return data?.getCallScriptById?.data;
}
