import { useTwilioContext } from 'lib/twilio';
import { usePhoneQuery } from 'components/organisms/widgets/call-widget/hooks/usePhoneQuery';
import useChannelsContext from 'components/pages/channels/hooks/useChannelsContext';

export function useCallWidgetHandler() {
  const { channels, loading, called } = useChannelsContext();

  const {
    state: { channelId },
  } = useTwilioContext();

  const channelInfo = usePhoneQuery({ channelId });

  return {
    channelInfo,
    channels,
    loadingChannels: loading,
    channelsQueryCalled: called,
  };
}
