import { BannerInfo } from '../styles';

interface BannerProps {
  heading?: any;
  subHeading?: string;
  containerClass?: string;
  vector?: any;
}
export const Banner = ({
  heading = '',
  subHeading = '',
  vector,
  containerClass = '',
}: BannerProps) => {
  return (
    <BannerInfo
      className={`${containerClass} banner-info flex justify-between items-center bg-primary-50 border border-solid border-primary-100 rounded-10
      lg:pl-6 lg:py-5.5 lg:pr-10 p-4
      `}
    >
      <div className='w-4/6'>
        <h6
          className='text-base lg:text-lg leading-snug text-gray-700 font-bold mb-1.5'
          data-cy='banner-heading'
        >
          {heading}{' '}
        </h6>
        <p className='text-14 lg:text-15 text-gray font-normal leading-normal'>{subHeading}</p>
      </div>
      <div className='icon'>{vector}</div>
    </BannerInfo>
  );
};
