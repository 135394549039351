const TextFile = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#E7E6EB' />
    <g clipPath='url(#clip0_25224_204422)'>
      <path
        d='M28.9357 13.8601C22.8845 7.78553 23.2877 8.17316 23.1716 8.10486C22.9343 7.96351 23.5429 8.00599 13.4375 8.00599C12.0157 8.00599 10.8594 9.16578 10.8594 10.5914V29.4145C10.8594 30.84 12.0157 32 13.4375 32H26.5625C27.9841 32 29.1406 30.84 29.1406 29.4145V14.3559C29.1406 14.1699 29.067 13.9917 28.9357 13.8601Z'
        fill='white'
      />
      <path
        d='M28.9357 13.8599C22.8846 7.78554 23.2878 8.17299 23.1716 8.10469C23.0066 8.00618 23.2512 7.99684 20 8.00069V31.9998H26.5625C27.9841 31.9998 29.1406 30.8398 29.1406 29.4143V14.3557C29.1406 14.1697 29.067 13.9915 28.9357 13.8599Z'
        fill='#F8F8F8'
      />
      <path
        d='M29.1406 14.354H25.031C23.8058 14.354 22.8125 13.368 22.8125 12.1514V8.00391C22.9399 8.00391 23.0637 8.0387 23.1716 8.10278C26.605 11.5113 25.2907 10.199 28.9357 13.8582C29.067 13.9898 29.1406 14.168 29.1406 14.354Z'
        fill='#ECECEC'
      />
      <rect x='14' y='16' width='8' height='1' rx='0.5' fill='#FB3449' />
      <rect x='14' y='19' width='12' height='1' rx='0.5' fill='#FB3449' />
      <rect x='14' y='22' width='12' height='1' rx='0.5' fill='#FB3449' />
      <rect x='14' y='25' width='12' height='1' rx='0.5' fill='#FB3449' />
    </g>
    <defs>
      <clipPath id='clip0_25224_204422'>
        <rect width='24' height='24' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>
);
export default TextFile;
