export default function Tagicon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.8727 11.4752L11.4752 14.8727C10.8032 15.5427 9.89293 15.919 8.94395 15.919C7.99498 15.919 7.08472 15.5427 6.4127 14.8727L3.1202 11.5802C2.45016 10.9082 2.0739 9.9979 2.0739 9.04892C2.0739 8.09995 2.45016 7.18968 3.1202 6.51767L6.5252 3.12767C6.87725 2.77465 7.29947 2.49945 7.76456 2.31987C8.22966 2.1403 8.72726 2.06034 9.2252 2.08517L12.9752 2.26517C14.4752 2.33267 15.6677 3.52517 15.7427 5.01767L15.9227 8.76767C15.9677 9.78017 15.5852 10.7627 14.8727 11.4752V11.4752Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.875 9C10.3777 9 9.90081 8.80246 9.54917 8.45083C9.19754 8.09919 9 7.62228 9 7.125C9 6.62772 9.19754 6.15081 9.54917 5.79917C9.90081 5.44754 10.3777 5.25 10.875 5.25C11.3723 5.25 11.8492 5.44754 12.2008 5.79917C12.5525 6.15081 12.75 6.62772 12.75 7.125C12.75 7.62228 12.5525 8.09919 12.2008 8.45083C11.8492 8.80246 11.3723 9 10.875 9V9Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
