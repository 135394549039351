import { Popover } from 'antd';
import styled from 'styled-components';

export const MoreOptionsPopover = styled(Popover)`
  width: fit-content;
  cursor: pointer;
  > svg {
    width: 28px;
    height: 28px;
    padding: 4px;
    color: #857f96;
    border-radius: 6px;
  }
  > svg:hover,
  &.ant-popover-open > svg {
    background: #ece6f2;
    color: #564d6d;
  }
`;
