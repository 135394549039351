export default function Userlockicon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='32' fill='none' viewBox='0 0 36 32'>
      <path
        fill='#76A1F8'
        d='M0 26.586C0 19.36 5.86 13.5 13.087 13.5c7.228 0 13.087 5.86 13.087 13.087H0zM13.297 11.79a5.895 5.895 0 100-11.79 5.895 5.895 0 000 11.79z'
      />
      <path
        fill='#857F96'
        d='M33.102 23.247h-1.94V13.18a4.07 4.07 0 00-4.064-4.065 4.07 4.07 0 00-4.065 4.065v10.068h-1.94V13.18a6.01 6.01 0 016.005-6.005 6.01 6.01 0 016.004 6.005v10.068z'
      />
      <path
        fill='#857F96'
        d='M27.103 7.174h-.021v1.94h.02a4.07 4.07 0 014.065 4.064v10.07h1.94v-10.07a6.01 6.01 0 00-6.004-6.004z'
      />
      <path
        fill='#FFC61B'
        d='M34.2 31.69H20.008a1.524 1.524 0 01-1.524-1.524v-11.32c0-.84.683-1.523 1.524-1.523H34.2c.841 0 1.524.682 1.524 1.524v11.319c0 .841-.682 1.523-1.524 1.523z'
      />
    </svg>
  );
}
