import { createContext, useState } from 'react';
import { WIDGET_NAV_SCREENS } from '../constants';
import useCallWidgetDrawerContext from '../hooks/useCallWidgetDrawerContext';

export const CallWidgetNavContext = createContext<any>({});

export const CallWidgetNavProvider = ({ children }: any) => {
  const { CALL_WIDGET } = WIDGET_NAV_SCREENS;
  const [selectedNavScreen, setSelectedNavScreen] = useState(CALL_WIDGET);

  const { closeDrawer } = useCallWidgetDrawerContext();

  const handleRedirectToMainScreen = () => {
    setSelectedNavScreen(CALL_WIDGET);
    closeDrawer();
  };

  return (
    <CallWidgetNavContext.Provider
      value={{
        selectedNavScreen,
        setSelectedNavScreen,
        handleRedirectToMainScreen,
      }}
    >
      {children}
    </CallWidgetNavContext.Provider>
  );
};
