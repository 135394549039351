import { useHistory, useLocation } from 'react-router-dom';

import { NUMBERS } from 'constants/routes';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';
import { customEncodeURIComponent } from 'components/utils/url';

import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';

export const useQuickSms = () => {
  const { channelInfo, clientInfo } = useActiveCallWidgetContext();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });
  const { id: channelId } = channelInfo || {};
  const { number, id: contactId } = clientInfo || {};
  const handleOpenConversation = () => {
    if (!channelId) return;
    if (isDialerPage) {
      window.open(
        `${NUMBERS}/${channelId}/${contactId ?? `new/?phone=${customEncodeURIComponent(number)}`}`,
        '_blank',
      );
      return;
    }
    history.push(
      `${NUMBERS}/${channelId}/${contactId ?? `new/?phone=${customEncodeURIComponent(number)}`}`,
    );
  };
  return { handleOpenConversation };
};
