import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PasswordInput } from 'components/atoms/fields';
import { Label, Error } from 'components/atoms';
import { InputFieldWrapper, FlexColumn } from './styled';
import { PasswordInputProps } from './types';

const PasswordField: React.FC<PasswordInputProps> = props => {
  const {
    label,
    id,
    name,
    value,
    placeholder,
    required = false,
    errors,
    onChange,
    control,
    className,
    iconRender,
    visibilityToggle,
    ...rest
  } = props;
  const { t } = useTranslation();

  const hasErrors = errors?.[name];

  const defaultIconRender = (visible: boolean) =>
    visible ? <RiEyeLine size={18} /> : <RiEyeOffLine size={18} />;

  return (
    <>
      <InputFieldWrapper className={hasErrors ? 'field-error' : ''}>
        <FlexColumn>
          {label && (
            <Label id={id || name} required={required}>
              {label}
            </Label>
          )}
          {!control && (
            <PasswordInput
              {...rest}
              id={id || name}
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                  onChange(event);
                }
              }}
              iconRender={iconRender || defaultIconRender}
              className={className}
              visibilityToggle={visibilityToggle}
            />
          )}
          {control && (
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <PasswordInput
                    {...rest}
                    {...field}
                    id={id || name}
                    value={field.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (onChange) {
                        onChange(event);
                      } else {
                        field.onChange(event);
                      }
                    }}
                    iconRender={iconRender || defaultIconRender}
                    className={className}
                    visibilityToggle={visibilityToggle}
                  />
                );
              }}
            />
          )}
          {errors?.[name] && <Error>{t(`constants:${errors[name].message}`)}</Error>}
        </FlexColumn>
      </InputFieldWrapper>
    </>
  );
};

PasswordField.displayName = 'PasswordField';
export default PasswordField;
