import { ReactElement } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ContactInputData } from 'generated/graphql';
import { Input } from 'components/molecules/fields';
import { useAddContactMutation } from 'components/organisms/contact/modals/useAddContactMutation';
import { NUMBERS } from 'constants/routes';
import { InputBox, Label, ToastMessage } from 'components/atoms';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { customEncodeURIComponent } from 'components/utils/url';
import { MAX_LENGTH } from 'schema-validation';

import { schema } from './schema';
import * as S from './Styles';
import useCallWidgetContext from '../hooks/useCallWidgetContext';

interface IContactAddProps {
  handleClose: () => void;
  defaultNumber?: string | null;
}

export function ContactAdd({ handleClose, defaultNumber }: IContactAddProps): ReactElement {
  const { clientInfo, channelInfo } = useCallWidgetContext();
  const { number } = clientInfo || {};
  const history = useHistory();
  const location = useLocation();
  const phoneNumber = defaultNumber?.replace(/\+/g, '') || number?.replace(/\+/g, '') || '';
  const { phoneFormatIntl, flagUrl } = parsePhoneNumber(phoneNumber);

  const handleCloseBtnClick = () => {
    handleClose();
  };

  const onAddContact = (contact: Client) => {
    handleClose();
    const { pathname } = location;
    if (
      pathname.includes(
        `${channelInfo?.id}/new/?phone=${customEncodeURIComponent(clientInfo?.number)}`,
      )
    ) {
      history.push(`${NUMBERS}/${channelInfo?.id}/${contact?.id}`);
    }
  };

  const { addContact, loading } = useAddContactMutation({ onAddContact });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ContactInputData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: null,
    },
  });

  const formSubmit = async (val: any) => {
    const data = {
      name: val.name || phoneNumber,
      number: phoneNumber,
      visibility: true,
    };
    if (val.name?.trim()?.length > MAX_LENGTH) {
      ToastMessage({
        content: `Shouldn't exceed ${MAX_LENGTH} characters`,
        type: 'danger',
      });
      return;
    }
    await addContact(data, setError);
  };

  return (
    <S.FormContainer onSubmit={handleSubmit(formSubmit)}>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-center '>
          Add to Contact
        </p>
        <button
          type='submit'
          className='text-success font-manrope text-xs leading-3 font-semibold'
          disabled={loading}
        >
          Save
        </button>
      </div>
      <div className='py-6 px-4 text-left'>
        <div>
          <Input name='name' id='name' label='Contact Name' control={control} errors={errors} />
        </div>
        <div className='mt-4'>
          <Label>Phone Numbers</Label>
          <InputBox>
            <div className='flex items-center h-full px-4 py-3'>
              {flagUrl && <img src={flagUrl} alt='flag' className='mr-2.5 h-3 w-5' />}
              <p>{phoneFormatIntl || `+${phoneNumber.replace(/\+/g, '')}`}</p>
            </div>
          </InputBox>
        </div>
      </div>
    </S.FormContainer>
  );
}
