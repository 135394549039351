import { Call } from '@twilio/voice-sdk';

export const getCallParams = (call?: Call) => {
  return {
    customParameters: call?.customParameters
      ? JSON.stringify([...call?.customParameters?.entries()])
      : {},
    parameters: call?.parameters,
  };
};
