import { memo } from 'react';

interface IProps {
  children: React.ReactNode;
}

const InputBox = ({ children }: IProps) => {
  return (
    <div className='h-11 bg-gray-50 border border-gray-200 rounded-xl text-sm leading-4 text-gray-700'>
      {children}
    </div>
  );
};

export default memo(InputBox);
