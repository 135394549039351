import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

export const CollapseWrapper = styled.div`
  .ant-collapse {
    padding: 0 20px;
  }
  .ant-collapse-item {
    margin-bottom: 2px;
    .ant-collapse-content {
      margin-bottom: 10px;
    }
    .ant-collapse-header {
      height: 34px;
      margin-bottom: 6px;
      text-transform: uppercase;
      font-family: Manrope;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px !important;
      letter-spacing: 0.03em;
      color: #6e6681 !important;
      > svg.ant-collapse-arrow {
        height: 14px;
        width: 14px;
        margin-right: 2px;
        color: #6e6681 !important;
        top: 14px !important;
      }
    }

    &.tags-panel {
      .ant-collapse-content-box {
        padding: 0 !important;
      }
      .ant-collapse-header {
        margin-bottom: -4px;
      }
    }
    &.content-padding {
      .ant-collapse-content-box {
        padding: 0 8px !important;
      }
    }
  }

  .notes-collapse-header {
    span {
      display: inline-block;
      border: 1px solid #b7b3c1;
      border-radius: 8px;
      padding: 3px 4px;
      position: relative;
      left: 6px;
      letter-spacing: 0.02em;
    }
  }
`;

export const NotesBox = styled(TextArea)`
  background: ${({ theme }) => theme.colors.gray.g1};
  resize: none;
  max-width: 300px;
  border: none;
  border-radius: 10px;
  margin-top: -4px;
  margin-bottom: 14px;
  padding: 14px;
  height: 60px !important;
  width: 300px !important;
  font-size: ${({ theme }) => theme.text.size.body.text.caption};
  line-height: 12px !important;
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.t6};
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
  }
`;

export const ClientNotesContainer = styled.div`
  margin-top: -8px;
  .ant-input {
    background: ${({ theme }) => theme.colors.text.t0};
    resize: none;
    padding: 11.5px 12px;
    font-size: 14px;
    color: #6e6681;
    &::placeholder {
      color: #9e99ab;
    }
    &:focus {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.text.t2};
    }
  }
`;

export const NotesDisplayCard = styled.div`
  padding: 14px 0;
  & + div {
    border-top: 1px solid ${({ theme }) => theme.colors.text.t1};
  }

  .note {
    &--header {
      display: flex;
      align-items: center;
    }
    &--user {
      font-size: 15px;
      font-weight: 500;
      line-height: 12px;
      color: ${({ theme }) => theme.colors.text.t7};
    }
    &--time {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      margin-top: 6px;
      color: ${({ theme }) => theme.colors.text.t5};
    }
    &--action {
      margin-left: auto;
      margin-right: 10px;
      cursor: pointer;
      svg {
        color: ${({ theme }) => theme.colors.text.t7};
      }
    }
    &--avatar {
      border-radius: 14px;
      min-width: 36px;
      margin-right: 10px;
    }
    &--body {
      margin-top: 15px;
      font-weight: 400;
      font-size: 14px;
      word-break: break-word;
      line-height: 21px;
      color: ${({ theme }) => theme.colors.text.t6};
    }
    &--action {
      margin-left: auto;
    }
    &--info {
      margin-left: 7px;
      font-size: 12px;
      line-height: 12px;
      color: ${({ theme }) => theme.colors.text.t7};
    }
  }
`;
