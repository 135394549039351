import { gql } from '@apollo/client';

export const CLIENT_INFO = gql`
  fragment ClientInfoFields on ClientInfo {
    id
    number
    name
    country
    createdBy
    profilePicture
  }
`;

export const TAG_NODE = gql`
  fragment TagFields on Tag {
    id
    title
    colorCode
    backgroundColorCode
    count
  }
`;

export const CLIENT = gql`
  fragment ClientFields on Client {
    id
    email
    name
    number
    address
    company
    visibility
    clientId
    country
    blocked
    profilePicture
    tags {
      ...TagFields
    }
    createdBy
    createdAt
  }
  ${TAG_NODE}
`;

// need to change name
export const TAG_RESPONSE_NODE = gql`
  fragment TagResponseFields on TagResponseData {
    id
    title
    colorCode
    backgroundColorCode
  }
`;

export const CONTACT_NODE = gql`
  fragment ContactFields on ContactNode {
    id
    email
    name
    createdOn
    number
    address
    company
    visibility
    clientId
    country
    profilePicture
    blocked
    tags {
      ...TagFields
    }
    createdBy
    dndInfo {
      dndEnabled
      dndDuration
      dndEndtime
    }
  }
  ${TAG_NODE}
`;
