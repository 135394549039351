const Working = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_20039_185218)'>
      <path
        d='M30 12.3938V15.9375H29.0625C28.0312 15.9375 27.1875 16.7812 27.1875 17.8125V8.4375C27.1875 7.37813 27.4781 6.39375 27.9844 5.55C28.7719 4.24688 30.0656 3.28125 31.5938 2.94375C31.9875 2.85938 32.3906 2.8125 32.8125 2.8125H37.9969C40.7531 2.8125 43.4719 2.175 45.9375 0.9375V7.03125C45.9375 7.19063 45.9281 7.34062 45.9187 7.5C45.7687 9.45937 44.6906 11.1656 43.125 12.1594V12.1687C42.1781 12.7687 41.0531 13.125 39.8438 13.125H36.5625V9.375C36.5625 9.375 35.5312 10.6687 33.7312 11.8969C32.55 12.7031 31.1812 12.8063 30 12.3938V12.3938Z'
        fill='#9A4738'
      />
      <path
        d='M33.8287 6.20719C35.4909 5.36344 36.6468 4.06406 37.0275 2.8125H32.8125C32.3906 2.8125 31.9875 2.85938 31.5937 2.94375C30.0656 3.28125 28.7718 4.24688 27.9843 5.55C27.9403 5.62406 27.9065 5.70469 27.8662 5.78062C28.7915 7.23562 31.3865 7.44656 33.8287 6.20719Z'
        fill='#AE6C60'
      />
      <path
        d='M59.0625 56.25V58.125C59.0625 58.6425 58.6425 59.0625 58.125 59.0625H1.875C1.3575 59.0625 0.9375 58.6425 0.9375 58.125V56.25C0.9375 55.7325 1.3575 55.3125 1.875 55.3125H19.6875H22.5H32.8125H40.3125H47.8125H53.1563H53.4375H58.125C58.6425 55.3125 59.0625 55.7325 59.0625 56.25Z'
        fill='#FCB984'
      />
      <path
        d='M50.625 33.75V50.625C50.625 51.1406 50.2031 51.5625 49.6875 51.5625H40.3125L39.975 51.5906L39.375 45H33.75L33.15 51.5906L32.8125 51.5625H23.4375C22.9219 51.5625 22.5 51.1406 22.5 50.625V33.75C22.5 33.2344 22.9219 32.8125 23.4375 32.8125H36.5625H49.6875C50.2031 32.8125 50.625 33.2344 50.625 33.75Z'
        fill='#E0DAE0'
      />
      <path
        d='M22.5 50.625C22.5 51.1406 22.9219 51.5625 23.4375 51.5625H24.375H32.8125L33.1969 51.5906L33.4472 48.5934H22.5V50.625Z'
        fill='#A8A3A8'
      />
      <path
        d='M49.6875 32.8125H49.3313H48.1247V43.5159C48.1247 46.3209 45.8513 48.5944 43.0463 48.5944H39.6769L39.9272 51.5916L40.3125 51.5625H48.75H49.6875C50.2031 51.5625 50.625 51.1406 50.625 50.625V33.75C50.625 33.2344 50.2031 32.8125 49.6875 32.8125V32.8125Z'
        fill='#A8A3A8'
      />
      <path
        d='M43.125 19.6875V20.625C43.125 22.4438 42.3938 24.075 41.2031 25.2656C40.9313 25.5375 40.6313 25.7906 40.3125 26.0062C39.2531 26.7562 37.9594 27.1875 36.5625 27.1875C35.1656 27.1875 33.8719 26.7562 32.8125 26.0062C31.1062 24.825 30 22.8563 30 20.625V19.6875V15.9375V12.3938C31.1812 12.8063 32.55 12.7031 33.7312 11.8969C35.5312 10.6687 36.5625 9.375 36.5625 9.375V13.125H39.8438C41.0531 13.125 42.1781 12.7687 43.125 12.1687V15.9375V19.6875Z'
        fill='#FCBFA9'
      />
      <path
        d='M44.1656 1.70437C42.7631 5.62594 39.0253 8.4375 34.6219 8.4375C30.5156 8.4375 27.1875 11.7656 27.1875 15.8719V17.8125C27.1875 16.7812 28.0312 15.9375 29.0625 15.9375H30V12.3938C31.1812 12.8063 32.55 12.7031 33.7312 11.8969C35.5312 10.6687 36.5625 9.375 36.5625 9.375V13.125H39.8438C41.0531 13.125 42.1781 12.7687 43.125 12.1687V12.1594C44.6906 11.1656 45.7687 9.45937 45.9187 7.5C45.9281 7.34062 45.9375 7.19063 45.9375 7.03125V0.9375C45.36 1.22719 44.7675 1.48125 44.1656 1.70437V1.70437Z'
        fill='#73352A'
      />
      <path
        d='M39.8438 13.125H36.5625V9.375C36.5625 9.375 35.5312 10.6687 33.7312 11.8969C32.55 12.7031 31.1812 12.8063 30 12.3938C30 14.1666 31.4372 15.6028 33.2091 15.6028H37.5478C39.3206 15.6028 40.7569 17.04 40.7569 18.8119V25.6716C42.2091 24.4612 43.125 22.6603 43.125 20.625V19.6875V15.9375V12.1687C42.1781 12.7687 41.0531 13.125 39.8438 13.125V13.125Z'
        fill='#ED907A'
      />
      <path
        d='M57.1875 55.3125H56.1C56.1 56.3484 55.2609 57.1875 54.225 57.1875H0.9375V58.125C0.9375 58.6425 1.3575 59.0625 1.875 59.0625H58.125C58.6425 59.0625 59.0625 58.6425 59.0625 58.125V56.25C59.0625 55.7325 58.6425 55.3125 58.125 55.3125H57.1875Z'
        fill='#FF9D5A'
      />
      <path
        d='M58.6688 47.6906C58.9125 48.3375 58.7719 49.0781 58.3031 49.5938L53.4375 55.0031V53.4375C53.4375 52.4063 52.5938 51.5625 51.5625 51.5625H49.6875C50.2031 51.5625 50.625 51.1406 50.625 50.625L57.1875 44.0625L57.2906 44.025L58.6688 47.6906Z'
        fill='#FCBFA9'
      />
      <path
        d='M54.0187 35.2969L57.2906 44.025L57.1875 44.0625L50.625 50.625V33.75C50.625 33.2344 50.2031 32.8125 49.6875 32.8125H36.5625V31.875C38.5687 31.875 40.2 30.3 40.3031 28.3219L43.0875 28.9219L47.9906 29.9719C50.7937 30.5719 53.0719 32.5875 54.0187 35.2969V35.2969Z'
        fill='#235085'
      />
      <path
        d='M53.4375 53.4375V55.0031L53.1563 55.3125H47.8125V53.4375C47.8125 52.4062 48.6562 51.5625 49.6875 51.5625H51.5625C52.5938 51.5625 53.4375 52.4062 53.4375 53.4375Z'
        fill='#ED907A'
      />
      <path
        d='M40.3125 51.5625H49.6875C48.6562 51.5625 47.8125 52.4062 47.8125 53.4375V55.3125H40.3125L39.975 51.5906L40.3125 51.5625Z'
        fill='#235085'
      />
      <path
        d='M45.9375 7.5V17.8125C45.9375 17.2969 45.7312 16.8281 45.3844 16.4906C45.0469 16.1438 44.5781 15.9375 44.0625 15.9375H43.125V12.1687V12.1594C44.6906 11.1656 45.7687 9.45937 45.9187 7.5H45.9375Z'
        fill='#9A4738'
      />
      <path
        d='M45.3844 16.4906C45.7312 16.8281 45.9375 17.2969 45.9375 17.8125C45.9375 18.8438 45.0938 19.6875 44.0625 19.6875H43.8844H43.125V15.9375H44.0625C44.5781 15.9375 45.0469 16.1438 45.3844 16.4906Z'
        fill='#FCBFA9'
      />
      <path
        d='M43.8844 19.6875C43.9969 20.6062 44.0625 21.5437 44.0625 22.5C44.0625 24.7406 43.725 26.8969 43.0875 28.9219L40.3031 28.3219C40.3125 28.2562 40.3125 28.1906 40.3125 28.125V26.0062C40.6312 25.7906 40.9312 25.5375 41.2031 25.2656C42.3937 24.075 43.125 22.4437 43.125 20.625V19.6875H43.8844Z'
        fill='#E52C55'
      />
      <path
        d='M40.3125 26.0063V28.125C40.3125 28.1906 40.3125 28.2562 40.3031 28.3219C40.2 30.3 38.5688 31.875 36.5625 31.875C35.5312 31.875 34.5937 31.4531 33.9094 30.7781C33.2812 30.1406 32.8687 29.2781 32.8219 28.3219C32.8125 28.2562 32.8125 28.1906 32.8125 28.125V26.0063C33.8719 26.7563 35.1656 27.1875 36.5625 27.1875C37.9594 27.1875 39.2531 26.7563 40.3125 26.0063V26.0063Z'
        fill='#ED907A'
      />
      <path
        d='M39.975 51.5906L40.3125 55.3125H32.8125L33.15 51.5906L33.75 45H39.375L39.975 51.5906Z'
        fill='#B2A6B2'
      />
      <path
        d='M36.5625 31.875V32.8125H23.4375C22.9219 32.8125 22.5 33.2344 22.5 33.75V50.625L15.9375 44.0625L15.8344 44.025L16.1719 43.125L17.4937 39.6L19.1062 35.2969C20.0531 32.5875 22.3312 30.5719 25.1344 29.9719L32.8125 28.3219H32.8218C32.8687 29.2781 33.2812 30.1406 33.9093 30.7781C34.5937 31.4531 35.5312 31.875 36.5625 31.875V31.875Z'
        fill='#2F6BB2'
      />
      <path
        d='M33.15 51.5906L32.8125 55.3125H22.5C23.0156 55.3125 23.4375 54.8906 23.4375 54.375V51.5625H32.8125L33.15 51.5906Z'
        fill='#2F6BB2'
      />
      <path
        d='M32.8125 28.125C32.8125 28.1906 32.8125 28.2562 32.8219 28.3219H32.8125L25.1344 29.9719C22.3312 30.5719 20.0531 32.5875 19.1062 35.2969L17.4937 39.6C10.0875 37.4344 4.6875 30.6 4.6875 22.5C4.6875 12.6656 12.6656 4.6875 22.5 4.6875C24.4125 4.6875 26.2594 4.9875 27.9844 5.55C27.4781 6.39375 27.1875 7.37812 27.1875 8.4375V17.8125C27.1875 18.3281 27.3938 18.7969 27.7406 19.1344C28.0781 19.4812 28.5469 19.6875 29.0625 19.6875H30V20.625C30 22.8562 31.1062 24.825 32.8125 26.0062V28.125Z'
        fill='#E0DAE0'
      />
      <path
        d='M32.8125 28.125V26.0062C31.1062 24.825 30 22.8562 30 20.625V19.6875H29.0625C28.5468 19.6875 28.0781 19.4812 27.7406 19.1344C27.3937 18.7969 27.1875 18.3281 27.1875 17.8125V8.4375C27.1875 7.37812 27.4781 6.39375 27.9843 5.55C26.2593 4.9875 24.4125 4.6875 22.5 4.6875C22.0275 4.6875 21.5625 4.72219 21.0993 4.75875C23.3568 12.8822 26.279 21.5334 22.5 25.3125C19.769 28.9537 14.3925 32.1544 10.7568 35.8772C12.6825 37.5684 14.9662 38.8612 17.4937 39.6L19.1062 35.2969C20.0531 32.5875 22.3312 30.5719 25.1343 29.9719L32.8125 28.3219H32.8218C32.8125 28.2562 32.8125 28.1906 32.8125 28.125V28.125Z'
        fill='#A8A3A8'
      />
      <path
        d='M30 15.9375V19.6875H29.0625C28.5469 19.6875 28.0781 19.4812 27.7406 19.1344C27.3938 18.7969 27.1875 18.3281 27.1875 17.8125C27.1875 16.7812 28.0312 15.9375 29.0625 15.9375H30Z'
        fill='#FCBFA9'
      />
      <path
        d='M31.5938 2.94375C30.0656 3.28125 28.7719 4.24688 27.9844 5.55C26.2594 4.9875 24.4125 4.6875 22.5 4.6875C12.6656 4.6875 4.6875 12.6656 4.6875 22.5C4.6875 30.6 10.0875 37.4344 17.4937 39.6L16.1719 43.125L16.0875 43.0969C7.3125 40.3594 0.9375 32.175 0.9375 22.5C0.9375 10.5938 10.5938 0.9375 22.5 0.9375C25.7531 0.9375 28.8375 1.65938 31.5938 2.94375V2.94375Z'
        fill='#E52C55'
      />
      <path d='M24.375 20.625V24.375H20.625V22.5V20.625H22.5H24.375Z' fill='#B2A6B2' />
      <path
        d='M23.4375 51.5625V54.375C23.4375 54.8906 23.0156 55.3125 22.5 55.3125H19.6875C19.1719 55.3125 18.75 54.8906 18.75 54.375V53.9625V51.5625C18.75 51.0469 19.1719 50.625 19.6875 50.625H22.5C22.5 51.1406 22.9219 51.5625 23.4375 51.5625Z'
        fill='#ED907A'
      />
      <path
        d='M22.5 50.625H19.6875C19.1719 50.625 18.75 51.0469 18.75 51.5625V53.9625L14.8219 49.5938C14.3532 49.0781 14.2125 48.3375 14.4563 47.6906L15.8344 44.025L15.9375 44.0625L22.5 50.625Z'
        fill='#FCBFA9'
      />
      <path
        d='M32.8125 20.3166C32.295 20.3166 31.875 19.8966 31.875 19.3791V18.4416C31.875 17.9241 32.295 17.5041 32.8125 17.5041C33.33 17.5041 33.75 17.9241 33.75 18.4416V19.3791C33.75 19.8975 33.33 20.3166 32.8125 20.3166Z'
        fill='#606060'
      />
      <path
        d='M40.3125 20.3166C39.795 20.3166 39.375 19.8966 39.375 19.3791V18.4416C39.375 17.9241 39.795 17.5041 40.3125 17.5041C40.83 17.5041 41.25 17.9241 41.25 18.4416V19.3791C41.25 19.8975 40.83 20.3166 40.3125 20.3166Z'
        fill='#606060'
      />
      <path
        d='M24.375 19.6875H23.4375V10.7006L23.7122 10.9753C23.895 11.1581 24.135 11.25 24.375 11.25C24.615 11.25 24.855 11.1581 25.0378 10.9753C25.4044 10.6087 25.4044 10.0163 25.0378 9.64969L23.1628 7.77469C22.7962 7.40813 22.2037 7.40813 21.8372 7.77469L19.9622 9.64969C19.5956 10.0163 19.5956 10.6087 19.9622 10.9753C20.3287 11.3419 20.9212 11.3419 21.2878 10.9753L21.5625 10.7006V19.6875H20.625C20.1075 19.6875 19.6875 20.1075 19.6875 20.625V21.5625H14.4506L14.7253 21.2878C15.0919 20.9213 15.0919 20.3287 14.7253 19.9622C14.3587 19.5956 13.7662 19.5956 13.3997 19.9622L11.5247 21.8372C11.1581 22.2037 11.1581 22.7963 11.5247 23.1628L13.3997 25.0378C13.5825 25.2206 13.8225 25.3125 14.0625 25.3125C14.3025 25.3125 14.5425 25.2206 14.7253 25.0378C15.0919 24.6713 15.0919 24.0787 14.7253 23.7122L14.4506 23.4375H19.6875V24.375C19.6875 24.8925 20.1075 25.3125 20.625 25.3125H24.375C24.8925 25.3125 25.3125 24.8925 25.3125 24.375V20.625C25.3125 20.1075 24.8925 19.6875 24.375 19.6875ZM23.4375 23.4375H21.5625V21.5625H23.4375V23.4375Z'
        fill='#606060'
      />
      <path
        d='M39.375 42.1875H33.75C33.2325 42.1875 32.8125 41.7675 32.8125 41.25C32.8125 40.7325 33.2325 40.3125 33.75 40.3125H39.375C39.8925 40.3125 40.3125 40.7325 40.3125 41.25C40.3125 41.7675 39.8925 42.1875 39.375 42.1875Z'
        fill='#B2A6B2'
      />
      <path
        d='M27.33 3.60281C25.7653 3.2025 24.1453 3 22.5 3C11.7478 3 3 11.7478 3 22.5C3 30.4987 7.97813 37.7119 15.3056 40.6294L15.2334 40.8206L16.8216 41.3916L17.4938 39.6C10.0875 37.4344 4.6875 30.6 4.6875 22.5C4.6875 12.6656 12.6656 4.6875 22.5 4.6875C24.4125 4.6875 26.2594 4.9875 27.9844 5.55C28.3181 4.99781 28.7475 4.51125 29.2416 4.1025L27.4931 3.42844C27.4387 3.48656 27.3825 3.54375 27.33 3.60281V3.60281Z'
        fill='#CC0033'
      />
    </g>
    <defs>
      <clipPath id='clip0_20039_185218'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Working;
