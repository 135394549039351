import { useChannelsQuery } from 'hooks/useChannelsQuery';
import { createContext, useMemo } from 'react';

export const ChannelsContext = createContext<any>({});

export const ChannelsProvider = ({ children }: any) => {
  const { numbers: channels, loading, called } = useChannelsQuery();

  const value = useMemo(() => ({ channels, loading, called }), [channels, loading, called]);

  return <ChannelsContext.Provider value={value}>{children}</ChannelsContext.Provider>;
};
