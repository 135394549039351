import { useEffect, useState } from 'react';
import { ALL_TAGS } from 'graphql/client';
import { useLazyQuery } from '@apollo/client';

export function useTagsQuery() {
  const [tagList, setTagList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadTags, { called, data, loading }] = useLazyQuery(ALL_TAGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (arg: any) => {
      setTagList(arg?.allTags?.data ?? []);
    },
  });

  useEffect(() => {
    if (!called) {
      loadTags({});
    }
  }, [called, loadTags]);

  const onSearch = (evt: any) => {
    const keyword = evt.target.value;
    const tokens = keyword
      .toLowerCase()
      .split(' ')
      .filter((token: string) => {
        return token.trim() !== '';
      });
    const searchTermRegex = new RegExp(tokens.join('|'), 'gim');
    const filteredList = data?.allTags?.data?.filter((tag: any) =>
      tag?.title?.match(searchTermRegex),
    );
    setSearchTerm(keyword);
    setTagList(keyword ? filteredList : data?.allTags?.data);
  };

  const hasTags = tagList?.length > 0;
  const counts = tagList?.length ?? 0;
  return {
    tagList,
    hasTags,
    loading,
    counts,
    called,
    onSearch,
    searchTerm,
    setTagList,
  };
}
