import { useState, useContext, useEffect, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Dropdown, Skeleton, Menu } from 'antd';
import { RiPhoneFill, RiMoreFill } from 'react-icons/ri';
import getUnixTime from 'date-fns/getUnixTime';

import { useTwilioContext } from 'lib/twilio';
import { Input } from 'components/molecules/fields';
import { Button, Tooltip, Icon } from 'components/atoms';
import { timeAgo } from 'components/utils/datetime';
import { isEmptyObject } from 'components/utils/helpers';
import { ContactContext } from 'components/organisms/contact/ContactContext';
import { Wrapper, Actions } from 'components/organisms/common/member-and-client-detail/Styles';
import * as S from './Styles';
import ContactDropdownOverlay from './ContactDropdownOverlay';

import useDNDMutation from '../hooks/useDNDMutaion';
import { useClientDNDMenu } from '../hooks/useClientDNDMenu';
import ClientProfilePic from './ClientProfilePic';
import { useContactMeta } from '../hooks/useContactMeta';
import { useCallOrMessage } from '../../useCallOrMessage';
import { ContactDetailContext } from '../ContactDetailContext';

function ContactDetailHeader() {
  const { selectDND } = useDNDMutation();
  const { contactId } = useParams<Record<string, string | undefined>>();

  const { t } = useTranslation();
  const { contactData, loading } = useContext<any>(ContactContext);

  const { blocked = false, number, dndInfo } = contactData || {};
  const { dndDuration, dndEnabled, dndEndtime } = dndInfo || {};
  const { menu, DND_VALUES } = useClientDNDMenu({ dndDuration, dndEnabled, dndEndtime });
  const {
    control,
    formState: { errors },
  } = useFormContext();
  // eslint-disable-next-line prettier/prettier
  const { handleSaveContactName, handleEditableChange, editableField } = useContext(
    ContactDetailContext,
  );
  const {
    state: { showPhoneWidget },
  } = useTwilioContext();

  const { lastContacted } = useContactMeta({ contact: number });

  const { onCallNow } = useCallOrMessage({
    client: contactData,
  });

  const [dropdownActionVisible, setDropdownActionVisible] = useState(false);
  const handleDropdownActionVisibility = (visible: boolean) => {
    setDropdownActionVisible(visible);
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownVisibility = (visible: boolean) => {
    setDropdownVisible(visible);
  };
  const handleCall = () => onCallNow();

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;
    if (dndEndtime) {
      timerId = setInterval(() => {
        const currentTime = getUnixTime(new Date());
        if (dndEndtime <= currentTime) {
          selectDND('OFF', number);
          clearInterval(timerId);
        }
      }, 20000);
    }
    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  const handleSave = (e: any) => {
    e.preventDefault();
    const { value } = e.target;
    if (contactId !== 'new' && contactId !== contactData?.id) return;
    handleSaveContactName(value.trim?.() || contactData?.number);
  };

  if (isEmptyObject(contactData) || loading) {
    return (
      <Wrapper className='skeleton-wrapper'>
        <Skeleton.Avatar active size={80} />
        <Skeleton active paragraph={{ rows: 1 }} />
        <div className='flex gap-4'>
          <Skeleton.Button active />
          <Skeleton.Button active />
          <Skeleton.Button active />
        </div>
      </Wrapper>
    );
  }

  const handleMenuClick = ({ key }: any) => {
    if (DND_VALUES.includes(key)) selectDND(key, number);
  };

  const muteTooltipTitle = dndEnabled ? (
    <Trans i18nKey='unmute'>Unmute</Trans>
  ) : (
    <Trans i18nKey='mute'>Mute</Trans>
  );

  return (
    <Wrapper>
      <div className={`relative top-block mb-4.75 z-10 ${blocked ? 'pointer-events-none' : ''}`}>
        <ClientProfilePic data-cy='client-profilepic-wrapper' />
        {blocked && (
          <div className='absolute -bottom-0.5 -right-0.5'>
            <Icon name='contact-block' />
          </div>
        )}
      </div>
      <S.Title>
        <div className='edit-field-wrapper'>
          <Input
            name='name'
            id='name'
            control={control}
            placeholder={contactData?.number || 'Name'}
            errors={errors}
            onPressEnter={handleSave}
            onBlur={handleSave}
            onClick={handleEditableChange('name')}
            isEditable={editableField === 'name'}
            className='truncate'
            // autoFocus={true}
          />
        </div>
      </S.Title>
      <p className='text-sm leading-3.5 text-gray-500'>
        {t('lastContacted', 'Last Contacted')}:
        <span data-cy='last-contact-time' className='ml-1 text-gray-700'>
          {(lastContacted && timeAgo(lastContacted)) || 'N/A'}
        </span>
      </p>
      <Actions data-cy='contacts-items-icons-actions'>
        <Tooltip title={<Trans i18nKey='callNow'>Call Now</Trans>}>
          <span>
            <Button
              data-cy='contacts-items-phone'
              className={`btn z-10 ${showPhoneWidget ? 'custom-disabled-cursor' : ''}`}
              icon={<RiPhoneFill />}
              disabled={showPhoneWidget}
              onClick={handleCall}
            />
          </span>
        </Tooltip>

        <Tooltip title={muteTooltipTitle}>
          <Dropdown
            overlay={<Menu onClick={handleMenuClick}>{menu}</Menu>}
            trigger={['click']}
            placement='bottom'
            overlayClassName='numbercard-dropdown'
            visible={dropdownVisible}
            onVisibleChange={handleDropdownVisibility}
          >
            <span>
              <Button
                data-cy='contacts-items-notify'
                className={`btn ${
                  showPhoneWidget || blocked ? 'custom-disabled-cursor' : ''
                } active:bg-primary-200`}
                icon={<Icon name={`notification-${dndEnabled ? 2 : 1}`} />}
                disabled={showPhoneWidget || blocked}
                danger={dndEnabled}
              />
            </span>
          </Dropdown>
        </Tooltip>
        <Tooltip
          data-cy='contacts-items-moreoptions'
          title={<Trans i18nKey='moreActions'>More Actions</Trans>}
        >
          <Dropdown
            overlay={
              <ContactDropdownOverlay closeDropdown={() => handleDropdownActionVisibility(false)} />
            }
            trigger={['click']}
            placement='bottomLeft'
            visible={dropdownActionVisible}
            onVisibleChange={handleDropdownActionVisibility}
          >
            <Button data-cy='three-dot' className='btn z-10' icon={<RiMoreFill />} />
          </Dropdown>
        </Tooltip>
      </Actions>

      {blocked && <div className='overlay' />}
    </Wrapper>
  );
}

export default memo(ContactDetailHeader);
