/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { produce } from 'immer';

// Create the initial value
export const LIMIT = 50;
const initialParams = {
  first: LIMIT,
  search: null,
  filter: null,
  after: null,
};

// Create the Query var and initialize it with the initial value
export const agentsQueryParamVars: any = makeVar(initialParams);

// expose the operations done on the state
export function useAgentsVars(qVars: any) {
  const setSearch = (text: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft.search = text;
    });
    qVars(updatedQvars);
  };

  return {
    setSearch,
  };
}
