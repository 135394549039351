import { useState, memo, useRef, forwardRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoize from 'memoize-one';

import * as S from './Styles';
import { Row } from './Row';

const createItemData = memoize((items, onRowSelect, onRowClick, checkedItems) => ({
  items,
  onRowSelect,
  onRowClick,
  checkedItems,
}));

function ContactList({ items, onRowSelect, onRowClick, checkedItems }: any) {
  const outerListRef: any = useRef(undefined);
  const innerListRef: any = useRef(undefined);
  const [scrollOffset, setScrollOffset] = useState(0);
  const listHeight = 600;
  const [pageUp, pageDown, home, end] = [33, 34, 36, 35];
  const pageOffset = listHeight * 5;
  const maxHeight =
    (innerListRef.current && innerListRef.current.style.height.replace('px', '')) || listHeight;
  const minHeight = 0.1;
  const keys = {
    [pageUp]: Math.max(minHeight, scrollOffset - pageOffset),
    [pageDown]: Math.min(scrollOffset + pageOffset, maxHeight),
    [end]: maxHeight,
    [home]: minHeight,
  };

  const handleKeyDown = ({ keyCode }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    keys[keyCode] && setScrollOffset(keys[keyCode]);
  };

  const innerElementType = forwardRef(({ style, ...rest }: any, ref: any) => {
    return (
      <div
        ref={ref}
        style={{
          ...style,
        }}
        {...rest}
      />
    );
  });
  const itemData = createItemData(items, onRowSelect, onRowClick, checkedItems);

  return (
    <S.ContactContainer tabIndex={0} onKeyDown={handleKeyDown} role='list'>
      <AutoSizer>
        {({ height, width }: any) => {
          return (
            <List
              outerRef={outerListRef}
              innerRef={innerListRef}
              itemData={itemData}
              height={height}
              width={width}
              initialScrollOffset={20}
              innerElementType={innerElementType}
              itemCount={items?.length}
              itemSize={index => {
                const contacts = itemData.items;
                return contacts[index].type === 'group' ? 38 : 50;
              }}
              useIsScrolling
            >
              {Row}
            </List>
          );
        }}
      </AutoSizer>
    </S.ContactContainer>
  );
}

export default memo(ContactList);
