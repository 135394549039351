export const restrictedScreenData: Record<string, any> = {
  isUserSuspended: {
    title: 'Account Suspended',
    description: 'Your KrispCall Account is currently suspended due to lack of credits.',
    icon: 'wks_acc_suspend',
    type: 'danger',
  },
  isDeletedWorkspace: {
    title: 'Workspace Deleted',
    description: 'Your current workspace is deleted. Contact support for more information',
    icon: 'wks_delete',
    type: 'danger',
  },
  isUnderReview: {
    title: 'Under Review',
    description: 'Your KrispCall account is under review. Please contact support.',
    icon: 'wks_review',
    type: 'info',
  },
  isSubscriptionCancelled: {
    title: 'Subscription Cancelled',
    description: 'Your KrispCall subscription has been cancelled.',
    icon: 'wks_sub_cancel',
    type: 'info',
  },
  isSubscriptionExpired: {
    title: 'Subscription Expired',
    description: 'You have reached the end of your KrispCall subscription.',
    icon: 'wks_sub_expire',
    type: 'danger',
  },
};
