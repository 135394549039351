import { useState } from 'react';
import { getBase64 } from 'components/utils/helpers';

export default function useProfileUpload() {
  const [imageFile, setImageFile] = useState<string | Blob | ArrayBuffer | null>();
  const [imageFileObj, setImageFileObj] = useState<string | Blob | ArrayBuffer | null>();

  const uploadProps = {
    beforeUpload: (fileObj: Blob) => {
      getBase64(fileObj, imageUrl => setImageFile(imageUrl));
      setImageFileObj(fileObj);
      return false;
    },
    multiple: false,
    accept: 'image/*',
  };
  return { imageFile, imageFileObj, uploadProps, setImageFile };
}
