import { Dispatch, SetStateAction, useContext } from 'react';
import { CallWidgetNavContext } from '../context/CallWidgetNavContext';

interface ICallWidgetNavContext {
  selectedNavScreen: string;
  setSelectedNavScreen: Dispatch<SetStateAction<string>>;
  handleRedirectToMainScreen: () => void;
}

export default function useCallWidgetNavContext() {
  const widgetNavState = useContext<ICallWidgetNavContext>(CallWidgetNavContext);
  if (!widgetNavState) {
    throw new Error('useCallWidgetNavContext must be used within a CallWidgetProvider');
  }
  return widgetNavState;
}
