export enum PaymentMethodEnum {
  Card = 'card',
  Paypal = 'paypal',
}

export enum PaymentGatewayTypesEnum {
  Card = 'card',
  Paypal = 'paypal_express_checkout',
}

export const payPalCustomStyles = {
  shape: 'rect',
  color: 'blue',
  layout: 'horizontal',
  label: 'checkout',
};

// Digital wallets will be added here in future implemtation
export const digitalWallets = ['Paypal'];
