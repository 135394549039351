import { ToastMessage } from 'components/atoms';
import { useCampaignMutation } from 'components/pages/sales-dialer/campaigns/hooks/useCampaignMutation';
import { useTwilioContext } from 'lib/twilio';
import { CAMPAIGN_STATUS_RESPONSE } from 'lib/twilio/constants';
import { cache } from 'services/apollo';
import { activeCallCampaignStatus } from 'services/apollo/reactiveVars';

const useUpdateCampaignCache = () => {
  const {
    state: { connection },
  } = useTwilioContext();

  const { onCampaignEnded, onCampaignInprogress, onCampaignPaused } = useCampaignMutation();
  const campaignId = connection?.customParameters?.get('campaignId') || '';
  const { PAUSED, INPROGRESS, ENDED } = CAMPAIGN_STATUS_RESPONSE;

  const updateCampaignAnalyticsCache = () => {
    cache.modify({
      fields: {
        campaignAnalytics: () => {},
      },
    });
  };

  const updateCampaignStatsCache = () => {
    cache.modify({
      fields: {
        campaignStats: () => {},
      },
    });
  };

  const updateCampaignDetailCache = () => {
    cache.modify({
      fields: {
        getCampaignsList: () => {},
        campaignDetails: () => {},
      },
    });
  };

  const disconnectCampaignCall = () => {
    if (campaignId) connection?.disconnect?.();
  };

  const updateCampaignStatus = (campaignStatus: string) => {
    if (!campaignStatus) return;
    switch (campaignStatus) {
      case INPROGRESS:
        updateCampaignAnalyticsCache();
        onCampaignInprogress();
        break;
      case PAUSED:
        updateCampaignDetailCache();
        setTimeout(() => {
          updateCampaignAnalyticsCache();
          updateCampaignStatsCache();
        }, 2000);
        onCampaignPaused();
        disconnectCampaignCall();
        break;
      case ENDED:
        updateCampaignDetailCache();
        setTimeout(() => {
          updateCampaignAnalyticsCache();
          updateCampaignStatsCache();
        }, 2000);
        onCampaignEnded();
        disconnectCampaignCall();
        break;
      default:
        activeCallCampaignStatus(campaignStatus);
        break;
    }
  };

  return {
    updateCampaignStatus,
  };
};

export default useUpdateCampaignCache;
