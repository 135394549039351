import { useContext } from 'react';
import { ChannelsContext } from '../context/ChannelsContext';

interface IChannelsContext {
  channels?: any;
  loading?: boolean;
  called?: boolean;
}

export default function useChannelsContext() {
  const widgetState = useContext<IChannelsContext>(ChannelsContext);
  if (!widgetState) {
    throw new Error('useChannelsContext must be used within a ChannelsProvider');
  }
  return widgetState;
}
