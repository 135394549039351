/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useContext, useState } from 'react';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import { ActionsModal } from 'components/organisms';
import { useWorkspacesQuery } from 'components/organisms/workspace';
import { Tooltip } from 'components/atoms';
import { BadgedAvatar } from 'components/atoms/avatar';
import { AuthContext } from 'contexts/auth/AuthContext';

import * as S from './Styles';
import useLogout from './useLogout';

export default function Logout() {
  const { isSubscriptionExpired } = useContext(AuthContext);
  const { t } = useTranslation();
  const { logout } = useLogout();
  const {
    profileData: { firstname, lastname, profilePicture },
  } = useWorkspacesQuery();

  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false);
  const toggleLogoutModal = () => setVisibleLogoutModal(prevState => !prevState);

  const onLogout = () => {
    logout();
  };

  return (
    <Tooltip title={`${firstname} ${lastname}`} placement='right'>
      <S.LogoutPopover
        trigger='click'
        getPopupContainer={(triggerNode: any) => triggerNode} // enables overriding ant popover styles
        content={null}
        overlayClassName='logout-container'
        title={
          <div className='flex items-center'>
            <div className='flex flex-col'>
              <div className='header-text'>
                {firstname} {lastname}
              </div>
            </div>
            <Tooltip title={t('logout', 'Logout')} placement='right'>
              <RiLogoutCircleRLine
                className='logout-icon'
                role='button'
                onClick={toggleLogoutModal}
              />
            </Tooltip>

            <ActionsModal
              type='primary'
              isModalOpen={visibleLogoutModal}
              onOk={onLogout}
              onCancel={toggleLogoutModal}
              onModalCancel={toggleLogoutModal}
              info='Are you sure you want to log out from KrispCall?'
              action='Logout'
            />
          </div>
        }
        placement='rightTop'
      >
        <div className='cursor-pointer'>
          <BadgedAvatar
            isOnline
            src={profilePicture}
            badgeOffset={[-5, 30]}
            dotSize={10}
            dotBoxShadowColor='#390179'
            dotBoxShadowWidth={3}
            isSubscriptionExpired={isSubscriptionExpired}
            avatarSize={36}
            avatarStyles={{ borderRadius: 14 }}
          />
        </div>
      </S.LogoutPopover>
    </Tooltip>
  );
}
