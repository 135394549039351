import { createContext } from 'react';

import { useHandlers } from './useHandlers';

export const HandlersContext = createContext({});

export const HandlersProvider = ({ children }: any) => {
  const {
    handleRowClick,
    onRowChecked,
    onCheckAllChange,
    called,
    counts,
    loading,
    contacts,
    checkAll,
    contactList,
    checkedList,
    indeterminate,
    getSearchedQuery,
    showContactDetailDrawer,
    toggleContactDetailDrawer,
    clearSelectedContacts,
  } = useHandlers();
  return (
    <HandlersContext.Provider
      value={{
        counts,
        loading,
        called,
        contacts,
        handleRowClick,
        onRowChecked,
        onCheckAllChange,
        indeterminate,
        checkAll,
        contactList,
        checkedList,
        getSearchedQuery,
        showContactDetailDrawer,
        toggleContactDetailDrawer,
        clearSelectedContacts,
      }}
    >
      {children}
    </HandlersContext.Provider>
  );
};
