import { useContext } from 'react';
import { Channel, Client } from 'generated/graphql';
import { CallWidgetContext } from '../CallWidgetProvider';
import { Banner, BannerType } from '../types';

interface ICallWidgetContext {
  callDurationTime?: string;
  mute?: boolean;
  recording?: boolean;
  recordingTime?: string;
  loadingCallRecord?: boolean;
  autoRecordingEnabled?: boolean;
  hold?: string;
  loadingCallHold?: boolean;
  clientInfo?: Client;
  channelInfo?: Channel;
  keypadNumberInput?: string;
  note?: Record<string, any>;
  loadingSaveNote?: boolean;
  loadingCallTransfer?: boolean;
  selectedMoreOption?: string;
  loadingCampaignSaveNote?: boolean;
  campaignCallNote?: Record<string, any>;
  banner?: Banner;
  setBanner?: (args: Banner) => void;
  showAlertBanner?: (title: string, type?: BannerType) => void;
  handleCallAnswer?: (args?: any) => void;
  handleCallReject?: (args?: any) => void;
  handleToggleRecording?: (args?: any) => void;
  handleToggleHold?: (args?: any) => void;
  handleToggleMute?: (args?: any) => void;
  handleDialKeypad?: (args?: string) => void;
  handleNoteSave?: (args?: string) => void;
  handleCallTransfer?: (args?: any, data?: string) => void;
  handleSelectMoreAction?: (args?: string) => void;
  handleExecuteCampaignAction?: (id?: string, action?: string) => void;
  handleCampaignNoteSave?: (args?: any, action?: 'create' | 'edit') => void;
  handleSkipCampaignCallQueue?: (conversationId: string, campaignId: string) => void;
  handleResetCallWidget?: () => void;
  handleDropVoicemail?: (conversationId: string, campaignId: string) => void;
  handleClearCampaignNote?: () => void;
}

export default function useCallWidgetContext() {
  const widgetState = useContext<ICallWidgetContext>(CallWidgetContext);
  if (!widgetState) {
    throw new Error('useCallWidgetContext must be used within a CallWidgetProvider');
  }
  return widgetState;
}
