export default function Envelop() {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3135 11.2207C9.75596 11.2207 9.20012 11.0366 8.73512 10.6682L4.99762 7.65491C4.72846 7.43824 4.68679 7.04408 4.90262 6.77574C5.12012 6.50824 5.51346 6.46574 5.78179 6.68158L9.51596 9.69158C9.98512 10.0632 10.646 10.0632 11.1185 9.68824L14.8151 6.68324C15.0835 6.46408 15.4768 6.50574 15.6951 6.77408C15.9126 7.04158 15.8718 7.43491 15.6043 7.65324L11.901 10.6632C11.4326 11.0349 10.8726 11.2207 10.3135 11.2207Z'
        fill='white'
      />
      <mask
        id='mask0_13220_146901'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='1'
        width='19'
        height='17'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.33301 1.66675H19.2496V17.9167H1.33301V1.66675Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_13220_146901)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.19884 16.6667H14.3822C14.3838 16.6651 14.3905 16.6667 14.3955 16.6667C15.3463 16.6667 16.1897 16.3267 16.8363 15.6809C17.5872 14.9334 17.9997 13.8592 17.9997 12.6567V6.93341C17.9997 4.60591 16.478 2.91675 14.3822 2.91675H6.20051C4.10467 2.91675 2.58301 4.60591 2.58301 6.93341V12.6567C2.58301 13.8592 2.99634 14.9334 3.74634 15.6809C4.39301 16.3267 5.23717 16.6667 6.18717 16.6667H6.19884ZM6.18467 17.9167C4.89884 17.9167 3.75051 17.4501 2.86384 16.5667C1.87634 15.5817 1.33301 14.1934 1.33301 12.6567V6.93341C1.33301 3.93091 3.42551 1.66675 6.20051 1.66675H14.3822C17.1572 1.66675 19.2497 3.93091 19.2497 6.93341V12.6567C19.2497 14.1934 18.7063 15.5817 17.7188 16.5667C16.833 17.4492 15.6838 17.9167 14.3955 17.9167H14.3822H6.20051H6.18467Z'
          fill='white'
        />
      </g>
    </svg>
  );
}
