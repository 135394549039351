import { ReactElement, useEffect } from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Input } from 'components/molecules/fields';
import { Icon } from 'components/atoms';
import { RiCloseLine } from 'react-icons/ri';
import * as S from './Styles';
import { keyPadButtons, KeyPadButtonType } from '../../constants';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

interface IKeypadProps {
  handleClose: () => void;
}

export function Keypad({ handleClose }: IKeypadProps): ReactElement {
  const { handleDialKeypad, keypadNumberInput } = useCallWidgetContext();

  const phoneNumberInput = {
    number: yup.string().required('Phone is required'),
  };

  const schema = yup.object().shape({
    number: phoneNumberInput.number,
  });

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (keypadNumberInput) {
      setValue('number', keypadNumberInput);
    }
  }, [keypadNumberInput, setValue]);

  const handleDialpadClick = (number?: string) => {
    handleDialKeypad?.(number);
  };

  const handleCloseBtnClick = () => {
    handleClose();
  };

  return (
    <S.KeypadContainer>
      <div className='py-3 px-4 flex'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700'>Keypad</p>
      </div>
      <div className='border-b border-gray-50'>
        <Input name='number' id='number' control={control} errors={errors} readOnly />
      </div>
      <S.Keypad>
        {keyPadButtons.map((char: KeyPadButtonType) => (
          <Button key={char.digit} onClick={() => handleDialpadClick(char.digit)}>
            <div className='flex flex-col items-center justify-center'>
              {char.icon ? (
                <Icon name={char.icon} />
              ) : (
                <>
                  <div className='text-20 leading-6 text-gray-800 font-semibold font-manrope tracking-widest'>
                    {char.digit}
                  </div>
                  <div className='text-xs leading-3 text-gray-500 font-normal tracking-widest uppercase mt-1'>
                    {char.alphabet || char.symbol}
                  </div>
                </>
              )}
            </div>
          </Button>
        ))}
      </S.Keypad>
    </S.KeypadContainer>
  );
}
