import styled from 'styled-components';

export const ClientInfoWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
  > h4 {
    color: var(--Text-600-Headers, #1a181b);
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
  }
  > p {
    color: var(--Text-400-Paragraph, #4d4950);
    font-size: 14px;
    line-height: 20px;
  }

  .recording {
    width: fit-content;
    margin: 8px auto -8px;
    padding: 1px 8px 1px 6px;
    border-radius: var(--VerticalPadding, 8px);
    background: var(--Error-300-Default, #f33);
    color: white;
    > svg,
    > span {
      display: inline-block;
    }
    > svg {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &.incoming {
    margin-bottom: 24px;
    > p {
      font-size: 13px;
      line-height: 18px;
    }
  }
`;
