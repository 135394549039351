import { useContext } from 'react';
import { CallWidgetContext } from '../context/CallWidgetContext';
import { Banner, BannerType } from '../types';

interface ICallWidgetContext {
  channelInfo?: any;
  channels?: any;
  loadingChannels?: boolean;
  channelsQueryCalled?: boolean;
  banner: Banner;
  kycBanner?: Banner;
  selectedDrawer: null | string;
  selectedChannel: any;
  setSelectedChannel?: (args?: any) => void;
  setBanner?: (args: Banner) => void;
  showAlertBanner?: (title: string, type?: BannerType) => void;
  setSelectedDrawer: (args?: null | string) => void;
  drawerContent?: (args: string | null) => React.ReactNode;
  drawerHeight: (args: string | null) => string | number;
  showBannerLikeToast?: (text: string, type?: BannerType, callback?: () => void) => void;
  showIncomingCallBanner?: (clientName?: string, callback?: () => void) => void;
  showCallEndedBanner?: (time?: string) => void;
  showOngoingCallBanner?: (time?: string, callback?: () => void) => void;
  handleChannelSelect?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  showKycUnverifiedBanner?: () => void;
}

export default function useCallWidgetContext() {
  const widgetState = useContext<ICallWidgetContext>(CallWidgetContext);
  if (!widgetState) {
    throw new Error('useCallWidgetContext must be used within a CallWidgetProvider');
  }
  return widgetState;
}
