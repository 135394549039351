import { gql } from '@apollo/client';

export const PAGE_INFO = gql`
  fragment PageInfo on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalCount
  }
`;

export const MULTICURSOR_PAGE_INFO = gql`
  fragment MultiCursorPageInfo on MultiCursorPageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalCount
  }
`;
