import React from 'react';
import styled from 'styled-components';

export interface AuxProps {
  children: React.ReactNode;
  direction?: string;
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }: AuxProps) => direction || 'column'};
  /* align-items:center; */
  padding: 8px;
`;
const Items = ({ children, direction }: AuxProps) => {
  return <Wrapper direction={direction}>{children}</Wrapper>;
};

export default React.memo(Items);
