export default function Keyvector() {
  return (
    <svg width='67' height='67' viewBox='0 0 67 67' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4504_48310)'>
        <path
          d='M55.7473 59.1946L54.5057 57.9529C54.1642 57.612 53.6848 57.4474 53.2063 57.5082C52.1947 57.636 51.3344 56.7752 51.4617 55.7636C51.5225 55.2851 51.3579 54.8051 51.017 54.4642L49.7753 53.2226C49.3413 52.7886 48.6375 52.7886 48.2035 53.2226C47.769 53.6571 47.0651 53.6571 46.6311 53.2226L44.0528 50.6447C43.6188 50.2102 43.6188 49.5063 44.0528 49.0724C44.4868 48.6384 44.4868 47.9345 44.0528 47.5L41.6809 45.1282C41.247 44.6942 40.5431 44.6942 40.1086 45.1282C39.6746 45.5622 38.9707 45.5622 38.5367 45.1282L32.3245 38.9159L41.2142 30.0267L65.3451 54.1575C66.1471 54.9595 66.6276 56.0279 66.6951 57.1601L66.9956 62.1839C67.081 63.606 65.9043 64.7832 64.4822 64.6978L59.4579 64.3972C58.3257 64.3293 57.2578 63.8493 56.4558 63.0472L55.7473 62.3388C55.3128 61.9048 55.3128 61.2009 55.7473 60.7664C56.1813 60.3324 56.1813 59.6285 55.7473 59.1946Z'
          fill='url(#paint0_linear_4504_48310)'
        />
        <path
          d='M64.8789 62.5811C65.293 62.1665 65.293 61.4954 64.8789 61.0808L38.0302 34.2326C37.6162 33.8181 36.9445 33.8181 36.5304 34.2326C36.1164 34.6467 36.1164 35.3178 36.5304 35.7324L63.3791 62.5811C63.7932 62.9951 64.4649 62.9951 64.8789 62.5811Z'
          fill='url(#paint1_linear_4504_48310)'
        />
        <path
          d='M61.733 54.612C61.319 55.0261 60.6473 55.0261 60.2333 54.612L33.3846 27.7633C32.9705 27.3493 32.9705 26.6776 33.3846 26.2636C33.7991 25.8495 34.4703 25.8495 34.8848 26.2636L61.733 53.1123C62.1476 53.5263 62.1476 54.198 61.733 54.612Z'
          fill='url(#paint2_linear_4504_48310)'
        />
        <path
          d='M43.5752 47.0226L41.6808 45.1282C41.2468 44.6942 40.5429 44.6942 40.1084 45.1282C39.6745 45.5627 38.9706 45.5627 38.5366 45.1282L32.3244 38.9164L41.2141 30.0267L43.5752 32.3878V47.0226Z'
          fill='url(#paint3_linear_4504_48310)'
        />
        <path
          d='M14.1871 11.8876C21.2704 4.80382 32.7559 4.80382 39.8397 11.8876C46.923 18.9714 46.9235 30.4569 39.8397 37.5402C32.7559 44.624 21.2704 44.624 14.1871 37.5402C7.10331 30.4569 7.10331 18.9714 14.1871 11.8876ZM25.7784 23.4789C27.7863 21.4705 27.7878 18.2149 25.7784 16.2055C23.77 14.1971 20.5144 14.1986 18.506 16.2065C16.4981 18.2149 16.4966 21.4705 18.505 23.4789C20.5144 25.4883 23.77 25.4873 25.7784 23.4789Z'
          fill='url(#paint4_linear_4504_48310)'
        />
        <path
          d='M16.4286 14.1279C22.2743 8.28217 31.7514 8.28217 37.5971 14.1279C43.4429 19.9742 43.4429 29.4508 37.5971 35.2965C31.7514 41.1428 22.2743 41.1428 16.4286 35.2965C10.5828 29.4508 10.5828 19.9742 16.4286 14.1279ZM25.7779 23.4772C27.7857 21.4688 27.7873 18.2132 25.7779 16.2038C23.7695 14.1954 20.5138 14.1969 18.5054 16.2048C16.4976 18.2132 16.496 21.4688 18.5044 23.4772C20.5138 25.4866 23.7695 25.4856 25.7779 23.4772Z'
          fill='url(#paint5_linear_4504_48310)'
        />
        <path
          d='M16.5665 14.2671C19.6453 11.1883 24.6374 11.1863 27.7161 14.2651C30.7975 17.3464 30.7954 22.3385 27.7161 25.4173C24.6374 28.4966 19.6453 28.4986 16.5644 25.4173C13.4851 22.3385 13.4872 17.3464 16.5665 14.2671ZM25.7778 23.4784C27.7857 21.47 27.7872 18.2144 25.7778 16.205C23.7694 14.1966 20.5137 14.1981 18.5053 16.206C16.4975 18.2144 16.4959 21.47 18.5043 23.4784C20.5137 25.4878 23.7694 25.4868 25.7778 23.4784Z'
          fill='url(#paint6_radial_4504_48310)'
        />
        <path
          d='M18.3564 16.3633C17.8167 16.9491 17.4379 17.63 17.2206 18.3492L8.97291 26.5974C8.43465 21.4157 10.1225 16.0479 14.0355 12.0424L18.3564 16.3633Z'
          fill='url(#paint7_linear_4504_48310)'
        />
        <path
          d='M46.7684 34.2101L46.0835 34.8956L48.6766 37.4887L49.3616 36.8033C53.1361 33.0303 55.2247 28.0219 55.2498 22.6888C55.3039 11.578 46.0466 2.48687 34.9343 2.48687H17.0704C14.398 2.48687 12.1029 4.02038 11.08 6.49036C10.0572 8.96033 10.5955 11.6675 12.4858 13.5578L17.7687 18.8407C18.4849 19.5569 19.6457 19.5569 20.3619 18.8407C20.7202 18.4829 20.9006 18.0132 20.9006 17.5434C20.9006 17.0741 20.7202 16.6044 20.3619 16.246L19.5297 15.4128H19.5287L15.129 11.0117L15.1229 11.0055C14.2968 10.181 14.0239 8.93068 14.4844 7.85774C14.9424 6.79092 15.905 6.15554 17.0704 6.15554H34.9624C44.0183 6.15554 51.5116 13.405 51.5806 22.4598C51.6143 26.8978 49.9019 31.0756 46.7684 34.2101Z'
          fill='url(#paint8_linear_4504_48310)'
        />
        <path
          d='M-0.000267029 31.9519C-0.000267029 36.3955 1.73158 40.5739 4.87374 43.717L14.6473 53.4911C17.1316 55.9754 20.397 57.2186 23.6608 57.2186C26.9246 57.2186 30.19 55.9754 32.6743 53.4911L41.29 44.8754C40.8939 44.7225 40.4287 44.8063 40.1087 45.1258C39.892 45.3431 39.6073 45.452 39.3225 45.452C39.0378 45.452 38.7531 45.3431 38.5374 45.1258L37.1945 43.7835L30.0801 50.8964C26.5402 54.4363 20.7814 54.4363 17.242 50.8964L7.46844 41.1229C5.01892 38.6733 3.66891 35.4167 3.66891 31.9519C3.66891 28.3594 6.10209 24.4556 9.11799 21.7439C9.44974 19.7319 10.1214 17.7654 11.1356 15.9354C5.62363 18.6487 -0.000267029 25.3129 -0.000267029 31.9519Z'
          fill='url(#paint9_linear_4504_48310)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_4504_48310'
          x1='56.2777'
          y1='41.9928'
          x2='40.1222'
          y2='64.01'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC738' />
          <stop offset='1' stopColor='#E97264' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4504_48310'
          x1='51.4534'
          y1='47.6562'
          x2='49.9535'
          y2='49.1561'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC738' />
          <stop offset='1' stopColor='#E97264' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4504_48310'
          x1='47.2365'
          y1='40.7586'
          x2='48.7364'
          y2='39.2587'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC738' />
          <stop offset='1' stopColor='#E97264' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4504_48310'
          x1='37.9487'
          y1='51.5714'
          x2='37.9487'
          y2='26.5491'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E97264' stopOpacity='0' />
          <stop offset='1' stopColor='#B53759' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_4504_48310'
          x1='27.0122'
          y1='13.1796'
          x2='27.0122'
          y2='65.0623'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC738' />
          <stop offset='1' stopColor='#E97264' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_4504_48310'
          x1='27.0117'
          y1='34.8845'
          x2='27.0117'
          y2='-14.2121'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC738' />
          <stop offset='1' stopColor='#E97264' />
        </linearGradient>
        <radialGradient
          id='paint6_radial_4504_48310'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(22.1404 19.8421) rotate(135) scale(7.88487)'
        >
          <stop stopColor='#B53759' />
          <stop offset='1' stopColor='#E97264' stopOpacity='0' />
        </radialGradient>
        <linearGradient
          id='paint7_linear_4504_48310'
          x1='11.2606'
          y1='19.5631'
          x2='17.9816'
          y2='12.8421'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E97264' stopOpacity='0' />
          <stop offset='1' stopColor='#B53759' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_4504_48310'
          x1='32.9097'
          y1='13.0872'
          x2='32.9097'
          y2='39.3492'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00E7B2' />
          <stop offset='0.4139' stopColor='#1FA999' />
          <stop offset='1' stopColor='#4F4A72' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_4504_48310'
          x1='8.32767'
          y1='47.5633'
          x2='33.6618'
          y2='22.2292'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00E7B2' />
          <stop offset='0.4139' stopColor='#1FA999' />
          <stop offset='1' stopColor='#4F4A72' />
        </linearGradient>
        <clipPath id='clip0_4504_48310'>
          <rect width='67' height='67' fill='white' transform='matrix(-1 0 0 1 67 0)' />
        </clipPath>
      </defs>
    </svg>
  );
}
