import { Radio } from 'antd';
import styled from 'styled-components';

const PHONE_INPUT_HEIGHT = 40;
const PHONE_FLAG_DROPDOWN_WIDTH = 34;

export const Wrapper = styled.div`
  h3 {
    width: 100%;
    padding: 7px 16px;
    background: var(--Background-200, #faf9fb);
    color: var(--Text-500-Component, #333036);
    font-size: 13px;
    font-weight: 450;
    line-height: 138%;
  }

  .react-tel-input {
    > input,
    > .flag-dropdown {
      border: none;
      background: none;
      height: ${PHONE_INPUT_HEIGHT}px;
    }
    > input {
      padding-left: ${PHONE_FLAG_DROPDOWN_WIDTH}px;
      color: var(--Text-500-Component, #333036);
      ::placeholder {
        color: var(--Text-300-Component-2, #66616b);
      }
    }
    > .flag-dropdown {
      width: ${PHONE_FLAG_DROPDOWN_WIDTH}px;
      > .selected-flag {
        padding-left: 0;
        &,
        &:hover {
          background: none;
        }
        > .flag {
          transform: scale(1.5);
          margin-left: 4px;
        }
      }
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    width: 100%;
    label.ant-radio-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      margin: 0;
      > .ant-radio {
        order: 2;
        transform: scale(1.5);
        margin: -5px 4px 0px;
        + span {
          padding-left: 0;
        }
      }
    }

    section {
      h6 {
        color: var(--Text-500-Component, #333036);
        font-weight: 420;
      }
    }
  }
`;
