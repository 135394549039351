import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { CARDS } from 'graphql/payments';

export function useChargebeeQuery() {
  const [loadCards, { data: cardsData, loading: loadingCards }] = useLazyQuery(CARDS, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const cards = useMemo(() => cardsData?.cards?.data ?? [], [cardsData]);
  const hasCards = useMemo(() => cardsData?.cards?.data?.length > 0 ?? false, [cardsData]);
  return {
    cards,
    hasCards,
    loadingCards,
  };
}
