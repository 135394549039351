import { useContext, useRef } from 'react';
import { produce } from 'immer';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

import * as path from 'constants/routes';
import { Query } from 'generated/graphql';
import appoloCache from 'services/apollo/cache';
import { ToastMessage } from 'components/atoms';
import debounce from 'components/utils/debounce';
import useLocalStorage from 'hooks/useLocalStorage';
import { MEMBER_LOGIN, GET_USER_PROFILE } from 'graphql/user';
import { resetWSLink } from 'services/apollo';
import { loadingSwitchWorkspace } from 'services/apollo/reactiveVars';
import { AppContext } from 'contexts/app/AppContext';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
interface WorkSpaceLoginProps {
  workspaceId: string;
  memberId: string;
  authToken?: string;
}

export function useWorkspaceSelector() {
  const history = useHistory();
  const userRef: any = useRef();
  const { state, search }: any = useLocation();
  const { t } = useTranslation();
  const { setChooseFreeNumberVisible } = useContext(AppContext);

  const WAIT_IN_MS = 200;
  const [, setActiveWorkspaceId] = useLocalStorage('activeWorkspaceId', null);

  const from = `${state?.from || path.DASHBOARD}${search}`;

  const [memberLogin] = useMutation(MEMBER_LOGIN, {
    onCompleted: async response => {
      const { error, data } = response.memberLogin;
      if (error === null) {
        resetWSLink(() => {
          history.push(from);
          loadingSwitchWorkspace(false);
        });
        return;
      }
      ToastMessage({ content: error.message, type: 'danger' });
      loadingSwitchWorkspace(false);
    },
    onError: () => {
      loadingSwitchWorkspace(false);
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const selectWorkspace = debounce(async (payload: WorkSpaceLoginProps): Promise<void> => {
    const { workspaceId, memberId, authToken } = payload;
    let tokens: any = null;
    try {
      tokens = JSON.parse(ls.get('_tokens') ?? '');
    } catch (e) {
      console.error(e);
      console.log('wks not found. Reload');
      // window.location.replace(window.location.href);
      window.location.reload();
    }
    const token = authToken || tokens?.authToken;
    if (workspaceId && memberId && token) {
      loadingSwitchWorkspace(true);
      await memberLogin({
        variables: {
          data: {
            authToken: token,
            workspaceId,
            memberId,
            platform: 'WEB',
          },
        },
        update(cache, { data: { memberLogin: workspaceSwitch } }) {
          if (workspaceSwitch?.status === 200) {
            const cachedProfile: any = cache.readQuery({
              query: GET_USER_PROFILE,
            });
            const cachedProfileId: any =
              cachedProfile?.profile?.data && cache.identify(cachedProfile.profile.data);

            const updatedProfile = produce(cachedProfile, (draft: Pick<Query, 'profile'>) => {
              if (draft?.profile?.data) {
                draft.profile.data.defaultWorkspace = workspaceId;
              }
            });

            cache.writeQuery({
              query: GET_USER_PROFILE,
              data: updatedProfile,
            });

            setActiveWorkspaceId(workspaceId);

            /**
             * Though retaining a profile is not required.
             * Because we aren't evicting `ROOT_MUTATION`(always has the UserProfile)
             * we are retainnig here just for safety
             */
            appoloCache.retain(cachedProfileId);
            /**
             * It evicts all the 'ROOT_QUERY'
             * To see what are remainning after evicting
             * We can console log ` cache.extract()` after garbage collection
             */
            appoloCache.evict({ id: 'ROOT_QUERY' });
            /**
             * The gc method removes all objects from the normalized cache that are not reachable
             */
            cache.gc();
            const { accessToken, refreshToken } = workspaceSwitch.data;
            const updatedTokens = {
              ...tokens,
              accessToken,
              refreshToken,
            };
            ls.set('_tokens', JSON.stringify(updatedTokens));
            setChooseFreeNumberVisible?.(true);
          }
        },
      });
      ls.remove('auth_login'); // clear temporary login payload from localstorage
    }
  }, WAIT_IN_MS);

  return { selectWorkspace };
}
