import { createContext, useState } from 'react';
import useCallWidgetContext from '../hooks/useCallWidgetContext';

export const CallWidgetDrawerContext = createContext<any>({});

export const CallWidgetDrawerProvider = ({ children }: any) => {
  const [selectedDrawer, setSelectedDrawer] = useState<null | string>(null);
  const [saveButtonLabel, setSaveButtonLabel] = useState<string | undefined>(undefined);
  const { setBanner } = useCallWidgetContext();

  const closeDrawer = () => {
    setSelectedDrawer(null);
    setBanner?.({});
  };

  const onSaveChangeSuccess = () => {
    setSaveButtonLabel('Saved');
    setTimeout(() => {
      closeDrawer();
      setSaveButtonLabel(undefined);
    }, 2000);
  };

  return (
    <CallWidgetDrawerContext.Provider
      value={{
        selectedDrawer,
        setSelectedDrawer,
        closeDrawer,
        saveButtonLabel,
        setSaveButtonLabel,
        onSaveChangeSuccess,
      }}
    >
      {children}
    </CallWidgetDrawerContext.Provider>
  );
};
