import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    line-height: 0;
    > svg {
      margin-bottom: 8px;
    }
  }
  /* actions secondary modal */
  .body-content {
    margin-top: 40px;
  }
  .remaining-days {
    color: ${({ theme }) => theme.colors.warning.default};
    font-weight: ${({ theme }) => theme.text.weight.bold};
  }
  &.one-user-login-modal {
    svg.info-icon {
      display: inline-block;
      top: -1px;
      left: 4px;
      position: relative;
    }
    .ant-tooltip {
      &-inner {
        width: 304px;
        line-height: 150%;
      }
    }
  }
  &.restore-modal {
    .ant-modal-body {
      > svg {
        margin: 14px auto 24px;
      }
    }
    .btn {
      background: ${({ theme }) => theme.colors.info.default};
      border: 1px dashed ${({ theme }) => theme.colors.info.default};
      width: 100%;
    }
  }

  &.us-info-modal {
    .ant-modal-close-x {
      padding: 16px;
    }
    .ant-btn {
      width: 100%;
    }
  }
`;
