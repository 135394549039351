export default function SimcardIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.333 7.9395V12.9585C15.333 13.7983 14.9994 14.6037 14.4055 15.1976C13.8117 15.7914 13.0063 16.125 12.1665 16.125H5.83349C4.99368 16.125 4.18827 15.7914 3.59444 15.1976C3.0006 14.6037 2.66699 13.7983 2.66699 12.9585V5.0415C2.66699 4.20169 3.0006 3.39628 3.59444 2.80245C4.18827 2.20861 4.99368 1.875 5.83349 1.875H9.26849C9.68456 1.87463 10.0966 1.95624 10.4811 2.11517C10.8657 2.2741 11.2151 2.50723 11.5095 2.80125L14.4067 5.6985C14.7008 5.99287 14.934 6.3423 15.0929 6.72682C15.2518 7.11135 15.3334 7.52343 15.333 7.9395Z'
        stroke='currentColor'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 7.41675V13.7497M5.0415 10.5832H12.9585M11.3753 7.41675H6.62475C6.20498 7.41695 5.80247 7.58384 5.50571 7.88074C5.20896 8.17763 5.04225 8.58022 5.04225 9V12.1665C5.04216 12.3744 5.08302 12.5802 5.1625 12.7723C5.24198 12.9644 5.35853 13.139 5.50549 13.286C5.65245 13.433 5.82694 13.5496 6.01898 13.6292C6.21103 13.7088 6.41687 13.7497 6.62475 13.7497H11.3753C11.7949 13.7495 12.1973 13.5828 12.494 13.286C12.7908 12.9893 12.9576 12.5869 12.9578 12.1672V9C12.9578 8.58022 12.7911 8.17763 12.4943 7.88074C12.1975 7.58384 11.795 7.41695 11.3753 7.41675Z'
        stroke='currentColor'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
