export default function ReportsFolder() {
  return (
    <svg width='74' height='74' viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.6666 49.3334L3.34538 70.6546C2.7729 70.0815 2.31895 69.4012 2.00945 68.6526C1.69996 67.904 1.54099 67.1018 1.54163 66.2917V26.2084L24.6666 49.3334Z'
        fill='#FFCC66'
      />
      <path
        d='M70.6545 70.6546C70.0814 71.2271 69.4011 71.681 68.6525 71.9905C67.9039 72.3 67.1017 72.459 66.2916 72.4583H7.70825C6.8982 72.459 6.09595 72.3 5.34735 71.9905C4.59874 71.681 3.91845 71.2271 3.34534 70.6546L30.4633 43.5367C32.1976 41.8045 34.5487 40.8315 36.9999 40.8315C39.4512 40.8315 41.8022 41.8045 43.5366 43.5367L70.6545 70.6546Z'
        fill='#FFBA55'
      />
      <path
        d='M72.4583 26.2084C72.4583 68.0492 73.1366 68.08 70.6545 70.6546L49.3333 49.3334L72.4583 26.2084Z'
        fill='#FFCC66'
      />
      <path d='M10.7916 16.9584V35.4584L1.54163 26.2084L10.7916 16.9584Z' fill='#FFBA55' />
      <path d='M72.4583 26.2084L63.2083 35.4584V16.9584L72.4583 26.2084Z' fill='#FFBA55' />
      <path
        d='M63.2083 1.54169V35.4584L49.3333 49.3334C43.7062 43.7063 41.8408 40.8388 37 40.8388C32.1591 40.8388 30.2166 43.7834 24.6666 49.3334L10.7916 35.4584V1.54169H63.2083Z'
        fill='#E9E7EE'
      />
      <path
        d='M63.2083 1.54169V31.0954L49.3333 44.9704C45.402 41.0546 42.9045 37.7554 37 37.7554C31.0954 37.7554 28.5208 41.1317 24.6666 44.9704L15.4166 35.7204V1.54169H63.2083Z'
        fill='#FEFEFE'
      />
      <path
        d='M69.3749 69.375H12.3333C11.3391 69.3734 10.36 69.1314 9.47956 68.6697C8.59909 68.208 7.8433 67.5403 7.27661 66.7234L30.4016 43.5984C32.136 41.8662 34.487 40.8932 36.9383 40.8932C39.3895 40.8932 41.7406 41.8662 43.4749 43.5984L69.3749 69.375Z'
        fill='#FFCC66'
      />
      <path
        d='M24.6666 49.3334L7.2766 66.7234C5.68868 64.4263 6.1666 65.2588 6.1666 30.8334L24.6666 49.3334Z'
        fill='#FFDE76'
      />
      <path
        d='M72.4583 26.2084C72.4583 73.4296 73.5066 69.375 69.3749 69.375L49.3333 49.3334L72.4583 26.2084Z'
        fill='#FFDE76'
      />
      <path
        d='M47.7916 29.2916H41.6249C41.216 29.2916 40.8239 29.1292 40.5348 28.8401C40.2457 28.551 40.0833 28.1589 40.0833 27.75C40.0833 27.3411 40.2457 26.949 40.5348 26.6599C40.8239 26.3707 41.216 26.2083 41.6249 26.2083H47.7916C48.2005 26.2083 48.5926 26.3707 48.8817 26.6599C49.1708 26.949 49.3333 27.3411 49.3333 27.75C49.3333 28.1589 49.1708 28.551 48.8817 28.8401C48.5926 29.1292 48.2005 29.2916 47.7916 29.2916Z'
        fill='#C6C3D8'
      />
      <path
        d='M57.0416 23.125H41.6249C41.216 23.125 40.8239 22.9626 40.5348 22.6735C40.2457 22.3844 40.0833 21.9922 40.0833 21.5834C40.0833 21.1745 40.2457 20.7823 40.5348 20.4932C40.8239 20.2041 41.216 20.0417 41.6249 20.0417H57.0416C57.4505 20.0417 57.8426 20.2041 58.1317 20.4932C58.4208 20.7823 58.5833 21.1745 58.5833 21.5834C58.5833 21.9922 58.4208 22.3844 58.1317 22.6735C57.8426 22.9626 57.4505 23.125 57.0416 23.125Z'
        fill='#C6C3D8'
      />
      <path
        d='M57.0416 16.9583H41.6249C41.216 16.9583 40.8239 16.7959 40.5348 16.5068C40.2457 16.2177 40.0833 15.8255 40.0833 15.4167C40.0833 15.0078 40.2457 14.6157 40.5348 14.3265C40.8239 14.0374 41.216 13.875 41.6249 13.875H57.0416C57.4505 13.875 57.8426 14.0374 58.1317 14.3265C58.4208 14.6157 58.5833 15.0078 58.5833 15.4167C58.5833 15.8255 58.4208 16.2177 58.1317 16.5068C57.8426 16.7959 57.4505 16.9583 57.0416 16.9583Z'
        fill='#C6C3D8'
      />
      <path
        d='M26.2083 12.3334V21.5834L19.6716 28.12C18.3796 26.8264 17.5 25.179 17.1437 23.3857C16.7875 21.5925 16.9707 19.7339 17.6701 18.0447C18.3695 16.3554 19.5539 14.9114 21.0735 13.8949C22.5932 12.8784 24.38 12.335 26.2083 12.3334Z'
        fill='#FFCC66'
      />
      <path
        d='M35.4583 21.5834H26.2083V12.3334C28.6615 12.3334 31.0143 13.3079 32.749 15.0426C34.4837 16.7773 35.4583 19.1301 35.4583 21.5834Z'
        fill='#82BCF4'
      />
      <path
        d='M35.4583 21.5834C35.4567 23.4117 34.9133 25.1985 33.8968 26.7181C32.8803 28.2378 31.4362 29.4221 29.747 30.1216C28.0578 30.821 26.1992 31.0042 24.406 30.6479C22.6127 30.2917 20.9652 29.412 19.6716 28.12L26.2083 21.5834H35.4583Z'
        fill='#DB5669'
      />
      <path
        d='M35.4583 21.5834C35.4633 23.2818 34.9934 24.9478 34.1017 26.3934C32.3317 27.4708 30.2512 27.9228 28.1938 27.6768C26.1363 27.4308 24.2211 26.5011 22.755 25.0367L26.2083 21.5834H35.4583Z'
        fill='#F26674'
      />
      <path
        d='M26.2082 12.3334V21.5834L22.7549 25.0367C21.2906 23.5706 20.3609 21.6554 20.1149 19.5979C19.8689 17.5404 20.3208 15.46 21.3982 13.69C22.8438 12.7983 24.5098 12.3284 26.2082 12.3334Z'
        fill='#FFDE76'
      />
    </svg>
  );
}
