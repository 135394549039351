import styled from 'styled-components';

import { Icon } from 'components/atoms';

interface NumberCardProps {
  icon?: string;
  name: string;
}

const Wrapper = styled.div`
  display: flex;
  padding: 8px 12px;
  width: 100%;
  margin-bottom: 2px;
  border-radius: 8px;
  h6 {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #6e6681;
    margin-left: 8px;
    transition: 0.2s ease-out;
  }
  svg {
    color: #6e6681;
    transition: 0.2s ease-out;
  }
  &:hover {
    h6,
    svg {
      color: #3d3358;
    }
  }
  .menu--active & {
    background: #e7e6eb;
    h6,
    svg {
      color: #3d3358;
    }
  }
  .icon {
    height: 18px;
    width: 18px;
  }
  &.subscription-cancelled {
    color: ${({ theme }) => theme.colors.text.t5};
    svg,
    &:hover svg {
      color: ${({ theme }) => theme.colors.text.t5};
    }
    .menu--active & {
      color: ${({ theme }) => theme.colors.text.t0};
      svg {
        color: ${({ theme }) => theme.colors.text.t0};
      }
    }
  }
`;
export default function MenuItem({ icon, name }: NumberCardProps) {
  return (
    <Wrapper>
      <Icon name={icon} className='icon' />
      <h6>{name}</h6>
    </Wrapper>
  );
}
