/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { produce } from 'immer';

export const LIMIT = 50;
export const initialAbilities = ['Voice', 'SMS', 'MMS'];
export const initialTypes = [
  { key: 'local', label: 'Local', value: 'Local', disabled: false },
  { key: 'mobile', label: 'Mobile', value: 'Mobile', disabled: false },
  {
    key: 'toll_free',
    label: 'Toll Free',
    value: 'TollFree',
    disabled: false,
  },
];

export const matchTo: any = makeVar('any');
export const contains: any = makeVar(null);
export const selectedType: any = makeVar(null);
export const selectedCountry: any = makeVar(null);
export const selectedAbilities: any = makeVar(null);
export const selectedLocation: any = makeVar(null);
export const locations: any = makeVar([]);
export const numberTypes: any = makeVar(initialTypes);
export const abilityOptions: any = makeVar(initialAbilities);
export const selectedAddressRequirement: any = makeVar(null);

export function useNumberSearchVars(qVars?: any) {
  const setSelectedCountry = (country: any) => {
    qVars(country);
  };

  const setSelectedLocation = (location: any) => {
    qVars(location);
  };
  const setSelectedAbilities = (abilities: any) => {
    qVars(abilities);
  };
  const setSelectedType = (type: any) => {
    qVars(type);
  };
  const setSelectedAddressRequirement = (requirement: any) => {
    qVars(requirement);
  };
  const setContains = (contain: any) => {
    qVars(contain);
  };
  const setNumberTypes = (availableTypes: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft?.forEach((option: any) => {
        const item = option.value;
        // eslint-disable-next-line no-param-reassign
        option.disabled = !availableTypes.includes(item);
      });
    });
    qVars(updatedQvars);
  };
  const setLocations = (availableLocations: any) => {
    qVars(availableLocations);
  };
  const setMatchTo = (match: any) => {
    qVars(match);
  };
  const resetVars = () => {
    selectedCountry(null);
    selectedLocation(null);
    selectedAbilities([]);
    matchTo('any');
    contains(null);
    selectedType([]);
    selectedAddressRequirement(null);
    numberTypes(initialTypes);
    locations([]);
  };
  return {
    setMatchTo,
    setContains,
    setSelectedType,
    setNumberTypes,
    setLocations,
    setSelectedCountry,
    setSelectedLocation,
    setSelectedAbilities,
    setSelectedAddressRequirement,
    resetVars,
  };
}
