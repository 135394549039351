import * as yup from 'yup';

import { contactInputs } from 'schema-validation';

export const schema = yup.object().shape({
  name: contactInputs.name.nullable(),
  email: contactInputs.email,
  number: contactInputs.number,
  company: contactInputs.company,
  address: contactInputs.address,
});
