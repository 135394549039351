import { useContext } from 'react';
import SecureLS from 'secure-ls';
import { AuthContext } from 'contexts/auth/AuthContext';
import { useMutation } from '@apollo/client';
import { draftMessageVars } from 'components/organisms/conversation/client-to-agent/contact-logs/hooks/useQVars';
import { SAVE_DRAFT_MSG } from 'graphql/channel/conversation';
import { ToastMessage } from 'components/atoms';
import { useTranslation } from 'react-i18next';
// messageSource is to mark if the message is from member-to-memeber chat or client-agent chat
// 1 for client-agent chat and 2 is used for member-to-member chat
const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const useDraftMessage = () => {
  const { loggedInMemberId } = useContext(AuthContext);
  const { t } = useTranslation();
  const [saveDraftMsg] = useMutation(SAVE_DRAFT_MSG);
  const getDraftMessageList = () => {
    try {
      const data = ls.get('_dmsgs') ?? [];
      const draftMessages = typeof data === 'string' ? JSON.parse(data) : [];
      return draftMessages;
    } catch (e) {
      return [];
    }
  };

  // this method is used to create unique ID for each draft message -> messageSource+loggedInMemberId+channelId+contactId/phoneNumber
  const createMessageId = (messageSource: number, phoneNumber: string, channelId?: string) => {
    return `${messageSource}-${loggedInMemberId}-${channelId}-${phoneNumber}`;
  };
  const notifyDraftMessageUpdate = (messageId: string) => {
    draftMessageVars({ messageId });
  };
  const saveDraftMessage = async (
    messageId: string,
    contactNumber: string,
    draftMessage: string,
    channelId?: string,
  ) => {
    const draftMessages = getDraftMessageList();
    const messageIndex = draftMessages.findIndex(
      (eachMessage: any) => eachMessage.messageId === messageId,
    );
    const eachDraftMessage: {
      messageId: string;
      draftMessage: string;
      id?: string;
    } = {
      messageId,
      draftMessage,
    };
    const requestParam = {
      action: 'CREATE',
      channel: channelId,
      contact: contactNumber,
      content: draftMessage,
    };
    if (messageIndex === -1) {
      const draftMsg = await saveDraftMsg({
        variables: {
          data: { ...requestParam },
        },
      });

      const { data, error } = draftMsg?.data?.agentDrafts;
      if (error === null) {
        draftMessages.push({ ...eachDraftMessage, id: data.id });
      } else {
        draftMessages.push({ ...eachDraftMessage, id: null });
        ToastMessage({
          content: t('error.draftMsgFailedToSave', 'Failed to save draft.'),
          type: 'danger',
        });
      }
    } else {
      const draftMsg = await saveDraftMsg({
        variables: {
          data: {
            ...requestParam,
            action: draftMessages[messageIndex].id ? 'UPDATE' : 'CREATE',
            id: draftMessages[messageIndex].id,
          },
        },
      });

      const { data, error } = draftMsg?.data?.agentDrafts;
      if (error === null) {
        draftMessages[messageIndex] = { ...eachDraftMessage, id: data.id };
      } else {
        draftMessages[messageIndex] = {
          ...eachDraftMessage,
          id: draftMessages[messageIndex].id,
        };
        ToastMessage({
          content: t('error.draftMsgFailedToSave', 'Failed to save draft.'),
          type: 'danger',
        });
      }
    }
    ls.set('_dmsgs', JSON.stringify(draftMessages));
    notifyDraftMessageUpdate(messageId);
  };

  const removeDraftMessage = async (messageId: string) => {
    const draftMessages = getDraftMessageList();
    const messageIndex = draftMessages.findIndex(
      (eachMessage: any) => eachMessage.messageId === messageId,
    );

    if (messageIndex !== -1) {
      const draftMsg = await saveDraftMsg({
        variables: {
          data: {
            action: 'DELETE',
            id: draftMessages[messageIndex].id,
          },
        },
      });
      const { data, error } = draftMsg?.data?.agentDrafts;
      if (error === null) {
        draftMessages.splice(messageIndex, 1);
        ls.set('_dmsgs', JSON.stringify(draftMessages));
        notifyDraftMessageUpdate(messageId);
      } else {
        ToastMessage({
          content: t('error.draftMsgFailedToDelete', 'Failed to delete draft message.'),
          type: 'danger',
        });
      }
    }
  };

  const getDraftMessage = (messageId: string) => {
    const draftMessages = getDraftMessageList();
    const messageIndex = draftMessages.findIndex(
      (eachMessage: any) => eachMessage.messageId === messageId,
    );
    if (messageIndex === -1) {
      return '';
    }
    return draftMessages[messageIndex].draftMessage;
  };

  return {
    createMessageId,
    saveDraftMessage,
    getDraftMessage,
    removeDraftMessage,
  };
};

export default useDraftMessage;
