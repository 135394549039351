import { DefaultTheme } from 'styled-components';
import text from './text';
import colors from './colors';
import typography from './typography';

const defaultTheme: DefaultTheme = {
  name: 'defaultTheme',
  text,
  colors,
  typography,
};
export default defaultTheme;
