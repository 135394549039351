import styled from 'styled-components';

export const PaymentMethodCardWrapper = styled.div`
  .ant-radio-wrapper {
    padding: 12px 24px 12px 12px;
    border-radius: 6px;
    display: flex;
    width: 100%;
    margin: 0;
    border: 1px solid #e7e6eb;
    align-items: center;
    background: #ffffff;
    &-checked {
      border: 1px solid #390179;
      background: #f5f2f8;
    }
    .ant-radio {
      height: fit-content;
    }
    & > span:last-child {
      display: flex;
      width: 100%;
      padding: 0;
      & > div:last-child {
        margin-left: auto;
      }
    }
  }
`;
