import { Drawer } from 'antd';
import styled from 'styled-components';

export const DrawerContainer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    left: 0;
    bottom: -1px;
    width: 320px;
    .ant-drawer-content {
      background: #ffffff;
      border: 1px solid #e7e6eb;
      border-radius: 12px;
      .ant-drawer-header {
        padding: 12px 24px;
        .ant-drawer-header-title {
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 150%;
        }
      }
    }
  }
`;
