import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  position: relative;
  .ant-avatar {
    background: #f5f2f8;
    border-radius: 20px;
  }

  .default-avatar {
    border: 1px dashed #9e99ab;
    border-radius: 20px;
    padding: 5px;
  }

  .ant-dropdown-trigger {
    > svg {
      background: #ffffff;
      box-shadow: 0px 1px 2px rgb(140 140 140 / 25%);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      color: #9e99ab;
    }
  }
`;
export const OptionsWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  padding: 20px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  svg {
    height: 34px;
    width: 34px;
  }
`;

export const DropdownIcon = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgb(140 140 140 / 25%);
  border-radius: 50%;
  width: 24px;
  height: 24px;

  svg {
    color: #9e99ab;
    height: 18px;
    width: 18px;
  }
`;

// reused in MemberEditForm : share-access
export const MemberOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  .ant-select-item-option-content > & {
    line-height: 40px;
  }
  .ant-avatar {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }
  .member-name {
    color: #564d6d;
    line-height: 150%;
  }
`;

// reused in MemberEditForm : share-access, crm config connected numbers
export const SelectFieldWrapper = styled.div`
  .ant-select {
    min-height: 44px;
    .ant-select-selector {
      padding: 7px 8px !important;
    }
    .ant-select-selection-overflow {
      row-gap: 8px;
      column-gap: 10px;
      .ant-select-selection-item {
        background: #ece6f2;
        border-radius: 6px;
        height: 20px;
        padding: 0 6px 0 0;
        align-items: center;
        margin: 0;
        .ant-select-selection-item-content {
          margin-right: 6px;
        }
      }
      .ant-select-selection-overflow-item-suffix {
        height: 20px;
        input {
          height: 20px;
        }
        .ant-select-selection-search {
          margin: 0;
        }
      }
    }
    .ant-select-selection-item-remove {
      vertical-align: middle;
      line-height: 0px;
      svg {
        width: 12px;
        height: 12px;
        color: #564d6d;
      }
    }
  }
  .ant-select-dropdown {
    .ant-select-item-empty {
      min-height: fit-content;
    }
    .ant-select-item.ant-select-item-option {
      min-height: 38px;
    }
  }
`;
