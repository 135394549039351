import { Icon } from 'components/atoms';
import { useState } from 'react';

import Form from './Form';
import * as S from './Styles';

interface AddMemberModalProps {
  isModalOpen: boolean;
  onModalCancel: () => void;
  width?: number | string;
  openInvitationSentModal: (email: string) => void;
}

const AddMemberModal = (props: AddMemberModalProps) => {
  const { isModalOpen, onModalCancel, width, openInvitationSentModal } = props;
  const [processing3DSPayment, setProcessing3DSPayment] = useState(false);

  return (
    <S.StyledModal
      data-cy='tab-pane-add-button-modal'
      className='modal'
      width={width ?? 442}
      centered
      destroyOnClose
      visible={isModalOpen}
      footer={null}
      onCancel={onModalCancel}
      closable={false}
      maskClosable={!processing3DSPayment}
    >
      <Icon name='member-invite' />
      <h5 data-cy='tab-pane-add-button-modal-title'>Invite Members</h5>
      <p data-cy='tab-pane-add-button-modal-desc'>
        Invite your member together for better communication and collaboration
      </p>
      <Form
        data-cy='tab-pane-add-button-modal-form'
        onCancel={onModalCancel}
        openInvitationSentModal={openInvitationSentModal}
        processing={processing3DSPayment}
        setProcessing={setProcessing3DSPayment}
      />
    </S.StyledModal>
  );
};

export default AddMemberModal;
