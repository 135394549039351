import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';
import { MEMBER_NODE, TEAM_MEMBER_NODE } from '../fragments';

export const MEMBER = gql`
  query memberDetail($id: ShortId!) {
    memberDetail(id: $id) {
      status
      data {
        ...MemberFields
      }
      error {
        ...Error
      }
    }
  }
  ${MEMBER_NODE}
  ${ERROR_FRAGMENT}
`;

export const TEAM_MEMBERS_LIST = gql`
  query teamMembersList($id: ShortId!, $params: ConnectionInput) {
    teamMembersList(id: $id, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...TeamMemberFields
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
  ${TEAM_MEMBER_NODE}
`;
