export default function DeafenedIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.0779 16.2514C25.2417 16.4154 25.3338 16.6378 25.3338 16.8697C25.3338 17.1017 25.2417 17.324 25.0779 17.4881C24.997 17.5699 24.9008 17.6347 24.7946 17.6788C24.6885 17.7229 24.5745 17.7453 24.4596 17.7447C24.3445 17.7459 24.2303 17.7239 24.1242 17.6798C24.0179 17.6357 23.9216 17.5704 23.8412 17.4881L21.6012 15.2481L19.3496 17.4881C19.27 17.5715 19.1739 17.6375 19.0675 17.6817C18.9609 17.7258 18.8464 17.7473 18.7312 17.7447C18.6162 17.7453 18.5023 17.7229 18.3962 17.6788C18.29 17.6347 18.1936 17.5699 18.1129 17.4881C17.949 17.324 17.8569 17.1017 17.8569 16.8697C17.8569 16.6378 17.949 16.4154 18.1129 16.2514L20.3529 14.0114L18.1129 11.7714C17.949 11.6074 17.8569 11.385 17.8569 11.1531C17.8569 10.9212 17.949 10.6988 18.1129 10.5348C18.1933 10.4522 18.2894 10.3866 18.3957 10.3418C18.5019 10.297 18.616 10.2739 18.7312 10.2739C18.8465 10.2739 18.9605 10.297 19.0668 10.3418C19.1729 10.3866 19.2691 10.4522 19.3496 10.5348L21.6012 12.7747L23.8412 10.5348C23.9216 10.4522 24.0178 10.3866 24.1239 10.3418C24.2302 10.297 24.3443 10.2739 24.4596 10.2739C24.5748 10.2739 24.6889 10.297 24.7951 10.3418C24.9013 10.3866 24.9974 10.4522 25.0779 10.5348C25.2417 10.6988 25.3338 10.9212 25.3338 11.1531C25.3338 11.385 25.2417 11.6074 25.0779 11.7714L22.8379 14.0114L25.0779 16.2514Z'
        fill='currentColor'
      />
      <path
        d='M15.9894 8.00311V19.9965C15.9916 20.446 15.8876 20.8897 15.686 21.2915C15.4905 21.6952 15.1929 22.0412 14.8227 22.2948C14.4465 22.5477 14.0045 22.6856 13.551 22.6915C13.1057 22.6915 12.6707 22.5573 12.3027 22.3065L7.63605 19.2381C7.54071 19.1806 7.43233 19.1485 7.3211 19.1448H5.11607C4.77723 19.1419 4.44307 19.0653 4.13683 18.9202C3.83058 18.7752 3.55958 18.5652 3.34272 18.3048C2.90316 17.7823 2.66334 17.1209 2.66604 16.4381V11.5614C2.66408 10.8823 2.90397 10.2247 3.34272 9.70643C3.55958 9.44604 3.83058 9.236 4.13683 9.09094C4.44307 8.94588 4.77723 8.86927 5.11607 8.86643H7.3211C7.4343 8.86407 7.54408 8.82746 7.63605 8.76143L12.3027 5.69308C12.677 5.44638 13.1145 5.31274 13.5627 5.30811C14.0122 5.31619 14.4497 5.45394 14.8227 5.70476C15.1929 5.95838 15.4905 6.30421 15.686 6.70808C15.8864 7.11034 15.9902 7.55374 15.9894 8.00311Z'
        fill='currentColor'
      />
    </svg>
  );
}
