export default function BelgiumFlagVector() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4504_82110)'>
        <path
          d='M49.6948 48.1463V52.665H13.3372V48.1463C13.3372 47.8282 11.3185 47.5135 11.3467 47.2028C11.823 41.9565 16.2328 37.847 21.6034 37.847H41.6876C47.0567 37.847 51.4678 41.9565 51.9443 47.2028C51.9724 47.5137 49.6948 47.8284 49.6948 48.1463Z'
          fill='#DA4A54'
        />
        <path
          d='M50.204 52.6658V56.2675C50.204 56.5856 51.9725 56.9001 51.9441 57.2108C51.4676 62.4572 47.0567 66.5666 41.6874 66.5666H29.0237L19.1424 74.6639V66.2695C14.9376 65.238 11.7475 61.628 11.3465 57.2105C11.3183 56.8998 13.337 56.5853 13.337 56.2673V52.6656H50.204V52.6658Z'
          fill='#FDC951'
        />
        <path
          d='M51.9869 48.1464V56.2669C51.9869 56.5853 51.9731 56.8992 51.9426 57.21H11.3471C11.318 56.8992 11.3042 56.5853 11.3042 56.2669V48.1464C11.3042 47.8279 11.318 47.5141 11.3486 47.2031H51.9426C51.9733 47.5141 51.9869 47.828 51.9869 48.1464Z'
          fill='#F7F9F9'
        />
        <path
          d='M76.0005 24.6222V32.7427C76.0005 37.5831 72.6623 41.6409 68.162 42.7434V51.1393L63.0674 46.9651L57.6394 30.6175L63.0674 14.3231H65.7012C71.3899 14.3231 76.0005 18.9337 76.0005 24.6222Z'
          fill='#DA4A54'
        />
        <path
          d='M48.2508 14.3221L52.5473 28.1723L48.2508 43.041H45.6171C39.93 43.041 35.3177 38.4304 35.3177 32.7417V24.6213C35.3177 18.9325 39.93 14.3219 45.6171 14.3219H48.2508V14.3221Z'
          fill='#5986CB'
        />
        <path d='M63.0674 14.3231V46.9651L58.2791 43.042H48.2508V14.3231H63.0674Z' fill='#F7F9F9' />
        <path
          d='M38.3898 11.6364V16.1551H2.03225V11.6364C2.03225 11.3183 0.013502 11.0036 0.0417051 10.6929C0.518041 5.44656 4.92782 1.33707 10.2984 1.33707H30.3826C35.7518 1.33707 40.1629 5.44656 40.6394 10.6929C40.6674 11.0036 38.3898 11.3183 38.3898 11.6364Z'
          fill='#5E6B75'
        />
        <path
          d='M38.8999 16.1545V19.7562C38.8999 20.0743 40.6684 20.3889 40.64 20.6995C40.1635 25.9459 35.7526 30.0554 30.3833 30.0554H17.7196L7.83844 38.1527V29.7582C3.6338 28.7269 0.443577 25.1169 0.0424986 20.6994C0.0142955 20.3887 2.03305 20.0742 2.03305 19.7562V16.1545H38.8999Z'
          fill='#FFD064'
        />
        <path
          d='M40.6827 11.6365V19.7569C40.6827 20.0753 40.6689 20.3893 40.6383 20.7001H0.0428984C0.0138047 20.3893 0 20.0752 0 19.7568V11.6365C0 11.318 0.0138047 11.0042 0.0443828 10.6932H40.6383C40.6691 11.004 40.6827 11.318 40.6827 11.6365Z'
          fill='#DA4A54'
        />
      </g>
      <defs>
        <clipPath id='clip0_4504_82110'>
          <rect width='76' height='76' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
