import styled from 'styled-components';
import { Modal, Button, Dropdown } from 'antd';

export const StyledModal = styled(Modal)`
  position:relative
  color: ${({ theme }) => theme.colors.text.t0};
  .ant-modal-content {
    background: #170046;
    border: none;
    border: 1px solid #613494;
    border-radius: 16px;
    padding: 0 !important;
  }
  .dialer-container {
    display: flex;
    flex-direction: column;

  }
  .title {
    font-size: ${({ theme }) => theme.text.size.heading.h5};
    font-weight: ${({ theme }) => theme.text.weight.bold};
    line-height: 24px;
    color: ${({ theme }) => theme.colors.text.t8};
  }

  .dropdown {
    .ant-dropdown-menu {
      border-radius: 2px;
    }
  }
  .call-button {
    background: linear-gradient(180deg, #4C9610 0%, #318F22 100%);
    box-shadow: 0px 14px 55px rgb(33 34 43 / 25%);
    border:none;
    border-radius: 8px;
    min-width: 143px;
    color:#fff;
    svg{
      margin-right:10px;
    }
    &:hover{
      background: linear-gradient(180deg, #458C0A 0%, #29841A 98.63%);
    }
  }
`;
export const DialerSettingsBtn = styled(Button)`
  display: block;
  background: ${({ theme }) => theme.colors.primary.p1};
  border: none;
  border-radius: 5px;
  padding: 3px;
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.colors.primary.p1};
  }
  width: 26px;
  height: 26px;
  svg {
    color: ${({ theme }) => theme.colors.text.t5};
  }
`;

export const NumberAutoCompleteOption = styled.div`
  img.flag {
    width: 20px;
    min-width: 20px;
    height: 14px;
    border-radius: 2px;
  }
  img[alt='NP'] {
    object-fit: contain;
  }

  .avatar {
    border-radius: 8px;
    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }
`;

export const DeviceDropdown = styled(Dropdown)`
  margin-top: -28px;
  .ant-dropdown {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    min-width: 230px !important;
    padding: 24px 16px 16px;
  }
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.medium};
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text.t6};
  padding-bottom: 14px;
  margin-bottom: -2px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1};
  > svg {
    position: relative;
    top: 2px;
    font-size: 16px;
    margin: 0 6px 0 8px;
    color: ${({ theme }) => theme.colors.text.t6};
  }
`;

export const CustomButton = styled(Button)`
  padding: 15px 28px;
`;
