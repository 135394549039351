/* eslint-disable no-continue */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react';

interface CacheBusterProps {
  children: any;
  currentVersion: string;
  isEnabled: boolean;
  isVerboseMode: boolean;
  loadingComponent: any;
}
/**
 * CacheBuster
 *
 * @param {CacheBusterProps} props
 * @returns {JSX.Element}
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 *
 * @typedef {Object} CacheBusterProps
 * @property {any} children - The content to be rendered when the app is up to date.
 * @property {string} currentVersion - The version number of the current app.
 * This will be compared to the version number in the app's meta.json file.
 * @property {boolean} isEnabled - A flag indicating whether the component is enabled or not.
 * If set to false, the component will not perform any cache checks or refreshes.
 * @property {boolean} isVerboseMode - A flag indicating whether the component should log out messages to the console or not.
 * @property {any} loadingComponent - The component to be rendered while the component is checking the cache status.
 */

function CacheBuster({
  children = null,
  currentVersion,
  isEnabled = false,
  isVerboseMode = false,
  loadingComponent = null,
}: CacheBusterProps) {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false,
  });
  const log = (message?: any, isError?: any) => {
    isVerboseMode && (isError ? console.error(message) : console.log(message));
  };

  const isThereNewVersion = (metaVersion: any, currentVer: any) => {
    if (!currentVer) {
      return false;
    }
    const metaVersions = metaVersion.split(/\./g);
    const currentVersions = currentVer.split(/\./g);

    while (metaVersions.length || currentVersions.length) {
      const a = Number(metaVersions.shift());

      const b = Number(currentVersions.shift());
      if (a === b) {
        continue;
      }
      return a > b || isNaN(b);
    }
    return false;
  };

  const checkCacheStatus = async () => {
    try {
      const res = await fetch('/meta.json');
      const { version: metaVersion } = await res.json();

      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);
      if (shouldForceRefresh) {
        log(`There is a new version (v${metaVersion}). Should force refresh.!`);
        setCacheStatus({
          loading: false,
          isLatestVersion: false,
        });
      } else {
        log('There is no new version. No cache refresh needed.');
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
      }
    } catch (error) {
      log('An error occurred while checking cache status.', true);
      log(error, true);

      // Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
      !isVerboseMode &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
    }
  };

  useEffect(() => {
    isEnabled ? checkCacheStatus() : log('React Cache Buster is disabled.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshCacheAndReload = async () => {
    try {
      if (caches) {
        const cacheNames = await caches.keys();
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
        log('The cache has been deleted.');
        // window.location.replace(window.location.href);
        window.location.reload();
      }
    } catch (error) {
      log('An error occurred while deleting the cache.', true);
      log(error, true);
    }
  };

  if (!isEnabled) {
    return children;
  }
  if (cacheStatus.loading) {
    return loadingComponent;
  }

  if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
    refreshCacheAndReload();
    return null;
  }
  return children;
}

export { CacheBuster };
