import { Icon } from 'components/atoms';

type Data = {
  title: string;
  description: string;
  icon: string;
  type: string;
};

interface IRestrictedScreensProps {
  data: Data;
}

const RestrictedScreens = ({ data }: IRestrictedScreensProps) => {
  const { title, description, icon, type } = data || {};
  return (
    <>
      <div className='rounded-lg h-full flex flex-col items-center justify-center'>
        <Icon name={icon} className='mt-0.5' />
        <h6
          className={`mt-7 text-lg leading-5 ${
            type === 'info' ? 'text-info' : 'text-error'
          } font-medium`}
        >
          {title}
        </h6>
        <p className='mt-2 mb-11 text-sm text-gray max-w-260 text-center'>{description}</p>
      </div>
    </>
  );
};

export default RestrictedScreens;
