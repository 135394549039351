import { useState, useEffect } from 'react';

export default function useMediaDevices() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [loadingMediaDevices, setLoadingMediaDevices] = useState<boolean>(true);
  useEffect(() => {
    const getMediaDevices = async () => {
      try {
        await navigator?.mediaDevices?.getUserMedia({ audio: true, video: false });
        const mediaDevices = await navigator?.mediaDevices?.enumerateDevices();
        navigator?.mediaDevices
          ?.enumerateDevices()
          .then(() => {
            setDevices(mediaDevices);
            setLoadingMediaDevices(false);
          })
          .catch(() => setLoadingMediaDevices(false));
      } catch {
        setLoadingMediaDevices(false);
      }
    };
    navigator?.mediaDevices?.addEventListener('devicechange', getMediaDevices);
    getMediaDevices();
    return () => {
      navigator?.mediaDevices?.removeEventListener('devicechange', getMediaDevices);
    };
  }, []);

  return {
    audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
    audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
    hasAudioInputDevices: devices.filter(device => device.kind === 'audioinput')?.length > 0,
    loadingMediaDevices,
  };
}
