import Highlighter from 'react-highlight-words';
import useScreenType from 'hooks/useScreenType';
import styled from 'styled-components';

interface ICustomHighlighter {
  textToHighlight: string;
  searchWords: string[];
  className?: string;
}

const Wrapper = styled.div<any>`
  .highlight {
    padding: 0;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.info.default};
  }
  .contact-log-sms {
    display: inline-block;
    max-width: 100%;
  }
`;

export const CustomHighlighter = ({
  textToHighlight,
  searchWords,
  className,
}: ICustomHighlighter) => {
  const { isBigScreen } = useScreenType();
  return (
    <Wrapper>
      <Highlighter
        highlightClassName='highlight'
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={textToHighlight}
        className={className}
      />
    </Wrapper>
  );
};
