import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PENDING_NUMBER_NODE, NUMBER_NODE, CHECKOUT_NUMBER } from '../fragments';

export const ADD_NUMBER_TO_CART = gql`
  mutation addNumber($data: Number!) {
    addNumber(data: $data) {
      status
      data {
        ...PendingNumberResponse
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PENDING_NUMBER_NODE}
`;
export const REMOVE_NUMBER_FROM_CART = gql`
  mutation archiveNumber($data: RemoveNumberInputData!) {
    archiveNumber(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const PROCESS_NUMBER = gql`
  mutation processNumber($data: ProcessNumbersInputData!) {
    processNumber(data: $data) {
      status
      data {
        successful {
          ...CheckoutNumberFields
        }
        unsuccessful {
          ...CheckoutNumberFields
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CHECKOUT_NUMBER}
`;
export const REQUEST_NON_VOIP_NUMBER = gql`
  mutation requestNonVoipNumbers($data: RequestNonVoipNumbersInput!) {
    requestNonVoipNumbers(data: $data) {
      status
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
