export default function Teamvector() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' viewBox='0 0 36 36'>
      <g clipPath='url(#clip0_22:11673-t)'>
        <path
          fill='#785550'
          d='M30.857 16.572c1.263 0 2.286-.896 2.286-2 0-1.105-1.023-2-2.286-2-1.262 0-2.285.895-2.285 2 0 1.104 1.023 2 2.285 2z'
        />
        <path
          fill='#694B4B'
          d='M30.286 14.572c0-.84.591-1.555 1.428-1.852a2.558 2.558 0 00-.857-.149c-1.262 0-2.286.896-2.286 2 0 1.105 1.024 2 2.286 2 .304 0 .592-.054.857-.148-.837-.297-1.428-1.013-1.428-1.851z'
        />
        <path
          fill='#E6AF78'
          d='M35.052 25.947l-1.849-.924A1.143 1.143 0 0132.571 24v-1.714h-3.428V24c0 .433-.245.829-.632 1.022l-1.85.925c-.58.29-.947.884-.947 1.533v3.377c0 .316.256.572.572.572h9.142a.571.571 0 00.572-.572V27.48c0-.649-.367-1.242-.948-1.533z'
        />
        <path
          fill='#D29B6E'
          d='M30.857 24.572c.616 0 1.205-.127 1.745-.353-.014-.072-.03-.144-.03-.219v-1.714h-3.43V24c0 .076-.016.148-.03.22a4.54 4.54 0 001.745.352z'
        />
        <path
          fill='#D5DCED'
          d='M35.052 25.947l-1.317-.659a3.423 3.423 0 01-5.756 0l-1.317.659c-.58.29-.948.884-.948 1.533v3.377c0 .316.256.572.572.572h9.142a.572.572 0 00.572-.572V27.48c0-.65-.367-1.243-.948-1.533z'
        />
        <path fill='#5A4146' d='M30.857 22.285a4 4 0 100-8 4 4 0 000 8z' />
        <path
          fill='#694B4B'
          d='M34.82 17.731a4.009 4.009 0 00-3.075-3.347 4.118 4.118 0 00-.863-.098c-1.916-.012-2.556 2.634-.833 3.473.092.046.151.066.151.066l1.99 3.604s.531.036 1.066.052a3.991 3.991 0 001.564-3.75z'
        />
        <path
          fill='#F0C087'
          d='M30.857 23.429a3.43 3.43 0 01-3.4-2.985.576.576 0 01.305-.594 4.772 4.772 0 001.012-.683 3.3 3.3 0 00.782-1.029.565.565 0 01.644-.313c2.012.513 3.382 1.56 3.879 1.987.133.115.21.284.196.46a3.429 3.429 0 01-3.418 3.157z'
        />
        <path
          fill='#E6AF78'
          d='M34.079 19.812c-.497-.427-1.867-1.474-3.88-1.987a.566.566 0 00-.643.313 3.269 3.269 0 01-.523.762v.002c-.078.088-.16.177-.26.265-.365.326-.747.55-1.01.683a.576.576 0 00-.306.594 3.424 3.424 0 002.88 2.936c-.684-.465-1.194-1.096-1.194-2.237v-.815c.13-.096.26-.191.392-.308.317-.284.595-.612.817-.965a8.629 8.629 0 012.978 1.62c.108.096.377.344.692.639.135-.326.224-.675.253-1.042a.553.553 0 00-.196-.46z'
        />
        <path
          fill='#C7CFE2'
          d='M35.652 26.45c.22.292.348.65.348 1.03v3.377a.571.571 0 01-.571.572h-1.715v-2.88c0-.347.158-.675.43-.892l1.508-1.207z'
        />
        <path
          fill='#5A4146'
          d='M1.714 16.572v.4c0 .492.08.98.235 1.446l.908 2.725h1.715v-6.286H3.429c-.947 0-1.715.768-1.715 1.715z'
        />
        <path
          fill='#694B4B'
          d='M9.538 17.759l-.967 3.384-4.571-4A1.143 1.143 0 012.857 16c0-.947.768-1.714 1.714-1.714h4c.632 0 1.143.512 1.143 1.143v1.074c0 .424-.059.847-.176 1.256z'
        />
        <path fill='#E6AF78' d='M7.429 22.857H4v2.53H7.43v-2.53z' />
        <path fill='#D29B6E' d='M4 23.907a4.543 4.543 0 003.429 0v-1.05H4v1.05z' />
        <path
          fill='#D5DCED'
          d='M10.185 25.031l-2.757-.787-1.714 1.137L4 24.243l-2.757.788A1.714 1.714 0 000 26.679v4.178c0 .316.256.572.571.572h10.286a.571.571 0 00.572-.572V26.68c0-.765-.508-1.438-1.244-1.648z'
        />
        <path fill='#AFB9D2' d='M6.286 31.428H5.143l.285-6.042H6l.286 6.043z' />
        <path
          fill='#F0C087'
          d='M5.714 23.429A3.429 3.429 0 012.286 20v-.67c0-.303.12-.593.334-.808l1.037-1.036c.219-.22.519-.345.83-.336 1.826.058 3.443.45 4.326 1.313.217.21.33.506.33.809V20a3.429 3.429 0 01-3.429 3.429z'
        />
        <path
          fill='#E6AF78'
          d='M4 19.472c0-.659.553-1.183 1.211-1.14 1.154.073 2.866.266 3.916.77a1.105 1.105 0 00-.314-.64c-.883-.863-2.5-1.254-4.327-1.312a1.13 1.13 0 00-.83.336L2.62 18.523a1.143 1.143 0 00-.334.808V20a3.428 3.428 0 002.478 3.291A3.4 3.4 0 014 21.143v-1.67z'
        />
        <path
          fill='#C7CFE2'
          d='M2.286 28.382c0-.455-.18-.89-.502-1.212L.313 25.699c-.197.28-.313.62-.313.98v4.178c0 .316.256.572.571.572h1.715v-3.047z'
        />
        <path
          fill='#959CB5'
          d='M6 26.53h-.572a.286.286 0 01-.285-.287v-.857h1.143v.857A.286.286 0 016 26.53z'
        />
        <path
          fill='#C7CFE2'
          d='M5.714 25.38l-.968.74a.343.343 0 01-.51-.107l-.868-1.587.35-.533a.229.229 0 01.346-.042l1.65 1.53zM5.714 25.38l.969.74a.343.343 0 00.509-.107l.868-1.587-.35-.533a.229.229 0 00-.346-.042l-1.65 1.53z'
        />
        <path
          fill='#5A4146'
          d='M18.286 24.572c3.738 0 7.095-1.53 8.194-2.086a.57.57 0 00.306-.604c-.177-1.06-.655-4.234-1.072-9.882-.339-4.594-3.325-7.428-7.428-7.428S11.196 7.406 10.857 12c-.417 5.648-.894 8.822-1.071 9.882a.57.57 0 00.306.604c1.099.557 4.455 2.086 8.194 2.086z'
        />
        <path
          fill='#694B4B'
          d='M26.48 22.486a.57.57 0 00.306-.605c-.177-1.06-.655-4.233-1.072-9.881-.339-4.595-3.326-7.429-7.428-7.429-.288 0-.57.014-.846.042-3.194.318-3.673 4.828-.604 5.765l.077.022 1.142 14.166.004.003c3.829.064 7.3-1.515 8.42-2.083z'
        />
        <path
          fill='#E6AF78'
          d='M8.572 30.857v-3.082c0-.905.534-1.725 1.362-2.09l4.814-2.127c.414-.183.68-.593.68-1.046V20h5.715v2.512c0 .453.267.863.681 1.046l4.814 2.126A2.286 2.286 0 0128 27.775v3.082a.571.571 0 01-.571.572H9.143a.571.571 0 01-.571-.572z'
        />
        <path
          fill='#00C3FF'
          d='M26.638 25.684l-3.55-1.568a5.14 5.14 0 01-4.802 3.312 5.14 5.14 0 01-4.802-3.312l-3.55 1.568a2.286 2.286 0 00-1.363 2.09v3.083c0 .316.256.571.572.571h18.285a.571.571 0 00.572-.571v-3.082c0-.905-.534-1.725-1.362-2.091z'
        />
        <path
          fill='#D29B6E'
          d='M15.428 21.656c.871.4 1.837.63 2.858.63 1.02 0 1.986-.23 2.857-.63V20h-5.715v1.656z'
        />
        <path
          fill='#F0C087'
          d='M23.424 13.143c-.918-.798-2.978-2.209-6.511-2.743a1.143 1.143 0 00-1.2.61c-.363.741-1.134 1.926-2.665 2.97a1.12 1.12 0 00-.477.928v.325c0 3.043 2.297 5.7 5.334 5.898A5.715 5.715 0 0024 15.429v-1.003c0-.491-.206-.96-.576-1.283z'
        />
        <path
          fill='#E6AF78'
          d='M23.744 13.537a1.637 1.637 0 00-.32-.394c-.918-.798-2.978-2.209-6.512-2.743a1.143 1.143 0 00-1.2.61 6.614 6.614 0 01-.344.61c-.439.696-1.154 1.563-2.311 2.354a1.13 1.13 0 00-.486.934v.325c0 2.937 2.142 5.508 5.021 5.861A5.69 5.69 0 0116 17.143V13.55a1.14 1.14 0 011.026-1.138c1.374-.138 3.959-.15 6.718 1.125z'
        />
        <path
          fill='#00AAF0'
          d='M9.426 25.997a2.283 2.283 0 00-.854 1.778v3.082c0 .316.256.571.571.571h3.429v-1.575c0-.455-.181-.891-.502-1.212l-2.644-2.644zM27.145 25.997c.531.428.855 1.076.855 1.778v3.082a.571.571 0 01-.571.571H24v-1.575c0-.455.18-.891.502-1.212l2.643-2.644z'
        />
      </g>
      <defs>
        <clipPath id='clip0_22:11673-t'>
          <path fill='#fff' d='M0 0H36V36H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
