import styled from 'styled-components';

export const TagFieldStyles = styled.div`
  position: relative;
  /* margin: 1px 0 !important; */
  p.tag-select-placeholder {
    cursor: text;
    color: ${({ theme }) => theme.colors.text.t3};
    margin: 0;
    height: 44px !important;
    padding-top: 9px !important;
  }
  .tag-select {
    &__menu {
      margin: 4px 0 0 0;
      box-shadow: none;
      &-list {
        border-radius: 8px;
        border: 1px solid #e7e6eb;
        padding: 10px 0;
        box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
        max-height: 190px;
        font-size: ${({ theme }) => theme.text.size.body.text.caption};
        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
      }
    }
    &__option {
      cursor: pointer;
      padding-left: 44px;
      font-family: 'Manrope';
      height: 34px;
      :before {
        position: absolute;
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 5px;
        left: 16px;
      }
      &--is-focused {
        background: #f3f2f4;
      }
    }
    &__control {
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 5px;
      &:hover {
        background: #f5f2f8;
        cursor: text;
      }
      .tag-select__multi-value {
        padding: 5px 8px;
        &__label {
          font-size: 12.5px;
        }

        &__remove {
          display: none;
        }
      }
      &--is-focused {
        box-shadow: 0 0 0 1px #d7cce4;
        border-radius: 4px 4px 0 0;
        &:hover {
          background: #fff;
          cursor: text;
        }
        .tag-select__multi-value {
          padding: 6px 8px 6px 10px;
          &__remove {
            display: flex;
          }
        }
      }

      &--is-disabled {
        background: #f5f2f8 !important;
        box-shadow: 0 0 0 0.7px #d7cce4;
      }
    }
    &__value-container {
      padding: 1px 8px;
    }
    &__placeholder {
      color: #857f96;
    }
    &__indicator {
      display: none;
      &-separator {
        background: none;
      }
    }
    &__input {
      > input {
        font-weight: ${({ theme }) => theme.text.weight.semiBold};
        font-family: ${({ theme }) => theme.text.font.body};
        color: ${({ theme }) => theme.colors.text.t2};
        line-height: 16px;
      }
    }
  }
`;

export const ColorPicker = styled.div`
  background: #ffffff;
  border: 1px solid #e7e6eb;
  border-radius: 8px;
  overflow: hidden;
  filter: drop-shadow(0px 4px 16px rgba(35, 30, 36, 0.1));
  min-width: 322px;
  position: absolute;
  margin-top: 4px;
  z-index: 99;

  .ant-menu {
    padding: 7px 0;
    &-item:hover {
      background: #f5f2f8;
    }
  }
  .color-menu-item {
    padding: 7px 20px !important;
    margin: 0 !important;
    height: fit-content;
    color: #564d6d;
    &.default {
      > div > div {
        background: #f3f2f4;
        border: 1px solid #e7e6eb;
      }
    }
  }
  &.widget-color-picker {
    width: 272px;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
    .ant-menu {
      max-height: 174px;
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.white};
      overflow: auto;
      &-item {
        font-size: ${({ theme }) => theme.text.size.body.text.caption};
        line-height: 16px;
        color: ${({ theme }) => theme.colors.text.t6};
      }
      ::-webkit-scrollbar {
        width: 24px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 14px;
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.text.t2}; // fills width 4px
        border: solid 10px transparent; // spaces around scrollbar
        background: none;
      }
    }
  }
`;

export const ColorBlock = styled.div<any>`
  background: ${props => props.background};
  width: 16px;
  height: 16px;
  border-radius: 5px;
  margin-right: 8px;
`;
