export default function LogoutSvg() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_434_2215)'>
        <path
          d='M14.6667 20C12.4613 20 10.6667 18.2053 10.6667 16C10.6667 13.7947 12.4613 12 14.6667 12H21.3333V3.66667C21.3333 1.644 19.6893 0 17.6667 0H3.66667C1.644 0 0 1.644 0 3.66667V28.3333C0 30.356 1.644 32 3.66667 32H17.6667C19.6893 32 21.3333 30.356 21.3333 28.3333V20H14.6667Z'
          fill='#76A1F8'
        />
        <path
          d='M24.8233 22.5652C24.326 22.3586 24.0007 21.8719 24.0007 21.3332V17.3332H14.6673C13.9313 17.3332 13.334 16.7359 13.334 15.9999C13.334 15.2639 13.9313 14.6666 14.6673 14.6666H24.0007V10.6666C24.0007 10.1279 24.326 9.64123 24.8233 9.43457C25.322 9.2279 25.8953 9.34257 26.2767 9.7239L31.61 15.0572C32.1313 15.5786 32.1313 16.4212 31.61 16.9426L26.2767 22.2759C25.8953 22.6572 25.322 22.7719 24.8233 22.5652Z'
          fill='#857F96'
        />
      </g>
      <defs>
        <clipPath id='clip0_434_2215'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
