import { ReactNode } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import * as S from './Styles';

export interface IWidgetBanner {
  title?: string;
  type?: 'info' | 'error' | 'warning' | 'success';
  icon?: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
  onClose?: (e: any) => void;
}

export const WidgetBanner = ({
  title,
  type,
  icon,
  showIcon = false,
  closable = false,
  onClose,
}: IWidgetBanner) => (
  <S.CustomAlert
    message={title}
    type={type}
    icon={icon}
    closeIcon={<RiCloseLine />}
    showIcon={showIcon}
    closable={!!closable}
    onClose={onClose}
  />
);
