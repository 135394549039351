import { memo } from 'react';

import { TwilioProvider } from 'lib/twilio';
import { AuthProvider } from 'contexts/auth/AuthContext';
import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import { NavHelperProvider } from 'components/organisms/navigation/NavHelperContext';

function DialerLayout({ children }: any) {
  // just to prompt media device selection on app load
  const { hasAudioInputDevices } = useMediaDevices();

  return (
    <AuthProvider>
      <TwilioProvider>
        {/* ADDED NAVHELPERPROVIDER TO FETCH PLAN, PERMISSION AND WORKSPACE DATA */}
        <NavHelperProvider>{children}</NavHelperProvider>
      </TwilioProvider>
    </AuthProvider>
  );
}

export default memo(DialerLayout);
