import styled from 'styled-components';

export const ChannelInfoWrapper = styled.div`
  .avatar-container {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    .avatar {
      width: 100%;
      height: 10px;
    }
    .ant-avatar-square {
      border-radius: 1px;
    }
    img[alt='NP'] {
      object-fit: contain;
    }
  }
`;
