import { useTranslation } from 'react-i18next';

import ActionModal from 'components/organisms/modals/actions/ActionsModal';
import { useEffect, useState } from 'react';
import { useTwilioContext } from 'lib/twilio';
import { Icon, ToastMessage } from 'components/atoms';
import { useCampaignHandlers } from './hooks/useCampaignHandlers';
import { CAMPAIGN_ACTIONS } from './constants';

interface ReattemptCampaignProps {
  visible: boolean;
  toggle: () => void;
}

const ReattemptCampaign = ({ visible, toggle }: ReattemptCampaignProps) => {
  const { t } = useTranslation();
  const {
    state: { prevConnection, connection },
  } = useTwilioContext();
  const [seconds, setSeconds] = useState(45);
  const { handleCampaignRun } = useCampaignHandlers();

  const handleReattemptCampaign = () => {
    const campaignId = prevConnection?.customParameters?.get('campaignId');
    toggle();
    if (campaignId) {
      handleCampaignRun(campaignId, CAMPAIGN_ACTIONS.reattempt);
      return;
    }
    ToastMessage({
      content: t('error.unspecific', 'Something went wrong.'),
      type: 'danger',
    });
  };

  const handleCancel = () => {
    const campaignId = prevConnection?.customParameters?.get('campaignId');
    if (campaignId) {
      handleCampaignRun(campaignId, CAMPAIGN_ACTIONS.end);
    }
    toggle();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds) {
        setSeconds((second: any) => second - 1);
        return;
      }
      handleReattemptCampaign();
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <>
      <ActionModal
        type='primary'
        onOk={handleReattemptCampaign}
        isModalOpen={visible}
        onModalCancel={handleCancel}
        onCancel={handleCancel}
        maskClosable={false}
        width={349}
        icon={<Icon name='incoming-call2' />}
        title={t('Re-attempt', 'Re-attempt')}
        info={t(
          'modal.info.accountLimitedVerifyIdentity',
          'Campaign completed. Re-attempts for contacts which did not connect will start after a few time. Do you want to continue?',
        )}
        loading={false}
        btnText={` Continue ( ${seconds} )`}
        className='code-verification-modal'
        cancelBtnText={t('modal.cancelBtnText.cancel', 'Cancel')}
      />
    </>
  );
};

export default ReattemptCampaign;
