import { memo } from 'react';

import Suspense from 'services/Suspense';
import { MainLayout } from 'components/atoms';
import SupportSidebar from 'components/organisms/navigation/support/SupportSidebar';
import SupportSideNav from 'components/organisms/navigation/support/SupportSideNav';
import { AuthProvider } from 'contexts/auth/AuthContext';

function SupportLayout({ children }: any) {
  return (
    <AuthProvider>
      <MainLayout hasWorkspace='true'>
        <div className='sideNavbar-container flex position-fixed'>
          <SupportSideNav />
        </div>
        <div className='flex grid-col-2' style={{ gridColumn: 2 }}>
          <Suspense>
            <SupportSidebar />
          </Suspense>

          <Suspense>{children}</Suspense>
        </div>
      </MainLayout>
    </AuthProvider>
  );
}

export default memo(SupportLayout);
