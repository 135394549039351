export default function Bag() {
  return (
    <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.49999 5.88745H13.5C16.3333 5.88745 16.6167 7.21245 16.8083 8.82912L17.5583 15.0791C17.8 17.1291 17.1667 18.8041 14.25 18.8041H6.75832C3.83332 18.8041 3.19999 17.1291 3.44999 15.0791L4.2 8.82912C4.38333 7.21245 4.66666 5.88745 7.49999 5.88745Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.16666 7.13745V4.22078C7.16666 2.97078 8 2.13745 9.25 2.13745H11.75C13 2.13745 13.8333 2.97078 13.8333 4.22078V7.13745'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5083 14.6624H7.16666'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
