import styled from 'styled-components';
import { Collapse } from 'antd';

export const AddContactFormWrapper = styled.div`
  .checkbox {
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    font-family: ${({ theme }) => theme.text.font.heading};
    color: ${({ theme }) => theme.colors.text.t6};
    line-height: 14px;
    align-items: center;
  }

  .ant-checkbox-wrapper {
    &::after {
      height: 20px;
    }
    .ant-checkbox-inner::after {
      left: 30%;
    }
  }
  .ant-input,
  .ant-select {
    margin: 0;
    padding: 0px 16px;
    font-weight: ${({ theme }) => theme.text.weight.regular};
    font-family: ${({ theme }) => theme.text.font.heading};
    color: ${({ theme }) => theme.colors.text.t6};
  }
  .ant-select {
    padding: 0px;
  }
  .ant-input.name-input {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none;
    border: none;
    height: 28px !important;
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #3d3358;
    font-family: Manrope;
    &::placeholder {
      font-size: 20px;
      line-height: 28px;
      color: #857f96;
    }
  }
  .add-new-contact-phone {
    .form-control {
      color: #3d3358;
    }
  }
`;

export const CustomCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > {
    .ant-collapse-header {
      min-height: 20px;
      .ant-collapse-arrow {
        right: 0;
        display: inline-flex;
        width: fit-content;
        height: 20px;
        color: #1a63f4 !important;
        svg {
          color: #1a63f4 !important;
          width: 20px;
          height: 20px;
        }
      }
    }
    .ant-collapse-content.ant-collapse-content-active {
      margin-top: 32px;
    }
  }
`;
