import { useWorkspaceCredit } from 'components/organisms/workspace/useWorkspaceCredit';
import useChannelsContext from 'components/pages/channels/hooks/useChannelsContext';
import useSalesDialerContext from '../../hooks/useCallWidgetContext';
import { CAMPAIGN_ACTIONS } from '../constants';
import { useCampaignMutation } from './useCampaignMutation';

export const useCampaignHandlers = () => {
  const { runCampaign } = useCampaignMutation({});
  const { sufficientCredit } = useWorkspaceCredit();

  const { toggleInsufficientCredit } = useSalesDialerContext();
  const { channels } = useChannelsContext();

  const handleCampaignRun = async (id: string, action: string, conversationId?: string) => {
    const { end, pause } = CAMPAIGN_ACTIONS;

    const isCreditSufficient = await sufficientCredit(false);
    if (![end, pause].includes(action) && !isCreditSufficient) {
      toggleInsufficientCredit?.();
      return false;
    }
    const payload = { id, action, conversationId };
    runCampaign(payload);
    return true;
  };

  const checkDialingNumberAvailability = (dialingChannelId?: string) => {
    if (!dialingChannelId) return false;
    const dialingNumber = channels?.find((channel: any) => channel.id === dialingChannelId);
    return !!dialingNumber;
  };

  return {
    handleCampaignRun,
    checkDialingNumberAvailability,
  };
};
