import styled from 'styled-components';
import { Button } from 'components/atoms';

export const ConfirmModalFields = styled.div`
  display: grid;
  justify-content: center;
  svg.contact-import-icon {
    margin: 0 auto;
  }
  h6 {
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #3d3358;
    margin-top: 12px;
    span {
      display: inline-block;
      background: #ffffff;
      border: 1px solid #b7b3c1;
      border-radius: 8px;
      font-weight: 600;
      font-size: 13px;
      line-height: 14px;
      padding: 2px 5px;
      letter-spacing: 0.02em;
      color: #6e6681;
      position: relative;
      top: -1px;
      left: 2px;
    }
  }
`;

export const CancelButton = styled(Button)`
  background: #f3f2f4;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #6e6681;
  min-width: 130px;
  &.ant-btn-link:hover {
    background: #f3f2f4;
  }
`;

export const SuccessButton = styled(Button)`
  background: #4c9610;
  min-width: 167px;
  border-radius: 8px;
  &.ant-btn-primary:hover {
    background: #39810b;
  }
`;
