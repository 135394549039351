import { gql } from '@apollo/client';

export const NUMBERS_UNSEEN_COUNT = gql`
  subscription updateCounversationCount($channels: [ShortId!]!) {
    updateCounversationCount(channels: $channels) {
      event
      message {
        channel
        count
      }
    }
  }
`;
