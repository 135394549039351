import { useState } from 'react';
import { DraggableProps } from 'react-draggable';

export const useCallWidgetDragger = () => {
  const bounds = 'parent'; // enables dragging within parent element bounds
  const draggableHandleClassName = 'select-dialing-number'; // element to drag
  const cancelDraggableClassName = 'select-channel-arrow';

  const [draggableState, setDraggableState] = useState({
    activeDrags: 0,
  });
  const onStart = () => {
    setDraggableState((prev: any) => ({ activeDrags: prev.activeDrags + 1 }));
  };
  const onStop = () => {
    setDraggableState((prev: any) => ({ activeDrags: prev.activeDrags - 1 }));
  };
  const dragHandlers = { onStart, onStop };
  const draggableProps: Partial<DraggableProps> = {
    bounds,
    handle: `.${draggableHandleClassName}`,
    cancel: `.${cancelDraggableClassName}, .ant-select-dropdown`,

    ...dragHandlers,
  };
  return {
    draggableProps,
    draggableHandleClassName,
    cancelDraggableClassName,
  };
};
