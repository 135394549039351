import { Alert } from 'antd';
import styled from 'styled-components';

export const CustomAlert = styled(Alert)`
  text-align: left;
  &.ant-alert {
    position: absolute;
    width: 100%;
    height: 22px;
    padding: 3px 16px 3px 20px;
    border-radius: 0px;
    pointer-events: all;
    border: none;
    color: #fff;
    z-index: 9;
    .ant-alert-icon {
      margin-right: 8px;
      font-size: 16px;
      min-width: 16px;
    }
    .ant-alert-message {
      width: fit-content;
      color: #fff;
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 246px;
    }
    .ant-alert-close-icon {
      /* margin-left: 12px;
      margin-right: -5px; */
      svg {
        font-size: 16x;
        fill: #fff;
      }
    }

    &-error {
      background: ${({ theme }) => theme.colors.danger.default};
      &-icon,
      &-action svg,
      .ant-alert-message,
      .ant-alert-close-icon svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &-info {
      background: ${({ theme }) => theme.colors.info.default};
      color: ${({ theme }) => theme.colors.white};
      &-icon,
      svg,
      .ant-alert-message,
      .ant-alert-close-icon svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    &-warning {
      background: ${({ theme }) => theme.colors.warning.default};
      &-icon,
      svg,
      .ant-alert-message,
      .ant-alert-close-icon svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
