import { useRef, useState } from 'react';
import { useChargebeeMutation } from 'lib/chargebee/hooks/useChargebeeMutation';
import {
  PaymentGatewayTypesEnum,
  PaymentMethodEnum,
  payPalCustomStyles,
} from 'constants/paymentGateway';
import { mapPaymentIntentData } from 'lib/chargebee/utils';
import { ToastMessage } from 'components/atoms';
import { useTranslation } from 'react-i18next';
import { logInfoDatdog } from 'services/datadog/helper';

export function usePaymentMethodHandler(props: any) {
  const [loadingPaypalBtn, setLoadingPaypalBtn] = useState(false);
  const { t } = useTranslation();
  const cardRef: any = useRef();
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [processing, setProcessing] = useState(false);

  const {
    addNewCard,
    addingCard,
    createPaymentIntent,
    paymentIntentLoading,
  } = useChargebeeMutation({
    onError: () => {
      setProcessing(false);
    },
    callback: () => {
      props?.onModalCancel();
      setProcessing(false);
    },
  });

  // ADD CARD
  const addPaymentCards = (intent: any) => {
    const {
      payment_method_type: paymentGatewayType,
      active_payment_attempt: activePaymentAttempt,
    } = intent || {};
    const args = {
      gatewayAccountId: intent?.gateway_account_id,
      gwToken:
        paymentGatewayType !== PaymentGatewayTypesEnum.Paypal
          ? activePaymentAttempt?.id_at_gateway
          : undefined,
      paymentIntentId: intent?.id,
    };
    addNewCard(args);
  };

  // HANDLE CONFIRM PAYMANT WITH CARD
  const handlePaymentWithCard = (paymentIntentData: any, additionalData: any) => {
    cardRef.current
      .authorizeWith3ds(paymentIntentData, additionalData)
      .then((intent: any) => {
        addPaymentCards(intent);
      })
      .catch((error: any) => {
        setProcessing(false);
        if (error) {
          const { displayMessage, message } = error || {};
          const errMsg =
            displayMessage || message || t('error.unspecific', 'Something went wrong.');
          setErrorMsg(errMsg);
          const additionalInfo = {
            paymentIntentData,
            context: 'Add Card',
            errText: 'Add Card Failed',
          };
          logInfoDatdog(error, additionalInfo);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  // HANDLE CONFIRM PAYMANT WITH PAYPAL
  const handlePaymentWithPaypal = (paymentIntentData: any) => {
    setProcessing(false);
    const cbInstance = window?.Chargebee?.getInstance?.();
    cbInstance.load('paypal').then((paypalHandler: any) => {
      setLoadingPaypalBtn(false);
      paypalHandler.setPaymentIntent(paymentIntentData);
      paypalHandler
        .mountPaymentButton('#paypal-button', {
          style: payPalCustomStyles,
        })
        .then(() => {
          paypalHandler
            .handlePayment()
            .then(async (intent: any) => {
              // handle payment success
              setProcessing(true);
              addPaymentCards(intent);
            })
            .catch((error: any) => {
              setProcessing(false);
              const additionalInfo = {
                paymentIntentData,
                context: 'Add Card',
                errText: 'Add Card Failed',
              };
              logInfoDatdog(error, additionalInfo);
              ToastMessage({
                content: 'Unable to add Paypal',
                type: 'danger',
              });
            })
            .finally(() => {
              setProcessing(false);
              setLoadingPaypalBtn(false);
            });
        });
    });
  };

  // FETCH PAYMENT INTENT
  const fetchPaymentIntent = async (paymentAmount: any, paymentMethod: string) => {
    try {
      setProcessing(true);
      const isPaypalPaymentSelected = paymentMethod === PaymentMethodEnum.Paypal;
      const intents = await createPaymentIntent({
        variables: {
          data: {
            amount: paymentAmount, // amount in cents
            paymentMethodType: isPaypalPaymentSelected ? 'paypal_express_checkout' : 'card',
          },
        },
      });
      const paymentIntentResponse = intents?.data?.createPaymentIntent;
      const paymentIntent = paymentIntentResponse?.data || {};
      if (paymentIntentResponse?.error) {
        setProcessing(false);
        return;
      }
      // eslint-disable-next-line consistent-return
      return mapPaymentIntentData(paymentIntent);
    } catch (err: any) {
      setProcessing(false);
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    }
  };

  // MOUNT PAYPAL BUTTON
  const loadPaypalButton = async (paymentAmount: any) => {
    // removing perviously loaded paypal instance
    document.querySelectorAll('[id^=zoid-paypal-button-]').forEach(data => data.remove());
    setLoadingPaypalBtn(true);
    const paymentIntentData = await fetchPaymentIntent(paymentAmount, PaymentMethodEnum.Paypal);
    handlePaymentWithPaypal(paymentIntentData);
  };

  return {
    addCardLoading: addingCard,
    cardRef,
    errorMsg,
    processing,
    loadingPaypalBtn,
    paymentIntentLoading,
    setProcessing,
    setErrorMsg,
    loadPaypalButton,
    fetchPaymentIntent,
    handlePaymentWithCard,
  };
}
