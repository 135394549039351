export default function Barrier() {
  return (
    <svg width='70' height='71' viewBox='0 0 70 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.314 12.7385C16.1742 12.7271 15.0704 13.1367 14.2141 13.8889C13.3578 14.6412 12.8092 15.683 12.6737 16.8147C12.5382 17.9464 12.8252 19.0883 13.4796 20.0215C14.1341 20.9546 15.11 21.6133 16.2202 21.8713V25.5573C16.2202 25.7009 16.2485 25.8431 16.3035 25.9758C16.3584 26.1085 16.439 26.2291 16.5406 26.3307C16.6421 26.4322 16.7627 26.5128 16.8954 26.5678C17.0281 26.6227 17.1703 26.651 17.314 26.651C17.4576 26.651 17.5998 26.6227 17.7325 26.5678C17.8652 26.5128 17.9858 26.4322 18.0874 26.3307C18.1889 26.2291 18.2695 26.1085 18.3245 25.9758C18.3794 25.8431 18.4077 25.7009 18.4077 25.5573V21.8713C19.5179 21.6133 20.4938 20.9546 21.1483 20.0215C21.8028 19.0883 22.0898 17.9464 21.9542 16.8147C21.8187 15.683 21.2702 14.6412 20.4139 13.8889C19.5575 13.1367 18.4537 12.7271 17.314 12.7385V12.7385Z'
        fill='#F8B64C'
      />
      <path
        d='M52.6858 12.7385C51.5461 12.7271 50.4422 13.1367 49.5859 13.8889C48.7296 14.6412 48.1811 15.683 48.0455 16.8147C47.91 17.9464 48.197 19.0883 48.8515 20.0215C49.5059 20.9546 50.4818 21.6133 51.592 21.8713V25.5573C51.592 25.8474 51.7073 26.1256 51.9124 26.3307C52.1175 26.5358 52.3957 26.651 52.6858 26.651C52.9759 26.651 53.2541 26.5358 53.4592 26.3307C53.6643 26.1256 53.7795 25.8474 53.7795 25.5573V21.8713C54.8898 21.6133 55.8657 20.9546 56.5201 20.0215C57.1746 19.0883 57.4616 17.9464 57.3261 16.8147C57.1905 15.683 56.642 14.6412 55.7857 13.8889C54.9294 13.1367 53.8255 12.7271 52.6858 12.7385V12.7385Z'
        fill='#F8B64C'
      />
      <path
        d='M20.2342 24.4648H14.3936C14.1035 24.4648 13.8253 24.5801 13.6202 24.7852C13.415 24.9903 13.2998 25.2685 13.2998 25.5586V29.6273C13.2998 29.9174 13.415 30.1956 13.6202 30.4007C13.8253 30.6059 14.1035 30.7211 14.3936 30.7211V38.3773C14.1035 38.3773 13.8253 38.4926 13.6202 38.6977C13.415 38.9028 13.2998 39.181 13.2998 39.4711V47.2039C13.2998 47.494 13.415 47.7722 13.6202 47.9773C13.8253 48.1824 14.1035 48.2977 14.3936 48.2977V50.7367C14.1035 50.7367 13.8253 50.852 13.6202 51.0571C13.415 51.2622 13.2998 51.5404 13.2998 51.8305V66.0602C13.2998 66.3502 13.415 66.6284 13.6202 66.8336C13.8253 67.0387 14.1035 67.1539 14.3936 67.1539H20.2342C20.5243 67.1539 20.8025 67.0387 21.0076 66.8336C21.2127 66.6284 21.3279 66.3502 21.3279 66.0602V51.7758C21.3279 51.4857 21.2127 51.2075 21.0076 51.0024C20.8025 50.7973 20.5243 50.682 20.2342 50.682V48.243C20.5243 48.243 20.8025 48.1277 21.0076 47.9226C21.2127 47.7175 21.3279 47.4393 21.3279 47.1492V39.4164C21.3279 39.1263 21.2127 38.8481 21.0076 38.643C20.8025 38.4379 20.5243 38.3227 20.2342 38.3227V30.7211C20.5243 30.7211 20.8025 30.6059 21.0076 30.4007C21.2127 30.1956 21.3279 29.9174 21.3279 29.6273V25.5586C21.3279 25.2685 21.2127 24.9903 21.0076 24.7852C20.8025 24.5801 20.5243 24.4648 20.2342 24.4648V24.4648Z'
        fill='#ACB3BA'
      />
      <path
        d='M56.7 29.6273V25.5586C56.7 25.2685 56.5848 24.9903 56.3797 24.7852C56.1745 24.5801 55.8963 24.4648 55.6063 24.4648H49.7656C49.4755 24.4648 49.1973 24.5801 48.9922 24.7852C48.7871 24.9903 48.6719 25.2685 48.6719 25.5586V29.6273C48.6719 29.9174 48.7871 30.1956 48.9922 30.4007C49.1973 30.6059 49.4755 30.7211 49.7656 30.7211V38.3773C49.4755 38.3773 49.1973 38.4926 48.9922 38.6977C48.7871 38.9028 48.6719 39.181 48.6719 39.4711V47.2039C48.6719 47.494 48.7871 47.7722 48.9922 47.9773C49.1973 48.1824 49.4755 48.2977 49.7656 48.2977V50.7367C49.4755 50.7367 49.1973 50.852 48.9922 51.0571C48.7871 51.2622 48.6719 51.5404 48.6719 51.8305V66.0602C48.6719 66.3502 48.7871 66.6284 48.9922 66.8336C49.1973 67.0387 49.4755 67.1539 49.7656 67.1539H55.6063C55.8963 67.1539 56.1745 67.0387 56.3797 66.8336C56.5848 66.6284 56.7 66.3502 56.7 66.0602V51.7758C56.7 51.4857 56.5848 51.2075 56.3797 51.0024C56.1745 50.7973 55.8963 50.682 55.6063 50.682V48.243C55.8963 48.243 56.1745 48.1277 56.3797 47.9226C56.5848 47.7175 56.7 47.4393 56.7 47.1492V39.4164C56.7 39.1263 56.5848 38.8481 56.3797 38.643C56.1745 38.4379 55.8963 38.3227 55.6063 38.3227V30.7211C55.8963 30.7211 56.1745 30.6059 56.3797 30.4007C56.5848 30.1956 56.7 29.9174 56.7 29.6273V29.6273Z'
        fill='#ACB3BA'
      />
      <path
        d='M60.7358 46.0547H9.22022C8.61615 46.0547 8.12646 46.5444 8.12646 47.1484V51.775C8.12646 52.3791 8.61615 52.8688 9.22022 52.8688H60.7358C61.3399 52.8688 61.8296 52.3791 61.8296 51.775V47.1484C61.8296 46.5444 61.3399 46.0547 60.7358 46.0547Z'
        fill='#F8B64C'
      />
      <path
        d='M63.853 28.543H6.15771C5.86763 28.543 5.58943 28.6582 5.38432 28.8633C5.1792 29.0684 5.06396 29.3466 5.06396 29.6367V39.4148C5.06396 39.7049 5.1792 39.9831 5.38432 40.1882C5.58943 40.3934 5.86763 40.5086 6.15771 40.5086H63.853C64.1431 40.5086 64.4213 40.3934 64.6264 40.1882C64.8315 39.9831 64.9468 39.7049 64.9468 39.4148V31.6055V29.6367C64.9468 29.3466 64.8315 29.0684 64.6264 28.8633C64.4213 28.6582 64.1431 28.543 63.853 28.543V28.543Z'
        fill='#FFD164'
      />
      <path
        d='M64.9468 31.6055V29.6367C64.9468 29.3466 64.8315 29.0684 64.6264 28.8633C64.4213 28.6582 64.1431 28.543 63.853 28.543H6.15771C5.86763 28.543 5.58943 28.6582 5.38432 28.8633C5.1792 29.0684 5.06396 29.3466 5.06396 29.6367V39.4148C5.06396 39.7049 5.1792 39.9831 5.38432 40.1882C5.58943 40.3934 5.86763 40.5086 6.15771 40.5086H63.853C64.1431 40.5086 64.4213 40.3934 64.6264 40.1882C64.8315 39.9831 64.9468 39.7049 64.9468 39.4148V31.6055ZM62.7593 31.157L55.5952 38.3211H51.2202L58.8108 30.7305H62.7702L62.7593 31.157ZM43.7499 38.3211L51.3515 30.7305H55.7265L48.1249 38.3211H43.7499ZM36.2905 38.3211L43.8811 30.7305H48.2561L40.6655 38.3211H36.2905ZM28.8202 38.3211L36.4108 30.7305H40.7858L33.1952 38.3211H28.8202ZM21.3499 38.3211L28.9405 30.7305H33.3155L25.7249 38.3211H21.3499ZM13.8796 38.3211L21.4702 30.7305H25.8452L18.2546 38.3211H13.8796ZM10.9374 30.7305L7.25146 34.4055V30.7305H10.9374ZM7.25146 37.4789L13.989 30.7305H18.364L10.7733 38.3211H7.25146V37.4789ZM58.6577 38.3211L62.7374 34.2523V38.3211H58.6577Z'
        fill='#F8B64C'
      />
    </svg>
  );
}
