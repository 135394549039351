import { Alert } from 'antd';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  &.ant-alert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: none;
    padding: 3px 8px;
    z-index: 1001;
    .ant-alert-icon,
    .ant-alert-message,
    .ant-alert-close-icon {
      color: white;
    }
    .ant-alert-icon {
      width: 18px;
      height: 18px;
    }
    .ant-alert-message {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px; /* 153.846% */
      display: inline-block;
    }
    .ant-alert-close-icon {
      font-size: 18px;
    }
    &-info {
      background: var(--Link-400, #006ee6);
    }
    &-error {
      background: var(--Error-400, #e62e2e);
    }
    &-warning {
      background: ${({ theme }) => theme.colors.warning.default};
    }
    &-success {
      background: var(--Success-300-Default, #10bc3b);
    }

    &.center {
      justify-content: center;
      > .ant-alert-content {
        flex: none;
      }
    }
  }
`;
