export default function Logoicon({ className }: { className?: string }) {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' fill='#390179' />
      <path
        d='M14.5078 29.9531C10.7214 26.6944 8.74901 22.9744 8.61945 18.8796C8.40342 11.7711 13.9319 6.27757 14.1623 6.04688L16.293 8.23852L15.2276 7.1427L16.293 8.23852C16.2498 8.28177 11.47 13.0543 11.6572 18.8075C11.7579 21.994 13.3848 24.9643 16.4946 27.6461L14.5078 29.9531Z'
        fill='#F3F2F4'
      />
      <path
        d='M19.5897 25.7429C15.9041 22.0805 16.1345 18.2452 16.9695 15.6786C17.8333 13.0112 19.5465 11.1944 19.6185 11.1079L21.8357 13.213L20.7272 12.1605L21.8357 13.213C21.8213 13.2274 20.4823 14.6838 19.8489 16.7168C19.0427 19.2689 19.6617 21.5183 21.7493 23.5801L19.5897 25.7429Z'
        fill='#F3F2F4'
      />
      <path
        d='M25.2046 20.1777C26.4292 20.1777 27.4218 19.1836 27.4218 17.9573C27.4218 16.7309 26.4292 15.7368 25.2046 15.7368C23.9802 15.7368 22.9875 16.7309 22.9875 17.9573C22.9875 19.1836 23.9802 20.1777 25.2046 20.1777Z'
        fill='#F3F2F4'
      />
    </svg>
  );
}
