import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { CONVERSATION_NODE } from '../fragments';

export const SEND_MESSAGE = gql`
  mutation sendMessage($data: ConversationInput!) {
    sendMessage(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...ConversationFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CONVERSATION_NODE}
`;

export const RESEND_MESSAGE = gql`
  mutation resendMessage($id: ShortId!) {
    resendMessage(id: $id) {
      status
      error {
        ...Error
      }
      data {
        ...ConversationFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CONVERSATION_NODE}
`;

export const SEND_BULK_SMS = gql`
  mutation sendBulkMessage($data: BulkMessageInput!) {
    sendBulkMessage(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        channel
        content
        contacts
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const TOGGLE_PIN_FAV_MESSAGE = gql`
  mutation pinnedAgentConversationData($id: ShortId!, $data: PinnedConversationAgentData!) {
    pinnedAgentConversationData(id: $id, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const DELETE_PARTICULAR_MESSAGE = gql`
  mutation archiveParticularConversation($data: ArchiveConversationInputData!) {
    archiveParticularConversation(data: $data) {
      status
      data {
        id
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const PIN_OR_UNPIN_LOG = gql`
  mutation addPinned($data: PinnedInputData!) {
    addPinned(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const MSG_SEEN = gql`
  mutation conversationSeen($contact: String!, $channel: ShortId!) {
    conversationSeen(contact: $contact, channel: $channel) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_CHANNEL_CONTACT_STATE = gql`
  mutation channelContactState($channel: ShortId!, $data: ChannelContactStateInputData!) {
    channelContactState(channel: $channel, data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const SAVE_DRAFT_MSG = gql`
  mutation agentDrafts($data: AgentDraftsInputData!) {
    agentDrafts(data: $data) {
      status
      data {
        id
        msg
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ARCHIVE_ALL_CONVERSATION = gql`
  mutation archieveContactThread($data: ArchiveContactThreadInputData!) {
    archieveContactThread(data: $data) {
      status
      data {
        contact
        msg
        channel
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const MARK_AS_UNREAD = gql`
  mutation markAsUnreadConversation($conversation: ShortId!) {
    markAsUnreadConversation(conversation: $conversation) {
      status
      data {
        id
        unreadCount
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ADD_MACROS = gql`
  mutation addMacros($data: MacrosInputData!) {
    addMacros(data: $data) {
      status
      data {
        id
        title
        message
        type
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const DELETE_MACROS = gql`
  mutation removeMacros($id: ShortId!) {
    removeMacros(id: $id) {
      status
      data {
        id
        title
        message
        type
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_MACROS = gql`
  mutation updateMacros($data: UpdateMacosInputData!, $id: ShortId!) {
    updateMacros(id: $id, data: $data) {
      status
      data {
        id
        title
        message
        type
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ADD_COMMENT = gql`
  mutation addThreadComment($data: ConversationThreadInput!) {
    addThreadComment(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...ConversationFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CONVERSATION_NODE}
`;
