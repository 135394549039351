import React from 'react';

import FallbackSpinner from 'components/atoms/spinner/Spinner';
// import NoData from 'components/no-data';

interface SuspenseProps {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

const Suspense = ({ fallback = <FallbackSpinner />, children }: SuspenseProps) => (
  <React.Suspense fallback={fallback}>{children}</React.Suspense>
);

export default Suspense;
