import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import SecureLS from 'secure-ls';
import { datadogLogs } from '@datadog/browser-logs';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
const operationsToSkipLog = [
  'login',
  'memberLogin',
  'register',
  'changePassword',
  'forgotPassword',
  'resetPassword',
  'signup',
  'checkLoginCredentials',
];
export const handleLogoutWithoutHook = () => {
  // Logout without hook
  ls.clear();
  sessionStorage.clear();
  Cookies.remove(`${COOKIE_PREFIX}_kc_auth_tk`);
  console.log('log out reload');
  // do other stuff required when logout
  // eslint-disable-next-line no-restricted-globals
  location.reload();
  // location.reload() after token removed affects user redirect
};

export default function useErrorLink() {
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: any) => {
    if (graphQLErrors) {
      if (process.env.NODE_ENV === 'production' && graphQLErrors) {
        const skipLog = operationsToSkipLog.includes(operation?.operationName);
        const errorPayload = {
          graphQLError: graphQLErrors,
          operationName: operation?.operationName,
          variables: skipLog ? {} : operation?.variables,
        };
        datadogLogs.logger.error(`GraphQL Error  `, {
          dataDogError: graphQLErrors,
          additionalInfo: errorPayload,
          context: 'graphql error',
        });
      }
      if (graphQLErrors?.length > 0 && graphQLErrors[0]?.extensions?.code === 'Expired') {
        const deviceId = localStorage.getItem('deviceId');
        handleLogoutWithoutHook();
        localStorage.setItem('deviceId', deviceId as string);
      }
    }
    if (networkError) {
      const { status, error } = networkError?.result || {};
      // if (process.env.NODE_ENV === 'production') {
      //   logRollbarError.warn(
      //     `Network Error : ${networkError?.result?.errors?.[0]?.message}`,
      //     networkError?.result?.errors?.[0] ?? networkError,
      //   );
      // }
      if (status === 401 && error?.error_key === 'token_expired') {
        // HANDLE_LOGOUT_FOR_DUPLICATE_LOGIN
        const deviceId = localStorage.getItem('deviceId');
        handleLogoutWithoutHook();
        localStorage.setItem('deviceId', deviceId as string);
      }
      console.error('[Network Error Result]', networkError.result);
    }
    return forward(operation);
  });

  return {
    errorLink,
  };
}
