export default function Addicon() {
  return (
    <svg
      className='h-full w-full'
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      fill='none'
      viewBox='0 0 12 12'
    >
      <path fill='#CFCCD5' d='M5.167 5.167v-5h1.667v5h5v1.666h-5v5H5.167v-5h-5V5.167h5z' />
    </svg>
  );
}
