import { ReactElement } from 'react';
import { Avatar } from 'antd';

import { parsePhoneNumber } from 'components/utils/phone-lib';

import { ChannelInfoWrapper } from './Styles';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

export default function WidgetHeader(): ReactElement {
  const { channelInfo } = useCallWidgetContext();
  const { number = '', name = '' } = channelInfo || {};
  const { flagUrl } = parsePhoneNumber(number);

  return (
    <ChannelInfoWrapper className='pl-12 pr-4 h-10 bg-primary flex items-center text-white w-80 -m-px mb-0 rounded-t-xl'>
      <div className='avatar-container mr-2.5'>
        <Avatar shape='square' className='avatar' src={flagUrl} />
      </div>
      <div>
        <h5 className='text-gray-100 text-13 leading-5 font-semibold truncate max-w-200'>{name}</h5>
      </div>
    </ChannelInfoWrapper>
  );
}
