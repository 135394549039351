import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import cache from 'services/apollo/cache';
import { ADD_NOTE, REMOVE_NOTE, EDIT_NOTE } from 'graphql/client';
import { NUMBERS } from 'constants/routes';
import { ToastMessage } from 'components/atoms';

const useClientNote = (props?: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { channelId } = useParams<Record<string, string | undefined>>();

  const [addNote, { loading: loadingNoteAdd }] = useMutation(ADD_NOTE, {
    onCompleted(resData) {
      const { error, data } = resData?.addNoteByContact || {};
      if (error === null) {
        props?.onNoteSave?.(resData?.addNoteByContact?.data);
        cache.modify({
          fields: {
            clientNotes: () => {},
          },
        });
        ToastMessage({
          content: `${t('toast.addNoteSuccess', 'Note added successfully.')}`,
          type: 'success',
        });
        if (channelId && data?.client?.id) {
          history.push(`${NUMBERS}/${channelId}/${data.client.id}`);
        }
        return;
      }
      props?.onNoteSaveError?.(error?.message);
      ToastMessage({
        content: error?.message || 'Something went wrong',
        type: 'danger',
      });
    },
    onError() {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const addContactNote = async (payload: any) => {
    await addNote({
      variables: payload,
    });
  };

  const [removeNote, { loading: removingNotes }] = useMutation(REMOVE_NOTE, {
    onCompleted(resData) {
      const { error } = resData?.removeNote || {};
      if (error === null) {
        props?.closeNoteDeleteModal?.();
        cache.modify({
          fields: {
            clientNotes: () => {},
          },
        });
        ToastMessage({
          content: `${t('toast.deleteNoteSuccess', 'Note deleted successfully')}`,
          type: 'success',
        });
      } else {
        ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      }
    },
    onError() {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const removeContactNote = async (payload: any) => {
    await removeNote({
      variables: payload,
    });
  };

  const [editNote, { loading: loadingNoteEdit }] = useMutation(EDIT_NOTE, {
    onCompleted(resData) {
      if (resData?.editNote?.error === null) {
        ToastMessage({
          content: `${t('toast.editNoteSuccess', 'Note updated successfully.')}`,
          type: 'success',
        });
        props?.onNoteSave?.(resData?.editNote?.data);
        cache.modify({
          fields: {
            clientNotes: () => {},
          },
        });
        return;
      }
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
    onError() {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const editContactNote = async (payload: any) => {
    await editNote({
      variables: payload,
    });
  };
  return {
    addContactNote,
    removeContactNote,
    editContactNote,
    removingNotes,
    loadingSaveNote: loadingNoteAdd || loadingNoteEdit,
  };
};

export default useClientNote;
