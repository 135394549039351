import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const CREATE_CAMPAIGN_CALL_NOTE = gql`
  mutation createCampaignCallNote($data: CampaignCallNoteInputData!) @api(name: "salesDialer") {
    createCampaignCallNote(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        note
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const EDIT_CAMPAIGN_CALL_NOTE = gql`
  mutation updateCampaignCallNote($data: CampaignCallNoteUpdateInputData!)
  @api(name: "salesDialer") {
    updateCampaignCallNote(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        note
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
