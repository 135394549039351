import styled from 'styled-components';
import { Menu } from 'antd';

export const OptionsWrapper = styled.div`
  min-width: 376px;
  h4 {
    font-weight: ${({ theme }) => theme.text.weight.bold};
    color: ${({ theme }) => theme.colors.text.t7} !important;
    line-height: 14px;
    margin-bottom: 20px;
  }
  label {
    font-weight: ${({ theme }) => theme.text.weight.bold};
    font-size: ${({ theme }) => theme.text.size.body.text.caption} !important;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.text.t5} !important;
    &:hover,
    span {
      padding: 0;
      &:first-child {
        height: fit-content;
      }
    }
  }
`;

export const OptionListWrapper = styled.div`
  width: 320px;
  line-height: 0;
  border: 1px solid #e7e6eb;
  box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
  border-radius: 12px;
  background: #fff;

  .search-field {
    &,
    & * {
      background: transparent;
      border: none;
      border-bottom: none !important;
    }
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1} !important;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #9e99ab;
    padding: 0;
    .ant-input {
      font-size: ${({ theme }) => theme.text.size.body.text.small};
      line-height: 14px;
      &::placeholder {
        color: #9e99ab;
      }
    }
    .ant-input-prefix {
      margin-right: 12px;
      > svg {
        font-size: 14px;
      }
    }
  }
  .scrollbar-container {
    width: 100% !important;
  }
  .no-data {
    padding: 8px 24px 16px;
  }
`;

export const OptionList = styled(Menu)`
  &.ant-menu {
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    height: 48px;

    > .ant-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 14px;
      padding: 0 !important;
      background: none !important;
      font-weight: ${({ theme }) => theme.text.weight.semiBold};
      box-shadow: inset 0px 0.5px 0px #cfccd5 !important;
      span {
        display: block;
        margin: 4px 0px !important;
        color: ${({ theme }) => theme.colors.text.t4};
        line-height: 32px;
      }
      &:hover {
        span {
          background: ${({ theme }) => theme.colors.primary.p0} !important;
          color: ${({ theme }) => theme.colors.text.t9};
        }
      }
      &-selected,
      &-active {
        background: none !important;
      }

      &-selected > svg {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.success.default};
        margin-right: 15px;
      }
    }
    .ant-menu-item-disabled {
      span {
        color: ${({ theme }) => theme.colors.text.t2};
      }
      img {
        opacity: 0.2;
      }
      &:hover {
        background: transparent !important;
        span {
          color: ${({ theme }) => theme.colors.text.t2};
        }
      }
    }
  }
`;
