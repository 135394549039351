export default function MicrophoneFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.43 5.83798V11.408C16.2863 12.4703 15.7622 13.4445 14.955 14.1499C14.1477 14.8553 13.112 15.244 12.04 15.244C10.968 15.244 9.93234 14.8553 9.12509 14.1499C8.31784 13.4445 7.79379 12.4703 7.65005 11.408V5.83798C7.79379 4.77564 8.31784 3.80146 9.12509 3.09607C9.93234 2.39068 10.968 2.00195 12.04 2.00195C13.112 2.00195 14.1477 2.39068 14.955 3.09607C15.7622 3.80146 16.2863 4.77564 16.43 5.83798Z'
        fill='currentColor'
      />
      <path
        d='M12.79 17.998V20.498H15.68C15.8789 20.498 16.0697 20.5771 16.2103 20.7177C16.351 20.8584 16.43 21.0491 16.43 21.248C16.43 21.447 16.351 21.6377 16.2103 21.7784C16.0697 21.919 15.8789 21.998 15.68 21.998H8.40001C8.2011 21.998 8.01033 21.919 7.86968 21.7784C7.72902 21.6377 7.65001 21.447 7.65001 21.248C7.65001 21.0491 7.72902 20.8584 7.86968 20.7177C8.01033 20.5771 8.2011 20.498 8.40001 20.498H11.25V17.998C9.41996 17.8166 7.72306 16.9603 6.48998 15.596C5.2569 14.2316 4.576 12.457 4.58 10.618C4.58 10.4191 4.65902 10.2284 4.79967 10.0877C4.94032 9.94706 5.13109 9.86804 5.33 9.86804C5.52892 9.86804 5.71969 9.94706 5.86034 10.0877C6.00099 10.2284 6.08 10.4191 6.08 10.618C6.08 12.1881 6.70368 13.6939 7.81389 14.8041C8.92411 15.9143 10.4299 16.5381 12 16.5381C13.5701 16.5381 15.0759 15.9143 16.1861 14.8041C17.2963 13.6939 17.92 12.1881 17.92 10.618C17.92 10.4191 17.999 10.2284 18.1396 10.0877C18.2803 9.94706 18.4711 9.86804 18.67 9.86804C18.8689 9.86804 19.0597 9.94706 19.2003 10.0877C19.341 10.2284 19.42 10.4191 19.42 10.618C19.424 12.457 18.7431 14.2316 17.51 15.596C16.2769 16.9603 14.58 17.8166 12.75 17.998H12.79Z'
        fill='currentColor'
      />
    </svg>
  );
}
