import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    box-shadow: 0px 15px 99px -25px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 48px 24px 36px 24px;
  }
  &.import-confirmation-modal {
    .ant-modal-content {
      padding: 40px 24px 26px 24px;
    }
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-footer {
    border-top: none;
  }
`;
export const ImportModal = styled(Modal)`
  .ant-modal-content {
    background: #ffffff;
    border: 1px solid #cfccd5;
    box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
    border-radius: 16px;
    padding: 26px 24px;
  }
`;

export const DownloadCSV = styled.a`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 13px;
  width: fit-content;
  color: ${({ theme }) => theme.colors.info.default};
  span {
    line-height: 17px;
  }
  svg {
    color: #1a63f4;
  }
`;
