import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const FETCH_CAMPAIGN_CALL_NOTES = gql`
  query getCampaignCallNotes($campaignConversationId: ShortId!) @api(name: "salesDialer") {
    getCampaignCallNotes(campaignConversationId: $campaignConversationId) {
      data {
        description
      }
      error {
        ...Error
      }
      status
    }
  }
  ${ERROR_FRAGMENT}
`;
