import { Radio } from 'antd';

import { Icon } from 'components/atoms';
import { CARD_LOGO } from 'constants/commons';
import uuid from 'components/utils/uuid';

import * as S from './PaymentCardStyles';

type PaymentMethodCardProps = {
  card?: any;
};

const PaymentMethodCard = ({ card }: PaymentMethodCardProps) => {
  const { id = '', lastDigit, brand = 'visa', email } = card ?? {};

  const cardLogoOrInitials = () => {
    if (CARD_LOGO[brand]) {
      return <Icon name={CARD_LOGO[brand]} />;
    }
    return <span>{brand?.toUpperCase()?.substring(0, 2)}</span>;
  };
  return (
    // TODO: Need to fix UI design as per figma
    <S.PaymentMethodCardWrapper>
      <Radio value={id} name='paymentID'>
        {brand ? (
          <>
            <div className='flex bg-white h-8 w-14 ml-4 border border-gray-100 items-center justify-center rounded-5'>
              {cardLogoOrInitials()}
            </div>
            <div className='flex ml-auto items-center'>
              <p className='w-32 text-base text-gray-600 font-medium  leading-normal flex justify-between'>
                {Array.from({ length: 3 }, () => (
                  <span key={uuid()}>****</span>
                ))}
                <span>{lastDigit ?? 'xxxx'}</span>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className='flex h-8 w-14 ml-4 items-center justify-center '>
              <Icon name='paypal' />
            </div>
            <div className='ml-2 items-center text-base text-gray-600 font-medium  leading-normal truncate w-60 '>
              {email}
            </div>
          </>
        )}
      </Radio>
    </S.PaymentMethodCardWrapper>
  );
};

export default PaymentMethodCard;
