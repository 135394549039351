// TODO: NEED TO FETCH CONTACTS SUGGESTIONS FROM BOTH CONTACTS TABLE AND CALL LOGS
import { useLazyQuery } from '@apollo/client';
import { CONTACTS } from 'graphql/client';

export function useContactLogsSearch() {
  const LIMIT = 20;

  const [loadSearchedContacts, { data: query, loading }] = useLazyQuery(CONTACTS, {
    fetchPolicy: 'cache-and-network',
  });

  const searchContacts = async (value: string) => {
    await loadSearchedContacts({
      variables: {
        params: {
          first: LIMIT,
          search: {
            columns: ['number', 'name'],
            value,
          },
        },
      },
    });
  };

  const { edges: contacts = [] } = query?.contacts?.data || {};

  const contactSuggestion = contacts?.map((data: any) => {
    const { id, number, name, profilePicture } = data?.node || {};
    return {
      id,
      name,
      number,
      profilePicture,
    };
  });
  return {
    loading,
    searchContacts,
    contacts,
    contactSuggestion,
  };
}
