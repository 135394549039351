import styled from 'styled-components';

import { WIDGET_SIDEBAR_WIDTH } from '../constants';

export const Wrapper = styled.nav`
  width: ${WIDGET_SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  > button,
  > strong {
    + button,
    + strong {
      border-top: 1px solid var(--Stroke-100-Default, #e5e4e7);
    }
  }
  + main {
    width: calc(100% - ${WIDGET_SIDEBAR_WIDTH}px);
  }
`;

export const SidebarIconWrapper = styled.button`
  cursor: pointer;
  transition: all 0.15s ease-in;
  > svg {
    height: 44px;
    width: 44px;
    padding: 10px;
  }
  :hover {
    background: var(--Background-400, #edebee);
  }
  &.active {
    background: var(--Background-300, #f5f4f6d7);
  }
`;
