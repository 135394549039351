import { css } from 'styled-components';

export const antRadio = css`
  .ant-radio {
    &-inner {
      &::after {
        background-color: #f5f2f8;
      }
    }
    &-checked {
      .ant-radio-inner {
        background: #390179;
        border: 1px solid #390179;
        &::after {
          background-color: #f5f2f8;
          border: 1px solid #390179;
        }
      }
    }
  }
`;
