import packageJson from '../../../../package.json';

export const KrispcallVersionMark = ({ className }: { className?: string }) => (
  <p className={`text-sm text-gray leading-4.5 ${className}`}>
    KrispCall{' '}
    <a className='text-info' href='##'>
      v{packageJson?.version}
    </a>
  </p>
);
