export default function SpeakerIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.9258 8.00359V19.9969C15.9267 20.4463 15.8228 20.8896 15.6225 21.2919C15.4302 21.698 15.1319 22.0446 14.7592 22.2952C14.3828 22.5483 13.9409 22.686 13.4875 22.6919C13.0385 22.6921 12.5997 22.5579 12.2274 22.3069L7.56078 19.2385C7.47023 19.18 7.36523 19.1478 7.25743 19.1452H5.07578C4.73692 19.1424 4.40276 19.0658 4.09652 18.9207C3.79029 18.7757 3.51935 18.5656 3.30249 18.3052C2.86112 17.7838 2.62105 17.1216 2.6258 16.4385V11.5619C2.62384 10.8828 2.86373 10.2252 3.30249 9.70692C3.51935 9.44653 3.79029 9.23649 4.09652 9.09143C4.40276 8.94637 4.73692 8.86976 5.07578 8.86692H7.29247C7.40567 8.86456 7.51552 8.82796 7.60749 8.76192L12.2742 5.69358C12.6484 5.44687 13.0858 5.31323 13.5341 5.30859C13.9875 5.31444 14.4295 5.45227 14.8058 5.70526C15.176 5.95887 15.4735 6.30469 15.6692 6.70857C15.8521 7.11507 15.94 7.55801 15.9258 8.00359Z'
        fill='currentColor'
      />
      <path
        d='M18.9593 19.2959C18.7283 19.2909 18.5076 19.1993 18.341 19.0393C18.1771 18.8752 18.0852 18.6527 18.0852 18.4209C18.0852 18.189 18.1771 17.9666 18.341 17.8026C18.8398 17.3051 19.2355 16.7142 19.5056 16.0636C19.7755 15.4129 19.9146 14.7154 19.9146 14.0109C19.9146 13.3065 19.7755 12.6089 19.5056 11.9583C19.2355 11.3077 18.8398 10.7167 18.341 10.2192C18.255 10.1391 18.1861 10.0425 18.1382 9.9352C18.0904 9.82788 18.0646 9.71204 18.0626 9.59455C18.0605 9.47706 18.0822 9.36035 18.1262 9.25139C18.1702 9.14245 18.2358 9.04346 18.3188 8.96037C18.4019 8.87728 18.5008 8.81179 18.6098 8.76779C18.7188 8.72378 18.8354 8.70216 18.9529 8.70424C19.0704 8.70631 19.1863 8.73204 19.2936 8.77986C19.4009 8.82768 19.4976 8.89662 19.5777 8.98259C20.2401 9.64172 20.7658 10.4253 21.1246 11.2882C21.4832 12.1511 21.6679 13.0764 21.6679 14.0109C21.6679 14.9454 21.4832 15.8707 21.1246 16.7337C20.7658 17.5966 20.2401 18.3801 19.5777 19.0393C19.4111 19.1993 19.1903 19.2909 18.9593 19.2959Z'
        fill='currentColor'
      />
      <path
        d='M21.5842 21.9091C21.4691 21.9103 21.355 21.8882 21.2487 21.8441C21.1425 21.8 21.0462 21.7348 20.9658 21.6524C20.8832 21.572 20.8178 21.4759 20.773 21.3696C20.7282 21.2634 20.7051 21.1493 20.7051 21.0341C20.7051 20.9188 20.7282 20.8047 20.773 20.6985C20.8178 20.5924 20.8832 20.4962 20.9658 20.4157C22.6585 18.7091 23.6084 16.4029 23.6084 13.9991C23.6084 11.5954 22.6585 9.28902 20.9658 7.58243C20.8832 7.50199 20.8178 7.40582 20.773 7.29963C20.7282 7.19343 20.7051 7.07935 20.7051 6.9641C20.7051 6.84884 20.7282 6.73473 20.773 6.62853C20.8178 6.52234 20.8832 6.42621 20.9658 6.34576C21.1299 6.18191 21.3523 6.08984 21.5842 6.08984C21.8161 6.08984 22.0385 6.18191 22.2026 6.34576C24.2337 8.38195 25.3743 11.1406 25.3743 14.0166C25.3743 16.8926 24.2337 19.6512 22.2026 21.6874C22.0337 21.8399 21.8114 21.9196 21.5842 21.9091Z'
        fill='currentColor'
      />
    </svg>
  );
}
