import { css } from 'styled-components';

export const phoneInput = css`
  .form-control.krisp-form-control {
    height: 44px;
    font-size: 14px;
    line-height: 14px;
    padding-left: 82px;
    color: ${({ theme }) => theme.colors.text.t5};
    background: ${({ theme }) => theme.colors.primary.p0};
    width: 100%;
    border: 1px solid #d7cce4;
    border-radius: 8px;
    &.field-error {
      border-color: #db312b;
      background: none;
      + .flag-dropdown {
        border-color: #db312b;
      }
    }
  }
  .krisp-phone-input-btn {
    &.flag-dropdown,
    &.flag-dropdown.open {
      width: 72px;
      height: 44px;
      background: transparent;
      border-radius: 8px 0px 0px 8px;
      background: ${({ theme }) => theme.colors.primary.p0};
      border: 1px solid #d7cce4;
      .flag {
        transform: scale(1.25);
      }
      .selected-flag:hover {
        background: ${({ theme }) => theme.colors.primary.p0};
      }
    }
  }
`;
