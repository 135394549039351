const CsvFile = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#E7E6EB' />
    <g clipPath='url(#clip0_24390_204484)'>
      <path d='M25.4006 8H10.5996V32H29.4001V11.9995L25.4006 8Z' fill='white' />
      <path d='M26.0196 9.99979H10.5996V8H25.4006L26.0196 9.99979Z' fill='#007934' />
      <path
        d='M17.0934 26.0465C16.8574 26.0465 16.673 26.1433 16.5408 26.3367C16.4085 26.5302 16.3423 26.7969 16.3423 27.1369C16.3423 27.8437 16.6111 28.1971 17.1488 28.1971C17.3112 28.1971 17.4687 28.1745 17.6211 28.1293C17.7735 28.0841 17.9267 28.0297 18.0809 27.966V28.8052C17.7744 28.9408 17.4277 29.0086 17.0407 29.0086C16.4863 29.0086 16.0613 28.8478 15.7657 28.5262C15.4701 28.2047 15.3223 27.7399 15.3223 27.1318C15.3223 26.7516 15.3938 26.4176 15.5371 26.1294C15.6803 25.8413 15.8863 25.6198 16.1552 25.4649C16.424 25.31 16.7402 25.2324 17.1035 25.2324C17.5006 25.2324 17.8799 25.3187 18.2416 25.4913L17.9376 26.2726C17.802 26.2089 17.6662 26.1554 17.5306 26.1119C17.395 26.0683 17.2493 26.0465 17.0934 26.0465Z'
        fill='#9E99AB'
      />
      <path
        d='M21.1986 27.8428C21.1986 28.0707 21.1409 28.2729 21.0252 28.4496C20.9097 28.6263 20.743 28.7636 20.5253 28.8616C20.3075 28.9595 20.0522 29.0085 19.759 29.0085C19.5145 29.0085 19.3093 28.9914 19.1435 28.9571C18.9777 28.9227 18.8052 28.8628 18.626 28.7774V27.893C18.8152 27.9902 19.012 28.0661 19.2163 28.1204C19.4207 28.1749 19.6083 28.2021 19.7791 28.2021C19.9265 28.2021 20.0345 28.1766 20.1032 28.1254C20.1719 28.0743 20.2063 28.0085 20.2063 27.9282C20.2063 27.8779 20.1925 27.834 20.1648 27.7963C20.1372 27.7585 20.0928 27.7205 20.0317 27.682C19.9706 27.6435 19.8077 27.5648 19.543 27.4459C19.3035 27.337 19.1238 27.2315 19.004 27.1293C18.8843 27.0271 18.7955 26.91 18.7377 26.7776C18.68 26.6452 18.6511 26.4887 18.6511 26.3077C18.6511 25.9695 18.7742 25.7057 19.0204 25.5164C19.2665 25.3271 19.6049 25.2324 20.0354 25.2324C20.4156 25.2324 20.8032 25.3204 21.1986 25.4963L20.8945 26.2626C20.5512 26.1051 20.2547 26.0264 20.0052 26.0264C19.8762 26.0264 19.7825 26.049 19.7238 26.0942C19.6652 26.1394 19.6359 26.1955 19.6359 26.2626C19.6359 26.3346 19.6732 26.3991 19.7477 26.456C19.8221 26.513 20.0244 26.6168 20.3544 26.7675C20.671 26.91 20.8908 27.0627 21.0139 27.226C21.1371 27.3893 21.1986 27.595 21.1986 27.8428Z'
        fill='#9E99AB'
      />
      <path
        d='M23.8293 25.2852H24.9397L23.7363 28.9582H22.568L21.3696 25.2852H22.4851L22.9851 27.1469C23.089 27.5607 23.1459 27.8486 23.1559 28.0111C23.1676 27.8939 23.191 27.7473 23.2263 27.5714C23.2614 27.3955 23.2924 27.2574 23.3192 27.1569L23.8293 25.2852Z'
        fill='#9E99AB'
      />
      <path d='M25.4004 11.9995H29.3999L25.4004 8V11.9995Z' fill='#D1D3D3' />
      <path d='M21.3165 14.3594H18.6826V15.5798H21.3165V14.3594Z' fill='#007934' />
      <path d='M24.5338 14.3594H21.8999V15.5798H24.5338V14.3594Z' fill='#007934' />
      <path d='M21.3165 16.125H18.6826V17.3455H21.3165V16.125Z' fill='#007934' />
      <path d='M24.5338 16.127H21.8999V17.3475H24.5338V16.127Z' fill='#007934' />
      <path d='M21.3165 17.8926H18.6826V19.113H21.3165V17.8926Z' fill='#007934' />
      <path d='M24.5338 17.8926H21.8999V19.113H24.5338V17.8926Z' fill='#007934' />
      <path d='M18.0992 17.8926H15.4653V19.113H18.0992V17.8926Z' fill='#007934' />
      <path d='M21.3165 19.6602H18.6826V20.8806H21.3165V19.6602Z' fill='#007934' />
      <path d='M24.5338 19.6602H21.8999V20.8806H24.5338V19.6602Z' fill='#007934' />
      <path d='M18.0992 19.6602H15.4653V20.8806H18.0992V19.6602Z' fill='#007934' />
      <path
        d='M18.0603 17.1951H17.2655L16.7698 16.4002L16.2794 17.1951H15.502L16.3455 15.8941L15.5524 14.6523H16.3142L16.7733 15.4385L17.2151 14.6523H17.9995L17.1942 15.9481L18.0603 17.1951Z'
        fill='#007934'
      />
    </g>
    <defs>
      <clipPath id='clip0_24390_204484'>
        <rect width='24' height='24' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>
);
export default CsvFile;
