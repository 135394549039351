import * as yup from 'yup';

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 46;

export const purchaseNumberInputs = {
  name: yup
    .string()
    .trim()
    .required('Required')
    .min(NAME_MIN_LENGTH, `Should contain atleast ${NAME_MIN_LENGTH} characters`)
    .max(NAME_MAX_LENGTH, `Shouldn't exceed ${NAME_MAX_LENGTH} characters`),
};
