import { useState, useEffect, useContext } from 'react';
import { Button } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import startsWith from 'lodash.startswith';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactiveVar } from '@apollo/client';
import { RiDeleteBack2Fill } from 'react-icons/ri';

import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import { Icon } from 'components/atoms';
import { HASHED_COUNTRIES } from 'constants/countries';
import useDebounce from 'hooks/useDebounce';
import AutoCompleteField from 'components/molecules/fields/AutoCompleteField';
import { parsePhoneNumber, isValidPhoneNumber } from 'components/utils/phone-lib';
import { EMERGENCY_NUMBERS } from 'constants/commons';
import { getAllUrlParams } from 'components/organisms/common/utils';

import WidgetDrawer from 'components/molecules/widget-drawer/WidgetDrawer';
import { WidgetBanner } from 'components/molecules/widget-banner/WidgetBanner';
import { useTwilioContext } from 'lib/twilio';
import { AuthContext } from 'contexts/auth/AuthContext';
import * as S from './Styles';
import { useContactLogsSearch } from '../useContactLogsSearch';
import CountrySelect from '../country-select/CountrySelect';
import { selectedCountry, useNumberSearchVars, updatedDialCode } from '../phone-input/useVars';
import { guessCountry, isNumber } from '../phone-input/helper';
import { ContactAutoCompleteOption } from '../ContactAutoCompleteOption';
import { ERROR_MESSAGES } from '../../../common/constants';
import { Keypad } from '../keypad/Keypad';
import NumberSelect from '../number-select/NumberSelect';
import { schema } from './schema';
import { ContactAdd } from '../../contact-add/ContactAdd';
import useTouchToneContext from '../../touch-tone/useTouchToneContext';
import { isKYCUnverifiedMsg } from '../constants';

interface IWidgetBodyProps {
  channels: any;
  selectedChannel: any;
  makeCall: (formData: any) => void;
  handleNumberSelect: ({ key }: any) => void;
}
export default function WidgetBody({
  makeCall,
  channels,
  selectedChannel,
  handleNumberSelect,
}: IWidgetBodyProps) {
  const defaultCountryCode = localStorage.getItem('_dialer-default-country') || 'US';
  const WAIT_TIME_IN_MS = 500;
  const [selectedContact, setSelectedContact] = useState<any>(null);
  const [searchedText, setSearchedText] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchedQuery, setSearchedQuery] = useState('');
  const [selectedAction, setSelectedAction] = useState<null | string>(null);
  const [numberInput, setNumberInput] = useState<string | null>(null);
  const [disableCall, setDisableCall] = useState<boolean>(false);

  const debouncedQuery = useDebounce<string>(searchedQuery, WAIT_TIME_IN_MS);
  const { searchContacts, contacts } = useContactLogsSearch();
  const { setSelectedCountry } = useNumberSearchVars(selectedCountry);
  const dialingCode: any = useReactiveVar(updatedDialCode);
  const { audioInputDevices } = useMediaDevices();
  const location: any = useLocation();
  const { play } = useTouchToneContext();
  const { isKYCVerified } = useContext(AuthContext);

  // Get contact number from url when opened from chrome extension
  const urlParams = getAllUrlParams(location?.search);

  // Get contact number from contacts page call action
  const {
    state: { clientNumber },
  } = useTwilioContext();

  // Get contact number from url or from contacts page call action
  const dialingNumber = urlParams?.call || clientNumber;

  const defaultCountryDetail = HASHED_COUNTRIES[defaultCountryCode];

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      /** Display recently dialed number's country code stored from localstorage or display US flag if not available* */
      number: defaultCountryDetail?.dialingCode ?? '+1',
    },
  });

  const formSubmit = async (formData: any) => {
    setDisableCall(true);
    const { number: dialedNumber } = formData;
    const number = dialedNumber?.replace(/\s/g, '');
    const { nationalNumber = '' } = number ? parsePhoneNumber(number) : {};
    const isValid = number && isValidPhoneNumber(number);
    if (audioInputDevices?.length < 1) {
      setErrorMessage(ERROR_MESSAGES.noMicPermission);
      setDisableCall(false);
      return;
    }
    if (!selectedChannel) {
      setErrorMessage(ERROR_MESSAGES.numberNotAvailable);
      setDisableCall(false);
      return;
    }
    if (!isNumber(number)) {
      setErrorMessage(ERROR_MESSAGES.invalidContactName);
      setDisableCall(false);
      return;
    }
    if (EMERGENCY_NUMBERS.includes(nationalNumber as string)) {
      setErrorMessage(ERROR_MESSAGES.emergencyCalls);
      setDisableCall(false);
      return;
    }
    if (!isValid) {
      setErrorMessage(ERROR_MESSAGES.invalidPhoneNumber);
      setDisableCall(false);
      return;
    }
    setErrorMessage(null);
    setTimeout(() => {
      setDisableCall(false);
    }, 5000);
    await makeCall(formData);
  };

  useEffect(() => {
    if (debouncedQuery) {
      searchContacts(debouncedQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    if (dialingCode) {
      setSelectedContact(null);
      setSearchedText(dialingCode);
      setValue('number', dialingCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialingCode, setValue]);

  useEffect(() => {
    if (dialingNumber) {
      setTimeout(() => {
        setSelectedContact(null);
        setSearchedText(dialingNumber);
        setValue('number', dialingNumber);
      }, 2000);
    }
  }, [dialingNumber, setValue]);

  useEffect(() => {
    if (defaultCountryDetail?.dialingCode) {
      setSelectedContact(null);
      setSearchedText(defaultCountryDetail?.dialingCode);
      setValue('number', defaultCountryDetail?.dialingCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountryDetail, setValue]);

  const handleSearch = (inputText: string) => {
    setSelectedContact(null);
    setSearchedQuery(inputText);
    setSearchedText(inputText);
    if (inputText?.length > 1 && !startsWith(inputText, '+') && isNumber(inputText))
      setValue('number', `+${inputText}`);
    if (inputText) {
      const guessedCountry = guessCountry(inputText);
      if (guessedCountry) setSelectedCountry(guessedCountry);
    }
  };

  const handleDialpadClick = (key: string, tone?: number[]) => {
    let inputText: string;
    switch (key) {
      case 'add-contact':
        setNumberInput(getValues('number'));
        setSelectedAction('contact-add');
        return;
      case 'make-call':
        formSubmit({ number: searchedText });
        return;
      case 'clear':
        inputText = searchedText?.slice(0, -1);
        break;
      default:
        inputText = searchedText + key;
    }
    setSelectedContact(null);
    setSearchedQuery(inputText);
    setSearchedText(inputText);
    setValue('number', inputText);
    if (inputText?.length > 1 && !startsWith(inputText, '+') && isNumber(inputText))
      setValue('number', `+${inputText}`);
    if (inputText) {
      const guessedCountry = guessCountry(inputText);
      if (guessedCountry) setSelectedCountry(guessedCountry);
    }
    // Play dtmf tone
    if (tone) play?.(tone);
  };

  const handleSelect = (value: string, options: any) => {
    setSearchedText(value);
    setSelectedContact(options?.data);
    const guessedCountry = guessCountry(value);
    if (guessedCountry) setSelectedCountry(guessedCountry);
  };

  const handleCloseDrawer = () => {
    setSelectedAction(null);
  };

  const options = contacts.map((data: any) => {
    const { number, profilePicture, name } = data?.node || {};
    const { country, flagUrl } = parsePhoneNumber(number);
    return {
      value: number,
      label: (
        <ContactAutoCompleteOption
          flagUrl={flagUrl}
          countryCode={country}
          number={number}
          searchedText={searchedText}
          name={name}
          profilePicture={profilePicture}
        />
      ),
      data: { name, number },
    };
  });

  return (
    <div className=' overflow-hidden relative'>
      <WidgetDrawer setOpen={setSelectedAction} open={selectedAction} height={242}>
        <ContactAdd handleClose={handleCloseDrawer} defaultNumber={numberInput} />
      </WidgetDrawer>
      {!isKYCVerified && (
        <WidgetBanner
          title={isKYCUnverifiedMsg}
          type='warning'
          icon={<Icon name='exclamation-triangle' />}
          showIcon={true}
          closable={true}
        />
      )}
      {errorMessage && (
        <WidgetBanner
          title={errorMessage}
          type='error'
          icon={<Icon name='exclamation' />}
          showIcon={true}
        />
      )}

      <form onSubmit={handleSubmit(formSubmit)}>
        <div className='w-full'>
          <S.PhoneInputStyles className='relative h-17'>
            {selectedContact?.name && !errorMessage && (
              <div className='number-input-upper-text absolute top-1.5 left-16 text-13 text-gray leading-3.25 truncate w-60'>
                {selectedContact.name}
              </div>
            )}
            <div className='flex h-full items-center'>
              <CountrySelect contactNumber={dialingNumber} />
              <AutoCompleteField
                className='number-input'
                placeholder='Enter a phone number or contact name'
                control={control}
                name='number'
                options={options}
                onSelect={handleSelect}
                onSearch={handleSearch}
                dropdownClassName='contact-options-dropdown'
                dropdownMatchSelectWidth={false}
              />
            </div>
          </S.PhoneInputStyles>
          <Keypad handleDialpadClick={handleDialpadClick} />
          <div
            data-cy='n-select-wrap'
            className='h-11 mx-2.5 bg-primary-50 border border-gray-100 rounded flex items-center justify-center'
          >
            {selectedChannel && (
              <NumberSelect
                channels={channels}
                selectedChannel={selectedChannel}
                handleNumberSelect={handleNumberSelect}
              />
            )}
          </div>
          <S.FooterBtnWrapper data-cy='btn-footer'>
            <Button onClick={() => handleDialpadClick('add-contact')}>
              <div data-cy='add-icon' className='flex flex-col items-center justify-center'>
                <Icon name='user-add' />
              </div>
            </Button>
            <Button
              onClick={() => handleDialpadClick('make-call')}
              className='make-call'
              disabled={disableCall}
            >
              <div data-cy='make-call-icon' className='flex flex-col items-center justify-center'>
                <Icon name='widget_call-receive' />
              </div>
            </Button>
            <Button onClick={() => handleDialpadClick('clear')}>
              <div data-cy='clear-icon' className='flex flex-col items-center justify-center'>
                <RiDeleteBack2Fill color='#3D3358' size={20} />
              </div>
            </Button>
          </S.FooterBtnWrapper>
        </div>
      </form>
    </div>
  );
}
