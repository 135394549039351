import React, { memo } from 'react';

interface LabelProps {
  id?: string;
  children: React.ReactNode;
  required?: boolean;
  fontWeight?: 'normal' | 'medium';
}

const Label = ({ id = 'id', children, required = false, fontWeight = 'medium' }: LabelProps) => {
  const mapFontWeightClass = (weight: string) => {
    const font: any = {
      medium: 'font-medium',
      normal: 'font-normal',
    };
    return font[weight];
  };

  return (
    <label
      htmlFor={id}
      className={`text-sm leading-3.5 text-gray-700 mb-1.5 inline-block ${mapFontWeightClass(
        fontWeight,
      )}`}
    >
      {children}
      {required && <span className='text-error'> *</span>}
    </label>
  );
};

export default memo(Label);
