import {
  isValidPhoneNumber as isValidPhone,
  parsePhoneNumber as parseNumber,
  isPossiblePhoneNumber,
} from 'libphonenumber-js/max';
import { HASHED_COUNTRIES, COUNTRIES } from 'constants/countries';

export const parsePhoneNumber = (phoneNumber: string) => {
  try {
    const parsedPhoneNumber = parseNumber(`+${phoneNumber.replace(/\+/g, '')} `);
    const guessedCountry = COUNTRIES?.find(data =>
      parsedPhoneNumber?.country
        ? data.alphaTwoCode === parsedPhoneNumber?.country
        : data.dialingCode === `+${parsedPhoneNumber?.countryCallingCode}`,
    );

    const parsedCountry = parsedPhoneNumber?.country && HASHED_COUNTRIES[parsedPhoneNumber.country];
    const number = parsedPhoneNumber?.number;
    const phoneFormatIntl = parsedPhoneNumber?.formatInternational() || phoneNumber || '';
    const nationalNumber = parsedPhoneNumber?.formatNational() || phoneNumber || '';
    const countryCode = parsedPhoneNumber?.countryCallingCode || '';
    const flagUrl = parsedCountry?.flagUrl ?? (guessedCountry?.flagUrl || '');
    const alphaTwoCode = parsedCountry?.alphaTwoCode ?? (guessedCountry?.alphaTwoCode || '');
    const country = parsedPhoneNumber?.country || guessedCountry?.alphaTwoCode || '';
    return {
      parsedPhoneNumber,
      number,
      phoneFormatIntl,
      country,
      flagUrl,
      nationalNumber,
      countryCode,
      alphaTwoCode,
    };
  } catch {
    return {};
  }
};

export const getPhoneNumberFormatIntl = (phoneNumber: string) => {
  try {
    const phone = parseNumber(`+${phoneNumber.replace(/\+/g, '')} `);
    const phoneNumberFormat = phone?.formatInternational() || phoneNumber || '';
    return phoneNumberFormat;
  } catch {
    return phoneNumber;
  }
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  try {
    const isPossibleNumber = isPossiblePhoneNumber(`+${phoneNumber.replace(/\+/g, '')} )`);
    const parsedNumber = parseNumber(`+${phoneNumber.replace(/\+/g, '')} `);
    const { country = '' } = parsedNumber || {};
    // VALIDATE NUMBER USING REGEX FOR SEPCIAL COUNTRIES WHICH ARE NOT HANDLED BY GOOGLE LIBPHONENUMBER LIBRARY
    const phRegex = HASHED_COUNTRIES[country]?.regex;
    // const isValid =
    //   parsedNumber &&
    //   (isValidPhone(phoneNumber, parsedNumber.country) || (phRegex && phoneNumber.match(phRegex)));

    // HOTFIX FOR NUMBER VALIDATION WHICH ARE PURCHASED FROM TWILIO
    const isPossible =
      parsedNumber && (isPossibleNumber || (phRegex && phoneNumber.match(phRegex)));
    return !!isPossible;
  } catch {
    return false;
  }
};

// TODO: Use this utility function in callwidget and other necessary components
export const guessCountry = (value: any) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value.replace(/\+/g, ''))) return {};
  const parsedNumber = value && parsePhoneNumber(value);
  if (parsedNumber) {
    const guessedCountry = COUNTRIES?.find(data =>
      parsedNumber?.country
        ? data.alphaTwoCode === parsedNumber?.country
        : data.dialingCode === `+${parsedNumber?.countryCode}`,
    );
    return guessedCountry;
  }
  return {};
};
