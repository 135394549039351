import { css } from 'styled-components';

export const antTooltip = css`
  .ant-tooltip {
    z-index: 99999999 !important; /* greater than react draggable */
    max-width: 285px !important;
    padding: 0 !important;
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      font-weight: normal;
      font-size: 13px;
      color: #ffffff;
      background: #251a43;
      border-radius: 5px;
      padding: 6px 12px;
      box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.25);
      min-height: auto;
      line-height: 12px;
    }
  }
`;
