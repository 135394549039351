export default function ContactBook2({ className }: { className?: string }) {
  return (
    <svg
      width='200'
      height='200'
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='100' cy='100' r='100' fill='#F3F7FE' />
      <path d='M118.333 80H126.667V91.6667H118.333V80Z' fill='url(#paint0_linear_14927_161557)' />
      <path
        d='M118.333 91.666H126.667V104.999H118.333V91.666Z'
        fill='url(#paint1_linear_14927_161557)'
      />
      <path d='M118.333 105H126.667V118.333H118.333V105Z' fill='url(#paint2_linear_14927_161557)' />
      <path
        d='M126.667 128.333V118.333H118.333V131.666H123.333C124.217 131.666 125.065 131.315 125.69 130.69C126.315 130.065 126.667 129.217 126.667 128.333Z'
        fill='url(#paint3_linear_14927_161557)'
      />
      <path
        d='M126.667 71.6663C126.667 70.7823 126.315 69.9344 125.69 69.3093C125.065 68.6842 124.217 68.333 123.333 68.333H118.333V79.9997H126.667V71.6663Z'
        fill='url(#paint4_linear_14927_161557)'
      />
      <path
        d='M121.667 71.6663C121.667 70.7823 121.315 69.9344 120.69 69.3093C120.065 68.6842 119.217 68.333 118.333 68.333H76.6667C75.7826 68.333 74.9348 68.6842 74.3097 69.3093C73.6845 69.9344 73.3333 70.7823 73.3333 71.6663V128.333C73.3333 129.217 73.6845 130.065 74.3097 130.69C74.9348 131.315 75.7826 131.666 76.6667 131.666H118.333C119.217 131.666 120.065 131.315 120.69 130.69C121.315 130.065 121.667 129.217 121.667 128.333V71.6663Z'
        fill='url(#paint5_linear_14927_161557)'
      />
      <path
        opacity='0.15'
        d='M98.3333 98.3327C102.936 98.3327 106.667 94.6017 106.667 89.9993C106.667 85.397 102.936 81.666 98.3333 81.666C93.731 81.666 90 85.397 90 89.9993C90 94.6017 93.731 98.3327 98.3333 98.3327Z'
        fill='url(#paint6_radial_14927_161557)'
      />
      <path
        opacity='0.05'
        d='M86.6667 111.666V115.19C86.6667 116.925 88.075 118.333 89.81 118.333H106.855C107.689 118.333 108.489 118.002 109.078 117.412C109.668 116.823 110 116.023 110 115.19V111.666C110 103.333 104.777 98.333 98.3334 98.333C91.89 98.333 86.6667 103.333 86.6667 111.666Z'
        fill='black'
      />
      <path
        opacity='0.07'
        d='M87.5 111.043V115.095C87.5 116.423 88.5767 117.5 89.905 117.5H106.762C108.09 117.5 109.167 116.423 109.167 115.095V110.833C109.167 103.853 104.233 99.0831 98.18 99.1681C92.2217 99.2514 87.5 104.138 87.5 111.043V111.043Z'
        fill='black'
      />
      <path
        d='M98.3334 96.6663C102.015 96.6663 105 93.6816 105 89.9997C105 86.3178 102.015 83.333 98.3334 83.333C94.6515 83.333 91.6667 86.3178 91.6667 89.9997C91.6667 93.6816 94.6515 96.6663 98.3334 96.6663Z'
        fill='white'
      />
      <path
        d='M108.333 110C108.333 108.661 108.064 107.335 107.542 106.102C107.02 104.869 106.256 103.753 105.294 102.821C104.333 101.888 103.194 101.159 101.945 100.675C100.697 100.191 99.3635 99.9635 98.025 100.005C92.5533 100.168 88.3333 104.943 88.3333 110.42V115C88.3333 115.442 88.5089 115.866 88.8215 116.178C89.1341 116.491 89.558 116.666 90 116.666H106.667C107.109 116.666 107.533 116.491 107.845 116.178C108.158 115.866 108.333 115.442 108.333 115V110Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_14927_161557'
          x1='118.355'
          y1='85.8333'
          x2='126.235'
          y2='85.8333'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.3' stopColor='#9C6500' />
          <stop offset='0.651' stopColor='#F2D33A' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_14927_161557'
          x1='118.355'
          y1='98.3327'
          x2='126.235'
          y2='98.3327'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.3' stopColor='#1A7317' />
          <stop offset='0.65' stopColor='#6DD669' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_14927_161557'
          x1='118.355'
          y1='111.667'
          x2='126.235'
          y2='111.667'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.3' stopColor='#0670AD' />
          <stop offset='0.65' stopColor='#08B7E8' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_14927_161557'
          x1='118.355'
          y1='125'
          x2='126.2'
          y2='125'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.301' stopColor='#5829A1' />
          <stop offset='0.65' stopColor='#8F4FE8' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_14927_161557'
          x1='118.355'
          y1='74.1663'
          x2='126.235'
          y2='74.1663'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.302' stopColor='#A63F62' />
          <stop offset='0.65' stopColor='#E86B97' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_14927_161557'
          x1='81.8867'
          y1='67.6397'
          x2='112.275'
          y2='130.623'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FC7D5B' />
          <stop offset='0.091' stopColor='#F87855' />
          <stop offset='0.683' stopColor='#DF5731' />
          <stop offset='1' stopColor='#D64B24' />
        </linearGradient>
        <radialGradient
          id='paint6_radial_14927_161557'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(98.3333 89.9993) scale(8.33333)'
        >
          <stop offset='0.486' />
          <stop offset='1' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
}
