import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const REMOVE_COACH_INFO = gql`
  mutation removeCoachInfo($conversationId: ShortId!) @api(name: "dash") {
    removeCoachInfo(conversationId: $conversationId) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
