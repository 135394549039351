function NoMessages({ className }: { className?: string }) {
  return (
    <svg
      width='57'
      height='57'
      viewBox='0 0 57 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7853 36.271C23.7642 37.373 21.4462 38 18.981 38C11.1173 38 4.73096 31.6136 4.73096 23.75C4.73096 15.8864 11.1173 9.5 18.981 9.5C26.847 9.5 33.231 15.8864 33.231 23.75C33.231 27.4265 31.8345 30.7824 29.545 33.3118L39.6791 43.4459C40.6053 44.3721 40.6053 45.8779 39.6791 46.8041C38.7528 47.7327 37.2471 47.7327 36.3208 46.8041L25.7853 36.271ZM18.981 14.25C24.225 14.25 28.481 18.506 28.481 23.75C28.481 28.994 24.225 33.25 18.981 33.25C13.7393 33.25 9.48096 28.994 9.48096 23.75C9.48096 18.506 13.7393 14.25 18.981 14.25ZM49.875 28.5H45.125C43.814 28.5 42.75 29.564 42.75 30.875C42.75 32.186 43.814 33.25 45.125 33.25H49.875C51.186 33.25 52.25 32.186 52.25 30.875C52.25 29.564 51.186 28.5 49.875 28.5ZM49.875 19H40.375C39.064 19 38 20.064 38 21.375C38 22.686 39.064 23.75 40.375 23.75H49.875C51.186 23.75 52.25 22.686 52.25 21.375C52.25 20.064 51.186 19 49.875 19ZM49.875 9.5H38C36.689 9.5 35.625 10.564 35.625 11.875C35.625 13.186 36.689 14.25 38 14.25H49.875C51.186 14.25 52.25 13.186 52.25 11.875C52.25 10.564 51.186 9.5 49.875 9.5Z'
        fill='#B7B3C1'
      />
    </svg>
  );
}

export default NoMessages;
