import styled from 'styled-components';

export const FormContainer = styled.form`
  .ant-input {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none;
    border: none;
    resize: none;
    height: 174px;
    color: #6e6681;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    border-radius: 0px;
    &::placeholder {
      color: #6e6681;
    }
  }
`;
