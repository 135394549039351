import { useState } from 'react';
import { produce } from 'immer';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useChatBot } from 'hooks/useChatBot';

import { Query } from 'generated/graphql';
import { UserStorageData } from 'types/user';
import { ToastMessage } from 'components/atoms';
import useLocalStorage from 'hooks/useLocalStorage';
import { CHANGE_EMAIL, CHANGE_PROFILE_NAME, CHANGE_PASSWORD, GET_USER_PROFILE } from 'graphql/user';
import apolloCache from 'services/apollo/cache';

export function useProfileMutation(props?: any) {
  const { t } = useTranslation();
  const { updateCrispEmail, updateCrispUserName } = useChatBot();
  const [user, setUser] = useLocalStorage('user', '');
  const [newEmail, setNewEmail] = useState<string>('');

  /**
   ************* change email Mutation *************
   */
  const [changeEmailMutation] = useMutation(CHANGE_EMAIL, {
    onCompleted: (response: any) => {
      const error = response?.changeEmail?.error;
      if (!error) {
        updateCrispEmail(newEmail);
        ToastMessage({
          content: t('changeEmailSuccess', 'Your email is changed'),
          type: 'success',
        });
        props.callback?.();
        // update user data in local storage
        const userData = produce(user, (draft: UserStorageData) => {
          if (draft?.details?.userProfile?.email) draft.details.userProfile.email = newEmail;
        });
        setUser(userData);
        return;
      }
      ToastMessage({
        content: error?.message || t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
    onError: (err: any) => {
      console.error(err);
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });
  const changeEmail = async (payload: any) => {
    setNewEmail(payload.email);
    await changeEmailMutation({
      variables: {
        data: { ...payload },
      },
      update(cache, { data: changeEmailData }) {
        if (changeEmailData?.changeEmail?.data) {
          const data: Pick<Query, 'profile'> | null = cache.readQuery({
            query: GET_USER_PROFILE,
          });
          const newData = produce(data, (draft: Pick<Query, 'profile'> | null) => {
            if (draft?.profile?.data?.email) draft.profile.data.email = payload.email;
          });
          cache.writeQuery({
            query: GET_USER_PROFILE,
            data: newData,
          });
        }
      },
    });
  };

  /**
   ************* change Fullname Mutation *************
   */
  const [updateProfileName] = useMutation(CHANGE_PROFILE_NAME, {
    onCompleted: (response: any) => {
      const { error, data: userData } = response?.changeProfileNames;
      if (error === null) {
        updateCrispUserName(`${userData.firstname} ${userData.lastname}`);
        ToastMessage({
          content: t('toast.fullNameChangeSuccess', 'Full Name changed successfully.'),
          type: 'success',
        });
        props.callback?.();
        apolloCache.modify({
          fields: {
            allWorkspaceMembers: () => {},
          },
        });
        return;
      }
      ToastMessage({
        content: error?.message || t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
    onError: (err: any) => {
      console.error(err);
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      props.callback?.();
    },
  });
  const changeFullname = async (payload: any) => {
    await updateProfileName({
      variables: {
        data: {
          ...payload,
        },
      },
    });
  };

  /**
   ************* change Password Mutation *************
   */
  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (response: any) => {
      const { error } = response?.changePassword;
      if (error === null) {
        ToastMessage({
          content: t('toast.numberSettingsUpdateSuccess', 'Settings updated successfully.'),
          type: 'success',
        });
        props.callback?.();
        return;
      }
      ToastMessage({
        content: error?.message || t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
    onError: (err: any) => {
      console.error(err);
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
      props.callback?.();
    },
  });
  const changePassword = async (payload: any) => {
    await changePasswordMutation({
      variables: {
        data: {
          ...payload,
        },
      },
    });
  };
  return {
    changeEmail,
    changeFullname,
    changePassword,
  };
}
