import { useEffect, useMemo, useContext } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { initialWorkspaceMemberParams } from 'components/pages/settings/members/hooks/useMembersQVars';
import { AuthContext } from 'contexts/auth/AuthContext';
import { userOnlineStatus } from 'services/apollo/reactiveVars';
import sendPingMessage from 'components/utils/sendPingMessage';
import { useChatBot } from 'hooks/useChatBot';

export function useMembersQuery() {
  const { updateMemberDetail } = useChatBot();
  const { loggedInMemberId, accessToken } = useContext(AuthContext);

  const [loadWorkspaceMembers, { data, loading }] = useLazyQuery(WORKSPACE_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    loadWorkspaceMembers({
      variables: {
        pageParams: initialWorkspaceMemberParams,
      },
    });
  }, [loadWorkspaceMembers]);

  const members = useMemo(() => data?.allWorkspaceMembers?.data?.edges ?? [], [data]);
  const isUserOnline = useReactiveVar(userOnlineStatus);
  useEffect(() => {
    if (data?.allWorkspaceMembers?.data?.edges) {
      sendPingMessage({
        memberList: data?.allWorkspaceMembers?.data?.edges ?? [],
        loggedInMemberId,
        isUserOnline,
        accessToken,
      });
      updateMemberDetail(data?.allWorkspaceMembers?.data?.edges?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    members,
    loading,
  };
}
