/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import 'react-phone-input-2/lib/style.css';
import { Control, Controller } from 'react-hook-form';
import PhoneField, { PhoneInputProps } from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';

import { Label, Error } from 'components/atoms';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import useLocationFromIP from 'hooks/useLocationFromIP';
import { InputFieldWrapper, PhoneFieldStyles } from './styled';

interface PhoneProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'id'>,
    PhoneInputProps {
  label?: React.ReactNode;
  labelWeight?: 'normal' | 'medium';
  id?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  control: Control<any>;
  errors?: any;
  isEditable?: boolean;
  autoFocus?: boolean;
  inputClass?: string;
  buttonClass?: string;
}

// const PhoneInput = React.forwardRef<HTMLInputElement, PhoneProps>((props: PhoneProps, ref) => {
const PhoneInput: React.FC<PhoneProps> = props => {
  const { countryCode } = useLocationFromIP();
  const {
    label,
    labelWeight,
    id,
    name,
    placeholder,
    required = false,
    errors,
    value,
    control,
    onChange,
    onClick,
    isEditable,
    autoFocus,
    ...rest
  } = props;
  const { t } = useTranslation();
  const hasErrors = errors?.[name];
  return (
    <>
      <InputFieldWrapper>
        <div className='flex flex-col' data-cy='phone-no-field-wrap'>
          {label && (
            <Label
              data-cy='contacts-add-form-number-label-field'
              id={id || name}
              required={required}
              fontWeight={labelWeight}
            >
              {label}
            </Label>
          )}
          <Controller
            data-cy='contacts-add-form-flag-control'
            name={name}
            control={control}
            render={({ field }) => {
              const { flagUrl, country, phoneFormatIntl } =
                (field.value && parsePhoneNumber(field.value)) ?? {};
              return isEditable === false ? (
                <div
                  className='phone-number-wrapper'
                  data-cy='contacts-add-form-flag-element-wrapper'
                >
                  {flagUrl && (
                    <div data-cy='contacts-add-form-flag-element' className='flag-wrapper'>
                      <img
                        data-cy='contacts-add-form-flag-element-img'
                        className='country-flag'
                        src={flagUrl}
                        alt={country}
                        title={country}
                      />
                    </div>
                  )}
                  <p
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      if (onClick) {
                        onClick(event, {});
                      }
                    }}
                  >
                    {phoneFormatIntl || field?.value || placeholder}
                  </p>
                </div>
              ) : (
                <PhoneFieldStyles>
                  <PhoneField
                    data-cy='contacts-add-form-phone-element'
                    inputClass={`krisp-form-control ${hasErrors ? 'field-error' : ''}`}
                    buttonClass='krisp-phone-input-btn'
                    placeholder={placeholder}
                    autoFormat={false}
                    {...field}
                    onChange={(val: string, data: any, evt: any, formattedValue: string) => {
                      if (onChange) {
                        onChange(val, data, evt, formattedValue);
                        field.onChange(val, data, evt, formattedValue);
                      } else {
                        field.onChange(val, data, evt, formattedValue);
                      }
                    }}
                    country={(country || countryCode) ?? 'us'}
                    {...rest}
                    value={field.value}
                    inputProps={{
                      name,
                      autoFocus,
                    }}
                  />
                </PhoneFieldStyles>
              );
            }}
          />
          {errors?.[name] && <Error>{t(`constants:${errors[name].message}`)}</Error>}
        </div>
      </InputFieldWrapper>
    </>
  );
};

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
