import styled from 'styled-components';

export const KeypadContainer = styled.div`
  padding-top: 28px;
  .ant-input:not(textarea) {
    border: none;
    background: none;
    color: var(--Text-600-Headers, #1a181b);
    font-size: 16px;
    font-weight: 420;
    line-height: 24px;
    height: 84px;
    text-align: center;
    ::placeholder {
      color: var(--Text-300-Component-2, #66616b);
    }
    &:focus,
    &:hover {
      border: none;
    }
  }
`;
