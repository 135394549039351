import React from 'react';
import Styled from 'styled-components';
import { Button } from 'components/atoms';
import { DisabledTooltip } from '../disabled-tooltip';

interface InformationWrapperProps {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
}

const InformationWrapper = Styled.div<InformationWrapperProps>`
  display:flex;
  justify-content:space-between;
  margin-top:${({ top }) => `${top || 0}px`};
  margin-left:${({ left }) => `${left || 0}px`};
  margin-bottom:${({ bottom }) => `${bottom || 0}px`};
  margin-right:${({ right }) => `${right || 0}px`};

  .title{
    font-weight: ${({ theme }) => theme.text.weight.bold};
    font-size: ${({ theme }) => theme.text.size.body.text.caption};
    line-height: 12px;
    color: ${({ theme }) => theme.colors.text.t7};
  }
  .info{
    font-size: ${({ theme }) => theme.text.size.body.text.primary};
    line-height: 24px;
    margin-top:2px;
    color:${({ theme }) => theme.colors.text.t6};
  }

  .ant-btn{
    padding:0 14px;
    height:36px;
    margin: auto 0 auto auto;
    box-shadow:none;
    svg{
      margin-right: 6px;
    }
    &:hover{
      background:#ECE6F2;
    }
  }

`;

interface InformationProps {
  title: string;
  info: string;
  icon?: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
  capitalizeInfo?: boolean;
  hasAccess?: boolean;
}
const Information: React.FC<InformationProps> = ({
  title,
  info,
  capitalizeInfo,
  icon,
  buttonText,
  onClick,
  hasAccess,
  ...props
}) => {
  return (
    <InformationWrapper {...props}>
      <div>
        <p
          data-cy='workspace-profile-title-info'
          className='text-gray-500 text-sm leading-none font-medium'
        >
          {title}
        </p>
        <p
          data-cy='workspace-profile-title-data-info'
          className={`mt-1 text-gray-700 text-base leading-normal font-normal  ${
            capitalizeInfo && 'truncate capitalize max-w-200 md:max-w-300'
          }`}
        >
          {info}
        </p>
      </div>
      {buttonText && (
        <>
          {hasAccess ? (
            <Button data-cy='workspace-profile-edit' variant='tertiary' onClick={onClick}>
              {icon && icon}
              {buttonText}
            </Button>
          ) : (
            <DisabledTooltip>
              <Button data-cy='workspace-profile-edit' variant='tertiary'>
                {icon && icon}
                {buttonText}
              </Button>
            </DisabledTooltip>
          )}
        </>
      )}
    </InformationWrapper>
  );
};

export default Information;
