import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const VOICE_TOKEN = gql`
  query getVoiceToken {
    getVoiceToken {
      status
      error {
        ...Error
      }
      data {
        voiceToken
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
