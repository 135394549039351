/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
export const initPartnerStack = () => {
  const gs: any = document.createElement('script');
  gs.src = 'https://snippet.growsumo.com/growsumo.min.js';
  gs.type = 'text/javascript';
  gs.async = 'true';
  gs.onload = gs.onreadystatechange = function () {
    const rs = this.readyState;
    if (rs && rs !== 'complete' && rs !== 'loaded') {
      return;
    }
    try {
      (window as any).growsumo._initialize(process.env.REACT_APP_PS_KEY);
      if (typeof (window as any).growsumoInit === 'function') {
        (window as any).growsumoInit();
        console.log('initialize growsumo');
      }
    } catch (e) {
      console.error('error', e);
    }
  };
  const s = document.getElementsByTagName('script')[0];
  s?.parentNode?.insertBefore?.(gs, s);
};
