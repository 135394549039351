import { gql } from '@apollo/client';

export const FETCH_AUTH_DATA = gql`
  query permission {
    permissions {
      status
      error {
        code
      }
      data
    }
  }
`;

export const FETCH_SUPPORT_AUTH_DATA = gql`
  query supportPermissions {
    supportPermissions {
      status
      error {
        code
      }
      data
    }
  }
`;
