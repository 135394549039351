/* eslint-disable consistent-return */
import { Route, useHistory } from 'react-router-dom';

import { MainLayout } from 'components/atoms';
import { PageNotFoundError } from 'components/pages/errors/Errors';

const ErrorRoute = ({ ...rest }) => {
  const history = useHistory();
  const goToHome = () => history.push('/');

  return (
    <>
      <Route {...rest}>
        <MainLayout>
          <div style={{ minWidth: 'inherit', width: 'inherit' }}>
            <PageNotFoundError action={goToHome} />
          </div>
        </MainLayout>
      </Route>
    </>
  );
};

export default ErrorRoute;
