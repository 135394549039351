import { useContext } from 'react';

import { AppContext } from 'contexts/app/AppContext';

export default function useAppContext() {
  const appState = useContext(AppContext);
  if (!appState) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return appState;
}
