interface ExportWorkspaceDataprops {
  title?: string;
  description?: string;
  onAction?: () => void;
  btnText?: string;
}
const ExportWorkspace = ({ title, description, onAction, btnText }: ExportWorkspaceDataprops) => {
  return (
    <div className='flex justify-between items-center border border-primary-100 box-border rounded-10 mt-5 px-6 py-6.5 '>
      <div className='w-3/5'>
        <h6
          data-cy='export-workspace-title'
          className='text-gray-700 text-lg font-bold leading-snug'
        >
          {title}
        </h6>
        <p className='text-gray text-15 font-normal leading-normal mt-3.5'>{description}</p>
      </div>
      <div>
        <button
          data-cy='export-workspace-button'
          type='button'
          className='bg-primary mt-4 px-3.5 py-3 rounded-md'
          onClick={onAction}
        >
          <h6 className='text-white text-sm font-semibold leading-4'>{btnText}</h6>
        </button>
      </div>
    </div>
  );
};

export default ExportWorkspace;
