import { memo, useEffect } from 'react';

import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { ToastMessage } from 'components/atoms';
import useAppContext from 'hooks/useAppContext';

import Dialer from '../../dialer';
import CallActive from '../../call-active';
import IncomingCall from '../../call-incoming';
import { ActiveCallWidgetProvider } from '../../context/ActiveCallWidgetContext';
import { DrawerBannerWrapper } from '../../DrawerBannerWrapper';
import useCallWidgetNavContext from '../../hooks/useCallWidgetNavContext';
import { WIDGET_NAV_SCREENS } from '../../constants';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import { useRestrictedEventInfo } from '../../dialer/useRestrictedEventInfo';
import RestrictedScreens from '../../dialer/RestrictedScreens';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

function CallWidget() {
  const { CALL_WIDGET } = WIDGET_NAV_SCREENS;
  const { unSavedCallWidgetAction } = useAppContext();
  const {
    state: {
      showDialer,
      direction,
      status,
      callInProgress,
      salesDialerWidget,
      callStatusMessage,
      channelId,
    },
  } = useTwilioContext();

  const { selectedNavScreen } = useCallWidgetNavContext();
  const { closeDrawer } = useCallWidgetDrawerContext();
  const { handleChannelSelect } = useCallWidgetContext();

  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_DISCONNECTED)
      ToastMessage({
        content: callStatusMessage,
        type: 'danger',
      });
  }, [status, callStatusMessage]);

  useEffect(() => {
    if (selectedNavScreen !== CALL_WIDGET) {
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNavScreen]);

  useEffect(() => {
    // Gets incoming call when call is ended and has unsaved call actions
    if (
      direction === 'Incoming' &&
      status === CALL_WIDGET_STATUS.INITIATED &&
      !salesDialerWidget &&
      unSavedCallWidgetAction
    ) {
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const isIncomingCall =
    direction === 'Incoming' &&
    !callInProgress &&
    !salesDialerWidget &&
    status === CALL_WIDGET_STATUS.INITIATED;
  const activeCallClassName = isIncomingCall ? 'pb-3.5' : 'content-end ';

  const { restrictedEventInfo } = useRestrictedEventInfo();

  useEffect(() => {
    if (channelId) {
      handleChannelSelect?.(channelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  if (restrictedEventInfo) return <RestrictedScreens data={restrictedEventInfo} />;

  return (
    <>
      {showDialer && (
        <DrawerBannerWrapper>
          <Dialer />
        </DrawerBannerWrapper>
      )}

      {!showDialer && direction && (
        <ActiveCallWidgetProvider>
          <DrawerBannerWrapper className={activeCallClassName}>
            {isIncomingCall ? <IncomingCall /> : <CallActive />}
          </DrawerBannerWrapper>
        </ActiveCallWidgetProvider>
      )}
    </>
  );
}

export default memo(CallWidget);
