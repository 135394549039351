import styled from 'styled-components';
import { Badge, Popover, Collapse, Menu } from 'antd';

export const SidebarMenu = styled(Menu)`
  &.ant-menu {
    background: none;
    .ant-menu-item {
      ::after {
        display: none;
      }
      a {
        width: 100%;
      }
    }
    .ant-menu-submenu-title {
      padding: 8px 12px !important;
    }
    .ant-menu-sub {
      background: none;

      > .ant-menu-item {
        padding-left: 24px !important;
        &.ant-menu-item-selected {
          background: none;
        }
      }
    }
  }
`;

export const SidebarTitle = styled.div`
  font-weight: ${({ theme }) => theme.text.weight.bold};
  font-size: ${({ theme }) => theme.text.size.body.text.secondary};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.t9};
  margin: 1px 0 30px 18px;
`;

export const BadgeCount = styled(Badge)`
  margin-left: auto;
  .ant-badge-count {
    background: none;
    color: ${({ theme }) => theme.colors.text.t6};
    border: 1px solid ${({ theme }) => theme.colors.text.t3};
    font-family: ${({ theme }) => theme.text.font.heading};
    font-weight: ${({ theme }) => theme.text.weight.medium};
    font-size: ${({ theme }) => theme.text.size.body.text.caption};
    line-height: 14px;
    border-radius: 10px;
    padding: 2px 5px;
    box-shadow: none;
    min-width: 32px;
    .menu--active & {
      background: ${({ theme }) => theme.colors.text.t0};
      color: ${({ theme }) => theme.colors.primary.default};
      border-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;
export const LogoutPopover = styled(Popover)`
  .logout-container {
    left: 46px !important;
  }
  .ant-popover-inner {
    padding: 24px;
  }
  .ant-popover-title {
    padding: 0 0 16px 0;
    min-width: fit-content !important;
  }
  .ant-popover-inner-content {
    padding: 16px 0 0;
  }
  .header-text {
    font-weight: ${({ theme }) => theme.text.weight.bold};
    font-size: ${({ theme }) => theme.text.size.body.text.secondary};
    line-height: 16px;
    color: ${({ theme }) => theme.colors.text.t9};
    padding-bottom: 4px;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .sub-header-text {
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    font-size: ${({ theme }) => theme.text.size.body.text.caption};
    line-height: 12px;
    color: ${({ theme }) => theme.colors.info.default};
  }
  .ant-badge {
    display: block;
    line-height: 14px;
    &.badge-custom:first-child {
      margin-bottom: 14px;
    }
  }
  .badge-cursor {
    cursor: pointer;
  }
  .ant-badge-status-dot {
    height: 12px;
    width: 12px;
    background: ${({ theme }) => theme.colors.text.t4};
  }
  .ant-badge-status-text {
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    line-height: 12px;
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.text.t4};
  }
  .ant-badge-status.active {
    .ant-badge-status-dot {
      background: ${({ theme }) => theme.colors.success.default};
    }
    .ant-badge-status-text {
      color: ${({ theme }) => theme.colors.text.t9} !important;
    }
  }
  .logout-icon {
    margin-left: 24px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text.t4};
    font-size: 20px;
  }
`;

export const SupportNavCollapse = styled(Collapse)`
  &.ant-collapse {
    margin-bottom: 2px;
    .ant-collapse-header {
      padding: 0 !important;
      margin: 0;
      .ant-collapse-arrow.arrow {
        left: auto;
        top: 10px;
        right: 9px;
        font-size: 20px;
        &.arrow-up {
          transform: rotate(180deg);
        }
      }
    }
    .ant-collapse-content {
      border-left: 1px solid ${({ theme }) => theme.colors.text.t2};
      border-radius: 0px;
      padding-left: 16px;
      margin-left: 14px;
      .ant-collapse-content-box {
        padding-top: 2px;
        > a {
          & + a {
            display: block;
            margin-top: 2px;
          }
        }
      }
    }
  }
`;
