import * as path from 'constants/routes';
import { roles } from 'constants/roles';
import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';

export type MenuItem = {
  key: string;
  label?: string;
  isProtected?: boolean;
  hidden?: boolean;
};

export type Sidebar = {
  group: string;
  roleList: number[];
  menus: MenuItem[];
};

const DEFAULT_WORKSPACE_MENUS = [
  {
    key: path.WORKSPACE,
    label: 'Overview',
    isProtected: true,
  },
  {
    key: path.MEMBERS,
    label: 'Members',
  },
  {
    key: path.NUMBERS,
    label: ENABLE_NON_VOIP_FEATURE ? 'VOIP Numbers' : 'My numbers',
  },
  {
    key: path.TEAM,
    label: 'Teams',
    isProtected: true,
  },
  {
    key: path.TAGS,
    label: 'Contact Tags',
  },
  {
    key: path.BLOCK,
    label: 'Blocklist',
  },
];

const WORKSPACE_MENU_WITH_VOIP = [
  ...DEFAULT_WORKSPACE_MENUS.slice(0, 3),
  {
    key: path.NON_VOIP_NUMBERS,
    label: 'Non-VOIP Numbers',
  },
  ...DEFAULT_WORKSPACE_MENUS.slice(3),
];

const BILLING_MENU = [
  {
    key: path.CREDIT,
    label: 'Credit',
  },
  {
    key: path?.PLAN_SUBS,
    label: 'Plan Subscription',
  },
];
const nonVoipNumberSubscriptionMenu = {
  key: path?.NONVOIP_SUBS,
  label: 'Non-Voip Subscription',
};

export const sidebar: Sidebar[] = [
  {
    group: 'USER SETTINGS',
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    menus: [
      {
        key: path.PROFILE,
        label: 'My Account',
      },
    ],
  },
  {
    group: 'WORKSPACE',
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    menus: ENABLE_NON_VOIP_FEATURE ? WORKSPACE_MENU_WITH_VOIP : DEFAULT_WORKSPACE_MENUS,
  },
  {
    group: 'BILLING',
    roleList: [roles.OWNER, roles.ADMIN],
    menus: ENABLE_NON_VOIP_FEATURE
      ? [...BILLING_MENU, nonVoipNumberSubscriptionMenu]
      : BILLING_MENU,
  },
  {
    group: 'APP SETTINGS',
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    menus: [
      {
        key: path.DEVICES,
        label: 'Mic and Audio',
      },
      {
        key: path.NOTIFICATION,
        label: 'Notifications',
      },
      {
        key: path.REPORTS,
        label: 'Reports',
        isProtected: true,
        hidden: process.env.REACT_APP_ENABLE_USAGE_INSIGTS_REPORTS !== 'true',
      },
      {
        key: path.INTEGRATION,
        label: 'Integrations',
      },
      {
        key: path.DEVELOPER,
        label: 'Developer',
      },
      {
        key: path.LANGUAGE_TIMEZONES,
        label: 'Time & Language',
      },
    ],
  },
];
