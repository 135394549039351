import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import useDebounce from 'hooks/useDebounce';
import { SearchInput } from 'components/atoms';
import { queryParamVars, useQVars } from './useAgentVars';

export default function Search() {
  const WAIT_TIME_IN_MS = 500;
  const { t } = useTranslation();
  const { setSearch } = useQVars(queryParamVars);
  const [searchedQuery, setSearchedQuery] = useState('');
  const debouncedQuery = useDebounce<string>(searchedQuery, WAIT_TIME_IN_MS);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedQuery(e.target.value);
  };

  React.useEffect(() => {
    if (debouncedQuery) {
      setSearch({
        columns: ['firstname', 'lastname'],
        value: debouncedQuery,
      });
      return;
    }
    setSearch(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  return (
    <>
      <SearchInput
        name='search agent'
        placeholder={t('searchForMember.placeholder', 'Search for member')}
        prefix={<FiSearch />}
        className='search-agent'
        onChange={handleSearch}
        value={searchedQuery}
      />
    </>
  );
}
