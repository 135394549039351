import * as yup from 'yup';
import { pattern } from 'constants/regex';
import { MIN_LENGTH } from './common';

const TITLE_MAX_LENGTH = 44;
const DESCRIPTION_MAX_LENGTH = 200;

export const teamInputs = {
  title: yup
    .string()
    .trim()
    .required('Required')
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(TITLE_MAX_LENGTH, `Shouldn't exceed ${TITLE_MAX_LENGTH} characters`),
  description: yup
    .string()
    .max(DESCRIPTION_MAX_LENGTH, `Shouldn't exceed ${DESCRIPTION_MAX_LENGTH} characters`),
};
