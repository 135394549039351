import { CSSProperties } from 'react';
import { Badge } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Maybe } from 'graphql/jsutils/Maybe';

import UserAvatar from './UserAvatar';
import { ManIcon } from '../icon';

export const BadgeStyled = styled(Badge)<any>`
  line-height: 14px;
  .ant-avatar-square {
    border-radius: 8px;
  }
  .ant-badge-dot {
    width: ${props => props.dotsize}px;
    height: ${props => props.dotsize}px;
    box-shadow: 0 0 0 ${props => props.boxshadowwidth ?? '1.5'}px ${props => props.boxshadowcolor};
  }
  &.cancel-subscription {
    .ant-badge-dot {
      visibility: hidden;
    }
    .ant-avatar {
      mix-blend-mode: luminosity;
      background-color: ${({ theme }) => theme.colors.text.t3};
    }
  }
`;

interface BadgedAvatarProps {
  isOnline?: boolean | Maybe<boolean>;
  src?: string | Maybe<string>;
  avatarSize?: number;
  avatarShape?: 'circle' | 'square';
  avatarStyles?: CSSProperties;
  dotSize?: number;
  dotBoxShadowColor?: string;
  dotBoxShadowWidth?: number;
  badgeOffset?: number[];
  isSubscriptionExpired?: boolean;
}

const BadgedAvatar = ({
  isOnline = false,
  src = '',
  avatarSize = 24,
  avatarStyles,
  dotSize = 6,
  dotBoxShadowColor,
  dotBoxShadowWidth,
  badgeOffset = [-4, 20],
  isSubscriptionExpired = false,
  avatarShape = 'square',
}: BadgedAvatarProps) => {
  const theme = useTheme();
  const boxShadowColor = dotBoxShadowColor ?? theme.colors.primary.p0;
  return (
    <BadgeStyled
      color={isOnline ? 'green' : theme.colors.text.t3}
      dot={true}
      offset={badgeOffset}
      dotsize={dotSize}
      boxshadowcolor={boxShadowColor}
      boxshadowwidth={dotBoxShadowWidth}
      className={` ${isSubscriptionExpired ? 'cancel-subscription' : ''}`}
    >
      <UserAvatar
        size={avatarSize}
        shape={avatarShape}
        src={src}
        icon={<ManIcon id='sidebar-member-avatar' bgColor='#b099c9' color='#E7E6EB' />}
        style={{ minWidth: avatarSize, ...avatarStyles }}
      />
    </BadgeStyled>
  );
};

export default BadgedAvatar;
