const File = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#E7E6EB' />
    <g clipPath='url(#clip0_24390_204516)'>
      <path d='M25.4006 8H10.5996V32H29.4001V11.9995L25.4006 8Z' fill='white' />
      <path d='M26.0196 9.99979H10.5996V8H25.4006L26.0196 9.99979Z' fill='#390179' />
      <path d='M25.4004 11.9995H29.3999L25.4004 8V11.9995Z' fill='#D1D3D3' />
      <rect x='13' y='17' width='3.25' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='17.0625' y='17' width='4.875' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='22.75' y='17' width='3.25' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='13' y='19.8125' width='3.25' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='17.0625' y='19.8125' width='3.25' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='21.125' y='19.8125' width='3.25' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='13' y='22.625' width='4.875' height='0.8125' rx='0.40625' fill='#D9D9D9' />
      <rect x='18.6875' y='22.625' width='3.25' height='0.8125' rx='0.40625' fill='#D9D9D9' />
    </g>
    <defs>
      <clipPath id='clip0_24390_204516'>
        <rect width='24' height='24' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>
);

export default File;
