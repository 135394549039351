import { ReactElement } from 'react';

interface EmptyListProps {
  vector: ReactElement;
  title: string;
  description: string;
  wrapperClassName?: string;
  children?: any;
}

const EmptyList = ({
  vector,
  title,
  description,
  wrapperClassName = '',
  children,
}: EmptyListProps) => (
  <div className={`m-auto p-6 ${wrapperClassName}`} data-cy='empty-b-wrap'>
    <div className='mx-auto w-50 mb-8'>{vector}</div>
    <div className='text-center'>
      <h4 className='text-2xl text-gray-700 leading-9 font-bold mb-3' data-cy='empty-b-title'>
        {title}
      </h4>
      <p className='text-base leading-6 text-gray' data-cy='empty-b-desc'>
        {description}
      </p>
    </div>
    {children}
  </div>
);

export default EmptyList;
