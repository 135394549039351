import { useContext } from 'react';
import { TouchToneContext } from './TouchToneProvider';

interface ITouchToneContext {
  play?: (tone: number[]) => void;
}

export default function useTouchToneContext() {
  const widgetState = useContext<ITouchToneContext>(TouchToneContext);
  if (!widgetState) {
    throw new Error('useTouchToneContext must be used within a TouchToneProvider');
  }
  return widgetState;
}
