import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { TEAM_NODE, TEAM_MEMBERS_NODE } from '../fragments';

export const ADD_TEAM = gql`
  mutation addTeam($data: TeamInputData!) {
    addTeam(data: $data) {
      status
      data {
        ...TeamFields
      }
      error {
        ...Error
      }
    }
  }
  ${TEAM_NODE}
  ${ERROR_FRAGMENT}
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: ShortId!, $data: UpdateTeamInputData!) {
    updateTeam(id: $id, data: $data) {
      status
      data {
        ...TeamFields
      }
      error {
        ...Error
      }
    }
  }
  ${TEAM_NODE}
  ${ERROR_FRAGMENT}
`;
export const REMOVE_TEAM = gql`
  mutation removeTeam($id: ShortId!, $data: RemoveTeamInputData!) {
    removeTeam(data: $data, id: $id) {
      status
      error {
        ...Error
      }
      data {
        id
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const REMOVE_TEAM_MEMBER = gql`
  mutation removeMember($id: ShortId!, $data: RemoveTeammemberInputData!) {
    removeMember(id: $id, data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        teamMembers {
          ...TeamMembersNode
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TEAM_MEMBERS_NODE}
`;
