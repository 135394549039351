import { gql } from '@apollo/client';

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfile on UserProfile {
    userId
    firstname
    lastname
    email
    status
    profilePicture
    defaultLanguage
    defaultTimezone
    defaultWorkspace
    stayOnline
    dndEnabled
    dndOn
    dndEndtime
    dndDuration
    useCases
    accountType
    signupComplete
    business {
      id
      name
      role
      businessType
      noOfUsers
    }
  }
`;
