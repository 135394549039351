export default function KeypadIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.25 7.05556C14.8176 7.05556 15.2777 6.5954 15.2777 6.02777C15.2777 5.46016 14.8176 5 14.25 5C13.6823 5 13.2222 5.46016 13.2222 6.02777C13.2222 6.5954 13.6823 7.05556 14.25 7.05556Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.4721 7.05556C23.0397 7.05556 23.4999 6.5954 23.4999 6.02777C23.4999 5.46016 23.0397 5 22.4721 5C21.9044 5 21.4443 5.46016 21.4443 6.02777C21.4443 6.5954 21.9044 7.05556 22.4721 7.05556Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.02778 7.05556C6.59542 7.05556 7.05556 6.5954 7.05556 6.02777C7.05556 5.46016 6.59542 5 6.02778 5C5.46015 5 5 5.46016 5 6.02777C5 6.5954 5.46015 7.05556 6.02778 7.05556Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 15.2782C14.8176 15.2782 15.2777 14.818 15.2777 14.2505C15.2777 13.6828 14.8176 13.2227 14.25 13.2227C13.6823 13.2227 13.2222 13.6828 13.2222 14.2505C13.2222 14.818 13.6823 15.2782 14.25 15.2782Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.4721 15.2782C23.0397 15.2782 23.4999 14.818 23.4999 14.2505C23.4999 13.6828 23.0397 13.2227 22.4721 13.2227C21.9044 13.2227 21.4443 13.6828 21.4443 14.2505C21.4443 14.818 21.9044 15.2782 22.4721 15.2782Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.02778 15.2782C6.59542 15.2782 7.05556 14.818 7.05556 14.2505C7.05556 13.6828 6.59542 13.2227 6.02778 13.2227C5.46015 13.2227 5 13.6828 5 14.2505C5 14.818 5.46015 15.2782 6.02778 15.2782Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 23.4999C14.8176 23.4999 15.2777 23.0397 15.2777 22.4722C15.2777 21.9045 14.8176 21.4443 14.25 21.4443C13.6823 21.4443 13.2222 21.9045 13.2222 22.4722C13.2222 23.0397 13.6823 23.4999 14.25 23.4999Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.4721 23.4999C23.0397 23.4999 23.4999 23.0397 23.4999 22.4722C23.4999 21.9045 23.0397 21.4443 22.4721 21.4443C21.9044 21.4443 21.4443 21.9045 21.4443 22.4722C21.4443 23.0397 21.9044 23.4999 22.4721 23.4999Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.02778 23.4999C6.59542 23.4999 7.05556 23.0397 7.05556 22.4722C7.05556 21.9045 6.59542 21.4443 6.02778 21.4443C5.46015 21.4443 5 21.9045 5 22.4722C5 23.0397 5.46015 23.4999 6.02778 23.4999Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
