import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';

import { ChevronDown, Multiply } from 'components/atoms/icon';
import { useTwilioContext } from 'lib/twilio';
import { ACTIONS, CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { useActiveWorkspaceQuery } from 'hooks/useActiveWorkspaceQuery';
import useLocalStorage from 'hooks/useLocalStorage';
import * as path from 'constants/routes';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';
import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';

import { Wrapper, ChannelSelectInput, ChannelDropdownHeader } from './Styles';
import { NumberCard } from './NumberCard';

import { WIDGET_SIDEBAR_WIDTH } from '../constants';
import useCallWidgetContext from '../hooks/useCallWidgetContext';
import { useCallWidgetDragger } from '../hooks/useCallWidgetDragger';

const getChannelOption = (channel: any) => {
  const option = channel || {};
  const { flagUrl = '', phoneFormatIntl = '' } = parsePhoneNumber(option?.number);
  return {
    value: option?.id,
    label: <NumberCard number={phoneFormatIntl} name={option?.name} flagUrl={flagUrl} />,
  };
};

const WidgetHeader = () => {
  const { cancelDraggableClassName } = useCallWidgetDragger();
  const { activeWorkspaceData, loadWorkspace } = useActiveWorkspaceQuery();
  const [activeWorkspaceId] = useLocalStorage('activeWorkspaceId', null);
  useEffect(() => {
    let isMounted = true;
    if (activeWorkspaceId && isMounted) loadWorkspace();

    return () => {
      isMounted = false;
    };
  }, [activeWorkspaceId, loadWorkspace]);

  const { plan } = activeWorkspaceData?.workspace?.data || {};

  const [isNumberSelectDropdownOpen, setIsNumberSelectDropdownOpen] = useState(false);
  const {
    dispatch,
    state: { showDialer, campaignStatus },
  } = useTwilioContext();

  const { PAUSED } = CALL_WIDGET_STATUS;

  const hideSelectNumber = !showDialer;

  const { channels, selectedChannel, handleChannelSelect } = useCallWidgetContext();
  const voipChannels = channels?.filter((channel: any) => channel?.phoneNumberType !== 'NonVoip');
  const channelOptions = (ENABLE_NON_VOIP_FEATURE ? voipChannels : channels)?.map((channel: any) =>
    getChannelOption(channel),
  );
  const hasChannelOptions = channelOptions?.length > 0;

  const handleClose = () => {
    dispatch({
      type: ACTIONS.CLOSE_PHONE_WIDGET,
      data: {},
    });
  };

  const history = useHistory();
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });
  const routeToCreditPage = () => {
    if (isDialerPage) {
      // TODO: we might not need this dialer page check logic as we have separated the crm dialer and web app dialer
      window.open(`${path.SETTINGS}${path.CREDIT}`, '_blank');
      return;
    }
    history.push(`${path.SETTINGS}${path.CREDIT}?from=dialer`);
  };

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Stop propagation to prevent other events
    setIsNumberSelectDropdownOpen(!isNumberSelectDropdownOpen); // Toggle dropdown visibility
  };

  const handleDropdownVisibleChange = (isVisible: boolean) => {
    // Prevent dropdown from opening when clicking on the input field
    if (!isVisible && isNumberSelectDropdownOpen) {
      setIsNumberSelectDropdownOpen(false);
    }
  };

  return (
    <Wrapper className='flex'>
      <div
        style={{ width: WIDGET_SIDEBAR_WIDTH }}
        className='flex justify-center items-center cursor-pointer flex-shrink-0'
      >
        {/* show close icon only if the voice call or campaign call is paused */}
        {(showDialer || (campaignStatus && [PAUSED].includes(campaignStatus))) && (
          <button type='button' onClick={handleClose}>
            <Multiply className='icon-with-bg' />
          </button>
        )}
      </div>
      <div className='border-l border-extra-widgetBorder w-full z-99999 bg-background-100'>
        <ChannelSelectInput
          className='select-dialing-number'
          name='select-dialing-number'
          onDropdownVisibleChange={handleDropdownVisibleChange}
          open={isNumberSelectDropdownOpen}
          dropdownRender={menu => (
            <>
              <ChannelDropdownHeader>
                <div>
                  <h3>Total Credit</h3>
                  <p>$ {plan?.currentCredit ?? ''} USD</p>
                </div>
                <button type='button' onClick={routeToCreditPage}>
                  Add Credit
                </button>
              </ChannelDropdownHeader>
              {!hideSelectNumber && (
                <>
                  <h2>Select Number</h2>
                  {menu}
                </>
              )}
            </>
          )}
          suffixIcon={
            <button
              type='button'
              className={`${cancelDraggableClassName} pointer-events-auto`}
              onClick={handleIconClick}
            >
              <ChevronDown
                className={`transition-all transform ${
                  isNumberSelectDropdownOpen ? 'rotate-180' : 'rotate-0'
                } icon-with-bg`}
              />
            </button>
          }
          options={channelOptions}
          value={hasChannelOptions ? selectedChannel?.id : undefined}
          listHeight={384}
          menuItemSelectedIcon={
            <FaCheckCircle className='relative -left-4' size={24} color='#007AFF' />
          }
          onChange={handleChannelSelect}
        />
      </div>
    </Wrapper>
  );
};

export default WidgetHeader;
