export default function CheckCircleFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 1.75C9.97276 1.75 7.99098 2.35115 6.30538 3.47743C4.61977 4.60372 3.30603 6.20456 2.53023 8.0775C1.75443 9.95044 1.55142 12.0114 1.94692 13.9997C2.34242 15.988 3.31867 17.8144 4.75215 19.2478C6.18564 20.6813 8.01202 21.6575 10.0003 22.053C11.9886 22.4485 14.0496 22.2456 15.9225 21.4698C17.7954 20.694 19.3963 19.3802 20.5225 17.6946C21.6488 16.009 22.25 14.0273 22.25 12C22.2474 9.28234 21.1666 6.67676 19.2449 4.75508C17.3232 2.83341 14.7177 1.75265 12 1.75ZM17.07 10.09L11.7 15.46C11.5177 15.6519 11.2959 15.8021 11.05 15.9C10.5526 16.0997 9.99739 16.0997 9.50002 15.9C9.25642 15.7977 9.03547 15.6482 8.84999 15.46L6.19002 12.8C6.09629 12.707 6.02186 12.5964 5.97109 12.4746C5.92032 12.3527 5.89418 12.222 5.89418 12.09C5.89418 11.958 5.92032 11.8273 5.97109 11.7054C6.02186 11.5836 6.09629 11.473 6.19002 11.38C6.37738 11.1938 6.63079 11.0892 6.89498 11.0892C7.15916 11.0892 7.41263 11.1938 7.59999 11.38L10.27 14.05L15.65 8.67999C15.7429 8.58626 15.8536 8.51187 15.9754 8.46111C16.0973 8.41034 16.228 8.3842 16.36 8.3842C16.492 8.3842 16.6227 8.41034 16.7446 8.46111C16.8664 8.51187 16.977 8.58626 17.07 8.67999C17.2473 8.86597 17.3462 9.11305 17.3462 9.37C17.3462 9.62694 17.2473 9.87402 17.07 10.06V10.09Z'
        fill='currentColor'
      />
    </svg>
  );
}
