import { css } from 'styled-components';

export const antInput = css`
  .ant-input,
  .ant-select {
    font-family: ${({ theme }) => theme.text.font.heading};
    font-weight: ${({ theme }) => theme.text.weight.regular};
    &::placeholder {
      color: ${({ theme }) => theme.colors.text.t5};
    }
    &:-webkit-autofill-selected {
      background: black;
    }
  }

  .ant-input {
    border: 1px solid #d7cce4;
  }
  textarea.ant-input {
    &:focus,
    &:hover {
      box-shadow: none;
    }
    &:focus {
      border-color: #390179;
      background: #fff;
    }
  }

  .team-form {
    .ant-input {
      padding: 14px 16px;
    }
  }
`;
