import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 48px 24px 36px;
  }
  .ant-modal-body {
    display: grid;
    justify-items: center;
  }
  h5 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #3d3358;
    margin: 24px 0 8px;
  }
  p {
    font-family: 'Heebo';
    text-align: center;
    font-size: 15px;
    line-height: 150%;
    color: #6e6681;
    max-width: 340px;
  }
  button.invite-more {
    border: 1px solid #1a63f4;
    color: #1a63f4;
    min-width: 154px;
  }
  button.done {
    background: #4c9610;
    min-width: 160px;
    &:hover {
      background: #4c9610;
    }
  }
`;

export const AddMemberForm = styled.form`
  input[name='email'].ant-input {
    padding-right: 118px; // same as select field width
  }

  .select-field-wrapper {
    position: absolute;
    bottom: 1px;
    right: 1px;
    .ant-select {
      .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        height: 41px;
        padding: 6px 13px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        min-width: 115px;
        text-align: right;
        .ant-select-selection-item {
          font-family: 'Heebo';
          font-weight: normal;
          font-size: 14px;
          line-height: 30px;
          color: #3d3358;
          padding-right: 24px;
        }
      }
      .ant-select-arrow {
        width: 18px;
        top: 45%;
        height: 18px;
        > svg {
          color: #564d6d;
        }
      }

      .ant-select-dropdown {
        min-width: 180px !important;
      }
    }
  }
  .field-error + .select-field-wrapper {
    bottom: 17px;
  }
  .ant-checkbox-wrapper {
    align-items: flex-start;
    .ant-checkbox + span {
      line-height: 150%;
      color: #6e6681;
    }
  }
`;
