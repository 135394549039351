import { css } from 'styled-components';

export const antBadge = css`
  .ant-badge-multiple-words {
    padding: 0;
  }
  .ant-badge-count {
    border-radius: 8px;
  }
  .ant-badge-status-green {
    background: ${({ theme }) => theme.colors.success.default};
  }
`;
