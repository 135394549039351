import { useEffect, useState } from 'react';
import { useChargebeeQuery } from 'lib/chargebee/hooks/useChargebeeQuery';
import { RiCloseLine } from 'react-icons/ri';

import { useChatBot } from 'hooks/useChatBot';
import * as S from './Styles';
import { useNonVoipPurchase } from './useNonVoipPurchase';
import { useRequestNonVoipNumbers } from './useRequestNonVoipNumbers';
import { SelectNumberType } from './SelectNumberType';
import { RequestForm } from './RequestForm';
import { SuccessForm } from './SuccessForm';

interface NumberSelectModalProps {
  isModalOpen: boolean;
  onModalCancel: () => void;
  shouldShowRequestForm?: boolean;
  showBackButtonInRequestForm?: boolean;
}

const NumberSelectModal = (props: NumberSelectModalProps) => {
  const {
    isModalOpen,
    onModalCancel,
    shouldShowRequestForm,
    showBackButtonInRequestForm = true,
  } = props;
  const [processing, setProcessing] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [paymentMethodID, setPaymentMethodID] = useState(null);
  const [visibleCreditModal, setVisibleCreditModal] = useState(() => false);
  const [visibleRequest, setVisibleRequest] = useState(() => false);
  const [current, setCurrent] = useState(0);
  const { requestNonVoipNumber } = useRequestNonVoipNumbers({
    setVisibleRequest,
    setCurrent,
    current,
    setProcessing,
    onModalCancel,
    isModalOpen,
  });
  const toggleCreditModal = () => setVisibleCreditModal(prevState => !prevState);
  const { openChat } = useChatBot();
  const { cards } = useChargebeeQuery();
  const amount = '75';

  useEffect(() => {
    if (cards?.length) {
      setPaymentMethodID(cards?.[0]?.id);
    }
  }, [cards]);

  useEffect(() => {
    if (!isModalOpen && !shouldShowRequestForm) {
      setCurrent(0);
      setProcessing(false);
      setConfirmation(false);
    }
  }, [isModalOpen, shouldShowRequestForm]);

  useEffect(() => {
    if (shouldShowRequestForm && isModalOpen) {
      setCurrent(1);
      setProcessing(false);
      setConfirmation(false);
    }
  }, [isModalOpen, shouldShowRequestForm]);

  const handleToggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(e.target.checked);
  };

  const handleSupport = () => {
    openChat();
    onModalCancel();
    setCurrent(0);
  };

  const close = () => {
    onModalCancel();
    setCurrent(showBackButtonInRequestForm ? 0 : 1);
    setConfirmation(false);
    setProcessing(false);
  };

  const prev = () => {
    setCurrent(current - 1);
    setConfirmation(false);
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const payloads = {
    amount,
    paymentID: paymentMethodID ?? '',
  };
  const onRequestNonVoipNumber = (request: any) => {
    requestNonVoipNumber(request);
  };

  const { onPurchase } = useNonVoipPurchase({
    onModalCancel: toggleCreditModal,
    setProcessing,
    onRequestNonVoipNumber,
    setVisibleRequest,
    confirmation,
  });

  const requestNonVoip = async () => {
    await onPurchase(payloads);
  };
  return (
    <>
      <S.StyledModal
        data-cy='tab-pane-add-button-modal'
        className={current === 1 ? 'modal-nomargin modal text-center' : 'modal text-center'}
        width={450}
        centered
        destroyOnClose
        visible={isModalOpen}
        footer={null}
        onCancel={onModalCancel}
        closable={true}
        closeIcon={<RiCloseLine color='#6E6681' />}
      >
        {current === 0 && <SelectNumberType onModalCancel={onModalCancel} next={next} />}
        {current === 1 && (
          <RequestForm
            prev={showBackButtonInRequestForm ? prev : undefined}
            handleSupport={handleSupport}
            handleToggleCheckbox={handleToggleCheckbox}
            requestNonVoip={requestNonVoip}
            confirmation={confirmation}
            processing={processing}
          />
        )}
        {current === 2 && visibleRequest === true && <SuccessForm close={close} />}
      </S.StyledModal>
    </>
  );
};

export default NumberSelectModal;
