import { gql } from '@apollo/client';

export const CAMPAIGN_RESPONSE_FIELDS = gql`
  fragment CampaignResponseFields on CampaignResponsePayload {
    success
    totalContactCount
    validContactCount
    invalidContactCount
    duplicatesContactCount
  }
`;
