import React from 'react';
import styled from 'styled-components';
import { AutoComplete as AntAutoComplete } from 'antd';

const AutoComplete = styled(AntAutoComplete)`
  all: unset;
  border-radius: 0px !important;
  background: transparent !important;
  > div {
    color: #3d3358;
    border: none !important;
    background-color: transparent !important;
    border-radius: 0px !important;
    height: fit-content !important;
    box-shadow: none !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
`;
export default React.memo(AutoComplete);
