import { useRef, useState } from 'react';
import { Collapse } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from 'react-icons/ri';
import { yupResolver } from '@hookform/resolvers/yup';

import { ContactInputData } from 'generated/graphql';
import UploadPic from 'components/molecules/upload-pic';
import { Input, PhoneInput } from 'components/molecules/fields';
import EditFieldModal from 'components/organisms/modals/fields-edit/EditFieldModal';

import * as S from './StylesAddContactModal';
import { useAddContactMutation } from './useAddContactMutation';

import { schema } from '../schema';

const { Panel } = Collapse;
interface ContactFormData extends ContactInputData {
  isPrivate?: boolean;
}

interface IAddContactModalProps {
  isModalOpen: boolean;
  onModalCancel: () => void;
  onCancel: () => void;
  width?: string | number;
  showAdditionalFields?: boolean;
}
const AddContactModal = (props: IAddContactModalProps) => {
  const { t } = useTranslation();
  const [imageFileObj, setImageFileObj] = useState<any>(null);
  const childRef: any = useRef(null);

  const onImgUpload = (img: any) => {
    setImageFileObj(img);
  };
  const { isModalOpen, onModalCancel, onCancel, width, showAdditionalFields } = props;

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ContactFormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: null,
      email: '',
      number: '',
      country: '',
      // isPrivate: true,
    },
  });

  const { addContact, loading } = useAddContactMutation({
    onModalCancel,
    imageFile: imageFileObj,
    reset,
  });

  const formSubmit = async (val: ContactFormData) => {
    const data = {
      ...val,
      // isPrivate: undefined,
      // visibility: !val.isPrivate,
      visibility: true, // Save contact as public by default
      number: `+${val.number}`,
    };
    await addContact(data, setError);
  };

  return (
    <EditFieldModal
      isModalOpen={isModalOpen}
      onCancel={onModalCancel}
      buttonText={t('addNewContact', 'Add New Contact')}
      formId='add-new-contact'
      data-cy='contacts-add-edit-modal'
    >
      <form
        data-cy='contacts-add-edit-form'
        onSubmit={handleSubmit(formSubmit)}
        id='add-new-contact'
        className='mt-2'
      >
        <S.AddContactFormWrapper>
          <UploadPic
            data-cy='contacts-add-upload-pic'
            ref={childRef}
            position='center'
            onImgUpload={onImgUpload}
            type='user'
          />
          <div className='mt-10'>
            <Input
              data-cy='contacts-add-form-name-field'
              name='name'
              id='name'
              label={t('form.label.contactName', 'Contact Name')}
              control={control}
              errors={errors}
              autoFocus
            />
          </div>

          <div className='mt-8 mb-6'>
            <PhoneInput
              data-cy='contacts-add-form-number-field'
              name='number'
              id='number'
              control={control}
              label={t('form.label.phoneNumber', 'Phone Number')}
              errors={errors}
              containerClass='add-new-contact-phone'
            />
          </div>
          {/* <div className='flex justify-between items-center w-95'>
            <Checkbox
              name='isPrivate'
              id='isPrivate'
              control={control}
              label={t('saveNumberPrivately', 'Save Number Privately')}
              errors={errors}
            />
          </div> */}
          {showAdditionalFields && (
            <span data-cy='contacts-add-form-additional-detail-element'>
              <S.CustomCollapse
                ghost
                expandIcon={({ isActive }) => (
                  <div>
                    <span data-cy='additional-detail' className='leading-5.25'>
                      {t('additionalDetails', 'Additional Details')}
                    </span>
                    <RiArrowDownSLine
                      className={`transform ${isActive ? 'rotate-180' : 'rotate-0'}`}
                    />
                  </div>
                )}
                expandIconPosition='right'
              >
                <Panel header='' key='additionalOptions'>
                  <div>
                    <div className='mb-6' data-cy='contacts-add-form-email-element'>
                      <Input
                        name='email'
                        id='email'
                        control={control}
                        label={t('form.label.emailAddress', 'Email Address')}
                        errors={errors}
                        size='large'
                      />
                    </div>
                    <div className='mb-6' data-cy='contacts-add-form-company-element'>
                      <Input
                        name='company'
                        id='company'
                        control={control}
                        label={t('form.label.company', 'Company')}
                        errors={errors}
                        size='large'
                      />
                    </div>

                    <Input
                      name='address'
                      data-cy='contacts-add-form-address-element'
                      id='address'
                      control={control}
                      label={t('form.label.address', 'Address')}
                      errors={errors}
                      size='large'
                    />
                  </div>
                </Panel>
              </S.CustomCollapse>
            </span>
          )}
        </S.AddContactFormWrapper>
      </form>
    </EditFieldModal>
  );
};

export default AddContactModal;
