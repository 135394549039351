import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const CARDS = gql`
  query cards {
    cards {
      status
      data {
        id
        name
        expiryMonth
        expiryYear
        lastDigit
        brand
        email
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const GET_DEFAULT_CARD = gql`
  query getDefaultCard {
    getDefaultCard {
      status
      data {
        id
        name
        expiryMonth
        expiryYear
        lastDigit
        brand
        email
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
