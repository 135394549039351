import { format } from 'date-fns';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import * as path from 'constants/routes';
import useScreenType from 'hooks/useScreenType';
import { AuthContext } from 'contexts/auth/AuthContext';

import * as S from '../Styles';

const NumberSubsNotification = () => {
  const history = useHistory();
  const { isTablet, isMobile } = useScreenType();
  const { numberSubscription, isNumberSubscriptionExpired } = useContext(AuthContext);

  const goToPlan = () => history.push(`${path.SETTINGS}${path.PLAN_SUBS}`);
  const expiredAt =
    numberSubscription?.endedAt && format(new Date(numberSubscription?.endedAt), 'LLL do, yyyy');
  const msg = isNumberSubscriptionExpired
    ? ' Paid number Subscription expired.'
    : `Your all paid numbers failed to auto-renew and will expire on ${expiredAt}. It will become inactive if not renewed.`;
  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={
          <>
            <p className='hidden lg:inline-block'>
              {msg}
              <button type='button' onClick={goToPlan} className='mr-2'>
                Renew
              </button>
            </p>
            <p className='lg:hidden'>
              Number Subscription expired.
              <button type='button' onClick={goToPlan} className='mr-2'>
                Renew
              </button>
            </p>
          </>
        }
        type='error'
        banner
        className={`has-bordered-btn ${isTablet || isMobile ? 'alert-sm' : ''}`}
      />
    </S.AlertWrapper>
  );
};

export default NumberSubsNotification;
