import { CheckCircleFill } from 'components/atoms/icon';
import { WidgetActionButton } from '../Styles';

interface SaveDiscaardProps {
  formId?: string;
  isSaveLoading?: boolean;
  disabled?: boolean;
  onDiscard: (args?: any) => void;
  onSave?: (args?: any) => void;
  htmlType?: 'button' | 'submit';
  saveButtonLabel?: string;
}

const SaveDiscardActionBlock = ({
  formId,
  onDiscard,
  onSave,
  isSaveLoading = false,
  disabled = false,
  saveButtonLabel,
  htmlType = 'button',
}: SaveDiscaardProps) => {
  return (
    <div className='flex gap-3 items-center mt-auto w-full'>
      <WidgetActionButton onClick={onDiscard}>Discard</WidgetActionButton>
      <WidgetActionButton
        form={formId}
        onClick={onSave}
        type='primary'
        icon={<CheckCircleFill className='w-5 h-5' />}
        loading={isSaveLoading}
        disabled={disabled || isSaveLoading}
        htmlType={htmlType}
      >
        {saveButtonLabel ?? 'Save Changes'}
      </WidgetActionButton>
    </div>
  );
};

export default SaveDiscardActionBlock;
