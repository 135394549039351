export default function CallTransferFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
    >
      <path
        d='M2.35538 20.7776C2.33578 21.0757 2.38082 21.3745 2.48734 21.6537C2.59327 21.9433 2.75648 22.2086 2.9673 22.4336C3.41857 22.8027 3.98043 23.0097 4.56318 23.0216H4.86325H7.74305C8.24098 23.0411 8.72982 22.8837 9.12298 22.5776C9.44172 22.2531 9.64052 21.83 9.68695 21.3777V20.1777C9.67248 20.0376 9.69745 19.8963 9.75905 19.7698C9.79708 19.6622 9.86347 19.5669 9.95108 19.4937C10.3246 19.3096 10.7346 19.2112 11.151 19.2058C12.0269 19.0737 12.7709 18.9658 13.5508 18.9177C14.9352 18.8207 16.3261 18.9545 17.6666 19.3138C17.7933 19.33 17.9134 19.3797 18.0146 19.4578C18.0972 19.5209 18.1632 19.6033 18.2066 19.6978C18.2968 20.0705 18.3251 20.4556 18.2906 20.8377C18.2551 21.1196 18.2795 21.4059 18.3625 21.6776C18.4379 21.9626 18.573 22.2285 18.7585 22.4576C18.9605 22.6685 19.2062 22.8323 19.4785 22.9376C19.7222 23.0356 19.984 23.0806 20.2464 23.0696H22.7303C23.1299 23.0927 23.5305 23.0927 23.9302 23.0696C24.4387 23.012 24.9064 22.7632 25.2382 22.3736C25.5502 21.8894 25.6935 21.3157 25.6462 20.7417C25.6597 19.999 25.5123 19.2621 25.2142 18.5819C24.9073 17.8847 24.4529 17.2625 23.8823 16.7579C22.8432 15.7814 21.6193 15.0225 20.2825 14.5261L19.3465 14.2021C17.686 13.7397 15.9704 13.5056 14.2467 13.506C12.0883 13.4237 9.93288 13.7368 7.88702 14.43C6.7592 14.852 5.70593 15.4516 4.76723 16.2059C3.73053 16.8973 2.93067 17.8899 2.47532 19.0498C2.36448 19.6187 2.32423 20.199 2.35538 20.7776Z'
        fill='currentColor'
      />
      <path
        d='M20.6543 5.17833L20.5226 5.08252C20.572 5.11107 20.6165 5.14748 20.6543 5.19032V5.17833Z'
        fill='currentColor'
      />
      <path
        d='M19.8392 4.92664C19.9583 4.90857 20.0794 4.90857 20.1985 4.92664H19.8392Z'
        fill='currentColor'
      />
      <path
        d='M7.43072 6.46245L12.1345 11.1662C12.643 11.6672 13.3286 11.9474 14.0423 11.9461C14.3942 11.9478 14.743 11.8798 15.0684 11.7458C15.3938 11.612 15.6896 11.415 15.9384 11.1662L19.1181 7.99837V10.6022C19.1181 10.8409 19.2129 11.0698 19.3817 11.2386C19.5505 11.4074 19.7793 11.5022 20.018 11.5022C20.2567 11.5022 20.4857 11.4074 20.6545 11.2386C20.8232 11.0698 20.918 10.8409 20.918 10.6022V5.8025C20.9165 5.56415 20.8217 5.33588 20.654 5.16653C20.6162 5.12362 20.5716 5.08715 20.522 5.05854C20.4263 4.98667 20.3155 4.93745 20.198 4.91455H15.2302C14.9917 4.91455 14.7628 5.00937 14.5939 5.17814C14.4252 5.34692 14.3304 5.57583 14.3304 5.81451C14.3304 6.05319 14.4252 6.28206 14.5939 6.45084C14.7628 6.61961 14.9917 6.71446 15.2302 6.71446H17.8342L14.6664 9.88226C14.4991 10.0251 14.2863 10.1036 14.0664 10.1036C13.8465 10.1036 13.6337 10.0251 13.4665 9.88226L8.76259 5.17851C8.59389 5.00997 8.36522 4.91532 8.12675 4.91532C7.88829 4.91532 7.6595 5.00997 7.4908 5.17851C7.31604 5.34287 7.21209 5.56875 7.20089 5.80836C7.18969 6.04796 7.27205 6.28251 7.43072 6.46245Z'
        fill='currentColor'
      />
    </svg>
  );
}
