import { memo } from 'react';
import isEmpty from 'lodash.isempty';

import WidgetSidebar from './widget-sidebar';
import AudioSettings from './nav-screens/audio-settings';
import { WIDGET_NAV_SCREENS } from './constants';
import CallWidget from './nav-screens/call-widget';
import useCallWidgetNavContext from './hooks/useCallWidgetNavContext';
import useCallWidgetContext from './hooks/useCallWidgetContext';
import WidgetBanner from './widget-banner';

function WidgetBody() {
  const { CALL_WIDGET, AUDIO_SETTINGS } = WIDGET_NAV_SCREENS;
  const { selectedNavScreen, setSelectedNavScreen } = useCallWidgetNavContext();
  const { kycBanner } = useCallWidgetContext();

  const mapComponent = {
    [AUDIO_SETTINGS]: <AudioSettings />,
  };

  return (
    <section>
      <WidgetSidebar
        selectedNavScreen={selectedNavScreen}
        setSelectedNavScreen={setSelectedNavScreen}
      />
      <main className='transition-none bg-white border-l relative border-extra-widgetBorder w-full h-full '>
        {!isEmpty(kycBanner) && (
          <WidgetBanner
            title={kycBanner?.title}
            icon={kycBanner?.icon}
            type={kycBanner?.type}
            showIcon={kycBanner?.showIcon}
            closable={kycBanner?.closable}
            alignContent={kycBanner?.alignContent ?? 'left'}
            onClick={kycBanner?.onClick}
            className='cursor-pointer'
          />
        )}
        {/**
         * Prevent remount of call widget component
         * CallWidget shouldn't be remounted in order for Widget to function properly (like timer & other functions)
         * */}
        <div
          className={`w-full h-full ${selectedNavScreen !== CALL_WIDGET ? 'absolute -z-1' : ''}`}
        >
          <CallWidget />
        </div>
        {mapComponent[selectedNavScreen]}
      </main>
    </section>
  );
}

export default memo(WidgetBody);
