export const COUNTRIES = [
  {
    uid: '7orjMbWyWNXCmT3H8YsELT',
    name: 'United States of America',
    dialingCode: '+1',
    flagUrl: 'https://flagcdn.com/w160/us.webp',
    alphaTwoCode: 'US',
  },
  {
    uid: '7opfWxvyTNhWq9yArDRrEv',
    name: 'Afghanistan',
    dialingCode: '+93',
    flagUrl: 'https://flagcdn.com/w160/af.webp',
    alphaTwoCode: 'AF',
  },
  {
    uid: '7or43SuX9HHndxxEattuVx',
    name: 'Åland Islands',
    dialingCode: '+358',
    flagUrl: 'https://flagcdn.com/w160/ax.webp',
    alphaTwoCode: 'AX',
  },
  {
    uid: '7or43ZJEgAzosUYeAb5dMH',
    name: 'Albania',
    dialingCode: '+355',
    flagUrl: 'https://flagcdn.com/w160/al.webp',
    alphaTwoCode: 'AL',
  },
  {
    uid: '7or43ffwE4imjpDBhtApcA',
    name: 'Algeria',
    dialingCode: '+213',
    flagUrl: 'https://flagcdn.com/w160/dz.webp',
    alphaTwoCode: 'DZ',
  },
  {
    uid: '7or43n4ekwSqYAzePbbKca',
    name: 'American Samoa',
    dialingCode: '+1684',
    flagUrl: 'https://flagcdn.com/w160/as.webp',
    alphaTwoCode: 'AS',
  },
  {
    uid: '7or43tSNJqAqGh56mFvXcB',
    name: 'Andorra',
    dialingCode: '+376',
    flagUrl: 'https://flagcdn.com/w160/ad.webp',
    alphaTwoCode: 'AD',
  },
  {
    uid: '7or43zp5qisn9vkEQTeiCj',
    name: 'Angola',
    dialingCode: '+244',
    flagUrl: 'https://flagcdn.com/w160/ao.webp',
    alphaTwoCode: 'AO',
  },
  {
    uid: '7or448CnPcboZVk8g7NGBC',
    name: 'Anguilla',
    dialingCode: '+1264',
    flagUrl: 'https://flagcdn.com/w160/ai.webp',
    alphaTwoCode: 'AI',
  },
  {
    uid: '7or44EaVvWKqJiDWUQhP4Z',
    name: 'Antarctica',
    dialingCode: '+672',
    flagUrl: 'https://flagcdn.com/w160/aq.webp',
    alphaTwoCode: 'AQ',
  },
  {
    uid: '7or44LxDUQ3woYo2CYdpKi',
    name: 'Antigua and Barbuda',
    dialingCode: '+1268',
    flagUrl: 'https://flagcdn.com/w160/ag.webp',
    alphaTwoCode: 'AG',
  },
  {
    uid: '7or44TLv2Hku9VbB4THQds',
    name: 'Argentina',
    dialingCode: '+54',
    flagUrl: 'https://flagcdn.com/w160/ar.webp',
    alphaTwoCode: 'AR',
  },
  {
    uid: '7or44ZidZBUyHjagYYxRLH',
    name: 'Armenia',
    dialingCode: '+374',
    flagUrl: 'https://flagcdn.com/w160/am.webp',
    alphaTwoCode: 'AM',
  },
  {
    uid: '7or44g7M75CraHUKrwoNf8',
    name: 'Aruba',
    dialingCode: '+297',
    flagUrl: 'https://flagcdn.com/w160/aw.webp',
    alphaTwoCode: 'AW',
  },
  {
    uid: '7or44nV4dwurHPhGV4qvdD',
    name: 'Australia',
    dialingCode: '+61',
    flagUrl: 'https://flagcdn.com/w160/au.webp',
    alphaTwoCode: 'AU',
  },
  {
    uid: '7or44trmBqdxXmnorUsKk8',
    name: 'Austria',
    dialingCode: '+43',
    flagUrl: 'https://flagcdn.com/w160/at.webp',
    alphaTwoCode: 'AT',
  },
  {
    uid: '7or452FUijMy3hwk7Cuvh6',
    name: 'Azerbaijan',
    dialingCode: '+994',
    flagUrl: 'https://flagcdn.com/w160/az.webp',
    alphaTwoCode: 'AZ',
  },
  {
    uid: '7or458dCGd5yZcgLvRbvXx',
    name: 'Bahamas',
    dialingCode: '+1242',
    flagUrl: 'https://flagcdn.com/w160/bs.webp',
    alphaTwoCode: 'BS',
  },
  {
    uid: '7or45EztoWnwa364sxnkpJ',
    name: 'Bahrain',
    dialingCode: '+973',
    flagUrl: 'https://flagcdn.com/w160/bh.webp',
    alphaTwoCode: 'BH',
  },
  {
    uid: '7or45MPcMQWttV6PT8cegz',
    name: 'Bangladesh',
    dialingCode: '+880',
    flagUrl: 'https://flagcdn.com/w160/bd.webp',
    alphaTwoCode: 'BD',
  },
  {
    uid: '7or45TmKtJF3VEZGVfayg6',
    name: 'Barbados',
    dialingCode: '+1246',
    flagUrl: 'https://flagcdn.com/w160/bb.webp',
    alphaTwoCode: 'BB',
  },
  {
    uid: '7or45aA3SBwv2Tz8AoXjpZ',
    name: 'Belarus',
    dialingCode: '+375',
    flagUrl: 'https://flagcdn.com/w160/by.webp',
    alphaTwoCode: 'BY',
  },
  {
    uid: '7or45gXjy5g2keRs2ra3Tx',
    name: 'Belgium',
    dialingCode: '+32',
    flagUrl: 'https://flagcdn.com/w160/be.webp',
    alphaTwoCode: 'BE',
  },
  {
    uid: '7or45nuTWxQ4NHvthfdDmL',
    name: 'Belize',
    dialingCode: '+501',
    flagUrl: 'https://flagcdn.com/w160/bz.webp',
    alphaTwoCode: 'BZ',
  },
  {
    uid: '7or45uJB4r7zmkPUZT9K4B',
    name: 'Benin',
    dialingCode: '+229',
    flagUrl: 'https://flagcdn.com/w160/bj.webp',
    alphaTwoCode: 'BJ',
  },
  {
    uid: '7or462fsbjpxR7uYeHmXWB',
    name: 'Bermuda',
    dialingCode: '+1441',
    flagUrl: 'https://flagcdn.com/w160/bm.webp',
    alphaTwoCode: 'BM',
  },
  {
    uid: '7or4694b9dZ6WqvDMVEHTx',
    name: 'Bhutan',
    dialingCode: '+975',
    flagUrl: 'https://flagcdn.com/w160/bt.webp',
    alphaTwoCode: 'BT',
  },
  {
    uid: '7or46FSJgXH2KKJv2QQrHB',
    name: 'Bolivia (Plurinational State of)',
    dialingCode: '+591',
    flagUrl: 'https://flagcdn.com/w160/bo.webp',
    alphaTwoCode: 'BO',
  },
  {
    uid: '7or46Mp2EQyxqms4TX4M4q',
    name: 'Bonaire, Sint comstatius and Saba',
    dialingCode: '+5997',
    flagUrl: 'https://flagcdn.com/w160/bq.webp',
    alphaTwoCode: 'BQ',
  },
  {
    uid: '7or46UCimJi42BYAzw3Stb',
    name: 'Bosnia and Herzegovina',
    dialingCode: '+387',
    flagUrl: 'https://flagcdn.com/w160/ba.webp',
    alphaTwoCode: 'BA',
  },
  {
    uid: '7or46aaSKCRxhME5xnjTDj',
    name: 'Botswana',
    dialingCode: '+267',
    flagUrl: 'https://flagcdn.com/w160/bw.webp',
    alphaTwoCode: 'BW',
  },
  {
    uid: '7or46gx9r69wTnQm9ZWTjn',
    name: 'Bouvet Island',
    dialingCode: '+',
    flagUrl: 'https://flagcdn.com/w160/bv.webp',
    alphaTwoCode: 'BV',
  },
  {
    uid: '7or46oLrPxry7We5MVLgmZ',
    name: 'Brazil',
    dialingCode: '+55',
    flagUrl: 'https://flagcdn.com/w160/br.webp',
    alphaTwoCode: 'BR',
  },
  {
    uid: '7or46uiZvrawDFAvRbNhf4',
    name: 'British Indian Ocean Territory',
    dialingCode: '+246',
    flagUrl: 'https://flagcdn.com/w160/io.webp',
    alphaTwoCode: 'IO',
  },
  {
    uid: '7or4737HUkK5JEEr8p2DJE',
    name: 'United States Minor Outlying Islands',
    dialingCode: '+',
    flagUrl: 'https://flagcdn.com/w160/um.webp',
    alphaTwoCode: 'UM',
  },
  {
    uid: '7or479Uz2e3B2ZftkUuPyJ',
    name: 'Virgin Islands (British)',
    dialingCode: '+1284',
    flagUrl: 'https://flagcdn.com/w160/vg.webp',
    alphaTwoCode: 'VG',
  },
  {
    uid: '7or47FrhZXk6wRu355iD85',
    name: 'Virgin Islands (U.S.)',
    dialingCode: '+1 340',
    flagUrl: 'https://flagcdn.com/w160/vi.webp',
    alphaTwoCode: 'VI',
  },
  {
    uid: '7or47NFR7RU44jmbQAEE9E',
    name: 'Brunei Darussalam',
    dialingCode: '+673',
    flagUrl: 'https://flagcdn.com/w160/bn.webp',
    alphaTwoCode: 'BN',
  },
  {
    uid: '7or47Ud8eKByd8TxdPJWTM',
    name: 'Bulgaria',
    dialingCode: '+359',
    flagUrl: 'https://flagcdn.com/w160/bg.webp',
    alphaTwoCode: 'BG',
  },
  {
    uid: '7or47azqCCu9ZyFuM333dE',
    name: 'Burkina Faso',
    dialingCode: '+226',
    flagUrl: 'https://flagcdn.com/w160/bf.webp',
    alphaTwoCode: 'BF',
  },
  {
    uid: '7or47hPYj6dBBSezZ2RWgA',
    name: 'Burundi',
    dialingCode: '+257',
    flagUrl: 'https://flagcdn.com/w160/bi.webp',
    alphaTwoCode: 'BI',
  },
  {
    uid: '7or47omGGyM5W9oxu93uJd',
    name: 'Cambodia',
    dialingCode: '+855',
    flagUrl: 'https://flagcdn.com/w160/kh.webp',
    alphaTwoCode: 'KH',
  },
  {
    uid: '7or47v9xos4znJy26Tb5w8',
    name: 'Cameroon',
    dialingCode: '+237',
    flagUrl: 'https://flagcdn.com/w160/cm.webp',
    alphaTwoCode: 'CM',
  },
  {
    uid: '7or483XgMknAHwSksADtpr',
    name: 'Canada',
    dialingCode: '+1',
    flagUrl: 'https://flagcdn.com/w160/ca.webp',
    alphaTwoCode: 'CA',
  },
  {
    uid: '7or489uPteWEgHiMmLVZAd',
    name: 'Cabo Verde',
    dialingCode: '+238',
    flagUrl: 'https://flagcdn.com/w160/cv.webp',
    alphaTwoCode: 'CV',
  },
  {
    uid: '7or48GJ7SYEDJDu2MoWfXd',
    name: 'Cayman Islands',
    dialingCode: '+1345',
    flagUrl: 'https://flagcdn.com/w160/ky.webp',
    alphaTwoCode: 'KY',
  },
  {
    uid: '7or48NfoyRwAvBhEPJf4At',
    name: 'Central African Republic',
    dialingCode: '+236',
    flagUrl: 'https://flagcdn.com/w160/cf.webp',
    alphaTwoCode: 'CF',
  },
  {
    uid: '7or48V4XXKf5iRwvCWLwUJ',
    name: 'Chad',
    dialingCode: '+235',
    flagUrl: 'https://flagcdn.com/w160/td.webp',
    alphaTwoCode: 'TD',
  },
  {
    uid: '7or48bSF5DP9BEmDzTqLJc',
    name: 'Chile',
    dialingCode: '+56',
    flagUrl: 'https://flagcdn.com/w160/cl.webp',
    alphaTwoCode: 'CL',
  },
  {
    uid: '7or48howc77GbVfQsDRPEj',
    name: 'China',
    dialingCode: '+86',
    flagUrl: 'https://flagcdn.com/w160/cn.webp',
    alphaTwoCode: 'CN',
  },
  {
    uid: '7or48pCf9yp6SyLXpDdHQV',
    name: 'Christmas Island',
    dialingCode: '+61',
    flagUrl: 'https://flagcdn.com/w160/cx.webp',
    alphaTwoCode: 'CX',
  },
  {
    uid: '7or48vaNgsYEQzJyKyyMnD',
    name: 'Cocos (Keeling) Islands',
    dialingCode: '+61',
    flagUrl: 'https://flagcdn.com/w160/cc.webp',
    alphaTwoCode: 'CC',
  },
  {
    uid: '7or493x6EmGBghhGbTYoEc',
    name: 'Colombia',
    dialingCode: '+57',
    flagUrl: 'https://flagcdn.com/w160/co.webp',
    alphaTwoCode: 'CO',
  },
  {
    uid: '7or49ALnmeyJhB7mJoKnu7',
    name: 'Comoros',
    dialingCode: '+269',
    flagUrl: 'https://flagcdn.com/w160/km.webp',
    alphaTwoCode: 'KM',
  },
  {
    uid: '7or49GiWKYhACRt99rUH5s',
    name: 'Congo',
    dialingCode: '+242',
    flagUrl: 'https://flagcdn.com/w160/cg.webp',
    alphaTwoCode: 'CG',
  },
  {
    uid: '7or49P7DrSRJTu8UzGyQuJ',
    name: 'Congo (Democratic Republic of the)',
    dialingCode: '+243',
    flagUrl: 'https://flagcdn.com/w160/cd.webp',
    alphaTwoCode: 'CD',
  },
  {
    uid: '7or49VUvQL9Ciy9ihc3pqH',
    name: 'Cook Islands',
    dialingCode: '+682',
    flagUrl: 'https://flagcdn.com/w160/ck.webp',
    alphaTwoCode: 'CK',
  },
  {
    uid: '7or49brdwDrLGntcye729d',
    name: 'Costa Rica',
    dialingCode: '+506',
    flagUrl: 'https://flagcdn.com/w160/cr.webp',
    alphaTwoCode: 'CR',
  },
  {
    uid: '7or49iFMV7aCihHtrYAReM',
    name: 'Croatia',
    dialingCode: '+385',
    flagUrl: 'https://flagcdn.com/w160/hr.webp',
    alphaTwoCode: 'HR',
  },
  {
    uid: '7or49pd52zJBD74CTE52kD',
    name: 'Cuba',
    dialingCode: '+53',
    flagUrl: 'https://flagcdn.com/w160/cu.webp',
    alphaTwoCode: 'CU',
  },
  {
    uid: '7or49vzmZt2HVq2v4yr2Ug',
    name: 'Curaçao',
    dialingCode: '+599',
    flagUrl: 'https://flagcdn.com/w160/cw.webp',
    alphaTwoCode: 'CW',
  },
  {
    uid: '7or4A4PV7mjCNRq7QFjiVL',
    name: 'Cyprus',
    dialingCode: '+357',
    flagUrl: 'https://flagcdn.com/w160/cy.webp',
    alphaTwoCode: 'CY',
  },
  {
    uid: '7or4AAmCefTCf8oKBV9bx3',
    name: 'Czech Republic',
    dialingCode: '+420',
    flagUrl: 'https://flagcdn.com/w160/cz.webp',
    alphaTwoCode: 'CZ',
  },
  {
    uid: '7or4AH9uCZBHrHzthHRKGg',
    name: 'Denmark',
    dialingCode: '+45',
    flagUrl: 'https://flagcdn.com/w160/dk.webp',
    alphaTwoCode: 'DK',
  },
  {
    uid: '7or4APXcjStLACbDbQzRBh',
    name: 'Djibouti',
    dialingCode: '+253',
    flagUrl: 'https://flagcdn.com/w160/dj.webp',
    alphaTwoCode: 'DJ',
  },
  {
    uid: '7or4AVuLHLcPYYNiEQbXZx',
    name: 'Dominica',
    dialingCode: '+1767',
    flagUrl: 'https://flagcdn.com/w160/dm.webp',
    alphaTwoCode: 'DM',
  },
  {
    uid: '7or4AcJ3pELEbYJu2oUs9j',
    name: 'Dominican Republic',
    dialingCode: '+1809',
    flagUrl: 'https://flagcdn.com/w160/do.webp',
    alphaTwoCode: 'DO',
  },
  {
    uid: '7or4AifkN84KZtUxYwxJtw',
    name: 'Ecuador',
    dialingCode: '+593',
    flagUrl: 'https://flagcdn.com/w160/ec.webp',
    alphaTwoCode: 'EC',
  },
  {
    uid: '7or4Aq4TtzmHdqxSusPGG4',
    name: 'Egypt',
    dialingCode: '+20',
    flagUrl: 'https://flagcdn.com/w160/eg.webp',
    alphaTwoCode: 'EG',
  },
  {
    uid: '7or4AwSBStVRaYAMtWmYEy',
    name: 'El Salvador',
    dialingCode: '+503',
    flagUrl: 'https://flagcdn.com/w160/sv.webp',
    alphaTwoCode: 'SV',
  },
  {
    uid: '7or4B4osynDJiMR4473TqU',
    name: 'Equatorial Guinea',
    dialingCode: '+240',
    flagUrl: 'https://flagcdn.com/w160/gq.webp',
    alphaTwoCode: 'GQ',
  },
  {
    uid: '7or4BBCbXfvJSPgf8zYyPc',
    name: 'Eritrea',
    dialingCode: '+291',
    flagUrl: 'https://flagcdn.com/w160/er.webp',
    alphaTwoCode: 'ER',
  },
  {
    uid: '7or4BHaK5ZeMAMuZtkvhSR',
    name: 'Estonia',
    dialingCode: '+372',
    flagUrl: 'https://flagcdn.com/w160/ee.webp',
    alphaTwoCode: 'EE',
    regex: /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im,
  },
  {
    uid: '7or4BPx2cTNKR3hJjDNscZ',
    name: 'Ethiopia',
    dialingCode: '+251',
    flagUrl: 'https://flagcdn.com/w160/et.webp',
    alphaTwoCode: 'ET',
  },
  {
    uid: '7or4BWLjAM6MxcQVZahBF8',
    name: 'Falkland Islands (Malvinas)',
    dialingCode: '+500',
    flagUrl: 'https://flagcdn.com/w160/fk.webp',
    alphaTwoCode: 'FK',
  },
  {
    uid: '7or4BciShEoS7F3EQp5DYV',
    name: 'Faroe Islands',
    dialingCode: '+298',
    flagUrl: 'https://flagcdn.com/w160/fo.webp',
    alphaTwoCode: 'FO',
  },
  {
    uid: '7or4Bj7AF8XKeBspxEenhi',
    name: 'Fiji',
    dialingCode: '+679',
    flagUrl: 'https://flagcdn.com/w160/fj.webp',
    alphaTwoCode: 'FJ',
  },
  {
    uid: '7or4BqUrn2FKbc2mZw88sU',
    name: 'Finland',
    dialingCode: '+358',
    flagUrl: 'https://flagcdn.com/w160/fi.webp',
    alphaTwoCode: 'FI',
  },
  {
    uid: '7or4BwraKtxWAgWW4xzK6H',
    name: 'France',
    dialingCode: '+33',
    flagUrl: 'https://flagcdn.com/w160/fr.webp',
    alphaTwoCode: 'FR',
  },
  {
    uid: '7or4C5FHrngR2v7oTbZbb4',
    name: 'French Guiana',
    dialingCode: '+594',
    flagUrl: 'https://flagcdn.com/w160/gf.webp',
    alphaTwoCode: 'GF',
  },
  {
    uid: '7or4CBczQgQUU53LVyaaiU',
    name: 'French Polynesia',
    dialingCode: '+689',
    flagUrl: 'https://flagcdn.com/w160/pf.webp',
    alphaTwoCode: 'PF',
  },
  {
    uid: '7or4CHzhwa8Na4Dk6rjZRE',
    name: 'French Southern Territories',
    dialingCode: '+',
    flagUrl: 'https://flagcdn.com/w160/tf.webp',
    alphaTwoCode: 'TF',
  },
  {
    uid: '7or4CQPRVTqR3xgZaFh59X',
    name: 'Gabon',
    dialingCode: '+241',
    flagUrl: 'https://flagcdn.com/w160/ga.webp',
    alphaTwoCode: 'GA',
  },
  {
    uid: '7or4CWm93MZTxL7Va7aQiG',
    name: 'Gambia',
    dialingCode: '+220',
    flagUrl: 'https://flagcdn.com/w160/gm.webp',
    alphaTwoCode: 'GM',
  },
  {
    uid: '7or4Cd9qaFHSycWz9vkKDJ',
    name: 'Georgia',
    dialingCode: '+995',
    flagUrl: 'https://flagcdn.com/w160/ge.webp',
    alphaTwoCode: 'GE',
  },
  {
    uid: '7or4CjXZ88zSSTMut9YXHa',
    name: 'Germany',
    dialingCode: '+49',
    flagUrl: 'https://flagcdn.com/w160/de.webp',
    alphaTwoCode: 'DE',
  },
  {
    uid: '7or4CquGf2iQK2eP2xTr7k',
    name: 'Ghana',
    dialingCode: '+233',
    flagUrl: 'https://flagcdn.com/w160/gh.webp',
    alphaTwoCode: 'GH',
  },
  {
    uid: '7or4CxHyCuSa2Xb5bh6XuP',
    name: 'Gibraltar',
    dialingCode: '+350',
    flagUrl: 'https://flagcdn.com/w160/gi.webp',
    alphaTwoCode: 'GI',
  },
  {
    uid: '7orPetUjXREjDGPd9EUZPY',
    name: 'Greece',
    dialingCode: '+30',
    flagUrl: 'https://flagcdn.com/w160/gr.webp',
    alphaTwoCode: 'GR',
  },
  {
    uid: '7orPezrT5JwqbN2UyGHB33',
    name: 'Greenland',
    dialingCode: '+299',
    flagUrl: 'https://flagcdn.com/w160/gl.webp',
    alphaTwoCode: 'GL',
  },
  {
    uid: '7orPf8FAcCftgmqtg5hLiu',
    name: 'Grenada',
    dialingCode: '+1473',
    flagUrl: 'https://flagcdn.com/w160/gd.webp',
    alphaTwoCode: 'GD',
  },
  {
    uid: '7orPfEcsA6PwA8UudJWJP3',
    name: 'Guadeloupe',
    dialingCode: '+590',
    flagUrl: 'https://flagcdn.com/w160/gp.webp',
    alphaTwoCode: 'GP',
  },
  {
    uid: '7orPfLzagy7tCyKgnR83x9',
    name: 'Guam',
    dialingCode: '+1671',
    flagUrl: 'https://flagcdn.com/w160/gu.webp',
    alphaTwoCode: 'GU',
  },
  {
    uid: '7orPfTPJErpmJco9BUH5DW',
    name: 'Guatemala',
    dialingCode: '+502',
    flagUrl: 'https://flagcdn.com/w160/gt.webp',
    alphaTwoCode: 'GT',
  },
  {
    uid: '7orPfZkzmkYxBFbjFYeAVj',
    name: 'Guernsey',
    dialingCode: '+44',
    flagUrl: 'https://flagcdn.com/w160/gg.webp',
    alphaTwoCode: 'GG',
  },
  {
    uid: '7orPfg9iKeGvQWYxKSngeN',
    name: 'Guinea',
    dialingCode: '+224',
    flagUrl: 'https://flagcdn.com/w160/gn.webp',
    alphaTwoCode: 'GN',
  },
  {
    uid: '7orPfnXRrXyvzTA9qnXVzU',
    name: 'Guinea-Bissau',
    dialingCode: '+245',
    flagUrl: 'https://flagcdn.com/w160/gw.webp',
    alphaTwoCode: 'GW',
  },
  {
    uid: '7orPftu9QRhvzkV67JjZY7',
    name: 'Guyana',
    dialingCode: '+592',
    flagUrl: 'https://flagcdn.com/w160/gy.webp',
    alphaTwoCode: 'GY',
  },
  {
    uid: '7orPg2HqwKRpKY7HiSDTe2',
    name: 'Haiti',
    dialingCode: '+509',
    flagUrl: 'https://flagcdn.com/w160/ht.webp',
    alphaTwoCode: 'HT',
  },
  {
    uid: '7orPg8fZVD9zgY5soJjzWu',
    name: 'Heard Island and McDonald Islands',
    dialingCode: '+',
    flagUrl: 'https://flagcdn.com/w160/hm.webp',
    alphaTwoCode: 'HM',
  },
  {
    uid: '7orPgF4H36rookzRFGymyL',
    name: 'Holy See',
    dialingCode: '+379',
    flagUrl: 'https://flagcdn.com/w160/va.webp',
    alphaTwoCode: 'VA',
  },
  {
    uid: '7orPgMRyZyaoRzN4urqRUr',
    name: 'Honduras',
    dialingCode: '+504',
    flagUrl: 'https://flagcdn.com/w160/hn.webp',
    alphaTwoCode: 'HN',
  },
  {
    uid: '7orPgToh7sJpM57uZRDgyZ',
    name: 'Hong Kong',
    dialingCode: '+852',
    flagUrl: 'https://flagcdn.com/w160/hk.webp',
    alphaTwoCode: 'HK',
  },
  {
    uid: '7orPgaCQem2pVxVqddWWJx',
    name: 'Hungary',
    dialingCode: '+36',
    flagUrl: 'https://flagcdn.com/w160/hu.webp',
    alphaTwoCode: 'HU',
  },
  {
    uid: '7orPgga8CejtG9X5Yoq6xG',
    name: 'Iceland',
    dialingCode: '+354',
    flagUrl: 'https://flagcdn.com/w160/is.webp',
    alphaTwoCode: 'IS',
  },
  {
    uid: '7orPgnwpjYTrqjvCc9tQvU',
    name: 'India',
    dialingCode: '+91',
    flagUrl: 'https://flagcdn.com/w160/in.webp',
    alphaTwoCode: 'IN',
  },
  {
    uid: '7orPguLYHSC3ddwMpdtKkD',
    name: 'Indonesia',
    dialingCode: '+62',
    flagUrl: 'https://flagcdn.com/w160/id.webp',
    alphaTwoCode: 'ID',
  },
  {
    uid: '7orPh2iFpKtuHhtjM7dEDZ',
    name: "Côte d'Ivoire",
    dialingCode: '+225',
    flagUrl: 'https://flagcdn.com/w160/ci.webp',
    alphaTwoCode: 'CI',
  },
  {
    uid: '7orPh96xNDcwhMSh8MMAkF',
    name: 'Iran (Islamic Republic of)',
    dialingCode: '+98',
    flagUrl: 'https://flagcdn.com/w160/ir.webp',
    alphaTwoCode: 'IR',
  },
  {
    uid: '7orPhFUfu7LubATiYttSMV',
    name: 'Iraq',
    dialingCode: '+964',
    flagUrl: 'https://flagcdn.com/w160/iq.webp',
    alphaTwoCode: 'IQ',
  },
  {
    uid: '7orPhMrPSz4vhDfjNDHVZP',
    name: 'Ireland',
    dialingCode: '+353',
    flagUrl: 'https://flagcdn.com/w160/ie.webp',
    alphaTwoCode: 'IE',
  },
  {
    uid: '7orPhUF6ysn2jJ3VmYekdo',
    name: 'Isle of Man',
    dialingCode: '+44',
    flagUrl: 'https://flagcdn.com/w160/im.webp',
    alphaTwoCode: 'IM',
  },
  {
    uid: '7orPhacoXmW7WuuJqB7xZy',
    name: 'Israel',
    dialingCode: '+972',
    flagUrl: 'https://flagcdn.com/w160/il.webp',
    alphaTwoCode: 'IL',
  },
  {
    uid: '7orPhgzX5fDwfYeXa4zUvv',
    name: 'Italy',
    dialingCode: '+39',
    flagUrl: 'https://flagcdn.com/w160/it.webp',
    alphaTwoCode: 'IT',
  },
  {
    uid: '7orPhoPEcYvxK9onAget6c',
    name: 'Jamaica',
    dialingCode: '+1876',
    flagUrl: 'https://flagcdn.com/w160/jm.webp',
    alphaTwoCode: 'JM',
  },
  {
    uid: '7orPhukwASexFbP5BtUqNT',
    name: 'Japan',
    dialingCode: '+81',
    flagUrl: 'https://flagcdn.com/w160/jp.webp',
    alphaTwoCode: 'JP',
  },
  {
    uid: '7orPi39ehLP26jpTzoefBX',
    name: 'Jersey',
    dialingCode: '+44',
    flagUrl: 'https://flagcdn.com/w160/je.webp',
    alphaTwoCode: 'JE',
  },
  {
    uid: '7orPi9XNFE76Uz46fabTsa',
    name: 'Jordan',
    dialingCode: '+962',
    flagUrl: 'https://flagcdn.com/w160/jo.webp',
    alphaTwoCode: 'JO',
  },
  {
    uid: '7orPiFu5n7p6Cdaqh9d9by',
    name: 'Kazakhstan',
    dialingCode: '+76',
    flagUrl: 'https://flagcdn.com/w160/kz.webp',
    alphaTwoCode: 'KZ',
  },
  {
    uid: '7orPiNHnKzY6QyzZwKpENS',
    name: 'Kenya',
    dialingCode: '+254',
    flagUrl: 'https://flagcdn.com/w160/ke.webp',
    alphaTwoCode: 'KE',
  },
  {
    uid: '7orPiUfVrtG9PtWGMYvgzw',
    name: 'Kiribati',
    dialingCode: '+686',
    flagUrl: 'https://flagcdn.com/w160/ki.webp',
    alphaTwoCode: 'KI',
  },
  {
    uid: '7orPib4DQmyBdUdjRq7oRV',
    name: 'Kuwait',
    dialingCode: '+965',
    flagUrl: 'https://flagcdn.com/w160/kw.webp',
    alphaTwoCode: 'KW',
  },
  {
    uid: '7orPihRuwfh6ZMNmUFF5ck',
    name: 'Kyrgyzstan',
    dialingCode: '+996',
    flagUrl: 'https://flagcdn.com/w160/kg.webp',
    alphaTwoCode: 'KG',
  },
  {
    uid: '7orPioodVZQz6Sim4TN6mA',
    name: "Lao People's Democratic Republic",
    dialingCode: '+856',
    flagUrl: 'https://flagcdn.com/w160/la.webp',
    alphaTwoCode: 'LA',
  },
  {
    uid: '7orPivCM3T93gKn9zUHZgR',
    name: 'Latvia',
    dialingCode: '+371',
    flagUrl: 'https://flagcdn.com/w160/lv.webp',
    alphaTwoCode: 'LV',
  },
  {
    uid: '7orPj3a4aLr3xfYUq5N3eF',
    name: 'Lebanon',
    dialingCode: '+961',
    flagUrl: 'https://flagcdn.com/w160/lb.webp',
    alphaTwoCode: 'LB',
  },
  {
    uid: '7orPj9wm8Ea24woVj8jnQ9',
    name: 'Lesotho',
    dialingCode: '+266',
    flagUrl: 'https://flagcdn.com/w160/ls.webp',
    alphaTwoCode: 'LS',
  },
  {
    uid: '7orPjGLUf8J7EnEYin4hJz',
    name: 'Liberia',
    dialingCode: '+231',
    flagUrl: 'https://flagcdn.com/w160/lr.webp',
    alphaTwoCode: 'LR',
  },
  {
    uid: '7orPjNiCD22C2TdLu7gLZ3',
    name: 'Libya',
    dialingCode: '+218',
    flagUrl: 'https://flagcdn.com/w160/ly.webp',
    alphaTwoCode: 'LY',
  },
  {
    uid: '7orPjV6tjtj8jUth5jznxn',
    name: 'Liechtenstein',
    dialingCode: '+423',
    flagUrl: 'https://flagcdn.com/w160/li.webp',
    alphaTwoCode: 'LI',
  },
  {
    uid: '7orPjbUcHnTBWLKbVK9FPU',
    name: 'Lithuania',
    dialingCode: '+370',
    flagUrl: 'https://flagcdn.com/w160/lt.webp',
    alphaTwoCode: 'LT',
  },
  {
    uid: '7orPjhrKpgBBt6G3fsPkaB',
    name: 'Luxembourg',
    dialingCode: '+352',
    flagUrl: 'https://flagcdn.com/w160/lu.webp',
    alphaTwoCode: 'LU',
  },
  {
    uid: '7orPjpF3NZtEdmzM38E7qW',
    name: 'Macao',
    dialingCode: '+853',
    flagUrl: 'https://flagcdn.com/w160/mo.webp',
    alphaTwoCode: 'MO',
  },
  {
    uid: '7orPjvcjuTcGziTFdNr3Gk',
    name: 'Macedonia (the former Yugoslav Republic of)',
    dialingCode: '+389',
    flagUrl: 'https://flagcdn.com/w160/mk.webp',
    alphaTwoCode: 'MK',
  },
  {
    uid: '7orPk3zTTMLABUrFwxPif5',
    name: 'Madagascar',
    dialingCode: '+261',
    flagUrl: 'https://flagcdn.com/w160/mg.webp',
    alphaTwoCode: 'MG',
  },
  {
    uid: '7orPkAPAzF48D8yYDFDViU',
    name: 'Malawi',
    dialingCode: '+265',
    flagUrl: 'https://flagcdn.com/w160/mw.webp',
    alphaTwoCode: 'MW',
  },
  {
    uid: '7orPkGksY8mFemCFipumNF',
    name: 'Malaysia',
    dialingCode: '+60',
    flagUrl: 'https://flagcdn.com/w160/my.webp',
    alphaTwoCode: 'MY',
  },
  {
    uid: '7orPkP9b62VDycRXcWu3zC',
    name: 'Maldives',
    dialingCode: '+960',
    flagUrl: 'https://flagcdn.com/w160/mv.webp',
    alphaTwoCode: 'MV',
  },
  {
    uid: '7orPkVXJcuD7p92MAUMALv',
    name: 'Mali',
    dialingCode: '+223',
    flagUrl: 'https://flagcdn.com/w160/ml.webp',
    alphaTwoCode: 'ML',
  },
  {
    uid: '7orPkbu2Anv9RYtTFkaD5z',
    name: 'Malta',
    dialingCode: '+356',
    flagUrl: 'https://flagcdn.com/w160/mt.webp',
    alphaTwoCode: 'MT',
  },
  {
    uid: '7orPkiHihgeBSKzQLPx3w7',
    name: 'Marshall Islands',
    dialingCode: '+692',
    flagUrl: 'https://flagcdn.com/w160/mh.webp',
    alphaTwoCode: 'MH',
  },
  {
    uid: '7orPkpfSFaN9gFqQAraxnd',
    name: 'Martinique',
    dialingCode: '+596',
    flagUrl: 'https://flagcdn.com/w160/mq.webp',
    alphaTwoCode: 'MQ',
  },
  {
    uid: '7orPkw49nU6M5kCZWsRZGG',
    name: 'Mauritania',
    dialingCode: '+222',
    flagUrl: 'https://flagcdn.com/w160/mr.webp',
    alphaTwoCode: 'MR',
  },
  {
    uid: '7orPm4RrLMo9soSSivrTGE',
    name: 'Mauritius',
    dialingCode: '+230',
    flagUrl: 'https://flagcdn.com/w160/mu.webp',
    alphaTwoCode: 'MU',
  },
  {
    uid: '7orPmAoZsFXEGGCMH2paKo',
    name: 'Mayotte',
    dialingCode: '+262',
    flagUrl: 'https://flagcdn.com/w160/yt.webp',
    alphaTwoCode: 'YT',
  },
  {
    uid: '7orPmHCHR9FLXt2TwBUuDn',
    name: 'Mexico',
    dialingCode: '+52',
    flagUrl: 'https://flagcdn.com/w160/mx.webp',
    alphaTwoCode: 'MX',
  },
  {
    uid: '7orPmPZyx2xKtVnDoEknKd',
    name: 'Micronesia (Federated States of)',
    dialingCode: '+691',
    flagUrl: 'https://flagcdn.com/w160/fm.webp',
    alphaTwoCode: 'FM',
  },
  {
    uid: '7orPmVwhVugNZfs7AbiddH',
    name: 'Moldova (Republic of)',
    dialingCode: '+373',
    flagUrl: 'https://flagcdn.com/w160/md.webp',
    alphaTwoCode: 'MD',
  },
  {
    uid: '7orPmcLR3oQNPAqoiBaciA',
    name: 'Monaco',
    dialingCode: '+377',
    flagUrl: 'https://flagcdn.com/w160/mc.webp',
    alphaTwoCode: 'MC',
  },
  {
    uid: '7orPmii8ah8Gxkkab4mLcU',
    name: 'Mongolia',
    dialingCode: '+976',
    flagUrl: 'https://flagcdn.com/w160/mn.webp',
    alphaTwoCode: 'MN',
  },
  {
    uid: '7orPmq6q8aqJqWeLPADxky',
    name: 'Montenegro',
    dialingCode: '+382',
    flagUrl: 'https://flagcdn.com/w160/me.webp',
    alphaTwoCode: 'ME',
  },
  {
    uid: '7orPmwUYfUZQ4RnetvJvQF',
    name: 'Montserrat',
    dialingCode: '+1664',
    flagUrl: 'https://flagcdn.com/w160/ms.webp',
    alphaTwoCode: 'MS',
  },
  {
    uid: '7orPn4rGDNHGGKwpcA7YtK',
    name: 'Morocco',
    dialingCode: '+212',
    flagUrl: 'https://flagcdn.com/w160/ma.webp',
    alphaTwoCode: 'MA',
  },
  {
    uid: '7orPnBExkFzRMmnm9Vupqx',
    name: 'Mozambique',
    dialingCode: '+258',
    flagUrl: 'https://flagcdn.com/w160/mz.webp',
    alphaTwoCode: 'MZ',
  },
  {
    uid: '7orPnHcgJ9iHte7KCKW2Sx',
    name: 'Myanmar',
    dialingCode: '+95',
    flagUrl: 'https://flagcdn.com/w160/mm.webp',
    alphaTwoCode: 'MM',
  },
  {
    uid: '7orPnPzPq3SQmniNVPydRc',
    name: 'Namibia',
    dialingCode: '+264',
    flagUrl: 'https://flagcdn.com/w160/na.webp',
    alphaTwoCode: 'NA',
  },
  {
    uid: '7orPnWP7NvAHKcVvRWQPhk',
    name: 'Nauru',
    dialingCode: '+674',
    flagUrl: 'https://flagcdn.com/w160/nr.webp',
    alphaTwoCode: 'NR',
  },
  {
    uid: '7orPnckouosS2Wak6C3ZBq',
    name: 'Nepal',
    dialingCode: '+977',
    flagUrl: 'https://krispcall-prod.sgp1.digitaloceanspaces.com/storage/flags/np.png',
    alphaTwoCode: 'NP',
  },
  {
    uid: '7orPnj9XThbHSULcWt9exE',
    name: 'Netherlands',
    dialingCode: '+31',
    flagUrl: 'https://flagcdn.com/w160/nl.webp',
    alphaTwoCode: 'NL',
  },
  {
    uid: '7orPnqXEzbKLekhdSvCwEM',
    name: 'New Caledonia',
    dialingCode: '+687',
    flagUrl: 'https://flagcdn.com/w160/nc.webp',
    alphaTwoCode: 'NC',
  },
  {
    uid: '7orPnwtwYV3RdAQg6mqoJR',
    name: 'New Zealand',
    dialingCode: '+64',
    flagUrl: 'https://flagcdn.com/w160/nz.webp',
    alphaTwoCode: 'NZ',
  },
  {
    uid: '7orPo5Hf6NkUX4U8DPH5Xn',
    name: 'Nicaragua',
    dialingCode: '+505',
    flagUrl: 'https://flagcdn.com/w160/ni.webp',
    alphaTwoCode: 'NI',
  },
  {
    uid: '7orPoBfNdGUPTypn5hNaFg',
    name: 'Niger',
    dialingCode: '+227',
    flagUrl: 'https://flagcdn.com/w160/ne.webp',
    alphaTwoCode: 'NE',
  },
  {
    uid: '7orPoJ46BACPnWNMfpYwZd',
    name: 'Nigeria',
    dialingCode: '+234',
    flagUrl: 'https://flagcdn.com/w160/ng.webp',
    alphaTwoCode: 'NG',
  },
  {
    uid: '7orPoQRni3uTohiLTsw9SM',
    name: 'Niue',
    dialingCode: '+683',
    flagUrl: 'https://flagcdn.com/w160/nu.webp',
    alphaTwoCode: 'NU',
  },
  {
    uid: '7orPoWoWFvdLHcomPyeKXL7',
    name: 'Norfolk Island',
    dialingCode: '+672',
    flagUrl: 'https://flagcdn.com/w160/nf.webp',
    alphaTwoCode: 'NF',
  },
  {
    uid: '7orPodCDnpMNEyoQ5NTMsk',
    name: "Korea (Democratic People's Republic of)",
    dialingCode: '+850',
    flagUrl: 'https://flagcdn.com/w160/kp.webp',
    alphaTwoCode: 'KP',
  },
  {
    uid: '7orPojZvLi5XbuEvedyWCR',
    name: 'Northern Mariana Islands',
    dialingCode: '+1670',
    flagUrl: 'https://flagcdn.com/w160/mp.webp',
    alphaTwoCode: 'MP',
  },
  {
    uid: '7orPoqwdsbnRJoMQxPyFeJ',
    name: 'Norway',
    dialingCode: '+47',
    flagUrl: 'https://flagcdn.com/w160/no.webp',
    alphaTwoCode: 'NO',
  },
  {
    uid: '7orPoxLMRVWRwtJVqmTMFS',
    name: 'Oman',
    dialingCode: '+968',
    flagUrl: 'https://flagcdn.com/w160/om.webp',
    alphaTwoCode: 'OM',
  },
  {
    uid: '7orPp5i4xPEUBYPJTGAW5v',
    name: 'Pakistan',
    dialingCode: '+92',
    flagUrl: 'https://flagcdn.com/w160/pk.webp',
    alphaTwoCode: 'PK',
  },
  {
    uid: '7orPpC6mWGwU4VPoBnukbr',
    name: 'Palau',
    dialingCode: '+680',
    flagUrl: 'https://flagcdn.com/w160/pw.webp',
    alphaTwoCode: 'PW',
  },
  {
    uid: '7orPpJUV4AfVFLR9hDAseb',
    name: 'Palestine, State of',
    dialingCode: '+970',
    flagUrl: 'https://flagcdn.com/w160/ps.webp',
    alphaTwoCode: 'PS',
  },
  {
    uid: '7orPpQrCb4PXwL5PsTuDoV',
    name: 'Panama',
    dialingCode: '+507',
    flagUrl: 'https://flagcdn.com/w160/pa.webp',
    alphaTwoCode: 'PA',
  },
  {
    uid: '7orPpXEu8w7bnCqyGLm2fu',
    name: 'Papua New Guinea',
    dialingCode: '+675',
    flagUrl: 'https://flagcdn.com/w160/pg.webp',
    alphaTwoCode: 'PG',
  },
  {
    uid: '7orPpdccfppTFFL8EAeBBh',
    name: 'Paraguay',
    dialingCode: '+595',
    flagUrl: 'https://flagcdn.com/w160/py.webp',
    alphaTwoCode: 'PY',
  },
  {
    uid: '7orPpjzLDiYcJDhvB4CZbD',
    name: 'Peru',
    dialingCode: '+51',
    flagUrl: 'https://flagcdn.com/w160/pe.webp',
    alphaTwoCode: 'PE',
  },
  {
    uid: '7orPprP3kcGTu3LzRPpB9D',
    name: 'Philippines',
    dialingCode: '+63',
    flagUrl: 'https://flagcdn.com/w160/ph.webp',
    alphaTwoCode: 'PH',
  },
  {
    uid: '7orPpxkkJVyXYn6UHQAuMr',
    name: 'Pitcairn',
    dialingCode: '+64',
    flagUrl: 'https://flagcdn.com/w160/pn.webp',
    alphaTwoCode: 'PN',
  },
  {
    uid: '7orPq69TqPhcScc7ofmqAP',
    name: 'Poland',
    dialingCode: '+48',
    flagUrl: 'https://flagcdn.com/w160/pl.webp',
    alphaTwoCode: 'PL',
  },
  {
    uid: '7orPqCXBPHRUdyTU7SbLt9',
    name: 'Portugal',
    dialingCode: '+351',
    flagUrl: 'https://flagcdn.com/w160/pt.webp',
    alphaTwoCode: 'PT',
  },
  {
    uid: '7orPqJtsvB9VZyFzDnSZxt',
    name: 'Puerto Rico',
    dialingCode: '+1787',
    flagUrl: 'https://flagcdn.com/w160/pr.webp',
    alphaTwoCode: 'PR',
  },
  {
    uid: '7orPqRHbU4re5xvcw8PoNW',
    name: 'Qatar',
    dialingCode: '+974',
    flagUrl: 'https://flagcdn.com/w160/qa.webp',
    alphaTwoCode: 'QA',
  },
  {
    uid: '7orPqXfJzwaYbfYkTz2yhB',
    name: 'Republic of Kosovo',
    dialingCode: '+383',
    flagUrl: 'https://flagcdn.com/w160/xk.webp',
    alphaTwoCode: 'XK',
  },
  {
    uid: '7orPqe42YqJXivyAuwXUVG',
    name: 'Réunion',
    dialingCode: '+262',
    flagUrl: 'https://flagcdn.com/w160/re.webp',
    alphaTwoCode: 'RE',
  },
  {
    uid: '7orPqkRj6j2VpVQ6XEtn8K',
    name: 'Romania',
    dialingCode: '+40',
    flagUrl: 'https://flagcdn.com/w160/ro.webp',
    alphaTwoCode: 'RO',
  },
  {
    uid: '7orPqroSdcjfmUG5rBo9BH',
    name: 'Russian Federation',
    dialingCode: '+7',
    flagUrl: 'https://flagcdn.com/w160/ru.webp',
    alphaTwoCode: 'RU',
  },
  {
    uid: '7orPqyCABWTi57gV46PgD9',
    name: 'Rwanda',
    dialingCode: '+250',
    flagUrl: 'https://flagcdn.com/w160/rw.webp',
    alphaTwoCode: 'RW',
  },
  {
    uid: '7orPr6ZriQBhxedRzz7J22',
    name: 'Saint Barthélemy',
    dialingCode: '+590',
    flagUrl: 'https://flagcdn.com/w160/bl.webp',
    alphaTwoCode: 'BL',
  },
  {
    uid: '7orPrCwaGHtXsCbV5cico6',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    dialingCode: '+290',
    flagUrl: 'https://flagcdn.com/w160/sh.webp',
    alphaTwoCode: 'SH',
  },
  {
    uid: '7orPrKLHoBcigKqJr332LQ',
    name: 'Saint Kitts and Nevis',
    dialingCode: '+1869',
    flagUrl: 'https://flagcdn.com/w160/kn.webp',
    alphaTwoCode: 'KN',
  },
  {
    uid: '7orPrRhzM5LegfCs3nXu7z',
    name: 'Saint Lucia',
    dialingCode: '+1758',
    flagUrl: 'https://flagcdn.com/w160/lc.webp',
    alphaTwoCode: 'LC',
  },
  {
    uid: '7orPrY6hsx4ibxauC8vNKP',
    name: 'Saint Martin (French part)',
    dialingCode: '+590',
    flagUrl: 'https://flagcdn.com/w160/mf.webp',
    alphaTwoCode: 'MF',
  },
  {
    uid: '7orPrcomRRqmdwHbkTJYHGQ',
    name: 'Saint Pierre and Miquelon',
    dialingCode: '+508',
    flagUrl: 'https://flagcdn.com/w160/pm.webp',
    alphaTwoCode: 'PM',
  },
  {
    uid: '7orPrkr8xjVa98deX5FGJj',
    name: 'Saint Vincent and the Grenadines',
    dialingCode: '+1784',
    flagUrl: 'https://flagcdn.com/w160/vc.webp',
    alphaTwoCode: 'VC',
  },
  {
    uid: '7orPrsEqWdDnSFpmtzjmnr',
    name: 'Samoa',
    dialingCode: '+685',
    flagUrl: 'https://flagcdn.com/w160/ws.webp',
    alphaTwoCode: 'WS',
  },
  {
    uid: '7orPrycZ4WviJJpF6zKtyh',
    name: 'San Marino',
    dialingCode: '+378',
    flagUrl: 'https://flagcdn.com/w160/sm.webp',
    alphaTwoCode: 'SM',
  },
  {
    uid: '7orPs6zGbQeiBh6azajReE',
    name: 'Sao Tome and Principe',
    dialingCode: '+239',
    flagUrl: 'https://flagcdn.com/w160/st.webp',
    alphaTwoCode: 'ST',
  },
  {
    uid: '7orPsDNy9JNhoRF4xvQkbD',
    name: 'Saudi Arabia',
    dialingCode: '+966',
    flagUrl: 'https://flagcdn.com/w160/sa.webp',
    alphaTwoCode: 'SA',
  },
  {
    uid: '7orPsKkggC6iYwtFKyEKj6',
    name: 'Senegal',
    dialingCode: '+221',
    flagUrl: 'https://flagcdn.com/w160/sn.webp',
    alphaTwoCode: 'SN',
  },
  {
    uid: '7orPsS9QE5okUEm7pF7Gjb',
    name: 'Serbia',
    dialingCode: '+381',
    flagUrl: 'https://flagcdn.com/w160/rs.webp',
    alphaTwoCode: 'RS',
  },
  {
    uid: '7orjHEfFNfToVSgKoPY52P',
    name: 'Seychelles',
    dialingCode: '+248',
    flagUrl: 'https://flagcdn.com/w160/sc.webp',
    alphaTwoCode: 'SC',
  },
  {
    uid: '7orjHM3wuZBmB53btAc3TE',
    name: 'Sierra Leone',
    dialingCode: '+232',
    flagUrl: 'https://flagcdn.com/w160/sl.webp',
    alphaTwoCode: 'SL',
  },
  {
    uid: '7orjHTRfTStqZqa3xPXwub',
    name: 'Singapore',
    dialingCode: '+65',
    flagUrl: 'https://flagcdn.com/w160/sg.webp',
    alphaTwoCode: 'SG',
  },
  {
    uid: '7orjHZoNzLcmVfHCAHBLjW',
    name: 'Sint Maarten (Dutch part)',
    dialingCode: '+1721',
    flagUrl: 'https://flagcdn.com/w160/sx.webp',
    alphaTwoCode: 'SX',
  },
  {
    uid: '7orjHgC6YELtKKLUAgbFxF',
    name: 'Slovakia',
    dialingCode: '+421',
    flagUrl: 'https://flagcdn.com/w160/sk.webp',
    alphaTwoCode: 'SK',
  },
  {
    uid: '7orjHnZo684vDMUmNDqGCg',
    name: 'Slovenia',
    dialingCode: '+386',
    flagUrl: 'https://flagcdn.com/w160/si.webp',
    alphaTwoCode: 'SI',
  },
  {
    uid: '7orjHtwWczmvm5mj2LJjso',
    name: 'Solomon Islands',
    dialingCode: '+677',
    flagUrl: 'https://flagcdn.com/w160/sb.webp',
    alphaTwoCode: 'SB',
  },
  {
    uid: '7orjJ2LEAtVnG7vBGrd6VH',
    name: 'Somalia',
    dialingCode: '+252',
    flagUrl: 'https://flagcdn.com/w160/so.webp',
    alphaTwoCode: 'SO',
  },
  {
    uid: '7orjJ8hvhnDwF9VMk3TX2H',
    name: 'South Africa',
    dialingCode: '+27',
    flagUrl: 'https://flagcdn.com/w160/za.webp',
    alphaTwoCode: 'ZA',
  },
  {
    uid: '7orjJF6eFfvxWR8A2uFJPy',
    name: 'South Georgia and the South Sandwich Islands',
    dialingCode: '+500',
    flagUrl: 'https://flagcdn.com/w160/gs.webp',
    alphaTwoCode: 'GS',
  },
  {
    uid: '7orjJMUMnZenhGfWNqibVQ',
    name: 'Korea (Republic of)',
    dialingCode: '+82',
    flagUrl: 'https://flagcdn.com/w160/kr.webp',
    alphaTwoCode: 'KR',
  },
  {
    uid: '7orjJTr5LTNoaFh6VHKpGh',
    name: 'South Sudan',
    dialingCode: '+211',
    flagUrl: 'https://flagcdn.com/w160/ss.webp',
    alphaTwoCode: 'SS',
  },
  {
    uid: '7orjJaEmsM6suvQcLBfsnt',
    name: 'Spain',
    dialingCode: '+34',
    flagUrl: 'https://flagcdn.com/w160/es.webp',
    alphaTwoCode: 'ES',
  },
  {
    uid: '7orjJgcVREouxDQ3QmgEKE',
    name: 'Sri Lanka',
    dialingCode: '+94',
    flagUrl: 'https://flagcdn.com/w160/lk.webp',
    alphaTwoCode: 'LK',
  },
  {
    uid: '7orjJnzCx8XxupeBQZzKro',
    name: 'Sudan',
    dialingCode: '+249',
    flagUrl: 'https://flagcdn.com/w160/sd.webp',
    alphaTwoCode: 'SD',
  },
  {
    uid: '7orjJuNuW2Fsj9XW3geTgq',
    name: 'Suriname',
    dialingCode: '+597',
    flagUrl: 'https://flagcdn.com/w160/sr.webp',
    alphaTwoCode: 'SR',
  },
  {
    uid: '7orjK2kd3txv9FkeQUPHUD',
    name: 'Svalbard and Jan Mayen',
    dialingCode: '+4779',
    flagUrl: 'https://flagcdn.com/w160/sj.webp',
    alphaTwoCode: 'SJ',
  },
  {
    uid: '7orjK99LangunsXTS8AwtA',
    name: 'Swaziland',
    dialingCode: '+268',
    flagUrl: 'https://flagcdn.com/w160/sz.webp',
    alphaTwoCode: 'SZ',
  },
  {
    uid: '7orjKFX48gR5ExuSoSZei8',
    name: 'Sweden',
    dialingCode: '+46',
    flagUrl: 'https://flagcdn.com/w160/se.webp',
    alphaTwoCode: 'SE',
  },
  {
    uid: '7orjKMtkfa8yarm6bmL7jK',
    name: 'Switzerland',
    dialingCode: '+41',
    flagUrl: 'https://flagcdn.com/w160/ch.webp',
    alphaTwoCode: 'CH',
  },
  {
    uid: '7orjKUHUDTr3eHvCzsK6LW',
    name: 'Syrian Arab Republic',
    dialingCode: '+963',
    flagUrl: 'https://flagcdn.com/w160/sy.webp',
    alphaTwoCode: 'SY',
  },
  {
    uid: '7orjKafBkMZt5eQvVuu2vd',
    name: 'Taiwan',
    dialingCode: '+886',
    flagUrl: 'https://flagcdn.com/w160/tw.webp',
    alphaTwoCode: 'TW',
  },
  {
    uid: '7orjKh3tJFJ2zT6A8TymeN',
    name: 'Tajikistan',
    dialingCode: '+992',
    flagUrl: 'https://flagcdn.com/w160/tj.webp',
    alphaTwoCode: 'TJ',
  },
  {
    uid: '7orjKoRbq8zzwkA47sHHw3',
    name: 'Tanzania, United Republic of',
    dialingCode: '+255',
    flagUrl: 'https://flagcdn.com/w160/tz.webp',
    alphaTwoCode: 'TZ',
  },
  {
    uid: '7orjKuoKP2j4PTKdi2xTzM',
    name: 'Thailand',
    dialingCode: '+66',
    flagUrl: 'https://flagcdn.com/w160/th.webp',
    alphaTwoCode: 'TH',
  },
  {
    uid: '7orjL3C2uuT7a4hFBHjYMo',
    name: 'Timor-Leste',
    dialingCode: '+670',
    flagUrl: 'https://flagcdn.com/w160/tl.webp',
    alphaTwoCode: 'TL',
  },
  {
    uid: '7orjL9ZjToAwCTg2gbqA3U',
    name: 'Togo',
    dialingCode: '+228',
    flagUrl: 'https://flagcdn.com/w160/tg.webp',
    alphaTwoCode: 'TG',
  },
  {
    uid: '7orjLFwSzgsxqKVHFeAezp',
    name: 'Tokelau',
    dialingCode: '+690',
    flagUrl: 'https://flagcdn.com/w160/tk.webp',
    alphaTwoCode: 'TK',
  },
  {
    uid: '7orjLNLAYabyBiYYCreZax',
    name: 'Tonga',
    dialingCode: '+676',
    flagUrl: 'https://flagcdn.com/w160/to.webp',
    alphaTwoCode: 'TO',
  },
  {
    uid: '7orjLUhs6UKzWfq4FEAjy2',
    name: 'Trinidad and Tobago',
    dialingCode: '+1868',
    flagUrl: 'https://flagcdn.com/w160/tt.webp',
    alphaTwoCode: 'TT',
  },
  {
    uid: '7orjLb6adN3ytDcoma5uacom',
    name: 'Tunisia',
    dialingCode: '+216',
    flagUrl: 'https://flagcdn.com/w160/tn.webp',
    alphaTwoCode: 'TN',
  },
  {
    uid: '7orjLhUJBFm2za3UUW9AzL',
    name: 'Turkey',
    dialingCode: '+90',
    flagUrl: 'https://flagcdn.com/w160/tr.webp',
    alphaTwoCode: 'TR',
  },
  {
    uid: '7orjLoqzi9V5uGZdhnY7sB',
    name: 'Turkmenistan',
    dialingCode: '+993',
    flagUrl: 'https://flagcdn.com/w160/tm.webp',
    alphaTwoCode: 'TM',
  },
  {
    uid: '7orjLvEiG3D7qPSDSSZAq2',
    name: 'Turks and Caicos Islands',
    dialingCode: '+1649',
    flagUrl: 'https://flagcdn.com/w160/tc.webp',
    alphaTwoCode: 'TC',
  },
  {
    uid: '7orjM3cRnuvAzSQMn3YKEP',
    name: 'Tuvalu',
    dialingCode: '+688',
    flagUrl: 'https://flagcdn.com/w160/tv.webp',
    alphaTwoCode: 'TV',
  },
  {
    uid: '7orjM9z9Loe57LXo9WNSJR',
    name: 'Uganda',
    dialingCode: '+256',
    flagUrl: 'https://flagcdn.com/w160/ug.webp',
    alphaTwoCode: 'UG',
  },
  {
    uid: '7orjMGNqshN4sS7MM3JPTs',
    name: 'Ukraine',
    dialingCode: '+380',
    flagUrl: 'https://flagcdn.com/w160/ua.webp',
    alphaTwoCode: 'UA',
  },
  {
    uid: '7orjMNkZRb6E5grGHgmYU9',
    name: 'United Arab Emirates',
    dialingCode: '+971',
    flagUrl: 'https://flagcdn.com/w160/ae.webp',
    alphaTwoCode: 'AE',
  },
  {
    uid: '7orjMV9GxUo5J6KB2UiKUA',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    dialingCode: '+44',
    flagUrl: 'https://flagcdn.com/w160/gb.webp',
    alphaTwoCode: 'GB',
  },
  {
    uid: '7orjMhth4GF6SfALxXaksf',
    name: 'Uruguay',
    dialingCode: '+598',
    flagUrl: 'https://flagcdn.com/w160/uy.webp',
    alphaTwoCode: 'UY',
  },
  {
    uid: '7orjMpHQb9xBKegHGR4air',
    name: 'Uzbekistan',
    dialingCode: '+998',
    flagUrl: 'https://flagcdn.com/w160/uz.webp',
    alphaTwoCode: 'UZ',
  },
  {
    uid: '7orjMvf893g97DXV7cHi9t',
    name: 'Vanuatu',
    dialingCode: '+678',
    flagUrl: 'https://flagcdn.com/w160/vu.webp',
    alphaTwoCode: 'VU',
  },
  {
    uid: '7orjN43pfvQ8yLdvJxhfvG',
    name: 'Venezuela (Bolivarian Republic of)',
    dialingCode: '+58',
    flagUrl: 'https://flagcdn.com/w160/ve.webp',
    alphaTwoCode: 'VE',
  },
  {
    uid: '7orjNARYDp8Ch3D2dgmP3R',
    name: 'Viet Nam',
    dialingCode: '+84',
    flagUrl: 'https://flagcdn.com/w160/vn.webp',
    alphaTwoCode: 'VN',
  },
  {
    uid: '7orjNGoFkhqEntoCFNBGvP',
    name: 'Wallis and Futuna',
    dialingCode: '+681',
    flagUrl: 'https://flagcdn.com/w160/wf.webp',
    alphaTwoCode: 'WF',
  },
  {
    uid: '7orjNPBxJbZANbgN3LizW2',
    name: 'Western Sahara',
    dialingCode: '+212',
    flagUrl: 'https://flagcdn.com/w160/eh.webp',
    alphaTwoCode: 'EH',
  },
  {
    uid: '7orjNVZfqVHDVJmL9awYHp',
    name: 'Yemen',
    dialingCode: '+967',
    flagUrl: 'https://flagcdn.com/w160/ye.webp',
    alphaTwoCode: 'YE',
  },
  {
    uid: '7orjNbwPPNzFBRQnhZhoF2',
    name: 'Zambia',
    dialingCode: '+260',
    flagUrl: 'https://flagcdn.com/w160/zm.webp',
    alphaTwoCode: 'ZM',
  },
  {
    uid: '7orjNiL6vGiJo7Xdjf6Jw3',
    name: 'Zimbabwe',
    dialingCode: '+263',
    flagUrl: 'https://flagcdn.com/w160/zw.webp',
    alphaTwoCode: 'ZW',
  },
];

interface Country {
  uid: string;
  name: string;
  dialingCode: string;
  flagUrl: string;
  alphaTwoCode: string;
  regex?: string;
}

interface HashedCountryProps {
  [key: string]: Country;
}

export const HASHED_COUNTRIES: HashedCountryProps = COUNTRIES.reduce(
  (acc, curr) => ({ ...acc, [curr.alphaTwoCode]: curr }),
  {},
);

export const COUNTRY_BY_UID: HashedCountryProps = COUNTRIES.reduce(
  (acc, curr) => ({ ...acc, [curr.uid]: curr }),
  {},
);

export default COUNTRIES;
