import { FETCH_SUPPORT_AUTH_DATA } from 'graphql/authorization/query';
import cache from 'services/apollo/cache';

export const privilegeListSupport = {
  support: {
    enterprise_plan: {
      change_ticket_status: 'change_ticket_status',
      upgrade_rate: 'upgrade_rate',
      upgrade_to_enterprise: 'upgrade_to_enterprise',
    },
    navbar: {
      account_review: 'account_review',
    },
    number_purchase: {
      archive_number: 'archive_number',
      change_purchase_status: 'change_purchase_status',
      change_ticket_status: 'change_ticket_status',
    },
    users_list: {
      change_email: 'change_email',
      deactivate_user: 'deactivate_user',
      generate_otp: 'generate_otp',
      unblock_otp: 'unblock_otp',
      view_activities: 'view_activities',
      wipe_data: 'wipe_data',
    },
    workspace_list: {
      add_number: 'add_number',
      cancel_subscription: 'cancel_subscription',
      delete_workspace: 'delete_workspace',
      downgrade_to_monthly: 'downgrade_to_monthly',
      download_charge_details: 'download_charge_details',
      view_credit_logs: 'view_credit_logs',
      view_invoices: 'view_invoices',
      view_members: 'view_members',
      delete_active_number: 'delete_active_number',
      additional_features: 'additional_features',
      make_number_free: 'make_number_free',
    },
  },
};

export function usePrivilegeSupport(screen: string, module: string, feature: string) {
  const authData: any = cache?.readQuery({
    query: FETCH_SUPPORT_AUTH_DATA,
  });
  const roleData = authData?.supportPermissions?.data;
  const hasAccess = roleData?.[screen]?.[module]?.[feature] ?? false;

  return [hasAccess];
}
