import { AuthContext } from 'contexts/auth/AuthContext';
import { FORCE_CONTROL_CAMPAIGN } from 'graphql/sales-dialer/campaign/mutation';
import { useContext } from 'react';

type ForcePausePayload = {
  id: string;
  conversationId: string;
  action: string;
};

const useForceCampaignPause = () => {
  const { accessToken } = useContext(AuthContext);

  const forcePauseCampaign = async (data: ForcePausePayload) => {
    const url = `${process.env.REACT_APP_SALES_DIALER_API_ENDPINT}/graphql/`;
    const query = FORCE_CONTROL_CAMPAIGN;

    const payload = JSON.stringify({
      operationName: 'controlCampaign',
      query: query.loc?.source.body,
      variables: {
        data,
      },
    });

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${accessToken}`,
      },
      body: payload,
      keepalive: true,
    })
      .then(response => {
        if (!response.ok) {
          // If the response is not OK (e.g., 400 Bad Request), throw an error
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log(response.json());
        return response.json();
      })
      .then(resData => console.log('Response:', resData))
      .catch(error => console.error('Error:', error));
  };

  return {
    forcePauseCampaign,
  };
};
export default useForceCampaignPause;
