import { useTranslation } from 'react-i18next';
import { SMS_MAX_LENGTH } from 'schema-validation/common';

import { Send } from 'components/atoms/icon';

import { useDeclineWithMessage } from './useDeclineWithMessage';
import * as S from './Styles';

export const DeclineWithMessage = () => {
  const { t } = useTranslation();

  const {
    formMethods: { control, watch, formState },
    handleSendMessage,
  } = useDeclineWithMessage();
  const { errors } = formState;

  return (
    <div className='mt-auto p-4'>
      <form onSubmit={handleSendMessage}>
        <div className='flex items-center gap-2.5 relative'>
          <S.InputField
            name='message'
            id='message'
            control={control}
            onPressEnter={e => {
              if (!e.shiftKey) {
                e.preventDefault();
              }
            }}
            placeholder={t('customMessage...', 'Custom message...')}
            autoSize
            maxLength={SMS_MAX_LENGTH}
          />
          <S.SendButton type='submit' disabled={!watch('message') || !!errors?.message}>
            <Send />
          </S.SendButton>
        </div>
      </form>
    </div>
  );
};
