// DTMF ROW FREQUENCIES
const ROW_1 = 697;
const ROW_2 = 770;
const ROW_3 = 852;
const ROW_4 = 941;

// DTMF COLUMN FREQUENCIES
const COL_1 = 1209;
const COL_2 = 1336;
const COL_3 = 1477;

// DTMF KEY FREQUENCY PAIRS
export const KEY_1 = [ROW_1, COL_1];
export const KEY_2 = [ROW_1, COL_2];
export const KEY_3 = [ROW_1, COL_3];

export const KEY_4 = [ROW_2, COL_1];
export const KEY_5 = [ROW_2, COL_2];
export const KEY_6 = [ROW_2, COL_3];

export const KEY_7 = [ROW_3, COL_1];
export const KEY_8 = [ROW_3, COL_2];
export const KEY_9 = [ROW_3, COL_3];

export const KEY_STAR = [ROW_4, COL_1];
export const KEY_0 = [ROW_4, COL_2];
export const KEY_POUND = [ROW_4, COL_3];
