import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/atoms';

import * as S from './StylesConfirmModal';
import { StyledModal } from './Styles';
import { useContactsMutation } from '../useContactsMutation';

interface IModalProps {
  isUploaded: boolean;
  resetFileUpload: () => void;
  contacts: any;
  csvFile: any;
}

export const ConfirmImportModal = ({
  isUploaded,
  contacts,
  resetFileUpload,
  csvFile,
}: IModalProps) => {
  const { t } = useTranslation();
  const numberOfContacts = contacts?.data?.length;

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  useEffect(() => setConfirmModalOpen(isUploaded), [isUploaded]);

  const closeModal = () => {
    setConfirmModalOpen(false);
  };

  const { uploadCsvContact, isImporting } = useContactsMutation({ numberOfContacts, closeModal });
  const onVisibilityChange = (evt: any) => {
    setChecked(evt.target.checked);
  };

  const handleCSVImport = () => {
    const payloads = {
      file: csvFile,
      // visibility: !checked,
      visibility: true, // Save all imported contact as public
    };
    uploadCsvContact(payloads);
  };
  return (
    <StyledModal
      width={361}
      centered
      destroyOnClose
      visible={isConfirmModalOpen}
      onCancel={closeModal}
      closable={false}
      footer={
        <div className='flex justify-between w-full'>
          <S.CancelButton type='link' size='large' onClick={closeModal}>
            {t('common.action.cancel', 'Cancel')}
          </S.CancelButton>
          <S.SuccessButton
            type='primary'
            size='large'
            onClick={handleCSVImport}
            loading={isImporting}
            disabled={isImporting}
          >
            {t('common.action.import', 'Import')}
          </S.SuccessButton>
        </div>
      }
      maskClosable
      afterClose={resetFileUpload}
      className='import-confirmation-modal'
    >
      <S.ConfirmModalFields>
        <Icon className='contact-import-icon' name='import-contacts' />
        <h6 className='mb-3'>
          {t('title.importContacts', 'Import Contacts')} <span>{numberOfContacts}</span>
        </h6>
        {/* <Checkbox defaultChecked={true} onChange={onVisibilityChange}>
          {t('saveImportedContactsPrivately', 'Save imported contacts privately')}
        </Checkbox> */}
      </S.ConfirmModalFields>
    </StyledModal>
  );
};
