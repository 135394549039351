import { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isValidPhoneNumber } from 'components/utils/phone-lib';
import * as path from 'constants/routes';
import { useTwilioContext } from 'lib/twilio';
import { ToastMessage } from 'components/atoms';
import { OutgoingProps } from 'lib/twilio/types';
import { AuthContext } from 'contexts/auth/AuthContext';
import { isAssignedNumber } from 'components/organisms/common/utils';
import { ACTIONS } from 'lib/twilio/constants';
import { useReadChannelsByCache } from 'hooks/useReadChannelsByCache';

import { useOutgoingCallHandler } from './useOutgoingCallHandler';

export function useCallOrMessage(props?: any) {
  const { cachedChannelsData: cachedData } = useReadChannelsByCache();

  const history = useHistory();
  const { t } = useTranslation();
  const { loggedInMemberId, activeWorkspaceId, isNumberSubscriptionExpired } = useContext(
    AuthContext,
  );
  const { handleDeviceOutgoing, dispatch } = useTwilioContext();
  const { channelId } = useParams<Record<string, string | undefined>>();

  const handleOutgoingCall = (callParams: OutgoingProps) => {
    handleDeviceOutgoing(callParams);
  };
  // added useOutgoingCallHandler to check if internationalCalls disabled before outgoing call
  const { callClient } = useOutgoingCallHandler({ callback: handleOutgoingCall });

  const client = props?.client;
  const isValidNumber = isValidPhoneNumber(client?.number);

  const generalcallParams = {
    To: client?.number,
    workspace_sid: activeWorkspaceId,
    agent_id: loggedInMemberId,
  };

  const gotoBuyNumberPage = () => {
    history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`);
  };

  const openDialer = () => {
    dispatch({
      type: ACTIONS.SHOW_DIALER,
      data: {
        contactNumber: client?.number,
      },
    });
  };

  const onCallNow = () => {
    // TODO: REMOVE CONSOLE LOGS
    console.log('get channels list from cached data for call ->', cachedData);
    const channels = cachedData?.channels?.data ?? [];
    const channelCount = channels?.length ?? 0;
    const activeChannel = channels?.find(channel => channel?.id === channelId);
    const isPaid = activeChannel?.numberCheckoutPrice?.basePrice > 0;
    if (!isValidNumber) {
      ToastMessage({
        content: `${t('toast.invalidPhoneNumber', 'Phone number is invalid')}`,
        type: 'danger',
      });
      return;
    }
    if (isAssignedNumber(channels, client.number)) {
      ToastMessage({
        content: `${t(
          'toast.dialAssignedNumberError',
          'Number is assigned to one of your channel',
        )}`,
        type: 'danger',
      });
      return;
    }
    if (isNumberSubscriptionExpired && isPaid) {
      ToastMessage({
        content: 'Number subscription has been expired. Please try with active number',
        type: 'danger',
      });
      return;
    }

    if (activeChannel) {
      if (!activeChannel?.call) {
        ToastMessage({
          content: `${t('toast.callNotSupported', 'Call not supported')}`,
          type: 'danger',
        });
        return;
      }
      // handle call for chatbox. No need to check channelCount
      callClient({
        ...generalcallParams,
        From: activeChannel.number,
        channel_sid: activeChannel.id,
      });
      return;
    }

    if (channelCount < 1 && !channelId) {
      gotoBuyNumberPage();
      return;
    }
    if (channelCount === 1 && channels[0]) {
      callClient({
        ...generalcallParams,
        From: channels[0].number,
        channel_sid: channels[0].id,
      });
    }
    if (channelCount > 1) {
      openDialer();
    }
  };

  return {
    onCallNow,
  };
}
