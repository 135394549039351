import { ReactNode } from 'react';
import { RiCloseLine } from 'react-icons/ri';

import { DrawerContainer } from './Styles';

export interface IProps {
  children: React.ReactNode;
  setOpen: (data: any) => void;
  open: string | null;
  title?: ReactNode;
  closable?: boolean;
  maskClosable?: boolean;
  height?: string | number;
}

const WidgetDrawer = ({
  children,
  setOpen,
  open,
  title,
  closable = false,
  maskClosable = true,
  height,
}: IProps) => {
  const onClose = () => {
    setOpen(null);
  };

  return (
    <DrawerContainer
      title={title}
      placement='bottom'
      closable={closable}
      onClose={onClose}
      visible={!!open}
      getContainer={false}
      closeIcon={<RiCloseLine size={18} color='#212121' />}
      style={{ position: 'absolute' }}
      maskClosable={maskClosable}
      height={height}
    >
      {children}
    </DrawerContainer>
  );
};

export default WidgetDrawer;
