import styled from 'styled-components';

interface ProfileWallProps {
  profile?: any;
  component: any;
}
const GradientBox = styled.div`
  height: 100px;
  background: linear-gradient(269.17deg, #390179 20.52%, #2e0b56 95.07%);
  border-radius: 10px 10px 0px 0px;
`;
const ProfileWall = ({ profile, component }: ProfileWallProps) => {
  return (
    <div className='relative bg-primary-50 border border-primary-100 rounded-10'>
      {profile && (
        <div className='absolute left-1/2 top-12 transform -translate-x-1/2 '>{profile}</div>
      )}
      <GradientBox />
      <div className='px-4 mt-16.5 pb-5'>{component}</div>
    </div>
  );
};

export default ProfileWall;
