import { gql } from '@apollo/client';

export const MEMBER_INFO = gql`
  fragment MemberInfoFields on MemberInfo {
    id
    firstname
    lastname
    picture
  }
`;

export const CHAT_NODE = gql`
  fragment ChatFields on ChatMessageNode {
    id
    sender {
      ...MemberInfoFields
    }
    receiver {
      ...MemberInfoFields
    }
    message
    type
    createdOn
    modifiedOn
    status
  }
  ${MEMBER_INFO}
`;
