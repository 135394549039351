/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import { Skeleton } from 'antd';
import * as yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsLockFill } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';
import { yupResolver } from '@hookform/resolvers/yup';

import { cardInputs } from 'schema-validation';
import { Input } from 'components/molecules/fields';
import { Button, Icon } from 'components/atoms';

import { PaymentMethodEnum } from 'constants/paymentGateway';
import { usePaymentMethodHandler } from 'components/pages/settings/plan-subscription/hooks/usePaymentMethodHandler';
import { StyledPaypalButton } from 'components/pages/authentication/account-setup-guide/Styles';
import CardSection from './CardSection';
import * as S from './Styles';
import useOptions from '../hooks/useOptions';
import PayalLogo from '../../../assets/icons/paypal.svg';

interface AddCardModalProps {
  isModalOpen: boolean;
  onModalCancel: (...args: any[]) => any;
  width?: string | number;
  formId?: string;
}
interface CardsInputs {
  cardHolderName: string;
  billingZip: any;
}

const schema = yup.object().shape({
  cardHolderName: cardInputs.cardHolderName,
  billingZip: cardInputs.billingZip,
});
export default function CardSetupForm(props: AddCardModalProps) {
  const [errorsObj, setErrorsObj] = useState({});
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodEnum>(
    PaymentMethodEnum.Card,
  );

  const { t } = useTranslation();
  const { styles, classes } = useOptions();
  const [isCardDetailsVisible, setCradDetialsVisible] = useState(true);
  const { isModalOpen, onModalCancel, width, formId } = props;
  const cbInstance = window?.Chargebee?.getInstance?.();

  const {
    addCardLoading,
    cardRef,
    errorMsg,
    processing,
    loadingPaypalBtn,
    paymentIntentLoading,
    setErrorMsg,
    loadPaypalButton,
    fetchPaymentIntent,
    handlePaymentWithCard,
  } = usePaymentMethodHandler({ onModalCancel });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CardsInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: { cardHolderName: '', billingZip: '' },
  });

  const handleToggleMethodType = (type: any) => (data: any) => {
    if (processing || type === paymentMethodType) return;
    setPaymentMethodType(type);
    setCradDetialsVisible(type === PaymentMethodEnum.Card);
    if (type === PaymentMethodEnum.Paypal && !loadingPaypalBtn) {
      loadPaypalButton(0);
    }
  };

  const onReady = async () => {
    console.info('card component ready');
  };

  const onChange = (event: any) => {
    const err: any = errorsObj;
    let msg: any = '';

    if (event.error) {
      // If error is present, display the error
      err[event.field] = event.error;
      msg = event.error.message;
    } else {
      err[event.field] = null;
      // If there's no error, check for existing error
      const _err = Object.values(err).filter(val => val);

      // The errorObject holds a message and code
      // Custom error messages can be displayed based on the error code
      const errorObj: any = _err.pop();

      // Display existing message
      if (errorObj) msg = errorObj.message;
      else msg = '';
    }
    setErrorsObj(err);
    setErrorMsg(msg);
  };

  const onSubmit = async (values: any) => {
    const additionalData = {
      firstName: values?.cardHolderName?.split(' ')?.[0],
      lastName: values?.cardHolderName?.split(' ')?.[1],
      billingZip: values?.billingZip,
    };

    if (cardRef?.current && !paymentIntentLoading) {
      const paymentIntentData = await fetchPaymentIntent(0, PaymentMethodEnum.Card);
      if (!paymentIntentData) return;
      handlePaymentWithCard(paymentIntentData, additionalData);
    }
  };
  const loadingAddCardBtn = processing || addCardLoading || paymentIntentLoading;

  return (
    <S.StyledModal
      width={width ?? 448}
      centered
      visible={isModalOpen}
      onCancel={onModalCancel}
      footer={null}
      closable={false}
      maskClosable={!(processing || addCardLoading)}
      data-cy='add-new-card-modal'
    >
      <div className='flex flex-col items-center mb-10'>
        <h5 data-cy='add-new-card-title' className='text-xl text-gray-700 font-bold leading-snug'>
          {t('modal.title.addCardNew', 'Add New Card')}
        </h5>
        <p
          data-cy='add-new-card-desc'
          className='text-15 text-gray font-normal text-center leading-normal'
        >
          Payment method for your workspace
        </p>
        {errorMsg && (
          <div className='flex text-error'>
            <RiErrorWarningFill className='m-1 flex-shrink-0' size={16} />
            <p className='text-15 font-normal text-center leading-normal'>{errorMsg}</p>
          </div>
        )}
      </div>
      <S.AddCardPaymentMethodWrapper className='flex mb-6'>
        <button
          type='button'
          onClick={handleToggleMethodType(PaymentMethodEnum.Card)}
          data-cy='add-new-card-btn-card'
          className={`${
            paymentMethodType === PaymentMethodEnum.Card ? 'active' : ''
          } border p-3 rounded-lg w-full flex justify-center items-center`}
        >
          <Icon name='icon-credit-card' />
          <h6 className=' text-sm font-semibold leading-4 ml-2'>Card</h6>
        </button>
        {process.env.REACT_APP_ENABLE_PAYPAL === 'true' && (
          <button
            type='button'
            id='paypal-btn'
            onClick={handleToggleMethodType(PaymentMethodEnum.Paypal)}
            className={`${
              paymentMethodType === PaymentMethodEnum.Paypal ? 'active' : ''
            } border p-3 rounded-lg w-full ml-5 flex justify-center`}
            data-cy='add-new-card-btn-paypal'
          >
            <img src={PayalLogo} alt='Paypal' />
          </button>
        )}
      </S.AddCardPaymentMethodWrapper>
      {isCardDetailsVisible ? (
        <>
          <form id='save-card-form' onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-6'>
              <Input
                data-cy='add-new-card-input-name'
                name='cardHolderName'
                id='name'
                control={control}
                placeholder='John  Doe'
                label={t('form.label.cardHoldersName', 'Card Holder’s Name')}
                errors={errors}
              />
            </div>
            <CardComponent
              ref={cardRef}
              styles={styles}
              classes={classes}
              className='fieldset field cy-add-new-card-input-number'
              onChange={onChange}
              onReady={onReady}
            >
              <CardSection />
            </CardComponent>
            <div className='mb-6'>
              <Input
                name='billingZip'
                id='billingZip'
                control={control}
                label={t('form.label.zip', 'ZIP/Postal code')}
                errors={errors}
                data-cy='add-new-card-input-postal'
              />
            </div>
          </form>
          <div>
            <Button
              key='submit'
              form={formId}
              disabled={!cardRef || !cbInstance || loadingAddCardBtn}
              loading={loadingAddCardBtn}
              className='capitalize btn w-full btn--ok--primary'
              type='primary'
              size='large'
              htmlType='submit'
              data-cy='add-new-card-btn-add-card'
            >
              <h6 className='text-white text-sm font-semibold leading-4'>
                {loadingAddCardBtn
                  ? `${t('processing', 'Processing')}...`
                  : t('modal.title.addCard', 'Add Card')}
              </h6>
            </Button>
            <div className='flex flex-col items-center mt-3'>
              <p className='text-15 text-gray font-normal text-center leading-normal'>
                <div className='flex items-center' data-cy='add-new-card-pci-compliant'>
                  <BsLockFill className='text-success mr-1' />
                  PCI-compliant, secure and encrypted
                </div>
              </p>
            </div>
          </div>
        </>
      ) : (
        <StyledPaypalButton>
          <div id='paypal-button' />
          {loadingPaypalBtn && <Skeleton.Button block active size='large' />}
        </StyledPaypalButton>
      )}
    </S.StyledModal>
  );
}
