import { useContext } from 'react';

import { Icon, Button } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';

import * as S from './Styles';

interface InvitationSentModalProps {
  isModalOpen: boolean;
  onModalCancel: () => void;
  width?: number | string;
  openAddMemberModal: () => void;
  email: string;
}

const InvitationSentModal = (props: InvitationSentModalProps) => {
  const { isModalOpen, onModalCancel, width, openAddMemberModal, email } = props;

  const handleInviteMore = () => {
    onModalCancel();
    openAddMemberModal();
  };

  const { loggedInMemberInfo } = useContext(AuthContext);

  return (
    <S.StyledModal
      className='modal'
      width={width ?? 378}
      centered
      destroyOnClose
      visible={isModalOpen}
      footer={null}
      onCancel={onModalCancel}
      closable={false}
      data-cy='invitation-modal'
    >
      <Icon name='member-invited' />
      <h5 data-cy='invitation-title'>Invitation sent</h5>
      <p>
        <span className='font-medium'>{email}</span> is invited to workspace{' '}
        {loggedInMemberInfo?.title}
      </p>
      <div className='flex justify-between items-center mt-6 w-full'>
        <Button
          data-cy='invite-more-btn'
          size='large'
          className='invite-more'
          type='ghost'
          onClick={handleInviteMore}
        >
          Invite more
        </Button>
        <Button
          data-cy='done-btn'
          size='large'
          type='primary'
          className='done'
          onClick={onModalCancel}
        >
          Done
        </Button>
      </div>
    </S.StyledModal>
  );
};

export default InvitationSentModal;
