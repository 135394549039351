import { RiCalendar2Line } from 'react-icons/ri';
import { Icon } from 'components/atoms';

interface IProps {
  title?: string;
  calls?: string | number;
  day?: any;
}

function ActivityStat({ title, calls, day }: IProps) {
  return (
    <div className='min-h-130 bg-white p-6'>
      <h6 className='uppercase  text-xs text-gray-500 font-semibold leading-normal tracking-0.04'>
        {title}
      </h6>
      <h5 className='text-2xl normal-case text-gray-600 font-bold leading-9.75'>
        {calls} <span className='text-sm'>Calls</span>
      </h5>
      <div className='flex items-center mt-0.5'>
        <Icon name='calendar' className='w-4 h-4 text-gray mr-2' />
        <span className='text-13 text-gray-600 leading-4'>{day}</span>
      </div>
    </div>
  );
}

export default ActivityStat;
