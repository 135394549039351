export const deviceEvent = {
  INCOMING: 'incoming',
  UNREGISTERED: 'unregistered',
  REGISTERING: 'registering',
  REGISTERED: 'registered',
  DESTROYED: 'destroyed',
  ERROR: 'error',
};

export const callEvent = {
  CANCEL: 'cancel',
  DISCONNECT: 'disconnect',
  CONNECT: 'connect',
  RINGING: 'ringing',
  ACCEPT: 'accept',
  REJECT: 'reject',
  ERROR: 'error',
};

export const ACTIONS = {
  SHOW_DIALER: 'show-dialer',
  INCOMING_CALL: 'incoming-call',
  ANSWER_INCOMING_CALL: 'answer-incoming-call',
  REJECT_INCOMING_CALL: 'reject-incoming-call',
  INCOMING_CALL_CANCEL: 'incoming-call-cancel',
  INCOMING_CALL_ERROR: 'incoming-call-error',
  INCOMING_CALL_DISCONNECT: 'incoming-call-disconnect',
  OUTGOING_CALL_ENABLED: 'outgoing-call-enabled',
  OUTGOING_CALL_INITIATED: 'outgoing-call-initiated',
  OUTGOING_CALL_RINGING: 'outgoing-call-ringing',
  OUTGOING_CALL_ANSWERED: 'outgoing-call-answered',
  OUTGOING_CALL: 'outgoing-call',
  OUTGOING_CALL_REJECTED: 'reject-outgoing-call',
  OUTGOING_CALL_DISCONNECT: 'outgoing-call-disconnect',
  CALL_HOLD: 'outgoing-call-hold',
  CALL_UNHOLD: 'outgoing-call-unhold',
  RESET: 'reset',
  CLOSE_PHONE_WIDGET: 'close-phone-widget',
  CALL_ENDED: 'call-ended',
  SET_LIVECALL: 'set-live-call-instance',
  CALL_TRANSFER_INITIAED: 'call-transfer-initiated',
  CALL_TRANSFER_INPROGRESS: 'call-transfer-inprogress',
  CALL_TRANSFER_FAILED: 'call-transfer-failed',
  CALL_TRANSFER_SUCCESS: 'call-transfer-success',
  CAMPAIGN_CALL_INITATED: 'campaign-call-initiated',
  CAMPAIGN_CALL_CONNECTED: 'campaign-call-connected',
  CAMPAIGN_CALL_DISCONNECTED: 'campaign-call-disconnected',
  CAMPAIGN_CALL_ENDED: 'campaign-call-ended',
  CAMPAIGN_STOPPED: 'campaign-stopped',
  CAMPAIGN_COMPLETED: 'campaign-completed',
  CAMPAIGN_CALL_SKIPPED: 'campaign-call-skipped',
  CAMPAIGN_PAUSED: 'campaign-paused',
  CAMPAIGN_INPROGRESS: 'campaign-inprogress',
  CAMPAIGN_DISCONNECT_INTERNET: 'campaign-disconnect-internet',
  CLOSE_SALES_DIALER_WIDGET: 'close-sales-dialer-widget',
};

export const CALL_WIDGET_STATUS = {
  INITIATE: 'initiate',
  INITIATED: 'initiated',
  CALLING: 'calling',
  RINGING: 'ringing',
  ANSWERED: 'answered',
  REJECTED: 'rejected',
  DISCONNECTED: 'disconnected',
  FAILED: 'failed',
  ENDED: 'ended',
  PAUSED: 'paused',
  TRANSFERRING: 'transferring',
  TRANSFER_SUCCESS: 'transfer-success',
  TRANSFER_FAILED: 'transfer-failed',
  CAMPAIGN_CALL_CONNECTED: 'callConnected',
  CAMPAIGN_CALL_DISCONNECTED: 'callDisconnected',
};

const { REJECTED, DISCONNECTED, FAILED, ENDED } = CALL_WIDGET_STATUS;

export const ENDED_CALL_STATES = [REJECTED, DISCONNECTED, FAILED, ENDED];

export const CAMPAIGN_STATUS = {
  ACTIVE: 'active',
  ENDED: 'ended',
  PAUSED: 'paused',
  INPROGRESS: 'inprogress',
};

export const CAMPAIGN_STATUS_RESPONSE = {
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  PAUSED: 'PAUSED',
};
