import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import cache from 'services/apollo/cache';
import { ToastMessage } from 'components/atoms';
import { RE_ORDER_WORKSPACE } from 'graphql/foundation';

export function useReorderWorkspace() {
  const { t } = useTranslation();
  const [changeOrder] = useMutation(RE_ORDER_WORKSPACE, {
    onCompleted: response => {
      const { error } = response.workspaceOrder;
      if (error === null) {
        ToastMessage({
          content: t('workspaceOrderSaved', 'Workspace order changed and saved.'),
          type: 'success',
        });
        cache.modify({
          fields: {
            workspaces: () => {},
          },
        });
      }
    },
    onError: () =>
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' }),
  });

  const onOrderChange = async (result: any, workspaceList: any) => {
    const hasNoDestination = !result.destination;
    const hasSameIndex = result?.destination?.index === result?.source?.index;
    if (hasNoDestination || hasSameIndex) {
      return;
    }

    const sourceId = result.draggableId;
    const destinationIndex = result.destination.index;
    const destinationOrder = +workspaceList[destinationIndex]?.order;
    const destinationId = workspaceList[destinationIndex].id;
    const sourceIndex = result.source.index;
    const dataTobeSwapped = [
      {
        workspace_id: sourceId,
        order: destinationOrder || destinationIndex,
      },
      {
        workspace_id: destinationId,
        order: sourceIndex,
      },
    ];
    await changeOrder({
      variables: {
        data: {
          workspaceorder: dataTobeSwapped,
        },
      },
    });
  };
  return {
    onOrderChange,
  };
}
