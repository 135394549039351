import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AuthContext } from 'contexts/auth/AuthContext';
import { Button, Icon } from 'components/atoms';

import { StyledModal } from './Styles';

interface RestoreModalProps {
  open: boolean;
  onOk: () => void;
}

const RestoreModal = ({ open, onOk }: RestoreModalProps) => {
  const { t } = useTranslation();
  const { planRemainingDays, loggedInMemberInfo } = useContext(AuthContext);
  return (
    <StyledModal
      width={438}
      className='restore-modal'
      centered
      visible={open}
      maskClosable={false}
      keyboard={false}
      zIndex={500}
      wrapClassName='blurred-wrapper'
      footer={[
        <Button key='submit' type='primary' className='capitalize btn' size='large' onClick={onOk}>
          {t('common.action.restoreWorkspace', 'Restore Workspace')}
        </Button>,
      ]}
      closable={false}
    >
      <Icon name='restore-workspace' />
      <h5 className='text-center text-xl text-gray-700 leading-7 font-bold mb-2'>
        {t('restoreWorkspace', 'Restore Workspace')}
      </h5>
      <p className='text-15 leading-5.75 text-gray text-center'>
        <Trans i18nKey='restoreModal.info'>
          Restore your workspace '{{ restoreWorkspaceTitle: loggedInMemberInfo?.title }}' before it
          gets permanently deleted in
          <span className='font-medium'>
            {{ remainingDays: planRemainingDays ? Math.abs(planRemainingDays) : 0 }} days.
          </span>
        </Trans>
      </p>
    </StyledModal>
  );
};

export default RestoreModal;
