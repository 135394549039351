/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Controller } from 'react-hook-form';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/atoms/fields';
import { Label, Error } from 'components/atoms';
import { InputFieldWrapper, FlexColumn } from './styled';
import { InputProps } from './types';

interface SelectProps extends InputProps {
  mode?: 'multiple' | 'tags' | undefined;
  maxTagCount?: number | 'responsive';
  dropdownRender?: (originNode: React.ReactNode) => React.ReactNode;
  isEditable?: boolean;
  showSearch?: boolean;
  dropdownClassName?: string;
  filterOption?: (input: string, option: Record<string, string>) => boolean;
  getPopupContainer?: any;
  onSearch?: any;
  labelInValue?: boolean;
  defaultValue?: any;
  options?: any;
  removeIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  menuItemSelectedIcon?: React.ReactNode;
  listHeight?: number;
  listItemHeight?: number;
  optionLabelProp?: string;
  open?: boolean;
  onDropdownVisibleChange?: (visible: any) => any;
  onDeselect?: (args: any) => any;
  notFoundContent?: React.ReactNode;
  size?: string;
  dropdownStyle?: React.CSSProperties;
  dropdownAlign?: any;
}

// const SelectInput = React.forwardRef<HTMLInputElement, SelectProps>((props: SelectProps, ref) => {
const SelectInput: React.FC<SelectProps> = props => {
  const {
    label,
    labelWeight,
    id,
    name,
    placeholder,
    required = false,
    children,
    errors,
    control,
    onChange,
    onBlur,
    onClick,
    mode,
    maxTagCount,
    dropdownRender,
    dropdownClassName,
    className,
    isEditable,
    defaultValue,
    options,
    autoFocus,
    showSearch,
    filterOption,
    onSearch,
    disabled,
    labelInValue,
    size,
    getPopupContainer,
    removeIcon,
    listHeight,
    listItemHeight,
    optionLabelProp,
    open,
    onDropdownVisibleChange,
    onDeselect,
    suffixIcon,
    notFoundContent,
    menuItemSelectedIcon,
    dropdownStyle,
    dropdownAlign,
    ...rest
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <InputFieldWrapper>
        <FlexColumn>
          {label && (
            <Label id={id || name} required={required} fontWeight={labelWeight}>
              {label}
            </Label>
          )}
          {!control && (
            <Select
              className={className}
              {...rest}
              getPopupContainer={
                getPopupContainer ??
                ((triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement)
              }
              dropdownClassName={dropdownClassName}
              placeholder={placeholder}
              id={id || name}
              mode={mode}
              maxTagCount={maxTagCount}
              suffixIcon={suffixIcon || <RiArrowDownSLine />}
              dropdownRender={dropdownRender}
              defaultValue={defaultValue ?? undefined} // must be 'undefined' to display placeholder
              showSearch={showSearch}
              filterOption={filterOption || false}
              onChange={onChange}
              onSearch={onSearch}
              onBlur={onBlur}
              autoFocus={autoFocus}
              disabled={disabled}
              labelInValue={labelInValue || false}
              menuItemSelectedIcon={menuItemSelectedIcon}
              removeIcon={removeIcon}
              listHeight={listHeight}
              listItemHeight={listItemHeight}
              optionLabelProp={optionLabelProp}
              open={open}
              onDropdownVisibleChange={onDropdownVisibleChange}
              onDeselect={onDropdownVisibleChange}
              size={size}
              options={options}
              notFoundContent={notFoundContent}
              dropdownStyle={dropdownStyle}
              dropdownAlign={dropdownAlign}
            >
              {children}
            </Select>
          )}
          {control && (
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return isEditable === false ? (
                  <p
                    className={`${field.value ? 'word-break' : 'input-placeholder'}`}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      if (onClick) {
                        onClick(event);
                      }
                    }}
                  >
                    {field.value || placeholder}
                  </p>
                ) : (
                  <Select
                    {...field}
                    {...rest}
                    getPopupContainer={
                      getPopupContainer ??
                      ((triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement)
                    }
                    className={className}
                    dropdownClassName={dropdownClassName}
                    placeholder={placeholder}
                    id={id || name}
                    mode={mode}
                    menuItemSelectedIcon={menuItemSelectedIcon}
                    maxTagCount={maxTagCount}
                    suffixIcon={suffixIcon || <RiArrowDownSLine />}
                    dropdownRender={dropdownRender}
                    defaultValue={defaultValue ?? undefined} // must be 'undefined' to display placeholder
                    showSearch={showSearch}
                    filterOption={filterOption || false}
                    value={field.value}
                    onSearch={onSearch}
                    labelInValue={labelInValue || false}
                    size={size}
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                      if (onChange) {
                        onChange(value);
                        field.onChange(value);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                      if (onBlur) {
                        onBlur(event);
                      } else {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        field?.onBlur && field.onBlur();
                      }
                    }}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    removeIcon={removeIcon}
                    listHeight={listHeight}
                    listItemHeight={listItemHeight}
                    optionLabelProp={optionLabelProp}
                    open={open}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                    onDeselect={onDeselect}
                    options={options}
                    notFoundContent={notFoundContent}
                    dropdownStyle={dropdownStyle}
                    dropdownAlign={dropdownAlign}
                  >
                    {children}
                  </Select>
                );
              }}
            />
          )}
          {errors?.[name] && <Error>{t(`constants:${errors[name].message}`)}</Error>}
        </FlexColumn>
      </InputFieldWrapper>
    </>
  );
};

SelectInput.displayName = 'SelectInput';
export default SelectInput;
