export default function NoteTextFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.6193 7.06C20.3694 6.46842 20.0058 5.9316 19.5493 5.47998C19.0944 5.01556 18.5501 4.64814 17.9493 4.39996C17.583 4.24931 17.2004 4.14191 16.8093 4.07996V2.75C16.8093 2.55109 16.7303 2.36032 16.5896 2.21967C16.449 2.07901 16.2582 2 16.0593 2C15.8604 2 15.6696 2.07901 15.529 2.21967C15.3883 2.36032 15.3093 2.55109 15.3093 2.75V4.01996H8.69927V2.75C8.69927 2.55109 8.62026 2.36032 8.47961 2.21967C8.33895 2.07901 8.14818 2 7.94927 2C7.75036 2 7.55959 2.07901 7.41894 2.21967C7.27828 2.36032 7.19927 2.55109 7.19927 2.75V4.07996C6.16401 4.23273 5.20617 4.71693 4.46929 5.45996C4.01116 5.91604 3.64798 6.45845 3.40081 7.05579C3.15364 7.65312 3.02737 8.29355 3.02929 8.94V17.04C3.02768 17.6914 3.15336 18.3368 3.39928 18.94C3.64031 19.5398 4.00097 20.0841 4.45928 20.54C4.91415 21.0044 5.45845 21.3718 6.05929 21.62C6.65767 21.8713 7.30024 22.0005 7.94927 22H16.0593C17.3616 21.9947 18.6089 21.4743 19.5289 20.5525C20.4488 19.6307 20.9666 18.3823 20.9693 17.08V8.96997C20.9856 8.31597 20.8665 7.66569 20.6193 7.06ZM16.6193 16.65H7.44927C7.25036 16.65 7.05959 16.571 6.91894 16.4303C6.77828 16.2896 6.69927 16.0989 6.69927 15.9C6.69927 15.7011 6.77828 15.5103 6.91894 15.3696C7.05959 15.229 7.25036 15.15 7.44927 15.15H16.5493C16.7482 15.15 16.939 15.229 17.0796 15.3696C17.2203 15.5103 17.2993 15.7011 17.2993 15.9C17.2993 16.0989 17.2203 16.2896 17.0796 16.4303C16.939 16.571 16.7482 16.65 16.5493 16.65H16.6193ZM16.6193 11.58H7.44927C7.25036 11.58 7.05959 11.5009 6.91894 11.3603C6.77828 11.2196 6.69927 11.0289 6.69927 10.83C6.69927 10.631 6.77828 10.4403 6.91894 10.2996C7.05959 10.159 7.25036 10.08 7.44927 10.08H16.5493C16.7482 10.08 16.939 10.159 17.0796 10.2996C17.2203 10.4403 17.2993 10.631 17.2993 10.83C17.2993 11.0289 17.2203 11.2196 17.0796 11.3603C16.939 11.5009 16.7482 11.58 16.5493 11.58H16.6193Z'
        fill='currentColor'
      />
    </svg>
  );
}
