import { useTwilioContext } from 'lib/twilio';
import { ReactElement } from 'react';
import { RiCloseLine } from 'react-icons/ri';

interface ICampaignCallScriptProps {
  handleClose: () => void;
  callScriptTemplate: any;
}

export function CampaignCallScript({
  handleClose,
  callScriptTemplate,
}: ICampaignCallScriptProps): ReactElement {
  const {
    state: { connection },
  } = useTwilioContext();

  const scriptTemplate = callScriptTemplate?.description;
  const contactName = connection?.customParameters?.get('contactName') || '';
  const contactNumber = connection?.customParameters?.get('contact_number') || '';

  const callScript = scriptTemplate
    ?.replaceAll('[NAME]', contactName)
    ?.replaceAll('[NUMBER]', contactNumber);

  const handleCloseBtnClick = () => {
    handleClose();
  };

  return (
    <div>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-center '>
          Scripts
        </p>
      </div>
      <div className='pt-3 pb-2.5 px-4 text-left'>
        <div className='whitespace-pre-wrap'>{callScript}</div>
      </div>
    </div>
  );
}
