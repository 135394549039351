import ContactTable from './ContactTable';
import { HandlersProvider } from './HandlersContext';

export default function ContactsIndex() {
  return (
    <HandlersProvider>
      <ContactTable />
    </HandlersProvider>
  );
}
