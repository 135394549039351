import { useEffect } from 'react';
import { Select } from 'antd';
import { Trans } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import { useReactiveVar } from '@apollo/client';

import { activeCallTimerVar, clientInfoCallWidgetVar } from 'services/apollo/reactiveVars';
import { useTwilioContext } from 'lib/twilio';
import { SelectInput } from 'components/molecules/fields';
import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import { useAudioDeviceHandlers } from 'hooks/useAudioDeviceHandlers';

import * as S from './Styles';
import BackButtonHeader from '../../call-active/back-button-header';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import WidgetBanner from '../../widget-banner';
import useCallWidgetNavContext from '../../hooks/useCallWidgetNavContext';

const { Option } = Select;

export default function AudioSettings() {
  const { audioInputDevices, audioOutputDevices } = useMediaDevices();
  const {
    deviceInstance,
    state: { direction, salesDialerWidget, callInProgress },
  } = useTwilioContext();
  const { banner, setBanner } = useCallWidgetContext();
  const activeCallTimer = useReactiveVar<string>(activeCallTimerVar);

  const clientInfo = useReactiveVar(clientInfoCallWidgetVar);
  const { number, name } = clientInfo || {};

  const {
    activeMic,
    activeSpeaker,
    handleAudioInputChange,
    handleAudioOutputChange,
  } = useAudioDeviceHandlers();

  const { handleRedirectToMainScreen } = useCallWidgetNavContext();

  const { showIncomingCallBanner, showOngoingCallBanner } = useCallWidgetContext();
  const isIncomingCall = direction === 'Incoming' && !callInProgress && !salesDialerWidget;
  const isActiveCall = callInProgress;

  useEffect(() => {
    if (isIncomingCall) {
      showIncomingCallBanner?.(name || number, handleRedirectToMainScreen);
    }
    return () => {
      setBanner?.({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isActiveCall) {
      showOngoingCallBanner?.(activeCallTimer, handleRedirectToMainScreen);
    }
    return () => {
      setBanner?.({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCallTimer]);

  return (
    <>
      {!isEmpty(banner) && (
        <WidgetBanner
          title={banner?.title}
          icon={banner?.icon}
          type={banner?.type}
          showIcon={banner?.showIcon}
          closable={banner?.closable}
          alignContent={banner?.alignContent ?? 'left'}
          onClick={banner?.onClick}
          className='cursor-pointer'
        />
      )}
      <S.FieldWrapper className='pt-12 px-3.5'>
        <BackButtonHeader className='mb-4' title='Audio Settings' showArrowIcon={false} />
        <div data-cy='input-device-select' className='mb-4.5'>
          <SelectInput
            name='audio-input'
            label={
              <span className='text-gray-700 text-sm font-medium leading-none'>
                <Trans i18nKey='form.label.inputDevices'>Input</Trans>
              </span>
            }
            defaultValue={activeMic}
            onChange={handleAudioInputChange as any}
            disabled={!deviceInstance?.audio?.availableInputDevices} // setInputDevice is not supported in firefox
          >
            {audioInputDevices?.map((device: any) => {
              return (
                <Option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </Option>
              );
            })}
          </SelectInput>
        </div>
        <div data-cy='output-device-select'>
          <SelectInput
            name='audio-output'
            label={
              <span className='text-gray-700 text-sm font-medium leading-none'>
                <Trans i18nKey='form.label.outputDevices'>Output</Trans>
              </span>
            }
            defaultValue={activeSpeaker}
            onChange={handleAudioOutputChange as any}
            disabled={!deviceInstance?.audio?.isOutputSelectionSupported} // not supported in firefox
          >
            {audioOutputDevices?.map((device: any) => {
              return (
                <Option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </Option>
              );
            })}
          </SelectInput>
        </div>
      </S.FieldWrapper>
    </>
  );
}
