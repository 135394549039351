import { Dispatch, SetStateAction } from 'react';
import { useReactiveVar } from '@apollo/client';

import { Headphone2, KeyPad, SignalIcon } from 'components/atoms/icon';
import { isNetworkAvailable } from 'services/apollo/reactiveVars';
import { Tooltip } from 'components/atoms';

import { Wrapper, SidebarIconWrapper } from './Styles';
import { WIDGET_NAV_SCREENS } from '../constants';

interface SidebarProps {
  selectedNavScreen?: string;
  setSelectedNavScreen?: Dispatch<SetStateAction<string>>;
}

const WidgetSidebar = ({ selectedNavScreen, setSelectedNavScreen }: SidebarProps) => {
  const { CALL_WIDGET, AUDIO_SETTINGS } = WIDGET_NAV_SCREENS;
  const internetAvailable = useReactiveVar(isNetworkAvailable);
  const hideSignalIcon = process.env.REACT_APP_HIDE_DIALER_SIGNAL_ICON === 'true';

  const displayScreen = (screen: string) => () => {
    setSelectedNavScreen?.(screen);
  };

  return (
    <Wrapper className='h-full'>
      <SidebarIconWrapper
        className={selectedNavScreen === CALL_WIDGET ? 'active' : ''}
        onClick={displayScreen(CALL_WIDGET)}
      >
        <KeyPad />
      </SidebarIconWrapper>
      <div className='flex-grow w-full' />
      <SidebarIconWrapper
        className={selectedNavScreen === AUDIO_SETTINGS ? 'active' : ''}
        onClick={displayScreen(AUDIO_SETTINGS)}
      >
        <Headphone2 />
      </SidebarIconWrapper>
      {!hideSignalIcon && (
        <Tooltip title={internetAvailable ? 'Connected' : 'Disconnected'} placement='right'>
          <SidebarIconWrapper>
            <SignalIcon className={internetAvailable ? 'text-success' : 'text-text-200'} />
          </SidebarIconWrapper>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default WidgetSidebar;
