export default function Numberemptyicon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none' viewBox='0 0 40 40'>
      <path
        fill='#47568C'
        d='M23.516 3.516v32.968A3.51 3.51 0 0120 40H3.516A3.51 3.51 0 010 36.484V3.516A3.51 3.51 0 013.516 0H20a3.51 3.51 0 013.516 3.516z'
      />
      <path
        fill='#29376D'
        d='M23.516 3.516v32.968A3.51 3.51 0 0120 40h-8.203V0H20a3.51 3.51 0 013.516 3.516z'
      />
      <path
        fill='#6AA9FF'
        d='M21.172 3.516v32.968A1.16 1.16 0 0120 37.656H3.516a1.16 1.16 0 01-1.172-1.172V3.516a1.16 1.16 0 011.172-1.172H20a1.16 1.16 0 011.172 1.172z'
      />
      <path
        fill='#4895FF'
        d='M21.172 3.516v32.968A1.16 1.16 0 0120 37.656h-8.203V2.344H20a1.16 1.16 0 011.172 1.172z'
      />
      <path
        fill='#EDF5FF'
        d='M11.797 7.031a1.172 1.172 0 100-2.344 1.172 1.172 0 000 2.344zM14.14 34.14a1.16 1.16 0 01-1.171 1.172h-2.344a1.16 1.16 0 01-1.172-1.171 1.16 1.16 0 011.172-1.172h2.344a1.16 1.16 0 011.172 1.172z'
      />
      <path
        fill='#D5E8FE'
        d='M11.797 7.031V4.688a1.16 1.16 0 011.172 1.171 1.16 1.16 0 01-1.172 1.172zM14.14 34.14a1.16 1.16 0 01-1.171 1.172h-1.172V32.97h1.172a1.16 1.16 0 011.172 1.172z'
      />
      <path
        fill='#FFDE46'
        d='M31.719 9.453h-9.375c-4.524 0-8.203 3.68-8.203 8.203 0 2.11.843 4.149 2.343 5.696v3.68c0 .468.282.89.727 1.077.422.188.938.094 1.266-.258l2.203-2.18a8.42 8.42 0 001.664.188h9.375c4.523 0 8.281-3.68 8.281-8.203s-3.758-8.203-8.281-8.203z'
      />
      <path
        fill='#FABE2C'
        d='M40 17.656c0 4.524-3.758 8.203-8.281 8.203H27.03V9.453h4.688c4.523 0 8.281 3.68 8.281 8.203z'
      />
      <path
        fill='#47568C'
        d='M22.344 18.828a1.172 1.172 0 100-2.343 1.172 1.172 0 000 2.343zM27.031 18.828a1.172 1.172 0 100-2.344 1.172 1.172 0 000 2.344z'
      />
      <path
        fill='#29376D'
        d='M31.719 18.828a1.172 1.172 0 100-2.343 1.172 1.172 0 000 2.343zM28.203 17.656a1.16 1.16 0 01-1.172 1.172v-2.344a1.16 1.16 0 011.172 1.172z'
      />
    </svg>
  );
}
