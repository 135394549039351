import { DisabledTooltip } from '../disabled-tooltip';

interface CriticalActionProps {
  isDanger?: boolean;
  title?: string;
  description?: string;
  onAction?: () => void;
  btnText?: string;
  vector?: any;
  hasAccess?: boolean;
}
const CriticalAction = ({
  isDanger = false,
  title,
  description,
  onAction,
  btnText,
  vector,
  hasAccess = false,
}: CriticalActionProps) => {
  return (
    <div className='flex justify-between items-center border border-primary-100 box-border rounded-10 mt-5'>
      <div className='px-4 lg:px-6 py-6.5 lg:w-3/5'>
        <h6
          data-cy='workspace-dangerzone-title'
          className='text-gray-700 text-lg font-bold leading-snug'
        >
          {title}
        </h6>
        <p className='text-gray text-15 font-normal leading-normal mt-3.5'>{description}</p>
        {hasAccess ? (
          <button
            data-cy='workspace-dangerzone-button'
            type='button'
            className={`${
              isDanger ? 'bg-error-50 hover:bg-error-100' : 'bg-info-50 hover:bg-info-100'
            } mt-4 px-4.5 py-2.5 rounded-md`}
            onClick={hasAccess && onAction}
          >
            <h6
              className={`${isDanger ? 'text-error' : 'text-info'} text-sm font-semibold leading-4`}
            >
              {btnText}
            </h6>
          </button>
        ) : (
          <button
            data-cy='workspace-dangerzone-button'
            type='button'
            className={`${
              isDanger ? 'bg-error-50 hover:bg-error-100' : 'bg-info-50 hover:bg-info-100'
            } mt-4 px-4.5 py-2.5 rounded-md'`}
          >
            <DisabledTooltip>
              <h6
                className={`${
                  isDanger ? 'text-error' : 'text-info'
                } text-sm font-semibold leading-4`}
              >
                {btnText}
              </h6>
            </DisabledTooltip>
          </button>
        )}
      </div>
      {vector && (
        <div
          className={`${
            isDanger ? 'bg-error-50' : 'bg-success-50'
          } flex items-center justify-center h-28 w-28 mr-12.5 rounded-full`}
        >
          {vector}
        </div>
      )}
    </div>
  );
};

export default CriticalAction;
