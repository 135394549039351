import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import startsWith from 'lodash.startswith';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactiveVar } from '@apollo/client';

import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import { HASHED_COUNTRIES } from 'constants/countries';
import { parsePhoneNumber, isValidPhoneNumber } from 'components/utils/phone-lib';
import { useNumberSearchVars } from 'hooks/useNumberSearchVars';
import useDebounce from 'hooks/useDebounce';
import { EMERGENCY_NUMBERS } from 'constants/commons';
import { useTwilioContext } from 'lib/twilio';
import { getAllUrlParams } from 'components/organisms/common/utils';

import useCallWidgetContext from '../hooks/useCallWidgetContext';
import { selectedCountry, updatedDialCode } from '../dialer/phone-input/useVars';
import { guessCountry, isNumber } from '../dialer/phone-input/helper';
import { schema } from '../dialer/phone-input/schema';
import { useContactLogsSearch } from '../dialer/phone-input/useContactLogsSearch';
import useTouchToneContext from '../touch-tone/useTouchToneContext';
import {
  SelectedContactType,
  BannerType,
  PhoneInputContextType,
  PhoneInputFormDataType,
} from '../types';
import { useMakeCall } from '../widget-header/useMakeCall';
import { CALL, CLEAR, ERROR_MESSAGES } from '../constants';

export const PhoneInputContext = createContext<PhoneInputContextType | undefined>(undefined);

export const PhoneInputProvider = ({ children }: any) => {
  const { makeCall, selectedChannel } = useMakeCall();

  const defaultCountryCode = localStorage.getItem('_dialer-default-country') || '';
  const WAIT_TIME_IN_MS = 500;
  const [selectedContact, setSelectedContact] = useState<SelectedContactType | null>(null);
  const [searchedText, setSearchedText] = useState<string>('');
  const [searchedQuery, setSearchedQuery] = useState<string>('');
  const [disableCall, setDisableCall] = useState<boolean>(false);

  const debouncedQuery = useDebounce<string>(searchedQuery, WAIT_TIME_IN_MS);
  const { searchContacts, contacts: autoCompleteContacts, onLoadMore } = useContactLogsSearch();
  const { setSelectedCountry } = useNumberSearchVars(selectedCountry);
  const { banner, setBanner, showAlertBanner } = useCallWidgetContext();
  const dialingCode: string | null = useReactiveVar(updatedDialCode);
  const { audioInputDevices } = useMediaDevices();
  const { play } = useTouchToneContext();

  const defaultCountryDetail = HASHED_COUNTRIES[defaultCountryCode];

  // For old chrome extension
  const location: any = useLocation();
  // Get contact number from url when opened from chrome extension
  const urlParams = getAllUrlParams(location?.search);
  // Get contact number from contacts page call action
  const {
    state: { clientNumber },
  } = useTwilioContext();
  // Get contact number from url or from contacts page call action
  const dialingNumber = urlParams?.call || clientNumber;

  const { control, setValue } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      /** Display recently dialed number's country code stored from localstorage or display US flag if not available* */
      number: defaultCountryDetail?.dialingCode ?? '',
    },
  });

  const formSubmit = async (formData: PhoneInputFormDataType) => {
    setDisableCall(true);
    const { number: dialedNumber } = formData;
    const number = dialedNumber?.replace(/\s/g, '');
    const { nationalNumber = '' } = number ? parsePhoneNumber(number) : {};
    const isValid = number && isValidPhoneNumber(number);
    const isPhoneInputEmpty = number?.length === 0;

    if (audioInputDevices?.length < 1) {
      showAlertBanner?.(ERROR_MESSAGES.noMicPermission, BannerType.info);
      setDisableCall(false);
      return;
    }
    if (!selectedChannel) {
      showAlertBanner?.(ERROR_MESSAGES.numberNotAvailable);
      setDisableCall(false);
      return;
    }
    if (!isNumber(number) || isPhoneInputEmpty) {
      showAlertBanner?.(ERROR_MESSAGES.invalidContactName);
      setDisableCall(false);
      return;
    }
    if (EMERGENCY_NUMBERS.includes(nationalNumber as string)) {
      showAlertBanner?.(ERROR_MESSAGES.emergencyCalls);
      setDisableCall(false);
      return;
    }
    if (!isValid) {
      showAlertBanner?.(ERROR_MESSAGES.invalidPhoneNumber);
      setDisableCall(false);
      return;
    }
    setBanner?.({});
    setTimeout(() => {
      setDisableCall(false);
    }, 5000);
    await makeCall(formData);
  };

  useEffect(() => {
    if (debouncedQuery) {
      searchContacts(debouncedQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    if (dialingCode) {
      setSelectedContact(null);
      setSearchedText(dialingCode);
      setValue('number', dialingCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialingCode, setValue]);

  useEffect(() => {
    if (defaultCountryDetail?.dialingCode) {
      setSelectedContact(null);
      setSearchedText(defaultCountryDetail?.dialingCode);
      setValue('number', defaultCountryDetail?.dialingCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountryDetail, setValue]);

  // use effect for old chrome extension
  useEffect(() => {
    if (dialingNumber) {
      setTimeout(() => {
        setSelectedContact(null);
        setSearchedText(dialingNumber);
        setValue('number', dialingNumber);
      }, 2000);
    }
  }, [dialingNumber, setValue]);

  const handleSearch = (inputText: string) => {
    setSelectedContact(null);
    setSearchedQuery(inputText);
    setSearchedText(inputText);
    if (inputText?.length > 1 && !startsWith(inputText, '+') && isNumber(inputText))
      setValue('number', `+${inputText}`);
    if (inputText) {
      const guessedCountry = guessCountry(inputText);
      if (guessedCountry) setSelectedCountry(guessedCountry);
    }
  };

  const handleDialpadClick = (key: string, tone?: number[]) => {
    let inputText: string;
    switch (key) {
      case CALL:
        formSubmit({ number: searchedText });
        return;
      case CLEAR:
        inputText = searchedText?.slice(0, -1);
        break;
      default:
        inputText = searchedText + key;
    }
    setSelectedContact(null);
    setSearchedQuery(inputText);
    setSearchedText(inputText);
    setValue('number', inputText);
    if (inputText?.length > 1 && !startsWith(inputText, '+') && isNumber(inputText))
      setValue('number', `+${inputText}`);
    if (inputText) {
      const guessedCountry = guessCountry(inputText);
      if (guessedCountry) setSelectedCountry(guessedCountry);
    }
    // Play dtmf tone
    if (tone) play?.(tone);
  };

  const handleSelect = (value: string, options: { data: SelectedContactType | null }) => {
    setBanner?.({});
    setSearchedText(value);
    setSelectedContact(options?.data);
    const guessedCountry = guessCountry(value);
    if (guessedCountry) setSelectedCountry(guessedCountry);
  };

  return (
    <PhoneInputContext.Provider
      value={{
        // auto complete variables
        selectedContact,
        searchedText,
        control,
        handleSearch,
        handleSelect,
        autoCompleteContacts,
        // keypad variables
        handleDialpadClick,
        disableCall,
        onLoadMore,
      }}
    >
      {children}
    </PhoneInputContext.Provider>
  );
};
