import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';
import { GET_CHANNELS, GET_VOIP_NONVOIP_CHANNELS } from 'graphql/channel';
import { Query } from 'generated/graphql';
import { cache } from 'services/apollo';

export const useReadChannelsByCache = () => {
  const query = ENABLE_NON_VOIP_FEATURE ? GET_VOIP_NONVOIP_CHANNELS : GET_CHANNELS;
  const variables = ENABLE_NON_VOIP_FEATURE
    ? {
        numberTypes: ['Voip', 'NonVoip'],
      }
    : {};

  const cachedChannelsData: Pick<Query, 'channels'> | null = cache.readQuery({
    query,
    variables,
  });

  return { cachedChannelsData };
};
