import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Upload } from 'antd';

import { Icon } from 'components/atoms';
import useProfileUpload from 'hooks/useProfileUpload';
import { RiAddLine } from 'react-icons/ri';
import { ManIcon } from 'components/atoms/icon';

import * as S from './Styles';

interface IProfilePicProps {
  data?: any;
  onImgUpload?: (imgFile: any) => void;
  hasAccess?: boolean;
  remove?: boolean;
  position?: string;
  avatar?: any;
  type?: 'user' | 'workspace';
  editText?: string;
  size?: 'small' | 'default';
}
const UploadPic = forwardRef(
  (
    {
      data,
      onImgUpload,
      hasAccess = true,
      remove = true,
      position,
      avatar,
      type,
      editText = 'Change',
      size = 'default',
    }: IProfilePicProps,
    ref,
  ) => {
    const { imageFile, imageFileObj, uploadProps, setImageFile } = useProfileUpload();

    React.useEffect(() => {
      onImgUpload?.(imageFileObj);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageFileObj]);

    const [preview, setPreview] = useState(false);
    const togglePreview = () => setPreview(prevState => !prevState);
    const onPreview = () => togglePreview();

    const imageUrl = imageFile || data;

    const handleRemovePic = () => setImageFile(null);

    useImperativeHandle(ref, () => ({
      handleRemovePic,
    }));

    const mapAvatarSize = {
      small: 64,
      default: 80,
    };

    return (
      <div className={`${position === 'left' ? 'flex' : 'text-center w-min mx-auto'}`}>
        <S.ProfileContainer
          data-cy='upload-pic-container'
          className={`${type === 'user' ? 'user-pic-upload' : ''} ${size}`}
        >
          <Upload {...uploadProps} showUploadList={false} accept='.png, .jpg, .jpeg'>
            {imageUrl ? (
              <>
                <S.ProfilePicture
                  data-cy='upload-pic-square'
                  shape='square'
                  src={imageUrl}
                  size={mapAvatarSize[size]}
                  onClick={onPreview}
                />
                <S.NoImage data-cy='upload-pic-no-image' className='edit-pic'>
                  <Icon name='image' />
                  <h6>{editText}</h6>
                </S.NoImage>
              </>
            ) : (
              <>
                {avatar ||
                  (type === 'user' ? (
                    <S.ProfilePicture
                      data-cy='upload-pic-square'
                      shape='square'
                      icon={<ManIcon />}
                      size={mapAvatarSize[size]}
                    />
                  ) : (
                    <S.NoImage data-cy='upload-pic-no-image'>
                      <Icon name='image' />
                      <h6>Upload</h6>
                    </S.NoImage>
                  ))}
              </>
            )}
            {hasAccess && !imageUrl && (
              <S.PlusIcon data-cy='upload-plus-icon'>
                <RiAddLine />
              </S.PlusIcon>
            )}
          </Upload>
        </S.ProfileContainer>
        {imageUrl && (
          <>
            {remove && (
              <button
                type='button'
                className={`text-info text-base leading-6 ${
                  position === 'left' ? 'ml-9' : 'mt-2.5'
                }`}
                onClick={handleRemovePic}
              >
                Remove
              </button>
            )}
          </>
        )}
      </div>
    );
  },
);

export default UploadPic;
