import styled from 'styled-components';

export const CampaignWidgetContainer = styled.div`
  img {
    object-fit: cover;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    &[alt='NP'] {
      padding-left: 2px;
      height: 12px;
      width: 12px;
    }
  }
  .skip-btn {
    font-family: 'Manrope';
    font-weight: 500;
    line-height: 16px;
    color: #6e6681;
  }
`;
