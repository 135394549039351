const NoData = ({ className }: { className?: string }) => (
  <svg
    width='200'
    height='200'
    viewBox='0 0 200 200'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <circle cx='100' cy='100' r='100' fill='#F3F7FE' />
    <g clipPath='url(#clip0_7543_122474)'>
      <path
        d='M132.442 88.3147L101.216 78.2848C100.762 78.1256 100.269 78.1225 99.8133 78.2759L68.5533 88.3158C67.6272 88.6145 66.9996 89.4767 67 90.4498V120.254C67.0007 121.683 67.9079 122.954 69.259 123.419L99.3979 133.811C99.7518 133.936 100.125 134 100.5 134C100.871 134 101.238 133.936 101.588 133.812L131.738 123.416C133.089 122.952 133.997 121.682 134 120.254V90.4498C134 89.4752 133.37 88.6122 132.442 88.3147V88.3147ZM99.3833 80.7616V96.0331C99.3833 96.6498 99.8833 97.1498 100.5 97.1498C101.117 97.1498 101.617 96.6498 101.617 96.0331V80.7594L131.767 90.4409V90.4498V90.4587H131.76L100.5 100.5L69.24 90.4409L99.3833 80.7616ZM69.2333 120.254V92.8026L99.3833 102.486V131.444L69.9893 121.309C69.5378 121.155 69.234 120.731 69.2333 120.254V120.254ZM131.012 121.309L101.617 131.445V102.486L131.767 92.8015V120.254C131.766 120.73 131.463 121.154 131.012 121.309Z'
        fill='#564D6D'
      />
      <path
        d='M95.5991 103.617L74.3824 96.805C73.7035 96.5872 72.9614 96.7066 72.3851 97.1264C71.8089 97.5462 71.4676 98.216 71.4668 98.9289V117.864C71.4671 118.817 72.0716 119.664 72.9721 119.975L94.1887 127.291C94.8715 127.527 95.6259 127.417 96.2139 126.998C96.8018 126.578 97.1506 125.901 97.1501 125.178V105.748C97.1545 104.775 96.5264 103.912 95.5991 103.617V103.617ZM73.7001 106.499L94.9168 113.31V118.534L73.7001 111.723V106.499ZM94.9168 105.748V110.973L73.7001 104.162V98.9289L94.9168 105.748ZM73.7001 117.864V114.068L94.9168 120.879V125.178L73.7001 117.864Z'
        fill='#564D6D'
      />
      <path
        d='M128.615 97.1229C128.038 96.7048 127.297 96.5854 126.617 96.8013L105.401 103.613C104.472 103.909 103.844 104.774 103.85 105.748V125.178C103.85 125.9 104.199 126.577 104.787 126.996C105.375 127.415 106.129 127.524 106.811 127.289L128.028 119.972C128.927 119.662 129.532 118.816 129.533 117.864V98.9286C129.531 98.215 129.191 97.5448 128.615 97.1229V97.1229ZM127.3 98.9286V106.698L121.688 108.497C120.337 108.955 119.443 110.24 119.483 111.666C119.48 112.654 118.829 113.523 117.881 113.802C116.934 114.081 115.915 113.705 115.377 112.877C114.586 111.703 113.105 111.206 111.767 111.666L106.083 113.488V105.748L127.3 98.9286ZM106.083 125.178V115.832L112.448 113.797C112.824 113.656 113.249 113.772 113.501 114.085C114.578 115.748 116.623 116.502 118.522 115.938C120.421 115.374 121.722 113.625 121.716 111.644C121.685 111.197 121.95 110.782 122.37 110.623L127.3 109.043V117.865L106.083 125.178Z'
        fill='#564D6D'
      />
      <path
        d='M100.5 75.9333C101.117 75.9333 101.617 75.4334 101.617 74.8167V68.1167C101.617 67.4999 101.117 67 100.5 67C99.8837 67 99.3838 67.4999 99.3838 68.1167V74.8167C99.3838 75.4334 99.8837 75.9333 100.5 75.9333Z'
        fill='#564D6D'
      />
      <path
        d='M84.9836 77.549C85.2593 78.1009 85.9302 78.3249 86.4822 78.0492C87.0341 77.7735 87.2581 77.1026 86.9824 76.5507L83.6324 69.8506C83.3567 69.2987 82.6858 69.0747 82.1339 69.3504C81.5819 69.6261 81.3579 70.297 81.6336 70.8489L84.9836 77.549Z'
        fill='#564D6D'
      />
      <path
        d='M114.517 78.0548C114.784 78.1887 115.092 78.2106 115.375 78.1156C115.657 78.0206 115.89 77.8165 116.021 77.549L119.371 70.8489C119.647 70.297 119.423 69.6261 118.871 69.3504C118.319 69.0747 117.648 69.2987 117.373 69.8506L114.023 76.5507C113.888 76.8156 113.864 77.1233 113.957 77.4056C114.05 77.6879 114.252 77.9215 114.517 78.0548V78.0548Z'
        fill='#564D6D'
      />
    </g>
    <defs>
      <clipPath id='clip0_7543_122474'>
        <rect width='67' height='67' fill='white' transform='translate(67 67)' />
      </clipPath>
    </defs>
  </svg>
);
export default NoData;
