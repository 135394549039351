import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';
import {
  CONVERSATION_NODE,
  RECENT_CONVERSATION_NODE,
  PINNED_CONVERSATION_NODE,
  CONTACT_PINNED_CONVERSATION_NODE,
} from '../fragments';

export const RECENT_CONVERSATION_LIST = gql`
  query recentConversation($channel: ShortId!, $params: ConnectionInput!) {
    recentConversation(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...RecentConversationFields
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
  ${RECENT_CONVERSATION_NODE}
`;

export const UPDATE_CONVERSATION_COUNT = gql`
  subscription updateConversationCount($channels: [ShortId!]!) {
    updateCounversationCount(channels: $channels) {
      event
      message {
        channel
      }
    }
  }
`;

export const NEW_CONVERSATION_COUNT = gql`
  query newCount($channel: ShortId!) {
    newCount(channel: $channel) {
      status
      error {
        ...Error
      }
      data
    }
  }
  ${ERROR_FRAGMENT}
`;

export const PINNED_CONVERSATION = gql`
  query allPinnedConversation($channel: ShortId!, $contact: String!) {
    allPinnedConversation(channel: $channel, contact: $contact) {
      status
      data {
        ...PinnedConversationFields
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PINNED_CONVERSATION_NODE}
`;

export const CONTACT_PINNED_CONVERSATION = gql`
  query contactPinnedConversationOpenClosed($channel: ShortId!, $params: ConnectionInput!) {
    contactPinnedConversationOpenClosed(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...PinnedConversationFields
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CONTACT_PINNED_CONVERSATION_NODE}
`;

export const RECENT_CONVERSATION_HISTORY = gql`
  query recentConversationHistory($channel: ShortId!, $params: ConnectionInput!) {
    recentConversationHistory(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...RecentConversationFields
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${RECENT_CONVERSATION_NODE}
`;

export const OPEN_CONVERSATION_COUNT = gql`
  query openConversationCount($channel: ShortId!) {
    openContactCount(channel: $channel) {
      status
      error {
        ...Error
      }
      data
    }
  }
  ${ERROR_FRAGMENT}
`;

export const GET_DRAFT_MSG = gql`
  query workspaceDrafts($workspace: ShortId) {
    workspaceDrafts(workspace: $workspace) {
      status
      data {
        channel
        messages {
          id
          content
          clientNumber
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const CONVERSATION_HISTORY = gql`
  query conversationHistory(
    $channel: ShortId!
    $contact: String!
    $conversationStatus: String!
    $params: ConnectionInput
  ) {
    conversationHistory(
      channel: $channel
      contact: $contact
      conversationStatus: $conversationStatus
      params: $params
    ) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...ConversationFields
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
  ${CONVERSATION_NODE}
`;

export const GET_MACROS_LIST = gql`
  query macros {
    macros {
      status
      error {
        ...Error
      }
      data {
        id
        message
        title
        type
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
