import styled from 'styled-components';
import ScrollBar from 'react-perfect-scrollbar';

export const FilterDropdown = styled.div`
  &.tags-filter {
    min-width: 350px;
    background: #ffffff;
    border: 1px solid #e7e6eb;
    box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
    border-radius: 8px;
    label {
      margin: 0;
      line-height: 0;
      padding: 6px 16px;
      display: flex;
      &.ant-checkbox-wrapper {
        margin: 0;
        align-items: center;
        .ant-checkbox {
          top: 0;
        }
      }
      .ant-checkbox {
        &-checked::after {
          border-radius: 5px;
          + span {
            color: ${({ theme }) => theme.colors.text.t7};
          }
        }
        + span {
          padding-left: 10px;
          padding-right: 0;
        }
      }

      span {
        color: ${({ theme }) => theme.colors.text.t4};
        font-weight: ${({ theme }) => theme.text.weight.semiBold};
        font-size: ${({ theme }) => theme.text.size.body.text.small};
        line-height: 14px;
      }

      &.ant-radio-wrapper {
        box-shadow: inset 0px 0.5px 0px #cfccd5;
        padding: 8px 16px;
        .ant-radio {
          transform: scale(1.25);
          margin: 2 px;
        }
        > span:last-child {
          font-family: Manrope;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: #6e6681;
        }
      }
    }

    .search-tags {
      background: #f5f2f8;
      border: 1px solid #d7cce4;
      box-sizing: border-box;
      border-radius: 8px;
      height: 40px;
      padding-left: 12px;
      .ant-input {
        border-radius: inherit;
        background: inherit;
      }
    }
  }
`;

export const TagsScrollList = styled(ScrollBar)`
  padding: 8px 0;
  max-height: 375px;
  box-shadow: inset 0px 0.5px 0px #cfccd5;
  h6 {
    font-weight: 600;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #857f96;
  }
  label {
    display: flex;
  }
`;
