/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { MemberNode } from 'generated/graphql';
import { Tooltip } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';
import { BadgedAvatar } from 'components/atoms/avatar';
import Search from './Search';
import * as S from './Styles';

interface AgentListProps {
  agents: MemberNode[];
  elementRef: any;
  onLoadMore: () => void;
  handleOptionSelection: (member: any) => void;
}
export default function AgentList({
  agents,
  elementRef,
  handleOptionSelection,
  onLoadMore,
}: AgentListProps) {
  const NAME_CHAR_LIMIT = 18;

  return (
    <>
      <S.SearchAgentWrapper>
        <Search />
      </S.SearchAgentWrapper>
      <S.ListMembers>
        {agents?.map((agent: MemberNode) => {
          const fullname = `${agent?.firstname} ${agent?.lastname}`;
          const showNameInTooltip = fullname.length > NAME_CHAR_LIMIT;
          return (
            <div
              key={agent.id}
              onClick={() => handleOptionSelection(agent)}
              className='py-1.5 px-5 cursor-pointer'
            >
              <div className='flex items-center'>
                <div className='mr-2.5'>
                  <BadgedAvatar
                    isOnline={agent?.online}
                    src={agent?.profilePicture}
                    avatarSize={22}
                    dotBoxShadowColor='white'
                    dotBoxShadowWidth={2}
                  />
                </div>

                <Tooltip title={showNameInTooltip ? fullname : ''}>
                  <h6 className='text-gray-600 font-semibold text-sm leading-3.5 capitalize'>
                    {nameElipsis(fullname ?? '', NAME_CHAR_LIMIT)}
                  </h6>
                </Tooltip>
              </div>
            </div>
          );
        })}
        <div ref={elementRef} onClick={onLoadMore} />
      </S.ListMembers>
    </>
  );
}
