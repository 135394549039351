import { FETCH_PLAN_DATA } from 'graphql/krispcall-plans/query';
import cache from 'services/apollo/cache';

export const featureList = {
  callbox: 'callbox',
  'global-phone-numbers': 'global-phone-numbers',
  'number-porting': 'number-porting',
  'pin-contact': 'pin-contact',
  'pin-message': 'pin-message',
  'starred-message': 'starred-message',
  'recording-playback-fast-forward': 'recording-playback-fast-forward',
  'call-filters': 'call-filters',
  'sms-filter': 'sms-filter',
  'call-history': 'call-history',
  'click-to-call-button/widget': 'click-to-call-button/widget',
  voicemail: 'voicemail',
  'voicemail-filter': 'voicemail-filter',
  'bulk-contact-import': 'bulk-contact-import',
  'public-and-private-contact-': 'public-and-private-contact-',
  'web-dialer': 'web-dialer',
  'desktop-apps-(windows/mac/-linux)': 'desktop-apps-(windows/mac/-linux)',
  'android/-ios-app': 'android/-ios-app',
  'answer-calls-in-browser': 'answer-calls-in-browser',
  'greeting-voice-messages': 'greeting-voice-messages',
  'hold-music': 'hold-music',
  'pause/resume-call-recording': 'pause/resume-call-recording',
  'notes-in-callbox': 'notes-in-callbox',
  'notes-in-dailer': 'notes-in-dailer',
  'tag-in-dailer': 'tag-in-dailer',
  'tag-in-callbox': 'tag-in-callbox',
  'dnd-for-phone-numbers': 'dnd-for-phone-numbers',
  'dnd-for-caller': 'dnd-for-caller',
  'new-lead-allerts': 'new-lead-allerts',
  'member-to-member-comminication': 'member-to-member-comminication',
  'text-messages-(sms)': 'text-messages-(sms)',
  'light-weight-crm': 'light-weight-crm',
  'admin-dashboard': 'admin-dashboard',
  'create-workspace': 'create-workspace',
  'call-analytics': 'call-analytics',
  reports: 'reports',
  'call-recordings-and-storage': 'call-recordings-and-storage',
  'white-labeling-for-call-back-widget': 'white-labeling-for-call-back-widget',
  'bundle-calling-and-sms-rate': 'bundle-calling-and-sms-rate',
  'number-sharing': 'number-sharing',
  'call-to-cellphones/landlines': 'call-to-cellphones/landlines',
  'call-blocking': 'call-blocking',
  'call-monitoring': 'call-monitoring',
  'call-forwarding': 'call-forwarding',
  'call-transfer': 'call-transfer',
  'phone-trees-(ivr)': 'phone-trees-(ivr)',
  'multiple-agents': 'multiple-agents',
  'agent-groups': 'agent-groups',
  'ring-strategy ': 'ring-strategy ',
  'microsoft-team': 'microsoft-team',
  slack: 'slack',
  hubspot: 'hubspot',
  pipedrive: 'pipedrive',
  salesforce: 'salesforce',
  'api-and-webhook-access': 'api-and-webhook-access',
  'developer-support': 'developer-support',
  'dedicated-account-manager': 'dedicated-account-manager',
};

export function useFeatureByPlan(featureName: string) {
  const plansData: any = cache?.readQuery({
    query: FETCH_PLAN_DATA,
  });
  const hasAccess = plansData?.planRestrictionData?.data?.[featureName]?.has_access ?? false;

  return [hasAccess];
}
