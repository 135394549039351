import { Icon } from 'components/atoms';

export const SuccessForm = () => {
  return (
    <div className='d-grid py-10 px-15 '>
      <Icon name='request-success' />
      <h5 data-cy='request-non-voip-title'>Request Successful</h5>
      <p data-cy='request-non-voip-desc'>
        Your request for a Non-VOIP number has been processed successfully.
      </p>
    </div>
  );
};
