import styled from 'styled-components';

export const ButtonPrimary = styled.button`
  border: none;
  background: #fff;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #564d6d;
  .icon {
    height: 46px;
    width: 46px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    svg {
      color: #6e6681;
      height: 20px;
      width: 20px;
    }
    &:hover {
      background: #f9f9fa;
    }
  }
  &.footer-action {
    width: 80px;
    .icon {
      margin-bottom: 10px;
      height: 20px;
      width: 20px;
      svg {
        color: #564d6d;
        height: 20px;
        width: 20px;
        &:hover {
          color: #390179;
        }
      }
    }
    &:hover {
      .icon {
        svg {
          color: #390179;
        }
      }
      p {
        color: #390179;
      }
    }
  }

  &.disabled {
    .icon {
      svg {
        opacity: 0.3;
      }
    }
    p {
      opacity: 0.3;
    }
  }
  &.active {
    .icon {
      background: #f3f7fe;
    }
  }
  &.mute {
    .icon {
      svg {
        color: #db312b;
      }
    }
  }
  &.hold {
    .icon {
      svg {
        color: #4c9610;
      }
    }
  }
`;

export const ButtonSecondary = styled.button`
  height: 68px;
  width: 68px;
  padding: 12px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #564d6d;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
  border-radius: 12px;
  .icon {
    margin-bottom: 8px;
    svg {
      height: 24px;
      width: 24px;
      color: #564d6d;
    }
  }
  &.disabled {
    .icon {
      svg {
        opacity: 0.3;
      }
    }
    p {
      opacity: 0.3;
    }
  }
`;
