import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { produce } from 'immer';

import { errorKeys } from 'constants/errors';
import { NUMBERS, CONTACTS } from 'constants/routes';
import { ToastMessage } from 'components/atoms';
import { Client } from 'generated/graphql';
import { UPDATE_CONTACT } from 'graphql/client';
import { queryParamVars, useQVars } from 'hooks/useContactVars';
import { useAddContactMutation } from '../../modals/useAddContactMutation';

type Props = {
  data: any;
};

const useContactDetails = (props: Props) => {
  const { data } = props;
  const { channelId } = useParams<Record<string, string | undefined>>();
  const { t } = useTranslation();
  const [editableField, setEditableField] = useState<string>('');
  const { setSearch } = useQVars(queryParamVars);
  const history = useHistory();

  const onAddContact = (contact: Client) => {
    if (channelId) {
      history.push(`${NUMBERS}/${channelId}/${contact?.id}`);
      return;
    }
    history.push(`${CONTACTS}/${contact?.id}`);
  };

  const { addContact } = useAddContactMutation({ onAddContact });

  const [updateContactInfo] = useMutation(UPDATE_CONTACT, {
    onCompleted: resData => {
      const { error } = resData.updateContact;
      if (error === null) {
        setEditableField('');
        ToastMessage({
          content: `${t(
            'toast.contactDetailUpdateSuccess',
            'Contact detail updated successfully',
          )}`,
          type: 'success',
        });
        setSearch('');
        return;
      }
      const err = errorKeys.find(item => item.errorKey === resData?.updateContact?.error?.errorKey);
      ToastMessage({
        content: err?.message || 'Something went wrong',
        type: 'danger',
      });
    },
    update: (cache, { data: { updateContact } }) => {
      if (updateContact?.data?.id) {
        cache.modify({
          fields: {
            contacts: existingContacts => {
              const updatedContacts = produce(existingContacts, (draft: any) => {
                if (draft?.data?.edges) {
                  const index = draft.data.edges.findIndex(
                    (contact: any) => contact?.node?.id === updateContact?.data?.id,
                  );
                  draft.data.edges[index] = {
                    ...draft.data.edges[index],
                    cursor: draft.data.edges[index]?.cursor,
                    node: { ...updateContact?.data, __typename: 'ContactNode' },
                  };
                }
              });
              return updatedContacts;
            },
            recentConversationHistory: () => {},
            tags: () => {},
            contact: () => {},
            contactPinnedConversationOpenClosed: () => {},
          },
        });
      }
    },
  });

  const handleInputSave = (field: string, value: any) => {
    const trimmedValueOrNull = value?.trim?.()?.length ? value.trim?.() : null;
    if (!data?.id) {
      const payload = {
        number: field === 'number' ? value : data?.clientInfo?.number || data?.number,
        visibility: true, // Save contact as public by default
        [field]: field === 'tags' ? value : trimmedValueOrNull,
      };
      addContact(payload);
      return;
    }
    setEditableField(field);
    updateContactInfo({
      variables: {
        id: data?.id,
        data: {
          [field]: field === 'tags' ? value : trimmedValueOrNull,
        },
      },
    });
  };
  return {
    setEditableField,
    handleInputSave,
    editableField,
  };
};

export default useContactDetails;
