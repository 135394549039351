import { gql } from '@apollo/client';

export const TEAM_MEMBERS_NODE = gql`
  fragment TeamMembersNode on TeamMembers {
    id
    firstname
    lastname
    online
    profilePicture
    role
    email
  }
`;

export const TEAM_NODE = gql`
  fragment TeamFields on Team {
    id
    title
    description
    picture
    total
    online
    teamMembers {
      ...TeamMembersNode
    }
    avatar
  }
  ${TEAM_MEMBERS_NODE}
`;

export const MEMBER_NODE = gql`
  fragment MemberFields on MemberDetails {
    id
    email
    firstname
    lastname
    photo
    role
    lastonline
    online
  }
`;

export const TEAM_MEMBER_NODE = gql`
  fragment TeamMemberFields on TeamMemberNode {
    id
    email
    firstname
    lastname
    photo
    role
    createdOn
    online
  }
`;
