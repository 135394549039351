import * as yup from 'yup';
import { MIN_LENGTH } from './common';

const TITLE_MAX_LENGTH = 44;

export const workspaceInputs = {
  title: yup
    .string()
    .required('Required')
    .trim()
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(TITLE_MAX_LENGTH, `Shouldn't exceed ${TITLE_MAX_LENGTH} characters`),
};
