import { useLazyQuery } from '@apollo/client';

import { WORKSPACE } from 'graphql/foundation';

export function useActiveWorkspaceQuery() {
  const [
    loadWorkspace,
    { data: activeWorkspaceData, loading: loadingActiveWorkspace },
  ] = useLazyQuery(WORKSPACE, {
    fetchPolicy: 'cache-and-network',
  });

  return {
    loadWorkspace,
    loadingActiveWorkspace,
    activeWorkspaceData,
  };
}
