import { ChevronLeft } from 'components/atoms/icon';
import { BackButton } from './Styles';

interface IProps {
  showArrowIcon?: boolean;
  handleBack?: (args?: any) => void;
  title?: string;
  className?: string;
}

export default function BackButtonHeader({
  showArrowIcon = true,
  handleBack,
  title,
  className,
}: IProps) {
  return (
    <BackButton className={className} type='button' onClick={handleBack}>
      {showArrowIcon && <ChevronLeft />}
      <h4>{title}</h4>
    </BackButton>
  );
}
