import { Progress } from 'antd';
import { RiArrowDownLine, RiArrowUpLine, RiInformationLine } from 'react-icons/ri';
import { Tooltip } from 'components/atoms';
import * as S from './Styles';

function between(x: number, min: number, max: number) {
  return x >= min && x <= max;
}
const getStrokeColor = (value: number) => {
  const IN_80_TO_100: any = between(value, 80, 100);
  const IN_36_TO_79: any = between(value, 36, 79);
  const IN_0_TO_35: any = between(value, 0, 35);
  return {
    [`${IN_80_TO_100}`]: {
      '25%': '#4C9610',
      '70%': '#77C63E',
    },
    [`${IN_36_TO_79}`]: {
      '25%': '#E69039',
      '70%': '#ECAC6B',
    },
    [`${IN_0_TO_35}`]: {
      '25%': '#E25A55',
      '70%': '#E98380',
    },
  }.true;
};
const getBgColor = (value: number) => {
  const IN_80_TO_100: any = between(value, 80, 100);
  const IN_36_TO_79: any = between(value, 36, 79);
  const IN_0_TO_35: any = between(value, 0, 35);
  return {
    [`${IN_80_TO_100}`]: 'bg-success-50',
    [`${IN_36_TO_79}`]: 'bg-warning-50',
    [`${IN_0_TO_35}`]: 'bg-error-50',
  }.true;
};
interface IProps {
  title?: string;
  description?: string;
  value?: string | number;
  percent?: number;
  hasTooltip?: boolean;
  progress?: boolean;
  increased?: boolean;
  containerClass?: string;
}

function InfoGraphic({
  title,
  description,
  value = 0,
  percent,
  progress = false,
  increased,
  hasTooltip = false,
  containerClass = '',
}: IProps) {
  return (
    <S.InfoGraph className={`${progress ? getBgColor(+value) : 'bg-white'}    ${containerClass}`}>
      <div className='flex justify-between'>
        <div>
          <h5 className='flex items-center text-xs text-gray-500 uppercase font-semibold leading-normal tracking-wider'>
            {title}
            {hasTooltip && (
              <Tooltip
                placement='bottomLeft'
                title={description}
                getPopupContainer={(trigger: any) => trigger.parentNode}
              >
                <RiInformationLine className='text-info ml-1.5' />
              </Tooltip>
            )}
          </h5>
          <h5 className='text-28 text-gray-600 font-bold leading-snug'>{value}</h5>
          {percent !== 0 && (
            <div
              className={`text-xs flex items-center font-medium leading-5.25 mt-1 ${
                increased ? 'text-success' : 'text-error'
              }`}
            >
              {percent}% {increased ? <RiArrowUpLine /> : <RiArrowDownLine />}
            </div>
          )}
        </div>
        {progress && (
          <div className=''>
            <Progress
              type='circle'
              percent={value as number}
              width={74}
              strokeColor={getStrokeColor(+value)}
              trailColor='#E7E6EB'
              strokeWidth={8}
              format={serviceLevelPercent => <div>{serviceLevelPercent}%</div>}
            />
          </div>
        )}
      </div>
    </S.InfoGraph>
  );
}

export default InfoGraphic;
