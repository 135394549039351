export const AVATARS_TEAM = [
  {
    id: '1',
    name: '050-goal-1',
  },
  {
    id: '2',
    name: '050-goal-2',
  },
  {
    id: '3',
    name: '050-goal-3',
  },
  {
    id: '4',
    name: '050-goal-4',
  },
  {
    id: '5',
    name: '050-goal-5',
  },
  {
    id: '6',
    name: '050-goal-6',
  },
  {
    id: '7',
    name: '050-goal-7',
  },
  {
    id: '8',
    name: '050-goal-8',
  },
  {
    id: '9',
    name: '050-goal-9',
  },
  {
    id: '10',
    name: '050-goal-10',
  },
  {
    id: '11',
    name: '050-goal-11',
  },
  {
    id: '12',
    name: '050-goal-12',
  },
];
