import * as yup from 'yup';
import { MIN_LENGTH } from './common';

const NAME_MAX_LENGTH = 46;
const PROVIDER_MAX_LENGTH = 46;

export const numberSettingsInputs = {
  name: yup
    .string()
    .trim()
    .required('Required')
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(NAME_MAX_LENGTH, `Shouldn't exceed ${NAME_MAX_LENGTH} characters`),
  portNumber: yup.string().required('Required'),
  portNumberProvider: yup
    .string()
    .trim()
    .required('Required')
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(PROVIDER_MAX_LENGTH, `Shouldn't exceed ${PROVIDER_MAX_LENGTH} characters`),
};
