const sortByLastContacted = (memberList: any) => {
  return memberList.slice().sort((a: any, b: any) => {
    return b.timeStamp - a.timeStamp;
  });
};

export const sortOnlineMembers = (memberList: any, memberId?: any) => {
  const onlineUsers: any[] = [];
  const offlineUsers: any[] = [];

  memberList.forEach((member: any) => {
    const currentMember = { ...member };
    currentMember.timeStamp = Math.round(
      new Date(member?.last_message?.createdOn ?? member.createdOn).getTime() / 1000,
    );
    if (currentMember.online) {
      onlineUsers.push(currentMember);
    } else {
      offlineUsers.push(currentMember);
    }
  });

  const sortedOnlineUsers = sortByLastContacted(onlineUsers);
  const sortedOfflineUsers = sortByLastContacted(offlineUsers);
  const concatedMemberList = sortedOnlineUsers.concat(sortedOfflineUsers);
  if (memberId) {
    const currentMemberIndex = concatedMemberList.findIndex(
      (eachMember: any) => eachMember.id === memberId,
    );
    concatedMemberList.unshift(concatedMemberList.splice(currentMemberIndex, 1)[0]);
  }
  return concatedMemberList;
};
