import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-btn.border-0 {
    border: none !important;
  }
  .select-number-modal {
    padding: 32px 24px 24px;
  }
  .ant-modal-close-icon,
  .ant-modal-close-x {
    font-size: 16px;
    width: auto;
    height: auto;
    svg {
      fill: #6e6681;
    }
    position: relative;
    right: -3px;
    top: 10px;
  }
  .request-form,
  .select-number-modal,
  .d-grid {
    display: grid;
    justify-items: center;
    p {
      text-align: center;
    }
  }
  .ant-modal-content {
    padding: 0 !important;
  }

  .request {
    &-form,
    &-method {
      padding: 25px 24px 24px;
      p {
        font-size: 16px;
      }
    }
  }
  .ant-checkbox {
    position: relative;
    top: -10px;
  }
  .border-top {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1};
  }
  h5 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #3d3358;
    margin: 24px 0 8px;
  }
  p {
    font-family: 'Heebo';
    font-size: 15px;
    line-height: 150%;
    color: #6e6681;
  }
  button.invite-more {
    border: 1px solid #1a63f4;
    color: #1a63f4;
    min-width: 154px;
  }
  button.done {
    background: #4c9610;
    min-width: 160px;
    &:hover {
      background: #4c9610;
    }
  }
`;

export const FormWrapper = styled.form`
  .ant-checkbox-wrapper {
    align-items: center;
    .ant-checkbox {
      top: -5px;
    }
  }
`;

export const RadioGroupWrapper = styled.div`
  > .ant-radio-group {
    &-solid .ant-radio-button-wrapper {
      &,
      &:first-child {
        border: 1px solid #e7e6eb;
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &-checked {
        &,
        &:first-child {
          border-color: #1a63f4;
        }
      }
      border-radius: 10px;
      height: 67px;
      width: 100%;
      display: flex;
      align-items: center;
      ::before {
        display: none;
      }
      & > span {
        display: flex;
        align-items: center;
      }
      .icon {
        padding: 16px;
      }
      padding-left: 0;
    }
    .ant-radio-button-wrapper-checked {
      background-color: transparent;
      &:not(.ant-radio-button-wrapper-disabled) {
        &:active,
        &:hover {
          color: inherit;
          background: transparent !important;
        }
        &:focus-within {
          box-shadow: none;
        }
      }
    }
    .d-flex {
      display: flex;
      flex-direction: column;
      .flex-item {
        line-height: 1.2;
      }
    }
    width: 100%;
    margin-top: 32px;
  }

  p {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #3d3358;
  }
`;

export const ListWrapper = styled.div`
  li {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    p {
      text-align: left;
    }
  }
  .count {
    background-color: ${({ theme }) => theme.colors.text.t0};
    color: ${({ theme }) => theme.colors.text.t6};
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;
