import { createContext } from 'react';

import { useTeamsQuery } from '../hooks/useTeamsQuery';

export const TeamsContext = createContext({});

export const TeamsProvider = ({ children }: any) => {
  const {
    teams,
    filteredTeams,
    hasTeams,
    loading,
    called,
    onSearch,
    searchTerm,
    setTeams,
    count,
    setSearchTerm,
  } = useTeamsQuery();

  return (
    <TeamsContext.Provider
      value={{
        teams,
        filteredTeams,
        hasTeams,
        loading,
        called,
        onSearch,
        searchTerm,
        setTeams,
        count,
        setSearchTerm,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};
