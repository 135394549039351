import { useMutation } from '@apollo/client';
import { ToastMessage } from 'components/atoms';
import { REMOVE_COACH_INFO } from 'graphql/dashboard';

export const useDashboardMutation = () => {
  const [removeCoachInfoMutation] = useMutation(REMOVE_COACH_INFO, {
    onCompleted(response) {
      const { error } = response?.removeCoachInfo;
      if (error === null) {
        return;
      }
      ToastMessage({
        content: error.message,
        type: 'danger',
      });
    },
    onError: () =>
      ToastMessage({
        content: 'Something went wrong.',
        type: 'danger',
      }),
  });

  const removeCoachInfo = async (conversationId: string) => {
    await removeCoachInfoMutation({
      variables: {
        conversationId,
      },
    });
  };

  return { removeCoachInfo };
};
