// TEST PEER CONNECTION TRANSFER TO TEST TURN UDP CONNECTITVITY
export const peerTransfer = (timeout = 5000) => {
  const payload = `test_${Date.now()}`;
  const rtcConfig = {};
  const debug = false;
  return new Promise((resolve, reject) => {
    const rtc1 = new RTCPeerConnection(rtcConfig);
    const rtc2 = new RTCPeerConnection(rtcConfig);
    const dataChannel = rtc1?.createDataChannel('sender');
    const ts = Date.now();
    let timer: any = null;
    if (timeout && Number.isInteger(timeout))
      timer = setTimeout(() => {
        if (debug) console.log('[peer-connection]: Timeout. Message Transmission failed');
        return resolve({
          err: 'Timeout. Message Transmission failed',
          result: 'Failed',
        });
      }, timeout);
    rtc1.addEventListener('icecandidate', ice => {
      if (!ice.candidate) return false;
      if (debug) console.log('[peer-connection]: First Peer Generated Candidate:', ice.candidate);
      return rtc2.addIceCandidate(ice.candidate);
    });
    rtc2.addEventListener('icecandidate', ice => {
      if (!ice.candidate) return false;
      if (debug) console.log('[peer-connection]: Second Peer Generated Candidate:', ice.candidate);
      return rtc1.addIceCandidate(ice.candidate);
    });
    rtc2.addEventListener('datachannel', evt => {
      evt.channel.addEventListener('message', msg => {
        if (debug) console.log('[peer-connection]: Message Transmission successful', msg);
        if (msg.data === payload) {
          const rcvTS = Date.now();
          const bin = new Blob([msg.data]);
          rtc1.close();
          rtc2.close();
          if (timer) clearTimeout(timer);
          return resolve({
            elapsed: rcvTS - ts,
            size: bin.size,
            result: 'Success',
          });
        }
        if (timer) clearTimeout(timer);
        return resolve({ err: 'Message integrity failure', result: 'Failed' });
      });
    });
    dataChannel.addEventListener('open', () => dataChannel.send(payload));
    rtc1
      .createOffer()
      .then(offer => {
        if (debug) console.log('[peer-connection]: First peer connection created RTC offer');
        rtc1
          .setLocalDescription(offer)
          .then(() => rtc2.setRemoteDescription(offer))
          .then(() =>
            rtc2
              .createAnswer()
              .then(answer => {
                if (debug)
                  console.log('[peer-connection]: Seocond peer connection created RTC answer');
                rtc2
                  .setLocalDescription(answer)
                  .then(() => rtc1.setRemoteDescription(answer))
                  .catch(e1 => {
                    if (timer) clearTimeout(timer);
                    reject(e1);
                  });
              })
              .catch(e2 => {
                if (timer) clearTimeout(timer);
                reject(e2);
              }),
          );
      })
      .catch(e3 => {
        if (timer) clearTimeout(timer);
        reject(e3);
      });
  });
};
