export const roles = {
  OWNER: 1,
  ADMIN: 2,
  MEMBER: 3,
  SUPPORT: 4,
  DEVELOPER: 5,
};
export const roleId: any = {
  Owner: 1,
  Admin: 2,
  Member: 3,
  Support: 4,
  'Support Member': 4,
  Developer: 5,
};

export const getRoleId = (role: string) => {
  return roleId[role] ?? 0;
};
