import { Review } from 'components/atoms/vectors';

import { StyledModal } from './Styles';

interface UnderReviewProps {
  open: boolean;
}

const UnderReview = ({ open }: UnderReviewProps) => {
  return (
    <StyledModal
      width={448}
      centered
      visible={open}
      maskClosable={false}
      keyboard={false}
      zIndex={500}
      wrapClassName='blurred-wrapper'
      footer={null}
      closable={false}
    >
      <div className='flex flex-col items-center'>
        <Review className='mx-auto mb-6 mt-3.5' />

        <h5 className='text-xl text-gray-700 font-bold leading-snug mb-2'>Account Under Review</h5>
        <div className='bg-warning-100 p-1.5 rounded text-15 text-gray-600 font-normal text-center leading-normal'>
          <p className='mb-6.5'>
            KrispCall is currently reviewing your account, and you will be contacted by email within
            24 hours for more information.
          </p>
          <p>
            KrispCall's Account Health Support team will contact you within 24 hours to discuss your
            account and any actions you need to take to continue using the service.
          </p>
        </div>
      </div>
    </StyledModal>
  );
};

export default UnderReview;
