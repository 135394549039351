import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFormContext } from 'react-hook-form';

import { Input, PhoneInput } from 'components/molecules/fields';

import * as S from './Styles';
import { ContactDetailContext } from '../ContactDetailContext';

export const ContactDetailForm = () => {
  const { t } = useTranslation();
  const { channelId } = useParams<Record<string, string | undefined>>();

  const {
    control,
    formState: { errors },
  } = useFormContext();
  // eslint-disable-next-line prettier/prettier
  const { handleEnterSubmit, handleEditableChange, editableField } = useContext(
    ContactDetailContext,
  );

  return (
    <S.DetailsContainer>
      <form className='details-form' data-cy='contact-detail-form'>
        <div className='edit-field-wrapper'>
          <span className='details-title'>{t('form.label.phone', 'Phone')}</span>
          <S.PhoneInputStyles data-cy='contacts-details-phone'>
            <PhoneInput
              name='number'
              id='number'
              control={control}
              placeholder='+1'
              errors={errors}
              onEnterKeyPress={handleEnterSubmit}
              onBlur={handleEnterSubmit}
              onClick={handleEditableChange('number')}
              // DISABLE NUMBER EDIT FROM CONVERSATION HISTORY PAGE
              isEditable={editableField === 'number' && !channelId}
              autoFocus={true}
            />
          </S.PhoneInputStyles>
        </div>
        <div className='edit-field-wrapper' data-cy='contacts-details-email'>
          <span className='details-title'>{t('form.label.email', 'Email')}</span>
          <Input
            name='email'
            id='email'
            control={control}
            placeholder={t('addEmail', 'Add Email')}
            errors={errors}
            onBlur={handleEnterSubmit}
            onPressEnter={handleEnterSubmit}
            onClick={handleEditableChange('email')}
            isEditable={editableField === 'email'}
            autoFocus={true}
          />
        </div>
        <div className='edit-field-wrapper' data-cy='contacts-details-company'>
          <span className='details-title'>{t('form.label.company', 'Company')}</span>
          <Input
            name='company'
            id='company'
            control={control}
            errors={errors}
            placeholder={t('addCountry', 'Add Company')}
            onBlur={handleEnterSubmit}
            onPressEnter={handleEnterSubmit}
            onClick={handleEditableChange('company')}
            isEditable={editableField === 'company'}
            autoFocus={true}
          />
        </div>
        <div className='edit-field-wrapper' data-cy='contacts-details-address'>
          <span className='details-title'>{t('form.label.address', 'Address')}</span>
          <Input
            name='address'
            id='address'
            control={control}
            errors={errors}
            placeholder={t('addAddress', 'Add Address')}
            onPressEnter={handleEnterSubmit}
            onBlur={handleEnterSubmit}
            onClick={handleEditableChange('address')}
            isEditable={editableField === 'address'}
            autoFocus={true}
          />
        </div>
      </form>
    </S.DetailsContainer>
  );
};
