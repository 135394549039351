import { useState, ReactElement } from 'react';

import * as S from './Styles';

import ExternalNumber from './ExternalTransfer';
import AgentTransfer from './AgentTransfer';

interface ICallTransferProps {
  handleClose: () => void;
}

export function CallTransfer({ handleClose }: ICallTransferProps): ReactElement {
  const [showExternalNumber, setShowExternalNumber] = useState(false);

  const toggleExternalNumber = () => setShowExternalNumber(prevState => !prevState);

  return (
    <S.Container>
      {showExternalNumber ? (
        <ExternalNumber toggleExternalNumber={toggleExternalNumber} handleClose={handleClose} />
      ) : (
        <AgentTransfer toggleExternalNumber={toggleExternalNumber} handleClose={handleClose} />
      )}
    </S.Container>
  );
}
