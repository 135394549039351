export default function TagCrossFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.8175 5.69498C20.0382 4.92167 18.9855 4.48689 17.8876 4.48499H8.70755C8.16428 4.48474 7.62643 4.59247 7.12515 4.80191C6.62388 5.01135 6.16912 5.31832 5.78751 5.70499L5.70755 5.78497L2.62759 9.99497C2.23873 10.5988 2.03189 11.3018 2.03189 12.02C2.03189 12.7382 2.23873 13.4412 2.62759 14.045L5.62759 18.215L5.70755 18.305C6.08966 18.6903 6.54461 18.9957 7.04593 19.2035C7.54725 19.4112 8.08494 19.5171 8.62759 19.515H17.8275C18.9254 19.5131 19.9783 19.0783 20.7576 18.305C21.142 17.9222 21.4468 17.4671 21.6544 16.9659C21.8621 16.4648 21.9686 15.9275 21.9676 15.385V8.61499C21.9856 7.52786 21.572 6.47788 20.8175 5.69498ZM16.9475 13.905C17.0886 14.0448 17.1847 14.2236 17.2238 14.4183C17.2629 14.6131 17.2431 14.815 17.1669 14.9984C17.0907 15.1819 16.9617 15.3385 16.7962 15.4483C16.6307 15.5581 16.4362 15.6161 16.2376 15.615C16.1053 15.6144 15.9743 15.5876 15.8524 15.5361C15.7306 15.4846 15.6201 15.4095 15.5275 15.315L13.6276 13.415L11.7376 15.305C11.5506 15.4933 11.2964 15.5996 11.031 15.6005C10.7657 15.6015 10.5108 15.4969 10.3225 15.31C10.1342 15.123 10.0279 14.8689 10.027 14.6035C10.0261 14.3382 10.1306 14.0833 10.3175 13.895L12.2176 12.005L10.3175 10.105C10.1313 9.91762 10.0268 9.66415 10.0268 9.39996C10.0268 9.13578 10.1313 8.88234 10.3175 8.69498C10.4105 8.60125 10.5211 8.52684 10.643 8.47607C10.7648 8.42531 10.8955 8.39917 11.0275 8.39917C11.1595 8.39917 11.2903 8.42531 11.4121 8.47607C11.534 8.52684 11.6446 8.60125 11.7376 8.69498L13.6276 10.585L15.5275 8.68497C15.6205 8.59124 15.7311 8.51686 15.8529 8.4661C15.9748 8.41533 16.1056 8.38919 16.2376 8.38919C16.3696 8.38919 16.5002 8.41533 16.6221 8.4661C16.744 8.51686 16.8546 8.59124 16.9475 8.68497C17.1338 8.87233 17.2383 9.1258 17.2383 9.38998C17.2383 9.65417 17.1338 9.90761 16.9475 10.095L15.0475 12.005L16.9475 13.905Z'
        fill='currentColor'
      />
    </svg>
  );
}
