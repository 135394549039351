import { memo, useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Draggable from 'react-draggable';

import { DragIcon } from 'components/atoms/icon';
import { useTwilioContext } from 'lib/twilio';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { ToastMessage } from 'components/atoms';

import * as S from './Styles';
import Dialer from './dialer/Dialer';
import ActiveCall from './active-call/ActiveCall';
import IncomingCall from './incoming-call/IncomingCall';

function CallWidget() {
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });

  const draggleRef: any = useRef();
  const [dragDisabled, setDragDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const {
    state: { showDialer, direction, status, callInProgress, salesDialerWidget, callStatusMessage },
  } = useTwilioContext();

  const onDragStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_DISCONNECTED)
      ToastMessage({
        content: callStatusMessage,
        type: 'danger',
      });
  }, [status, callStatusMessage]);

  return (
    <Draggable
      disabled={dragDisabled}
      bounds={bounds}
      onStart={(event, uiData) => onDragStart(event, uiData)}
    >
      <S.WidgetWrapper ref={draggleRef} isDialerPage={isDialerPage}>
        <S.WidgetContainer data-cy='d-wrapper' className='relative'>
          <div
            data-cy='d-icon'
            className='absolute top-2.5 left-5 cursor-drag text-gray-200'
            onMouseOver={() => {
              if (dragDisabled) {
                setDragDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDragDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            <DragIcon />
          </div>
          {showDialer && <Dialer />}
          {!showDialer &&
            direction &&
            (direction === 'Incoming' && !callInProgress && !salesDialerWidget ? (
              <IncomingCall />
            ) : (
              <ActiveCall />
            ))}
        </S.WidgetContainer>
      </S.WidgetWrapper>
    </Draggable>
  );
}

export default memo(CallWidget);
