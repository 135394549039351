import { css } from 'styled-components';

export const margins = css`
  .mx {
    &-0 {
      margin-right: 0px;
      margin-left: 0px;
    }
    &-5 {
      margin-right: 5px;
      margin-left: 5px;
    }
    &-10 {
      margin-right: 10px;
      margin-left: 10px;
    }
    &-13 {
      margin-right: 13px;
      margin-left: 13px;
    }
    &-14 {
      margin-right: 14px;
      margin-left: 14px;
    }
    &-15 {
      margin-right: 15px;
      margin-left: 15px;
    }
    &-16 {
      margin-right: 16px;
      margin-left: 16px;
    }
  }
  .my {
    &-0 {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &-16 {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &-24 {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    &-28 {
      margin-top: 28px;
      margin-bottom: 28px;
    }
    &-48 {
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }
  .mr {
    &-auto {
      margin-right: auto;
    }
    &-0 {
      margin-right: 0px;
    }
    &-6 {
      margin-right: 6px;
    }
    &-8 {
      margin-right: 8px;
    }
    &-10 {
      margin-right: 10px;
    }
    &-12 {
      margin-right: 12px;
    }
    &-14 {
      margin-right: 14px;
    }
    &-16 {
      margin-right: 16px;
    }
    &-24 {
      margin-right: 24px;
    }
    &-40 {
      margin-right: 40px;
    }
  }
  .mb {
    &-0 {
      margin-bottom: 0px !important;
    }
    &-4 {
      margin-bottom: 4px;
    }
    &-6 {
      margin-bottom: 6px;
    }
    &-8 {
      margin-bottom: 8px;
    }
    &-10 {
      margin-bottom: 10px;
    }
    &-12 {
      margin-bottom: 12px;
    }
    &-14 {
      margin-bottom: 14px;
    }
    &-15 {
      margin-bottom: 15px;
    }
    &-16 {
      margin-bottom: 16px;
    }
    &-20 {
      margin-bottom: 20px;
    }
    &-24 {
      margin-bottom: 24px;
    }
    &-32 {
      margin-bottom: 32px;
    }
    &-36 {
      margin-bottom: 36px;
    }
    &-40 {
      margin-bottom: 40px;
    }
    &-48 {
      margin-bottom: 48px;
    }
    &-58 {
      margin-bottom: 58px;
    }
    &-60 {
      margin-bottom: 60px;
    }
    &-62 {
      margin-bottom: 62px;
    }
    &-68 {
      margin-bottom: 68px;
    }
    &-88 {
      margin-bottom: 88px;
    }
  }
  .ml {
    &-auto {
      margin-left: auto;
    }
    &-0 {
      margin-left: 0px;
    }
    &-4 {
      margin-left: 4px;
    }
    &-8 {
      margin-left: 8px;
    }
    &-10 {
      margin-left: 10px;
    }
    &-12 {
      margin-left: 12px;
    }
    &-16 {
      margin-left: 16px;
    }
    &-18 {
      margin-left: 18px;
    }
    &-20 {
      margin-left: 20px;
    }
    &-24 {
      margin-left: 24px;
    }
    &-32 {
      margin-left: 32px;
    }
    &-50 {
      margin-left: 50px;
    }
  }
  .mt {
    &-auto {
      margin-top: auto;
    }
    &-0 {
      margin-top: 0px;
    }
    &-4 {
      margin-top: 4px;
    }
    &-6 {
      margin-top: 6px;
    }
    &-8 {
      margin-top: 8px;
    }
    &-9 {
      margin-top: 9px;
    }
    &-10 {
      margin-top: 10px;
    }
    &-12 {
      margin-top: 12px;
    }
    &-16 {
      margin-top: 16px;
    }
    &-18 {
      margin-top: 18px;
    }
    &-20 {
      margin-top: 20px;
    }
    &-24 {
      margin-top: 24px;
    }
    &-26 {
      margin-top: 26px;
    }
    &-28 {
      margin-top: 28px;
    }
    &-32 {
      margin-top: 32px;
    }
    &-33 {
      margin-top: 33px;
    }
    &-36 {
      margin-top: 36px;
    }
    &-40 {
      margin-top: 40px;
    }
    &-45 {
      margin-top: 45px;
    }
    &-48 {
      margin-top: 48px;
    }
    &-60 {
      margin-top: 60px;
    }
    &-80 {
      margin-top: 80px;
    }
  }
`;
