import { css } from 'styled-components';

export const antTable = css`
  .ant-table {
    font-size: 12px !important;
    font-family: ${({ theme }) => theme.text.font.body};
  }
  .ant-table-thead > tr > th {
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.white} !important;
    color: ${({ theme }) => theme.colors.text.t5} !important;
    font-weight: ${({ theme }) => theme.text.weight.bold} !important;
    padding-top: 32px !important;
    padding-bottom: 8px !important;
    border: none !important;
    .ant-table-column-sorters {
      padding: 0;
      width: 100%;
      .ant-table-column-sorter {
        margin-left: auto;
      }
    }
  }

  .ant-table-tbody > tr {
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      border-radius: 15px;
    }

    &:hover > td,
    &.ant-table-row-selected > td {
      background: ${({ theme }) => theme.colors.primary.p0} !important;
      .box {
        background: rgba(57, 1, 121, 0.2);
        svg {
          color: ${({ theme }) => theme.colors.primary.default};
        }
      }
    }

    /* table border radius */
    &:hover > td:first-child,
    &.ant-table-row-selected > td:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    &:hover > td:last-child,
    &.ant-table-row-selected > td:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &.ant-table-row-selected {
      & > td {
        background: ${({ theme }) => theme.colors.primary.p0} !important;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: ${({ theme }) => theme.colors.primary.p0} !important;
  }
  .ant-table-tbody > tr > .ant-table-row-selected {
    border-radius: 15px;
  }

  .ant-table-filter-dropdown {
    padding: 0;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
    .ant-dropdown-menu {
      max-height: 100%;
    }
    .ant-dropdown-menu-item {
      span {
        text-transform: capitalize;
        letter-spacing: 0;
      }
    }
  }

  .ant-table-cell-scrollbar {
    box-shadow: none !important;
  }
`;
