import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { CHANNEL_NODE, NONVOIP_CHANNEL_NODE } from './fragments';

export const GET_CHANNELS = gql`
  query channels {
    channels {
      status
      data {
        ...ChannelFields
      }
      error {
        ...Error
      }
    }
  }
  ${CHANNEL_NODE}
  ${ERROR_FRAGMENT}
`;

export const GET_VOIP_NONVOIP_CHANNELS = gql`
  query channels($numberTypes: [PhoneNumberType]) {
    channels(numberTypes: $numberTypes) {
      status
      data {
        ...ChannelFields
      }
      error {
        ...Error
      }
    }
  }
  ${NONVOIP_CHANNEL_NODE}
  ${ERROR_FRAGMENT}
`;

export const NON_VOIP_NUMBER_REQUEST_COUNT = gql`
  query nonVoipNumberRequestCount {
    nonVoipNumberRequestCount {
      status
      data {
        pendingTotal
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CHANNEL_INFO = gql`
  query channelInfoData($channel: ShortId!) {
    channelInfoData(channel: $channel) {
      status
      data {
        id
        country
        name
        number
        call
        sms
        mms
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ALL_WORKSPACE_NUMBERS = gql`
  query allWorkspaceNumbers {
    allWorkspaceNumbers {
      status
      data {
        id
        name
        number
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const NONVOIP_ALL_WORKSPACE_NUMBERS = gql`
  query allWorkspaceNumbers {
    allWorkspaceNumbers {
      status
      data {
        id
        name
        number
        phoneNumberType
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
