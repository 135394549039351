import { ReactElement, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { Icon } from 'components/atoms';
import { CAMPAIGN_ACTIONS } from 'components/pages/sales-dialer/campaigns/constants';
import { UsersIcon } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import ClockOutline from 'components/atoms/icon/mage-icons/ClockOutline';
import { activeCallCampaignStatus } from 'services/apollo/reactiveVars';
import useCallWidgetContext from '../hooks/useCallWidgetContext';
import { useCoolOffTimer } from '../hooks/useCoolOffTimer';
import useActiveCallWidgetContext from '../hooks/useActiveCallWidgetContext';
import { CampaignWidgetContainer } from './styles';
import { BannerType } from '../types';
import { WidgetActionButton } from '../call-active/Styles';

export default function CampaignWidget(): ReactElement {
  const {
    state: { connection, status, callEnded, voicemailDropEnabled },
  } = useTwilioContext();
  const activeCampaignStatus = useReactiveVar<any>(activeCallCampaignStatus);
  const campaignId = connection?.customParameters?.get('campaignId') || '';
  const coolOffPeriod = connection?.customParameters?.get('coolOffPeriod') || 10;
  const nextQueue = connection?.customParameters?.get('nextQueue') || '';
  const conversationId = connection?.customParameters?.get('campaignConversationId') || '';
  const lastQueueCall = connection?.customParameters?.get('lastQueue') || '';

  const { nationalNumber, flagUrl, country } = parsePhoneNumber(nextQueue);
  const { setBanner } = useCallWidgetContext();

  const {
    handleExecuteCampaignAction,
    handleSkipCampaignCallQueue,
    handleResetCallWidget,
    handleDropVoicemail,
  } = useActiveCallWidgetContext();

  const { seconds: coolOffTime, startCoolOffTimer, resetCoolOffTime } = useCoolOffTimer({
    time: coolOffPeriod,
  });
  const isCampaignPaused = activeCampaignStatus === 'PAUSED';
  useEffect(() => {
    if (callEnded) {
      startCoolOffTimer();
      return;
    }
    resetCoolOffTime(coolOffPeriod);
    handleResetCallWidget?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEnded]);

  const handleClickDropVoicemail = () => {
    handleDropVoicemail?.(conversationId, campaignId);
  };

  const handleCampaignAction = () => {
    const action = isCampaignPaused ? CAMPAIGN_ACTIONS.resume : CAMPAIGN_ACTIONS.pause;
    handleExecuteCampaignAction?.(campaignId, action);
  };

  useEffect(() => {
    if (isCampaignPaused) {
      setBanner?.({
        title: 'Campaign has been paused',
        closable: true,
        type: BannerType.warning,
        icon: <Icon name='pause-line' />,
        showIcon: true,
      });
      return;
    }
    setBanner?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampaignStatus]);

  useEffect(() => {
    return () => {
      setBanner?.({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queueCallId = connection?.customParameters?.get('nextCampaignConversationId');

  const handleSkipQueueCall = () => {
    if (queueCallId) handleSkipCampaignCallQueue?.(queueCallId, campaignId);
  };

  const isSecondLastCall =
    nextQueue &&
    lastQueueCall &&
    nextQueue.replace(/\+/g, '').trim() === lastQueueCall.replace(/\+/g, '').trim();

  return (
    <CampaignWidgetContainer className='border-t p-4'>
      <WidgetActionButton
        type='primary'
        className='w-full btn-success'
        icon={<Icon name='voicemail' />}
        onClick={handleClickDropVoicemail}
        disabled={!voicemailDropEnabled}
      >
        Voicemail Drop
      </WidgetActionButton>
      <div className='my-4 font-heebo'>
        <div className='flex items-center'>
          <span className='flex flex-1 items-center '>
            <ClockOutline className='text-text-300' />
            <p className='text-text-300 ml-1.5 mr-1 text-13 leading-none'>Cool Off:</p>
            <h3 className='text-text-300 text-13 leading-none font-normal'>{coolOffTime} s</h3>
          </span>
          {/* Hide skip button because we couldn't skip the cooloff time */}
          {/* <button type='button' className='skip-btn'>
            Skip
          </button> */}
        </div>
        <div className='flex items-center mt-4'>
          <UsersIcon className='text-text-300' />
          <span className='flex flex-1 items-center'>
            <p className='text-text-300 ml-1.5 mr-1 text-13 leading-none'>In a queue:</p>
            <h3 className='text-text-300 text-13 leading-none font-normal'>
              {queueCallId ? nationalNumber : '-'}
            </h3>
            <img src={flagUrl} alt={country} title={country} className='ml-1.5' />
          </span>
          {/* Hide skip button for second last call */}
          {nationalNumber && queueCallId && !isSecondLastCall && (
            <button type='button' className='skip-btn' onClick={handleSkipQueueCall}>
              Skip
            </button>
          )}
        </div>
      </div>
      <WidgetActionButton
        className={`w-full ${isCampaignPaused ? 'btn-success-outlined' : 'btn-white'}`}
        onClick={handleCampaignAction}
        disabled={status !== CALL_WIDGET_STATUS.ENDED}
      >
        {isCampaignPaused ? 'Resume' : 'Pause'} Campaign
      </WidgetActionButton>
    </CampaignWidgetContainer>
  );
}
