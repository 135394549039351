import { ReactElement } from 'react';

import WidgetHeader from './widget-header';
import WidgetBody from './widget-body';

export default function IncomingCall(): ReactElement {
  return (
    <div className='rounded-t-xl h-full flex flex-col '>
      <WidgetHeader />
      <WidgetBody />
    </div>
  );
}
