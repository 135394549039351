export default function Payment() {
  return (
    <svg
      width='158'
      height='121'
      viewBox='0 0 158 121'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M122.414 49.3682L133.978 74.6695C134.14 83.5525 127.867 90.1025 118.774 90.1591L84.6255 64.0944L122.414 49.3682Z'
        fill='#ECAC6B'
      />
      <path
        d='M133.263 72.2295L157.881 86.1623C158.625 93.5031 152.267 106.788 144.539 110.007L118.774 90.1592L133.263 72.2295Z'
        fill='#ECAC6B'
      />
      <path
        d='M119.879 52.8982C119.879 52.8982 80.4817 6.31816 74.7462 12.3781C68.1778 19.3179 111.345 61.4779 111.345 61.4779L119.879 52.8982Z'
        fill='#ECAC6B'
      />
      <path
        d='M109.201 90.1181L75.13 82.2623C73.0041 81.7723 71.6865 79.5601 72.1865 77.3231L88.7524 3.15751C89.2345 1.00016 91.3169 -0.37239 93.4062 0.0894093L126.882 7.48677C128.988 7.952 130.321 10.0879 129.858 12.2598L113.951 86.9335C113.471 89.1851 111.344 90.6116 109.201 90.1181Z'
        fill='#8FC9ED'
      />
      <path
        d='M109.791 89.6836C109.645 89.6699 109.498 89.6459 109.354 89.6125L75.3021 81.7234C73.476 81.3001 72.3347 79.3638 72.7708 77.4112L89.3094 3.32447C89.7302 1.44128 91.5418 0.234089 93.3381 0.629061L126.819 7.9913C126.871 8.00244 126.924 8.01529 126.976 8.02986C127.81 8.25519 128.516 8.79324 128.967 9.54292C129.428 10.3097 129.577 11.2222 129.387 12.1141L113.455 86.8614C113.077 88.6324 111.506 89.8473 109.791 89.6836Z'
        fill='#33201B'
      />
      <path
        d='M128.131 12.2255L112.233 86.7081L73.8593 77.8773L90.3827 3.76318L128.131 12.2255Z'
        fill='white'
      />
      <path
        d='M111.046 56.8609C110.938 56.8609 110.829 56.8497 110.72 56.8257L87.1617 51.7331C86.3287 51.5532 85.7964 50.7247 85.9761 49.8867C86.063 49.4806 86.3015 49.1328 86.6481 48.9074C86.9948 48.6821 87.4079 48.6059 87.8116 48.6932L111.371 53.7859C112.204 53.9658 112.736 54.7943 112.557 55.6322C112.401 56.3614 111.759 56.8609 111.046 56.8609ZM87.4837 48.8766C87.2307 48.8766 86.9828 48.9494 86.7648 49.0908C86.4667 49.2844 86.2614 49.5834 86.1865 49.933C86.0323 50.6535 86.4897 51.3655 87.206 51.5206L110.765 56.6133C111.482 56.7683 112.19 56.3074 112.344 55.5877C112.498 54.8671 112.04 54.1552 111.323 54.0001L87.7639 48.9074C87.671 48.8869 87.5773 48.8766 87.4837 48.8766Z'
        fill='#04F2F5'
      />
      <path
        d='M110.182 60.9193C110.073 60.9193 109.963 60.9074 109.854 60.8842L86.2947 55.7916C85.891 55.7042 85.5452 55.4644 85.3212 55.1157C85.0972 54.767 85.0214 54.3514 85.1083 53.9453C85.2871 53.1074 86.1107 52.5719 86.9437 52.7518L110.503 57.8444C111.336 58.0244 111.868 58.8528 111.689 59.6908C111.602 60.0969 111.363 60.4447 111.017 60.67C110.765 60.8345 110.476 60.9193 110.182 60.9193ZM86.6184 52.9352C86.006 52.9352 85.4532 53.3644 85.3195 53.9907C85.2445 54.3394 85.3101 54.6976 85.5026 54.9974C85.6951 55.2973 85.9923 55.5038 86.3398 55.5792L109.899 60.6717C110.246 60.7471 110.602 60.6812 110.899 60.4875C111.197 60.2939 111.403 59.9949 111.478 59.6453C111.632 58.9248 111.174 58.2128 110.458 58.0578L86.8986 52.9652C86.8049 52.9446 86.7112 52.9352 86.6184 52.9352Z'
        fill='#04F2F5'
      />
      <path
        d='M109.315 64.978C109.206 64.978 109.096 64.966 108.987 64.9429L85.4279 59.8503C85.0242 59.7629 84.6784 59.523 84.4544 59.1743C84.2304 58.8256 84.1546 58.41 84.2415 58.0039C84.4203 57.166 85.244 56.6305 86.0769 56.8104L109.636 61.9031C110.469 62.083 111.002 62.9115 110.823 63.7494C110.736 64.1555 110.497 64.5034 110.151 64.7287C109.898 64.8932 109.609 64.978 109.315 64.978ZM85.7524 56.9938C85.14 56.9938 84.5873 57.423 84.4536 58.0493C84.3786 58.398 84.4442 58.7562 84.6367 59.056C84.8292 59.3559 85.1264 59.5624 85.4739 59.6378L109.033 64.7304C109.38 64.805 109.736 64.7399 110.033 64.5462C110.331 64.3526 110.537 64.0536 110.612 63.704C110.766 62.9835 110.308 62.2715 109.591 62.1164L86.0318 57.0238C85.9381 57.0032 85.8444 56.9938 85.7524 56.9938Z'
        fill='#04F2F5'
      />
      <path
        d='M95.0462 66.1396C94.9372 66.1396 94.8273 66.1276 94.7183 66.1045L84.5599 63.9086C84.1562 63.8212 83.8112 63.5813 83.5864 63.2326C83.3624 62.8839 83.2866 62.4684 83.3734 62.0623C83.5523 61.2243 84.3751 60.6889 85.2089 60.8688L95.3673 63.0647C96.2003 63.2446 96.7326 64.0731 96.5538 64.911C96.4669 65.3171 96.2284 65.6649 95.8818 65.8903C95.6288 66.0548 95.3409 66.1396 95.0462 66.1396ZM84.8852 61.0521C84.2729 61.0521 83.7201 61.4814 83.5864 62.1077C83.5114 62.4564 83.577 62.8145 83.7695 63.1144C83.962 63.4142 84.2592 63.6207 84.6059 63.6952L94.7643 65.8911C95.111 65.9665 95.467 65.9005 95.7651 65.7069C96.0632 65.5133 96.2684 65.2143 96.3434 64.8647C96.4976 64.1442 96.0402 63.4322 95.323 63.2771L85.1646 61.0813C85.0709 61.0616 84.9772 61.0521 84.8852 61.0521Z'
        fill='#04F2F5'
      />
      <path
        d='M108.231 68.9893C108.122 68.9893 108.012 68.9774 107.903 68.9542L98.6634 66.9571C97.8304 66.7772 97.2981 65.9487 97.477 65.1108C97.6558 64.2729 98.4794 63.7374 99.3124 63.9182L108.552 65.9153C109.385 66.0952 109.917 66.9237 109.738 67.7616C109.651 68.1677 109.413 68.5156 109.066 68.7409C108.813 68.9045 108.525 68.9893 108.231 68.9893ZM98.9879 64.1006C98.3755 64.1006 97.8228 64.5299 97.689 65.1562C97.5349 65.8767 97.9931 66.5887 98.7094 66.7438L107.949 68.7409C108.296 68.8163 108.652 68.7503 108.95 68.5567C109.248 68.3631 109.453 68.064 109.528 67.7145C109.682 66.9939 109.225 66.282 108.508 66.1269L99.2673 64.1306C99.1736 64.1109 99.0799 64.1006 98.9879 64.1006Z'
        fill='#04F2F5'
      />
      <path
        d='M88.044 50.334C87.9861 50.6038 87.7221 50.7761 87.4529 50.7178C87.1847 50.6595 87.0135 50.3939 87.0714 50.1232C87.1293 49.8533 87.3933 49.6811 87.6624 49.7394C87.9307 49.7976 88.1019 50.0641 88.044 50.334Z'
        fill='#04F2F5'
      />
      <path
        d='M90.0703 50.7727C90.0124 51.0426 89.7484 51.2148 89.4792 51.1565C89.2109 51.0983 89.0397 50.8327 89.0976 50.5619C89.1556 50.292 89.4196 50.1198 89.6887 50.1781C89.957 50.2363 90.1282 50.5019 90.0703 50.7727Z'
        fill='#04F2F5'
      />
      <path
        d='M92.0966 51.2104C92.0387 51.4803 91.7746 51.6525 91.5055 51.5943C91.2372 51.536 91.066 51.2704 91.1239 50.9997C91.1818 50.7298 91.4459 50.5576 91.715 50.6158C91.9833 50.6741 92.1545 50.9397 92.0966 51.2104Z'
        fill='#04F2F5'
      />
      <path
        d='M94.1229 51.6481C94.065 51.918 93.8009 52.0902 93.5318 52.0319C93.2635 51.9737 93.0923 51.7081 93.1502 51.4374C93.2081 51.1675 93.4721 50.9953 93.7413 51.0536C94.0096 51.1118 94.1808 51.3783 94.1229 51.6481Z'
        fill='#04F2F5'
      />
      <path
        d='M96.1491 52.0859C96.0912 52.3557 95.8271 52.5279 95.558 52.4697C95.2897 52.4114 95.1186 52.1458 95.1765 51.8751C95.2344 51.6053 95.4984 51.4331 95.7675 51.4913C96.0358 51.5496 96.207 51.816 96.1491 52.0859Z'
        fill='#04F2F5'
      />
      <path
        d='M98.3556 52.5632C98.2977 52.833 98.0336 53.0052 97.7645 52.947C97.4962 52.8887 97.325 52.6231 97.383 52.3524C97.4409 52.0826 97.7049 51.9103 97.974 51.9686C98.2423 52.0269 98.4135 52.2933 98.3556 52.5632Z'
        fill='#04F2F5'
      />
      <path
        d='M100.382 53.001C100.324 53.2709 100.06 53.4431 99.7908 53.3848C99.5226 53.3266 99.3514 53.061 99.4093 52.7902C99.4672 52.5203 99.7312 52.3481 100 52.4063C100.269 52.4646 100.44 52.7311 100.382 53.001Z'
        fill='#04F2F5'
      />
      <path
        d='M102.408 53.4387C102.35 53.7085 102.086 53.8807 101.817 53.8225C101.549 53.7642 101.378 53.4986 101.436 53.2279C101.493 52.958 101.758 52.7858 102.027 52.8441C102.295 52.9032 102.466 53.1688 102.408 53.4387Z'
        fill='#04F2F5'
      />
      <path
        d='M104.435 53.8774C104.377 54.1473 104.113 54.3195 103.843 54.2613C103.575 54.203 103.404 53.9374 103.462 53.6667C103.52 53.3968 103.784 53.2246 104.053 53.2828C104.321 53.3411 104.492 53.6067 104.435 53.8774Z'
        fill='#04F2F5'
      />
      <path
        d='M106.461 54.3154C106.403 54.5853 106.139 54.7575 105.87 54.6992C105.601 54.641 105.43 54.3754 105.488 54.1046C105.546 53.8348 105.81 53.6625 106.079 53.7208C106.347 53.7791 106.519 54.0455 106.461 54.3154Z'
        fill='#04F2F5'
      />
      <path
        d='M87.1773 54.3925C87.1194 54.6624 86.8553 54.8346 86.5862 54.7763C86.3179 54.7181 86.1467 54.4525 86.2046 54.1818C86.2625 53.9119 86.5266 53.7397 86.7957 53.798C87.064 53.8562 87.2352 54.1227 87.1773 54.3925Z'
        fill='#04F2F5'
      />
      <path
        d='M89.2035 54.8303C89.1455 55.1002 88.8815 55.2725 88.6124 55.2142C88.3441 55.1559 88.1729 54.8903 88.2308 54.6196C88.2887 54.3497 88.5527 54.1774 88.8219 54.2357C89.0902 54.2948 89.2614 54.5604 89.2035 54.8303Z'
        fill='#04F2F5'
      />
      <path
        d='M91.2297 55.269C91.1718 55.5389 90.9078 55.7111 90.6386 55.6529C90.3703 55.5946 90.1991 55.329 90.2571 55.0583C90.315 54.7884 90.579 54.6162 90.8482 54.6744C91.1164 54.7327 91.2876 54.9983 91.2297 55.269Z'
        fill='#04F2F5'
      />
      <path
        d='M93.256 55.7068C93.198 55.9767 92.934 56.1489 92.6649 56.0907C92.3966 56.0324 92.2254 55.7668 92.2833 55.496C92.3413 55.2261 92.6053 55.0539 92.8744 55.1122C93.1427 55.1704 93.3139 55.4369 93.256 55.7068Z'
        fill='#04F2F5'
      />
      <path
        d='M95.2823 56.1447C95.2244 56.4146 94.9603 56.5868 94.6912 56.5285C94.4229 56.4703 94.2517 56.2047 94.3096 55.934C94.3675 55.6641 94.6316 55.4919 94.9007 55.5501C95.169 55.6084 95.3402 55.8748 95.2823 56.1447Z'
        fill='#04F2F5'
      />
      <path
        d='M97.4888 56.6218C97.4308 56.8917 97.1668 57.0639 96.8977 57.0056C96.6294 56.9474 96.4582 56.6818 96.5161 56.411C96.574 56.1412 96.8381 55.9689 97.1072 56.0272C97.3755 56.0855 97.5467 56.3519 97.4888 56.6218Z'
        fill='#04F2F5'
      />
      <path
        d='M99.5149 57.0595C99.457 57.3294 99.193 57.5016 98.9239 57.4434C98.6556 57.3851 98.4844 57.1195 98.5423 56.8488C98.6003 56.5789 98.8643 56.4067 99.1334 56.4649C99.4017 56.5232 99.5729 56.7897 99.5149 57.0595Z'
        fill='#04F2F5'
      />
      <path
        d='M101.541 57.4976C101.483 57.7675 101.219 57.9397 100.95 57.8814C100.682 57.8232 100.511 57.5575 100.569 57.2868C100.627 57.0169 100.891 56.8447 101.16 56.9029C101.428 56.9612 101.599 57.2277 101.541 57.4976Z'
        fill='#04F2F5'
      />
      <path
        d='M103.567 57.9363C103.51 58.2061 103.246 58.3784 102.976 58.3201C102.708 58.2618 102.537 57.9962 102.595 57.7255C102.653 57.4556 102.917 57.2834 103.186 57.3417C103.454 57.3999 103.625 57.6655 103.567 57.9363Z'
        fill='#04F2F5'
      />
      <path
        d='M115.482 36.0533L113.118 47.116C112.886 48.1998 111.826 48.8895 110.748 48.6564L90.1463 44.2029C89.0689 43.9699 88.3832 42.9032 88.6149 41.8186L90.9784 30.7559C91.2101 29.6721 92.2705 28.9824 93.3488 29.2154L113.95 33.6689C115.028 33.902 115.713 34.9695 115.482 36.0533Z'
        fill='#039EE3'
      />
      <path
        d='M90.6465 32.3132L90.1291 34.7349L114.633 40.0317L115.15 37.6101L90.6465 32.3132Z'
        fill='white'
      />
      <path
        d='M96.551 42.3334L90.1907 40.9585L89.9707 41.988L96.3311 43.3629L96.551 42.3334Z'
        fill='white'
      />
      <path
        d='M104.18 43.9828L97.8195 42.6079L97.5995 43.6374L103.96 45.0124L104.18 43.9828Z'
        fill='white'
      />
      <path
        d='M111.622 45.591L105.262 44.2161L105.042 45.2456L111.403 46.6205L111.622 45.591Z'
        fill='white'
      />
      <path
        d='M91.3686 36.2818L90.7785 39.0444L93.8995 39.7191L94.4897 36.9565L91.3686 36.2818Z'
        fill='#F7E105'
      />
      <path
        d='M92.7642 70.8102C92.7669 70.8001 92.7679 70.7956 92.7679 70.7947C92.7679 70.7965 92.7642 70.8102 92.7642 70.8102Z'
        fill='#D9FFFF'
      />
      <path
        d='M92.7642 70.8102C92.7669 70.8001 92.7679 70.7956 92.7679 70.7947C92.7679 70.7965 92.7642 70.8102 92.7642 70.8102Z'
        fill='#D9FFFF'
      />
      <path
        d='M98.3959 72.3214C98.3959 72.3214 98.3986 72.3077 98.3995 72.3059C98.3995 72.3068 98.3986 72.3114 98.3959 72.3214Z'
        fill='#D9FFFF'
      />
      <path
        d='M98.3959 72.3214C98.3959 72.3214 98.3986 72.3077 98.3995 72.3059C98.3995 72.3068 98.3986 72.3114 98.3959 72.3214Z'
        fill='#D9FFFF'
      />
      <path
        d='M95.9318 70.0593C95.1133 70.588 93.9439 70.8604 92.7642 70.809C92.7472 70.8835 92.6501 71.2974 92.2047 73.2045C91.6102 75.7483 93.7735 77.6392 94.0989 77.9056C94.5035 77.8345 97.2417 77.2596 97.8362 74.7159C98.2817 72.8087 98.3779 72.3949 98.3958 72.3203C97.3337 71.7694 96.4215 70.9392 95.9318 70.0593Z'
        fill='#03D67C'
      />
      <path
        d='M94.1801 77.5621C93.9391 77.3479 93.4723 76.8913 93.0771 76.2479C92.4741 75.2686 92.2859 74.273 92.5158 73.2877L93.0183 71.1346C94.0558 71.1406 95.0531 70.9136 95.8308 70.4946C96.3324 71.2572 97.1126 71.9614 98.0308 72.4798L97.5282 74.6328C97.2983 75.6181 96.6936 76.4012 95.732 76.9598C95.0991 77.3274 94.4867 77.4953 94.1801 77.5621Z'
        fill='#1BF79A'
      />
      <path
        d='M97.262 73.2243C96.4895 73.1421 95.3184 73.5525 94.4914 74.1993C94.4897 73.7906 94.166 73.3331 93.9727 73.2012L93.1865 73.6733C93.5451 73.9577 93.8654 74.5814 93.804 75.1169L94.4207 75.3842C94.8116 74.925 95.7979 73.8395 97.3378 73.5216C97.3344 73.4119 97.2697 73.2423 97.262 73.2243Z'
        fill='white'
      />
      <path
        d='M133.032 71.7535C128.476 57.6707 118.867 30.7338 118.867 30.7338C118.867 30.7338 115.744 29.3372 114.201 35.2969C112.657 41.2566 117.024 53.4665 117.024 53.4665C117.024 53.4665 107.858 81.9233 122.748 85.2845C133.092 87.6192 136.028 77.6969 133.032 71.7535Z'
        fill='#ECAC6B'
      />
      <path
        d='M82.7331 29.7234C82.7331 29.7234 71.7272 31.5166 76.3128 41.1236C80.8985 50.7306 86.0088 43.2972 83.7338 35.845C82.894 33.0922 82.7331 29.7234 82.7331 29.7234Z'
        fill='#ECAC6B'
      />
      <path
        d='M80.6925 38.9363C80.6925 38.9363 69.6865 40.7295 74.2722 50.3365C78.8578 59.9434 83.9682 52.5101 81.6932 45.0579C80.8526 42.3051 80.6925 38.9363 80.6925 38.9363Z'
        fill='#ECAC6B'
      />
      <path
        d='M76.8808 55.0906C76.8808 55.0906 68.2947 56.4897 71.8719 63.9847C75.4499 71.4798 79.436 65.6803 77.661 59.8662C77.006 57.7192 76.8808 55.0906 76.8808 55.0906Z'
        fill='#ECAC6B'
      />
      <path
        d='M15.8057 90.0834L8.22146 108.819C7.30387 115.379 25.7955 120.766 29.2921 115.856C32.3832 103.183 38.2912 85.1191 37.9071 78.5602C34.4645 73.3041 21.9814 74.5291 15.8057 90.0834Z'
        fill='#ECAC6B'
      />
      <path
        d='M35.4189 89.3769C37.4798 85.8144 35.3409 81.9321 36.5052 76.7124C38.2479 73.3985 42.0168 68.6985 40.5525 64.9781C30.7 38.2811 17.4373 39.9399 4.30581 64.2676C3.43707 68.7992 10.185 83.3414 14.1846 91.4189C16.7686 97.6499 32.3634 97.0953 35.4189 89.3769Z'
        fill='#ECAC6B'
      />
      <path
        d='M36.7424 75.6143C36.7041 75.6533 36.6705 75.6874 36.6369 75.7216C36.5873 76.0614 36.5422 76.3886 36.5042 76.7122C31.0388 72.0197 31.9899 62.2171 34.4817 56.3983C34.3169 62.4044 36.8134 68.2782 40.4003 69.6379C40.1466 70.572 39.7254 71.5988 39.0475 72.7014C38.4787 73.623 37.7256 74.6024 36.7424 75.6143Z'
        fill='#CF7D36'
      />
      <path
        d='M24.135 52.9021C19.1596 50.8493 11.5109 54.6406 11.5109 54.6406C11.5109 54.6406 20.5176 60.4277 21.3261 63.9776C22.1345 67.5283 20.0812 71.3804 21.0516 77.3948C22.022 83.4092 29.4049 71.7586 30.2926 65.044C31.1637 58.4466 29.1094 54.9557 24.135 52.9021Z'
        fill='#CF7D36'
      />
      <path
        d='M58.6728 25.2984L37.6557 66.2076C37.2071 67.0816 36.1393 67.4245 35.2708 66.9741L11.5104 54.6414C10.6419 54.191 10.3021 53.1173 10.7507 52.2433L31.7678 11.3341C32.2165 10.4601 33.2842 10.1172 34.1527 10.5676L57.9131 22.9003C58.7817 23.3507 59.1224 24.4244 58.6728 25.2984Z'
        fill='#025BB3'
      />
      <path
        d='M37.6651 12.3868L15.022 56.4622L19.6648 58.872L42.3079 14.7966L37.6651 12.3868Z'
        fill='#039EE3'
      />
      <path
        d='M36.8676 55.0148L33.5389 61.4947C33.3269 61.907 33.4964 62.4171 33.9167 62.636C34.337 62.854 34.8492 62.6971 35.0611 62.2857L38.3899 55.8058C38.6019 55.3935 38.4323 54.8834 38.0121 54.6645C37.5918 54.4456 37.0796 54.6025 36.8676 55.0148Z'
        fill='#D1F5FF'
      />
      <path
        d='M41.9875 45.0494L38.6587 51.5295C38.4467 51.9417 38.6163 52.4518 39.0365 52.6707C39.4568 52.8888 39.969 52.7319 40.1809 52.3205L43.5097 45.8405C43.7217 45.4282 43.5521 44.9181 43.1319 44.6992C42.7116 44.4803 42.1986 44.6371 41.9875 45.0494Z'
        fill='#D1F5FF'
      />
      <path
        d='M47.1078 35.0842L43.779 41.5643C43.567 41.9765 43.7365 42.4867 44.1568 42.7056C44.5771 42.9236 45.0893 42.7667 45.3012 42.3553L48.63 35.8752C48.842 35.463 48.6724 34.9528 48.2522 34.734C47.8311 34.515 47.3188 34.6728 47.1078 35.0842Z'
        fill='#D1F5FF'
      />
      <path
        d='M52.2268 25.1186L48.898 31.5987C48.686 32.0109 48.8555 32.5211 49.2758 32.74C49.6961 32.958 50.2083 32.8011 50.4202 32.3897L53.749 25.9097C53.961 25.4974 53.7914 24.9873 53.3712 24.7684C52.9509 24.5495 52.4387 24.7072 52.2268 25.1186Z'
        fill='#D1F5FF'
      />
      <path
        d='M13.4341 50.394L12.5884 52.0403L14.564 53.0657L15.4098 51.4194L13.4341 50.394Z'
        fill='#D1F5FF'
      />
      <path
        d='M16.6785 44.0776L13.9218 49.4436L15.8974 50.469L18.6541 45.1031L16.6785 44.0776Z'
        fill='#D1F5FF'
      />
      <path
        d='M40.0037 25.4358L42.2619 21.0403C42.6319 20.3205 43.5109 20.0384 44.2262 20.409L48.1598 22.451C48.875 22.8225 49.1546 23.7063 48.7854 24.4261L46.5272 28.8217C46.1571 29.5415 45.2782 29.8236 44.5629 29.4529L40.6293 27.411C39.9141 27.0395 39.6337 26.1556 40.0037 25.4358Z'
        fill='#DBC704'
      />
      <path
        d='M36.3248 70.7748C35.1852 67.7536 34.667 64.3112 34.909 60.8116C34.9138 60.7432 34.9187 60.674 34.9227 60.6047C35.3851 53.2701 33.3762 47.1849 30.4387 46.9899C26.7362 46.7442 24.7429 52.6018 24.2806 59.9364C24.2409 60.5685 24.219 61.1917 24.2154 61.8018C24.1942 65.0816 23.4203 68.286 22.1611 71.1087C20.9797 73.7557 20.1779 77.0187 19.9539 80.5792C19.3725 89.8099 22.8846 97.5425 27.7989 97.851C32.7132 98.1596 37.1685 90.9271 37.7498 81.6964C38.0086 77.5835 37.4546 73.7685 36.3248 70.7748Z'
        fill='#ECAC6B'
      />
    </svg>
  );
}
