import { createContext } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';

type ILoginContext = {
  loginPayload?: any;
  setLoginPayload?: (val: any) => void;
};

const LoginContext = createContext<ILoginContext>({});

function LoginProvider({ children }: any) {
  // save login payload in local storage temporarily for login
  const [loginPayload, setLoginPayload] = useLocalStorage('auth_login', '');

  return (
    <LoginContext.Provider
      value={{
        loginPayload,
        setLoginPayload,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export { LoginContext, LoginProvider };
