/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore

import { Provider } from '@chargebee/chargebee-js-react-wrapper';
import { CHARGEBEE_PUBLISHABLE_KEY, CHARGEBEE_SITE } from 'constants/endpoint';

declare global {
  interface Window {
    Chargebee: any;
  }
}

function initChargebee() {
  window?.Chargebee?.init?.({
    site: CHARGEBEE_SITE,
    publishableKey: CHARGEBEE_PUBLISHABLE_KEY,
  });
}
(() => {
  initChargebee();
})();

export default function ElementsProvider({ children }: { children: React.ReactNode }) {
  const cbInstance = window?.Chargebee?.getInstance?.();
  if (!cbInstance) {
    initChargebee();
    // throw new Error('cb not loaded');
  }
  return <Provider cbInstance={cbInstance}>{children}</Provider>;
}
