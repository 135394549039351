export default function UserSquareFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.2566 2H8.75338C6.96309 2.00265 5.2469 2.71468 3.98097 3.97998C2.71504 5.24528 2.00265 6.9606 2 8.75V15.25C1.99998 16.3828 2.28604 17.4973 2.83172 18.4902C3.37739 19.4831 4.16501 20.3223 5.12156 20.93C5.45548 21.1516 5.81076 21.3392 6.18209 21.49C6.99368 21.8277 7.86425 22.0011 8.74337 22H15.2466C16.147 22 17.0383 21.8198 17.8679 21.47C18.2644 21.3013 18.6431 21.0936 18.9985 20.85C19.9213 20.238 20.6783 19.4073 21.202 18.4319C21.7257 17.4566 21.9998 16.3669 22 15.26V8.76001C22 6.97061 21.2902 5.25417 20.0261 3.987C18.7621 2.71983 17.0469 2.00529 15.2566 2ZM12.005 6.58002C12.6264 6.58002 13.2338 6.76416 13.7504 7.10919C14.267 7.45422 14.6697 7.94461 14.9075 8.51837C15.1452 9.09213 15.2074 9.72348 15.0862 10.3326C14.965 10.9417 14.6658 11.5012 14.2265 11.9403C13.7871 12.3794 13.2273 12.6785 12.6179 12.7997C12.0085 12.9208 11.3769 12.8586 10.8028 12.621C10.2288 12.3833 9.73806 11.9808 9.39286 11.4645C9.04766 10.9481 8.86342 10.341 8.86342 9.71997C8.86606 8.888 9.1979 8.09092 9.78649 7.50262C10.3751 6.91433 11.1726 6.58265 12.005 6.58002ZM17.0075 20.21C16.4616 20.4044 15.8861 20.5025 15.3066 20.5H8.80339C7.56062 20.4967 6.35911 20.0539 5.41168 19.25C5.88035 18.2304 6.604 17.3487 7.51277 16.69C8.79437 15.6421 10.3992 15.0695 12.055 15.0695C13.7108 15.0695 15.3157 15.6421 16.5973 16.69C17.5093 17.3421 18.2477 18.2072 18.7484 19.21C18.2251 19.6609 17.92 19.9082 17.2615 20.1172L17.0075 20.21Z'
        fill='currentColor'
      />
    </svg>
  );
}
