/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactElement, useState, useEffect } from 'react';
import { Avatar, Dropdown } from 'antd';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { User3Icon } from 'components/atoms/icon';
import { Icon, Tooltip } from 'components/atoms';
import { AVATARS_TEAM } from './constants';
import * as S from './Styles';

interface AvatarSelectProps {
  avatar?: string;
  onAvatarSelect: (avatar: string) => void;
}

const AvatarSelect = ({ avatar, onAvatarSelect }: AvatarSelectProps): ReactElement => {
  const [icon, setIcon] = useState<string | null | undefined>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownVisibility = (visible: boolean) => {
    setDropdownVisible(visible);
  };

  useEffect(() => {
    if (avatar) {
      const selectedAvatar = AVATARS_TEAM.find(item => item.id === avatar);
      setIcon(selectedAvatar?.name);
    }
    return () => {
      setIcon(null);
    };
  }, [avatar]);

  const handleAvatarSelect = (selectedItem: any) => () => {
    const selectedAvatar = AVATARS_TEAM.find(item => item.id === selectedItem.id);
    setIcon(selectedAvatar?.name);
    onAvatarSelect(selectedItem.id);
    setDropdownVisible(false);
  };

  const avatarsList = () => {
    return (
      <S.OptionsWrapper>
        {AVATARS_TEAM.map((item: any, idx: number) => {
          return (
            <div
              data-test-id={`team-avatar-${idx}`}
              className={`cursor-pointer team-avatar-${idx}`}
              onClick={handleAvatarSelect(item)}
              key={item.id}
            >
              <Icon name={item.name} />
            </div>
          );
        })}
      </S.OptionsWrapper>
    );
  };
  return (
    <S.AvatarWrapper>
      <Avatar
        size={80}
        icon={
          icon ? (
            <Icon name={icon} className='w-full h-full' />
          ) : (
            <User3Icon bgColor='#F5F2F8' color='#B7B3C1' className='default-avatar' />
          )
        }
      />
      <Dropdown
        overlay={avatarsList}
        data-test-id='team-edit-dropdown'
        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
        trigger={['click']}
        className='cursor-pointer team-edit-dropdown'
        overlayClassName=''
        onVisibleChange={handleDropdownVisibility}
        visible={dropdownVisible}
        placement='bottom'
      >
        <div className='absolute ring ring-white top-15 left-15 rounded-full'>
          <Tooltip getPopupContainer={(trigger: HTMLElement) => trigger} title='More'>
            <MdKeyboardArrowDown />
          </Tooltip>
        </div>
      </Dropdown>
    </S.AvatarWrapper>
  );
};
export default AvatarSelect;
