import theme from 'themes';
import PlusIcon from 'assets/icons/create-tag.svg';

export const tagSelectStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 34,
    border: 'none',
  }),
  option: (styles: any, tag: any) => {
    return {
      ...styles,
      color: '#6E6681',
      fontWeight: theme.default.text.weight.semiBold,
      '&:before': {
        background: tag.data.colorCode || `#1A63F4 url(${PlusIcon}) no-repeat center`,
        backgroundSize: 'cover',
      },
    };
  },
  multiValue: (styles: any, tag: any) => {
    const { colorCode } = tag.data;
    return {
      ...styles,
      backgroundColor: colorCode,
      borderRadius: 5,
    };
  },
  multiValueLabel: (styles: any, tag: any) => ({
    ...styles,
    color: '#564D6D',
    padding: 0,
    paddingLeft: 0,
    fontWeight: 400,
    lineHeight: '14px',
  }),
  multiValueRemove: (styles: any, tag: any) => ({
    ...styles,
    color: '#3D3358',
    padding: 0,
    marginLeft: 2,
    width: 14,
    height: 14,
    position: 'relative',
    top: 0,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#3D3358',
    },
  }),
};
