export default function Headphone({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.09504 13.8675V11.6775C4.09504 10.95 4.66504 10.2975 5.47504 10.2975C6.20254 10.2975 6.85504 10.8675 6.85504 11.6775V13.785C6.85504 15.2475 5.64004 16.4626 4.17754 16.4626C2.71504 16.4626 1.50004 15.24 1.50004 13.785V9.165C1.41754 4.95004 4.74754 1.53754 8.9625 1.53754C13.1775 1.53754 16.5 4.95004 16.5 9.0825V13.7026C16.5 15.1651 15.285 16.38 13.8225 16.38C12.36 16.38 11.145 15.1651 11.145 13.7026V11.595C11.145 10.8675 11.715 10.215 12.525 10.215C13.2525 10.215 13.905 10.785 13.905 11.595V13.8675'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
