import { css } from 'styled-components';

export const antDrawer = css`
  .ant-drawer {
    .ant-drawer-mask {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &.ant-drawer-left {
      left: 64px;
    }
    .ant-drawer-body {
      padding: 0;
    }
  }
`;
