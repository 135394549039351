import { css } from 'styled-components';

export const antSelect = css`
  .ant-select {
    :not(.ant-select-customize-input) .ant-select-selector {
      background: #f5f2f8;
      border-radius: 8px;
      border: 1px solid #d7cce4;
      padding: 6px 11px;
      .ant-select-selection-search-input {
        height: 100%;
      }
    }
    border-radius: 8px;
    .rc-virtual-list-scrollbar-thumb {
      // ant select dropdown scrollbar
      background: #cfccd5 !important;
    }
    // size
    &.ant-select-sm {
      :not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
        .ant-select-selection-item {
          line-height: 38px;
        }
      }
    }
  }
  .ant-select-selection-item {
    font-family: 'Manrope', 'sans-serif';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #564d6d;
  }
  .ant-select-selection-placeholder {
    color: ${({ theme }) => theme.colors.text.t5};
    font-weight: normal;
    font-family: ${({ theme }) => theme.text.font.heading};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
  }

  .select--add-member {
    margin: 0px;
    .ant-select-selection-item {
      border-radius: 10px;
      background: ${({ theme }) => theme.colors.primary.p2};
      color: ${({ theme }) => theme.colors.text.t8};
      font-size: ${({ theme }) => theme.text.size.body.text.caption};
      padding: 2px 8px;
      height: 28px;
      .ant-select-selection-item-content {
        margin-right: 8px;
      }
      svg {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.text.t5};
        padding: 1px;
        position: relative;
        top: 1px;
        &:hover {
          color: ${({ theme }) => theme.colors.text.t9};
        }
      }
    }
  }
  // check icon vertical alignment
  .ant-select-item-option-selected {
    .ant-select-item-option-state {
      padding: 8px 0;
    }
  }

  // Text to speech number settings dropdown
  .tts-person-dropdown {
    .ant-select-item {
      padding: 0 8px !important;
      font-weight: ${({ theme }) => theme.text.weight.regular};
      font-size: ${({ theme }) => theme.text.size.body.text.small};
    }
  }
`;
