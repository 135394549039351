import styled from 'styled-components';
import { Dropdown } from 'antd';

export const WidgetWrapper = styled.div<any>`
  position: ${props => (props.isDialerPage ? 'fixed' : 'absolute')};
  left: ${props => (props.isDialerPage ? '50%' : 'auto')};
  top: ${props => (props.isDialerPage ? '50%' : 'auto')};
  bottom: ${props => (props.isDialerPage ? 'auto' : '30px')};
  right: ${props => (props.isDialerPage ? 'auto' : '60px')};
  transform: ${props => (props.isDialerPage ? 'translate(-50%,-50%) !important' : 'auto')};
  z-index: 99999;
`;

export const WidgetContainer = styled.div<any>`
  width: 320px;
  min-height: 526px;
  background: #ffffff;
  border: 1px solid #e7e6eb;
  box-shadow: 0px 4px 28px -4px rgb(66 56 95 / 10%);
  border-radius: 12px;
  & > div:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .dropdown-overlay {
    top: auto !important;
    left: auto !important;
    right: 416px !important;
    bottom: -227px !important;
  }

  .ant-tooltip-inner {
    background: #10003a;
    border: 1px solid #3d3358;
    padding: 5px 11px;
    white-space: nowrap;
  }

  .disabled,
  button[disabled] {
    &,
    & * {
      cursor: url('/static-icons/disabled-cursor-light.svg'), auto !important;
    }
  }
`;
export const CustomPopover = styled(Dropdown)`
  .ant-dropdown {
    top: 42px !important;
    left: -238px !important;
  }
`;
