import { useState, useEffect } from 'react';

interface IProps {
  recordingTimeSec?: number;
  recordingTimeMin?: number;
  startTimer?: boolean;
}

export function useRecordingTimer({
  recordingTimeSec = 0,
  recordingTimeMin = 0,
  startTimer,
}: IProps) {
  const [seconds, setSeconds] = useState<number>(recordingTimeSec);
  const [minutes, setminutes] = useState<number>(recordingTimeMin);
  const [recorded, setRecorded] = useState<boolean>(false); // flag to check if recording initiated

  useEffect(() => {
    const interval = setInterval(() => {
      if (startTimer) {
        setSeconds((second: number) => second + 1);
        setRecorded(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startTimer]);

  if (seconds >= 60) {
    setSeconds(0);
    setminutes(minutes + 1);
  }

  const resetRecordedTime = () => {
    setSeconds(0);
    setminutes(0);
  };

  const minStr = minutes < 10 ? `0${minutes}` : minutes;
  const secStr = seconds < 10 ? `0${seconds}` : seconds;
  const recordingTime = `${minStr}:${secStr}`;

  return { recordingTime, seconds, minutes, recorded, resetRecordedTime };
}
