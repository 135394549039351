import * as yup from 'yup';
import { MIN_LENGTH } from './common';

export const MAX_LENGTH = 46;
export const NOTES_MAX_LENGTH = 200;

export const campaignSettingsInput = {
  campaignName: yup
    .string()
    .trim()
    .required('Required')
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(MAX_LENGTH, `Shouldn't exceed ${MAX_LENGTH} characters`),
  skipCsvUpload: yup.boolean(),
  isContactListHidden: yup.boolean(),
  contactListId: yup.string(),
  file: yup.mixed(),
  assigneeId: yup.string().required('Required'),
  assigneeName: yup.string().required('Required'),
  dialingNumber: yup.string().nullable(),
  dataCenter: yup.string(),
  isVoicemailEnabled: yup.boolean(),
  voiceMailId: yup.string().required('Required'),
  isCallRecordingEnabled: yup.boolean(),
  isCoolOffPeriodEnabled: yup.boolean(),
  isAttemptsPerCallEnabled: yup.boolean(),
  coolOffPeriod: yup.number().typeError('Must be a number'), // TODO: might need to add limit validation here
  callAttemptCount: yup.number().typeError('Must be a number'),
  callAttemptGap: yup.number().typeError('Must be a number'),
  isCallScriptEnabled: yup.boolean(),
  callScriptId: yup.string().when('isCallScriptEnabled', ([isCallScriptEnabled], schema) => {
    return isCallScriptEnabled ? schema.required('Please select a Call Script') : schema.nullable();
  }),
  dialingNumberId: yup.string().required('Required'),
  createdByName: yup.string(),
};
