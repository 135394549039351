import { css } from 'styled-components';

export const antMessage = css`
  .ant-message-notice {
    padding: 10px 8px !important;
  }
  .ant-message-notice-content {
    padding: 11px 25px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.text.t7};
    font-weight: ${({ theme }) => theme.text.weight.medium};
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    letter-spacing: 0.05em;
    @media screen and (min-width: 768px) {
      height: 40px;
    }
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
  .ant-message-custom-content {
    display: flex;
    align-items: center;
    &.ant-message-info,
    &.ant-message-success,
    &.ant-message-error,
    &.ant-message-warning {
      line-height: 14px;
      svg {
        font-size: ${({ theme }) => theme.text.size.body.text.small};
      }
      span:nth-child(1) {
        position: relative;
        top: 0px;
        margin-right: 8px;
        img {
          width: 14px;
          position: relative;
          top: -2px;
          margin-right: -4px;
          &.country-flag {
            width: 20px;
            margin-right: 0;
          }
        }
      }
      & > span:last-child {
        color: ${({ theme }) => theme.colors.text.t7};
        letter-spacing: 0.05em;
      }

      .anticon {
        svg {
          top: 0;
        }
      }
    }
    &.ant-message-success {
      svg {
        color: ${({ theme }) => theme.colors.success.default};
      }
    }
    &.ant-message-error {
      svg {
        color: ${({ theme }) => theme.colors.danger.default};
      }
    }
    &.ant-message-warning {
      svg {
        color: ${({ theme }) => theme.colors.warning.default};
      }
    }
    &.ant-message-info.anticon {
      color: ${({ theme }) => theme.colors.primary.p5};
    }
  }
  .ant-message {
    top: 0 !important;
    z-index: 10100;
  }
`;
