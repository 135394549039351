import styled from 'styled-components';
import { TextAreaInput } from 'components/molecules/fields';
import { Button } from 'antd';

export const InputField = styled(TextAreaInput)`
  background: #f5f2f8 !important;
  color: #564d6d;
  padding: 9px 32px 9px 14px;
  font-size: 13px;
  line-height: 18px !important;
  outline: none !important;
  border: 1px solid #d7cce4 !important;
  height: 38px !important;
  border-radius: 4px !important;
  margin: 0;
  &::placeholder {
    color: #b7b3c1;
  }
`;

export const SendButton = styled(Button)`
  position: absolute;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  svg {
    color: #564d6d;
  }
  &:disabled {
    svg {
      color: #564d6d;
    }
  }
`;
