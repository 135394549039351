import { Tooltip as AntTooltip } from 'antd';
import styled from 'styled-components';

type AntTooltipProps = React.ComponentProps<typeof AntTooltip>;

const StyledTooltip = styled(AntTooltip)``;

const Tooltip = ({ ...props }: AntTooltipProps) => {
  return (
    <>
      <StyledTooltip data-cy='tool-tip' {...props} />
    </>
  );
};

export default Tooltip;
