import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Avatar, Menu } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';

import { Note } from 'generated/graphql';
import { TextAreaInput } from 'components/molecules/fields';
import { Icon, ToastMessage } from 'components/atoms';
import { dateWithTime } from 'components/utils/datetime';
import useLocalStorage from 'hooks/useLocalStorage';
import { ManIcon } from 'components/atoms/icon';
import { MoreOptionPopover } from 'components/pages/settings/common';

import * as S from './Styles';
import { useClientNoteForm } from './hooks/useClientNoteForm';
import useClientNote from './hooks/useClientNote';

interface NoteDisplayCardProps {
  note: Note;
  openDeleteModal: (id: string) => () => void;
}

export default function NoteDisplayCard({
  note,
  openDeleteModal,
}: NoteDisplayCardProps): ReactElement {
  const notesFieldRef = useRef();
  const { editContactNote } = useClientNote();
  const { methods, handleNotesChanges } = useClientNoteForm({
    type: 'edit',
    editContactNote,
    selectedNoteId: note.id,
  });
  const {
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue('note', note.title);
  }, [setValue, note.title]);

  const { t } = useTranslation();
  const [user] = useLocalStorage('user', '');
  const [showNoteInputField, setShowNoteInputField] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const author = () => {
    if (user?.details?.id === note?.userId) return 'You';
    return `${note?.firstName} ${note?.lastName}`;
  };

  const theme = useTheme();

  const onCopy = () => {
    ToastMessage({
      content: <Trans i18nKey='NoteCopied'>Note Copied.</Trans>,
      icon: (
        <span>
          <Icon name='copy-stack' />
        </span>
      ),
    });
  };

  const onNotesChanges = (e: React.FormEvent<HTMLInputElement>) => {
    setShowNoteInputField(false);
    handleNotesChanges(e);
  };

  const handleOptions = ({ key }: { key: string }) => {
    setDropdownVisible(false);
    if (key === 'delete') {
      openDeleteModal(note?.id)();
    }
    if (key === 'edit') setShowNoteInputField(true);
  };
  const options = (
    <Menu onClick={handleOptions}>
      <Menu.Item key='edit' icon={<Icon name='edit-square' />}>
        Edit
      </Menu.Item>
      <CopyToClipboard text={note?.title} onCopy={onCopy}>
        <Menu.Item key='copyNote' icon={<Icon name='copy-stack' />}>
          {t('copy', 'Copy')}
        </Menu.Item>
      </CopyToClipboard>
      <Menu.Divider />
      <Menu.Item key='delete' icon={<Icon name='delete-bin' />} danger>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <S.NotesDisplayCard key={note?.id}>
      <div className='note--header'>
        <Avatar
          size={36}
          className='note--avatar'
          shape='square'
          src={note?.profilePicture}
          icon={<ManIcon bgColor={theme.colors.text.t1} color={theme.colors.text.t2} />}
        />
        <div className='note--user truncate'>
          {author()}
          <div className='note--time'>{`${note?.createdAt && dateWithTime(note?.createdAt)}`}</div>
        </div>

        <div className='note--action self-start pt-1'>
          <MoreOptionPopover
            optionDropdownVisible={dropdownVisible}
            setOptionDropdownVisible={setDropdownVisible}
            options={options}
            overlayClassName='more-options-contact-notes'
          />
        </div>
      </div>
      {showNoteInputField ? (
        <div className='mt-2.5'>
          <TextAreaInput
            autoSize={{ minRows: 2, maxRows: 3 }}
            ref={notesFieldRef}
            name='note'
            id='noteBox'
            control={control}
            onPressEnter={onNotesChanges}
            placeholder={t('addNote.placeholder', 'Write a note...')}
            errors={errors}
            autoFocus
          />
        </div>
      ) : (
        <div className='note--body'>{note.title}</div>
      )}
    </S.NotesDisplayCard>
  );
}
