export default function ContactTags() {
  return (
    <svg
      width='200'
      height='200'
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z'
        fill='#F3F7FE'
      />
      <g filter='url(#filter0_d_4989_42583)'>
        <rect x='67.6123' y='62' width='68.0886' height='73.3713' rx='10' fill='#4882F6' />
        <rect x='68.1123' y='62.5' width='67.0886' height='72.3713' rx='9.5' stroke='#3777F7' />
      </g>
      <rect x='74.6562' y='68.4572' width='54.5883' height='60.458' rx='8' fill='#4882F6' />
      <path
        d='M96.6671 86.3599C100.517 86.3599 103.637 83.2392 103.637 79.3896C103.637 75.54 100.517 72.4193 96.6671 72.4193C92.8175 72.4193 89.6968 75.54 89.6968 79.3896C89.6968 83.2392 92.8175 86.3599 96.6671 86.3599Z'
        fill='white'
      />
      <path
        d='M96.6676 89.845C89.6834 89.845 83.9956 94.5291 83.9956 100.3C83.9956 100.691 84.3023 100.997 84.6926 100.997H108.643C109.033 100.997 109.34 100.691 109.34 100.3C109.34 94.5291 103.652 89.845 96.6676 89.845Z'
        fill='white'
      />
      <path
        d='M116.581 105.932H86.1348C85.0302 105.932 84.1348 106.828 84.1348 107.932V109.215C84.1348 110.32 85.0302 111.215 86.1348 111.215H107.333C107.88 111.215 108.402 110.987 108.791 110.602C112.538 106.885 115.518 105.932 116.581 105.932Z'
        fill='white'
      />
      <path
        d='M84.1348 118.492V117.209C84.1348 116.104 85.0302 115.209 86.1348 115.209H106.061C105.529 115.209 104.965 117.124 104.585 118.836C104.374 119.788 103.546 120.492 102.571 120.492H86.1348C85.0302 120.492 84.1348 119.596 84.1348 118.492Z'
        fill='white'
      />
      <g filter='url(#filter1_d_4989_42583)'>
        <circle cx='125.67' cy='124.871' r='12.5487' fill='#4882F6' />
        <circle cx='125.67' cy='124.871' r='15.5487' stroke='#F3F7FE' strokeWidth='6' />
      </g>
      <path
        d='M131.114 122.906L128.099 119.891C127.466 119.258 126.594 118.919 125.702 118.966L122.374 119.125C121.043 119.185 119.984 120.244 119.918 121.568L119.758 124.896C119.718 125.788 120.051 126.66 120.683 127.293L123.699 130.308C124.937 131.546 126.947 131.546 128.192 130.308L131.114 127.386C132.359 126.155 132.359 124.144 131.114 122.906ZM124.238 125.356C123.18 125.356 122.321 124.497 122.321 123.439C122.321 122.38 123.18 121.522 124.238 121.522C125.296 121.522 126.155 122.38 126.155 123.439C126.155 124.497 125.296 125.356 124.238 125.356Z'
        fill='white'
      />
      <path
        d='M61.9999 109.847L67.6123 109.847L67.6123 119.672L63.5266 119.672C62.6834 119.672 61.9999 118.988 61.9999 118.145L61.9999 109.847Z'
        fill='#FFE07D'
      />
      <path
        d='M67.6123 119.672L64.8061 119.672L64.8061 109.847L67.6123 109.847L67.6123 119.672Z'
        fill='#FFD064'
      />
      <path
        d='M67.6123 109.847L61.9999 109.847L61.9999 100.022L67.6123 100.022L67.6123 109.847Z'
        fill='#95D6A4'
      />
      <path
        d='M67.6123 109.847L64.8061 109.847L64.8061 100.022L67.6123 100.022L67.6123 109.847Z'
        fill='#78C2A4'
      />
      <path
        d='M67.6123 100.022L61.9999 100.022L61.9999 90.1965L67.6123 90.1965L67.6123 100.022Z'
        fill='#E7A561'
      />
      <path
        d='M67.6123 100.022L64.8061 100.022L64.8061 90.1965L67.6123 90.1965L67.6123 100.022Z'
        fill='#E49542'
      />
      <path
        d='M63.5266 80.3718L67.6123 80.3718L67.6123 90.1969L61.9999 90.1969L61.9999 81.8984C61.9999 81.0552 62.6834 80.3718 63.5266 80.3718Z'
        fill='#DF73C1'
      />
      <path
        d='M67.6123 90.1969L64.8061 90.1969L64.8061 80.3718L67.6123 80.3718L67.6123 90.1969Z'
        fill='#DD4FB1'
      />
      <defs>
        <filter
          id='filter0_d_4989_42583'
          x='55.6123'
          y='52'
          width='92.0884'
          height='97.3713'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='6' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.117647 0 0 0 0 0.141176 0 0 0 0.02 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4989_42583' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4989_42583'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_4989_42583'
          x='91.1211'
          y='94.3226'
          width='69.0972'
          height='69.0974'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.117647 0 0 0 0 0.141176 0 0 0 0.1 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4989_42583' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4989_42583'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
