import { datadogLogs } from '@datadog/browser-logs';

// LOG ERROR IN DATADOG
export const logInfoDatdog = (err: any, additionalInfo: any) => {
  datadogLogs.logger.info(additionalInfo?.errText, {
    dataDogError: err,
    additionalInfo,
    context: additionalInfo?.context,
  });
};
