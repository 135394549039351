import styled from 'styled-components';
import { Popover, Checkbox } from 'antd';
import ScrollBar from 'react-perfect-scrollbar';
import { Button } from 'components/atoms';

export const WorkSpaceProfileWrapper = styled.div`
  height: 100vh;
  width: 60px;
  background-color: ${({ theme }) => theme.colors.primary.default};
  padding: 18px 0 24px 0;
`;

export const AddOrganizationButton = styled(Button)`
  cursor: pointer;
  margin: 0 auto 16px auto !important;
  width: 32px;
  height: 32px;
  svg {
    color: #cfccd5;
    border-radius: 9px;
    padding: 10px;
    height: 32px;
    width: 32px;
    transition: 0.2s ease-out;
  }
  &:active,
  :hover,
  :visited {
    svg {
      background: #613494;
    }
  }
`;

export const BadgeWrapper = styled.div`
  position: relative;
  min-height: 42px;
  .ant-badge {
    display: block;
    line-height: 14px;
    cursor: pointer;
    &.badge-custom:first-child {
      margin-bottom: 14px;
    }
  }
  .ant-badge-status-dot {
    height: 12px;
    width: 12px;
    background: ${({ theme }) => theme.colors.text.t4};
  }
  .ant-badge-status-text {
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    line-height: 12px;
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.text.t4};
  }

  .ant-badge-status.online {
    .ant-badge-status-dot {
      background: ${({ theme }) => theme.colors.success.default} !important;
    }
  }
  .ant-badge-status.active {
    .ant-badge-status-text {
      color: ${({ theme }) => theme.colors.text.t9} !important;
    }
  }
`;

export const StyledScrollbar = styled(ScrollBar)`
  height: auto;
  max-height: calc(100% - 100px);
  .ps__rail-y {
    background: none !important;
  }
`;

export const ModalHead = styled.div`
  img {
    font-size: 40px;
    margin: 0 auto 8px;
  }
`;
export const CheckboxStyled = styled<any>(Checkbox)`
  .ant-checkbox + span {
    position: relative;
    padding-left: 10px;
    color: ${({ theme }) => theme.colors.text.t6};
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
  }
`;

export const CustomPopover = styled(Popover)`
  .ant-popover {
    .ant-popover-inner {
      border-radius: 10px;
      background: none;
      box-shadow: none;
      &-content {
        padding: 0;
      }
      .ant-menu {
        padding: 10px 0;
        background: #ffffff;
        border: 1px solid #e7e6eb;
        box-shadow: 0px 4px 28px -4px rgb(66 56 95 / 20%);
        border-radius: 10px;
        overflow: hidden;
        .ant-menu-submenu-title {
          padding: 0 16px;
          line-height: 34px;
        }
        .ant-menu-title-content {
          width: 100%;
        }
        .ant-menu-submenu-expand-icon {
          font-size: 20px;
          width: 20px;
          color: #6e6681;
          margin-right: -6px;
        }
      }
    }
    .ant-popover-arrow {
      display: none;
    }
  }
`;

export const LogoWrapperBtn = styled.div`
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
