import { memo } from 'react';
import styled from 'styled-components';

interface EmptyCardProps {
  icon?: any;
  info?: string;
  action?: string;
  handleAction?: () => void;
}

const ActionLink = styled.span`
  color: #1a63f4;
  cursor: pointer;
  margin-left: 3px;
`;

function EmptyCard({ icon, info, action, handleAction }: EmptyCardProps) {
  return (
    <div
      className='rounded-lg bg-gray-100 bg-opacity-60 py-6 px-2 flex flex-col items-center justify-center'
      data-cy='number-collapse'
    >
      <div className='h-10 w-10'>{icon}</div>
      <p className='text-center pt-3 text-13 leading-5 text-gray'>
        {info}
        <ActionLink onClick={handleAction}>{action}</ActionLink>
      </p>
    </div>
  );
}

export default memo(EmptyCard);
