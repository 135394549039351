import React from 'react';
import styled from 'styled-components';

import useScreenType from 'hooks/useScreenType';

export interface AuxProps {
  children: React.ReactNode;
}

const Wrapper = styled.div<any>`
  height: 100vh;
  width: ${props => `${props.width}px`};
  position: fixed;
  background: ${({ theme }) => theme.colors.primary.p0};
  z-index: 1;
  & + main {
    margin-left: ${props => `${props.width}px`};
    width: calc(100vw - ${props => `${props.width}px`} - 60px);
    // screen width - sidebar - workspace listbar
  }
  .content-wrapper {
    overflow-x: hidden;
  }
  .sideWorkspaceBar-label {
    margin-left: 3px;
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
  }

  > div > a {
    display: block;
    margin-bottom: 2px;
  }
`;
const SidebarWrapper = ({ children }: AuxProps) => {
  const { isBigScreen } = useScreenType();
  return <Wrapper width={250}>{children}</Wrapper>;
};

export default SidebarWrapper;
