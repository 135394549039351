/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useState } from 'react';
import useScreenType from 'hooks/useScreenType';

import { DollarSack } from 'components/atoms/vectors';
import { EditFieldModal } from 'components/organisms';
import CreditForm from 'components/pages/settings/credit/form/CreditForm';
import { usePurchaseHandler } from 'components/pages/settings/credit/hooks/usePurchaseHandler';

import * as S from '../Styles';

const AccountSuspendNotification = () => {
  const [processing, setProcessing] = useState(false);
  const { isTablet, isMobile } = useScreenType();
  const [visibleCreditModal, setVisibleCreditModal] = useState(() => false);
  const toggleCreditModal = () => {
    setVisibleCreditModal(prevState => !prevState);
  };

  const { onPurchase, restricted } = usePurchaseHandler({
    onModalCancel: toggleCreditModal,
    setProcessing,
  });
  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={
          <>
            <p className='hidden lg:inline-block'>
              Your KrispCall Account is currently suspended due to a lack of funds.{' '}
              <span onClick={toggleCreditModal} className='underline bold cursor-pointer'>
                Recharge your account{' '}
              </span>
              and get back to making calls.
            </p>
            <p className='lg:hidden'>
              Account suspended due to a lack of funds.{' '}
              <span onClick={toggleCreditModal} className='underline bold cursor-pointer'>
                Recharge your account{' '}
              </span>
              and get back to making calls.
            </p>
          </>
        }
        type='error'
        banner
        className={`has-bordered-btn ${isTablet || isMobile ? 'alert-sm' : ''}`}
      />
      {visibleCreditModal && (
        <EditFieldModal
          vector={<DollarSack />}
          title='Purchase Credits'
          subTitle='Buy credit to make best out of KrispCall'
          buttonText='Make Payment'
          isModalOpen={visibleCreditModal}
          formId='purchase-credit-form'
          onCancel={toggleCreditModal}
          loading={restricted || processing}
          maskClosable={!(restricted || processing)}
        >
          <CreditForm onSubmit={onPurchase} />
        </EditFieldModal>
      )}
    </S.AlertWrapper>
  );
};

export default AccountSuspendNotification;
