export default function Review({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='64' height='64' rx='20' fill='#FCF2E7' />
      <path
        d='M47.6369 12H16.3633C15.9588 12 15.6309 12.328 15.6309 12.7324V16.1017L15.9015 16.1798H48.0988L48.3695 16.1017V12.7324C48.3694 12.328 48.0414 12 47.6369 12V12Z'
        fill='#26A6FE'
      />
      <path
        d='M19.994 12H16.3633C15.9588 12 15.6309 12.328 15.6309 12.7324V16.1017H19.2616V12.7324C19.2616 12.328 19.5895 12 19.994 12Z'
        fill='#0593FC'
      />
      <path
        d='M48.3695 16.1018H15.6309V42.785C16.543 44.286 17.6567 45.6507 18.9329 46.8437H45.0673C46.3435 45.6507 47.4573 44.286 48.3694 42.7849V16.1018H48.3695Z'
        fill='#EAF6FF'
      />
      <path
        d='M48.3695 16.1018H15.6309V42.785C16.543 44.286 17.6567 45.6507 18.9329 46.8437H45.0673C46.3435 45.6507 47.4573 44.286 48.3694 42.7849V16.1018H48.3695Z'
        fill='#EAF6FF'
      />
      <path
        d='M19.2616 16.1018H15.6309V42.785C16.543 44.286 17.6567 45.6507 18.9329 46.8437H19.2616V16.1018Z'
        fill='#D8ECFE'
      />
      <path
        d='M38.1649 23.7969C36.6787 23.7969 35.4696 22.5877 35.4696 21.1016C35.4696 20.6126 35.6011 20.1541 35.8296 19.7584C34.6898 19.1444 33.3859 18.7959 32.0005 18.7959C27.5345 18.7959 23.9141 22.4163 23.9141 26.8823C23.9141 29.6848 25.3398 32.154 27.5053 33.6048L27.5914 33.5684C27.8125 32.4046 28.4991 31.3614 29.4846 30.6924C30.2325 31.188 31.1012 31.4496 32.0005 31.4496C32.8997 31.4496 33.7684 31.188 34.5163 30.6924C35.5018 31.3614 36.1884 32.4046 36.4095 33.5684L36.4956 33.6048C38.6612 32.154 40.0869 29.6848 40.0869 26.8823C40.0869 25.682 39.825 24.543 39.3559 23.5187C38.9966 23.6964 38.5923 23.7969 38.1649 23.7969V23.7969Z'
        fill='#FED402'
      />
      <path
        d='M27.5054 33.6048C27.6125 33.0025 27.8388 32.4418 28.1572 31.946C27.0424 30.5601 26.3745 28.7995 26.3745 26.8824C26.3745 22.8345 29.3486 19.4814 33.2308 18.8889C32.8296 18.8277 32.4188 18.7959 32.0005 18.7959C27.5345 18.7959 23.9141 22.4164 23.9141 26.8824C23.9141 29.6849 25.3398 32.1541 27.5054 33.605C27.5055 33.6049 27.5055 33.6048 27.5054 33.6048V33.6048Z'
        fill='#FAC600'
      />
      <path
        d='M34.517 30.5984C33.7988 31.0861 32.9327 31.3715 32.0011 31.3715C31.0695 31.3715 30.2034 31.0861 29.4852 30.5984C28.4632 31.2749 27.7288 32.3512 27.5059 33.6048C28.7914 34.4661 30.3375 34.9687 32.0011 34.9687C33.6647 34.9687 35.2108 34.4661 36.4963 33.6048C36.2734 32.3512 35.539 31.2749 34.517 30.5984V30.5984Z'
        fill='#26A6FE'
      />
      <path
        d='M29.4146 30.6462C28.4295 31.3251 27.7236 32.3803 27.5059 33.6048C27.9685 33.9148 28.4654 34.1775 28.989 34.3879C29.2429 33.1716 29.8677 32.0919 30.7395 31.2717C30.261 31.1344 29.8148 30.9217 29.4146 30.6462V30.6462Z'
        fill='#0593FC'
      />
      <path
        d='M32.0009 31.4496C34.5234 31.4496 36.5683 29.4047 36.5683 26.8823C36.5683 24.3598 34.5234 22.3149 32.0009 22.3149C29.4785 22.3149 27.4336 24.3598 27.4336 26.8823C27.4336 29.4047 29.4785 31.4496 32.0009 31.4496Z'
        fill='#8AC9FE'
      />
      <path
        d='M29.0381 26.8823C29.0381 24.6337 30.6635 22.766 32.8032 22.3866C32.5426 22.3405 32.2748 22.3149 32.0009 22.3149C29.4784 22.3149 27.4336 24.3598 27.4336 26.8823C27.4336 29.4048 29.4784 31.4496 32.0009 31.4496C32.2748 31.4496 32.5426 31.4241 32.8032 31.3779C30.6635 30.9986 29.0381 29.1308 29.0381 26.8823V26.8823Z'
        fill='#60B7FF'
      />
      <path
        d='M40.4375 38.7969H23.5625C23.2388 38.7969 22.9766 38.5345 22.9766 38.2109C22.9766 37.8873 23.2388 37.625 23.5625 37.625H40.4375C40.7612 37.625 41.0234 37.8873 41.0234 38.2109C41.0234 38.5345 40.7612 38.7969 40.4375 38.7969Z'
        fill='#60B7FF'
      />
      <path
        d='M37.4688 41.8438H26.5312C26.2076 41.8438 25.9453 41.5814 25.9453 41.2578C25.9453 40.9342 26.2076 40.6719 26.5312 40.6719H37.4688C37.7924 40.6719 38.0547 40.9342 38.0547 41.2578C38.0547 41.5814 37.7924 41.8438 37.4688 41.8438Z'
        fill='#60B7FF'
      />
      <path
        d='M18.0547 14.5781C18.3783 14.5781 18.6406 14.3158 18.6406 13.9922C18.6406 13.6686 18.3783 13.4062 18.0547 13.4062C17.7311 13.4062 17.4688 13.6686 17.4688 13.9922C17.4688 14.3158 17.7311 14.5781 18.0547 14.5781Z'
        fill='#FE646F'
      />
      <path
        d='M20.1641 14.5781C20.4877 14.5781 20.75 14.3158 20.75 13.9922C20.75 13.6686 20.4877 13.4062 20.1641 13.4062C19.8405 13.4062 19.5781 13.6686 19.5781 13.9922C19.5781 14.3158 19.8405 14.5781 20.1641 14.5781Z'
        fill='#FED402'
      />
      <path
        d='M22.2734 14.5781C22.597 14.5781 22.8594 14.3158 22.8594 13.9922C22.8594 13.6686 22.597 13.4062 22.2734 13.4062C21.9498 13.4062 21.6875 13.6686 21.6875 13.9922C21.6875 14.3158 21.9498 14.5781 22.2734 14.5781Z'
        fill='#8BC727'
      />
      <path
        d='M37.7734 23.5469C39.3052 23.5469 40.5469 22.3052 40.5469 20.7734C40.5469 19.2417 39.3052 18 37.7734 18C36.2417 18 35 19.2417 35 20.7734C35 22.3052 36.2417 23.5469 37.7734 23.5469Z'
        fill='#1A63F4'
      />
      <path
        d='M19.8906 29.2656C20.9477 29.2656 21.8047 28.4087 21.8047 27.3516C21.8047 26.2945 20.9477 25.4375 19.8906 25.4375C18.8335 25.4375 17.9766 26.2945 17.9766 27.3516C17.9766 28.4087 18.8335 29.2656 19.8906 29.2656Z'
        fill='#8AC9FE'
      />
      <path
        d='M19.5781 27.3516C19.5781 26.5805 20.0344 25.9163 20.6914 25.6131C20.4477 25.5007 20.1766 25.4375 19.8906 25.4375C18.8335 25.4375 17.9766 26.2945 17.9766 27.3516C17.9766 28.4087 18.8335 29.2656 19.8906 29.2656C20.1766 29.2656 20.4477 29.2024 20.6914 29.09C20.0344 28.7868 19.5781 28.1227 19.5781 27.3516Z'
        fill='#60B7FF'
      />
      <path
        d='M44.1094 29.2656C45.1665 29.2656 46.0234 28.4087 46.0234 27.3516C46.0234 26.2945 45.1665 25.4375 44.1094 25.4375C43.0523 25.4375 42.1953 26.2945 42.1953 27.3516C42.1953 28.4087 43.0523 29.2656 44.1094 29.2656Z'
        fill='#8AC9FE'
      />
      <path
        d='M43.7969 27.3516C43.7969 26.5805 44.2531 25.9163 44.9102 25.6131C44.6665 25.5007 44.3954 25.4375 44.1094 25.4375C43.0523 25.4375 42.1953 26.2945 42.1953 27.3516C42.1953 28.4087 43.0523 29.2656 44.1094 29.2656C44.3954 29.2656 44.6665 29.2024 44.9102 29.09C44.2531 28.7868 43.7969 28.1227 43.7969 27.3516Z'
        fill='#60B7FF'
      />
      <g clipPath='url(#clip0_16677_155974)'>
        <path
          d='M39.8173 20.9091H39.09C38.9896 20.9091 38.9082 20.9905 38.9082 21.0909C38.9082 21.1913 38.9896 21.2727 39.09 21.2727H39.8173C39.9177 21.2727 39.9991 21.1913 39.9991 21.0909C39.9991 20.9905 39.9177 20.9091 39.8173 20.9091Z'
          fill='white'
        />
        <path
          d='M36.9091 20.9091H36.1818C36.0814 20.9091 36 20.9905 36 21.0909C36 21.1913 36.0814 21.2727 36.1818 21.2727H36.9091C37.0095 21.2727 37.0909 21.1913 37.0909 21.0909C37.0909 20.9905 37.0095 20.9091 36.9091 20.9091Z'
          fill='white'
        />
        <path
          d='M39.1574 19.6767L38.6431 20.191C38.5721 20.2619 38.5721 20.3771 38.6431 20.4481C38.6786 20.4836 38.7251 20.5013 38.7716 20.5013C38.8182 20.5013 38.8647 20.4836 38.9002 20.4481L39.4145 19.9338C39.4855 19.8628 39.4855 19.7477 39.4145 19.6767C39.3435 19.6057 39.2284 19.6057 39.1574 19.6767Z'
          fill='white'
        />
        <path
          d='M38.0002 22.1818C37.8998 22.1818 37.8184 22.2632 37.8184 22.3636V22.7273C37.8184 22.8277 37.8998 22.9091 38.0002 22.9091C38.1006 22.9091 38.182 22.8277 38.182 22.7273V22.3636C38.182 22.2632 38.1006 22.1818 38.0002 22.1818Z'
          fill='white'
        />
        <path
          d='M37.1007 21.7337L36.5865 22.248C36.5155 22.319 36.5155 22.4341 36.5865 22.5051C36.622 22.5406 36.6685 22.5584 36.715 22.5584C36.7616 22.5584 36.8081 22.5406 36.8436 22.5051L37.3578 21.9909C37.4288 21.9198 37.4288 21.8047 37.3578 21.7337C37.2868 21.6627 37.1717 21.6627 37.1007 21.7337Z'
          fill='white'
        />
        <path
          d='M38.0002 19.0909C37.8998 19.0909 37.8184 19.1723 37.8184 19.2727V20C37.8184 20.1004 37.8998 20.1818 38.0002 20.1818C38.1006 20.1818 38.182 20.1004 38.182 20V19.2727C38.182 19.1723 38.1006 19.0909 38.0002 19.0909Z'
          fill='white'
        />
        <path
          d='M36.8436 19.6767C36.7726 19.6057 36.6575 19.6057 36.5865 19.6767C36.5155 19.7477 36.5155 19.8628 36.5865 19.9338L37.1007 20.4481C37.1362 20.4836 37.1827 20.5013 37.2293 20.5013C37.2758 20.5013 37.3224 20.4836 37.3578 20.4481C37.4288 20.3771 37.4288 20.262 37.3578 20.191L36.8436 19.6767Z'
          fill='white'
        />
      </g>
      <path
        d='M46.4072 45.2202L50.4929 48.8557C51.1701 49.5235 51.1949 50.5879 50.5484 51.2286C49.9028 51.8683 48.8333 51.8417 48.1629 51.1691L44.082 47.5141L46.4072 45.2202Z'
        fill='#F3D332'
      />
      <path
        d='M33 41.2965C33 40.4468 33.1448 39.631 33.411 38.8725C34.4072 36.0343 37.1038 34 40.274 34C44.2913 34 47.5479 37.2667 47.5479 41.2964C47.5479 45.3261 44.2913 48.5929 40.274 48.5929C36.2567 48.5929 33 45.3262 33 41.2965Z'
        fill='#30579D'
      />
      <path
        d='M35.7324 41.2965C35.7324 40.766 35.8228 40.2566 35.9891 39.7831C36.6111 38.011 38.2947 36.7409 40.274 36.7409C42.7822 36.7409 44.8156 38.7805 44.8156 41.2965C44.8156 43.8125 42.7822 45.8521 40.274 45.8521C37.7657 45.8521 35.7324 43.8125 35.7324 41.2965V41.2965Z'
        fill='#E1EBF0'
      />
      <defs>
        <clipPath id='clip0_16677_155974'>
          <rect width='4' height='4' fill='white' transform='translate(36 19)' />
        </clipPath>
      </defs>
    </svg>
  );
}
