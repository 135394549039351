import styled from 'styled-components';

export const InfoGraph = styled.div`
  height: fit-content;
  &.call-stats-infographic {
    padding: 31px 23px;
    min-width: 185px;
    border: 1px solid #f3f2f4;
    margin: -1px 0 -1px -1px;
    min-height: 144px;
  }
  &.call-stats-with-progress {
    padding: 29px 24px;
    min-height: 140px;
  }
  &.call-duration-infographic {
    padding: 29px 23px;
    min-width: 174px;
    min-height: 139px;
  }
  .ant-progress-text {
    font-family: Manrope;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #3d3358;
  }
  .ant-tooltip-inner {
    font-family: Heebo;
    line-height: 18px;
    color: #ffffff;
    text-transform: lowercase;
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;
