export default function Banned() {
  return (
    <svg
      width='200'
      height='200'
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='100' cy='100' r='100' fill='#E9F0FE' />
      <g clipPath='url(#clip0_4529_84974)'>
        <path
          d='M117.5 70H79.1667C74.1 70 70 74.1 70 79.1667V120.833C70 125.9 74.1 130 79.1667 130H101.2C99.3667 126.2 98.3333 121.967 98.3333 117.5L98.3667 116.667H82.5C81.1333 116.667 80 115.533 80 114.167C80 112.8 81.1333 111.667 82.5 111.667H98.9333C99.3333 109.633 99.97 107.667 100.77 105.833H82.5C81.1333 105.833 80 104.7 80 103.333C80 101.967 81.1333 100.833 82.5 100.833H103.567C105.1 98.6667 106.9 96.7 108.93 95H82.5C81.1333 95 80 93.8667 80 92.5C80 91.1333 81.1333 90 82.5 90H114.167C114.867 90 115.467 90.2667 115.933 90.7333C119.233 89.3 122.867 88.4667 126.667 88.3667V79.1667C126.667 74.1 122.567 70 117.5 70Z'
          fill='#2196F3'
        />
        <path
          d='M110.833 66.6667H107.167C106.067 62.8333 102.533 60 98.3333 60C94.1333 60 90.6 62.8333 89.5 66.6667H85.8333C84.4667 66.6667 83.3333 67.8 83.3333 69.1667V75.8333C83.3333 79.0333 85.9667 81.6667 89.1667 81.6667H107.5C110.7 81.6667 113.333 79.0333 113.333 75.8333V69.1667C113.333 67.8 112.2 66.6667 110.833 66.6667Z'
          fill='#1976D2'
        />
        <path
          d='M115.933 90.7333C113.4 91.8333 111.033 93.2667 108.933 95H82.5C81.1333 95 80 93.8667 80 92.5C80 91.1333 81.1333 90 82.5 90H114.167C114.867 90 115.467 90.2667 115.933 90.7333Z'
          fill='white'
        />
        <path
          d='M103.567 100.834C102.467 102.4 101.533 104.067 100.767 105.834H82.5C81.1333 105.834 80 104.7 80 103.334C80 101.967 81.1333 100.834 82.5 100.834H103.567Z'
          fill='white'
        />
        <path
          d='M98.9333 111.666C98.6 113.3 98.4 114.966 98.3667 116.666H82.5C81.1333 116.666 80 115.533 80 114.166C80 112.8 81.1333 111.666 82.5 111.666H98.9333Z'
          fill='white'
        />
        <path
          d='M83.3333 70H79.1667C74.1 70 70 74.1 70 79.1667V120.833C70 125.9 74.1 130 79.1667 130H98.3333V117.5V116.667H82.5C81.1333 116.667 80 115.533 80 114.167C80 112.8 81.1333 111.667 82.5 111.667H98.3333V105.833H82.5C81.1333 105.833 80 104.7 80 103.333C80 101.967 81.1333 100.833 82.5 100.833H98.3333V95H82.5C81.1333 95 80 93.8667 80 92.5C80 91.1333 81.1333 90 82.5 90H98.3333V81.6667H89.1667C85.9667 81.6667 83.3333 79.0333 83.3333 75.8333V70Z'
          fill='#1D83D4'
        />
        <path
          d='M98.3333 60C94.1333 60 90.6 62.8333 89.5 66.6667H85.8333C84.4667 66.6667 83.3333 67.8 83.3333 69.1667V70V75.8333C83.3333 79.0333 85.9667 81.6667 89.1667 81.6667H98.3333V60Z'
          fill='#1667B7'
        />
        <path
          d='M98.3333 90H82.5C81.1333 90 80 91.1333 80 92.5C80 93.8667 81.1333 95 82.5 95H98.3333V90Z'
          fill='#DEDEDE'
        />
        <path
          d='M98.3333 100.834H82.5C81.1333 100.834 80 101.967 80 103.334C80 104.7 81.1333 105.834 82.5 105.834H98.3333V100.834Z'
          fill='#DEDEDE'
        />
        <path
          d='M98.3333 111.666H82.5C81.1333 111.666 80 112.8 80 114.166C80 115.533 81.1333 116.666 82.5 116.666H98.3333V111.666Z'
          fill='#DEDEDE'
        />
        <path
          d='M112.959 130C112.202 130 111.444 129.71 110.867 129.133C109.711 127.977 109.711 126.107 110.867 124.95L134.95 100.867C136.106 99.7108 137.976 99.7108 139.133 100.867C140.289 102.024 140.289 103.894 139.133 105.05L115.05 129.133C114.47 129.713 113.716 130 112.959 130Z'
          fill='#F44336'
        />
        <path
          d='M125 135C113.972 135 105 126.031 105 115C105 103.969 113.972 95 125 95C136.028 95 145 103.969 145 115C145 126.031 136.028 135 125 135ZM125 100.926C117.24 100.926 110.926 107.24 110.926 115C110.926 122.76 117.24 129.074 125 129.074C132.76 129.074 139.074 122.76 139.074 115C139.074 107.24 132.76 100.926 125 100.926Z'
          fill='#F44336'
        />
        <path
          d='M111 128.402C111.083 128.513 111.175 128.622 111.279 128.722C111.382 128.825 111.488 128.917 111.6 129C111.396 128.805 111.195 128.608 111 128.402ZM125 111L113.539 122.422C114.59 124.039 115.978 125.422 117.601 126.469L125 119.095V111Z'
          fill='#D43A2F'
        />
        <path
          d='M125 95C113.972 95 105 103.969 105 115C105 120.357 107.119 125.228 110.559 128.822C110.76 129.033 110.967 129.24 111.178 129.441C114.772 132.884 119.643 135 125 135V129.074C122.188 129.074 119.569 128.247 117.367 126.822C115.693 125.738 114.262 124.307 113.178 122.633C111.753 120.434 110.926 117.812 110.926 115C110.926 107.24 117.24 100.926 125 100.926V95Z'
          fill='#D43A2F'
        />
      </g>
      <defs>
        <clipPath id='clip0_4529_84974'>
          <rect width='80' height='80' fill='white' transform='translate(70 60)' />
        </clipPath>
      </defs>
    </svg>
  );
}
