import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { CHANNEL_INFO } from 'graphql/channel';
import { Channel } from 'generated/graphql';

interface IProps {
  channelId?: string;
}

export function usePhoneQuery({ channelId }: IProps) {
  const [loadChannelInfo, { data }] = useLazyQuery(CHANNEL_INFO, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (channelId) {
      loadChannelInfo({
        variables: {
          channel: channelId,
        },
      });
    }
  }, [loadChannelInfo, channelId]);

  const channelInfo: Channel = data?.channelInfoData?.data;
  return channelInfo;
}
