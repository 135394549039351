export default function Budget() {
  return (
    <svg width='66' height='66' viewBox='0 0 66 66' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5575_68319)'>
        <path
          d='M51.1758 0H17.6602C16.5928 0 15.7266 0.86625 15.7266 1.93359V66H64.0664C65.1338 66 66 65.1338 66 64.0664V14.8242L51.1758 0Z'
          fill='white'
        />
        <path
          d='M41 0V66H64.2031C65.2705 66 66.1367 65.1338 66.1367 64.0664V14.8242L51.3125 0H41Z'
          fill='#E2DFF4'
        />
        <path
          d='M51.1758 0V12.8906C51.1758 13.958 52.042 14.8242 53.1094 14.8242H66L51.1758 0Z'
          fill='#D5D3EA'
        />
        <path
          d='M38.9297 11.6016H23.4609C22.3936 11.6016 21.5273 10.7353 21.5273 9.66797C21.5273 8.60063 22.3936 7.73438 23.4609 7.73438H38.9297C39.997 7.73438 40.8633 8.60063 40.8633 9.66797C40.8633 10.7353 39.997 11.6016 38.9297 11.6016Z'
          fill='#FD715C'
        />
        <path
          d='M38.9297 19.3359H23.4609C22.3936 19.3359 21.5273 18.4697 21.5273 17.4023C21.5273 16.335 22.3936 15.4688 23.4609 15.4688H38.9297C39.997 15.4688 40.8633 16.335 40.8633 17.4023C40.8633 18.4697 39.997 19.3359 38.9297 19.3359Z'
          fill='#FD715C'
        />
        <path
          d='M27.5859 66H3.86719C1.73134 66 0 64.2687 0 62.1328V27.3281C0 25.1923 1.73134 23.4609 3.86719 23.4609H27.5859C29.7218 23.4609 31.4531 25.1923 31.4531 27.3281V62.1328C31.4531 64.2687 29.7218 66 27.5859 66Z'
          fill='#005EDF'
        />
        <path
          d='M27.5859 23.4609H15.7266V66H27.5859C29.7218 66 31.4531 64.2687 31.4531 62.1328V27.3281C31.4531 25.1923 29.7218 23.4609 27.5859 23.4609Z'
          fill='#00429B'
        />
        <path
          d='M23.4609 43.4414C24.5288 43.4414 25.3945 42.5757 25.3945 41.5078C25.3945 40.4399 24.5288 39.5742 23.4609 39.5742C22.393 39.5742 21.5273 40.4399 21.5273 41.5078C21.5273 42.5757 22.393 43.4414 23.4609 43.4414Z'
          fill='#E2DFF4'
        />
        <path
          d='M7.99219 43.4414C9.06008 43.4414 9.92578 42.5757 9.92578 41.5078C9.92578 40.4399 9.06008 39.5742 7.99219 39.5742C6.92429 39.5742 6.05859 40.4399 6.05859 41.5078C6.05859 42.5757 6.92429 43.4414 7.99219 43.4414Z'
          fill='#FFF5F5'
        />
        <path
          d='M23.4609 51.1758C24.5288 51.1758 25.3945 50.3101 25.3945 49.2422C25.3945 48.1743 24.5288 47.3086 23.4609 47.3086C22.393 47.3086 21.5273 48.1743 21.5273 49.2422C21.5273 50.3101 22.393 51.1758 23.4609 51.1758Z'
          fill='#E2DFF4'
        />
        <path
          d='M7.99219 51.1758C9.06008 51.1758 9.92578 50.3101 9.92578 49.2422C9.92578 48.1743 9.06008 47.3086 7.99219 47.3086C6.92429 47.3086 6.05859 48.1743 6.05859 49.2422C6.05859 50.3101 6.92429 51.1758 7.99219 51.1758Z'
          fill='#FFF5F5'
        />
        <path
          d='M23.4609 58.2656C24.5288 58.2656 25.3945 57.3999 25.3945 56.332C25.3945 55.2641 24.5288 54.3984 23.4609 54.3984C22.393 54.3984 21.5273 55.2641 21.5273 56.332C21.5273 57.3999 22.393 58.2656 23.4609 58.2656Z'
          fill='#E2DFF4'
        />
        <path
          d='M7.99219 58.2656C9.06008 58.2656 9.92578 57.3999 9.92578 56.332C9.92578 55.2641 9.06008 54.3984 7.99219 54.3984C6.92429 54.3984 6.05859 55.2641 6.05859 56.332C6.05859 57.3999 6.92429 58.2656 7.99219 58.2656Z'
          fill='#FFF5F5'
        />
        <path
          d='M4.125 35.0625H27.3281C27.6839 35.0625 27.9727 34.7737 27.9727 34.418V27.9727C27.9727 27.6169 27.6839 27.3281 27.3281 27.3281H4.125C3.76922 27.3281 3.48047 27.6169 3.48047 27.9727V34.418C3.48047 34.7737 3.76922 35.0625 4.125 35.0625Z'
          fill='#8CBCFF'
        />
        <path
          d='M27.3281 27.3281H15.7266V35.0625H27.3281C27.6839 35.0625 27.9727 34.7737 27.9727 34.418V27.9727C27.9727 27.6169 27.6839 27.3281 27.3281 27.3281Z'
          fill='#4895FF'
        />
        <path
          d='M15.7266 43.4414C16.7945 43.4414 17.6602 42.5757 17.6602 41.5078C17.6602 40.4399 16.7945 39.5742 15.7266 39.5742C14.6587 39.5742 13.793 40.4399 13.793 41.5078C13.793 42.5757 14.6587 43.4414 15.7266 43.4414Z'
          fill='#FFF5F5'
        />
        <path
          d='M15.7266 39.5742V43.4414C16.7939 43.4414 17.6602 42.5752 17.6602 41.5078C17.6602 40.4405 16.7939 39.5742 15.7266 39.5742Z'
          fill='#E2DFF4'
        />
        <path
          d='M15.7266 51.1758C16.7945 51.1758 17.6602 50.3101 17.6602 49.2422C17.6602 48.1743 16.7945 47.3086 15.7266 47.3086C14.6587 47.3086 13.793 48.1743 13.793 49.2422C13.793 50.3101 14.6587 51.1758 15.7266 51.1758Z'
          fill='#FFF5F5'
        />
        <path
          d='M15.7266 47.3086V51.1758C16.7939 51.1758 17.6602 50.3095 17.6602 49.2422C17.6602 48.1748 16.7939 47.3086 15.7266 47.3086Z'
          fill='#E2DFF4'
        />
        <path
          d='M15.7266 58.2656C16.7945 58.2656 17.6602 57.3999 17.6602 56.332C17.6602 55.2641 16.7945 54.3984 15.7266 54.3984C14.6587 54.3984 13.793 55.2641 13.793 56.332C13.793 57.3999 14.6587 58.2656 15.7266 58.2656Z'
          fill='#FFF5F5'
        />
        <path
          d='M15.7266 54.3984V58.2656C16.7939 58.2656 17.6602 57.3994 17.6602 56.332C17.6602 55.2647 16.7939 54.3984 15.7266 54.3984Z'
          fill='#E2DFF4'
        />
        <path
          d='M53.0635 37.0663C50.8319 37.0663 49 38.8907 49 41.1223C49 43.354 50.8319 45.1858 53.0635 45.1858C54.1974 45.1858 55.0953 46.0837 55.0953 47.2176C55.0953 48.3514 54.1974 49.2493 53.0635 49.2493C51.9296 49.2493 51.0318 48.3514 51.0318 47.2176C51.0318 47.0841 51.0055 46.952 50.9545 46.8288C50.9035 46.7055 50.8287 46.5935 50.7343 46.4991C50.64 46.4048 50.528 46.3298 50.4047 46.2788C50.2814 46.2277 50.1493 46.2015 50.0159 46.2015C49.8824 46.2015 49.7503 46.2277 49.6271 46.2788C49.5038 46.3298 49.3918 46.4048 49.2974 46.4991C49.2031 46.5935 49.1283 46.7055 49.0772 46.8288C49.0262 46.952 49 47.0841 49 47.2176C49 49.4492 50.8319 51.2811 53.0635 51.2811C55.2952 51.2811 57.127 49.4492 57.127 47.2176C57.127 44.9859 55.2952 43.1541 53.0635 43.1541C51.9296 43.1541 51.0318 42.2562 51.0318 41.1223C51.0318 39.9884 51.9296 39.0906 53.0635 39.0906C54.1974 39.0906 55.0953 39.9884 55.0953 41.1223C55.0952 41.2557 55.1215 41.3879 55.1725 41.5112C55.2235 41.6345 55.2984 41.7465 55.3927 41.8409C55.487 41.9352 55.599 42.01 55.7223 42.0611C55.8456 42.1122 55.9777 42.1385 56.1111 42.1385C56.2446 42.1385 56.3767 42.1122 56.5 42.0611C56.6232 42.01 56.7352 41.9352 56.8296 41.8409C56.9239 41.7465 56.9987 41.6345 57.0498 41.5112C57.1008 41.3879 57.127 41.2557 57.127 41.1223C57.127 38.8907 55.2952 37.0663 53.0635 37.0663Z'
          fill='#E07408'
        />
        <path
          d='M52.0439 38.0598V35.0159C52.0439 33.6614 54.0757 33.6614 54.0757 35.0159V38.0598H52.0439Z'
          fill='#E07408'
        />
        <path
          d='M52.0439 50.269V53.3128C52.0439 54.6673 54.0757 54.6673 54.0757 53.3128V50.269H52.0439Z'
          fill='#E07408'
        />
      </g>
      <defs>
        <clipPath id='clip0_5575_68319'>
          <rect width='66' height='66' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
