import * as React from 'react';

export interface IMultiEmailProps {
  emails?: string[];
  id?: string;
  onChange?: (emails: string[]) => void;
  value?: string | string[];
  noClass?: boolean;
  validateEmail?: (email: string) => boolean;
  getLabel: (
    email: string,
    index: number,
    removeEmail: (index: number, isDisabled: boolean) => void,
  ) => void;
  className?: string;
  placeholder?: string | React.ReactNode;
}

export interface IReactMultiEmailState {
  focused?: boolean;
  propsEmails?: string[];
  emails: string[];
  inputValue?: string;
}

const isEmailFn = (email: string) => {
  return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
};

function MultiEmailChip(props: IMultiEmailProps) {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [emails, setEmails] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');

  const emailInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const isInList = (email: string) => {
    return emails.includes(email);
  };

  React.useEffect(() => {
    if (props.emails) {
      setEmails(props.emails);
    }
  }, [props]);

  const findEmailAddress = (value: string, isEnter?: boolean) => {
    const { validateEmail } = props;
    const validEmails: string[] = [];
    let emailValue = '';
    const isEmail = validateEmail || isEmailFn;

    const addEmails = (email: string) => {
      if (isInList(email)) {
        return false;
      }
      validEmails.push(email);
      return true;
    };
    const trimmedValue = value.trim();
    if (trimmedValue && isEnter) {
      if (isEmail(value)) {
        addEmails(value);
      } else {
        emailValue = value;
      }
    } else {
      emailValue = value;
    }
    const updatedEmails = emails.concat(validEmails);
    setEmails(updatedEmails);
    setInputValue(emailValue);

    if (validEmails.length && props.onChange) {
      props.onChange(updatedEmails);
    }
  };

  const onChangeInputValue = (value: string) => {
    findEmailAddress(value);
  };

  const removeEmail = (index: number, isDisabled: boolean) => {
    if (isDisabled) {
      return;
    }
    if (index !== -1) emails?.splice(index, 1);

    if (props.onChange) {
      props.onChange(emails);
    }
  };

  const handleOnKeydown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ',', ';', ' '].includes(evt.key)) {
      evt.preventDefault();
    }
    if (evt.key === 'Backspace' && !evt.currentTarget.value) {
      removeEmail(emails.length - 1, false);
    }
  };

  const handleOnKeyup = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ',', ';', ' '].includes(evt.key)) {
      findEmailAddress(evt.currentTarget.value, true);
    }
  };

  const handleOnChange = (evt: React.SyntheticEvent<HTMLInputElement>) =>
    onChangeInputValue(evt.currentTarget.value);

  const handleOnBlur = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    setFocused(false);
    findEmailAddress(evt.currentTarget.value, true);
  };

  const handleOnFocus = () => setFocused(true);

  const { getLabel, className = '', placeholder } = props;

  return (
    <div
      className={`${className} ${focused ? 'focused' : ''} ${
        inputValue === '' && emails.length === 0 ? 'empty' : ''
      }`}
      onClick={() => {
        if (emailInputRef.current) {
          emailInputRef.current.focus();
        }
      }}
      aria-hidden='true'
    >
      {placeholder ? <span data-placeholder>{placeholder}</span> : null}
      {emails.map((email: string, index: number) => getLabel(email, index, removeEmail))}
      <input
        ref={emailInputRef}
        type='text'
        value={inputValue}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeydown}
        onKeyUp={handleOnKeyup}
      />
    </div>
  );
}

export default MultiEmailChip;
