export default function Blockicon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.2'
        d='M8 14.667A6.669 6.669 0 0014.667 8 6.669 6.669 0 008 1.333 6.67 6.67 0 001.333 8 6.669 6.669 0 008 14.667zM12.6 3.333l-9.333 9.334'
      />
    </svg>
  );
}
