import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  REMOVE_CARD,
  MAKE_CARD_PRIMARY,
  ADD_CARD,
  RECHARGE_CREDIT,
  CREATE_PAYMENT_INTENT,
} from 'graphql/payments';
import { ToastMessage } from 'components/atoms';
import apolloCache from 'services/apollo/cache';

export function useChargebeeMutation(props?: any) {
  const { t } = useTranslation();

  const [remove, { loading: removing }] = useMutation(REMOVE_CARD, {
    onCompleted: response => {
      const { error } = response.removeCard;
      if (error !== null) {
        ToastMessage({
          content: error.message,
          type: 'danger',
        });
        return;
      }
      ToastMessage({
        content: t('card.removedSuccess', 'Card has been removed.'),
        type: 'success',
      });
      props?.callback?.();
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
  });
  const removeCard = useCallback(
    async (cardId: string) => {
      await remove({
        variables: {
          data: { paymentMethodId: cardId },
        },
        update(cache, { data }) {
          const { id } = data?.removeCard?.data;
          if (id) {
            cache.evict({ fieldName: 'cards' });
          }
        },
      });
    },
    [remove],
  );

  const [makeDefault] = useMutation(MAKE_CARD_PRIMARY, {
    onCompleted: response => {
      const { error } = response.makePrimaryCard;
      if (error !== null) {
        ToastMessage({
          content: error.message,
          type: 'danger',
        });
        return;
      }
      ToastMessage({
        content: t('card.makePrimarySuccess', 'Primary card updated.'),
        type: 'success',
      });
      apolloCache.modify({
        fields: {
          cards: () => {},
          planOverview: () => {},
        },
      });
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
  });
  const makePrimary = useCallback(
    async (cardId: string) => {
      await makeDefault({
        variables: {
          data: { paymentMethodId: cardId },
        },
      });
    },
    [makeDefault],
  );

  const [addCard, { loading: addingCard }] = useMutation(ADD_CARD, {
    onCompleted: response => {
      const { error } = response.addCard;
      if (error !== null) {
        ToastMessage({
          content: error.message,
          type: 'danger',
        });
        return;
      }
      props?.callback?.();
      ToastMessage({
        content: 'Card added.',
        type: 'success',
      });
      apolloCache.modify({
        fields: {
          cards: () => {},
          planOverview: () => {},
        },
      });
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
      props?.onError();
    },
  });

  const addNewCard = async (payload: any) => {
    const { gatewayAccountId, gwToken, paymentIntentId } = payload;
    await addCard({
      variables: {
        data: {
          gateway_account_id: gatewayAccountId,
          gw_token: gwToken,
          payment_intent_id: paymentIntentId,
        },
      },
    });
  };

  const [recharge, { loading: recharging }] = useMutation(RECHARGE_CREDIT, {
    onCompleted: response => {
      const { error, data } = response.purchaseCredit;
      if (error !== null) {
        ToastMessage({
          content: error.message,
          type: 'danger',
        });
        return;
      }
      ToastMessage({
        content: `Your workspace has been credited $${data?.amount}.`,
        type: 'success',
      });
      apolloCache.modify({
        fields: {
          planOverview: () => {},
        },
      });
    },
    onError: () => {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
  });
  const rechargeCredit = async (payload: any) => {
    await recharge({
      variables: {
        data: payload,
      },
    });
  };

  const [createPaymentIntent, { loading: paymentIntentLoading }] = useMutation(
    CREATE_PAYMENT_INTENT,
    {
      onCompleted: response => {
        const { error } = response.createPaymentIntent;
        if (error !== null) {
          ToastMessage({
            content: error.message,
            type: 'danger',
          });
        }
      },
      onError: () => {
        ToastMessage({
          content: t('error.unspecific', 'Something went wrong.'),
          type: 'danger',
        });
      },
    },
  );

  return {
    removeCard,
    makePrimary,
    removing,
    addNewCard,
    addingCard,
    recharging,
    rechargeCredit,
    createPaymentIntent,
    paymentIntentLoading,
  };
}
