import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';

export const DropdownWrapper = styled(Dropdown)`
  .ant-dropdown {
    top: 242px !important;
    left: 0px !important;
  }
`;

export const OptionListWrapper = styled.div`
  width: 320px;
  line-height: 0;
  border: 1px solid #e7e6eb;
  box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
  border-radius: 12px;
  background: #fff;

  .search-field {
    &,
    & * {
      background: transparent;
      border: none;
      border-bottom: none !important;
    }
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1} !important;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    width: 100%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #9e99ab;
    padding: 0;
    .ant-input {
      font-size: ${({ theme }) => theme.text.size.body.text.small};
      line-height: 14px;
      &::placeholder {
        color: #9e99ab;
      }
    }
    .ant-input-prefix {
      margin-right: 12px;
      > svg {
        font-size: 14px;
      }
    }
  }
  .scrollbar-container {
    width: 100% !important;
  }
  .no-data {
    padding: 8px 24px 14px;
  }
`;

export const MenuWrapper = styled(Menu)`
  border: none !important;
  overflow: auto;
  height: 195px;
  width: 100%;
  box-shadow: none !important;
  padding: 0px !important;
  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 11px 16px !important;
    &.ant-menu-item-selected {
      background: #f5f2f8 !important;
    }
    &:not(:last-child),
    & {
      margin: 0;
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
`;

export const MenuItemWrapper = styled.div`
  img {
    width: 16px;
    height: 11px;
    border-radius: 2px;
  }
  img[alt='NP'] {
    object-fit: contain;
  }
`;
