export default function Contactbook({ className }: { className?: string }) {
  return (
    <svg
      width='200'
      height='200'
      className={className}
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='100' cy='100' r='100' fill='#F3F7FE' />
      <g clipPath='url(#clip0_14927_156281)'>
        <path
          d='M122.604 60C119.563 60 73.3143 60 70.3702 60C68.3119 60 66.6435 61.6684 66.6435 63.7267V132.65L126.33 125.3V63.7267C126.33 61.6684 124.662 60 122.604 60Z'
          fill='#4882F6'
        />
        <path
          d='M122.604 60C122.109 60 120.47 60 118.061 60C120.119 60 121.788 61.6684 121.788 63.7267V125.3L123.906 126.665L126.33 125.3V63.7267C126.33 61.6684 124.662 60 122.604 60Z'
          fill='#1A63F4'
        />
        <path
          d='M66.6435 130.06C66.6435 127.431 72.9841 132.65 75.6129 132.65L78.7877 125.3V60H70.3702C68.3119 60 66.6435 61.6684 66.6435 63.7267V130.06Z'
          fill='#26457D'
        />
        <path
          d='M133.357 81.2612H126.33V68.9609H131.445C132.501 68.9609 133.357 69.8167 133.357 70.8722V81.2612Z'
          fill='#FFE07D'
        />
        <path d='M126.33 68.9609H129.844V81.2612H126.33V68.9609Z' fill='#FFD064' />
        <path d='M126.33 81.2607H133.357V93.5611H126.33V81.2607Z' fill='#95D6A4' />
        <path d='M126.33 81.2607H129.844V93.5611H126.33V81.2607Z' fill='#78C2A4' />
        <path d='M126.33 93.5615H133.357V105.862H126.33V93.5615Z' fill='#E7A561' />
        <path d='M126.33 93.5615H129.844V105.862H126.33V93.5615Z' fill='#E49542' />
        <path
          d='M131.445 118.162H126.33V105.861H133.357V116.25C133.357 117.306 132.501 118.162 131.445 118.162Z'
          fill='#DF73C1'
        />
        <path d='M126.33 105.861H129.844V118.162H126.33V105.861Z' fill='#DD4FB1' />
        <path
          d='M101.145 93.1877L92.5381 84.5806C92.1532 84.1958 91.5293 84.1958 91.1445 84.5806L89.2092 86.5159C86.3193 89.4058 86.3193 94.0911 89.2092 96.9809L105.191 112.963C108.081 115.853 112.766 115.853 115.656 112.963L117.592 111.028C117.976 110.643 117.976 110.019 117.592 109.634L108.984 101.027C108.6 100.642 107.976 100.642 107.591 101.027L105.856 102.762C105.027 103.591 103.683 103.591 102.854 102.762L99.4104 99.3177C98.5815 98.4888 98.5815 97.1448 99.4104 96.3161L101.145 94.5814C101.53 94.1964 101.53 93.5725 101.145 93.1877Z'
          fill='#F9F6F6'
        />
        <path
          d='M109.268 68.4417H95.6538C94.9869 68.4417 94.4466 67.9013 94.4466 67.2345C94.4466 66.5678 94.987 66.0273 95.6538 66.0273H109.268C109.935 66.0273 110.475 66.5678 110.475 67.2345C110.475 67.9013 109.935 68.4417 109.268 68.4417Z'
          fill='#21386B'
        />
        <path
          d='M114.902 75.8988H90.02C89.3531 75.8988 88.8128 75.3583 88.8128 74.6916C88.8128 74.0248 89.3532 73.4844 90.02 73.4844H114.902C115.569 73.4844 116.109 74.0248 116.109 74.6916C116.109 75.3583 115.569 75.8988 114.902 75.8988Z'
          fill='#21386B'
        />
        <path
          d='M126.33 125.301H71.4032C68.7744 125.301 66.6435 127.432 66.6435 130.06V135.241C66.6435 137.87 68.7744 140 71.4032 140H122.603C124.662 140 126.33 138.332 126.33 136.274V125.301H126.33Z'
          fill='#F9F6F6'
        />
        <path
          d='M121.796 125.301V136.274C121.796 138.332 120.128 140 118.07 140H122.604C124.662 140 126.33 138.332 126.33 136.274V125.301H121.796Z'
          fill='#DDDAEC'
        />
        <path
          d='M119.04 131.532H73.9342C73.2674 131.532 72.7271 130.991 72.7271 130.324C72.7271 129.658 73.2675 129.117 73.9342 129.117H119.04C119.706 129.117 120.247 129.658 120.247 130.324C120.247 130.991 119.706 131.532 119.04 131.532Z'
          fill='#DDDAEC'
        />
        <path
          d='M119.04 136.183H73.9342C73.2674 136.183 72.7271 135.642 72.7271 134.976C72.7271 134.309 73.2675 133.769 73.9342 133.769H119.04C119.706 133.769 120.247 134.309 120.247 134.976C120.247 135.642 119.706 136.183 119.04 136.183Z'
          fill='#DDDAEC'
        />
      </g>
      <defs>
        <clipPath id='clip0_14927_156281'>
          <rect width='80' height='80' fill='white' transform='translate(60 60)' />
        </clipPath>
      </defs>
    </svg>
  );
}
