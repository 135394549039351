import styled from 'styled-components';

export const FormContainer = styled.form`
  .ant-input-affix-wrapper {
    background: none;
    border: none;
    padding: 0 10px;
    border-radius: 10px;
    height: 38px !important;
    > .ant-input-prefix {
      margin-right: 12px;
      > svg {
        color: #4d4950;
        width: 22px;
        height: 22px;
      }
      + input {
        font-weight: 420;
        padding: 0;
        color: var(--Text-500-Component, #333036);
        ::placeholder {
          color: var(--Text-300-Component-2, #66616b);
        }
        :disabled {
          background: #faf9fb;
          color: var(--Text-200-Disabled, #99949e);
        }
      }
    }
    &-focused {
      box-shadow: 0 0 0 1px var(--Stroke-100-Default, #e5e4e7);
    }
    &-disabled {
      background: #faf9fb;
      > .ant-input-prefix > svg {
        color: var(--Text-200-Disabled, #99949e);
      }
    }
  }
`;

export const DisabledInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 10px;
  border-radius: 10px;
  height: 38px !important;
  background: #faf9fb;
  cursor: not-allowed;
  > svg {
    color: var(--Text-200-Disabled, #99949e);
    width: 22px;
    height: 22px;
  }
  > p {
    color: var(--Text-200-Disabled, #99949e);
    font-weight: 420;
  }
`;
