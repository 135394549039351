import { memo } from 'react';
import styled from 'styled-components';

export interface AuxProps {
  children: React.ReactNode;
}

const Wrapper = styled.main<Record<string, unknown>>`
  height: 100%;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = ({ children }: AuxProps) => {
  return <Wrapper>{children}</Wrapper>;
};

export default memo(Container);
