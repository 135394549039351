// TODO: NEED TO FETCH CONTACTS SUGGESTIONS FROM BOTH CONTACTS TABLE AND CALL LOGS
import { produce } from 'immer';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { GET_CONTACTS } from 'graphql/client';
import { LIMIT, queryParamVars, useQVars } from './useContactsQueryParams';

export function useContactLogsSearch() {
  const { setSearch } = useQVars(queryParamVars);
  const qParams = useReactiveVar<any>(queryParamVars);

  const [loadSearchedContacts, { data: query, loading, fetchMore }] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const searchContacts = async (value: string) => {
    setSearch({
      columns: ['number', 'name'],
      value,
    });
    await loadSearchedContacts({
      variables: {
        params: {
          first: LIMIT,
          search: {
            columns: ['number', 'name'],
            value,
          },
        },
      },
    });
  };

  const { edges: contacts = [], pageInfo } = query?.getContacts?.data || {};
  const endCursor = pageInfo?.endCursor;
  const hasNextPage = pageInfo?.hasNextPage;

  const onLoadMore = async () => {
    if (fetchMore && endCursor && !loading && hasNextPage) {
      await fetchMore({
        variables: {
          params: {
            ...qParams,
            after: endCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          const contactsData = fetchMoreResult.getContacts.data;
          return produce(prev, (draft: Pick<any, 'getContacts'>) => {
            draft.getContacts.data = {
              pageInfo: contactsData.pageInfo,
              edges: draft?.getContacts?.data?.edges?.concat(contactsData.edges),
              // eslint-disable-next-line no-underscore-dangle
              __typename: draft?.getContacts?.data?.__typename,
            };
          });
        },
      });
    }
  };
  const contactSuggestion = contacts?.map((data: any) => {
    const { id, number, name, profilePicture } = data?.node || {};
    return {
      id,
      name,
      number,
      profilePicture,
    };
  });

  return {
    loading,
    searchContacts,
    contacts,
    contactSuggestion,
    onLoadMore,
  };
}
