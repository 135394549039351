import { useTranslation } from 'react-i18next';

import { ContactNode } from 'generated/graphql';
import { ActionsModal } from 'components/organisms';
import { Button, Icon } from 'components/atoms';
import { useContactDelete } from '../useContactDelete';

interface ContactProps {
  contact: ContactNode | undefined | null;
  closeDropdown?: any;
}
export default function DeleteContact({ contact, closeDropdown }: ContactProps) {
  const { t } = useTranslation();
  const {
    onContactDelete,
    toggleDeleteConfirm,
    visibleDeleteConfirm,
    deleting,
  } = useContactDelete();

  const handleDeleteContact = async () => {
    await onContactDelete([contact?.id]);
  };

  const handleDelete = () => {
    toggleDeleteConfirm();
    closeDropdown();
  };

  return (
    <>
      <Button
        type='link'
        className='btn--normal'
        icon={<Icon name='delete-bin' />}
        onClick={handleDelete}
      >
        {t('common.action.deleteContact', 'Delete Contact')}
      </Button>
      <ActionsModal
        onOk={handleDeleteContact}
        isModalOpen={visibleDeleteConfirm}
        onCancel={toggleDeleteConfirm}
        onModalCancel={toggleDeleteConfirm}
        title={t('deleteContact', 'Delete Contact')}
        info={t('deleteContactConfirmation', 'Are you sure you want to delete this contact?')}
        btnText={t('action.common.delete', 'Delete')}
        action='delete'
        loading={deleting}
        icon={<Icon name='delete-contact' />}
        btnIcon={<Icon name='delete-bin-2' />}
      />
    </>
  );
}
