function DragIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='CurrentColor'
        d='M6.667 5a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zM6.667 11.667a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zM6.667 18.333a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zM13.333 5a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zM13.333 11.667a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zM13.333 18.333a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z'
      />
    </svg>
  );
}

export default DragIcon;
