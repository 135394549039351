import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { TEAM_NODE } from '../fragments';

export const GET_TEAMS = gql`
  query teams {
    teams {
      status
      error {
        ...Error
      }
      data {
        ...TeamFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TEAM_NODE}
`;

export const GET_TEAM = gql`
  query team($id: String!) {
    team(id: $id) {
      status
      error {
        ...Error
      }
      data {
        ...TeamFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TEAM_NODE}
`;
