import styled from 'styled-components';
import { Tooltip } from 'components/atoms';
import { Disabled } from './Styles';

const ChildrenWrapper = styled.div`
  &.btn-wrapper {
    width: fit-content;
  }
`;

export const DisabledTooltip = ({
  previlegedUserType = 'admin',
  wrapperClass,
  isChildElementButton = false, // to make tooltip visible in button
  tooltipPlacement = 'right',
  tooltipTitle,
  children,
}: any) => {
  const capitalizeTitle = (title: string) => title[0].toUpperCase() + title.slice(1);

  return (
    <Disabled className={wrapperClass}>
      <Tooltip
        title={tooltipTitle ?? `${capitalizeTitle(previlegedUserType)} only.`}
        placement={tooltipPlacement}
      >
        <ChildrenWrapper className={isChildElementButton && 'btn-wrapper'}>
          {children}
        </ChildrenWrapper>
      </Tooltip>
    </Disabled>
  );
};
