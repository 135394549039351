import { CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper';
import { useTranslation } from 'react-i18next';
import { ChangeEventHandler } from 'react';

import * as S from './Styles';

interface ICardSection {
  className?: string;
  onCardValueChanges?: ChangeEventHandler<Element> | undefined;
}

export default function CardSection({ className, onCardValueChanges }: ICardSection) {
  const { t } = useTranslation();

  return (
    <S.ElementsWrapper className={className} data-cy='chargbee-iframe'>
      <p className='text-sm leading-3.5 font-medium text-gray-700 mb-1.5'>
        {t('cardNumber', 'Card number')}
      </p>
      <CardNumber
        placeholder='4111 1111 1111 1111'
        className='card_input'
        onChange={onCardValueChanges ?? undefined}
      />
      <div className='grid grid-cols-2 gap-5'>
        <div>
          <p className='text-sm leading-3.5 font-medium text-gray-700 mb-1.5'>
            {t('expirationDate', ' Expiration date')}
          </p>
          <CardExpiry
            placeholder='MM / YY'
            className='card_input'
            onChange={onCardValueChanges ?? undefined}
          />
        </div>
        <div>
          <p className='text-sm leading-3.5 font-medium text-gray-700 mb-1.5'>CVC</p>
          <CardCVV
            placeholder='CVV'
            className='card_input'
            onChange={onCardValueChanges ?? undefined}
          />
        </div>
      </div>
    </S.ElementsWrapper>
  );
}
