import { useContext } from 'react';

import useScreenType from 'hooks/useScreenType';
import { TagSelect } from 'components/molecules/tag-select';

import * as S from './Styles';
import { ContactDetailContext } from '../ContactDetailContext';

const TagsDetailForm = () => {
  const { isBigScreen } = useScreenType();

  const {
    handleTagChange,
    handleTagCreate,
    handleOnTagBlur,
    handleColorPicking,
    isTagFieldLoading,
    tagSelectRef,
    tagFieldOptions,
    showTagColorOptions,
    tagFieldValue,
  } = useContext(ContactDetailContext);

  return (
    <S.DetailsContainer detailsLabelWidth={isBigScreen ? 95 : 85}>
      <form className='details-form' data-cy='tags-details-form'>
        <div data-cy='tag-d-select' className=''>
          <TagSelect
            isTagFieldLoading={isTagFieldLoading}
            handleTagChange={handleTagChange}
            handleBlur={handleOnTagBlur}
            handleTagCreate={handleTagCreate}
            tagFieldOptions={tagFieldOptions}
            tagFieldValue={tagFieldValue}
            showTagColorOptions={showTagColorOptions}
            handleColorPicking={handleColorPicking}
            ref={tagSelectRef}
          />
        </div>
      </form>
    </S.DetailsContainer>
  );
};

export default TagsDetailForm;
