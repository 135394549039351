export const getDNDvalue = (duration: number, isEnabled: boolean) => {
  if ([null, 0].includes(duration) && isEnabled) {
    return 'UNTIL_I_RESUME';
  }
  const durations: any = {
    30: 'THIRTY_MINUTES',
    60: 'ONE_HOUR',
    480: 'EIGHT_HOURS',
    720: 'TWELVE_HOURS',
  };

  return durations[duration] ?? '';
};
