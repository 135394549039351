import { Menu } from 'antd';
import format from 'date-fns/format';
import { RiQuestionLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

import Bell from 'assets/icons/silent-bell.svg';

import { Tooltip } from 'components/atoms';
import { getDNDvalue } from 'components/utils/getDndValue';

const { SubMenu } = Menu;

export function useClientDNDMenu({ dndDuration, dndEnabled, dndEndtime }: any) {
  const { t } = useTranslation();
  const DND_OPTIONS = [
    { label: t('dnd.30min', 'For 30 minutes'), value: 'THIRTY_MINUTES' },
    { label: t('dnd.1hr', 'For 1 hour'), value: 'ONE_HOUR' },
    { label: t('dnd.8hr', 'For 8 hours'), value: 'EIGHT_HOURS' },
    { label: t('dnd.12h3', 'For 12 hours'), value: 'TWELVE_HOURS' },
    { label: t('dnd.untilTurnedBackOn', 'Until I turn it back on'), value: 'UNTIL_I_RESUME' },
  ];
  const DND_VALUES = [...DND_OPTIONS?.map(option => option.value), 'OFF'];

  const subMenuItems = (
    <>
      <h6 className='flex items-center text-13 font-semibold leading-4 text-gray-500 justify-between px-4 py-2.25 min-w-230'>
        {t('pauseNotification.title', 'Pause notifications for...')}
        <Tooltip
          title={t(
            'pauseNotification.helpText',
            'This allows you to stop receiving calls and messages.',
          )}
          placement='top'
        >
          <RiQuestionLine className='text-gray-500 h-3.25 w-3.25' />
        </Tooltip>
      </h6>
      {DND_OPTIONS.map(option => (
        <Menu.Item
          key={option.value}
          className={
            option.value === getDNDvalue(dndDuration as number, dndEnabled as boolean)
              ? 'active-dnd'
              : ''
          }
        >
          {option.label}
        </Menu.Item>
      ))}
    </>
  );
  const subMenuItemsOnDND = (
    <>
      <div className='bg-primary-600 p-3.5 flex items-center justify-between mb-2.5 -mt-2.5 rounded-t-10'>
        <div className='mr-4'>
          <h6 className='text-15 leading-5 text-white font-bold mb-1'>{t('Muted', 'Muted')}</h6>
          <p className='text-13 leading-4 text-primary-100'>
            Notifications paused until{' '}
            {dndEndtime ? format(new Date(dndEndtime * 1000), 'h:mm a') : 'I turn it on'}
          </p>
        </div>
        <div className='h-9 w-9'>
          <img src={Bell} alt='bell' height={36} width={36} />
        </div>
      </div>
      <Menu.Item className='resume' key='OFF'>
        {t('resumeNotifications', 'Resume Notifications')}
      </Menu.Item>
      <SubMenu title='Adjust time'>{subMenuItems}</SubMenu>
    </>
  );

  const menu = dndEnabled ? subMenuItemsOnDND : subMenuItems;

  return { menu, DND_VALUES };
}
