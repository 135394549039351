import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RiCloseLine } from 'react-icons/ri';
import iEmpty from 'lodash.isempty';

import { useTwilioContext } from 'lib/twilio';
import { isValidPhoneNumber } from 'components/utils/phone-lib';
import { Button } from 'components/atoms';
import { PhoneInput } from 'components/molecules/fields';

import * as S from './Styles';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

const schema = yup.object().shape({
  number: yup.string().required('Required'),
});

interface ExternalNumberProps {
  toggleExternalNumber: () => void;
  handleClose: () => void;
}

const ExternalNumber = ({ toggleExternalNumber, handleClose }: ExternalNumberProps) => {
  const { t } = useTranslation();
  const { handleCallTransfer, loadingCallTransfer } = useCallWidgetContext();
  const {
    state: { connection, direction },
  } = useTwilioContext();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleFormSubmit = (number: string) => {
    const payload: any = {
      destination: number,
      callerId:
        direction === 'Incoming'
          ? connection?.parameters?.From
          : connection?.customParameters?.get('To'),
      direction,
      externalNumber: true,
    };
    handleCallTransfer?.(payload, number);
    handleClose();
  };

  const formSubmit = async (val: any) => {
    const isValid = isValidPhoneNumber(`+${val.number}`);
    if (!isValid) {
      if (setError) {
        setError('number', {
          type: 'focus',
          message: t('toast.invalidNumber', 'Invalid Phone Number'),
        });
      }
      return;
    }
    handleFormSubmit(`+${val.number}`);
  };

  const handleCloseBtnClick = () => {
    handleClose();
  };

  return (
    <>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-left pl-20 '>
          External Number
        </p>
        <button
          type='button'
          className='text-gray font-manrope text-xs leading-3 font-semibold'
          onClick={toggleExternalNumber}
        >
          <span>Go Back</span>
        </button>
      </div>
      <div className='px-4 mt-6 '>
        <S.ExternalNumberForm onSubmit={handleSubmit(formSubmit)} className=''>
          <div className='text-left'>
            <PhoneInput
              name='number'
              id='number'
              control={control}
              errors={errors}
              containerClass='add-new-contact-phone '
              label='Phone Number'
              disableDropdown={true}
            />
          </div>

          <div className='mt-5'>
            <Button
              key='submit'
              className='btn w-full'
              type='primary'
              loading={loadingCallTransfer}
              disabled={loadingCallTransfer || !iEmpty(errors)}
              htmlType='submit'
            >
              {t('transferCall', 'Transfer Call')}
            </Button>
          </div>
        </S.ExternalNumberForm>
      </div>
    </>
  );
};

export default ExternalNumber;
