import { ElementsProvider } from 'lib/chargebee';
import AccountSuspendNotification from './AccountSuspendNotification';

export default function AlertBar() {
  return (
    <ElementsProvider>
      <AccountSuspendNotification />
    </ElementsProvider>
  );
}
