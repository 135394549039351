import React from 'react';
import styled from 'styled-components';
import { Slider as AntSlider } from 'antd';

const Slider = styled(AntSlider)`
  & * {
    transition: all 0.15s ease;
  }
  .ant-slider {
    &-rail {
      background-color: ${({ theme }) => theme.colors.primary.p1};
      border-radius: 10px;
      height: 10px;
    }
    &-track {
      background-color: ${({ theme }) => theme.colors.primary.p3};
      border-radius: 10px;
      height: 10px;
    }
    &-handle {
      width: 24px;
      height: 24px;
      top: 1px;
      background-color: ${({ theme }) => theme.colors.primary.default};
      box-shadow: -5px 5px 13px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
  }
  &:hover {
    .ant-slider-rail {
      background-color: ${({ theme }) => theme.colors.primary.p1};
    }
    .ant-slider-track {
      background-color: ${({ theme }) => theme.colors.primary.p3};
      .ant-slider-handle:not(.ant-slider-tooltip-open) {
        border-color: ${({ theme }) => theme.colors.primary.p3};
      }
    }
  }
`;
export default React.memo(Slider);
