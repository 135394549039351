import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';
import { CHAT_NODE } from './fragments';

export const CHAT_HISTORY = gql`
  query chatHistory($member: ShortId!, $params: ConnectionInput!) {
    chatHistory(member: $member, params: $params) {
      status
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            ...ChatFields
          }
        }
      }
      error {
        ...Error
      }
    }
  }
  ${PAGE_INFO}
  ${CHAT_NODE}
  ${ERROR_FRAGMENT}
`;
