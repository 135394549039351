import * as yup from 'yup';
import { inputs } from './common';

export const profileInputs = {
  firstName: inputs.firstName,
  lastName: inputs.lastName,
  email: inputs.email.required('Required'),
  confirmEmail: inputs.email
    .required('Required')
    .oneOf([yup.ref('email'), null], 'Email must match'),
  password: yup.string().required('Required'),
  confirmPassword: inputs.default
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  oldPassword: inputs.default.required('Required'),
  newPassword: inputs.password,
  newPasswordConfirm: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
};
