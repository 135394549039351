import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const ADD_CALL_SCRIPT = gql`
  mutation addCampaignCallScripts($data: CampaignCallScriptInputData!) @api(name: "salesDialer") {
    addCampaignCallScripts(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const EDIT_CALL_SCRIPT = gql`
  mutation updateCampaignCallScripts($data: UpdateCallScriptInputData!) @api(name: "salesDialer") {
    updateCampaignCallScripts(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
