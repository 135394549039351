const text = {
  font: {
    heading: '"Heebo", sans-serif',
    body: '"Manrope", sans-serif',
  },
  size: {
    heading: {
      h1: '4.5rem', // 72px
      h2: '2.5rem', // 48px
      h3: '2rem', // 40px
      h4: '2rem', // 32px
      h5: '1.5rem', // 24px
      h6: '1.25rem', // 20px
    },
    body: {
      text: {
        primary: '1.125rem', // 18px
        secondary: '1rem', // 16px
        small: '0.875rem', // 14px
        smaller: '0.8125rem', // 13px
        caption: '0.75rem', // 12px
        smallest: '0.625rem', // 10px
      },
    },
  },
  weight: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
};
export default text;
