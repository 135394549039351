import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { USER_PROFILE_FRAGMENT } from './fragments';

export const GET_USER_PROFILE = gql`
  query profile {
    profile {
      status
      error {
        ...Error
      }
      data {
        ...UserProfile
      }
    }
  }
  ${USER_PROFILE_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const CHECK_DUPLICATE_LOGIN = gql`
  query checkDuplicateLogin($data: LoginInputData!) {
    checkDuplicateLogin(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
