import { Button } from 'components/atoms';

import CreditIcon from 'components/atoms/icon/CreditIcon';

import { StyledModal } from './Styles';

interface CancelSubscriptionModalProps {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
  title: any;
  description: any;
  btnText: any;
}

const UpgradeModal = ({
  open,
  onOk,
  onClose,
  title,
  description,
  btnText,
}: CancelSubscriptionModalProps) => {
  return (
    <StyledModal
      width={348}
      centered
      visible={open}
      maskClosable={false}
      keyboard={false}
      zIndex={500}
      wrapClassName='blurred-wrapper'
      footer={[
        <Button
          variant='light-gray'
          key='back'
          type='link'
          size='large'
          className='btn min-w-120'
          onClick={onClose}
        >
          Not Now
        </Button>,
        <Button
          data-cy='login-modal-submit'
          key='submit'
          type='primary'
          className='capitalize btn min-w-164'
          size='large'
          onClick={onOk}
        >
          {btnText}
        </Button>,
      ]}
      closable={false}
    >
      <div className='bg-info-100 h-16 w-16 flex items-center justify-center rounded-20 mx-auto mb-6'>
        <CreditIcon />
      </div>
      <h5 className='text-center text-xl text-gray-700 leading-7 font-bold mb-2'>{title}</h5>
      <p className='text-15 leading-normal text-gray text-center'>{description}</p>
    </StyledModal>
  );
};

export default UpgradeModal;
