import styled from 'styled-components';

export const ChannelInfoWrapper = styled.div`
  .avatar-container {
    width: 36px;
    height: 36px;
    display: flex;
    padding: 11px 8px;
    border-radius: 50%;
    .avatar {
      width: 100%;
      height: 14px;
    }
  }
`;
