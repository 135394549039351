import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

const Alert = styled(AntAlert)`
  border: none;
  border-radius: 10px;
  padding: 16px;
  @media screen and (min-width: 1023px) {
    padding: 12px;
  }
  &.rounded-none {
    border-radius: 0 !important;
  }
  .ant-alert-message {
    line-height: 20px;
  }
  &.ant-alert-success {
    background: ${({ theme }) => theme.colors.success.s0};
    .ant-alert-message {
      color: ${({ theme }) => theme.colors.success.default};
    }
  }
  &.ant-alert-info {
    background: ${({ theme }) => theme.colors.info.i0};
    .ant-alert-message {
      color: ${({ theme }) => theme.colors.info.default};
    }
  }
  &.ant-alert-warning {
    background: ${({ theme }) => theme.colors.warning.w0};
    .ant-alert-message {
      color: ${({ theme }) => theme.colors.warning.default};
    }
  }
  &.ant-alert-error {
    background: ${({ theme }) => theme.colors.danger.d0};
    .ant-alert-message {
      color: ${({ theme }) => theme.colors.danger.default};
    }
  }
  svg {
    font-size: 20px;
    @media screen and (max-width: 767px) {
      min-width: 15px;
    }
  }
`;
export default Alert;
