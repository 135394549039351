import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import getUnixTime from 'date-fns/getUnixTime';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { Workspace } from 'generated/graphql';
import { useTwilioContext } from 'lib/twilio';
import { ToastMessage } from 'components/atoms';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  useWorkspaceSelector,
  useWorkspacesQuery,
  useReorderWorkspace,
} from 'components/organisms/workspace';
import { usePrivilege, privilegeList } from 'hooks/usePrivilege';
import * as path from 'constants/routes';
import useDND from './useDND';

export default function useWorkspaceList() {
  const history = useHistory();
  const [activeWorkspaceId] = useLocalStorage('activeWorkspaceId', null);
  const [isDeletedWorkspace, setIsDeletedWorkspace] = useState(false);
  const { selectWorkspace } = useWorkspaceSelector();
  const { onOrderChange } = useReorderWorkspace();

  const [workspaceModal, setWorkspaceModal] = useState(false);
  const [workspaceSwitchModal, setWorkspaceSwitchModal] = useState(false);
  const {
    // profileData,
    profileData: { userId, stayOnline, defaultWorkspace, dndEndtime },
    workspaceData,
  } = useWorkspacesQuery();
  const [openStayAwayModel, setOpenStayAwayModel] = useState(false);
  const [tempWorkspaceId, setTempWorkspaceId] = useState<string>('');
  const [tempMemberId, setTempMemberId] = useState<string>('');
  const {
    state: { callInProgress },
  } = useTwilioContext();

  const hideUserStatusModal = () => {
    setOpenStayAwayModel(false);
  };
  const { selectDND } = useDND({ callback: hideUserStatusModal });

  const addNewWks = () => {
    history.push(path.NEW_WORKSPACE);
    // FORCE REFRESH TO PREVENT USER FROM VIEWING THE PAGE IF BLOCKED IN CLOUDFARE
    if (process.env.REACT_APP_TESTING_ENV) history.go(0);
  };

  const toggleAddWorkspaceModal = () => setWorkspaceModal(prevState => !prevState);
  const toggleSwitchWorkspaceModal = () => setWorkspaceSwitchModal(prevState => !prevState);

  const handleWorkspaceSwitch = (workspace: Workspace) => () => {
    setIsDeletedWorkspace(workspace?.status === 'Inactive');
    // restrict switch on same workspace
    if (activeWorkspaceId === workspace.id) {
      return;
    }
    if (callInProgress) {
      toggleSwitchWorkspaceModal();
      setTempWorkspaceId(workspace.id as string);
      setTempMemberId(workspace.memberId as string);
      return;
    }
    selectWorkspace({ workspaceId: workspace.id, memberId: workspace.memberId as string });
  };
  const onSwitchAllowded = async () => {
    await selectWorkspace({ workspaceId: tempWorkspaceId, memberId: tempMemberId });
    toggleSwitchWorkspaceModal();
  };

  const [workspaceList, setWorkspaceList] = useState<any>([]);
  const onStayAway = () => {
    hideUserStatusModal();
  };

  const goOnline = () => {
    selectDND('OFF');
  };

  const handleDontShowPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      localStorage.setItem('hideStatusChangePrompt', JSON.stringify({ status: true }));
    } else {
      localStorage.setItem('hideStatusChangePrompt', JSON.stringify({ status: false }));
    }
  };
  useEffect(() => setWorkspaceList(workspaceData?.workspaces?.data), [workspaceData]);
  useEffect(() => {
    const currentTime = getUnixTime(new Date());
    if (dndEndtime && dndEndtime <= currentTime) {
      setOpenStayAwayModel(false);
      return;
    }
    if (userId && !stayOnline) {
      const hideStatusChangePrompt = JSON.parse(
        localStorage.getItem('hideStatusChangePrompt') ?? JSON.stringify({ status: false }),
      );

      if (hideStatusChangePrompt !== null) {
        if (!hideStatusChangePrompt.status === true) {
          setOpenStayAwayModel(true);
        }
      } else {
        setOpenStayAwayModel(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const checkAndSyncWorkspace = (defaultWorkspaceID: string) => {
    const activeWorkspace = workspaceList?.find(
      (workspace: any) => workspace?.id === defaultWorkspaceID && workspace?.status === 'Active',
    );
    const { id, memberId } = activeWorkspace || {};
    if (id && memberId) {
      ToastMessage({ content: 'Redirecting to your active workspace', type: 'warning' });
      selectWorkspace({ workspaceId: id, memberId });
    }
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', message => {
        if (message.data.type === 'NOTIFICATION_CLICK') {
          history.push(message.data.url);
        }
      });
    }
  }, [history]);

  const [hasAccess] = usePrivilege(
    'main_screen',
    'workspace',
    privilegeList.main_screen.workspace.create_new_workspace,
  );

  const handleDragEnd = async (result: any) => {
    await onOrderChange(result, workspaceList);
  };

  return {
    activeWorkspaceId,
    openStayAwayModel,
    isDeletedWorkspace,
    onOrderChange,
    workspaceModal,
    workspaceSwitchModal,
    toggleAddWorkspaceModal,
    toggleSwitchWorkspaceModal,
    handleWorkspaceSwitch,
    onSwitchAllowded,
    workspaceList,
    onStayAway,
    goOnline,
    defaultWorkspace,
    handleDontShowPrompt,
    hasAccess,
    handleDragEnd,
    addNewWks,
    checkAndSyncWorkspace,
  };
}
