export default function IntegrationIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_25333_199825)'>
        <path
          d='M12.72 4.62753C14.22 5.67003 15.255 7.32753 15.465 9.24'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.61743 9.2775C2.81243 7.37248 3.83243 5.71498 5.31743 4.66498'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.14258 15.7049C7.01258 16.1474 8.00258 16.3949 9.04508 16.3949C10.0501 16.3949 10.9951 16.1699 11.8426 15.7574'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.04494 5.77501C10.1965 5.77501 11.1299 4.84153 11.1299 3.69001C11.1299 2.5385 10.1965 1.60501 9.04494 1.60501C7.89346 1.60501 6.95996 2.5385 6.95996 3.69001C6.95996 4.84153 7.89346 5.77501 9.04494 5.77501Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.6226 14.94C4.77411 14.94 5.7076 14.0065 5.7076 12.855C5.7076 11.7035 4.77411 10.77 3.6226 10.77C2.47109 10.77 1.5376 11.7035 1.5376 12.855C1.5376 14.0065 2.47109 14.94 3.6226 14.94Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.3775 14.94C15.529 14.94 16.4625 14.0065 16.4625 12.855C16.4625 11.7035 15.529 10.77 14.3775 10.77C13.226 10.77 12.2925 11.7035 12.2925 12.855C12.2925 14.0065 13.226 14.94 14.3775 14.94Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_25333_199825'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
