import { useContext } from 'react';
import { TwilioContext } from '../context/TwilioProvider';

export default function useTwilioContext() {
  const twilioState = useContext(TwilioContext);
  if (!twilioState) {
    throw new Error('useTwilioContext must be used within a TwilioProvider');
  }
  return twilioState;
}
