import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { CALL_TRANSFER } from 'graphql/channel/phoneWidget';
import { ToastMessage } from 'components/atoms';
import { transferToVar } from 'services/apollo/reactiveVars';
import { ACTIONS } from 'lib/twilio/constants';
import { useTwilioContext } from 'lib/twilio';

export function useCallTransfer(props?: any) {
  const { t } = useTranslation();
  const { dispatch } = useTwilioContext();
  const transferTo = useReactiveVar(transferToVar);

  const [transferCall, { data: transferCallData, loading: loadingCallTransfer }] = useMutation(
    CALL_TRANSFER,
    {
      onCompleted: resData => {
        const { error } = resData?.warmTransfer;
        // (Temporary fix) TODO: Need to remove errorKey checking after the fixes pushed in backend
        if (error === null || error?.errorKey === 'invalid_input') {
          if (error === null) {
            dispatch({
              type: ACTIONS.CALL_TRANSFER_INPROGRESS,
              data: {
                transferTo,
              },
            });
            return;
          }
        }
        ToastMessage({
          content: "Sorry, We can't transfer this call at the moment",
          type: 'danger',
        });
      },
      onError: () => {
        dispatch({
          type: ACTIONS.CALL_TRANSFER_FAILED,
          data: {
            transferTo,
          },
        });
        ToastMessage({
          content: t('error.unspecific', 'Something went wrong.'),
          type: 'danger',
        });
      },
    },
  );

  // TODO: Update type for argument data
  const callTransfer = async (data: any, to: string) => {
    transferToVar(to);
    dispatch({
      type: ACTIONS.CALL_TRANSFER_INITIAED,
      data: {
        transferTo,
      },
    });
    await transferCall({
      variables: {
        data,
      },
    });
  };

  return {
    callTransfer,
    transferCallData,
    loadingCallTransfer,
  };
}
