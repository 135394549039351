const AudioFile = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='#E7E6EB' />
    <path d='M25.17 8H11V32H28.999V11.9995L25.17 8Z' fill='white' />
    <g clipPath='url(#clip0_24390_204465)'>
      <path
        d='M23.7203 16V21.4683C23.7203 22.2409 23.0917 22.8695 22.3191 22.8695C21.5464 22.8695 20.9177 22.2409 20.9177 21.4683C20.9177 20.6957 21.5464 20.067 22.3191 20.067C22.6764 20.067 23.003 20.2016 23.2508 20.4227V18.1227L20.8753 18.7249L19.5193 19.0686L19.0828 19.1805V22.6016H19.0827C19.0811 23.3729 18.4532 24 17.6815 24C16.9088 24 16.2803 23.3713 16.2803 22.5986C16.2803 21.826 16.9088 21.1974 17.6815 21.1974C18.0389 21.1974 18.3654 21.332 18.6133 21.553V17.2946L20.8753 16.7212L23.7203 16Z'
        fill='#FB3449'
      />
      <path
        d='M23.72 16V21.4683C23.72 22.2409 23.0914 22.8695 22.3188 22.8695C21.5461 22.8695 20.9174 22.2409 20.9174 21.4683C20.9174 20.6957 21.5461 20.067 22.3188 20.067C22.6761 20.067 23.0027 20.2016 23.2505 20.4227V18.1227L20.875 18.7249V16.7212L23.72 16Z'
        fill='#FB3449'
      />
    </g>
    <path d='M25.7625 9.99979H11V8H25.17L25.7625 9.99979Z' fill='#FB3449' />
    <path d='M25.1709 11.9995H28.9999L25.1709 8V11.9995Z' fill='#D1D3D3' />
    <defs>
      <clipPath id='clip0_24390_204465'>
        <rect width='8' height='8' fill='white' transform='translate(16 16)' />
      </clipPath>
    </defs>
  </svg>
);

export default AudioFile;
