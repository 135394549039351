import styled from 'styled-components';

export const Container = styled.div`
  .external-link {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const SearchAgentWrapper = styled.div`
  padding: 5px 16px;
  .search-agent {
    height: 36px;
    background: #ffffff;
    border: 1px solid #d7cce4;
    border-radius: 8px;
    padding: 8px 16px;
    .ant-input,
    .ant-input::placeholder {
      font-family: Heebo;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
    .ant-input {
      color: #6e6681;
      &::placeholder {
        color: #9e99ab;
      }
    }
  }
`;
export const SpacingScrollbar = styled.div`
  padding-bottom: 4px;
`;

export const ListMembers = styled.div`
  height: 136px;
  margin-right: 6px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;

export const ExternalNumberForm = styled.form`
  .form-control.krisp-form-control {
    padding-left: 48px;
  }
  .krisp-phone-input-btn.flag-dropdown {
    width: 46px;
    border-right: none;
    .flag {
      border-radius: 2px;
      &.np {
        border-radius: 0px;
      }
    }
  }
  .ant-btn {
    height: 44px;
  }
`;
