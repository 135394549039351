import styled from 'styled-components';
import { Button } from 'components/atoms';

type BtnType = 'button' | 'submit' | 'reset';

const Wrapper = styled.div`
  .btn-success {
    padding: 0 8px;
  }
`;

interface GlobalSaveProps {
  onSave?: any;
  onDiscard?: any;
  htmlType?: BtnType;
  formId?: string;
  disabled?: boolean;
  loading?: boolean;
}
const GlobalSave = ({
  onSave,
  onDiscard,
  htmlType = 'button',
  formId,
  disabled = false,
  loading = false,
}: GlobalSaveProps) => {
  return (
    <Wrapper className='absolute bottom-6 z-10 2xl:min-w-800'>
      <div
        className='w-full flex items-center bg-gray-800 rounded-10 pl-5 pr-2.5 py-2.5 gap-6'
        data-cy='global-save'
      >
        <p className='text-white text-3.75 leading-normal'>
          You have <span className='font-medium'>unsaved changes</span> in this page
        </p>
        <div className='flex ml-auto'>
          <button
            data-cy='discard'
            type='button'
            onClick={onDiscard}
            className='h-10 rounded-lg bg-white mr-2.5 px-5'
          >
            <span className='font-manrope font-semibold leading-4 text-gray'>Discard</span>
          </button>
          <Button
            data-cy='save'
            variant='success'
            disabled={disabled}
            htmlType={htmlType}
            form={formId}
            loading={loading}
            onClick={onSave}
            className='h-10 btn-success'
          >
            <span className='font-manrope font-semibold leading-4 px-3 py-5 text-white'>
              Save Changes
            </span>
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default GlobalSave;
