export default function TeamIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.37793 5.57965L9.00043 9.41215L15.5779 5.60215'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 16.2064V9.40393'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.44797 1.85952L3.44297 4.07952C2.53547 4.58202 1.79297 5.84202 1.79297 6.87702V11.1145C1.79297 12.1495 2.53547 13.4095 3.44297 13.912L7.44797 16.1395C8.30297 16.612 9.70547 16.612 10.5605 16.1395L14.5655 13.912C15.473 13.4095 16.2155 12.1495 16.2155 11.1145V6.87702C16.2155 5.84202 15.473 4.58202 14.5655 4.07952L10.5605 1.85202C9.69797 1.37952 8.30297 1.37952 7.44797 1.85952V1.85952Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
