import React from 'react';
import styled, { css } from 'styled-components';

// TODO need to import themes for coloring

interface TextProps {
  as?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'label';
  children: unknown;
  size?: string;
  weight?: number;
  width?: number;
  color?: string;
  theme?: never;
  className?: string;
  margin?: string;
  padding?: string;
  lineHeight?: string;
  onClick?: () => void;
}

// need to workaround explicit any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledComponent = styled.div<any>`
  font-size: ${(props: { size: string }) => {
    return `${props.size}px`;
  }};
  color: ${({ theme, color }) => (color ? `${color}` : theme.colors.text.t5)};
  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight}px;
    `};

  .capitalize {
    text-transform: capitalize;
  }
`;
const Text = (props: TextProps) => {
  const { as = 'p', size = '14', weight, width, color, children, className, ...rest } = props;
  return (
    <StyledComponent
      as={as}
      size={size}
      weight={weight}
      width={width}
      color={color}
      className={className}
      {...rest}
    >
      {children}
    </StyledComponent>
  );
};

export default Text;
