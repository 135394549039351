import { useEffect, useState } from 'react';
import { isMobileIos } from 'components/utils/helpers';
import useScreenType from 'hooks/useScreenType';

export const useMobilePopUp = () => {
  const { isMobile } = useScreenType();
  const [showMobileInfoModal, setShowMobileInfoModal] = useState<boolean>(false);
  const iosAppUrl = process.env.REACT_APP_IOS_APP_URL;
  const androidAppUrl = process.env.REACT_APP_ANDROID_APP_URL;

  useEffect(() => {
    if (isMobile) setShowMobileInfoModal(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleMobileInfoModal = () => setShowMobileInfoModal(!showMobileInfoModal);

  const openAppUrl = () => {
    const appUrl = isMobileIos() ? iosAppUrl : androidAppUrl;
    window.open(appUrl, '_blank', 'noreferrer');
    toggleMobileInfoModal();
  };

  return { showMobileInfoModal, openAppUrl, toggleMobileInfoModal };
};
