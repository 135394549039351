export type IErrorObject = {
  errorKey: string;
  message: string;
};
export type ITwilioError = {
  [key: number]: IErrorObject;
};
export const TWILIO_ERRORS: ITwilioError = {
  31003: {
    errorKey: 'error.connectionTimeout',
    message: 'Connection Timeout',
  },
  31205: {
    errorKey: 'error.tokenExpired',
    message: 'Voice token expired',
  },
  31401: {
    errorKey: 'error.noAccessMicrophone',
    message: 'No access for microphone',
  },
  31402: {
    errorKey: 'error.noUserMedia',
    message: 'Unable to detect user media',
  },
  // HIDE SIGNALING CONNECTION ERRORS
  // 53000: {
  //   errorKey: 'error.signalingConnectionError',
  //   message: 'Signaling connection error',
  // },
  // TODO: NEED TO ADD TWILIO ERROR CODES THAT NEED TO BE HANDLED
};
