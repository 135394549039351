import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const ADD_CAMPAIGN_VOICEMAIL = gql`
  mutation addCampaignVoicemailDrop($data: CampaignVoiceMailDropInputData!)
  @api(name: "salesDialer") {
    addCampaignVoicemailDrop(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const UPDATE_CAMPAIGN_VOICEMAIL = gql`
  mutation updateCampaignVoicemailDrop($data: UpdateCampaignVoiceMailInputData!)
  @api(name: "salesDialer") {
    updateCampaignVoicemailDrop(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CAMPAIGN_VOICEMAIL_DROP = gql`
  mutation campaignVoicemailDrop($input: VoicemailDropInput!) @api(name: "salesDialer") {
    campaignVoicemailDrop(input: $input) {
      status
      error {
        ...Error
      }
      data {
        conversationId
        status
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
