import { useState, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import {
  activeAudioInputId,
  activeAudioOutputId,
  audioConstraintsVar,
} from 'services/apollo/reactiveVars';

export function useAudioDeviceHandlers() {
  const activeMicRef: any = useRef();
  const activeSpeakerRef: any = useRef();

  const { deviceInstance } = useTwilioContext();
  const [echoCancellation, setEchoCancellation] = useState(false);
  const [noiseReduction, setNoiseReduction] = useState(false);

  const activeMic = useReactiveVar(activeAudioInputId);
  activeMicRef.current = activeMic;

  const activeSpeaker = useReactiveVar(activeAudioOutputId);
  activeSpeakerRef.current = activeSpeaker;

  const audioConstraints = useReactiveVar(audioConstraintsVar);

  async function getOldTrack() {
    const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true, video: false });
    const oldTrack = stream?.getTracks()?.[0];
    return oldTrack;
  }

  async function getUserMedia(constraints: any, oldTrack: any) {
    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (e: any) {
      oldTrack.stop();
      return await navigator.mediaDevices.getUserMedia(constraints);
    }
  }
  const handleAudioInputChange = async (value: string) => {
    activeAudioInputId(value);
    const oldTrack = await getOldTrack();
    const constraints = {
      audio: { deviceId: value ? { exact: value } : undefined },
    };
    getUserMedia(constraints, oldTrack);
  };

  const handleAudioOutputChange = (value: string) => {
    activeAudioOutputId(value);
    if (deviceInstance) {
      deviceInstance?.audio?.speakerDevices?.set(value).catch(error => {
        console.error(error);
      });
    }
  };

  const handleEcho = (isEnabled: boolean) => {
    if (deviceInstance) {
      deviceInstance?.audio
        ?.setAudioConstraints({
          echoCancellation: isEnabled,
        })
        .then(
          () => {
            setEchoCancellation(isEnabled);
            audioConstraintsVar({ ...audioConstraints, echoCancellation: isEnabled });
          },
          err => {
            console.error(err);
            deviceInstance?.audio?.unsetAudioConstraints();
            setEchoCancellation(false);
          },
        );
    }
  };

  const handleNoise = (isEnabled: boolean) => {
    if (deviceInstance) {
      deviceInstance?.audio
        ?.setAudioConstraints({
          noiseSuppression: isEnabled,
        })
        .then(
          () => {
            setNoiseReduction(isEnabled);
            audioConstraintsVar({ ...audioConstraints, noiseSuppression: isEnabled });
          },
          err => {
            console.error(err);
            deviceInstance?.audio?.unsetAudioConstraints();
            setNoiseReduction(false);
          },
        );
    }
  };
  return {
    echoCancellation,
    noiseReduction,
    activeMic,
    activeSpeaker: activeSpeakerRef.current,
    audioConstraints,
    handleAudioInputChange,
    handleAudioOutputChange,
    handleEcho,
    handleNoise,
  };
}
