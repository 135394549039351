import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';
import { UserAvatar } from 'components/atoms/avatar';

import { StyledModal } from './Styles';

interface CancelSubscriptionModalProps {
  open: boolean;
  onOk: () => void;
}

const CancelSubscriptionModal = ({ open, onOk }: CancelSubscriptionModalProps) => {
  const { t } = useTranslation();
  const { loggedInMemberInfo } = useContext(AuthContext);
  return (
    <StyledModal
      width={348}
      centered
      visible={open}
      maskClosable={false}
      keyboard={false}
      zIndex={500}
      wrapClassName='blurred-wrapper'
      footer={null}
      closable={false}
    >
      <div className='bg-info-100 h-16 w-16 flex items-center justify-center rounded-20 mx-auto mb-6'>
        <div>
          <UserAvatar
            src={loggedInMemberInfo?.photo}
            size={40}
            style={{
              borderRadius: 10,
              background: '#613494',
              filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
          >
            {loggedInMemberInfo?.title?.charAt(0)}
          </UserAvatar>
        </div>
      </div>
      <h5 className='text-center text-xl text-gray-700 leading-7 font-bold mb-2'>
        {t('subsCancelled', 'Subscription Cancelled')}
      </h5>
      <p className='text-15 leading-normal text-gray text-center'>
        <Trans i18nKey='cancelSubs.info'>
          Your subscription for the workspace{' '}
          <span className='text-gray-700 font-medium'>
            {{ restoreWorkspaceTitle: loggedInMemberInfo?.title }}
          </span>{' '}
          has been cancelled. Contact Support to Reactivate.
        </Trans>
      </p>
      <div className='mt-6'>
        <Button
          key='submit'
          type='primary'
          className='w-full capitalize btn'
          size='large'
          onClick={onOk}
        >
          {t('common.action.contactSupport', 'Contact Support')}
        </Button>
      </div>
    </StyledModal>
  );
};

export default CancelSubscriptionModal;
