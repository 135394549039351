import { Drawer } from 'antd';

import { useTwilioContext } from 'lib/twilio';

import useCallWidgetDrawerContext from './useCallWidgetDrawerContext';
import BackButtonHeader from '../call-active/back-button-header';
import ActiveCallKeypad from '../call-active/screen-keypad';
import TagsAssign from '../call-active/screen-tags';
import NoteAdd from '../call-active/screen-notes';
import ContactAdd from '../call-active/screen-add-contact';
import CallTransfer from '../call-active/screen-call-transfer';
import { DeclineWithMessage } from '../call-incoming/DeclineWithMessage';
import { CALL_ACTIVE_SCREENS, CALL_INCOMING_SCREENS } from '../constants';
import Script from '../call-active/script';
import CampaignNoteAdd from '../call-active/screen-campaign-notes';

type DrawerContentType = {
  title?: string;
  component: React.ReactNode;
};

export const useDrawerComponent = () => {
  const { selectedDrawer, closeDrawer } = useCallWidgetDrawerContext();
  const {
    state: { salesDialerWidget },
  } = useTwilioContext();
  const {
    ADD_TAG,
    ADD_NOTE,
    ADD_CONTACT,
    KEYPAD,
    CALL_TRANSFER,
    CALL_SCRIPT,
    ADD_CAMPAIGN_NOTE,
  } = CALL_ACTIVE_SCREENS;
  const { CUSTOM_MESSAGE } = CALL_INCOMING_SCREENS;

  const drawerContent = (action: string | null) => {
    const actions: Record<string, DrawerContentType | undefined> = {
      [KEYPAD]: {
        component: <ActiveCallKeypad />,
      },
      [ADD_TAG]: {
        title: 'Add tags',
        component: <TagsAssign />,
      },
      [ADD_NOTE]: {
        title: 'Add note',
        component: <NoteAdd />,
      },
      [ADD_CAMPAIGN_NOTE]: {
        title: 'Add note',
        component: <CampaignNoteAdd />,
      },
      [ADD_CONTACT]: {
        title: 'Add to contacts',
        component: <ContactAdd />,
      },
      [CALL_TRANSFER]: {
        title: 'Call Transfer',
        component: <CallTransfer />,
      },
      [CUSTOM_MESSAGE]: {
        title: 'Custom Message',
        component: <DeclineWithMessage />,
      },
      [CALL_SCRIPT]: {
        title: 'Script',
        component: <Script />,
      },
      none: undefined,
    };
    return actions[action || 'none'];
  };

  const SCREENS_WITH_BACK_BUTTON = [
    ADD_TAG,
    ADD_NOTE,
    ADD_CONTACT,
    CALL_TRANSFER,
    CUSTOM_MESSAGE,
    CALL_SCRIPT,
    ADD_CAMPAIGN_NOTE,
  ];
  const hasBackButtonInScreen = selectedDrawer && SCREENS_WITH_BACK_BUTTON.includes(selectedDrawer);

  const drawerComponent = (
    <Drawer
      title={<></>}
      placement={salesDialerWidget ? 'top' : 'bottom'}
      onClose={closeDrawer}
      getContainer={false}
      visible={!!selectedDrawer}
      height={500}
      style={{ position: 'absolute', width: 294, left: 'auto' }}
      headerStyle={{ display: 'none' }}
      contentWrapperStyle={{ boxShadow: 'none' }}
      mask={false}
      closable={false}
    >
      <div className={hasBackButtonInScreen ? 'pt-12 h-full flex flex-col' : ''}>
        {hasBackButtonInScreen && (
          <BackButtonHeader
            className='px-4 w-full mb-5'
            handleBack={closeDrawer}
            title={drawerContent(selectedDrawer)?.title}
          />
        )}
        {drawerContent(selectedDrawer)?.component}
      </div>
    </Drawer>
  );

  return {
    drawerComponent,
  };
};
