/* eslint-disable prefer-destructuring */

export function useTouchTone() {
  const audioContext = new window.AudioContext();

  const play = (tones: any) => {
    // Create, amplify, and connect row oscillator
    const rowOscillator = audioContext.createOscillator();
    const rowGain = audioContext.createGain();
    rowOscillator.connect(rowGain);
    rowGain.connect(audioContext.destination);

    // Create, amplify, and connect column oscillator
    const colOscillator = audioContext.createOscillator();
    const colGain = audioContext.createGain();
    colOscillator.connect(colGain);
    colGain.connect(audioContext.destination);

    // Get the current time from the audio context
    const time = audioContext.currentTime;

    // Load tones into oscillators
    rowOscillator.frequency.value = tones[0];
    colOscillator.frequency.value = tones[1];

    // Set gain (volume) and start oscillators
    const volume = 0.2; // Set the desired volume level (0.2 is 20% of full volume)
    rowGain.gain.setValueAtTime(volume, time);
    colGain.gain.setValueAtTime(volume, time);
    rowOscillator.start(time);
    colOscillator.start(time);

    // Ramp down gain and stop oscillators
    rowGain.gain.setValueAtTime(0, time + 0.2);
    colGain.gain.setValueAtTime(0, time + 0.2);
    rowOscillator.stop(time + 0.2);
  };

  return {
    play,
  };
}
