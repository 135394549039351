import { memo } from 'react';
import styled from 'styled-components';

const Error = styled.span`
  margin-top: 4px;
  display: block;
  color: ${({ theme }) => theme.colors.danger.default};
  font-size: ${({ theme }) => theme.text.size.body.text.caption};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: 12px;
  &:first-letter {
    text-transform: capitalize;
  }
  .has-emergency-number & {
    margin: -10.5px 0 -8px 60px;
    font-family: ${({ theme }) => theme.text.font.heading};
    font-size: 14px;
    line-height: 150%;
    white-space: pre-line;
  }
`;
export default memo(Error);
