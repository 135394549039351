import { memo, useEffect, useContext, Suspense } from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from 'components/atoms';
import { contactBlockedVar } from 'services/apollo/reactiveVars';
import { ContactContext } from 'components/organisms/contact/ContactContext';

import ContactDetail from './ContactDetail';
// import { ContactsGroupDetail } from './ContactsGroupDetail';

const Contact: React.FunctionComponent = () => {
  const { contactId } = useParams<Record<string, string | undefined>>();
  const { contactData } = useContext<any>(ContactContext);
  useEffect(() => {
    if (contactData) {
      contactBlockedVar(contactData?.blocked);
    }
  }, [contactData]);

  return (
    <>
      {/* {contactId === 'new-conversation' ? ( // BULK SMS FEATURE HOLD FOR VERSION 1 RELEASE
        <ContactsGroupDetail />
      ) : ( */}
      <Suspense fallback={<Spinner />}>
        {contactId !== 'new-conversation' && <ContactDetail data={contactData} />}
      </Suspense>
      {/* )} */}
    </>
  );
};

export default memo(Contact);
