import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_CHANNELS, GET_VOIP_NONVOIP_CHANNELS } from 'graphql/channel';
import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';

export function useChannelsQuery() {
  const [fetchChannels, { data, loading, called }] = useLazyQuery(
    ENABLE_NON_VOIP_FEATURE ? GET_VOIP_NONVOIP_CHANNELS : GET_CHANNELS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    fetchChannels({
      variables: ENABLE_NON_VOIP_FEATURE
        ? {
            numberTypes: ['Voip', 'NonVoip'],
          }
        : {},
    });
  }, [fetchChannels]);

  const numbers: never[] = useMemo(() => data?.channels?.data ?? [], [data]);

  return {
    numbers,
    loading,
    called,
  };
}
