import * as yup from 'yup';
import { inputs, MIN_LENGTH } from './common';

const ORGANIZATION_MAX_LENGTH = 49;

export const registerInputs = {
  organization: yup
    .string()
    .trim()
    .required('Required')
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(ORGANIZATION_MAX_LENGTH, `Shouldn't exceed ${ORGANIZATION_MAX_LENGTH} characters`),
  firstName: inputs.firstName,
  lastName: inputs.lastName,
  email: inputs.email.required('Required'),
  phoneNumber: inputs.required,
  password: inputs.password,
  confirmPassword: inputs.default
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
};
