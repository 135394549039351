import Suspense from 'services/Suspense';
import { Icon } from 'components/atoms';

import Logout from './Logout';
import * as S from '../workspace-list-sidebar/Styles';

export default function SupportSideNav() {
  return (
    <S.WorkSpaceProfileWrapper className='flex flex-col items-center'>
      <Suspense>
        <>
          <div>
            <Icon name='logo' />
          </div>

          <div className='mt-auto'>
            <Logout />
          </div>
        </>
      </Suspense>
    </S.WorkSpaceProfileWrapper>
  );
}
