import { css } from 'styled-components';

export const antTabs = css`
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs-dropdown-menu-item {
    font-weight: ${({ theme }) => theme.text.weight.semiBold};
    font-size: ${({ theme }) => theme.text.size.body.text.small};
    line-height: 14px;
    color: ${({ theme }) => theme.colors.text.t6};
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1} !important;
  }
  .ant-tabs-ink-bar {
    border-bottom: 3px solid ${({ theme }) => theme.colors.primary.default};
  }

  // contact logs
  .ant-tabs-dropdown {
    .logs-badge .ant-badge {
      position: relative;
      top: 0;
    }
  }
`;
