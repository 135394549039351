import React, { ReactElement } from 'react';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

import { Action } from '../call-action/Action';

interface IProps {
  setSelectedAction: (data: string | null) => void;
}

export function SecondaryCallActions({ setSelectedAction }: IProps): ReactElement {
  const { clientInfo } = useCallWidgetContext();
  const { id: contactId } = clientInfo || {};
  const handleNoteAdd = () => {
    setSelectedAction('note-add');
  };

  const handleTagAssign = () => {
    setSelectedAction('tags-assign');
  };

  const handleAddContact = () => {
    setSelectedAction('contact-add');
  };
  return (
    <div className='mx-7.5 flex justify-center items-center gap-x-6.5 border-b border-gray-50  pb-6 mb-5'>
      <Action
        action='Notes'
        icon='note-add'
        onClick={handleNoteAdd}
        className=''
        type='secondary'
      />
      <Action action='Tags' icon='tag' onClick={handleTagAssign} className='' type='secondary' />
      <Action
        action='Add'
        icon='user-square'
        onClick={handleAddContact}
        className=''
        type='secondary'
        disabled={!!contactId}
      />
    </div>
  );
}
