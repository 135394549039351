export default function DialpadIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M8.00009 4.55065C8.33622 4.55065 8.60876 4.27812 8.60876 3.94195C8.60876 3.60578 8.33622 3.33325 8.00009 3.33325C7.66389 3.33325 7.39136 3.60578 7.39136 3.94195C7.39136 4.27812 7.66389 4.55065 8.00009 4.55065Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0587 4.55065C12.3949 4.55065 12.6675 4.27812 12.6675 3.94195C12.6675 3.60578 12.3949 3.33325 12.0587 3.33325C11.7225 3.33325 11.4501 3.60578 11.4501 3.94195C11.4501 4.27812 11.7225 4.55065 12.0587 4.55065Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.94127 4.55065C4.27745 4.55065 4.54997 4.27812 4.54997 3.94195C4.54997 3.60578 4.27745 3.33325 3.94127 3.33325C3.6051 3.33325 3.33258 3.60578 3.33258 3.94195C3.33258 4.27812 3.6051 4.55065 3.94127 4.55065Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00009 8.60876C8.33622 8.60876 8.60876 8.33622 8.60876 8.00009C8.60876 7.66389 8.33622 7.39136 8.00009 7.39136C7.66389 7.39136 7.39136 7.66389 7.39136 8.00009C7.39136 8.33622 7.66389 8.60876 8.00009 8.60876Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0587 8.60876C12.3949 8.60876 12.6675 8.33622 12.6675 8.00009C12.6675 7.66389 12.3949 7.39136 12.0587 7.39136C11.7225 7.39136 11.4501 7.66389 11.4501 8.00009C11.4501 8.33622 11.7225 8.60876 12.0587 8.60876Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.94127 8.60876C4.27745 8.60876 4.54997 8.33622 4.54997 8.00009C4.54997 7.66389 4.27745 7.39136 3.94127 7.39136C3.6051 7.39136 3.33258 7.66389 3.33258 8.00009C3.33258 8.33622 3.6051 8.60876 3.94127 8.60876Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00009 12.6666C8.33622 12.6666 8.60876 12.3941 8.60876 12.058C8.60876 11.7218 8.33622 11.4492 8.00009 11.4492C7.66389 11.4492 7.39136 11.7218 7.39136 12.058C7.39136 12.3941 7.66389 12.6666 8.00009 12.6666Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0587 12.6666C12.3949 12.6666 12.6675 12.3941 12.6675 12.058C12.6675 11.7218 12.3949 11.4492 12.0587 11.4492C11.7225 11.4492 11.4501 11.7218 11.4501 12.058C11.4501 12.3941 11.7225 12.6666 12.0587 12.6666Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.94127 12.6666C4.27745 12.6666 4.54997 12.3941 4.54997 12.058C4.54997 11.7218 4.27745 11.4492 3.94127 11.4492C3.6051 11.4492 3.33258 11.7218 3.33258 12.058C3.33258 12.3941 3.6051 12.6666 3.94127 12.6666Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
