import { memo, useContext } from 'react';
import { Skeleton } from 'antd';

import { Tooltip } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';

import Dialer from './dialer';
import { NavHelperContext } from '../NavHelperContext';
import WorkspaceCredit from './CreditAmount';

function WorkspaceBarHeader() {
  const { activeWorkspaceData, loadingActiveWorkspace } = useContext<any>(NavHelperContext);
  const { plan, title, id } = activeWorkspaceData?.workspace?.data || {};
  const workspaceTooltipTitle = title && title.length > 18 ? title : '';

  return (
    <div className='pl-4 pr-3 py-3 flex justify-between items-center w-full shadow-bottom'>
      <div>
        {loadingActiveWorkspace ? (
          <Skeleton.Input active size='default' />
        ) : (
          <>
            <Tooltip title={workspaceTooltipTitle} placement='right'>
              <h6
                data-cy='user-title'
                className='text-gray-700 text-sm leading-5.25 font-bold mb-px'
              >
                {nameElipsis(title ?? '', 18)}
              </h6>
            </Tooltip>
            {id && <WorkspaceCredit credit={plan?.currentCredit} workspaceId={id} />}
          </>
        )}
      </div>
      <div data-cy='dialer-icon' className='ml-auto'>
        <Dialer />
      </div>
    </div>
  );
}
export default memo(WorkspaceBarHeader);
