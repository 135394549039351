import { useEffect, useState } from 'react';

const useLocationFromIP = () => {
  const [countryInfo, setCountryInfo] = useState(undefined);
  const [countryCode, setCountryCode] = useState(undefined);
  useEffect(() => {
    // may be we need to hit our own server which will hit multiple server if one is busy
    fetch('https://extreme-ip-lookup.com/json/')
      .then((res: any) => res.json())
      .then((response: any) => {
        setCountryInfo(response);
        setCountryCode(response?.countryCode.toLowerCase());
      })
      .catch((error: any) => {
        console.error('Request failed in ip lookup:', error);
      });
  }, []);
  return { countryCode, countryInfo };
};

export default useLocationFromIP;
