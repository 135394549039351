export default function TimerIcon() {
  return (
    <svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.9688 8.78125C12.9688 11.8 10.5187 14.25 7.5 14.25C4.48125 14.25 2.03125 11.8 2.03125 8.78125C2.03125 5.7625 4.48125 3.3125 7.5 3.3125C10.5187 3.3125 12.9688 5.7625 12.9688 8.78125Z'
        stroke='#564D6D'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 5.5V8.625'
        stroke='#564D6D'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 1.75H9.375'
        stroke='#564D6D'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
