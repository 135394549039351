import SecureLS from 'secure-ls';
import Cookies from 'js-cookie';
import { useApolloClient } from '@apollo/client';
import { useFcm } from 'components/pages/authentication/login/useFcm';
import useLocalStorage from './useLocalStorage';
import { useChatBot } from './useChatBot';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;

export function useSession() {
  const [, setUser] = useLocalStorage('user', '');
  const [, setToken] = useLocalStorage('_tokens', '');
  const { clearFcmInstance } = useFcm();
  const { resetCrispSession } = useChatBot();

  const client = useApolloClient();

  const clearSession = async () => {
    const deviceId = localStorage.getItem('deviceId');
    const hideStatusChangePrompt = localStorage.getItem('hideStatusChangePrompt');
    const dailerDefaultCountry = localStorage.getItem('_dialer-default-country');
    await setUser('');
    await setToken('');
    Cookies.remove(`${COOKIE_PREFIX}_kc_auth_tk`);
    ls.clear();
    sessionStorage.clear();
    clearFcmInstance();
    try {
      if (client)
        await client.clearStore().then(() => {
          client.resetStore();
        });
    } catch (e) {
      console.error(e);
    }
    localStorage.setItem('deviceId', deviceId as string);
    localStorage.setItem('hideStatusChangePrompt', hideStatusChangePrompt as string);
    if (dailerDefaultCountry)
      localStorage.setItem('_dialer-default-country', dailerDefaultCountry as string);

    resetCrispSession();
    window.location.reload();
  };
  return {
    clearSession,
  };
}
