export default function ContactListIcon() {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='0.5' width='35' height='35' rx='9.5' fill='#F5F2F8' />
      <path
        d='M15.75 25.5H20.25C24 25.5 25.5 24 25.5 20.25V15.75C25.5 12 24 10.5 20.25 10.5H15.75C12 10.5 10.5 12 10.5 15.75V20.25C10.5 24 12 25.5 15.75 25.5Z'
        stroke='#3D3358'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.8125 15.75H15.1875'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.8125 20.25H15.1875'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect x='0.5' y='0.5' width='35' height='35' rx='9.5' stroke='#ECE6F2' />
    </svg>
  );
}
