import { memo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useReactiveVar } from '@apollo/client';

import { Tooltip } from 'components/atoms';
import { Workspace } from 'generated/graphql';
import { loadingSwitchWorkspace } from 'services/apollo/reactiveVars';
import { UserAvatar } from 'components/atoms/avatar';

interface WorkspaceProfileProps {
  id?: string;
  onClick?: () => void;
  isActive?: boolean;
  workspace?: Workspace;
}

const Wrapper = styled(({ isActive, ...rest }) => <div {...rest} />)<{
  isActive: boolean;
}>`
  height: 40px;
  width: 40px;
  padding: 2px;
  margin: 0 auto;
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid;
  border-color: transparent;
  transition: 0.2s ease-out;
  ${({ isActive }) =>
    isActive &&
    css`
      .ant-avatar {
        outline: 2px solid ${({ theme }) => theme.colors.text.t0} !important;
        outline-offset: 2px;
      }
    `};
  &:active,
  :hover,
  :visited,
  :focus {
    .ant-avatar {
      outline: 2px solid #8867af;
      outline-offset: 2px;
    }
  }
`;

const WorkspaceProfile = ({ id, onClick, isActive, workspace }: WorkspaceProfileProps) => {
  const theme = useTheme();
  const isDeletedWorkspace = workspace?.status === 'Inactive';
  const loadingOnWorkspaceSwitch = useReactiveVar(loadingSwitchWorkspace);
  return (
    <Wrapper
      id={id}
      onClick={loadingOnWorkspaceSwitch ? () => console.log('') : onClick}
      role='button'
      isActive={isActive}
      className={loadingOnWorkspaceSwitch ? 'disabled-cursor-light-theme opacity-50' : ''}
    >
      <Tooltip title={workspace?.title as string} placement='right'>
        <div>
          <UserAvatar
            src={!isDeletedWorkspace && workspace?.photo}
            style={{
              borderRadius: 8,
              background: isDeletedWorkspace ? theme.colors.text.t5 : theme.colors.primary.p5,
            }}
          >
            {workspace?.title?.charAt(0)}
          </UserAvatar>
        </div>
      </Tooltip>
    </Wrapper>
  );
};
export default memo(WorkspaceProfile);
