import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import * as path from 'constants/routes';
import { AuthContext } from 'contexts/auth/AuthContext';
import { userOnlineStatus } from 'services/apollo/reactiveVars';
import { useWorkspacesQuery } from 'components/organisms/workspace';
import { useSession } from 'hooks/useSession';

export default function useUserSettings() {
  const [loggingOut, setLoggingOut] = useState(false);
  const { clearSession } = useSession();

  const onLogout = async () => {
    setLoggingOut(true);
    clearSession();
    setLoggingOut(false);
  };

  const history = useHistory();

  const {
    profileData: { firstname, lastname, profilePicture },
  } = useWorkspacesQuery();
  const [visibleLogoutModal, setVisibleLogoutModal] = React.useState(false);
  const isUserOnline = useReactiveVar(userOnlineStatus);
  const toggleLogoutModal = () => setVisibleLogoutModal(prevState => !prevState);
  const { isSubscriptionExpired } = useContext(AuthContext);

  const { SETTINGS, PROFILE, LANGUAGE_TIMEZONES } = path;

  const handleProfileEdit = () => {
    history.push(
      isSubscriptionExpired ? `${path.SETTINGS}${path?.PLAN_SUBS}` : `${SETTINGS}${PROFILE}`,
    );
  };
  const redirectToLanguagePage = () => {
    history.push(
      isSubscriptionExpired
        ? `${path.SETTINGS}${path?.PLAN_SUBS}`
        : `${SETTINGS}${LANGUAGE_TIMEZONES}`,
    );
  };

  return {
    firstname,
    lastname,
    profilePicture,
    visibleLogoutModal,
    isUserOnline,
    toggleLogoutModal,
    onLogout,
    loggingOut,
    handleProfileEdit,
    redirectToLanguagePage,
  };
}
