export default function NonVoipBudget() {
  return (
    <svg width='66' height='67' viewBox='0 0 66 67' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_417_18682)'>
        <path
          d='M51.1758 0.5H17.6602C16.5928 0.5 15.7266 1.36625 15.7266 2.43359V66.5H64.0664C65.1338 66.5 66 65.6338 66 64.5664V15.3242L51.1758 0.5Z'
          fill='white'
        />
        <path
          d='M41 0.5V66.5H64.2031C65.2705 66.5 66.1367 65.6338 66.1367 64.5664V15.3242L51.3125 0.5H41Z'
          fill='#E2DFF4'
        />
        <path
          d='M51.1758 0.5V13.3906C51.1758 14.458 52.042 15.3242 53.1094 15.3242H66L51.1758 0.5Z'
          fill='#D5D3EA'
        />
        <path
          d='M38.9297 12.1016H23.4609C22.3936 12.1016 21.5273 11.2353 21.5273 10.168C21.5273 9.10063 22.3936 8.23438 23.4609 8.23438H38.9297C39.997 8.23438 40.8633 9.10063 40.8633 10.168C40.8633 11.2353 39.997 12.1016 38.9297 12.1016Z'
          fill='#FD715C'
        />
        <path
          d='M38.9297 19.8359H23.4609C22.3936 19.8359 21.5273 18.9697 21.5273 17.9023C21.5273 16.835 22.3936 15.9688 23.4609 15.9688H38.9297C39.997 15.9688 40.8633 16.835 40.8633 17.9023C40.8633 18.9697 39.997 19.8359 38.9297 19.8359Z'
          fill='#FD715C'
        />
        <path
          d='M53.0635 37.5664C50.8319 37.5664 49 39.3908 49 41.6224C49 43.854 50.8319 45.6859 53.0635 45.6859C54.1974 45.6859 55.0953 46.5838 55.0953 47.7177C55.0953 48.8515 54.1974 49.7494 53.0635 49.7494C51.9296 49.7494 51.0318 48.8515 51.0318 47.7177C51.0318 47.5842 51.0055 47.4521 50.9545 47.3289C50.9035 47.2056 50.8287 47.0936 50.7343 46.9992C50.64 46.9048 50.528 46.8299 50.4047 46.7789C50.2814 46.7278 50.1493 46.7015 50.0159 46.7015C49.8824 46.7015 49.7503 46.7278 49.6271 46.7789C49.5038 46.8299 49.3918 46.9048 49.2974 46.9992C49.2031 47.0936 49.1283 47.2056 49.0772 47.3289C49.0262 47.4521 49 47.5842 49 47.7177C49 49.9493 50.8319 51.7812 53.0635 51.7812C55.2952 51.7812 57.127 49.9493 57.127 47.7177C57.127 45.486 55.2952 43.6542 53.0635 43.6542C51.9296 43.6542 51.0318 42.7563 51.0318 41.6224C51.0318 40.4885 51.9296 39.5907 53.0635 39.5907C54.1974 39.5907 55.0953 40.4885 55.0953 41.6224C55.0952 41.7558 55.1215 41.888 55.1725 42.0113C55.2235 42.1346 55.2984 42.2466 55.3927 42.341C55.487 42.4353 55.599 42.5101 55.7223 42.5612C55.8456 42.6123 55.9777 42.6386 56.1111 42.6386C56.2446 42.6386 56.3767 42.6123 56.5 42.5612C56.6232 42.5101 56.7352 42.4353 56.8296 42.341C56.9239 42.2466 56.9987 42.1346 57.0498 42.0113C57.1008 41.888 57.127 41.7558 57.127 41.6224C57.127 39.3908 55.2952 37.5664 53.0635 37.5664Z'
          fill='#E07408'
        />
        <path
          d='M52.0439 38.5598V35.5159C52.0439 34.1614 54.0757 34.1614 54.0757 35.5159V38.5598H52.0439Z'
          fill='#E07408'
        />
        <path
          d='M52.0439 50.769V53.8129C52.0439 55.1674 54.0757 55.1674 54.0757 53.8129V50.769H52.0439Z'
          fill='#E07408'
        />
        <path
          d='M30.5938 65.8281H6.40625C5.69348 65.8281 5.0099 65.545 4.5059 65.041C4.0019 64.537 3.71875 63.8534 3.71875 63.1406V32.2344L11.7812 24.1719H30.5938C31.3065 24.1719 31.9901 24.455 32.4941 24.959C32.9981 25.463 33.2812 26.1466 33.2812 26.8594V63.1406C33.2812 63.8534 32.9981 64.537 32.4941 65.041C31.9901 65.545 31.3065 65.8281 30.5938 65.8281Z'
          fill='#637991'
        />
        <path
          d='M8.42188 65.8281C7.70911 65.8281 7.02553 65.545 6.52153 65.041C6.01752 64.537 5.73438 63.8534 5.73438 63.1406V33.0688L12.6157 26.1875H30.5938C31.3065 26.1875 31.9901 26.4706 32.4941 26.9747C32.9981 27.4787 33.2812 28.1622 33.2812 28.875V63.1406C33.2812 63.8534 32.9981 64.537 32.4941 65.041C31.9901 65.545 31.3065 65.8281 30.5938 65.8281H8.42188Z'
          fill='#4A6078'
        />
        <path
          d='M26.5625 42.3125H10.4375C9.3243 42.3125 8.42188 43.2149 8.42188 44.3281V60.4531C8.42188 61.5663 9.3243 62.4688 10.4375 62.4688H26.5625C27.6757 62.4688 28.5781 61.5663 28.5781 60.4531V44.3281C28.5781 43.2149 27.6757 42.3125 26.5625 42.3125Z'
          fill='#FFDB43'
        />
        <path
          d='M24.5469 50.8789C24.6805 50.8789 24.8087 50.8258 24.9032 50.7313C24.9977 50.6368 25.0508 50.5086 25.0508 50.375V46.8477H28.5781V45.8398H21.0195V42.3125H20.0117V45.8398H12.957V42.3125H11.9492V46.3438C11.9492 46.4774 12.0023 46.6056 12.0968 46.7001C12.1913 46.7946 12.3195 46.8477 12.4531 46.8477H15.9805V49.8711H8.42188V50.8789H15.9805V62.4688H16.9883V58.9414H28.5781V57.9336H16.9883V54.9102H28.5781V53.9023H16.9883V50.8789H24.5469ZM16.9883 46.8477H24.043V49.8711H16.9883V46.8477Z'
          fill='#F4CC21'
        />
        <path
          d='M12.4531 53.9023H8.42188V54.9102H11.9492V62.4688H12.957V54.4062C12.957 54.2726 12.9039 54.1444 12.8094 54.0499C12.7149 53.9554 12.5868 53.9023 12.4531 53.9023Z'
          fill='#F4CC21'
        />
        <path
          d='M11.7812 36.0977C11.6476 36.0977 11.5194 36.0446 11.4249 35.9501C11.3304 35.8556 11.2773 35.7274 11.2773 35.5938V30.2188C11.2773 30.0851 11.3304 29.9569 11.4249 29.8624C11.5194 29.7679 11.6476 29.7148 11.7812 29.7148C11.9149 29.7148 12.0431 29.7679 12.1376 29.8624C12.2321 29.9569 12.2852 30.0851 12.2852 30.2188V35.5938C12.2852 35.7274 12.2321 35.8556 12.1376 35.9501C12.0431 36.0446 11.9149 36.0977 11.7812 36.0977Z'
          fill='#FFDB43'
        />
        <path
          d='M14.4688 36.0977C14.3351 36.0977 14.2069 36.0446 14.1124 35.9501C14.0179 35.8556 13.9648 35.7274 13.9648 35.5938V30.2188C13.9648 30.0851 14.0179 29.9569 14.1124 29.8624C14.2069 29.7679 14.3351 29.7148 14.4688 29.7148C14.6024 29.7148 14.7306 29.7679 14.8251 29.8624C14.9196 29.9569 14.9727 30.0851 14.9727 30.2188V35.5938C14.9727 35.7274 14.9196 35.8556 14.8251 35.9501C14.7306 36.0446 14.6024 36.0977 14.4688 36.0977Z'
          fill='#FFDB43'
        />
        <path
          d='M17.1562 36.0977C17.0226 36.0977 16.8944 36.0446 16.7999 35.9501C16.7054 35.8556 16.6523 35.7274 16.6523 35.5938V30.2188C16.6523 30.0851 16.7054 29.9569 16.7999 29.8624C16.8944 29.7679 17.0226 29.7148 17.1562 29.7148C17.2899 29.7148 17.4181 29.7679 17.5126 29.8624C17.6071 29.9569 17.6602 30.0851 17.6602 30.2188V35.5938C17.6602 35.7274 17.6071 35.8556 17.5126 35.9501C17.4181 36.0446 17.2899 36.0977 17.1562 36.0977Z'
          fill='#FFDB43'
        />
        <path
          d='M19.8438 36.0977C19.7101 36.0977 19.5819 36.0446 19.4874 35.9501C19.3929 35.8556 19.3398 35.7274 19.3398 35.5938V30.2188C19.3398 30.0851 19.3929 29.9569 19.4874 29.8624C19.5819 29.7679 19.7101 29.7148 19.8438 29.7148C19.9774 29.7148 20.1056 29.7679 20.2001 29.8624C20.2946 29.9569 20.3477 30.0851 20.3477 30.2188V35.5938C20.3477 35.7274 20.2946 35.8556 20.2001 35.9501C20.1056 36.0446 19.9774 36.0977 19.8438 36.0977Z'
          fill='#FFDB43'
        />
        <path
          d='M22.5312 36.0977C22.3976 36.0977 22.2694 36.0446 22.1749 35.9501C22.0804 35.8556 22.0273 35.7274 22.0273 35.5938V30.2188C22.0273 30.0851 22.0804 29.9569 22.1749 29.8624C22.2694 29.7679 22.3976 29.7148 22.5312 29.7148C22.6649 29.7148 22.7931 29.7679 22.8876 29.8624C22.9821 29.9569 23.0352 30.0851 23.0352 30.2188V35.5938C23.0352 35.7274 22.9821 35.8556 22.8876 35.9501C22.7931 36.0446 22.6649 36.0977 22.5312 36.0977Z'
          fill='#FFDB43'
        />
        <path
          d='M25.2188 36.0977C25.0851 36.0977 24.9569 36.0446 24.8624 35.9501C24.7679 35.8556 24.7148 35.7274 24.7148 35.5938V30.2188C24.7148 30.0851 24.7679 29.9569 24.8624 29.8624C24.9569 29.7679 25.0851 29.7148 25.2188 29.7148C25.3524 29.7148 25.4806 29.7679 25.5751 29.8624C25.6696 29.9569 25.7227 30.0851 25.7227 30.2188V35.5938C25.7227 35.7274 25.6696 35.8556 25.5751 35.9501C25.4806 36.0446 25.3524 36.0977 25.2188 36.0977Z'
          fill='#FFDB43'
        />
      </g>
      <defs>
        <clipPath id='clip0_417_18682'>
          <rect width='66' height='66' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
}
