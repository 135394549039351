import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { RiAddFill } from 'react-icons/ri';

interface AddActionButtonProps {
  key?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: auto;
  svg {
    color: #564d6d;
    width: 18px;
    height: 18px;
  }
`;

export default function AddActionButton({ key, onClick }: AddActionButtonProps): ReactElement {
  return (
    <Wrapper data-cy='tags-details-add' key={key} onClick={onClick}>
      <RiAddFill />
    </Wrapper>
  );
}
