import CallWidget from './CallWidget';
import { CallWidgetProvider } from './CallWidgetProvider';
import { TouchToneProvider } from './touch-tone/TouchToneProvider';

export default function DialerWidget() {
  return (
    <CallWidgetProvider>
      <TouchToneProvider>
        <CallWidget />
      </TouchToneProvider>
    </CallWidgetProvider>
  );
}
