import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_DEFAULT_CARD } from 'graphql/payments';

export function useDefaultCardQuery() {
  const [loadCards, { data: cardsData }] = useLazyQuery(GET_DEFAULT_CARD, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const defaultPaymentCard = useMemo(() => cardsData?.getDefaultCard?.data ?? {}, [cardsData]);

  return {
    defaultPaymentCard,
  };
}
