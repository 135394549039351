/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { produce } from 'immer';
import { RiCloseLine } from 'react-icons/ri';
import { useEffect, useRef, useContext, ReactElement } from 'react';
import { useReactiveVar, useLazyQuery } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import { MemberEdge, MemberNode, Query } from 'generated/graphql';
import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { Icon } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';
import { sortOnlineMembers } from 'components/utils/sortOnlineMembers';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import { queryParamVars, LIMIT } from './useAgentVars';
import * as S from './Styles';
import AgentList from './AgentList';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';

interface IAgentTransferProps {
  toggleExternalNumber: () => void;
  handleClose: () => void;
}

export default function AgentTransfer({
  handleClose,
  toggleExternalNumber,
}: IAgentTransferProps): ReactElement {
  const { loggedInMemberId } = useContext(AuthContext);
  const {
    state: { connection, direction },
  } = useTwilioContext();
  const { handleCallTransfer } = useCallWidgetContext();
  const agentParams = useReactiveVar<any>(queryParamVars);

  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, entry] = useIntersectionObserver({
    elementRef,
  });
  const targetElement = entry?.target;

  const [loadWorkspaceMembers, { data: query, fetchMore }] = useLazyQuery(WORKSPACE_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    loadWorkspaceMembers({
      variables: {
        pageParams: agentParams,
      },
    });
  }, [agentParams, loadWorkspaceMembers]);

  const handleOptionSelection = (member: MemberNode) => {
    const payload = {
      destination: member.id,
      callerId:
        direction === 'Incoming'
          ? connection?.parameters?.From
          : connection?.customParameters?.get('To'),
      direction,
      externalNumber: false,
    };
    const transferredTo = `${member.firstname} ${member.lastname}`;
    handleCallTransfer?.(payload, transferredTo);
    handleClose();
  };

  const { edges = [] } = query?.allWorkspaceMembers?.data || {};
  const agents = edges.filter(({ node }: MemberEdge) => {
    const { id, online } = node || {};
    return id !== loggedInMemberId && online;
  });
  const memberMap = agents.map((member: MemberEdge) => {
    return member.node;
  });

  const sortedMembers = sortOnlineMembers(memberMap);

  const endCursor = query?.allWorkspaceMembers?.data?.pageInfo?.endCursor;
  const hasNextPage = query?.allWorkspaceMembers?.data?.pageInfo?.hasNextPage;

  useEffect(() => {
    if (isVisible) {
      if (targetElement instanceof HTMLElement && hasNextPage) {
        targetElement?.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isVisible, targetElement]);

  const onLoadMore = async () => {
    if (fetchMore && endCursor) {
      await fetchMore({
        variables: {
          pageParams: {
            first: LIMIT,
            after: endCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          const members = fetchMoreResult.allWorkspaceMembers.data;
          return produce(prev, (draft: Pick<Query, 'allWorkspaceMembers'>) => {
            draft.allWorkspaceMembers.data = {
              pageInfo: members.pageInfo,
              edges: draft?.allWorkspaceMembers?.data?.edges?.concat(members.edges),
              // eslint-disable-next-line no-underscore-dangle
              __typename: draft?.allWorkspaceMembers?.data?.__typename,
            };
          });
        },
      });
    }
  };

  const handleCloseBtnClick = () => {
    handleClose();
  };

  return (
    <S.Container>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-left pl-20 '>
          Transfer Call
        </p>
        <button
          type='button'
          className='external-link text-info font-manrope text-xs leading-3 font-semibold flex items-center'
          onClick={toggleExternalNumber}
        >
          <span>External</span>
          <span>
            <Icon name='external-link' className='text-info ml-1.5' />
          </span>
        </button>
      </div>

      <>
        <S.SpacingScrollbar className='position-relative text-left'>
          <AgentList
            agents={sortedMembers}
            onLoadMore={onLoadMore}
            elementRef={elementRef}
            handleOptionSelection={handleOptionSelection}
          />
        </S.SpacingScrollbar>
      </>
    </S.Container>
  );
}
