export default function Doticon({ isActive }: { isActive?: boolean }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'>
      <circle
        cx='7'
        cy='7'
        r='5.75'
        fill={`${isActive ? '#3BA55D' : '#B7B3C1'}`}
        stroke='#fff'
        strokeWidth='1.5'
      />
    </svg>
  );
}
