import { createContext } from 'react';

import { useActiveCallWidgetHandler } from '../hooks/useActiveCallWidgetHandler';

export const ActiveCallWidgetContext = createContext({});

export const ActiveCallWidgetProvider = ({ children }: any) => {
  const contextValue = useActiveCallWidgetHandler();

  return (
    <ActiveCallWidgetContext.Provider value={contextValue}>
      {children}
    </ActiveCallWidgetContext.Provider>
  );
};
