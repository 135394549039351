import { cache } from 'services/apollo';
import useLocalStorage from './useLocalStorage';

const useUpdateCache = () => {
  const [activeWorkspaceId] = useLocalStorage('activeWorkspaceId', null);

  const updateCreditCache = (creditAmount: number) => {
    cache.modify({
      id: cache.identify({ id: activeWorkspaceId, __typename: 'Workspace' }),
      fields: {
        plan(existingPlanRef) {
          const updatedPlan = {
            ...existingPlanRef,
            currentCredit: creditAmount,
          };

          return updatedPlan;
        },
      },
    });
  };
  return {
    updateCreditCache,
  };
};

export default useUpdateCache;
