import { lazy } from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from 'react-icons/ri';

import { SidebarWrapper } from 'components/atoms';
import { AddMemberModal } from 'components/organisms';
import { usePrivilege, privilegeList } from 'hooks/usePrivilege';
import InvitationSentModal from 'components/organisms/member/add-member/InvitationSentModal';
import { TeamForm } from 'components/organisms/team';
import { NumberSelectModal } from 'components/organisms/numbers';
import TopNav from './TopNav';
import useWorkspaceBar from './useWorkspaceBar';
import WorkspaceBarHeader from './WorkspaceBarHeader';
import * as S from './Styles';

const Numbers = lazy(() => import('./numbers'));
const Teams = lazy(() => import('./teams'));
const Members = lazy(() => import('./members'));

const { Panel } = Collapse;

export default function SideWorkspaceBar() {
  const [accessTeamsAddNew] = usePrivilege(
    'main_screen',
    'navigation',
    privilegeList.main_screen.navigation.teams_add_new,
  );
  const [accessMembersAddNew] = usePrivilege(
    'main_screen',
    'navigation',
    privilegeList.main_screen.navigation.members_add_new,
  );
  const [accessNumbersAddNew] = usePrivilege(
    'main_screen',
    'navigation',
    privilegeList.main_screen.navigation.numbers_add_new,
  );
  const { t } = useTranslation();

  // eslint-disable-next-line prettier/prettier
  const {
    addAction,
    openTeam,
    toggleTeamModal,
    openMember,
    toggleMemberModal,
    visibleInvitationSentModal,
    toggleInvitationSentModal,
    openInvitationSentModal,
    invitedMemberEmail,
    openNumberSelect,
    toggleNumberSelectModal,
  } = useWorkspaceBar();

  return (
    <SidebarWrapper>
      <div className='h-full content-wrapper border-0 border-r border-gray-100'>
        <WorkspaceBarHeader />
        <S.WorkspaceBodyScrollbar>
          <TopNav />
          <div className='px-3 pb-5'>
            <Collapse
              className='sidebar-collapse'
              defaultActiveKey={['numbers', 'members', 'teams', 'tags']}
              expandIcon={({ isActive }) => (
                <RiArrowDownSLine className={`rotate--${isActive ? '0' : '90'}`} />
              )}
              ghost
            >
              <Panel
                forceRender
                header={
                  <h6 data-cy='numbers-purchase'>{t('workspace.sidebar.numbers', 'NUMBERS')}</h6>
                }
                key='numbers'
                extra={addAction(
                  t('purchaseNumber', 'Purchase Number'),
                  'numbers',
                  accessNumbersAddNew,
                )}
              >
                <Numbers openNumberSelectModal={toggleNumberSelectModal} />
              </Panel>

              <Panel
                forceRender
                header={<h6>{t('workspace.sidebar.members', 'MEMBERS')}</h6>}
                key='members'
                extra={addAction(t('addMember', 'Add Member'), 'members', accessMembersAddNew)}
              >
                <Members />
              </Panel>
              <Panel
                forceRender
                header={<h6>{t('workspace.sidebar.teams', 'TEAMS')}</h6>}
                key='teams'
                extra={addAction(t('addTeam', 'Add Team'), 'team', accessTeamsAddNew)}
              >
                <Teams />
              </Panel>
            </Collapse>
          </div>

          <TeamForm toogleAddTeamModal={toggleTeamModal} visibleAddTeamModal={openTeam} />

          <AddMemberModal
            isModalOpen={openMember}
            onModalCancel={toggleMemberModal}
            openInvitationSentModal={openInvitationSentModal}
          />
          <NumberSelectModal
            isModalOpen={openNumberSelect}
            onModalCancel={toggleNumberSelectModal}
          />
          <InvitationSentModal
            isModalOpen={visibleInvitationSentModal}
            onModalCancel={toggleInvitationSentModal}
            openAddMemberModal={toggleMemberModal}
            email={invitedMemberEmail}
          />
        </S.WorkspaceBodyScrollbar>
      </div>
    </SidebarWrapper>
  );
}
