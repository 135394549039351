import styled from 'styled-components';

const InputFieldWrapper = styled.div`
  width: 100%;
  .ant-input,
  .ant-input:not(textarea),
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-input-lg {
    height: 44px;
  }

  .react-tel-input {
    & > .flag {
      transform: scale(1.13) translateY(-50%);
      margin-top: 0;
    }
    .flag-dropdown.open .selected-flag {
      background: transparent;
    }
    .country-list {
      background: #ffffff;
      border: 1px solid #e7e6eb;
      box-sizing: border-box;
      box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
      border-radius: 8px;
      padding-bottom: 10px;
      .country {
        padding: 9px 20px !important;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #564d6d;
        &.highlight,
        &:hover {
          background: #f5f2f8 !important;
        }
        .country-name {
          font-family: Manrope;
        }
      }
      .search {
        padding: 21px 20px 11px 20px;
      }
      .flag {
        margin-right: 10px !important;
      }
      .search-box {
        border: none;
        padding: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #9e99ab;
      }
    }
  }
  .ant-input-affix-wrapper {
    padding: 0;
    height: 40px !important;
    margin: 0;
    &:hover {
      border-color: ${({ theme }) => theme.colors.primary.p2};
    }
    .ant-input {
      margin: 0 !important;
      background: inherit;
      height: 100% !important;
      border: none;
      outline: none;
      padding-left: 18px;
    }

    .ant-input-suffix {
      margin-right: 18px; /* same as ant-input padding  */
    }
  }
  .ant-input-password {
    .ant-input-password-icon {
      cursor: pointer;
    }
  }

  .warning-error {
    background: ${({ theme }) => theme.colors.warning.w0} !important;
    border-color: ${({ theme }) => theme.colors.warning.default};

    & + span {
      color: ${({ theme }) => theme.colors.warning.default};
    }
    svg {
      color: ${({ theme }) => theme.colors.warning.default};
    }
  }

  .danger-error {
    background: ${({ theme }) => theme.colors.danger.d0} !important;
    border-color: ${({ theme }) => theme.colors.danger.default};

    & + span {
      color: ${({ theme }) => theme.colors.danger.default};
    }
    svg {
      color: ${({ theme }) => theme.colors.danger.default};
    }
  }
  &.field-error {
    label {
      + input,
      + span {
        border: 1px solid #db312b;
        background: none;
        &:hover {
          border-color: #db312b !important;
        }
      }
    }
  }

  &.field-disabled {
    label {
      opacity: 0.5;
    }

    .ant-input-disabled {
      color: unset;
      background: #f5f2f8;
      opacity: 0.5;
    }
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneFieldStyles = styled.div`
  /* phone field input in pop ups */
  .request-portability-phone,
  .add-new-contact-phone {
    &.react-tel-input {
      .selected-flag,
      .flag-dropdown.open .selected-flag {
        width: 36px;
        padding: 0 0 0 17px;
        border-radius: 8px 0 0 8px;
        .flag {
          border-radius: 2px;
          transform: scale(1.125); /* to gain width 18px */
          &.np {
            border-radius: 0px;
          }
        }
      }
      .arrow {
        left: 25px;
        background-image: url(/static-icons/arrow-down-black.svg);
        border: none;
        width: 13px;
        height: 13px;
        background-size: cover;
        top: 1px;
        transition: all 0.1s ease-in;
        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }
  .add-new-contact-phone {
    .country-list {
      width: 382px; /* equal to field width */
    }
  }
  .request-portability-phone {
    .country-list {
      width: 374px; /* equal to field width */
    }
  }
`;

export { InputFieldWrapper, FlexColumn, PhoneFieldStyles };
