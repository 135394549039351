import { Button } from 'antd';
import { Icon } from 'components/atoms';

import * as S from './Styles';
import { keyPadButtons, KeyPadButtonType } from '../../constants';

interface IKeypadProps {
  handleDialpadClick: (key: string, tone: number[]) => void;
}

export function Keypad({ handleDialpadClick }: IKeypadProps) {
  return (
    <S.KeypadContainer>
      <S.Keypad data-cy='key-pad'>
        {keyPadButtons.map((char: KeyPadButtonType) => (
          <Button
            data-cy={`${char.digit}+num`}
            key={char.digit}
            onClick={() => handleDialpadClick(char.digit, char.tone)}
          >
            <div className='flex flex-col items-center justify-center'>
              {char.icon ? (
                <Icon name={char.icon} />
              ) : (
                <>
                  <div className='text-20 leading-6 text-gray-800 font-semibold font-manrope tracking-widest'>
                    {char.digit}
                  </div>
                  <div className='text-xs leading-3 text-gray-500 font-normal tracking-widest uppercase mt-1'>
                    {char.alphabet || char.symbol}
                  </div>
                </>
              )}
            </div>
          </Button>
        ))}
      </S.Keypad>
    </S.KeypadContainer>
  );
}
