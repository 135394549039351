import { useContext } from 'react';

import { PhoneInputContext } from '../context/PhoneInputContext';
import { PhoneInputContextType } from '../types';

export default function usePhoneInputContext() {
  const phoneInputState = useContext<PhoneInputContextType | undefined>(PhoneInputContext);
  if (!phoneInputState) {
    throw new Error('usePhoneInputContext must be used within a PhoneInputProvider');
  }
  return phoneInputState;
}
