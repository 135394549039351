import React from 'react';
import styled from 'styled-components';

const Progressbar = styled.div`
  .shim-krisp {
    position: relative;
    overflow: hidden;
    background-color: rgba(57, 1, 121, 0.7);
  }
  .shim-krisp::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(233, 233, 233, 1) 0,
      rgba(233, 233, 233, 0.9) 50%,
      rgba(233, 233, 233, 0.8) 100%
    );
    animation: shimmer 2.5s ease-out infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(0%);
      opacity: 0;
    }
  }
`;

function LinearIndeterminate() {
  return (
    <Progressbar className='absolute top-0 w-full bg-gray-200 z-10'>
      <div className='w-full h-1  shim-krisp' />
    </Progressbar>
  );
}
export default React.memo(LinearIndeterminate);
