import { useLazyQuery } from '@apollo/client';
import { FETCH_SUPPORT_AUTH_DATA } from 'graphql/authorization/query';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Menu } from 'antd';
import { FiUser } from 'react-icons/fi';
import { FcCustomerSupport } from 'react-icons/fc';

import * as path from 'constants/routes';
import useLocalStorage from 'hooks/useLocalStorage';
import { usePrivilegeSupport, privilegeListSupport } from 'hooks/usePrivilegeSupport';

import { KrispcallVersionMark } from 'components/molecules/krispcall-version';
import { ENABLE_NON_VOIP_FEATURE } from 'constants/featureFlags';
import { SupportMenuItem, SupportSidebarWrapper } from './components';
import * as S from './Styles';

const { SubMenu } = Menu;

export default function SupportSidebar() {
  const [fetchSupportAuthData] = useLazyQuery(FETCH_SUPPORT_AUTH_DATA, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchSupportAuthData({});
  }, [fetchSupportAuthData]);

  const [hasAccountReviewAccess] = usePrivilegeSupport(
    'support',
    'navbar',
    privilegeListSupport.support.navbar.account_review,
  );
  const location = useLocation();

  const [tokens] = useLocalStorage('_tokens', '');
  const accessToken = tokens?.accessToken;
  const accessTokenDecoded = accessToken && jwtDecode<JwtPayload>(accessToken);
  const ROLE: string = accessTokenDecoded?.user_claims?.role;
  const isDeveloper = ROLE === 'Developer';

  const isSupportTicket = /^\/support-ticket\/*/.test(location.pathname);
  const isPurchaseActive = /^\/support-purchase\/*/.test(location.pathname);
  const isNonVoipPurchaseActive = /^\/support-non-voip-purchase\/*/.test(location.pathname);
  const isPortingNavActive = /^\/support-porting\/*/.test(location.pathname);
  const isPlanNavActive = /^\/support-plan\/*/.test(location.pathname);
  const isFeedbacksNavActive = /^\/support-feedbacks\/*/.test(location.pathname);
  const isWorkspacesNavActive = /^\/support-workspaces\/*/.test(location.pathname);
  const isCancelWorkspacesNavActive = /^\/support-cancel-workspaces\/*/.test(location.pathname);
  const isDeletedWorkspacesNavActive = /^\/support-deleted-workspaces\/*/.test(location.pathname);
  const isUsersNavActive = /^\/support-users\/*/.test(location.pathname);
  const isReviewNavActive = /^\/support-account-review\/*/.test(location.pathname);
  const isEmailNavActive = /^\/support-email-verification\/*/.test(location.pathname);
  const isPlanSetup = /^\/update-plan\/*/.test(location.pathname);
  const isAuthSetup = /^\/update-auth\/*/.test(location.pathname);
  const isSupportAuthSetup = /^\/update-support-auth\/*/.test(location.pathname);
  const isAppsSetup = /^\/setup-apps\/*/.test(location.pathname);
  const isSignupNavActive = /^\/support-incomplete-signup\/*/.test(location.pathname);
  const isWorkspaceCredit = /^\/support-workspace-credit\/*/.test(location.pathname);
  const isNumberRates = /^\/support-number-rates\/*/.test(location.pathname);
  const isWorkspaceSpam = /^\/support-workspace-spam\/*/.test(location.pathname);
  const isAuditLog = /^\/audit-log\/*/.test(location.pathname);
  const isCompleteSignupNavActive = /^\/support-complete-signup\/*/.test(location.pathname);

  if (isDeveloper) {
    return (
      <SupportSidebarWrapper>
        <div className='h-full flex flex-col px-2.5 py-6'>
          <S.SidebarTitle>Developer Portal</S.SidebarTitle>

          <S.SidebarMenu mode='inline' defaultSelectedKeys={['1']} defaultOpenKeys={['user']}>
            <SubMenu
              key='user'
              title={
                <div className='flex items-center gap-2'>
                  <FiUser size={18} />
                  <span>User</span>
                </div>
              }
            >
              <Menu.Item>
                <NavLink className={isPlanSetup ? 'menu--active' : ''} to={`${path.PLANSETUP}`}>
                  <SupportMenuItem icon='support_plan' name='Plan Setup' />
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink className={isAuthSetup ? 'menu--active' : ''} to={`${path.AUTHORIZATION}`}>
                  <SupportMenuItem icon='settings' name='Permissions' />
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key='support'
              title={
                <div className='flex items-center gap-2'>
                  <FcCustomerSupport size={18} />
                  <span>Support</span>
                </div>
              }
            >
              <Menu.Item>
                <NavLink
                  className={isSupportAuthSetup ? 'menu--active' : ''}
                  to={`${path.SUPPORT_AUTHORIZATION}`}
                >
                  <SupportMenuItem icon='settings' name='Permissions' />
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <NavLink className={isAppsSetup ? 'menu--active' : ''} to={`${path.SETUP_APPS}`}>
              <SupportMenuItem icon='support_porting' name='Apps Setup' />
            </NavLink>
            <NavLink
              className={isWorkspaceCredit ? 'menu--active' : ''}
              to={`${path.WORKSPACE_CREDIT}`}
            >
              <SupportMenuItem icon='support_purchase' name='Workspace Credit' />
            </NavLink>
            <NavLink className={isNumberRates ? 'menu--active' : ''} to={`${path.NUMBER_RATES}`}>
              <SupportMenuItem icon='pay' name='Number Rates' />
            </NavLink>
          </S.SidebarMenu>
          <KrispcallVersionMark className='mt-auto ml-2 ' />
        </div>
      </SupportSidebarWrapper>
    );
  }

  return (
    <SupportSidebarWrapper>
      <div className='h-full flex flex-col px-2.5 py-6'>
        <S.SidebarTitle>
          Customer Support <br /> <span className='text-sm font-normal'>({ROLE})</span>
        </S.SidebarTitle>

        <NavLink
          className={isPurchaseActive ? 'menu--active' : ''}
          activeClassName='menu--active'
          to={`${path.PURCHASE}`}
        >
          <SupportMenuItem icon='support_purchase' name='Number Purchase' />
        </NavLink>
        {ENABLE_NON_VOIP_FEATURE && (
          <NavLink
            className={isNonVoipPurchaseActive ? 'menu--active' : ''}
            activeClassName='menu--active'
            to={`${path.NON_VOIP_PURCHASE}`}
          >
            <SupportMenuItem icon='support_purchase' name='Non-Voip Purchase' />
          </NavLink>
        )}
        <NavLink
          className={isPortingNavActive ? 'menu--active' : ''}
          activeClassName='menu--active'
          to={`${path.PORTING}`}
        >
          <SupportMenuItem icon='support_porting' name='Number Porting' />
        </NavLink>
        <NavLink className={isPlanNavActive ? 'menu--active' : ''} to={`${path.PLAN}`}>
          <SupportMenuItem icon='support_plan' name='Enterprise Plan' />
        </NavLink>
        <NavLink className={isFeedbacksNavActive ? 'menu--active' : ''} to={`${path.FEEDBACKS}`}>
          <SupportMenuItem icon='bubble-message2' name='Feedbacks' />
        </NavLink>
        <NavLink className={isWorkspacesNavActive ? 'menu--active' : ''} to={`${path.WORKSPACES}`}>
          <SupportMenuItem icon='settings' name='Workspaces' />
        </NavLink>
        <NavLink
          className={isDeletedWorkspacesNavActive ? 'menu--active' : ''}
          to={`${path.DELETED_WORKSPACES}`}
        >
          <SupportMenuItem icon='settings' name='Deleted Workspaces' />
        </NavLink>
        <NavLink
          className={isCancelWorkspacesNavActive ? 'menu--active' : ''}
          to={`${path.CANCELLED_WORKSPACES}`}
        >
          <SupportMenuItem icon='settings' name='Cancelled Workspaces' />
        </NavLink>
        <NavLink className={isUsersNavActive ? 'menu--active' : ''} to={`${path.USERS}`}>
          <SupportMenuItem icon='support_caller-id' name='Users' />
        </NavLink>
        {hasAccountReviewAccess && (
          <NavLink
            className={isReviewNavActive ? 'menu--active' : ''}
            to={`${path.ACCOUNT_REVIEW}`}
          >
            <SupportMenuItem icon='support_review' name='Account Review' />
          </NavLink>
        )}
        <NavLink
          className={isEmailNavActive ? 'menu--active' : ''}
          to={`${path.EMAIL_VERIFICATION}`}
        >
          <SupportMenuItem icon='mail-unread-line' name='Email Verification' />
        </NavLink>
        <NavLink
          className={isSignupNavActive ? 'menu--active' : ''}
          to={`${path.INCOMPLETE_SIGNUP}`}
        >
          <SupportMenuItem icon='support_caller-id' name='Incomplete Signup' />
        </NavLink>
        <NavLink
          className={isCompleteSignupNavActive ? 'menu--active' : ''}
          to={`${path.COMPLETE_SIGNUP}`}
        >
          <SupportMenuItem icon='support_caller-id' name='Workspace Usecases' />
        </NavLink>
        <NavLink className={isWorkspaceSpam ? 'menu--active' : ''} to={`${path.WORKSPACE_SPAM}`}>
          <SupportMenuItem icon='warning-line' name='Spam' />
        </NavLink>
        <NavLink className={isAuditLog ? 'menu--active' : ''} to={`${path.AUDIT_LOG}`}>
          <SupportMenuItem icon='support_plan' name='Audit log' />
        </NavLink>
        <KrispcallVersionMark className='mt-auto ml-2 ' />
      </div>
    </SupportSidebarWrapper>
  );
}
