import { hexToStringForTags } from 'components/utils/hexToStringForTags';
import styled from 'styled-components';

const TagStyled = styled.div`
  font-family: Heebo;
  font-weight: normal;
  width: fit-content;
`;

interface ITag {
  colorCode?: string;
  title?: string;
  className?: string;
}

export const Tag = ({ colorCode, title, className }: ITag) => {
  return (
    <TagStyled
      className={`text-gray-600 text-12.5 leading-3.5 py-1.25 px-2.5 bg-tags-${
        colorCode && hexToStringForTags(colorCode)
      } rounded-5 ${className}`}
    >
      {title}
    </TagStyled>
  );
};
