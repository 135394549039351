import { useEffect, useState, useCallback, FunctionComponent } from 'react';
import { produce } from 'immer';
import styled from 'styled-components';

const Counter = styled.div`
  display: flex;
  align-items: center;
  font-size: 8px;
`;
const Circle = styled.circle`
  fill: none;
  stroke-width: 2.5;
  &#gray {
    stroke: #d7cce4;
  }
`;

interface Props {
  charCount: number;
}

interface State {
  stroke: string;
  strokeDasharray: string;
}
const getStrokeColor = (count: number) => {
  const LT_EQ_TO_0: any = count <= 0;
  const GT_0_AND_LT_EQ_TO_15: any = count > 0 && count <= 15;
  const GT_15: any = count > 15;
  return {
    [`${LT_EQ_TO_0}`]: 'red',
    [`${GT_0_AND_LT_EQ_TO_15}`]: '#E07408',
    [`${GT_15}`]: '#613494',
  }.true;
};
const RADIUS = 8;

const CounterCircle: FunctionComponent<Props> = ({ charCount }) => {
  const COUNT_THRESHOLD = 160;
  const [circleStyle, setCircleStyle] = useState<State>({ stroke: '', strokeDasharray: '' });
  const changeCircleStyle = () => {
    const remainingCount = COUNT_THRESHOLD - charCount;
    const circleLength = 2 * Math.PI * RADIUS;
    const colored = (circleLength * charCount) / COUNT_THRESHOLD;
    const gray = circleLength - colored > 0 ? circleLength - colored : 0;
    setCircleStyle(
      produce((draft: State) => {
        draft.stroke = getStrokeColor(remainingCount);
        draft.strokeDasharray = `${colored}  ${gray}`;
      }),
    );
  };

  const memoizedChangeCircleStyle = useCallback(changeCircleStyle, [charCount]);

  useEffect(() => {
    memoizedChangeCircleStyle();
  }, [memoizedChangeCircleStyle]);

  return (
    <Counter>
      <svg width='20px' height='20px'>
        <Circle id='gray' cx='50%' cy='50%' r={RADIUS} />
        <Circle id='colored' cx='50%' cy='50%' r={RADIUS} style={circleStyle} />
        {charCount >= 145 && (
          <text x='50%' y='50%' textAnchor='middle' fill='#E07408' dy='.3em'>
            <tspan x='50%' dy='.35em'>
              {COUNT_THRESHOLD - charCount}
            </tspan>
          </text>
        )}
      </svg>
    </Counter>
  );
};

export default CounterCircle;
