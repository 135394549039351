import styled from 'styled-components';
import { Menu, Dropdown } from 'antd';

export const DropdownOption = styled.div`
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.text.t7};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  .placeholder {
    color: ${({ theme }) => theme.colors.text.t3};
    font-family: ${({ theme }) => theme.text.font.heading};
    font-weight: ${({ theme }) => theme.text.weight.regular};
  }
  &.selected {
    height: 40px;
    padding: 9px 16px;
    background: ${({ theme }) => theme.colors.primary.p0};
  }
  img {
    width: 20px;
    height: 14px;
    border-radius: 2px;
    margin-right: 10px;
    [alt='NP'] {
      object-fit: contain;
    }
  }
  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.text.t4};
    vertical-align: middle;
  }
  .country-label {
    display: inline-block;
    max-width: 200px;
    vertical-align: middle;
    color: #564d6d !important;
  }
`;

export const DropdownContainer = styled(Dropdown)`
  h4 {
    all: unset;
    font-weight: ${({ theme }) => theme.text.weight.bold};
    color: ${({ theme }) => theme.colors.text.t7} !important;
    & + div {
      margin-top: 2px;
    }
  }
  img {
    width: 16px;
    height: 11px;
    border-radius: 2px;
  }

  img[alt='NP'] {
    object-fit: contain;
  }
  .anticon.anticon-down {
    color: #fff;
    font-size: 14px;
  }
  .ant-dropdown {
    top: 246px !important;
    left: -21px !important;
  }
`;
