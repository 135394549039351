import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { memo, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { isEmptyObject } from 'components/utils/helpers';
import * as path from 'constants/routes';
import { MenuItem } from 'components/atoms';
import { SETTINGS, PROFILE } from 'constants/routes';
import { AuthContext } from 'contexts/auth/AuthContext';
import { useChatBot } from 'hooks/useChatBot';

import { UpgradeModal } from 'components/organisms/workspace/modals';
import { NewTag, TopNavLink } from './Styles';

function TopNav() {
  const { t } = useTranslation();
  const { openChat, updateUserWorkspaceDetails } = useChatBot();
  const history = useHistory();
  const { isSubscriptionExpired, loggedInMemberInfo, accessToken } = useContext(AuthContext);
  const allowedPlanForBulkSms = ['Enterprise', 'Standard'];
  const activePlan = loggedInMemberInfo?.plan?.planDetail?.title ?? 'Essential';
  const isActivePlanAllowed = allowedPlanForBulkSms.includes(activePlan);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const accessTokenDecoded = accessToken && jwtDecode<any>(accessToken);
  const role: string = accessTokenDecoded?.user_claims?.role;
  const isMember = role === 'Member';
  const toggleUpgradeModal = () => setOpenUpgradeModal(prev => !prev);

  const getRedirectLink = (menu: string) => {
    if (isSubscriptionExpired) {
      return `${path.SETTINGS}${path?.PLAN_SUBS}`;
    }
    return menu === 'bulk-sms' ? path.BULK_SMS : path.SALES_DIALER;
  };
  const handleUpgradeModal = () => {
    if (isMember) {
      return toggleUpgradeModal();
    }
    if (!isActivePlanAllowed) {
      return history.push(path.CHOOSE_PLAN);
    }
    return openChat();
  };
  // to set active background on Settings if route starts with /settings/
  const locationUrl = useLocation();
  const isDashboardNavActive = /^\/dashboard\/*/.test(locationUrl.pathname);
  const isSettingsNavActive = /^\/settings\/*/.test(locationUrl.pathname);
  const isContactsNavActive = /^\/contacts\/*/.test(locationUrl.pathname);
  const isDownloadNavActive = /^\/download-apps\/*/.test(locationUrl.pathname);
  const isSalesDialerNavActive = /^\/sales-dialer\/*/.test(locationUrl.pathname);

  const isBulkSMSActive = /^\/bulk-sms\/*/.test(locationUrl.pathname);

  const betaPowerdialer = process.env.REACT_APP_POWER_DIALER_BETA;

  useEffect(() => {
    if (!isEmptyObject(loggedInMemberInfo))
      updateUserWorkspaceDetails({
        workspaceName: loggedInMemberInfo?.title ?? '',
        workspaceStatus: loggedInMemberInfo?.status ?? '',
        workspacePlan: loggedInMemberInfo?.plan?.planDetail?.title ?? '',
        subscriptionStatus: loggedInMemberInfo?.plan?.subscriptionActive ?? '',
      });
  }, [loggedInMemberInfo, updateUserWorkspaceDetails]);

  return (
    <>
      <div className='px-3 pt-4'>
        <TopNavLink
          className={isDashboardNavActive ? 'menu--active' : ''}
          activeClassName='menu--active'
          to={isSubscriptionExpired ? `${path.SETTINGS}${path?.PLAN_SUBS}` : `${path.DASHBOARD}`}
          isActive={(match, location) => {
            if (!location) {
              return false;
            }
            return (match?.isExact as boolean) && location.pathname === `${path.DASHBOARD}`;
          }}
        >
          <MenuItem icon='dashboard' name={t('workspace.sidebar.dashboard', 'Dashboard')} />
        </TopNavLink>
        {/* TODO: HIDE CONTACTS FOR MVP USERS */}
        {!process.env.REACT_APP_HIDE_CONTACT && (
          <TopNavLink
            className={isContactsNavActive ? 'menu--active' : ''}
            activeClassName='menu--active'
            to={isSubscriptionExpired ? `${path.SETTINGS}${path?.PLAN_SUBS}` : `${path.CONTACTS}`}
            isActive={(match, location) => {
              if (!location) {
                return false;
              }
              return (match?.isExact as boolean) && location.pathname === `${path.CONTACTS}`;
            }}
          >
            <MenuItem icon='contact' name={t('workspace.sidebar.contacts', 'Contacts')} />
          </TopNavLink>
        )}
        {/* TODO: HIDE POWER DIALER IN PRODUCTION AND MVP */}
        {!process.env.REACT_APP_HIDE_POWER_DIALER && (
          <TopNavLink
            className={isSalesDialerNavActive ? 'menu--active' : ''}
            activeClassName='menu--active'
            to={getRedirectLink('sales-dialer')}
            isActive={(match, location) => {
              if (!location) {
                return false;
              }
              return (match?.isExact as boolean) && location.pathname === `${path.SALES_DIALER}`;
            }}
          >
            <MenuItem icon='phone-line' name={t('workspace.sidebar.salesDialer', 'Power Dialer')}>
              {betaPowerdialer && (
                <span className='bg-gray-200 text-gray-600 text-10 rounded ml-1 px-1.5 font-medium leading-4'>
                  Beta
                </span>
              )}
              <NewTag>New</NewTag>
            </MenuItem>
          </TopNavLink>
        )}
        {!process.env.REACT_APP_HIDE_BULK_SMS && (
          <TopNavLink
            className={isBulkSMSActive ? 'menu--active' : ''}
            activeClassName='menu--active'
            to={getRedirectLink('bulk-sms')}
            isActive={(match, location) => {
              if (!location) {
                return false;
              }
              return (match?.isExact as boolean) && location.pathname === `${path.BULK_SMS}`;
            }}
          >
            <MenuItem icon='bulk-sms' name={t('workspace.sidebar.bulkSMS', 'Bulk SMS')}>
              <NewTag>New</NewTag>
            </MenuItem>
          </TopNavLink>
        )}
        <TopNavLink
          className={isDownloadNavActive ? 'menu--active' : ''}
          activeClassName='menu--active'
          to={
            isSubscriptionExpired ? `${path.SETTINGS}${path?.PLAN_SUBS}` : `${path.DOWNLOAD_APPS}`
          }
          isActive={(match, location) => {
            if (!location) {
              return false;
            }
            return (match?.isExact as boolean) && location.pathname === `${path.DOWNLOAD_APPS}`;
          }}
        >
          <MenuItem icon='mobile' name={t('workspace.sidebar.downloadApps', 'Download Apps')} />
        </TopNavLink>
        <TopNavLink
          className={isSettingsNavActive ? 'menu--active' : ''}
          activeClassName='menu--active'
          to={
            isSubscriptionExpired ? `${path.SETTINGS}${path?.PLAN_SUBS}` : `${SETTINGS}${PROFILE}`
          }
          isActive={(match, location) => {
            if (!location) {
              return false;
            }
            return location.pathname === `${path.SETTINGS}`;
          }}
        >
          <MenuItem icon='settings' name={t('workspace.sidebar.settings', 'Settings')} />
        </TopNavLink>
        <UpgradeModal
          title={!isActivePlanAllowed ? 'Premium Feature' : 'Verification Required'}
          description={
            // eslint-disable-next-line no-nested-ternary
            !isActivePlanAllowed
              ? isMember
                ? 'This feature is available only for Standard and Enterprise plans. Please contact owner/admin of your workspace to upgrade.'
                : 'This feature is available only for Standard and Enterprise plans. Please upgrade your plan!'
              : 'KYC verification is required to enable this feature.'
          }
          // eslint-disable-next-line no-nested-ternary
          btnText={!isActivePlanAllowed ? (isMember ? 'ok' : 'Upgrade') : 'Contact Support'}
          open={openUpgradeModal}
          onOk={handleUpgradeModal}
          onClose={toggleUpgradeModal}
        />
      </div>
    </>
  );
}
export default memo(TopNav);
