import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
`;

export const TopContent = styled.div`
  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #3d3358;
    text-transform: capitalize;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6e6681;
  }
`;

export const RadioGroupWrapper = styled.div`
  > .ant-radio-group {
    display: flex;
    gap: 24px;
    .ant-radio-button-wrapper {
      &,
      &:first-child {
        border: 1px solid #e7e6eb;
      }
      &-checked {
        &,
        &:first-child {
          border-color: #1a63f4;
        }
      }
      box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
      border-radius: 10px;
      width: 300px;
      height: 280px;
      display: flex;
      justify-content: center;
      align-items: center;
      ::before {
        display: none;
      }
    }
  }

  p {
    font-family: 'Manrope';
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #3d3358;
  }
`;

export const StepsWrapper = styled.div`
  min-height: 594px;
  .ant-steps {
    .ant-steps-item {
      max-width: 74px;
      .ant-steps-item-icon {
        margin: 0;
        border: 2px solid #b7b3c1;
        .ant-steps-icon {
          font-family: 'Manrope';
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #b7b3c1;
          top: -1px;
        }
      }
      .ant-steps-item-title {
        ::after {
          height: 2px;
          background-color: #b7b3c1;
        }
      }
      &-active {
        .ant-steps-item-icon {
          background: #5065f6;
          border-color: #5065f6;
          position: relative;
          .ant-steps-icon {
            display: block;
            background: white;
            border-radius: 50%;
            height: 12px;
            width: 12px;
            top: 4px;
            left: 4px;
            color: white;
            font-size: 0px;
          }
        }
        .ant-steps-item-title {
          ::after {
            background-color: #5065f6;
          }
        }
      }
      &-finish {
        .ant-steps-item-icon {
          background: #0e9e49;
          border-color: #0e9e49;
          > .ant-steps-icon {
            color: white;
            top: -4px;
          }
        }
        .ant-steps-item-title {
          ::after {
            background-color: #0e9e49;
          }
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          padding: 0;
        }
      }
    }
    &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      padding: 0;
    }
  }
  .steps-action {
    .ant-btn {
      min-width: 160px;
      &-text {
        color: #390179;
        padding: 6.4px 0;
        min-width: 82px;
        &:hover {
          background: transparent;
        }
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .ant-checkbox-wrapper {
      text-align: center;
      padding: 0px 23px;
      background: #ffffff;
      border: 1px solid #e7e6eb;
      border-radius: 10px;
      font-family: 'Manrope';
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: capitalize;
      color: #6e6681;
      margin: 0;
      min-width: 156px;
      min-height: 63px;
      display: flex;
      justify-content: center;
      &-checked {
        border-color: #1a63f4;
      }
      :last-child {
        min-width: 101px;
      }
      > * {
        padding: 0;
      }
      .ant-checkbox {
        display: none;
      }
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #564d6d;
      }
    }
  }
`;

export const StyledPaypalButton = styled.div`
  .zoid-outlet {
    width: 100% !important;
  }
`;
