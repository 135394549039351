import { css } from 'styled-components';

export const antPopover = css`
  .ant-popover {
    z-index: 10000 !important;
    &.more-options {
      padding: 0;
      .ant-popover-arrow {
        display: none;
      }
      .ant-popover-inner {
        background: #ffffff;
        border: 1px solid #e7e6eb;
        box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
        border-radius: 8px;
        overflow: hidden;
        &-content {
          padding: 10px 0;
          .ant-menu {
            border: none;
            min-width: 207px;
            .ant-menu-item,
            .ant-menu-submenu-title {
              padding: 8px 16px;
              display: flex;
              align-items: center;
              min-height: 34px;
              > svg {
                color: #6e6681;
                width: 18px;
                height: 18px;
                flex-shrink: 0;
              }
              > span {
                color: #6e6681;
                font-size: 13px;
                line-height: 16px;
              }
              svg.ant-menu-submenu-expand-icon {
                margin: 0;
              }
            }
            .ant-menu-submenu {
              .ant-menu {
                &,
                &.ant-menu-submenu-popup {
                  min-width: 180px;
                }
                &.ant-menu-sub {
                  border: 1px solid #e7e6eb;
                  box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
                  border-radius: 8px;
                }
                .ant-menu-item {
                  padding: 8px 20px;
                }
              }
            }
            .ant-menu-item-danger {
              &.ant-menu-item-selected {
                background: none;
              }
              > svg,
              > span {
                color: #db312b;
              }
            }
            .ant-menu-item-divider {
              margin: 3.5px 0;
            }
          }
        }
      }

      &.more-options-audio {
        .ant-popover-inner-content .ant-menu {
          min-width: 169px;
        }
      }

      &.more-options-receipts {
        .ant-popover-inner-content .ant-menu {
          min-width: 180px;
        }
      }

      &.more-options-contact-notes {
        .ant-popover-inner-content .ant-menu {
          min-width: 109px;
        }
      }
    }
  }

  .ant-avatar-group-popover {
    .ant-avatar {
      border-radius: 8px;
    }
  }
`;
