import { useCallback, useState } from 'react';
import { Menu } from 'antd';
import { FixedSizeList as List } from 'react-window';
import { RiSearchLine, RiCloseLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';

import { SearchInput, Tooltip } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';
import { HASHED_COUNTRIES } from 'constants/countries';

import * as S from '../phone-input/Styles';
import { OptionListProps, OptionProps } from '../phone-input/interfaces';
import { DropdownOption } from './Styles';

export const Option = ({ value, selected }: OptionProps) => {
  const countryDetail = HASHED_COUNTRIES[value.alphaTwoCode];
  return (
    <DropdownOption className={`${selected && 'selected'} px-5`}>
      <Tooltip title={countryDetail?.name}>
        <div className='flex items-center'>
          <img src={countryDetail?.flagUrl} alt={countryDetail?.alphaTwoCode} />
          <span className='country-label truncate'>
            {countryDetail?.dialingCode}({nameElipsis(countryDetail?.name, 25)})
          </span>
        </div>
      </Tooltip>
    </DropdownOption>
  );
};

export default function CountriesList({
  options,
  placeholder,
  handleSelect,
  handleSearch,
  handleDropdownVisibility,
}: OptionListProps) {
  const [searchInputVisible, setSearchInputVisible] = useState(false);
  const { t } = useTranslation();
  const handleToggleSearch = () => setSearchInputVisible(prevState => !prevState);

  const handleClose = () => {
    handleDropdownVisibility(false);
    setSearchInputVisible(false);
  };

  const listItem = useCallback(
    ({ index, style }) => {
      const item: any = options[index];
      return (
        <>
          <S.OptionList onClick={handleSelect}>
            <Menu.Item key={item.alphaTwoCode} style={style}>
              <Option selected={false} value={item} />
            </Menu.Item>
            {/* {options?.length !== index + 1 && <Menu.Divider />} */}
          </S.OptionList>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  return (
    <S.OptionListWrapper>
      <div className='flex py-3 px-4 shadow-bottom'>
        <RiCloseLine onClick={handleClose} className='cursor-pointer' size={18} color='#212121' />
        {searchInputVisible ? (
          <SearchInput
            className='search-field flex-1'
            placeholder={placeholder}
            size='large'
            onChange={handleSearch}
          />
        ) : (
          <p className='text-13 text-gray-700 leading-5 font-bold flex-1 text-center'>Countries</p>
        )}
        <RiSearchLine onClick={handleToggleSearch} size={18} color='#6E6681' />
      </div>
      {options?.length <= 0 ? (
        <div className='h-48.5'>
          <p className='p-10 text-center text-gray-500 font-heebo font-medium text-13'>
            {t('noData', 'No Data')}
          </p>
        </div>
      ) : (
        <List
          outerElementType={ScrollBar}
          width={320}
          height={194}
          itemCount={options?.length}
          itemSize={48} /* size of menu item */
        >
          {listItem}
        </List>
      )}
    </S.OptionListWrapper>
  );
}
