import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { CHAT_NODE } from './fragments';

export const SEND_MESSAGE = gql`
  mutation createChatMessage($data: ChatMessageInput!) {
    createChatMessage(data: $data) {
      status
      data {
        ...ChatFields
      }
      error {
        ...Error
      }
    }
  }
  ${CHAT_NODE}
  ${ERROR_FRAGMENT}
`;

export const MSG_SEEN = gql`
  mutation editMemberChatSeen($senderId: ShortId!) {
    editMemberChatSeen(senderId: $senderId) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
