export const { TWILIO_DEBUG } = process.env;

export const visibilityOptions = [
  { id: 1, name: 'Private', value: false },
  { id: 2, name: 'Public', value: true },
];

export const conversationStatusValues = {
  pending: 'PENDING',
  sent: 'SENT',
  failed: 'FAILED',
  delivered: 'DELIVERED',
  noAnswer: 'NOANSWER',
  busy: 'BUSY',
  completed: 'COMPLETED',
  cancelled: 'CANCELED',
  callInProgress: 'INPROGRESS',
  ringing: 'RINGING',
  onHold: 'ONHOLD',
  rejected: 'REJECTED',
};

export const INCOMPLETE_CALL_STATUS = ['RINGING', 'PENDING', 'INPROGRESS'];

export type DNDStatus = Record<string, number>;

export const DND_STATUS: DNDStatus = {
  THIRTY_MINUTES: 30,
  ONE_HOUR: 60,
  EIGHT_HOURS: 8 * 60,
  TWELVE_HOURS: 12 * 60,
  OFF: 0,
  // UNTIL_I_RESUME: 0,
};

export const CARD_LOGO: any = {
  visa: 'visa',
  mastercard: 'master_card',
  paypal: 'paypal',
};

export const DND_OPTIONS = [
  { label: 'For 30 minutes', value: 'THIRTY_MINUTES' },
  { label: 'For 1 hour', value: 'ONE_HOUR' },
  { label: 'For 8 hours', value: 'EIGHT_HOURS' },
  { label: 'For 12 hours', value: 'TWELVE_HOURS' },
  { label: 'Until I turn it back on', value: 'UNTIL_I_RESUME' },
];

export const GREETINGS_TYPE = {
  welcome: 'Welcome',
  voicemail: 'Voicemail',
  hold: 'Hold',
  transfer: 'Transfer',
};

export const RECORDING_TYPE = {
  textToSpeech: 'TextToSpeech',
  custom: 'Custom',
  none: 'None',
};

export const ALERT_BAR_HEIGHT = 36;

export const WORKSPACE_LIST_WIDTH = 64;
export const WORKSPACE_DETAIL_BAR_WIDTH = 274;
export const WORKSPACE_DETAIL_BAR_WIDTH_MOBILE = 289;

export const EMERGENCY_NUMBERS = [
  '119',
  '112',
  '129',
  '127',
  '128',
  '1548',
  '14',
  '911',
  '110',
  '116',
  '118',
  '113',
  '115',
  '000',
  '122',
  '102',
  '103',
  '101',
  '919',
  '999',
  '117',
  '124',
  '123',
  '997',
  '998',
  '190',
  '192',
  '193',
  '993',
  '991',
  '995',
  '160',
  '17',
  '18',
  '132',
  '130',
  '131',
  '1220',
  '2251-4242',
  '133',
  '120',
  '772-03-73',
  '996',
  '194',
  '106',
  '104',
  '105',
  '912',
  '158',
  '155',
  '150',
  '19',
  '180',
  '913',
  '114',
  '977',
  '933',
  '15',
  '1730',
  '1300',
  '191',
  '100',
  '166',
  '199',
  '442-020',
  '195',
  '198',
  '107',
  '125',
  '170',
  '185',
  '140',
  '175',
  '121',
  '1515',
  '994',
  '10 111',
  '111',
  '9999',
  '1122',
  '16',
  '151',
  '888',
  '555',
  '10 177',
  '777',
  '1669',
  '8200',
  '811',
  '990',
  '197',
  '171',
  '992',
];
