import { gql } from '@apollo/client';

export const UPDATE_CREDIT_SUBSCRIPTION = gql`
  subscription updateCreditSalesDialer($user: ShortId!) {
    updateCredit(user: $user) {
      event
      message {
        creditAmount
        isCallDisconnected
        campaignStatus
      }
    }
  }
`;
