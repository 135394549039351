import { useEffect } from 'react';

import { PhoneFill, TagCrossFill } from 'components/atoms/icon';
import { FallbackSpinner } from 'components/atoms';

import PhoneAutoCompleteInput from './phone-input';
import NumbersNotFound from './NumberNotFound';
import { CALL, CLEAR } from '../constants';
import usePhoneInputContext from '../hooks/usePhoneInputContext';
import Keypad from '../common/keypad';
import { KeypadButton } from '../common/keypad/KeypadButton';
import useCallWidgetContext from '../hooks/useCallWidgetContext';

const Dialer = () => {
  const { disableCall, handleDialpadClick } = usePhoneInputContext();
  const { channels, loadingChannels, selectedChannel, setSelectedChannel } = useCallWidgetContext();

  useEffect(() => {
    if (channels.length && !selectedChannel) {
      setSelectedChannel?.(channels[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels]);

  if (loadingChannels) return <FallbackSpinner />;
  if (!channels?.length) return <NumbersNotFound />;

  return (
    <>
      <PhoneAutoCompleteInput />
      <div className='px-10.5'>
        <Keypad handleKeypadClick={handleDialpadClick} />
        <div className='flex gap-4 justify-end mt-3'>
          <KeypadButton
            variant='primary-success'
            onClick={() => handleDialpadClick(CALL)}
            disabled={disableCall}
          >
            <PhoneFill className='w-8 h-8' />
          </KeypadButton>
          <KeypadButton variant='tertiary' onClick={() => handleDialpadClick(CLEAR)}>
            <TagCrossFill className='w-7 h-7' />
          </KeypadButton>
        </div>
      </div>
    </>
  );
};

export default Dialer;
