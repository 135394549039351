/* eslint-disable no-param-reassign */

/**
 * Converts a hex color code to an RGBA color code
 * @param {string} hex - The hex color code to convert (with or without the "#" symbol)
 * @param {number} [opacity=1] - The opacity value, a decimal between 0 and 1 (default is 1)
 * @return {string} - The RGBA color code in the format "rgba(red,green,blue,opacity)"
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 */

export function hexToRGBA(hex: string, opacity = 1): string {
  const trimmedHex = hex.replace('#', '');
  // eslint-disable-next-line no-return-assign
  return `rgba(${(hex = trimmedHex)
    ?.match(new RegExp(`(.{${hex.length / 3}})`, 'g'))
    ?.map(l => {
      return parseInt(hex.length % 2 ? l + l : l, 16);
    })
    // eslint-disable-next-line no-restricted-globals
    .concat(isFinite(opacity) ? opacity : 1)
    .join(',')})`;
}
