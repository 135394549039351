import { produce } from 'immer';
import { useEffect, useRef, useContext } from 'react';
import { useReactiveVar, useLazyQuery } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import { MemberEdge, MemberNode, Query } from 'generated/graphql';
import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { AuthContext } from 'contexts/auth/AuthContext';
import { sortOnlineMembers } from 'components/utils/sortOnlineMembers';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import { queryParamVars, LIMIT } from './useAgentVars';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';

export const useAgentTransferHandler = () => {
  const { closeDrawer } = useCallWidgetDrawerContext();
  const { loggedInMemberId } = useContext(AuthContext);
  const {
    state: { connection, direction },
  } = useTwilioContext();
  const { handleCallTransfer } = useActiveCallWidgetContext();
  const agentParams = useReactiveVar<any>(queryParamVars);

  const elementRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, entry] = useIntersectionObserver({
    elementRef,
  });
  const targetElement = entry?.target;

  const [loadWorkspaceMembers, { data: query, fetchMore, loading: loadingMembers }] = useLazyQuery(
    WORKSPACE_MEMBERS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    loadWorkspaceMembers({
      variables: {
        pageParams: agentParams,
      },
    });
  }, [agentParams, loadWorkspaceMembers]);

  const handleOptionSelection = (member: MemberNode) => {
    const payload = {
      destination: member.id,
      callerId:
        direction === 'Incoming'
          ? connection?.parameters?.From
          : connection?.customParameters?.get('To'),
      direction,
      externalNumber: false,
    };
    const transferredTo = `${member.firstname} ${member.lastname}`;
    handleCallTransfer?.(payload, transferredTo);
    closeDrawer();
  };

  const { edges = [] } = query?.allWorkspaceMembers?.data || {};
  const agents = edges.filter(({ node }: MemberEdge) => {
    const { id, online } = node || {};
    return id !== loggedInMemberId && online;
  });
  const memberMap = agents.map((member: MemberEdge) => {
    return member.node;
  });

  const sortedMembers = sortOnlineMembers(memberMap);

  const endCursor = query?.allWorkspaceMembers?.data?.pageInfo?.endCursor;
  const hasNextPage = query?.allWorkspaceMembers?.data?.pageInfo?.hasNextPage;

  useEffect(() => {
    if (isVisible) {
      if (targetElement instanceof HTMLElement && hasNextPage) {
        targetElement?.click();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isVisible, targetElement]);

  const onLoadMore = async () => {
    if (fetchMore && endCursor && !loadingMembers && hasNextPage) {
      await fetchMore({
        variables: {
          pageParams: {
            first: LIMIT,
            after: endCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          const members = fetchMoreResult.allWorkspaceMembers.data;
          return produce(prev, (draft: Pick<Query, 'allWorkspaceMembers'>) => {
            draft.allWorkspaceMembers.data = {
              pageInfo: members.pageInfo,
              edges: draft?.allWorkspaceMembers?.data?.edges?.concat(members.edges),
              // eslint-disable-next-line no-underscore-dangle
              __typename: draft?.allWorkspaceMembers?.data?.__typename,
            };
          });
        },
      });
    }
  };

  return {
    elementRef,
    onlineMembers: sortedMembers,
    handleOptionSelection,
    onLoadMore,
  };
};
