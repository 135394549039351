import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';

import { ToastMessage } from 'components/atoms';
import { useChargebeeMutation } from 'lib/chargebee/hooks/useChargebeeMutation';
import { mapPaymentIntentData } from 'lib/chargebee/utils';
import { PaymentGatewayTypesEnum } from 'constants/paymentGateway';
import { logInfoDatdog } from 'services/datadog/helper';
import { convertToCent } from 'components/utils/helpers';

export function usePurchaseHandler(props: any) {
  const { t } = useTranslation();
  const { rechargeCredit, recharging, createPaymentIntent } = useChargebeeMutation();
  const cbInstance = window?.Chargebee?.getInstance?.();
  const { setProcessing, onModalCancel } = props || {};

  // FETCH PAYMENT INTENT
  const fetchPaymentIntent = async (payload: Record<string, string>) => {
    const { amount, paymentID } = payload;
    try {
      setProcessing(true);
      const credit = convertToCent(amount);
      const intents = await createPaymentIntent({
        variables: {
          data: {
            amount: credit, // amount in cents
            paymentMethodId: paymentID, // card id is sent in paymentMethodId
          },
        },
      });
      const paymentIntentResponse = intents?.data?.createPaymentIntent;
      const paymentIntent = paymentIntentResponse?.data || {};
      if (paymentIntentResponse?.error) {
        setProcessing(false);
        return;
      }
      // eslint-disable-next-line consistent-return
      return mapPaymentIntentData(paymentIntent);
    } catch (error: any) {
      setProcessing(false);
      const additionalInfo = {
        paymentIntentPayload: {
          amount: convertToCent(amount || 0), // amount in cents
          paymentMethodId: paymentID, // card id is sent in paymentMethodId
          paymentIntentType: 'WorkspaceRenewal',
        },
        context: 'Credit Purchase',
        errText: 'Credit Purchase Failed',
      };
      logInfoDatdog(error, additionalInfo);
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    }
  };

  const onPurchase = async (payload: Record<string, string>) => {
    const { amount, paymentID } = payload;
    if (!paymentID) return;
    const paymentIntentData = await fetchPaymentIntent(payload);
    if (!paymentIntentData) return;
    const { payment_method_type: paymentMethodType } = paymentIntentData;
    const args = {
      paymentId: paymentID,
      amount: Number(amount),
      gatewayAccountId: paymentIntentData.gateway_account_id,
    };
    if (paymentMethodType === PaymentGatewayTypesEnum.Paypal) {
      await rechargeCredit(args);
      setProcessing(false);
      onModalCancel?.();
      return;
    }
    cbInstance.load3DSHandler().then((threeDSHandler: any) => {
      threeDSHandler.setPaymentIntent(paymentIntentData);
      threeDSHandler
        .handleCardPayment()
        .then(async (intent: any) => {
          await rechargeCredit({
            ...args,
            gwToken: intent?.active_payment_attempt?.id_at_gateway,
          });
          setProcessing(false);
          onModalCancel?.();
        })
        .catch((error: any) => {
          setProcessing(false);
          const { displayMessage, message } = error || {};
          const errMsg =
            displayMessage || message || t('error.unspecific', 'Something went wrong.');
          const additionalInfo = {
            credit: Number(amount),
            paymentID,
            paymentIntentData,
            context: 'Credit Purchase',
            errText: 'Credit Purchase Failed',
          };
          logInfoDatdog(error, additionalInfo);
          ToastMessage({
            content: errMsg,
            type: 'danger',
          });
        });
    });
  };

  const restricted = recharging;
  return {
    onPurchase,
    restricted,
  };
}
