export const ERROR_MESSAGES = {
  numberNotAvailable: "You don't have any numbers on this workspace",
  invalidContactName: 'Choose an existing contact or dial',
  invalidPhoneNumber: 'Invalid phone number',
  emergencyCalls: 'For emergency calls contact support',
  noMicPermission: 'Permission needed for microphone',
  accountSuspended: 'Your KrispCall Account is currently suspended due to lack of credits',
  deletedWorkspace: 'Your current workspace is deleted',
  underReview: 'Your KrispCall account is under review, Please contact support!',
  subscriptionCancelled: 'Your KrispCall subscription has been cancelled',
  subscriptionExpired: 'Your KrispCall subscription has expired',
};
