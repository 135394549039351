/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from 'components/atoms/fields';
import { Label, Tooltip, Error } from 'components/atoms';
import { InputFieldWrapper, FlexColumn } from './styled';
import { InputProps } from './types';

const InputField: React.FC<InputProps> = props => {
  const {
    label,
    labelWeight,
    id,
    name,
    placeholder,
    required = false,
    errors,
    suffix,
    onChange,
    onBlur,
    onFocus,
    onClick,
    onPressEnter,
    control,
    className,
    disabled,
    isEditable,
    autoComplete,
    autoFocus,
    value,
    size,
    addonBefore,
    addonAfter,
    prefix,
    ...rest
  } = props;
  const { t } = useTranslation();
  const hasErrors = errors?.[name];
  return (
    <>
      <InputFieldWrapper
        className={`${hasErrors ? 'field-error' : ''} ${disabled ? 'field-disabled' : ''}`}
      >
        <FlexColumn>
          {label && (
            <Label
              data-cy='contacts-add-form-name-label'
              id={id || name}
              required={required}
              fontWeight={labelWeight}
            >
              {label}
            </Label>
          )}

          {!control && (
            <Input
              addonBefore={addonBefore}
              addonAfter={addonAfter}
              data-cy='contacts-add-form-name-element-input'
              suffix={suffix}
              placeholder={placeholder}
              id={id || name}
              className={className}
              onChange={onChange}
              onPressEnter={onPressEnter}
              onBlur={onBlur}
              autoFocus={autoFocus}
              value={value}
              size={size}
              disabled={disabled}
              prefix={prefix}
              {...rest}
            />
          )}
          {control && (
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                // we can handle through fieldState for error
                const fieldValue = field?.value;
                return isEditable === false ? (
                  <Tooltip
                    title={fieldValue && fieldValue.length > 20 ? fieldValue : ''}
                    placement='top'
                  >
                    <p
                      className={`${field.value ? 'word-break' : 'input-placeholder'} ${className}`}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                        if (onClick) {
                          onClick(event);
                        }
                      }}
                    >
                      {fieldValue || placeholder}
                    </p>
                  </Tooltip>
                ) : (
                  <Input
                    {...field}
                    {...rest}
                    suffix={suffix}
                    id={id || name}
                    value={field.value}
                    className={className}
                    placeholder={placeholder}
                    size={size}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (onChange) {
                        onChange(event);
                      } else {
                        field.onChange(event);
                      }
                    }}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                      if (onBlur) {
                        onBlur(event);
                      } else {
                        field?.onBlur?.();
                      }
                    }}
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                      if (onFocus) {
                        onFocus(event);
                      }
                    }}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      if (onClick) {
                        onClick(event);
                      }
                    }}
                    disabled={disabled}
                    prefix={prefix}
                    onPressEnter={onPressEnter}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    ref={field.ref}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                  />
                );
              }}
            />
          )}
          {errors?.[name] && (
            <Error data-cy='required-label'>{t(`constants:${errors[name].message}`)}</Error>
          )}
        </FlexColumn>
      </InputFieldWrapper>
    </>
  );
};

InputField.displayName = 'InputField';
export default InputField;
