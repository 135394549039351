import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { Query } from 'generated/graphql';
import { produce } from 'immer';
import { cache } from 'services/apollo';
import { MEMBER, TEAM_MEMBERS_LIST } from 'graphql/organization/member';
import { GET_TEAMS } from 'graphql/organization/team';
import { LIMIT } from 'hooks/useMemberVars';
import { initialWorkspaceMemberParams } from 'components/pages/settings/members/hooks/useMembersQVars';

export default function useMemberOnlineStatus() {
  const updateMemberOnlineStatus = (id: string, onlineStatus: boolean, teamId?: string) => {
    const cachedData: Pick<Query, 'allWorkspaceMembers'> | null | any = cache.readQuery({
      query: WORKSPACE_MEMBERS,
      variables: {
        pageParams: initialWorkspaceMemberParams,
      },
    });

    if (cachedData?.allWorkspaceMembers?.data?.edges) {
      const memberIndex = cachedData.allWorkspaceMembers.data.edges.findIndex(
        (member: any) => member?.node?.id === id,
      );

      if (memberIndex !== -1) {
        if (cachedData?.allWorkspaceMembers?.data.edges[memberIndex].node.online !== onlineStatus) {
          const updatedData = produce(
            cachedData,
            (draft: Pick<Query, 'allWorkspaceMembers'> | any) => {
              if (draft?.allWorkspaceMembers?.data?.edges) {
                if (draft.allWorkspaceMembers.data.edges[memberIndex]) {
                  draft.allWorkspaceMembers.data.edges[memberIndex].node = {
                    ...draft.allWorkspaceMembers.data.edges[memberIndex].node,
                    online: onlineStatus,
                  };
                }
              }
            },
          );

          cache?.writeQuery({
            query: WORKSPACE_MEMBERS,
            variables: {
              pageParams: initialWorkspaceMemberParams,
            },
            data: updatedData,
          });
        }
        const cachedTeamsData: any = cache?.readQuery({
          query: GET_TEAMS,
        });

        const cachedMemberData = cache.readQuery({
          query: MEMBER,
          variables: {
            id,
          },
        });

        const updatedTeamsData = produce(cachedTeamsData, (draft: Pick<Query, 'teams'> | any) => {
          if (draft?.teams?.data) {
            draft.teams.data.filter((eachTeam: any, eachTeamIndex: number) => {
              if (eachTeam?.teamMembers) {
                const index1 = eachTeam.teamMembers.findIndex(
                  (eachMember: any) => eachMember.id === id,
                );
                if (index1 !== -1) {
                  draft.teams.data[eachTeamIndex].teamMembers[index1].online = onlineStatus;
                }
              }
              return {};
            });
          } // here
        });

        cache?.writeQuery({
          query: GET_TEAMS,
          data: updatedTeamsData,
        });

        if (teamId) {
          const cachedTeamListData: any = cache?.readQuery({
            query: TEAM_MEMBERS_LIST,
            variables: {
              id: teamId,
              params: {
                first: LIMIT,
                search: null,
                filter: null,
                after: null,
              },
            },
          });
          if (cachedTeamListData) {
            const updatedTeamListData = produce(cachedTeamListData, (draft: Pick<Query, any>) => {
              if (draft?.teamMembersList?.data?.edges) {
                const index2 = cachedTeamListData.teamMembersList.data.edges.findIndex(
                  (member: any) => member?.node?.id === id,
                );
                if (index2 !== -1) {
                  if (draft.teamMembersList.data.edges[index2]) {
                    draft.teamMembersList.data.edges[index2].node = {
                      ...draft.teamMembersList.data.edges[index2].node,
                      online: onlineStatus,
                    };
                  }
                }
              }
            });
            cache?.writeQuery({
              query: TEAM_MEMBERS_LIST,
              variables: {
                id: teamId,
                params: {
                  first: LIMIT,
                  search: null,
                  filter: null,
                  after: null,
                },
              },
              data: updatedTeamListData,
            });
          }
        }
        if (cachedMemberData) {
          const updatedMemberData = produce(
            cachedMemberData,
            (draft: Pick<Query, 'memberDetail'>) => {
              if (draft?.memberDetail?.data) {
                draft.memberDetail.data.online = onlineStatus;
              }
            },
          );

          cache.writeQuery({
            query: MEMBER,
            variables: {
              id,
            },
            data: updatedMemberData,
          });
        }
      }
    }
  };

  return { updateMemberOnlineStatus };
}
