export default function BookmarkPlusFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.585 3.19384C18.1595 2.79219 17.6583 2.4792 17.1106 2.27318C16.5629 2.06716 15.9797 1.97221 15.395 1.99382H8.66501C8.07447 1.97245 7.48551 2.06787 6.93192 2.27459C6.37832 2.4813 5.871 2.79527 5.439 3.19848C5.00699 3.60168 4.65883 4.08616 4.41447 4.6242C4.17012 5.16223 4.03435 5.74324 4.015 6.33385V19.6938C4.04503 20.1412 4.19343 20.5726 4.44501 20.9438C4.69935 21.3038 5.04434 21.5902 5.44501 21.7739C5.75893 21.9174 6.09983 21.9923 6.44501 21.9938H6.74501C7.18248 21.9373 7.59683 21.7647 7.94501 21.4938L11.375 18.9538C11.5551 18.8273 11.7699 18.7594 11.99 18.7594C12.2101 18.7594 12.4249 18.8273 12.605 18.9538L16.015 21.4838C16.3675 21.762 16.7894 21.9384 17.235 21.9938C17.6784 22.0429 18.1269 21.9739 18.535 21.7938C18.9493 21.6066 19.3039 21.3087 19.5598 20.9329C19.8156 20.5571 19.9627 20.1179 19.985 19.6638V6.31383C19.9711 5.72676 19.8402 5.14837 19.5997 4.6126C19.3593 4.07683 19.0143 3.59448 18.585 3.19384ZM15.015 10.7438H12.765V12.9938C12.765 13.1927 12.686 13.3835 12.5453 13.5242C12.4047 13.6648 12.2139 13.7438 12.015 13.7438C11.8161 13.7438 11.6253 13.6648 11.4847 13.5242C11.344 13.3835 11.265 13.1927 11.265 12.9938V10.7438H9.015C8.81609 10.7438 8.62533 10.6648 8.48468 10.5242C8.34403 10.3835 8.265 10.1927 8.265 9.99382C8.265 9.79491 8.34403 9.60414 8.48468 9.46349C8.62533 9.32284 8.81609 9.24382 9.015 9.24382H11.265V6.99382C11.265 6.79491 11.344 6.60414 11.4847 6.46349C11.6253 6.32284 11.8161 6.24382 12.015 6.24382C12.2139 6.24382 12.4047 6.32284 12.5453 6.46349C12.686 6.60414 12.765 6.79491 12.765 6.99382V9.24382H15.015C15.2139 9.24382 15.4047 9.32284 15.5453 9.46349C15.686 9.60414 15.765 9.79491 15.765 9.99382C15.765 10.1927 15.686 10.3835 15.5453 10.5242C15.4047 10.6648 15.2139 10.7438 15.015 10.7438Z'
        fill='currentColor'
      />
    </svg>
  );
}
