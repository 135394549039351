import { getSubdomain } from 'components/utils/url';
import useLocalStorage from 'hooks/useLocalStorage';

// TODO: Need to remove toggle v3 dialer option after complete migration to v3 design
export default function useSwitchV3Dialer() {
  const [v3DialerVisible, setV3DialerVisible] = useLocalStorage('v3DialerVisible', null);
  const subdomain = getSubdomain();
  const showToggleV3DialerOption = ['dev', 'qa'].includes(subdomain);

  const toggleV3DialerWidget = (val: boolean) => {
    setV3DialerVisible(val);
  };

  return {
    showToggleV3DialerOption,
    v3DialerVisible,
    toggleV3DialerWidget,
  };
}
