import { css } from 'styled-components';

export const antCollapse = css`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    h6 {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #564d6d;
      margin-left: 2px;
    }
    svg {
      color: #564d6d !important;
    }
    .ant-collapse-extra {
      position: absolute;
      right: 0;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding: 0;
    margin: 0;
    transition: all 0.15s ease-in;
    font-size: 14px;
    vertical-align: -3px;
    &.rotate {
      &--180 {
        transform: rotate(180deg);
      }
      &--0 {
        transform: rotate(0deg);
      }
      &--90 {
        transform: rotate(-90deg);
      }
    }
  }
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  &.sidebar-collapse {
    .ant-collapse-item > .ant-collapse-header {
      margin-top: 20px;
      margin-bottom: 8px;
    }
  }
`;
