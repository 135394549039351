const colors = {
  primary: {
    default: '#390179',
    p0: '#F5F2F8',
    p1: '#ECE6F2',
    p2: '#D7CCE4',
    p3: '#B099C9',
    p4: '#8867AF',
    p5: '#613494',
    p6: '#2C0068',
    p7: '#200057',
    p8: '#170046',
    p9: '#10003A',
    p10: '#EBE6F2',
  },
  success: {
    default: '#4C9610',
    s0: '#F6F9F3',
    s1: '#EEF5E8',
    s2: '#DBEACF',
    s3: '#B7D59F',
    s4: '#94C070',
    s5: '#70AB40',
    s6: '#39810B',
    s7: '#296C08',
    s8: '#1C5705',
    s9: '#124803',
  },
  info: {
    default: '#1A63F4',
    i0: '#F3F7FE',
    i1: '#E9F0FE',
    i2: '#D1E0FD',
    i3: '#A3C1FB',
    i4: '#76A1F8',
    i5: '#4882F6',
    i6: '#134CD1',
    i7: '#0D38AF',
    i8: '#08278D',
    i9: '#041B75',
  },
  warning: {
    default: '#E07408',
    w0: '#FDF8F2',
    w1: '#FCF2E7',
    w2: '#F9E3CE',
    w3: '#F3C79C',
    w4: '#ECAC6B',
    w5: '#E69039',
    w6: '#C05905',
    w7: '#A14204',
    w8: '#812F02',
    w9: '#6B2101',
  },
  danger: {
    default: '#DB312B',
    d0: '#FDF4F4',
    d1: '#FCEBEA',
    d2: '#F8D6D5',
    d3: '#F1ADAA',
    d4: '#E98380',
    d5: '#E69039',
    d6: '#BC1F27',
    d7: '#9D1528',
    d8: '#7F0D26',
    d9: '#690825',
  },
  text: {
    t0: '#F3F2F4',
    t1: '#E7E6EB',
    t2: '#CFCCD5',
    t3: '#B7B3C1',
    t4: '#9E99AB',
    t5: '#857F96',
    t6: '#6E6681',
    t7: '#564D6D',
    t8: '#3D3358',
    t9: '#251A43',
    t10: '#0D002E',
  },
  gray: {
    g1: '#F6FAFA',
    g2: '#F0EEF1',
    g3: '#DFDDE1',
    g4: '#B3BFC5',
    g5: '#56666D',
  },
  white: '#FFFFFF',
};
export default colors;
