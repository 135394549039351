import { Tag, ContactNode } from 'generated/graphql';
import { hexToRGBA } from 'components/utils/colors';

export const createOption = (
  id: string,
  colorTitle: string,
  colorCode: string,
  backgroundColorCode: string,
) => ({
  id,
  label: colorTitle,
  value: colorTitle.toLowerCase().replace(/\W/g, ''),
  colorCode,
  backgroundColorCode,
});

export const setTag = (tag: Tag) => {
  const { id, title = '', colorCode = '', backgroundColorCode = '' } = tag ?? {};
  const bgColor =
    colorCode === backgroundColorCode ? hexToRGBA(colorCode as string, 0.1) : backgroundColorCode;

  return {
    id,
    label: title,
    value: id,
    colorCode,
    backgroundColorCode: bgColor,
  };
};
