import React from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/button/Button';

const InfoButton = styled(Button)`
  &.ant-btn,
  &.ant-btn:hover {
    border: none;
    background: #1a63f4;
    span,
    svg {
      color: #ffffff;
    }
  }
  &.ant-btn {
    padding: 0 13px;
    @media screen and (min-width: 769px) {
      padding: 0 24px;
    }
    &[disabled] {
      background: #f3f2f4;
      span {
        color: #857f96;
      }
    }
  }
  &.ant-btn-link {
    padding: 0;
    &,
    :hover {
      background: transparent;
      span,
      svg {
        color: #1a63f4;
      }
    }
  }
`;

export default React.memo(InfoButton);
