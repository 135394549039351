import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Select, ConfigProvider } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash.isempty';
import { RiCheckLine, RiCloseFill } from 'react-icons/ri';

import { Team } from 'generated/graphql';
import { Input, SelectInput } from 'components/molecules/fields';
import AvatarSelect from 'components/organisms/team/AvatarSelect';
import { EditFieldModal } from 'components/organisms';
import { ManIcon } from 'components/atoms/icon';
import { useMembers } from 'components/pages/settings/team/hooks/useMembers';
import { useTeamMutation } from 'components/pages/settings/team/hooks/useTeamMutation';
import { TeamsContext } from 'components/pages/settings/team/context/TeamsContext';

import { schema } from './schema';
import * as S from './Styles';

interface TeamInput {
  title: string;
  description: string;
  members: string[];
}

export type Maybe<T> = T | null;
interface Props {
  toogleAddTeamModal: () => void;
  visibleAddTeamModal: boolean;
}

const TeamForm: React.FunctionComponent<Props> = props => {
  const { teams } = useContext<any>(TeamsContext);
  const { toogleAddTeamModal, visibleAddTeamModal } = props;
  const { t } = useTranslation();
  const { addTeam, addingTeam } = useTeamMutation({ callback: toogleAddTeamModal });
  const { members, handleMemberSearch } = useMembers();
  const [step, setStep] = useState(0);
  const [teamName, setTeamName] = useState('');
  const [avatar, setAvatar] = useState<string | undefined>();

  const {
    control,
    formState: { errors },
    setError,
    setValue,
    handleSubmit,
  } = useForm<TeamInput>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { title: '', members: [] },
  });

  const resetForm = () => {
    setStep(0);
    setValue('title', '');
    setValue('members', []);
    setAvatar('');
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleAddTeamModal]);

  const handleAvatarSelect = async (icon: string) => {
    setAvatar(icon);
  };
  const formSubmit = async (val: TeamInput) => {
    const isTeamExist = (teams || []).find(
      (team: Team) => team?.title?.toLowerCase() === val?.title?.toLowerCase(),
    );
    if (isTeamExist) {
      setError('title', {
        type: 'manual',
        message: 'Team Name already exists',
      });
      return;
    }
    if (!step) {
      setTeamName(val?.title);
      setStep(1);
      return;
    }
    if (!val.members?.length) {
      if (setError) {
        setError('members', {
          type: 'focus',
          message: 'Required',
        });
      }
      return;
    }
    await addTeam({ ...val, avatar });
  };
  const title = step ? teamName : 'Create New Team';
  const subTitle = step
    ? 'Add members to this team for better collaboration and communication'
    : 'Bring your team together for better communication and effective work';
  const buttonText = step ? 'Done' : 'Create Team';

  return (
    <EditFieldModal
      buttonText={buttonText}
      isModalOpen={visibleAddTeamModal}
      formId='create-team-form'
      onCancel={toogleAddTeamModal}
      disableSubmitButton={!isEmpty(errors)}
      loading={addingTeam}
    >
      <form
        className='team-form items-center mt-2'
        id='create-team-form'
        onSubmit={handleSubmit(formSubmit)}
      >
        <div className='w-85 m-auto'>
          <div className='flex justify-center'>
            <AvatarSelect onAvatarSelect={handleAvatarSelect} avatar={avatar} />
          </div>
          <div className='mb-10 mt-7.5 text-center'>
            <h5 className='text-xl text-gray-700 font-bold leading-7 mb-2'>{title}</h5>
            <p className='text-15 text-gray font-normal text-center leading-normal'>{subTitle}</p>
          </div>
        </div>
        <div className={`${step && 'hidden'}`}>
          <Input
            name='title'
            id='title'
            control={control}
            placeholder={t('teamName', 'Team Name')}
            label={t('form.label.teamName', 'Team Name')}
            errors={errors}
          />
        </div>
        <div className={`${!step && 'hidden'}`}>
          <ConfigProvider
            renderEmpty={() => (
              <div className='text-gray-600 leading-3.5 font-semibold text-center p-px'>
                We didn’t find any match
              </div>
            )}
          >
            <S.SelectFieldWrapper>
              <SelectInput
                name='members'
                id='members'
                control={control}
                label={t('form.label.addTeamMembers', 'Add Team Members')}
                showSearch
                onSearch={handleMemberSearch}
                className='member-select-field'
                mode='multiple'
                errors={errors}
                removeIcon={<RiCloseFill />}
                menuItemSelectedIcon={<RiCheckLine />}
              >
                {members.map((member: any) => {
                  const { id, firstname, lastname, profilePicture } = member?.node || {};
                  return (
                    <Select.Option value={id ?? ''} key={id}>
                      <S.MemberOptionWrapper>
                        <Avatar src={profilePicture} alt='profile pic' icon={<ManIcon />} />
                        <div className='member-name truncate max-w-150'>{`${firstname} ${lastname}`}</div>
                      </S.MemberOptionWrapper>
                    </Select.Option>
                  );
                })}
              </SelectInput>
            </S.SelectFieldWrapper>
          </ConfigProvider>
        </div>
      </form>
    </EditFieldModal>
  );
};

export default TeamForm;
