import { webRtcPeerConnectionEnabled } from 'services/apollo/reactiveVars';
import { peerTransfer } from './utils';

const useWebRtcTest = () => {
  const checkPeerConnection = async (timeout?: number) => {
    try {
      const res: any = await peerTransfer(timeout);
      return webRtcPeerConnectionEnabled(res?.result === 'Success');
    } catch {
      return webRtcPeerConnectionEnabled(false);
    }
  };
  return { checkPeerConnection };
};

export default useWebRtcTest;
