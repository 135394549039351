import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';

export const FETCH_CALL_SCRIPTS = gql`
  query getCampaignCallScriptList @api(name: "salesDialer") {
    getCampaignCallScriptList {
      data {
        id
        createdOn
        createdBy
        scriptTitle
        description
        isDefault
      }
      error {
        ...Error
      }
      status
    }
  }

  ${ERROR_FRAGMENT}
`;

export const FETCH_CALL_SCRIPT_ATTRIBUTES = gql`
  query getCampaignCallScriptAttributeList @api(name: "salesDialer") {
    getCampaignCallScriptAttributeList {
      data {
        name
      }
      error {
        ...Error
      }
      status
    }
  }

  ${ERROR_FRAGMENT}
`;

export const FETCH_CALL_SCRIPT_BY_ID = gql`
  query getCallScriptById($callScriptId: ShortId!) @api(name: "salesDialer") {
    getCallScriptById(callScriptId: $callScriptId) {
      data {
        scriptTitle
        description
      }
      error {
        ...Error
      }
      status
    }
  }
  ${ERROR_FRAGMENT}
`;
