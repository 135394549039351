/* eslint-disable no-restricted-globals */

import { parsePhoneNumber } from 'components/utils/phone-lib';
import { COUNTRIES } from 'constants/countries';

export const guessCountry = (value: any) => {
  if (isNaN(value.replace(/\+/g, ''))) return false;
  const parsedNumber = value && parsePhoneNumber(value);
  if (parsedNumber) {
    const guessedCountry = COUNTRIES?.find(data =>
      parsedNumber?.country
        ? data.alphaTwoCode === parsedNumber?.country
        : data.dialingCode === `+${parsedNumber?.countryCode}`,
    );
    return guessedCountry;
  }
  return false;
};

export const isNumber = (value: string) => {
  const trimmedValue: any = value?.replace(/\+/g, '');
  return !isNaN(trimmedValue);
};
