import { ReactElement } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

import { useTwilioContext } from 'lib/twilio';
import { ACTIONS } from 'lib/twilio/constants';
import { Tooltip } from 'components/atoms';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';

export default function WidgetHeader(): ReactElement {
  const { dispatch } = useTwilioContext();
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });

  const handleClose = () => {
    dispatch({
      type: ACTIONS.CLOSE_PHONE_WIDGET,
      data: {},
    });
  };

  return (
    <div
      data-cy='d-title-info'
      className='pl-12 pr-2.5 h-10 bg-primary border border-gray-100 border-b-0 flex justify-between items-center rounded-t-xl text-white w-80 -m-px mb-0'
    >
      <p data-cy='d-title' className='font-semibold leading-4.75'>
        Dialer
      </p>
      <span data-cy='d-title-close-icon'>
        {!isDialerPage && (
          <Tooltip title='Close' placement='top'>
            <RiCloseLine size={18} onClick={handleClose} className='cursor-pointer' />
          </Tooltip>
        )}
      </span>
    </div>
  );
}
