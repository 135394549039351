export const tagsColors = [
  {
    id: 1,
    colorTitle: 'Default',
    colorCode: '#F3F2F4',
    backgroundColorCode: '#F3F2F4',
    bgClass: 'bg-tags-gray50',
  },
  {
    id: 2,
    colorTitle: 'Red',
    colorCode: '#F2BFC4',
    backgroundColorCode: '#F2BFC4',
    bgClass: 'bg-tags-red',
  },
  {
    id: 3,
    colorTitle: 'Pink',
    colorCode: '#F8CCE6',
    backgroundColorCode: '#F8CCE6',
    bgClass: 'bg-tags-pink',
  },
  {
    id: 4,
    colorTitle: 'Purple',
    colorCode: '#E1D3F8',
    backgroundColorCode: '#E1D3F8',
    bgClass: 'bg-tags-purple',
  },
  {
    id: 5,
    colorTitle: 'Blue',
    colorCode: '#CCE4F9',
    backgroundColorCode: '#CCE4F9',
    bgClass: 'bg-tags-blue',
  },
  {
    id: 6,
    colorTitle: 'Green',
    colorCode: '#CCE7E1',
    backgroundColorCode: '#CCE7E1',
    bgClass: 'bg-tags-green',
  },
  {
    id: 7,
    colorTitle: 'Yellow',
    colorCode: '#FBEECC',
    backgroundColorCode: '#FBEECC',
    bgClass: 'bg-tags-yellow',
  },
  {
    id: 8,
    colorTitle: 'Mustard',
    colorCode: '#FDDFCC',
    backgroundColorCode: '#FDDFCC',
    bgClass: 'bg-tags-mustard',
  },
  {
    id: 9,
    colorTitle: 'Brown',
    colorCode: '#E8D5CC',
    backgroundColorCode: '#E8D5CC',
    bgClass: 'bg-tags-brown',
  },
  {
    id: 10,
    colorTitle: 'Grey',
    colorCode: '#E7E6EB',
    backgroundColorCode: '#E7E6EB',
    bgClass: 'bg-tags-gray100',
  },
];
