import { gql } from '@apollo/client';
import { CONVERSATION_NODE } from '../fragments';

export const COMMUNICATION_SUBSCRIPTION = gql`
  subscription updateConversation($channel: ShortId!) {
    updateConversation(channel: $channel) {
      event
      message {
        ...ConversationFields
      }
    }
  }
  ${CONVERSATION_NODE}
`;

export const OPEN_CONTACT_COUNT_SUBSCRIPTION = gql`
  subscription openContactCount($channel: ShortId!) {
    openContactCount(channel: $channel) {
      event
      message {
        count
        channel
      }
    }
  }
`;

export const INTEGRATION_SUBSCRIPTION = gql`
  subscription integrationUpdate($integration: ShortId!) {
    integrationUpdate(integration: $integration) {
      event
      message {
        id
      }
    }
  }
`;
