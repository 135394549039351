import { ReactElement, useState, useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';

import { Maybe, Tag } from 'generated/graphql';
import { SearchInput } from 'components/atoms';
import { useTagsQuery } from 'components/pages/settings/tags/hooks/useTagsQuery';
import * as S from './Styles';
import TagList from './TagList';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import { useTag } from '../../hooks/useTag';

interface ITagsAssignProps {
  handleClose: () => void;
}

export function TagsAssign({ handleClose }: ITagsAssignProps): ReactElement {
  const [selectedTags, setSelectedTags] = useState<Maybe<Tag>[]>([]);
  const { t } = useTranslation();
  const { tagList, onSearch } = useTagsQuery();

  const { clientInfo } = useCallWidgetContext();
  const { addTag, loadingAssignTags } = useTag();
  const { tags } = clientInfo || {};

  useEffect(() => {
    if (tags?.length) {
      setSelectedTags(tags);
    }
  }, [tags]);

  const handleCloseBtnClick = () => {
    handleClose();
  };

  const handleTagSelect = (tag: Maybe<Tag>) => {
    const tagIndex = selectedTags.findIndex((elem: any) => elem.id === tag?.id);
    const tagsArray = [...selectedTags];
    if (tagIndex !== -1) {
      tagsArray.splice(tagIndex, 1);
      setSelectedTags(tagsArray);
      return;
    }
    setSelectedTags([...selectedTags, tag]);
  };

  const handleTagSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    addTag(selectedTags);
  };

  return (
    <div>
      <div className='py-3 px-4 flex shadow-bottom'>
        <RiCloseLine
          size={18}
          color='#212121'
          onClick={handleCloseBtnClick}
          className='cursor-pointer'
        />
        <p className='font-manrope font-bold text-13 flex-1 leading-5 text-gray-700 text-center '>
          Assign Tags
        </p>
        <button
          type='button'
          className='text-success font-manrope text-xs leading-3 font-semibold'
          disabled={loadingAssignTags}
          onClick={handleTagSave}
        >
          Save
        </button>
      </div>
      <div className='py-1.5 px-4 text-left'>
        <S.SearchFieldWrapper>
          <SearchInput
            size='large'
            placeholder={t('searchForTags...', 'Search for tags...')}
            prefix={<FiSearch />}
            onChange={onSearch}
            className='search-tag'
          />
        </S.SearchFieldWrapper>
      </div>
      <div className='pb-3 px-4 text-left'>
        <TagList tags={tagList} handleTagSelect={handleTagSelect} selectedTags={selectedTags} />
      </div>
    </div>
  );
}
