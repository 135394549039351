import { useState, useEffect } from 'react';

/**
 * Custom hook for debouncing a value in React.
 * @param {T} value - The value to be debounced.
 * @param {number} delay=500 - The delay in milliseconds before updating the debounced value.
 * @returns {T} - The debounced value.
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 */

export default function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
