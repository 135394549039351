import React, { useMemo, useState, useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import useDebounce from 'hooks/useDebounce';
import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { agentsQueryParamVars, useAgentsVars } from './useAgentsVar';

export function useMembers() {
  const { setSearch } = useAgentsVars(agentsQueryParamVars);
  const membersParams = useReactiveVar<any>(agentsQueryParamVars);

  const [loadWorkspaceMembers, { data: query }] = useLazyQuery(WORKSPACE_MEMBERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    loadWorkspaceMembers({
      variables: {
        pageParams: membersParams,
      },
    });
  }, [loadWorkspaceMembers, membersParams]);

  const members = useMemo(() => query?.allWorkspaceMembers?.data?.edges ?? [], [query]);

  const WAIT_TIME_IN_MS = 500;
  const [searchedQuery, setSearchedQuery] = useState('');
  const debouncedQuery = useDebounce<string>(searchedQuery, WAIT_TIME_IN_MS);

  React.useEffect(() => {
    if (debouncedQuery) {
      setSearch({
        columns: ['firstname'],
        value: debouncedQuery,
      });
      return;
    }

    setSearch(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  const handleMemberSearch = (val: any) => {
    setSearchedQuery(val);
  };

  return {
    members,
    handleMemberSearch,
  };
}
