/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar } from '@apollo/client';
import { produce } from 'immer';

// Create the initial value
export const LIMIT = 20;
const initialParams = {
  first: LIMIT,
  filter: null,
  filters: null,
  q: 'Open',
  s: null,
  search: null,
};

const draftMessageParams = {
  messageId: '',
};

// Create the Query var and initialize it with the initial value
export const queryParamVars: any = makeVar(initialParams);

// Create the Query var for pinned contact logs and initialize it with the initial value
export const pinnedQParamVars: any = makeVar(initialParams);

export const draftMessageVars: any = makeVar(draftMessageParams);

// expose the operations done on the state
export function useQVars(qVars: any) {
  const setSearch = (searchParams: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft.s = searchParams ? 'search' : null;
      draft.search = searchParams;
    });
    qVars(updatedQvars);
  };

  const setFilters = (filters: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft.filters = filters;
    });
    qVars(updatedQvars);
  };

  const setQParams = (q: any) => {
    const qvars = qVars();
    const updatedQvars: any = produce(qvars, (draft: any) => {
      draft.q = q;
    });
    qVars(updatedQvars);
  };

  return {
    setSearch,
    setFilters,
    setQParams,
  };
}
