import React from 'react';
import styled from 'styled-components';

export interface AuxProps {
  children: React.ReactNode;
  hasWorkspace?: string;
}

const Wrapper = styled(({ showNavBar, ...rest }) => <main {...rest} />)<{
  // Temporary fix to handle state for no workspace
  showNavBar: string;
}>`
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: ${props => props.gridTemplateColumns ?? '60px auto'};
  grid-template-rows: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  .sideNavbar-container {
    z-index: 2000;
  }
`;

const Container = ({ children, hasWorkspace }: AuxProps) => {
  return <Wrapper showNavBar={hasWorkspace}>{children}</Wrapper>;
};

export default React.memo(Container);
