import { ReactElement, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash.isempty';

import { useTwilioContext } from 'lib/twilio';
import { ACTIONS, CALL_WIDGET_STATUS, ENDED_CALL_STATES } from 'lib/twilio/constants';
import { NOTES_MAX_LENGTH } from 'schema-validation';
import useAppContext from 'hooks/useAppContext';

import { StyledTextAreaInput } from './Styles';
import { schema } from './schema';
import SaveDiscardActionBlock from '../save-discard-action';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import useDraftCallNotes, { MessageIdPayload } from '../hooks/useDraftCallNotes';

export default function NoteAdd(): ReactElement {
  const callEndedRef = useRef<boolean>();
  const prevConnectionRef = useRef<any>();
  const callStatusRef = useRef<string>();
  const directionRef = useRef<string>();
  const salesDialerWidgetRef = useRef<boolean>();

  const noteTextRef = useRef<string>();

  const [noteText, setNoteText] = useState('');
  const { t } = useTranslation();
  const { setUnSavedCallWidgetAction } = useAppContext();
  const {
    dispatch,
    state: { callEnded, prevConnection, status, direction, salesDialerWidget },
  } = useTwilioContext();

  callEndedRef.current = callEnded;
  prevConnectionRef.current = prevConnection;
  callStatusRef.current = status;
  directionRef.current = direction;
  salesDialerWidgetRef.current = salesDialerWidget;

  const { closeDrawer, saveButtonLabel } = useCallWidgetDrawerContext();
  const { note: savedNote, handleNoteSave, loadingSaveNote } = useActiveCallWidgetContext();
  const { saveDraftMessage, createMessageId } = useDraftCallNotes();
  noteTextRef.current = noteText;

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm<{ title: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      title: savedNote?.title || '',
    },
  });
  const hasErrors = !isEmpty(errors);
  const disableSaveButton = hasErrors || !watch('title');

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteText(e.target.value);
  };

  const formSubmit = async (val: any) => {
    const { title } = val;
    if (hasErrors) return;
    handleNoteSave?.(title);
  };

  useEffect(() => {
    setUnSavedCallWidgetAction?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (
        !salesDialerWidgetRef.current &&
        (callEndedRef.current ||
          (directionRef.current === 'Incoming' &&
            callStatusRef.current === CALL_WIDGET_STATUS.INITIATED))
      ) {
        const contactNumberTo = prevConnectionRef.current?.customParameters?.get('To');
        const contactNumberFrom = prevConnectionRef.current?.customParameters?.get('from');
        const contactNumber = contactNumberTo || contactNumberFrom;
        const callerChannelId = prevConnectionRef.current?.customParameters?.get('channel_sid');
        const messageIdPayload: MessageIdPayload = {
          channelId: callerChannelId,
          contactNumber: contactNumber?.trim(),
        };
        const draftNotesId = createMessageId(messageIdPayload);
        if (
          draftNotesId &&
          directionRef.current === 'Incoming' &&
          callStatusRef.current === CALL_WIDGET_STATUS.INITIATED
        ) {
          saveDraftMessage(draftNotesId, noteTextRef.current || '');
        }
        if (
          callEndedRef.current &&
          callStatusRef.current &&
          ENDED_CALL_STATES.includes(callStatusRef.current)
        ) {
          dispatch({
            type: ACTIONS.CLOSE_PHONE_WIDGET,
            data: {},
          });
        }
      }
      setUnSavedCallWidgetAction?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (savedNote) {
      setValue('title', savedNote?.title);
    }
  }, [savedNote, setValue]);

  const FORM_ID = 'add-notes-form';

  return (
    <form
      className='flex flex-col h-full px-4 pb-4'
      id={FORM_ID}
      onSubmit={handleSubmit(formSubmit)}
    >
      <StyledTextAreaInput
        name='title'
        id='noteBox'
        control={control}
        errors={errors}
        placeholder={t('saveNotes', 'Save notes here..')}
        maxLength={NOTES_MAX_LENGTH}
        autoSize={{ minRows: 10 }}
        onChange={handleOnChange}
      />

      <SaveDiscardActionBlock
        disabled={disableSaveButton}
        isSaveLoading={loadingSaveNote}
        onDiscard={closeDrawer}
        formId={FORM_ID}
        htmlType='submit'
        saveButtonLabel={saveButtonLabel}
      />
    </form>
  );
}
