import { CallActionWrapper } from './Styles';

export interface ICallActionProps {
  label?: string;
  icon: React.ReactNode;
  className?: string;
  onClick?: (args?: any) => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'info' | 'danger';
}

export const CallAction = ({
  label,
  icon,
  onClick,
  disabled,
  className,
  variant = 'primary',
}: ICallActionProps) => {
  return (
    <CallActionWrapper
      className={`${variant} ${className ?? ''}`}
      type='button'
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {icon}
      {label && <span>{label}</span>}
    </CallActionWrapper>
  );
};
