import { useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { OutgoingProps } from 'lib/twilio/types';
import { ToastMessage } from 'components/atoms';
import { NUMBER_SETTINGS } from 'graphql/channel/settings';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { isNetworkAvailable } from 'services/apollo/reactiveVars';

export function useOutgoingCallHandler(props?: any) {
  const { t } = useTranslation();
  const isOnline = useReactiveVar(isNetworkAvailable);

  const [outgoingCallParams, setOutgoingCallParams] = useState<OutgoingProps>({});

  const [loadNumberSettings] = useLazyQuery(NUMBER_SETTINGS, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      const sourceNumberDetail = parsePhoneNumber(outgoingCallParams?.From ?? '');
      const targetNumberDetail = parsePhoneNumber(outgoingCallParams?.To ?? '');
      const { error, data } = response.numberSettings;
      if (error === null) {
        if (
          !data?.internationalCallAndMessages &&
          sourceNumberDetail.country !== targetNumberDetail.country
        ) {
          ToastMessage({
            content: `${t(
              'toast.internationalCallsDisabled',
              'International Calls and Messages Disabled. Contact your Administrator',
            )}`,
            type: 'danger',
          });
          return;
        }

        props.callback(outgoingCallParams);
      }
    },
  });

  const callClient = (params: any) => {
    if (!isOnline) {
      ToastMessage({
        content: `${t(
          'toast.callFailedNoInternetConnection',
          "Can't make call. Please check your internet connection",
        )}`,
        type: 'danger',
      });
      return;
    }
    const channelId = params?.channel_sid;
    setOutgoingCallParams(params);
    if (channelId) {
      loadNumberSettings({
        variables: {
          channel: channelId,
        },
      });
    }
  };

  return { callClient };
}
