export function getSubdomain() {
  const { host } = window.location;
  let subDomain = '';
  const QFDArray = host?.split('.');
  if (QFDArray.length >= 3) {
    [subDomain] = QFDArray;
  }
  return subDomain;
}

export function customEncodeURIComponent(str?: string) {
  if (!str) return str;
  // Encode the string with encodeURIComponent
  let encodedStr = encodeURIComponent(str);
  // Manually replace the encoded %2B (which is +) back to +
  encodedStr = encodedStr.replace(/%2B/g, '+');
  return encodedStr;
}
