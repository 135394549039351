export default function GlobeIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 16.4999C13.1421 16.4999 16.5 13.142 16.5 8.99988C16.5 4.85774 13.1421 1.49988 9 1.49988C4.85786 1.49988 1.5 4.85774 1.5 8.99988C1.5 13.142 4.85786 16.4999 9 16.4999Z'
        stroke='currentColor'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 9H16.5'
        stroke='currentColor'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 1.49988C10.876 3.55364 11.9421 6.2189 12 8.99988C11.9421 11.7809 10.876 14.4461 9 16.4999C7.12404 14.4461 6.05794 11.7809 6 8.99988C6.05794 6.2189 7.12404 3.55364 9 1.49988V1.49988Z'
        stroke='currentColor'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
