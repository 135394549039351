import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

import * as path from 'constants/routes';
import useScreenType from 'hooks/useScreenType';
import { useContactsQuery } from 'components/organisms/contact';

const filterContact = (query: string, list: any, keys: string[]) => {
  const lowSearch = query.toLowerCase();
  return list?.filter((item: any) =>
    keys?.some(key => String(item[key]).toLowerCase().includes(lowSearch)),
  );
};
export function useHandlers() {
  const history = useHistory();
  const { isBigScreen } = useScreenType();
  const { contactList, counts, loading, called } = useContactsQuery();
  const [selectedContactId, setSelectedContactId] = useState(() => undefined);
  const [showContactDetailDrawer, setShowContactDetailDrawer] = useState(false);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    setContacts(contactList);
  }, [contactList]);

  const getSearchedQuery = (searchedValue: string) => {
    const filtered = filterContact(searchedValue, contactList, ['name', 'email']);
    setContacts(filtered);
  };

  const toggleContactDetailDrawer = () => setShowContactDetailDrawer(prev => !prev);

  // Check box

  const [checkedList, setCheckedList] = useState<string[]>(() => []);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onCheckAllChange = (evt: any) => {
    setCheckedList(
      evt.target.checked
        ? contacts?.filter?.((contact: any) => contact.type !== 'group')?.map((ctx: any) => ctx.id)
        : [],
    );
    setIndeterminate(false);
    setCheckAll(evt.target.checked);
  };

  const onRowChecked = useCallback(
    (evt: any) => {
      evt.preventDefault();
      const selected: any = evt.target.value;
      const isChecked = evt.target.checked;
      setCheckedList(prev =>
        isChecked ? [...prev, selected] : [...prev].filter(idx => idx !== selected),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleRowClick = useCallback(
    cId => () => {
      history.push(`${path.CONTACTS}/${cId}`);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isBigScreen && toggleContactDetailDrawer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBigScreen],
  );

  const clearSelectedContacts = () => {
    setCheckedList([]);
    setCheckAll(false);
  };

  useEffect(() => {
    setIndeterminate(checkedList?.length > 0 && checkedList?.length < counts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  return {
    counts,
    loading,
    called,
    contacts,
    handleRowClick,
    onRowChecked,
    onCheckAllChange,
    indeterminate,
    checkAll,
    contactList,
    checkedList,
    getSearchedQuery,
    showContactDetailDrawer,
    toggleContactDetailDrawer,
    clearSelectedContacts,
  };
}
