/* eslint-disable no-console */
/* TODO: Access token expiry case is handled in authlink instead of handling it in this file
   due to some errors encountered in handleError fn below while fetching new access token. */
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import decode, { JwtPayload } from 'jwt-decode';
import { API_ENDPOINT } from 'constants/endpoint';
import SecureLS from 'secure-ls';
import { handleLogoutWithoutHook } from './errorLink';
import { refreshAllTokenQuery, Tokens } from './constants';

interface TokenType {
  [Tokens.accessToken]: string;
  [Tokens.refreshToken]: string;
  [Tokens.authToken]: string;
}

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const logout = () => {
  const deviceId = localStorage.getItem('deviceId');
  handleLogoutWithoutHook();
  localStorage.setItem('deviceId', deviceId as string);
};
export const getAuthAccessTokens = () => {
  let parsedTokens: any = {};
  try {
    const tokens = ls.get('_tokens');
    parsedTokens = tokens && JSON.parse(tokens);
  } catch (e) {
    console.warn(e);
  }
  return parsedTokens;
};

const getTokens = () => {
  const { accessToken, refreshToken, authToken } = getAuthAccessTokens();
  return { accessToken, refreshToken, authToken };
};

const JwtRefreshLink = new TokenRefreshLink<TokenType>({
  accessTokenField: `responseTokens`,
  isTokenValidOrUndefined: async () => {
    const { accessToken, authToken } = getTokens();
    if (!accessToken || !authToken) {
      return true;
    }

    try {
      const { exp: expAccessToken } = decode<JwtPayload>(accessToken);
      const { exp: expAuthToken } = decode<JwtPayload>(authToken);

      if (
        (expAccessToken && Date.now() >= expAccessToken * 1000) ||
        (expAuthToken && Date.now() >= expAuthToken * 1000)
      ) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  },
  fetchAccessToken: () => {
    const tokens = getTokens() || {};
    return fetch(`${API_ENDPOINT}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `JWT ${tokens.refreshToken}`,
      },
      body: JSON.stringify({
        query: refreshAllTokenQuery,
        variables: {
          data: {
            client: 'web',
          },
        },
      }),
    }).then(response => response.json());
  },
  handleResponse: () => (res: any) => {
    const { data, error } = res?.data?.refreshAllToken ?? {};
    if (error && error.code === 'token_expired') {
      logout();
      return {};
    }
    return { responseTokens: data };
  },
  handleFetch: newTokens => {
    const tokens = getAuthAccessTokens();
    const updatedTokens = {
      ...tokens,
      ...newTokens,
    };
    ls.set('_tokens', JSON.stringify(updatedTokens));
  },
  handleError: err => {
    console.error(err);
    logout();
  },
});

export default JwtRefreshLink;
