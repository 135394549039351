import { Menu } from 'antd';
import styled from 'styled-components';

import { TextAreaInput } from 'components/molecules/fields';

export const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    padding: 6px 0;
    > h3 {
      padding: 7px 14px 3px;
      font-weight: 500;
      line-height: 145%; /* 20.3px */
    }
    li {
      color: var(--Text-500-Component, #333036);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding: 8px 14px;
      svg {
        color: #007aff;
      }
      + li {
        box-shadow: 0px -1px 0px 0px var(--Stroke-100-Default, #e5e4e7);
      }
    }
  }
`;

export const InputField = styled(TextAreaInput)`
  border-radius: 14px;
  border: 1px solid var(--Stroke-100-Default, #e5e4e7);
  background: var(--Background-200, #faf9fb);
  height: 48px !important;
  padding: 12px 14px;
  &::placeholder {
    color: var(--Text-300-Component-2, #66616b);
  }
`;

export const SendButton = styled.button`
  border-radius: 20px;
  width: 34px;
  padding: 7px;
  flex-shrink: 0;
  height: 34px;
  background: var(--Primary-300-Default, #5301b1);
  &:disabled {
    background: var(--Text-200-Disabled, #99949e);
  }
  svg {
    color: white;
  }
`;
