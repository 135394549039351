export const CAMPAIGN_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  COMPLETED: 'completed',
  ENDED: 'ended',
  PAUSED: 'paused',
  INPROGRESS: 'inprogress',
};

export const CAMPAIGN_ACTIONS = {
  start: 'START',
  pause: 'PAUSE',
  end: 'END',
  reattempt: 'REATTEMPT',
  resume: 'RESUME',
};
