import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Drawer, Divider, Skeleton } from 'antd';

import useScreenType from 'hooks/useScreenType';
import { ContactProvider } from 'components/organisms/contact/ContactContext';
import { Contact as ContactDetail, useContactsQuery } from 'components/organisms/contact';
import uuid from 'components/utils/uuid';
import { Button } from 'components/atoms';
import { UserAddIcon } from 'components/atoms/icon';
import { ContactBook } from 'components/atoms/vectors';
import { ImportContactModal } from 'components/organisms/contact/modals';
import { AddContactModal } from 'components/organisms';
import * as S from './Styles';
import { HandlersContext } from './HandlersContext';
import TopBar from '../topbar/TopBar';
import { Header } from './Header';
import ContactList from './List';

export default function ContactTable() {
  const {
    counts,
    contacts,
    checkedList,
    onRowChecked,
    handleRowClick,
    showContactDetailDrawer,
    toggleContactDetailDrawer,
  } = useContext<any>(HandlersContext);
  const { contactId } = useParams<Record<string, string | undefined>>();
  const { isBigScreen, isMobile } = useScreenType();

  const NUMBER_OF_CONTACTS = counts;
  const DRAWER_WIDTH = isMobile ? 260 : 360;

  const [visibleAddContactModal, setVisibleAddContactModal] = useState<boolean>(false);
  const [visibleImportContactModal, setVisibleImportContactModal] = useState(false);
  const toggleAddContactModal = () => setVisibleAddContactModal(prevState => !prevState);
  const toggleImportContactModal = () => setVisibleImportContactModal(prevState => !prevState);
  const { loading, hasContact, filteredTags, called } = useContactsQuery();

  if (loading && !filteredTags?.length) {
    return (
      <div className='w-full'>
        <TopBar numberOfContacts={0} />
        <S.SkeletonContainer className='pl-17.5 pr-6 py-6'>
          {Array.from(Array(18), (item: number, index: number) =>
            index % 4 !== 0 ? (
              <div key={uuid()} className='flex gap-3 py-2'>
                <Skeleton.Avatar size={32} active />
                <Skeleton paragraph={{ rows: 1 }} active />
              </div>
            ) : (
              <Divider key={uuid()} />
            ),
          )}
        </S.SkeletonContainer>
      </div>
    );
  }

  if (!loading && called && !hasContact && filteredTags?.length === 0) {
    return (
      <>
        <TopBar numberOfContacts={0} />
        <S.EmptyContact className='h-full w-full flex'>
          <div className='m-auto'>
            <div className='mx-auto h-50 w-50 bg-info-100 rounded-full flex items-center justify-center mb-8'>
              <ContactBook />
            </div>
            <div className='text-center w-100'>
              <h4
                className='text-2xl text-gray-700 leading-9 font-bold mb-3'
                data-cy='nocontact-title'
              >
                No Contacts
              </h4>
              <p className='text-base leading-6 text-gray' data-cy='nocontact-desc'>
                No existing contacts. Add your first contact here to start a conversation or{' '}
                <button
                  data-cy='bulk-upload-text-btn'
                  className='text-info'
                  type='button'
                  onClick={toggleImportContactModal}
                >
                  bulk upload
                </button>{' '}
                as a csv
              </p>
              <Button
                variant='success'
                className='mx-auto mt-10'
                onClick={toggleAddContactModal}
                size='large'
                icon={<UserAddIcon />}
                data-cy='contacts-add-button'
              >
                Add New Contact
              </Button>
            </div>
          </div>
        </S.EmptyContact>
        {visibleAddContactModal && (
          <AddContactModal
            isModalOpen={visibleAddContactModal}
            onCancel={toggleAddContactModal}
            onModalCancel={toggleAddContactModal}
            showAdditionalFields
          />
        )}
        <ImportContactModal
          isModalOpen={visibleImportContactModal}
          onCancel={toggleImportContactModal}
          onModalCancel={toggleImportContactModal}
        />
      </>
    );
  }

  return (
    <div className='flex w-full h-full'>
      <div className='w-full shadow-right'>
        <TopBar numberOfContacts={NUMBER_OF_CONTACTS} />
        <div>
          <Header />
          <ContactList
            items={contacts}
            onRowSelect={onRowChecked}
            onRowClick={handleRowClick}
            checkedItems={checkedList}
          />
        </div>
      </div>
      <ContactProvider>
        {contactId &&
          (isBigScreen ? (
            <ContactDetail />
          ) : (
            <Drawer
              width={DRAWER_WIDTH}
              placement='right'
              visible={showContactDetailDrawer}
              onClose={toggleContactDetailDrawer}
              closable={false}
            >
              <ContactDetail />
            </Drawer>
          ))}
      </ContactProvider>
    </div>
  );
}
