import styled from 'styled-components';
import Suspense from 'services/Suspense';

import WorkspaceList from './WorkspaceList';

const Wrapper = styled.div`
  height: inherit;
  z-index: 9999;
  + div {
    margin-left: 64px; // workspace-list-sidebar width
    width: calc(100vw - 64px);
  }
`;

export default function Index() {
  return (
    <Wrapper className='fixed bg-primary w-16 flex flex-col justify-between items-center py-3'>
      <Suspense>
        <WorkspaceList />
      </Suspense>
    </Wrapper>
  );
}
