export default function Notifyicon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.01497 2.18237C6.53244 2.18237 4.51494 4.19987 4.51494 6.68237V8.84987C4.51494 9.30737 4.31994 10.0049 4.08744 10.3949L3.22494 11.8274C2.69244 12.7124 3.05994 13.6949 4.03494 14.0249C7.26744 15.1049 10.755 15.1049 13.9875 14.0249C14.895 13.7249 15.2925 12.6524 14.7975 11.8274L13.935 10.3949C13.71 10.0049 13.515 9.30737 13.515 8.84987V6.68237C13.515 4.20737 11.49 2.18237 9.01497 2.18237Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M10.4029 2.39983C10.1704 2.33233 9.93043 2.27983 9.68293 2.24983C8.96293 2.15983 8.27293 2.21233 7.62793 2.39983C7.84543 1.84483 8.38543 1.45483 9.01543 1.45483C9.64543 1.45483 10.1854 1.84483 10.4029 2.39983Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.2646 14.2952C11.2646 15.5327 10.2521 16.5452 9.01463 16.5452C8.39963 16.5452 7.82963 16.2902 7.42465 15.8852C7.01965 15.4802 6.76465 14.9102 6.76465 14.2952'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeMiterlimit='10'
      />
    </svg>
  );
}
