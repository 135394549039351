import styled from 'styled-components';

export const WidgetBody = styled.div``;

export const FooterBtnWrapper = styled.div`
  display: block;
  > .ant-btn {
    border-radius: 8px;
    border: none;
    height: 40px;
    color: #fff;
  }
  > .ant-btn.call-accept {
    background: #4c9610;
    &:focus,
    &:hover {
      background: #39810b;
    }
  }
  > .ant-btn.call-decline {
    background: #db312b;
    &:focus,
    &:hover {
      background: #bc1f27;
    }
  }
  > .ant-btn.call-reject {
    background: rgba(226, 90, 85, 0.1);
    color: #e25a55;
    &:focus,
    &:hover {
      background: #f9dedd;
    }
  }
`;
