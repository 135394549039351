const AppStore = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='129'
    height='45'
    viewBox='0 0 129 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='0.5' width='129' height='44' rx='7' fill='#390179' />
    <path
      d='M30.7973 21.3667C30.8081 20.5321 31.0298 19.7137 31.4418 18.9877C31.8538 18.2618 32.4428 17.6518 33.1539 17.2147C32.7021 16.5695 32.1062 16.0386 31.4134 15.664C30.7205 15.2895 29.9499 15.0816 29.1627 15.057C27.4835 14.8807 25.8555 16.0618 24.9998 16.0618C24.1275 16.0618 22.81 15.0745 21.3913 15.1037C20.4736 15.1333 19.5793 15.4002 18.7954 15.8782C18.0115 16.3563 17.3649 17.0293 16.9184 17.8316C14.9844 21.18 16.427 26.101 18.2796 28.8076C19.2065 30.133 20.2898 31.6134 21.7072 31.5609C23.0943 31.5034 23.6123 30.6765 25.2866 30.6765C26.9454 30.6765 27.4314 31.5609 28.8776 31.5276C30.366 31.5034 31.3038 30.1963 32.1981 28.8584C32.8641 27.9141 33.3766 26.8703 33.7166 25.7659C32.8518 25.4002 32.1139 24.788 31.5948 24.0057C31.0757 23.2234 30.7983 22.3056 30.7973 21.3667Z'
      fill='white'
    />
    <path
      d='M28.0659 13.2758C28.8774 12.3016 29.2772 11.0494 29.1804 9.78516C27.9406 9.91538 26.7953 10.5079 25.9728 11.4448C25.5707 11.9024 25.2627 12.4349 25.0664 13.0116C24.8702 13.5884 24.7896 14.1982 24.8291 14.8062C25.4493 14.8125 26.0628 14.6781 26.6234 14.413C27.1841 14.148 27.6773 13.7591 28.0659 13.2758Z'
      fill='white'
    />
    <path
      d='M48.3311 28.2061H43.5977L42.4609 31.5626H40.4561L44.9395 19.1446H47.0225L51.5059 31.5626H49.4668L48.3311 28.2061ZM44.0879 26.6573H47.8399L45.9903 21.21H45.9385L44.0879 26.6573Z'
      fill='white'
    />
    <path
      d='M61.1885 27.0378C61.1885 29.8513 59.6826 31.6589 57.4102 31.6589C56.8345 31.689 56.262 31.5564 55.7582 31.2763C55.2544 30.9961 54.8397 30.5798 54.5616 30.0749H54.5186V34.5593H52.6602V22.5105H54.459V24.0163H54.4932C54.7841 23.5139 55.2059 23.0997 55.7135 22.8178C56.2211 22.536 56.7957 22.397 57.376 22.4157C59.6738 22.4157 61.1885 24.2321 61.1885 27.0378ZM59.2783 27.0378C59.2783 25.2048 58.3311 23.9997 56.8858 23.9997C55.4658 23.9997 54.5108 25.2302 54.5108 27.0378C54.5108 28.862 55.4658 30.0837 56.8858 30.0837C58.3311 30.0837 59.2783 28.8874 59.2783 27.0378Z'
      fill='white'
    />
    <path
      d='M71.1533 27.0378C71.1533 29.8513 69.6475 31.6589 67.375 31.6589C66.7994 31.689 66.2269 31.5564 65.7231 31.2763C65.2193 30.9961 64.8046 30.5798 64.5264 30.0749H64.4834V34.5593H62.625V22.5105H64.4238V24.0163H64.458C64.749 23.5138 65.1707 23.0997 65.6783 22.8178C66.186 22.536 66.7605 22.397 67.3408 22.4157C69.6387 22.4157 71.1533 24.2321 71.1533 27.0378ZM69.2432 27.0378C69.2432 25.2048 68.2959 23.9997 66.8506 23.9997C65.4307 23.9997 64.4756 25.2302 64.4756 27.0378C64.4756 28.862 65.4307 30.0837 66.8506 30.0837C68.2959 30.0837 69.2432 28.8874 69.2432 27.0378H69.2432Z'
      fill='white'
    />
    <path
      d='M77.7393 28.1025C77.877 29.3339 79.0733 30.1425 80.708 30.1425C82.2744 30.1425 83.4014 29.3339 83.4014 28.2235C83.4014 27.2597 82.7217 26.6825 81.1123 26.287L79.503 25.8993C77.2227 25.3485 76.1641 24.2821 76.1641 22.5517C76.1641 20.4091 78.0313 18.9374 80.6826 18.9374C83.3066 18.9374 85.1055 20.4091 85.166 22.5517H83.29C83.1777 21.3124 82.1533 20.5644 80.6563 20.5644C79.1592 20.5644 78.1348 21.3212 78.1348 22.4228C78.1348 23.3007 78.7891 23.8173 80.3897 24.2128L81.7578 24.5487C84.3057 25.1512 85.3643 26.1747 85.3643 27.9911C85.3643 30.3143 83.5137 31.7694 80.5703 31.7694C77.8164 31.7694 75.957 30.3485 75.8369 28.1024L77.7393 28.1025Z'
      fill='white'
    />
    <path
      d='M89.375 20.3672V22.5098H91.0967V23.9814H89.375V28.9727C89.375 29.748 89.7197 30.1094 90.4766 30.1094C90.6809 30.1058 90.885 30.0915 91.0879 30.0664V31.5293C90.7476 31.5928 90.4018 31.6216 90.0557 31.6152C88.2227 31.6152 87.5078 30.9267 87.5078 29.1709V23.9814H86.1914V22.5098H87.5078V20.3672H89.375Z'
      fill='white'
    />
    <path
      d='M92.0938 27.0371C92.0938 24.1885 93.7715 22.3984 96.3877 22.3984C99.0127 22.3984 100.683 24.1884 100.683 27.0371C100.683 29.8936 99.0215 31.6758 96.3877 31.6758C93.7548 31.6758 92.0938 29.8935 92.0938 27.0371ZM98.7891 27.0371C98.7891 25.083 97.8935 23.9297 96.3877 23.9297C94.8818 23.9297 93.9873 25.0918 93.9873 27.0371C93.9873 28.999 94.8818 30.1436 96.3877 30.1436C97.8935 30.1436 98.789 28.999 98.789 27.0371H98.7891Z'
      fill='white'
    />
    <path
      d='M102.215 22.5104H103.987V24.0514H104.03C104.15 23.5701 104.432 23.1448 104.829 22.8469C105.225 22.549 105.712 22.3967 106.208 22.4156C106.422 22.4149 106.636 22.4381 106.845 22.485V24.2233C106.574 24.1406 106.292 24.1027 106.01 24.111C105.74 24.1 105.471 24.1476 105.221 24.2505C104.971 24.3534 104.746 24.5091 104.562 24.707C104.378 24.9049 104.239 25.1403 104.155 25.3969C104.071 25.6536 104.043 25.9255 104.073 26.194V31.5641H102.215L102.215 22.5104Z'
      fill='white'
    />
    <path
      d='M115.413 28.9043C115.163 30.5478 113.562 31.6758 111.515 31.6758C108.881 31.6758 107.246 29.9111 107.246 27.0801C107.246 24.2402 108.89 22.3984 111.437 22.3984C113.941 22.3984 115.517 24.1191 115.517 26.8643V27.501H109.122V27.6133C109.093 27.9465 109.134 28.2821 109.244 28.598C109.355 28.9138 109.531 29.2026 109.761 29.4452C109.991 29.6877 110.271 29.8785 110.581 30.0048C110.89 30.1311 111.223 30.19 111.558 30.1777C111.997 30.2189 112.437 30.1172 112.814 29.8877C113.191 29.6583 113.484 29.3134 113.648 28.9043L115.413 28.9043ZM109.131 26.2021H113.657C113.674 25.9026 113.628 25.6027 113.524 25.3215C113.419 25.0403 113.257 24.7838 113.049 24.568C112.84 24.3523 112.59 24.1819 112.312 24.0677C112.035 23.9535 111.737 23.8979 111.437 23.9043C111.134 23.9025 110.834 23.9606 110.554 24.0754C110.274 24.1901 110.019 24.3592 109.805 24.5728C109.591 24.7865 109.421 25.0404 109.305 25.3201C109.189 25.5997 109.13 25.8995 109.131 26.2022V26.2021Z'
      fill='white'
    />
    <path
      d='M43.8545 9.79583C44.2441 9.76787 44.6351 9.82673 44.9992 9.96817C45.3633 10.1096 45.6915 10.3301 45.9601 10.6137C46.2286 10.8973 46.4309 11.237 46.5523 11.6083C46.6737 11.9796 46.7112 12.3732 46.6621 12.7607C46.6621 14.6669 45.6318 15.7627 43.8545 15.7627H41.6992V9.79583H43.8545ZM42.626 14.9188H43.751C44.0294 14.9355 44.308 14.8898 44.5665 14.7852C44.8251 14.6806 45.057 14.5196 45.2455 14.3141C45.434 14.1085 45.5743 13.8635 45.6562 13.5969C45.738 13.3303 45.7594 13.0488 45.7187 12.7728C45.7564 12.498 45.7329 12.2182 45.6498 11.9536C45.5667 11.6889 45.426 11.4459 45.238 11.242C45.0499 11.038 44.8191 10.8782 44.562 10.774C44.3049 10.6698 44.028 10.6237 43.751 10.639H42.626V14.9188Z'
      fill='white'
    />
    <path
      d='M47.7093 13.5083C47.681 13.2123 47.7148 12.9138 47.8086 12.6317C47.9025 12.3496 48.0542 12.0903 48.2542 11.8703C48.4541 11.6503 48.6978 11.4746 48.9697 11.3543C49.2415 11.234 49.5355 11.1719 49.8328 11.1719C50.1301 11.1719 50.4241 11.234 50.6959 11.3543C50.9678 11.4746 51.2115 11.6503 51.4114 11.8703C51.6114 12.0903 51.7631 12.3496 51.857 12.6317C51.9508 12.9138 51.9846 13.2123 51.9563 13.5083C51.9852 13.8045 51.9517 14.1035 51.8581 14.386C51.7645 14.6685 51.6129 14.9283 51.4129 15.1487C51.2129 15.3692 50.969 15.5453 50.6969 15.6658C50.4248 15.7864 50.1304 15.8486 49.8328 15.8486C49.5352 15.8486 49.2408 15.7864 48.9687 15.6658C48.6966 15.5453 48.4527 15.3692 48.2527 15.1487C48.0527 14.9283 47.9011 14.6685 47.8075 14.386C47.7139 14.1035 47.6804 13.8045 47.7093 13.5083ZM51.0423 13.5083C51.0423 12.5322 50.6038 11.9614 49.8343 11.9614C49.0618 11.9614 48.6273 12.5322 48.6273 13.5083C48.6273 14.4922 49.0618 15.0586 49.8343 15.0586C50.6038 15.0585 51.0423 14.4882 51.0423 13.5083H51.0423Z'
      fill='white'
    />
    <path
      d='M57.6016 15.7607H56.6797L55.749 12.4443H55.6787L54.752 15.7607H53.8389L52.5977 11.2578H53.499L54.3057 14.6938H54.3721L55.2979 11.2578H56.1504L57.0762 14.6938H57.1465L57.9492 11.2578H58.8379L57.6016 15.7607Z'
      fill='white'
    />
    <path
      d='M59.8818 11.2593H60.7373V11.9747H60.8037C60.9164 11.7177 61.1064 11.5024 61.3472 11.3586C61.5881 11.2147 61.8679 11.1497 62.1475 11.1724C62.3666 11.1559 62.5866 11.189 62.7912 11.2691C62.9958 11.3492 63.1797 11.4743 63.3294 11.6351C63.4791 11.7959 63.5907 11.9884 63.6559 12.1982C63.7211 12.408 63.7382 12.6299 63.7061 12.8472V15.7622H62.8174V13.0704C62.8174 12.3467 62.5029 11.9869 61.8457 11.9869C61.6969 11.9799 61.5484 12.0053 61.4104 12.0611C61.2723 12.1169 61.148 12.2019 61.0459 12.3103C60.9437 12.4187 60.8663 12.5479 60.8187 12.689C60.7712 12.8301 60.7547 12.9799 60.7705 13.128V15.7623H59.8818L59.8818 11.2593Z'
      fill='white'
    />
    <path d='M65.1221 9.5H66.0107V15.7607H65.1221V9.5Z' fill='white' />
    <path
      d='M67.2463 13.5083C67.2181 13.2124 67.2519 12.9138 67.3458 12.6317C67.4397 12.3497 67.5914 12.0903 67.7914 11.8703C67.9914 11.6503 68.2351 11.4746 68.507 11.3543C68.7788 11.234 69.0728 11.1719 69.3701 11.1719C69.6674 11.1719 69.9614 11.234 70.2333 11.3543C70.5052 11.4746 70.7489 11.6503 70.9489 11.8703C71.1488 12.0903 71.3006 12.3497 71.3945 12.6317C71.4883 12.9138 71.5222 13.2124 71.4939 13.5083C71.5227 13.8046 71.4892 14.1036 71.3956 14.3861C71.302 14.6686 71.1503 14.9284 70.9503 15.1488C70.7503 15.3692 70.5064 15.5454 70.2342 15.6659C69.9621 15.7864 69.6678 15.8487 69.3701 15.8487C69.0725 15.8487 68.7782 15.7864 68.506 15.6659C68.2339 15.5454 67.99 15.3692 67.79 15.1488C67.59 14.9284 67.4383 14.6686 67.3446 14.3861C67.251 14.1036 67.2175 13.8046 67.2463 13.5083ZM70.5793 13.5083C70.5793 12.5323 70.1409 11.9615 69.3713 11.9615C68.5989 11.9615 68.1644 12.5323 68.1644 13.5083C68.1644 14.4922 68.5989 15.0586 69.3713 15.0586C70.1409 15.0586 70.5794 14.4883 70.5794 13.5083H70.5793Z'
      fill='white'
    />
    <path
      d='M72.4297 14.4883C72.4297 13.6777 73.0332 13.2104 74.1045 13.144L75.3242 13.0737V12.6851C75.3242 12.2095 75.0098 11.9409 74.4023 11.9409C73.9063 11.9409 73.5625 12.123 73.4639 12.4414H72.6035C72.6943 11.668 73.4219 11.1719 74.4434 11.1719C75.5723 11.1719 76.209 11.7339 76.209 12.6851V15.7617H75.3535V15.1289H75.2832C75.1405 15.3559 74.9401 15.5409 74.7025 15.6652C74.4648 15.7894 74.1985 15.8483 73.9307 15.8359C73.7416 15.8556 73.5506 15.8354 73.3698 15.7767C73.1891 15.718 73.0226 15.6221 72.8812 15.4951C72.7398 15.3681 72.6265 15.2129 72.5488 15.0395C72.471 14.8661 72.4304 14.6783 72.4297 14.4883ZM75.3242 14.1035V13.727L74.2246 13.7974C73.6045 13.8389 73.3232 14.0498 73.3232 14.4468C73.3232 14.852 73.6748 15.0879 74.1582 15.0879C74.2999 15.1022 74.443 15.0879 74.579 15.0458C74.715 15.0037 74.8411 14.9347 74.9499 14.8429C75.0587 14.751 75.1479 14.6382 75.2122 14.5112C75.2765 14.3842 75.3146 14.2455 75.3242 14.1035Z'
      fill='white'
    />
    <path
      d='M77.376 13.5073C77.376 12.0845 78.1074 11.1831 79.2451 11.1831C79.5265 11.1702 79.8058 11.2376 80.0504 11.3775C80.2949 11.5173 80.4945 11.724 80.626 11.9731H80.6924V9.5H81.5811V15.7607H80.7295V15.0493H80.6592C80.5176 15.2968 80.311 15.5008 80.0617 15.6393C79.8125 15.7778 79.5301 15.8455 79.2451 15.8349C78.0996 15.835 77.376 14.9336 77.376 13.5073ZM78.294 13.5073C78.294 14.4624 78.7442 15.0371 79.4971 15.0371C80.2461 15.0371 80.709 14.4541 80.709 13.5112C80.709 12.5728 80.2412 11.9815 79.4971 11.9815C78.749 11.9815 78.2939 12.5601 78.2939 13.5073H78.294Z'
      fill='white'
    />
    <path
      d='M85.2581 13.5083C85.2298 13.2123 85.2636 12.9138 85.3575 12.6317C85.4513 12.3496 85.6031 12.0903 85.803 11.8703C86.003 11.6503 86.2467 11.4746 86.5185 11.3543C86.7904 11.234 87.0844 11.1719 87.3816 11.1719C87.6789 11.1719 87.9729 11.234 88.2447 11.3543C88.5166 11.4746 88.7603 11.6503 88.9602 11.8703C89.1602 12.0903 89.3119 12.3496 89.4058 12.6317C89.4996 12.9138 89.5335 13.2123 89.5052 13.5083C89.534 13.8045 89.5006 14.1035 89.407 14.386C89.3134 14.6685 89.1617 14.9283 88.9617 15.1487C88.7617 15.3692 88.5178 15.5453 88.2457 15.6658C87.9736 15.7864 87.6793 15.8486 87.3816 15.8486C87.084 15.8486 86.7897 15.7864 86.5175 15.6658C86.2454 15.5453 86.0015 15.3692 85.8015 15.1487C85.6015 14.9283 85.4499 14.6685 85.3563 14.386C85.2627 14.1035 85.2292 13.8045 85.2581 13.5083ZM88.5911 13.5083C88.5911 12.5322 88.1526 11.9614 87.3831 11.9614C86.6106 11.9614 86.1761 12.5322 86.1761 13.5083C86.1761 14.4922 86.6107 15.0586 87.3831 15.0586C88.1526 15.0585 88.5911 14.4882 88.5911 13.5083Z'
      fill='white'
    />
    <path
      d='M90.6973 11.2593H91.5527V11.9747H91.6191C91.7318 11.7177 91.9218 11.5024 92.1627 11.3586C92.4035 11.2147 92.6833 11.1497 92.9629 11.1724C93.182 11.1559 93.402 11.189 93.6066 11.2691C93.8112 11.3492 93.9951 11.4743 94.1448 11.6351C94.2945 11.7959 94.4061 11.9884 94.4713 12.1982C94.5365 12.408 94.5536 12.6299 94.5215 12.8472V15.7622H93.6328V13.0704C93.6328 12.3467 93.3184 11.9869 92.6611 11.9869C92.5124 11.9799 92.3639 12.0053 92.2258 12.0611C92.0878 12.1169 91.9634 12.2019 91.8613 12.3103C91.7592 12.4187 91.6817 12.5479 91.6342 12.689C91.5866 12.8301 91.5702 12.9799 91.5859 13.128V15.7623H90.6973V11.2593Z'
      fill='white'
    />
    <path
      d='M99.5439 10.1367V11.2783H100.52V12.0269H99.5439V14.3423C99.5439 14.814 99.7383 15.0205 100.181 15.0205C100.294 15.0201 100.407 15.0133 100.52 15V15.7402C100.36 15.7688 100.198 15.784 100.036 15.7856C99.0479 15.7856 98.6543 15.438 98.6543 14.5698V12.0268H97.9395V11.2783H98.6543V10.1367H99.5439Z'
      fill='white'
    />
    <path
      d='M101.732 9.5H102.613V11.9815H102.684C102.802 11.7221 102.997 11.5055 103.243 11.3612C103.488 11.2168 103.773 11.1518 104.057 11.1748C104.275 11.1629 104.492 11.1993 104.695 11.2814C104.897 11.3634 105.079 11.4891 105.227 11.6494C105.375 11.8098 105.486 12.0008 105.552 12.209C105.617 12.4171 105.636 12.6372 105.607 12.8535V15.7607H104.718V13.0728C104.718 12.3535 104.383 11.9893 103.755 11.9893C103.602 11.9767 103.449 11.9977 103.305 12.0508C103.161 12.1038 103.031 12.1877 102.923 12.2964C102.815 12.4051 102.732 12.5362 102.68 12.6803C102.628 12.8244 102.608 12.9782 102.621 13.1308V15.7607H101.732L101.732 9.5Z'
      fill='white'
    />
    <path
      d='M110.79 14.546C110.669 14.9575 110.407 15.3135 110.051 15.5516C109.694 15.7898 109.265 15.8949 108.839 15.8487C108.542 15.8565 108.247 15.7997 107.975 15.6822C107.702 15.5647 107.458 15.3892 107.26 15.1681C107.063 14.947 106.915 14.6854 106.828 14.4016C106.742 14.1178 106.718 13.8185 106.758 13.5245C106.719 13.2296 106.743 12.9297 106.83 12.645C106.916 12.3603 107.063 12.0976 107.26 11.8745C107.457 11.6514 107.699 11.4732 107.971 11.352C108.243 11.2308 108.537 11.1694 108.835 11.172C110.088 11.172 110.843 12.028 110.843 13.442V13.752H107.664V13.8018C107.65 13.9671 107.671 14.1334 107.725 14.2902C107.779 14.4469 107.865 14.5906 107.978 14.7121C108.091 14.8335 108.228 14.9301 108.38 14.9955C108.533 15.0609 108.697 15.0937 108.863 15.0918C109.076 15.1174 109.291 15.0791 109.482 14.9819C109.672 14.8847 109.83 14.7329 109.934 14.5459L110.79 14.546ZM107.664 13.0948H109.938C109.949 12.9437 109.929 12.7919 109.878 12.6492C109.827 12.5064 109.747 12.376 109.642 12.2661C109.538 12.1562 109.412 12.0694 109.272 12.0113C109.132 11.9531 108.981 11.9248 108.83 11.9283C108.676 11.9264 108.523 11.9552 108.381 12.0132C108.239 12.0711 108.109 12.157 108.001 12.2657C107.892 12.3744 107.806 12.5038 107.748 12.6462C107.691 12.7886 107.662 12.9411 107.664 13.0948H107.664Z'
      fill='white'
    />
  </svg>
);
export default AppStore;
