import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { ToastMessage } from 'components/atoms';
import { INVITE_MEMBER } from 'graphql/foundation';

export function useInvite(props: any) {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [isBtnDisabled, setBtnDisabled] = useState(true);

  const [inviteMemberMutation, { loading: requesting }] = useMutation(INVITE_MEMBER, {
    onCompleted: resData => {
      const { error } = resData.inviteMember;

      if (error === null) {
        props?.callback?.();
        props.openInvitationSentModal(email);
        return;
      }
      ToastMessage({ content: error.message, type: 'danger' });
    },
    onError: () => {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });
  const inviteMember = async (payload: any) => {
    await inviteMemberMutation({
      variables: {
        data: payload,
      },
      update: (cache, { data: { inviteMember: response } }) => {
        if (response?.data) {
          cache.modify({
            fields: {
              allInvites: () => {},
            },
          });
        }
      },
    });
  };

  const onEmailChange = (payload: any, setValue: any) => {
    setEmail(payload);
    setBtnDisabled(payload === '');
    setValue('email', payload);
  };

  return {
    email,
    requesting,
    isBtnDisabled,
    setBtnDisabled,
    inviteMember,
    onEmailChange,
  };
}
