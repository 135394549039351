import { FETCH_AUTH_DATA } from 'graphql/authorization/query';
import cache from 'services/apollo/cache';

export const privilegeList = {
  main_screen: {
    workspace: {
      view_switch_workspace: 'view_switch_workspace',
      create_new_workspace: 'create_new_workspace',
      edit_profile: 'edit_profile',
    },
    navigation: {
      search_box: 'search_box',
      show_dialer: 'show_dialer',
      dashboard_view: 'dashboard_view',
      contact_view: 'contact_view',
      settings_view: 'settings_view',
      numbers_add_new: 'numbers_add_new',
      numbers_view_details: 'numbers_view_details',
      numbers_do_not_disturb: 'numbers_do_not_disturb',
      numbers_settings: 'numbers_settings',
      members_add_new: 'members_add_new',
      teams_add_new: 'teams_add_new',
      tags_add_new: 'tags_add_new',
      tags_view_details: 'tags_view_details',
    },
  },
  contacts: {
    header: {
      search_contact: 'search_contact',
      add_new_contact: 'add_new_contact',
      import_csv: 'import_csv',
    },
    body: {
      table_view: 'table_view',
    },
  },
  settings: {
    my_profile: {
      edit_full_name: 'edit_full_name',
      edit_email: 'edit_email',
      change_password: 'change_password',
      change_profile_picture: 'change_profile_picture',
    },
    my_numbers: {
      port_number_config: 'port_number_config',
      add_new_number: 'add_new_number',
      view_number_list: 'view_number_list',
      edit_number_details: 'edit_number_details',
      share_access: 'share_access',
      edit_toggle_intn_calls: 'edit_toggle_intn_calls',
      delete_number: 'delete_number',
      auto_record_calls: 'auto_record_calls',
      auto_voicemail_transcription: 'auto_voicemail_transcription',
    },
    members: {
      add_new_member: 'add_new_member',
      members_list_view: 'members_list_view',
      members_view_assigned_number: 'members_view_assigned_number',
      delete_member: 'delete_member',
      invited_members_view: 'invited_members_view',
      invited_members_resend_invite: 'invited_members_resend_invite',
    },
    my_team: {
      create_new_team: 'create_new_team',
      team_list_view: 'team_list_view',
      team_edit: 'team_edit',
      team_delete: 'team_delete',
    },
    contacts: {
      add_new_integration: 'add_new_integration',
      integration_google: 'integration_google',
      integration_pipedrive: 'integration_pipedrive',
      csv_import: 'csv_import',
      delete_all_contacts: 'delete_all_contacts',
    },
    workspace: {
      update_profile_picture: 'update_profile_picture',
      change_workspace_name: 'change_workspace_name',
      enable_notification: 'enable_notification',
      delete_workspace: 'delete_workspace',
    },
    integration: {
      view_enabled: 'view_enabled',
      view_other_integration: 'view_other_integration',
    },
    billing_plans: {
      overview_change_plan: 'overview_change_plan',
      overview_purchase_credit: 'overview_purchase_credit',
      overview_manage_card_add: 'overview_manage_card_add',
      overview_manage_card_delete: 'overview_manage_card_delete',
      overview_hide_krispcall_branding: 'overview_hide_krispcall_branding',
      overview_notification_auto_recharge: 'overview_notification_auto_recharge',
      ovierview_cancel_subscription: 'ovierview_cancel_subscription',
      billing_info_save: 'billing_info_save',
      billing_receipts_view_list: 'billing_receipts_view_list',
      billing_receipts_view_invoice: 'billing_receipts_view_invoice',
      billing_receipts_download_invoice: 'billing_receipts_download_invoice',
    },
    devices: {
      select_input_device: 'select_input_device',
      select_output_devices: 'select_output_devices',
      adjust_input_volume: 'adjust_input_volume',
      adjust_output_volume: 'adjust_output_volume',
      microphone_test: 'microphone_test',
      cancel_echo: 'cancel_echo',
      reduce_noise: 'reduce_noise',
    },
    notifications: {
      enable_desktop_notification: 'enable_desktop_notification',
      enable_new_calls_message: 'enable_new_calls_message',
      enable_new_leads: 'enable_new_leads',
      enable_flash_taskbar: 'enable_flash_taskbar',
    },
    language: {
      language_switch: 'language_switch',
    },
  },
  dashboard: {
    main_screen: {
      view: 'view',
      listen_live_call: 'listen_live_call',
      whisper_live_call: 'whisper_live_call',
      barge_live_call: 'barge_live_call',
    },
  },
};

export function usePrivilege(screen: string, module: string, feature: string) {
  const authData: any = cache?.readQuery({
    query: FETCH_AUTH_DATA,
  });
  const roleData = authData?.permissions?.data;
  const hasAccess = roleData?.[screen]?.[module]?.[feature] ?? false;

  return [hasAccess];
}
