import { Divider } from 'antd';
import { useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';
import { showCallboxContactDetail, showCallboxSearchDrawer } from 'services/apollo/reactiveVars';
import { ContactContext } from 'components/organisms/contact/ContactContext';

import BlockUnblockContact from '../BlockUnblockContact';
import DeleteContact from '../DeleteContact';

const Wrapper = styled.div`
  display: flex;
  min-width: 250px;
  padding: 10px 0;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #e7e6eb;
  box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
  border-radius: 8px;
  .actions {
    line-height: 14px;
    .ant-btn {
      justify-content: flex-start;
      padding: 7px 16px;
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0px;
      font-weight: ${({ theme }) => theme.text.weight.semiBold};
      &:last-child {
        margin: 0;
      }
      &:hover {
        background: #f3f2f4;
        color: ${({ theme }) => theme.colors.text.t6};
        > svg {
          color: ${({ theme }) => theme.colors.text.t6};
        }
      }
      svg {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
    }
    .btn--normal {
      color: #6e6681;
      > svg {
        color: #6e6681;
      }
    }
    .btn--block {
      color: ${({ theme }) => theme.colors.danger.default};
      &:hover {
        color: ${({ theme }) => theme.colors.danger.default};
      }
    }
    .icon {
      margin-right: 22px;
    }
  }
  .ant-divider {
    border-color: ${({ theme }) => theme.colors.text.t1};
    margin: 4px 0;
  }
`;
export default function ContactDropdownOverlay({ closeDropdown }: any) {
  const { t } = useTranslation();
  const { channelId } = useParams<Record<string, string | undefined>>();
  const { contactData } = useContext<any>(ContactContext);
  const { blocked } = contactData || {};
  const { loggedInMemberId } = useContext(AuthContext);

  const handleSearchConversation = () => {
    closeDropdown();
    showCallboxSearchDrawer(true);
    showCallboxContactDetail(false);
  };

  return (
    <Wrapper>
      <div className='actions'>
        {channelId && (
          <div>
            <Button
              type='link'
              onClick={handleSearchConversation}
              className='btn--normal'
              icon={<Icon name='search-line' />}
            >
              {t('searchConversation', 'Search in conversation')}
            </Button>
            <Divider />
          </div>
        )}

        {/* Hide delete option if client is not save in loggedIn agent's contact list */}
        {contactData?.createdBy === loggedInMemberId && !blocked && (
          <DeleteContact contact={contactData} closeDropdown={closeDropdown} />
        )}
        <BlockUnblockContact contact={contactData} closeDropdown={closeDropdown} />
      </div>
    </Wrapper>
  );
}
