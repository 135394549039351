import React from 'react';
import styled from 'styled-components';
import { Select as AntSelect } from 'antd';

const Select = styled(AntSelect)`
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.text.weight.semiBold};
  font-size: 14px;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f2f8;
    border: 1px solid #d7cce4;
  }
  .ant-select-arrow {
    top: 40%;
    &,
    & > svg {
      height: 20px;
      width: 20px;
    }
    svg {
      color: #3d3358;
    }
  }

  &.ant-select-item {
    font-weight: 600;
    line-height: 16px;
    color: #564d6d;
  }
`;
export default React.memo(Select);
