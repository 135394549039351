import { ReactElement, useContext, useState } from 'react';
import { Button, Icon } from 'components/atoms';

import WidgetDrawer from 'components/molecules/widget-drawer/WidgetDrawer';
import { WidgetBanner } from 'components/molecules/widget-banner/WidgetBanner';
import { AuthContext } from 'contexts/auth/AuthContext';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import * as S from './Styles';
import { ClientInfo } from '../client-info/ClientInfo';
import { RejectWithMessage } from '../reject-with-message/RejectWithMessage';
import { isKYCUnverifiedMsg } from '../../dialer/constants';

export default function WidgetBody(): ReactElement {
  const { handleCallAnswer, handleCallReject } = useCallWidgetContext();
  const [selectedAction, setSelectedAction] = useState<null | string>(null);
  const { isKYCVerified } = useContext(AuthContext);

  const handleRejectWihMsg = () => {
    setSelectedAction('reject-with-message');
  };

  const handleCloseDrawer = () => {
    setSelectedAction(null);
  };

  return (
    <S.WidgetBody className='text-center relative overflow-hidden h-full'>
      {!isKYCVerified && (
        <WidgetBanner
          title={isKYCUnverifiedMsg}
          type='warning'
          icon={<Icon name='exclamation-triangle' />}
          showIcon={true}
          closable={true}
        />
      )}
      <WidgetDrawer setOpen={setSelectedAction} open={selectedAction} height={234}>
        <RejectWithMessage handleClose={handleCloseDrawer} />
      </WidgetDrawer>
      <ClientInfo />
      <S.FooterBtnWrapper className='mt-17.5 mx-5'>
        <Button onClick={handleCallAnswer} className='call-accept w-full mb-2.5 '>
          Accept
        </Button>
        <Button onClick={handleCallReject} className='call-decline w-full mb-2.5'>
          Decline
        </Button>
        <Button onClick={handleRejectWihMsg} className='call-reject w-full'>
          Reject with Message
        </Button>
      </S.FooterBtnWrapper>
    </S.WidgetBody>
  );
}
