import { useReactiveVar } from '@apollo/client';
import { useState, useCallback, useEffect, Suspense, memo } from 'react';
import { RiSearchLine } from 'react-icons/ri';

import { FallbackSpinner, SearchInput, Tooltip } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';
import { HASHED_COUNTRIES, COUNTRIES } from 'constants/countries';
import useScreenType from 'hooks/useScreenType';
import { SelectInput } from 'components/molecules/fields';
import { parsePhoneNumber } from 'components/utils/phone-lib';

import * as S from './Styles';
import { selectedCountry, updatedDialCode, useNumberSearchVars } from '../phone-input/useVars';
import { PHONE_INPUT_PADDING_RIGHT } from '../../constants';

const CountrySelectInput = ({ contactNumber }: { contactNumber?: string }) => {
  const defaultCountryCode = localStorage.getItem('_dialer-default-country');
  const [countries, setCountries] = useState(() => COUNTRIES);
  const selectedCountryData: any = useReactiveVar(selectedCountry);
  const { setSelectedCountry } = useNumberSearchVars(selectedCountry);
  const { setUpdatedDialCode } = useNumberSearchVars(updatedDialCode);

  const findCountry = (keyword: React.ChangeEvent<HTMLInputElement> | string) =>
    countries?.find((country: any) => country?.alphaTwoCode === keyword);

  const searchCountry = useCallback(
    (searchedKeyword: string) =>
      COUNTRIES?.filter(
        (country: any) =>
          country?.name?.toLowerCase().includes(searchedKeyword.toLowerCase()) ||
          country?.dialingCode?.includes(searchedKeyword),
      ),
    [],
  );
  useEffect(() => {
    const keyword = defaultCountryCode?.toUpperCase() || 'US';
    /** Display recently dialed number's country flag from localstorage or display US flag if not available */
    let defaultCountry = findCountry(keyword);
    if (contactNumber) {
      const number = contactNumber?.replace(/\s/g, '');
      const { country = 'US' } = parsePhoneNumber(number);
      defaultCountry = findCountry(country);
    }
    setSelectedCountry(defaultCountry);
    return () => {
      setSelectedCountry(null);
      setUpdatedDialCode(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountryCode, contactNumber]);

  const handleCountrySelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCountryObj = findCountry(e);
    setUpdatedDialCode(selectedCountryObj?.dialingCode);
    setSelectedCountry(selectedCountryObj);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchedKeyword = e.target.value;
    const searchResults = searchCountry(searchedKeyword);
    setCountries(searchResults);
  };

  const selectedCountryCode: any = selectedCountryData?.alphaTwoCode;
  const selectedCountryDetail = HASHED_COUNTRIES[selectedCountryCode];

  const { isBigScreen } = useScreenType();

  const options = countries.map(country => {
    const countryDetail = HASHED_COUNTRIES[country.alphaTwoCode];
    const countryName = isBigScreen ? countryDetail?.name : nameElipsis(countryDetail?.name, 36);
    return {
      value: countryDetail?.alphaTwoCode,
      label: (
        <Tooltip title={countryDetail?.name} placement='topLeft'>
          <S.SelectCountryOption>
            <img src={countryDetail?.flagUrl} alt={countryDetail?.alphaTwoCode} />
            <span className='truncate'>{countryName}</span>
            <span className='ml-auto'>{countryDetail?.dialingCode} </span>
          </S.SelectCountryOption>
        </Tooltip>
      ),
      name: <img src={countryDetail?.flagUrl} alt={countryDetail?.alphaTwoCode} />,
    };
  });

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <>
        <S.CountrySelectInputWrapper>
          <SelectInput
            size='small'
            name='country-selection-input'
            dropdownRender={menu => (
              <>
                <SearchInput
                  placeholder='Search'
                  size='large'
                  onChange={onSearch}
                  prefix={<RiSearchLine />}
                  autoFocus
                  ref={input => input && input.focus()}
                />
                {menu}
              </>
            )}
            value={selectedCountryDetail?.alphaTwoCode ?? 'US'}
            onChange={handleCountrySelect}
            options={options}
            optionLabelProp='name'
            suffixIcon={<></>}
            dropdownStyle={{
              width: 293,
              minWidth: 293,
            }}
            listItemHeight={36}
            listHeight={286}
            dropdownAlign={{ offset: [-PHONE_INPUT_PADDING_RIGHT, 0] }}
          />
        </S.CountrySelectInputWrapper>
      </>
    </Suspense>
  );
};
export default memo(CountrySelectInput);
