export default function Location({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5 12H19.3889'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12 2.5V4.61111'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M2.5 12H4.61111'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12 21.5V19.3889'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12 19.3889C16.0808 19.3889 19.3889 16.0808 19.3889 12C19.3889 7.91924 16.0808 4.61111 12 4.61111C7.91924 4.61111 4.61111 7.91924 4.61111 12C4.61111 16.0808 7.91924 19.3889 12 19.3889Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M12 16.2222C14.3318 16.2222 16.2222 14.3318 16.2222 12C16.2222 9.66812 14.3318 7.77777 12 7.77777C9.66812 7.77777 7.77777 9.66812 7.77777 12C7.77777 14.3318 9.66812 16.2222 12 16.2222Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  );
}
