export default function ThreeDotsIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.0933 13.9999C21.0933 14.6184 21.5947 15.1199 22.2133 15.1199C22.8318 15.1199 23.3333 14.6184 23.3333 13.9999C23.3333 13.3813 22.8318 12.8799 22.2133 12.8799C21.5947 12.8799 21.0933 13.3813 21.0933 13.9999Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.8801 13.9999C12.8801 14.6184 13.3816 15.1199 14.0001 15.1199C14.6187 15.1199 15.1201 14.6184 15.1201 13.9999C15.1201 13.3813 14.6187 12.8799 14.0001 12.8799C13.3816 12.8799 12.8801 13.3813 12.8801 13.9999Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.66674 13.9999C4.66674 14.6184 5.16817 15.1199 5.78674 15.1199C6.4053 15.1199 6.90674 14.6184 6.90674 13.9999C6.90674 13.3813 6.4053 12.8799 5.78674 12.8799C5.16817 12.8799 4.66674 13.3813 4.66674 13.9999Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
