import React from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { RiCloseLine } from 'react-icons/ri';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Tooltip } from 'components/atoms';

import { tagsColors } from './TagColors';
import * as S from './Styles';
import { tagSelectStyles } from './tagSelectStyles';

export interface ITagSelectProps {
  isTagFieldLoading?: boolean;
  handleTagChange: (newValue: any, actionMeta: any) => void;
  handleBlur: () => void;
  handleTagCreate: (inputValue: any) => void;
  tagFieldOptions: any;
  tagFieldValue: any;
  customStyles?: any;
  colorPickerClass?: string;
  showTagColorOptions: boolean;
  handleColorPicking: (tag: any) => void;
}

// customize tag remove icon
const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <Tooltip title='remove tag'>
        <RiCloseLine />
      </Tooltip>
    </components.MultiValueRemove>
  );
};

export const TagSelect = React.forwardRef(
  (
    {
      isTagFieldLoading,
      handleTagChange,
      handleBlur,
      handleTagCreate,
      tagFieldOptions,
      tagFieldValue,
      showTagColorOptions,
      handleColorPicking,
      customStyles,
      colorPickerClass,
    }: ITagSelectProps,
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <S.TagFieldStyles data-cy='tag-f-field'>
        <CreatableSelect
          isMulti
          isClearable
          isDisabled={isTagFieldLoading}
          isLoading={isTagFieldLoading}
          onChange={handleTagChange}
          onBlur={handleBlur}
          onCreateOption={handleTagCreate}
          options={tagFieldOptions}
          value={tagFieldValue}
          className='tag-select'
          classNamePrefix='tag-select'
          styles={customStyles ?? tagSelectStyles}
          placeholder={t('addTag', 'Add a tag')}
          maxMenuHeight={213}
          ref={ref as any}
          components={{ MultiValueRemove }}
        />

        {showTagColorOptions && (
          <S.ColorPicker id='color-picker' data-cy='col-picker' className={colorPickerClass}>
            <Menu>
              {tagsColors.map(tag => (
                <Menu.Item
                  key={tag.id}
                  className={`color-menu-item ${tag.colorTitle.toLowerCase()}`}
                  onClick={() => {
                    handleColorPicking(tag);
                  }}
                >
                  <div className='flex items-center'>
                    <div className={`${tag.bgClass} h-4.5 w-4.5 rounded-5 mr-2`} />
                    <span>{tag.colorTitle}</span>
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          </S.ColorPicker>
        )}
      </S.TagFieldStyles>
    );
  },
);
