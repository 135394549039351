import React from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';

const PasswordInput = styled(AntInput.Password)`
  background: #f5f2f8;
  border: 1px solid #d7cce4;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  > input {
    background-color: ${({ theme }) => theme.colors.primary.p0};
    font-size: 14px !important;
    line-height: 16px !important;
    position: relative;
    padding-right: 44px;
  }
  &:hover {
    border-color: #390179 !important;
  }
  &.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    background: none !important;
    border-color: #390179 !important;
  }
  .ant-input-suffix {
    position: absolute;
    padding: 0 16px 0 10px;
    top: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    margin-left: -10px;
    background: none;
    z-index: 1;
    border-radius: 8px;
    margin: 0 !important;
    svg {
      color: #3d3358 !important;
    }
  }
`;
export default React.memo(PasswordInput);
