import { getColorForCharacter } from 'components/atoms/avatar/v3/common';
import UserAvatar from 'components/atoms/avatar/v3/UserAvatar';
import { CustomHighlighter } from 'components/molecules/highlighter';
import { getNameInitialsForAvatar } from 'components/utils/helpers';
import { NumberAutoCompleteOption } from './Styles';

interface ContactAutoComplete {
  number: string;
  searchedText: string;
  name: string;
  profilePicture: string;
}

export const ContactAutoCompleteOption = ({
  number,
  searchedText,
  name,
  profilePicture,
}: ContactAutoComplete) => {
  return (
    <NumberAutoCompleteOption data-cy='contact-auto-complete'>
      <div className='flex items-center gap-1.5'>
        <UserAvatar
          size={24}
          className='avatar'
          src={profilePicture}
          bgColorType={getColorForCharacter(name[0]) as any}
        >
          {getNameInitialsForAvatar(name)}
        </UserAvatar>
        <CustomHighlighter textToHighlight={name} searchWords={[searchedText]} />
      </div>
      <CustomHighlighter className='number' textToHighlight={number} searchWords={[searchedText]} />
    </NumberAutoCompleteOption>
  );
};
