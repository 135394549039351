export default function Cancel() {
  return (
    <svg
      width='108'
      height='112'
      viewBox='0 0 108 112'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_5575_67666)'>
        <path d='M16 12.2553H78.722V91.1944H16V12.2553Z' fill='white' />
        <path d='M47.2762 12.2553H78.722V91.1944H47.2762V12.2553Z' fill='#F2F6FC' />
        <path d='M23.2738 21.1436H71.4482V26.2627H23.2738V21.1436Z' fill='#BEC3D2' />
        <path d='M23.2738 32.1801H71.4481V37.2992H23.2738V32.1801Z' fill='#BEC3D2' />
        <path d='M23.2738 43.2158H71.4481V48.3348H23.2738V43.2158Z' fill='#BEC3D2' />
        <path d='M23.2738 54.2523H71.4481V59.3714H23.2738V54.2523Z' fill='#BEC3D2' />
        <path d='M23.2738 65.2888H71.4481V70.4079H23.2738V65.2888Z' fill='#BEC3D2' />
        <path d='M23.2738 77.1871H71.4481V82.3062H23.2738V77.1871Z' fill='#BEC3D2' />
        <path d='M47.2762 21.1436H71.4482V26.2627H47.2762V21.1436Z' fill='#AFB4C8' />
        <path d='M47.2762 32.1801H71.4482V37.2992H47.2762V32.1801Z' fill='#AFB4C8' />
        <path d='M47.2762 43.2158H71.4482V48.3348H47.2762V43.2158Z' fill='#AFB4C8' />
        <path d='M47.2762 54.2523H71.4482V59.3713H47.2762V54.2523Z' fill='#AFB4C8' />
        <path d='M47.2762 65.2888H71.4482V70.4079H47.2762V65.2888Z' fill='#AFB4C8' />
        <path d='M47.2762 77.1871H71.4482V82.3062H47.2762V77.1871Z' fill='#AFB4C8' />
        <path
          d='M91.9999 60.4389C91.9999 72.5943 82.1465 82.4477 69.9912 82.4477C57.8358 82.4477 47.9824 72.5943 47.9824 60.4389C47.9824 48.2835 57.8358 38.4301 69.9912 38.4301C82.1465 38.4301 91.9999 48.2835 91.9999 60.4389Z'
          fill='#FE6A16'
        />
        <path
          d='M92 60.4389C92 48.2919 82.1606 38.4441 69.9912 38.4301V82.4468C82.1606 82.4328 92 72.585 92 60.4389Z'
          fill='#F24500'
        />
        <path
          d='M83.8719 52.1227L78.3075 46.5574L69.9912 54.8737L61.6749 46.5574L56.1105 52.1227L64.4259 60.439L56.1105 68.7543L61.6749 74.3197L69.9912 66.0034L78.3075 74.3197L83.8719 68.7543L75.5556 60.439L83.8719 52.1227Z'
          fill='white'
        />
        <path
          d='M83.8719 52.1227L78.3075 46.5574L69.9912 54.8737V66.0034L78.3075 74.3197L83.8719 68.7543L75.5556 60.439L83.8719 52.1227Z'
          fill='#F2F6FC'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_5575_67666'
          x='0'
          y='0.25531'
          width='108'
          height='110.939'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.137255 0 0 0 0 0.117647 0 0 0 0 0.141176 0 0 0 0.1 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5575_67666' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_5575_67666'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
