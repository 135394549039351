import { ChannelWrapper } from './Styles';

interface NumberCardProps {
  number: string;
  name: string;
  flagUrl: string;
}

export const NumberCard = ({ number, name, flagUrl }: NumberCardProps) => {
  return (
    <ChannelWrapper>
      <img src={flagUrl} alt='country code' />
      <div>
        <h4>{name}</h4>
        <p>{number}</p>
      </div>
    </ChannelWrapper>
  );
};
