import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { NUMBER_SETTINGS } from 'graphql/channel/settings';

export function useSettingQuery(props?: any) {
  const { channelId } = useParams<Record<string, string | undefined>>();
  const id = channelId || props?.channelId;
  const [loadNumberSettings, { data, loading: loadingNumberSettings }] = useLazyQuery(
    NUMBER_SETTINGS,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (id) {
      loadNumberSettings({
        variables: {
          channel: id,
        },
      });
    }
  }, [id, loadNumberSettings]);
  const configs = useMemo(() => data?.numberSettings?.data ?? {}, [data]);

  return { configs, loadingNumberSettings };
}
