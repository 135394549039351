import { css } from 'styled-components';

export const antNotification = css`
  .ant-notification {
    .ant-notification-notice {
      margin-bottom: 17px;
      border-radius: 4px 10px 10px 4px;
      padding: 0;
      border-left: 4px solid;
      width: fit-content;
      box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
      &-icon {
        margin: 0;
        font-size: 20px;
      }
      &-message {
        margin-left: 30px;
        margin-bottom: 0;
        padding: 0;

        font-family: ${({ theme }) => theme.text.font.heading};
        font-size: ${({ theme }) => theme.text.size.body.text.small};
        line-height: 150%;
        color: ${({ theme }) => theme.colors.text.t7};
      }
      &.connection-offline {
        border-color: ${({ theme }) => theme.colors.danger.default};
        svg {
          color: ${({ theme }) => theme.colors.text.t2};
        }
      }
      &.connection-online {
        border-color: ${({ theme }) => theme.colors.success.default};
        svg {
          color: ${({ theme }) => theme.colors.success.default};
        }
      }
    }
    &-bottomLeft {
      margin-left: 100px;
    }
    .ant-notification-notice-content {
      padding: 13px 12px;
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.text.t1};
      border-radius: 0px 10px 10px 0px;
    }
  }
`;
