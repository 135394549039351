import { css } from 'styled-components';

export const antCheckbox = css`
  .ant-checkbox-wrapper {
    align-items: center;
    &::after {
      height: 20px;
    }
  }

  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-input .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
  .ant-checkbox-inner {
    background-color: #fff;
    border: 1px solid #d7cce4;
  }
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-checked::after {
    border-radius: 5px;
  }
  .ant-checkbox-inner::after {
    left: 27.5%;
  }

  .ant-checkbox + span {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #3d3358;
    padding-right: 0px;
    padding-left: 10px;
  }
`;
