export const KrispcallDark = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='140'
    height='50'
    viewBox='0 0 140 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.81418 22.4283L16.3363 32.5561H11.4608L4.02063 23.4332V32.5561H0V12.6443H4.02063V21.5954L11.4875 12.6443H16.3363L7.81418 22.4283Z'
      fill='black'
    />
    <path
      d='M24.9248 17.5628C25.8999 16.9943 26.9819 16.7034 28.1707 16.7034V20.9607H27.0086C24.0566 20.9607 22.5872 22.3093 22.5872 25.0198V32.5561H18.5666V16.9281H22.5872V19.9162C23.175 18.9246 23.9497 18.1445 24.9248 17.5628Z'
      fill='black'
    />
    <path d='M34.4222 16.9281V32.5693H30.4015V16.9281H34.4222Z' fill='black' />
    <path
      d='M48.1406 18.171C49.3027 19.1362 50.024 20.4054 50.3045 22.0053H46.5376C46.3907 21.2516 46.0434 20.6566 45.5091 20.1939C44.9748 19.7444 44.2802 19.5196 43.4253 19.5196C42.7441 19.5196 42.2098 19.6783 41.8224 19.9956C41.4484 20.3129 41.248 20.7492 41.248 21.2913C41.248 21.7276 41.395 22.0714 41.7022 22.3358C42.0094 22.6002 42.3834 22.8118 42.8376 22.9572C43.2918 23.1026 43.9463 23.2878 44.8012 23.4993C46.0033 23.7637 46.9784 24.0414 47.7265 24.3323C48.4745 24.6231 49.129 25.0859 49.6633 25.7205C50.211 26.3552 50.4781 27.2013 50.4781 28.2723C50.4781 29.6077 49.9438 30.6919 48.9019 31.5248C47.8467 32.3578 46.4308 32.7676 44.6409 32.7676C42.5838 32.7676 40.9408 32.3049 39.7119 31.3926C38.4697 30.4803 37.7217 29.1714 37.4545 27.479H41.3015C41.395 28.2459 41.7423 28.8541 42.33 29.3036C42.9177 29.7399 43.6925 29.9647 44.6409 29.9647C45.3221 29.9647 45.8431 29.806 46.2037 29.4755C46.5644 29.1449 46.7514 28.7218 46.7514 28.193C46.7514 27.7434 46.5911 27.3732 46.2839 27.0956C45.9766 26.8179 45.5759 26.5932 45.1084 26.4477C44.6409 26.3023 43.9864 26.1172 43.1315 25.9056C41.956 25.6412 40.9943 25.3768 40.2596 25.0991C39.5249 24.8215 38.8971 24.3851 38.3762 23.7902C37.8552 23.182 37.5881 22.3622 37.5881 21.3045C37.5881 19.9559 38.109 18.8453 39.1509 17.9991C40.1928 17.1529 41.6354 16.7298 43.4921 16.7298C45.4423 16.7298 46.9784 17.2058 48.1406 18.171Z'
      fill='black'
    />
    <path
      d='M66.5339 17.6951C67.6025 18.3298 68.4307 19.2553 69.0318 20.4717C69.6329 21.6881 69.9267 23.116 69.9267 24.7422C69.9267 26.3817 69.6329 27.8097 69.0318 29.0128C68.4307 30.2292 67.6025 31.1547 66.5339 31.7894C65.4653 32.424 64.2765 32.7545 62.9407 32.7545C61.605 32.7545 60.4696 32.4372 59.5345 31.8026C58.5995 31.168 57.9183 30.335 57.5175 29.2773V40H53.4969V16.9283H57.5175V20.1808C57.9183 19.1231 58.5862 18.2901 59.5345 17.6687C60.4696 17.0473 61.605 16.7432 62.9407 16.7432C64.2631 16.7299 65.4653 17.0473 66.5339 17.6951ZM58.6663 21.4368C57.8782 22.2566 57.4775 23.3672 57.4775 24.7555C57.4775 26.1305 57.8782 27.2279 58.6663 28.0477C59.4544 28.8674 60.4562 29.2773 61.6718 29.2773C62.9274 29.2773 63.9292 28.8806 64.6906 28.0741C65.4519 27.2808 65.826 26.1702 65.826 24.7555C65.826 23.3408 65.4519 22.2301 64.6906 21.4236C63.9292 20.6171 62.9274 20.2072 61.6718 20.2072C60.4696 20.2072 59.4544 20.6171 58.6663 21.4368Z'
      fill='black'
    />
    <path
      d='M32.4988 14.6805C33.6349 14.6805 34.5559 13.7689 34.5559 12.6444C34.5559 11.5199 33.6349 10.6082 32.4988 10.6082C31.3627 10.6082 30.4418 11.5199 30.4418 12.6444C30.4418 13.7689 31.3627 14.6805 32.4988 14.6805Z'
      fill='black'
    />
    <path
      d='M107.813 17.6869C108.802 18.3348 109.496 19.2074 109.91 20.2916V16.9201H114.065V33.1694H109.91V29.7715C109.496 30.8689 108.788 31.7415 107.813 32.4026C106.825 33.0636 105.636 33.381 104.26 33.381C102.871 33.381 101.629 33.0504 100.533 32.3893C99.4248 31.7283 98.5566 30.7631 97.9421 29.507C97.3143 28.251 97.0071 26.7702 97.0071 25.0646C97.0071 23.359 97.3143 21.8782 97.9421 20.6221C98.5699 19.3661 99.4248 18.4009 100.533 17.7398C101.642 17.0787 102.884 16.7482 104.26 16.7482C105.636 16.7085 106.825 17.039 107.813 17.6869ZM102.417 21.5873C101.629 22.4335 101.228 23.5837 101.228 25.0513C101.228 26.5189 101.629 27.6692 102.417 28.5022C103.205 29.3351 104.26 29.745 105.556 29.745C106.825 29.745 107.867 29.3219 108.682 28.4757C109.496 27.6296 109.91 26.4925 109.91 25.0646C109.91 23.6234 109.496 22.4731 108.682 21.6137C107.867 20.7675 106.825 20.3445 105.556 20.3445C104.247 20.318 103.205 20.7411 102.417 21.5873Z'
      fill='#390179'
    />
    <path d='M122.026 11.4594V33.1561H117.845V11.4594H122.026Z' fill='#390179' />
    <path d='M130 11.4594V33.1561H125.819V11.4594H130Z' fill='#390179' />
    <path
      d='M86.5334 15.9573C84.1471 18.1483 82.6537 21.2788 82.6537 24.7546C82.6537 28.2462 84.1607 31.3894 86.566 33.5817L89.4139 30.7629C87.7348 29.2943 86.676 27.1469 86.676 24.7546C86.676 22.3782 87.7208 20.2433 89.3807 18.7756L86.5334 15.9573Z'
      fill='#390179'
    />
    <path
      d='M80.5146 10C76.336 13.5747 73.6918 18.8591 73.6918 24.7548C73.6918 30.6664 76.3502 35.9634 80.5483 39.5385L83.4026 36.7132C79.928 33.8678 77.7141 29.5672 77.7141 24.7548C77.7141 19.9583 79.9134 15.6702 83.3683 12.8247L80.5146 10Z'
      fill='#390179'
    />
    <path
      d='M90.4911 24.7708C90.4911 25.8939 91.4109 26.8043 92.5456 26.8043C93.6802 26.8043 94.6001 25.8939 94.6001 24.7708C94.6001 23.6476 93.6802 22.7372 92.5456 22.7372C91.4109 22.7372 90.4911 23.6476 90.4911 24.7708Z'
      fill='#390179'
    />
  </svg>
);
