import styled from 'styled-components';

export const KeypadContainer = styled.div`
  .ant-input {
    width: 280px;
    height: 56px !important;
    background: #ffffff;
    box-shadow: 0px 1px 0px rgba(231, 230, 235, 0.6);
    text-align: center;
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #4882f6;
    border: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    &:focus,
    &:hover {
      border: none;
      /* border-top: 1px solid #e7e6eb; */
    }
  }
`;
export const Keypad = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px 30px 12px;
  row-gap: 4px;
  column-gap: 30px;
  justify-content: center;
  > .ant-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    &:hover {
      border-color: inherit;
      background: #f9f9fa !important;
    }
  }
`;
