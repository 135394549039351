import React, { useEffect } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import uuid from 'components/utils/uuid';
import * as path from 'constants/routes';
import { routes } from 'services/Route/RouteConfig';
import { ErrorRoute, ProtectedRoute } from 'services/Route';
import FallbackSpinner from 'components/atoms/spinner/Spinner';
import IndexLayout from 'components/pages/layouts/IndexLayout';
import withNetworkDetector from 'services/withNetworkDetector';
import { getSubdomain } from 'components/utils/url';
import { INTERCOM_APP_ID, INTERCOM_TEST_ID } from 'constants/endpoint';
import { AppProvider } from 'contexts/app/AppContext';
import { useQuery } from '@apollo/client';
import { WORKSPACES } from 'graphql/foundation';
import useLocalStorage from 'hooks/useLocalStorage';

function AppRouter() {
  const subdomain = getSubdomain();

  // const isIntercomDisabled = pathname?.includes('dialer') || pathname?.includes('support');
  const isIntercomDisabled = true;

  const INTERCOM_ID = subdomain.includes('app') ? INTERCOM_APP_ID : INTERCOM_TEST_ID;

  /* -----------------------------------------------------------------------------*/
  /*
   * Below code has been commented because of its side effect on other areas
   * Call coaching feature was not working because of the following code
   * Need to reverify the following code that was added to fix the workspace subscription issues [KS-13666]
   */
  // const { pathname } = window.location;
  // const { data: workspaceData, loading, error } = useQuery(WORKSPACES);
  // const workspaces = workspaceData?.workspaces?.data;
  // const [activeWorkspaceId] = useLocalStorage('activeWorkspaceId', null);
  // const loggedInMemberInfo = workspaces?.find((item: any) => item.id === activeWorkspaceId) ?? {};
  // const { plan } = loggedInMemberInfo || {};
  // const { subscriptionActive } = plan || {};

  // // To Do !(reviewStatus==='Under Review') need discuss to check or not
  // useEffect(() => {
  //   if (pathname !== '/settings/plan-subscription' && subscriptionActive === 'Expired') {
  //     window.location.href = '/settings/plan-subscription';
  //   }
  // }, [subscriptionActive, pathname]);
  /* -----------------------------------------------------------------------------*/

  return (
    <AppProvider>
      <IntercomProvider
        appId={INTERCOM_ID as string}
        initializeDelay={10000}
        autoBoot={!isIntercomDisabled}
        shouldInitialize={!isIntercomDisabled}
      >
        <React.Suspense fallback={<FallbackSpinner />}>
          <IndexLayout>
            <Switch>
              <Redirect exact path='/' to={path.DASHBOARD} />
              {routes.map(route => (
                <ProtectedRoute {...route} key={uuid()} />
              ))}
              <ErrorRoute path='*' />
            </Switch>
          </IndexLayout>
        </React.Suspense>
      </IntercomProvider>
    </AppProvider>
  );
}

export default withNetworkDetector(AppRouter);
