import { gql } from '@apollo/client';

import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';

export const CALL_STATS = gql`
  query callStats @api(name: "dash") {
    callStats {
      status
      error {
        ...Error
      }
      data {
        liveCalls
        queuedCalls
        missedCalls
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const MISSED_CALLS_COUNT = gql`
  query missedCallsCount($channel: ShortId) @api(name: "dash") {
    missedCallsCount(channel: $channel) {
      status
      error {
        ...Error
      }
      data {
        count
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const LIVE_CALLS = gql`
  query liveCalls($channel: ShortId, $params: ConnectionInput) @api(name: "dash") {
    liveCalls(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            sid
            contact
            conversation
            agent
            workspace
            channel
            timestamp
            callTimestamp
            callStatus
            direction
            streamUrl
            coachId
            isCoaching
            coachName
            callerInfo {
              name
              contact
            }
            agentInfo {
              id
              firstname
              lastname
              profile_picture
            }
            channelInfo {
              id
              number
              name
            }
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
`;

export const MISSED_CALLS = gql`
  query missedCalls($channel: ShortId, $params: ConnectionInput) @api(name: "dash") {
    missedCalls(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            sid
            contact
            conversation
            agent
            workspace
            channel
            timestamp
            callStatus
            direction
            streamUrl
            callerInfo {
              name
              contact
            }
            agentInfo {
              id
              firstname
              lastname
              profile_picture
            }
            channelInfo {
              id
              number
              name
            }
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
`;

export const QUEUED_CALLS = gql`
  query queuedCalls($channel: ShortId, $params: ConnectionInput) @api(name: "dash") {
    queuedCalls(channel: $channel, params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            sid
            contact
            conversation
            agent
            workspace
            channel
            timestamp
            callStatus
            direction
            streamUrl
            callerInfo {
              name
              contact
            }
            agentInfo {
              id
              firstname
              lastname
              profile_picture
            }
            channelInfo {
              id
              number
              name
            }
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
`;

export const CALLS_DISTRIBUTION = gql`
  query callDistribution($channel: ShortId, $date: Datetime) @api(name: "dash") {
    callDistribution(channel: $channel, date: $date) {
      status
      error {
        ...Error
      }
      data {
        overall {
          total
          outbound
          inbound
          missed
          serviceLevel
        }
        change {
          total
          outbound
          inbound
          missed
          serviceLevel
        }
        hourly {
          time
          value
          day
          type
          date
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const TODAY_STATS = gql`
  query todayStats @api(name: "dash") {
    todayStats {
      status
      error {
        ...Error
      }
      data {
        avgDuration
        avgWaitTime
        avgQueueTime
        callsPerHour
        callsPerHourPctChange
        avgDurationPctChange
        avgWaitTimePctChange
        avgQueueTimePctChange
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ACTIVITIES = gql`
  query activities($channel: ShortId, $start: Datetime, $end: Datetime) @api(name: "dash") {
    activities(channel: $channel, start: $start, end: $end) {
      status
      error {
        ...Error
      }
      data {
        mostBusyHour {
          time
          value
          day
          type
          date
        }
        mostBusyDay {
          time
          value
          day
          type
          date
        }
        leastBusyDay {
          time
          value
          day
          type
          date
        }
        weeklyActivity {
          time
          value
          day
          date
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
