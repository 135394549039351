export default function ClockOutline({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group'>
        <path
          id='Vector'
          d='M9.11465 9.11461L7.00354 7.0035V2.78128'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          id='Vector_2'
          d='M6.99996 13.3333C10.4978 13.3333 13.3333 10.4978 13.3333 6.99999C13.3333 3.50219 10.4978 0.666656 6.99996 0.666656C3.50216 0.666656 0.666626 3.50219 0.666626 6.99999C0.666626 10.4978 3.50216 13.3333 6.99996 13.3333Z'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
