import { css } from 'styled-components';

export const antDropdown = css`
  .ant-dropdown {
    z-index: 9999;
  }
  .ant-dropdown-menu,
  .ant-dropdown .ant-menu,
  .ant-select-dropdown {
    background: #ffffff;
    border: 1px solid #e7e6eb;
    box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.1);
    border-radius: 8px;
    padding: 10px 0;
  }
  .ant-dropdown-menu-submenu,
  .ant-dropdown .ant-menu-submenu {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    svg {
      color: #6e6681;
    }
  }
  .ant-dropdown-menu-submenu-popup ul {
    margin: 0;
    margin-left: -5px;
  }
  .ant-dropdown-menu-item-divider {
    margin: 6px 0;
    background-color: #cfccd5;
  }
  .ant-menu-title-content {
    width: 100%;
    display: flex !important;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title,
  .ant-dropdown .ant-menu .ant-menu-item,
  .ant-dropdown .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    display: flex !important;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #6e6681;
    padding: 9px 16px;
    &:hover {
      background: #f5f2f8;
      color: #564d6d;
    }
  }
  .ant-dropdown-menu-item-selected {
    background: #f5f2f8;
  }
  .ant-table-filter-dropdown {
    padding: 16px 0;
    .ant-dropdown-menu-item {
      padding: 8px 24px;
      line-height: 14px;
      color: ${({ theme }) => theme.colors.text.t4};
      font-weight: ${({ theme }) => theme.text.weight.semiBold};
      .ant-checkbox-inner,
      .ant-checkbox-checked::after {
        border-radius: 4px;
      }
      &:hover,
      &-selected {
        color: ${({ theme }) => theme.colors.text.t9};
        background: none;
      }
    }
    .ant-table-filter-dropdown-btns {
      display: none;
    }
  }

  .ant-select-dropdown {
    font-family: ${({ theme }) => theme.text.font.body};
    .ant-select-item {
      padding: 0px 20px;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #564d6d;
      min-height: 34px;
      display: flex;
      align-items: center;
      &:hover {
        background: #f5f2f8;
      }
      &-option-active {
        background: #ece6f2;
      }
      &-option-selected {
        background: #f5f2f8;
      }
      &-option-state {
        line-height: 0px;
      }
    }
    // search input in select dropdown
    .ant-input-affix-wrapper {
      padding: 0 20px;
      height: 38px !important;
    }
  }

  /* numbercard dropdown */
  .numbercard-dropdown {
    .ant-menu {
      position: relative;
      top: -6px;
    }
  }
  // dnd for clients
  .dnd-submenu {
    padding: 0;
    background: none;
    box-shadow: none;
    > .ant-dropdown-menu {
      padding: 16px 24px;
      margin: 0 20px 0 0;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 15px 50px rgb(0 0 0 / 10%);
    }
  }

  // responsive callbox filter
  .filter-call-list-sm {
    box-shadow: none;
    padding: 0;
    > .ant-dropdown-menu {
      margin: 0 -3px 0 0;
      > .call-list {
        margin: 0;
        border: none;
      }
    }
  }

  /* macros list dropdown */
  .macros-list-dropdown {
    min-width: 418px !important;
  }
`;
