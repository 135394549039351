import { ReactElement } from 'react';

import { useTwilioContext } from 'lib/twilio';
import { ManIcon, RecordIcon } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { UserAvatar } from 'components/atoms/avatar';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';

import { ClientInfoWrapper } from './Styles';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';

interface ClientInfoProps {
  variant?: 'incoming' | 'outgoing';
}

export default function ClientInfo({ variant = 'outgoing' }: ClientInfoProps): ReactElement {
  const isIncomingCall = variant === 'incoming';
  const {
    clientInfo,
    callDurationTime,
    hold,
    recording,
    recordingTime,
  } = useActiveCallWidgetContext();
  const { phoneFormatIntl } = parsePhoneNumber(clientInfo?.number ?? '');

  const {
    state: { status = 'initiated', callInProgress, connection },
  } = useTwilioContext();

  // campaign call contact name
  const contactName = connection?.customParameters?.get('contactName');

  const getCallStatusLabel = (callStatus: string) => {
    const labelByCallStatus: Record<string, any> = {
      [CALL_WIDGET_STATUS.INITIATED]: 'Dialing...',
      [CALL_WIDGET_STATUS.RINGING]: 'Ringing...',
      [CALL_WIDGET_STATUS.ENDED]: 'Call ended',
      hold: `On hold - ${callDurationTime}`,
    };
    return labelByCallStatus?.[callStatus] ?? `Ongoing - ${callDurationTime}`;
  };

  const mapAvatarSize = {
    incoming: 64,
    outgoing: 48,
  };

  return (
    <ClientInfoWrapper className={`${variant}`}>
      <UserAvatar
        size={mapAvatarSize[variant]}
        icon={<ManIcon />}
        src={clientInfo?.profilePicture}
      />
      <h4 className='mt-4'> {contactName || clientInfo?.name || phoneFormatIntl || 'Unknown'}</h4>
      <p>{isIncomingCall ? 'Incoming Call...' : getCallStatusLabel(hold ? 'hold' : status)}</p>
      {callInProgress && recording && (
        <div className='recording'>
          <RecordIcon />
          <span>Recording {recordingTime}</span>
        </div>
      )}
    </ClientInfoWrapper>
  );
}
