import { useEffect } from 'react';

import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { PhoneDownFill } from 'components/atoms/icon';
import useAppContext from 'hooks/useAppContext';
import { ActiveCallActions } from './Styles';
import { CallAction, ICallActionProps } from './call-action';
import { useCallActions } from './hooks/useCallActions';
import CampaignWidget from '../campaign-widget/CampaignWidget';
import ClientInfo from '../common/client-info';
import useActiveCallWidgetContext from '../hooks/useActiveCallWidgetContext';
import useCallWidgetContext from '../hooks/useCallWidgetContext';
import useCallWidgetDrawerContext from '../hooks/useCallWidgetDrawerContext';

const CallActive = () => {
  const {
    handleCallReject,
    handleClearCampaignNote,
    callDurationTime,
  } = useActiveCallWidgetContext();
  const { showCallEndedBanner } = useCallWidgetContext();
  const { closeDrawer } = useCallWidgetDrawerContext();

  const {
    state: { direction, salesDialerWidget, status = '' },
  } = useTwilioContext();

  const { disableEndCall, callActions, campaignCallActions } = useCallActions();
  const actionsToShow = salesDialerWidget ? campaignCallActions : callActions;

  const { unSavedCallWidgetAction } = useAppContext();
  const {
    state: { callEnded },
  } = useTwilioContext();
  const { ENDED, CAMPAIGN_CALL_DISCONNECTED } = CALL_WIDGET_STATUS;

  useEffect(() => {
    if (unSavedCallWidgetAction && callEnded) {
      showCallEndedBanner?.(callDurationTime);
    }
    if (!callEnded && salesDialerWidget) {
      // CLOSE ALL DRAWER IF NEW CAMPAIGN CALL IS STARTED
      closeDrawer?.();
      handleClearCampaignNote?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEnded]);

  const campaignCallEndedState = [CAMPAIGN_CALL_DISCONNECTED, ENDED].includes(status);
  const endCallBtnDisabled =
    (direction === 'Outgoing' && disableEndCall && !salesDialerWidget) ||
    (salesDialerWidget && campaignCallEndedState);

  return (
    <>
      <div className='pb-10'>
        <ClientInfo />
        <ActiveCallActions>
          {actionsToShow?.map((callAction: ICallActionProps) => (
            <CallAction
              key={callAction?.label}
              onClick={callAction?.onClick}
              label={callAction?.label}
              icon={callAction?.icon}
              disabled={callAction?.disabled}
              variant={callAction?.variant}
            />
          ))}
        </ActiveCallActions>
        <CallAction
          onClick={handleCallReject}
          variant='secondary'
          className={`mt-6 mx-auto block ${endCallBtnDisabled && 'disabled'} `}
          icon={<PhoneDownFill />}
          disabled={endCallBtnDisabled}
        />
      </div>
      {salesDialerWidget && <CampaignWidget />}
    </>
  );
};

export default CallActive;
