import { createGlobalStyle } from 'styled-components';
import './styles/index.css';
import {
  lineclamp,
  antAvatar,
  antBadge,
  antCollapse,
  antDropdown,
  antPopover,
  antMessage,
  antSelect,
  antTabs,
  antModal,
  antMenu,
  antSubmenu,
  antInput,
  antTable,
  antTooltip,
  antRadio,
  antDrawer,
  antNotification,
  antPickerDropdown,
  phoneInput,
  antCheckbox,
} from './styles';

const GlobalStyle = createGlobalStyle`
    ${lineclamp}
    ${antAvatar}
    ${antBadge}
    ${antCollapse}
    ${antDropdown}
    ${antPopover}
    ${antMessage}
    ${antSelect}
    ${antTabs}
    ${antModal}
    ${antMenu}
    ${antSubmenu}
    ${antInput}
    ${antTable}
    ${antTooltip}
    ${antRadio}
    ${antDrawer}
    ${antNotification}
    ${antPickerDropdown}
    ${phoneInput}
    ${antCheckbox}
    ${({ theme }) => theme.typography};

    /* custom Scrollbar  */
    * {
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: none;
            border-radius: 4px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #CFCCD5;
            border-radius: 8px;
        }

        /* for mozilla */
        scrollbar-width: thin;
        scrollbar-color: #CFCCD5 white;
    }

    body{
        font-family: "Heebo", sans-serif;
        /* To hide scrollbar when dragging dialer to the right */
        overflow-x: hidden;
        @apply bg-green-500;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: "Manrope", sans-serif;
        margin: 0;
    }
    p{
        margin: 0;
    }
    :root {
        margin:0px;
        padding:0px;
    }
    .position{
        &-fixed{
            position:fixed;
        }
        &-relative{
            position:relative;
        }
    }

    .mw1024{
        max-width:1024px;
    }
    .sticky{
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
    }

    .chatbox--container{
        width:calc(100vw - 890px)
    }
    .message-container{
        max-height: 800px;
        overflow-y: scroll
    }
    .line--bottom{
        border-bottom: 1px solid ${({ theme }) => theme.colors.text.t1};
    }
    .cursor-pointer{
        cursor:pointer;
    }
    .w-max-search{
        max-width:458px;
    }
    .word-break{
      word-break: break-word;
    }
    .custom-disabled-cursor, .ant-input-disabled,.ant-select-disabled, button:disabled, button[disabled], .ant-menu-item-disabled, .ant-radio-wrapper-disabled   {
        &, & * {
        cursor: url('/static-icons/disabled-cursor.svg'), auto !important;
        }
    }
    .disabled-cursor-light-theme {
        &,
        & * {
        cursor: url('/static-icons/disabled-cursor-light.svg'), auto !important;
        }
    }

    /* scroll utility */
    .overflow-auto{
        overflow:auto;
    }

    img{           /* for alt text */
        white-space: nowrap;
        font-size: ${({ theme }) => theme.text.size.body.text.smallest};
    }

    // react-scrollbar library
    .ps__rail-y:hover > .ps__thumb-y{
        background-color: ${({ theme }) => theme.colors.primary.p2};;
    }
    .ps__thumb-y {
        background-color: ${({ theme }) => theme.colors.primary.p2};;
    }
    .ps .ps__rail-y:hover{
      background-color:  ${({ theme }) => theme.colors.primary.p1};
    }

    /* intercom */
    .intercom-lightweight-app, .intercom-namespace .intercom-launcher-frame, .intercom-namespace .intercom-messenger-frame  {
      z-index: 999 !important;
    }

    .w-fit{
        width:fit-content;
    }
     /*
      * Sticky header used for making header stikcy on header section of page which will be fixed on scroll
      * Sticky header is used for almost settings page for now
      * Might be needed for other pages too on future
    */
    .sticky-header {
        background-color: white;
        padding-top: 19px;
        z-index: 1;
        min-height: 56px;
        @media only screen and (min-width: 769px) {
            padding-top: 0;
            min-height: auto;
        }
        &-body{
            &-55{
                margin-top: 55px !important;
                @media only screen and (min-width: 769px) {
                     margin-top: 0 !important;
                }
            }
        }
    }

`;
export default GlobalStyle;
