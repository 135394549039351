export default function KeyPad({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M12.0001 6.82609C12.5043 6.82609 12.9131 6.4173 12.9131 5.91304C12.9131 5.40879 12.5043 5 12.0001 5C11.4958 5 11.087 5.40879 11.087 5.91304C11.087 6.4173 11.4958 6.82609 12.0001 6.82609Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0878 6.82609C18.5921 6.82609 19.0009 6.4173 19.0009 5.91304C19.0009 5.40879 18.5921 5 18.0878 5C17.5835 5 17.1748 5.40879 17.1748 5.91304C17.1748 6.4173 17.5835 6.82609 18.0878 6.82609Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.91188 6.82609C6.41615 6.82609 6.82492 6.4173 6.82492 5.91304C6.82492 5.40879 6.41615 5 5.91188 5C5.40762 5 4.99884 5.40879 4.99884 5.91304C4.99884 6.4173 5.40762 6.82609 5.91188 6.82609Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0001 12.913C12.5043 12.913 12.9131 12.5042 12.9131 12C12.9131 11.4957 12.5043 11.0869 12.0001 11.0869C11.4958 11.0869 11.087 11.4957 11.087 12C11.087 12.5042 11.4958 12.913 12.0001 12.913Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0878 12.913C18.5921 12.913 19.0009 12.5042 19.0009 12C19.0009 11.4957 18.5921 11.0869 18.0878 11.0869C17.5835 11.0869 17.1748 11.4957 17.1748 12C17.1748 12.5042 17.5835 12.913 18.0878 12.913Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.91188 12.913C6.41615 12.913 6.82492 12.5042 6.82492 12C6.82492 11.4957 6.41615 11.0869 5.91188 11.0869C5.40762 11.0869 4.99884 11.4957 4.99884 12C4.99884 12.5042 5.40762 12.913 5.91188 12.913Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0001 19C12.5043 19 12.9131 18.5912 12.9131 18.087C12.9131 17.5827 12.5043 17.1739 12.0001 17.1739C11.4958 17.1739 11.087 17.5827 11.087 18.087C11.087 18.5912 11.4958 19 12.0001 19Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0878 18.9999C18.5921 18.9999 19.0009 18.5911 19.0009 18.0869C19.0009 17.5826 18.5921 17.1738 18.0878 17.1738C17.5835 17.1738 17.1748 17.5826 17.1748 18.0869C17.1748 18.5911 17.5835 18.9999 18.0878 18.9999Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.91188 19C6.41615 19 6.82492 18.5912 6.82492 18.087C6.82492 17.5827 6.41615 17.1739 5.91188 17.1739C5.40762 17.1739 4.99884 17.5827 4.99884 18.087C4.99884 18.5912 5.40762 19 5.91188 19Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
