import { useState } from 'react';
import { RiMoreFill } from 'react-icons/ri';

import useScreenType from 'hooks/useScreenType';

import { MoreOptionsPopover } from './Styles';

export function MoreOptionPopover({
  options,
  overlayClassName,
  optionDropdownVisible,
  setOptionDropdownVisible,
  placement = 'rightTop',
}: any) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownVisibility = (value: boolean) => {
    setDropdownVisible(value);
    if (setOptionDropdownVisible) setOptionDropdownVisible(value);
  };

  const { isMobile } = useScreenType();

  return (
    <MoreOptionsPopover
      placement={placement}
      data-cy='member-options-dropdown'
      className='member-option-dropdown'
      visible={optionDropdownVisible ?? dropdownVisible}
      onVisibleChange={handleDropdownVisibility}
      content={options}
      trigger='click'
      overlayClassName={`more-options  ${overlayClassName}`}
      getPopupContainer={(trigger: HTMLElement) =>
        isMobile ? (trigger.parentElement as HTMLElement) : document.body
      }
    >
      <div data-test-id='member-options-dropdown'>
        <RiMoreFill />
      </div>
    </MoreOptionsPopover>
  );
}
