import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ScrollBar from 'react-perfect-scrollbar';
import { Button } from 'components/atoms';
import { Drawer } from 'antd';

export const WorkspaceBodyScrollbar = styled(ScrollBar)`
  height: calc(100% - 64px); // parent container height - workspace bar header height
`;

export const TopNavLink = styled(NavLink)`
  position: relative;
  > div:hover {
    background: #eeecf1;
  }
`;

export const LowCreditButton = styled.div`
  background-color: #db312b;
  height: 16px;
  border-radius: 4px;
  color: white;
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
`;

export const NewTag = styled.div`
  display: flex;
  padding: 2px 6px;
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background-color: #1a63f4;
  color: white;
  width: 36px;
  height: 16px;
  margin-left: auto;
`;
export const Mark = styled.mark`
  border-radius: 6px;
  background: #d1e0fd;
  color: #1a63f4;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  padding: 2px 12px;
`;

export const MemberNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 14px;
  border-radius: 8px;
  margin-bottom: 1px;
  &.menu--active {
    background: #e7e6eb;
  }
  &:hover {
    background: #eeecf1;
  }
  .ant-badge-count {
    background: #fe4646;
    border-radius: 8px;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    span.ant-scroll-number-only-unit {
      font-family: 'Manrope', sans-serif;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
  }
`;

export const Hamburger = styled(Button)<any>`
  &.ant-btn {
    position: absolute;
    left: 78px;
    top: ${props => props.top || '16px'};
    border-radius: 4px;
    border: none;
    z-index: 99;
    padding: 0;
    width: 24px;
    height: 24px;
    svg {
      color: ${({ theme }) => theme.colors.text.t2};
      margin: 0;
    }
    + main {
      width: calc(100vw - 64px); // window width - side workspace list
      @media only screen and (max-width: 768px) {
        overflow-x: scroll;
      }
    }
  }
`;

export const WorkspaceDetailDrawer = styled(Drawer)<any>`
  &.ant-drawer {
    top: ${props => props.alertBarHeight || 0}px;
    .ant-drawer-body > div {
      height: calc(100% - ${props => props.alertBarHeight || 0}px);
    }
  }
  .ant-drawer-body {
    div {
      box-shadow: none !important;
    }
    .settings-menu {
      width: 289px;
    }
  }
  .ant-drawer-header-close-only {
    position: absolute;
    top: 21px;
    left: 15px;
    z-index: 100;
    padding: 0;
    .ant-drawer-close {
      margin: 0;
      svg {
        fill: #564d6d;
      }
    }
  }
  .settings-menu {
    padding-top: 0;
  }
`;

export const SettingsDrawer = styled(Drawer)<any>`
  .closeBtn {
    position: absolute;
    top: 23px;
    left: 25px;
    z-index: 100;
  }
`;
