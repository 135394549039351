export default function PhoneFill({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.9633 18.8545C21.83 19.4284 21.515 19.944 21.065 20.3245C20.5632 20.8351 19.9641 21.24 19.3031 21.5153C18.6421 21.7906 17.9327 21.9306 17.2167 21.9271H16.8594C15.3732 21.8507 13.9166 21.483 12.5721 20.8451C12.2455 20.6919 11.929 20.549 11.5513 20.3755C9.97576 19.4849 8.52978 18.3824 7.25383 17.0988C5.59412 15.5427 4.23434 13.6951 3.24215 11.6478C2.69532 10.5314 2.32677 9.33616 2.1499 8.10566C1.90521 6.8232 2.05101 5.49684 2.56843 4.29815C2.88981 3.75314 3.28482 3.25505 3.74232 2.818C3.94531 2.59363 4.19193 2.41303 4.46709 2.2872C4.75234 2.15513 5.06138 2.08218 5.37558 2.07281C5.71182 2.10836 6.03759 2.21064 6.33382 2.37365C6.63004 2.53666 6.89076 2.75714 7.10071 3.02217C7.57027 3.54277 8.12149 4.04294 8.62167 4.57375L9.26477 5.20663C9.64539 5.5397 9.87999 6.0089 9.91807 6.51324C9.91948 6.97679 9.74847 7.42431 9.4383 7.76881C9.2425 8.01047 9.03467 8.24213 8.81562 8.46294L8.60127 8.68751C8.47562 8.80365 8.3779 8.94674 8.31545 9.10604C8.26289 9.26016 8.24544 9.42408 8.26441 9.58581C8.42852 10.03 8.68613 10.4339 9.01979 10.7699C9.54039 11.4743 10.0406 12.0867 10.602 12.8115C11.5706 13.9302 12.7196 14.879 14.0012 15.6186C14.1249 15.7194 14.2726 15.7863 14.4299 15.8126C14.5691 15.8341 14.7115 15.8127 14.8383 15.7514C15.2272 15.542 15.5735 15.2615 15.859 14.9245C16.2196 14.4815 16.7406 14.1989 17.3086 14.1385C17.5811 14.1396 17.8507 14.1953 18.1014 14.3022C18.3521 14.4091 18.5788 14.5652 18.7683 14.7612C19.0021 14.9621 19.2237 15.1769 19.4318 15.4043L19.738 15.7309L20.0545 16.0372C20.2484 16.2311 20.4322 16.4047 20.6159 16.6088C20.9481 16.895 21.2586 17.2055 21.5448 17.5377C21.8379 17.9122 21.9865 18.3796 21.9633 18.8545Z'
        fill='currentColor'
      />
    </svg>
  );
}
