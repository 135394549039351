import React, { ReactElement, ReactNode } from 'react';
import { Button } from 'components/atoms';

import { useTranslation } from 'react-i18next';

import * as S from './Styles';

interface ErrorPageProps {
  wrapperClassName?: string;
  title?: string;
  message?: string | ReactNode;
  imageSrc?: string;
  onReport?: () => void;
  extra?: React.ReactNode;
  hasFeedback?: boolean;
}

export default function ErrorPage({
  wrapperClassName,
  title,
  message,
  imageSrc,
  extra,
  onReport,
  hasFeedback,
}: ErrorPageProps): ReactElement {
  const { t } = useTranslation();
  return (
    <S.Wrapper className={`h-full w-full ${wrapperClassName}`}>
      <img src={imageSrc} alt='error-page' />
      <h3 className='title'>{title}</h3>
      <p className='note'>{message}.</p>
      <div className='flex'>
        {extra}
        {hasFeedback && (
          <div className='ml-12'>
            <Button type='primary' size='large' danger onClick={onReport}>
              {t('reportIssue', 'Report Issue')}
            </Button>
          </div>
        )}
      </div>
    </S.Wrapper>
  );
}
