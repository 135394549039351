export const restrictedScreenData: any = {
  isUserSuspended: {
    title: 'Account Suspended',
    description: 'Your krispCall Account is currerntly suspended due to lack of credits',
    icon: 'wks_acc_suspend',
    type: 'danger',
  },
  isDeletedWorkspace: {
    title: 'Workspace Deleted',
    description: 'Your current workspace is delete contact support for more information',
    icon: 'wks_delete',
    type: 'danger',
  },
  isUnderReview: {
    title: 'Under Review',
    description: 'Your krispCall account is under review. Please contact support.',
    icon: 'wks_review',
    type: 'info',
  },
  isSubscriptionCancelled: {
    title: 'Subscription Cancelled',
    description: 'Your krispCall subscription has been cancelled',
    icon: 'wks_sub_cancel',
    type: 'info',
  },
  isSubscriptionExpired: {
    title: 'Subscription Expired',
    description: 'You have reached the end of your KrispCall subscription',
    icon: 'wks_sub_expire',
    type: 'danger',
  },
};

export const isKYCUnverifiedMsg = 'Your limit is 15 minutes. Update KYC.';
