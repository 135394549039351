const VideoFile = () => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_25224_204944)'>
      <path
        d='M26.1697 7.32507C18.6056 -0.268091 19.1096 0.216453 18.9645 0.13108C18.6678 -0.0456172 19.4286 0.00748339 6.79688 0.00748339C5.01961 0.00748339 3.57422 1.45722 3.57422 3.2393V26.7681C3.57422 28.55 5.01961 30 6.79688 30H23.2031C24.9802 30 26.4258 28.55 26.4258 26.7681V7.94488C26.4258 7.71234 26.3338 7.48964 26.1697 7.32507Z'
        fill='white'
      />
      <path
        d='M26.1697 7.32486C18.6058 -0.268075 19.1098 0.216239 18.9645 0.130866C18.7582 0.00772802 19.064 -0.00394497 15 0.000861546V29.9997H23.2031C24.9802 29.9997 26.4258 28.5498 26.4258 26.7679V7.94467C26.4258 7.71212 26.3338 7.48942 26.1697 7.32486Z'
        fill='#F8F8F8'
      />
      <path
        d='M26.4258 7.94544H21.2888C19.7573 7.94544 18.5156 6.71291 18.5156 5.19222V0.0078125C18.6749 0.0078125 18.8297 0.0513001 18.9645 0.131409C23.2562 4.39205 21.6133 2.75165 26.1697 7.32563C26.3338 7.49019 26.4258 7.71289 26.4258 7.94544Z'
        fill='#ECECEC'
      />
      <g clipPath='url(#clip1_25224_204944)'>
        <path
          d='M18.7683 12H11.2317C9.99977 12.0014 9.00143 12.9998 9 14.2317V21.7683C9.00143 23.0002 9.99977 23.9986 11.2317 24H18.7683C20.0002 23.9986 20.9986 23.0002 21 21.7683V14.2317C20.9986 12.9998 20.0002 12.0014 18.7683 12ZM20.1716 14.2317V14.9553H18.073L16.5035 12.8284H18.7683C19.543 12.8293 20.1707 13.457 20.1716 14.2317ZM15.474 12.8284L17.0435 14.9553H14.526L12.9565 12.8284H15.474ZM9.8284 14.2317C9.82927 13.4571 10.457 12.8293 11.2317 12.8284H11.927L13.4965 14.9553H9.8284V14.2317ZM18.7683 23.1716H11.2317C10.4571 23.1707 9.82929 22.543 9.8284 21.7683V15.7837H20.1716V21.7683C20.1707 22.5429 19.5429 23.1707 18.7683 23.1716Z'
          fill='#FB3449'
        />
        <path
          d='M17.2097 19.1194L13.9328 17.2274C13.7347 17.1131 13.4814 17.1809 13.367 17.3791C13.3307 17.442 13.3115 17.5134 13.3115 17.5862V21.37C13.3115 21.5988 13.497 21.7842 13.7257 21.7842C13.7984 21.7842 13.8699 21.7651 13.9328 21.7287L17.2097 19.8368C17.4079 19.7224 17.4757 19.4691 17.3614 19.271C17.325 19.208 17.2727 19.1557 17.2097 19.1194ZM14.1399 20.6526V18.3036L16.1742 19.4781L14.1399 20.6526Z'
          fill='#FB3449'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_25224_204944'>
        <rect width='30' height='30' fill='white' />
      </clipPath>
      <clipPath id='clip1_25224_204944'>
        <rect width='12' height='12' fill='white' transform='translate(9 12)' />
      </clipPath>
    </defs>
  </svg>
);

export default VideoFile;
