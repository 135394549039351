import { Dispatch, SetStateAction, createContext, useState } from 'react';

type ILiveCallsContext = {
  selectedRowId: string;
  setSelectedRowId: Dispatch<SetStateAction<string>>;
};
export const LiveCallsContext = createContext<ILiveCallsContext>({
  selectedRowId: '',
  setSelectedRowId: (args?: any) => {},
});

export const LiveCallsProvider = ({ children }: any) => {
  const [selectedRowId, setSelectedRowId] = useState('');
  return (
    <LiveCallsContext.Provider value={{ selectedRowId, setSelectedRowId }}>
      {children}
    </LiveCallsContext.Provider>
  );
};
