function UserSquare({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.605 16.2153C12.945 16.4103 12.165 16.5003 11.25 16.5003H6.75002C5.83502 16.5003 5.05502 16.4103 4.39502 16.2153C4.56002 14.2653 6.56252 12.7278 9.00002 12.7278C11.4375 12.7278 13.44 14.2653 13.605 16.2153V16.2153Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 14.085 2.355 15.6375 4.395 16.215C4.56 14.265 6.5625 12.7275 9 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C15.645 15.6375 16.5 14.085 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5ZM9 10.6275C7.515 10.6275 6.315 9.42 6.315 7.935C6.315 6.45 7.515 5.25 9 5.25C10.485 5.25 11.685 6.45 11.685 7.935C11.685 9.42 10.485 10.6275 9 10.6275Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6849 7.935C11.6849 9.42 10.4849 10.6275 8.99994 10.6275C7.51494 10.6275 6.31494 9.42 6.31494 7.935C6.31494 6.45 7.51494 5.25 8.99994 5.25C10.4849 5.25 11.6849 6.45 11.6849 7.935Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default UserSquare;
