export const hexToStringForTags = (code: any) => {
  const colors: any = {
    '#FFAE00': 'yellow',
    '#FBEECC': 'yellow',
    '#3EB53E': 'green',
    '#CCE7E1': 'green',
    '#CCE4F9': 'blue',
    '#155BDB': 'blue',
    '#C29D3B': 'mustard',
    '#F2BFC4': 'red',
    '#DB312B': 'red',
    '#56666D': 'gray',
    '#E1D3F8': 'purple',
    '#BB6BD9': 'purple',
    '#F8CCE6': 'pink',
    '#E8D5CC': 'brown',
    '#FDDFCC': 'orange',
    '#E7E6EB': 'gray100',
    '#F3F2F4': 'gray50',
  };
  return colors[code] ?? '';
};
export const mapTagBgClass = (code: any) => {
  const colors: any = {
    '#FFAE00': 'bg-tags-yellow',
    '#FBEECC': 'bg-tags-yellow',
    '#3EB53E': 'bg-tags-green',
    '#CCE7E1': 'bg-tags-green',
    '#CCE4F9': 'bg-tags-blue',
    '#155BDB': 'bg-tags-blue',
    '#C29D3B': 'bg-tags-mustard',
    '#F2BFC4': 'bg-tags-red',
    '#DB312B': 'bg-tags-red',
    '#56666D': 'bg-tags-gray',
    '#E1D3F8': 'bg-tags-purple',
    '#BB6BD9': 'bg-tags-purple',
    '#F8CCE6': 'bg-tags-pink',
    '#E8D5CC': 'bg-tags-brown',
    '#FDDFCC': 'bg-tags-orange',
    '#E7E6EB': 'bg-tags-gray100',
    '#F3F2F4': 'bg-tags-gray50',
  };
  return colors[code] ?? '';
};
