export default function Binvector() {
  return (
    <svg width='77' height='57' viewBox='0 0 77 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M77 54.887C77 56.057 59.7634 56.9998 38.5 56.9998C17.2366 56.9998 0 56.0527 0 54.887C0 53.717 17.2366 52.7741 38.5 52.7741C59.7634 52.7699 77 53.717 77 54.887Z'
        fill='#E5DFC6'
      />
      <path
        d='M63.0791 13.1442H27.4116V49.0756C27.4116 51.6213 29.4854 53.6828 32.0463 53.6828H58.5565C61.1174 53.6828 63.1912 51.6213 63.1912 49.0756V11.2071H63.0791V13.1442Z'
        fill='#74C1B9'
      />
      <path d='M64.5231 3.75434H25.855V7.44862H64.5231V3.75434Z' fill='#66AAA2' />
      <path d='M63.0789 11.2071H27.2993V13.1442H27.4114H63.0789V11.2071Z' fill='#66AAA2' />
      <path
        d='M64.8552 7.45271H64.5232H25.8551H25.5231C24.4798 7.45271 23.6348 8.29272 23.6348 9.32986C23.6348 10.367 24.4798 11.207 25.5231 11.207H27.2994H63.0789H63.191H64.8595C65.9028 11.207 66.7479 10.367 66.7479 9.32986C66.7479 8.29272 65.8985 7.45271 64.8552 7.45271Z'
        fill='#9AD7D2'
      />
      <path
        d='M64.8551 3.75429C65.8984 3.75429 66.7435 2.91429 66.7435 1.87714C66.7435 0.84 65.8984 0 64.8551 0H25.5187C24.4754 0 23.6304 0.84 23.6304 1.87714C23.6304 2.91429 24.4754 3.75429 25.5187 3.75429H25.8507H64.5188H64.8551Z'
        fill='#9AD7D2'
      />
      <path
        d='M33.1022 47.9401C32.0847 47.9401 31.2612 47.0487 31.2612 45.9515V17.6744C31.2612 16.5772 32.0847 15.6858 33.1022 15.6858C34.1196 15.6858 34.9431 16.5772 34.9431 17.6744V45.9515C34.9431 47.0487 34.1196 47.9401 33.1022 47.9401Z'
        fill='#66AAA2'
      />
      <path
        d='M41.3888 47.9401C40.3713 47.9401 39.5479 47.0487 39.5479 45.9515V17.6744C39.5479 16.5772 40.3713 15.6858 41.3888 15.6858C42.4062 15.6858 43.2297 16.5772 43.2297 17.6744V45.9515C43.234 47.0487 42.4062 47.9401 41.3888 47.9401Z'
        fill='#66AAA2'
      />
      <path
        d='M49.3043 47.9401C48.2868 47.9401 47.4634 47.0487 47.4634 45.9515V17.6744C47.4634 16.5772 48.2868 15.6858 49.3043 15.6858C50.3218 15.6858 51.1452 16.5772 51.1452 17.6744V45.9515C51.1452 47.0487 50.3218 47.9401 49.3043 47.9401Z'
        fill='#66AAA2'
      />
      <path
        d='M57.2198 47.9401C56.2024 47.9401 55.3789 47.0487 55.3789 45.9515V17.6744C55.3789 16.5772 56.2024 15.6858 57.2198 15.6858C58.2373 15.6858 59.0608 16.5772 59.0608 17.6744V45.9515C59.0608 47.0487 58.2373 47.9401 57.2198 47.9401Z'
        fill='#66AAA2'
      />
      <path
        d='M27.0879 15.6302C26.9974 15.433 24.7124 14.2202 22.962 13.3116C22.9275 13.2945 22.8887 13.2816 22.8542 13.2645C26.4326 16.153 25.9713 25.7916 21.5694 35.8373C17.0943 46.0545 10.1488 53.023 5.5874 52.1102C7.44989 53.023 9.78231 54.1245 10.0194 54.0516C14.8265 52.5859 20.6166 46.1273 24.4839 37.303C28.2692 28.6459 29.1272 20.1945 27.0879 15.6302Z'
        fill='#66AAA2'
      />
      <path
        d='M21.5694 35.8371C25.9669 25.7914 26.4325 16.1529 22.8541 13.2643C22.5825 13.0414 22.285 12.8571 21.966 12.72C17.4478 10.7657 9.94177 17.9443 5.20795 28.7571C0.46982 39.57 0.293056 49.92 4.81131 51.8743C5.06137 51.9814 5.32004 52.0586 5.58303 52.11C10.1487 53.0229 17.0942 46.0543 21.5694 35.8371Z'
        fill='#74C1B9'
      />
      <path
        d='M19.1987 34.0908C22.7888 25.8947 22.9226 18.0486 19.4976 16.5661C16.0725 15.0836 10.3855 20.5261 6.79542 28.7222C3.20529 36.9183 3.07149 44.7644 6.49656 46.2469C9.92164 47.7294 15.6086 42.287 19.1987 34.0908Z'
        fill='#9AD7D2'
      />
      <path
        d='M11.5975 36.407C11.4984 36.407 11.3992 36.3856 11.3043 36.347L9.67467 35.6527C9.22629 35.4598 8.88139 35.1084 8.70462 34.6541C8.52786 34.1998 8.54511 33.7113 8.74774 33.2698L12.0502 26.1127C12.4253 25.3027 13.3652 24.9427 14.1886 25.2941L16.0037 26.0656C16.3831 26.2284 16.5598 26.6656 16.396 27.0427C16.2322 27.4198 15.7924 27.5956 15.413 27.4327L13.598 26.6613C13.5247 26.6313 13.4428 26.6613 13.4083 26.7341L10.1058 33.8913C10.0584 33.9898 10.0799 34.0798 10.0972 34.1227C10.1144 34.1656 10.1575 34.247 10.261 34.2898L11.8907 34.9841C12.2701 35.147 12.4468 35.5841 12.283 35.9613C12.1623 36.2398 11.8864 36.407 11.5975 36.407Z'
        fill='#66AAA2'
      />
      <path
        d='M8.9631 43.4529C8.59664 43.4529 8.25174 43.3843 7.92839 43.2429C6.53152 42.6386 5.81585 40.8172 5.91069 38.1172C6.00123 35.5929 6.78158 32.5329 8.10946 29.5072C10.9635 22.9972 15.3482 18.8272 18.1031 20.0186C19.4999 20.6229 20.2156 22.4443 20.1208 25.1443C20.0302 27.6686 19.2499 30.7286 17.922 33.7543C16.5941 36.7843 14.8739 39.4329 13.0761 41.22C11.5973 42.69 10.1746 43.4529 8.9631 43.4529ZM17.077 20.9229C15.0765 20.9229 11.6016 24.3 9.12693 29.9529C7.8551 32.8586 7.10493 35.7729 7.0187 38.16C6.9411 40.3415 7.43259 41.8286 8.36814 42.2315C9.3037 42.6386 10.7351 41.9829 12.2871 40.44C13.9901 38.7515 15.6241 36.2186 16.8959 33.3129L17.4046 33.5315L16.8959 33.3129C18.1677 30.4072 18.9179 27.4929 19.0041 25.1058C19.0817 22.9243 18.5903 21.4372 17.6547 21.0343C17.4822 20.9572 17.2882 20.9229 17.077 20.9229Z'
        fill='#74C1B9'
      />
    </svg>
  );
}
