import { gql } from '@apollo/client';

export const UPDATED_CREDIT = gql`
  subscription updateCredit($workspace: ShortId!) {
    updateCredit(workspace: $workspace) {
      event
      message {
        total
      }
    }
  }
`;
