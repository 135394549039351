import { datadogLogs } from '@datadog/browser-logs';

export const isProduction = process.env.NODE_ENV === 'production';

export default function useReportErrorsToServices() {
  // for error page
  const reportErrorToServices = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here
    datadogLogs.logger.error(`App Error : ${error?.message}`, {
      dataDogError: error,
      addtionalInfo: info,
      context: 'app error',
    });
  };

  return {
    reportErrorToServices,
  };
}
