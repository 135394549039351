import { css } from 'styled-components';

export const antModal = css`
  .ant-modal-mask {
    background: rgba(13, 0, 46, 0.2);
  }
  .ant-modal-wrap {
    z-index: 9999;
  }
  .blurred-wrapper {
    background-color: rgba(100, 100, 100, 0.9);
  }
  @supports ((-webkit-backdrop-filter: blur(6px)) or (backdrop-filter: blur(6px))) {
    .blurred-wrapper {
      background: rgba(0, 0, 0, 0.2);
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    padding: 26px 24px;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: none;
    padding: 0px;
    margin-top: 24px;
    .ant-btn {
      width: fit-content;
      padding: 13px 25px;
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .ant-modal-close {
    right: 14px;
    .ant-modal-close-x {
      &:hover {
        svg {
          color: ${({ theme }) => theme.colors.text.t4};
        }
      }
      svg {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.text.t2};
      }
    }
  }
`;
