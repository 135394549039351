import { useState } from 'react';

const useNoteModal = () => {
  const [selectedNoteId, setSelectedNoteId] = useState<string>('');
  const [visibleNoteDeleteModal, setVisibleNoteDeleteModal] = useState(false);
  const closeNoteDeleteModal = () => {
    setVisibleNoteDeleteModal(false);
    setSelectedNoteId('');
  };
  const openNoteDeleteModal = (id: string) => () => {
    setVisibleNoteDeleteModal(true);
    setSelectedNoteId(id);
  };
  return {
    selectedNoteId,
    visibleNoteDeleteModal,
    openNoteDeleteModal,
    closeNoteDeleteModal,
  };
};

export default useNoteModal;
