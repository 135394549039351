import uuid from 'components/utils/uuid';

/**
 * useDeviceId is a hook that retrieves a deviceId from the browser's local storage or generates a new one using the uuid() function.
 *
 * @returns {Object} An object with two properties:
 * - deviceId: The retrieved or generated unique identifier.
 * - generateDeviceId: A function that will generate a new unique identifier and store it in local storage.
 * @author {Sundar Shahi Thakuri} <{shahithakurisundar@gmail.com}>
 */
export function useDeviceId() {
  let deviceId: any = '';

  try {
    deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      localStorage.setItem('deviceId', uuid());
    }
  } catch {
    if (!deviceId) {
      localStorage.setItem('deviceId', uuid());
    }
  }

  const generateDeviceId = () => {
    if (!deviceId) {
      localStorage.setItem('deviceId', uuid());
    }
  };

  return { deviceId, generateDeviceId };
}
