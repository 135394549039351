import styled from 'styled-components';

export const PhoneInputStyles = styled.div<any>`
  padding: 0px 20px;
  background: #fff;
  box-shadow: 0px 1px 0px #eaeaea;
  box-sizing: border-box;
  & * {
    font-family: 'Manrope', sans-serif;
  }
  .number-input-upper-text {
    font-family: Heebo, sans-serif;
  }
  .number-input {
    .ant-select-selector {
      padding: 4px;
      .ant-select-selection-search {
        left: 4px;
      }
      .ant-select-selection-search-input {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #3d3358;
      }
      .ant-select-selection-placeholder {
        font-weight: 500;
        font-size: 12.5px;
        line-height: 48px;
        color: #857f96;
      }
    }
  }
  .contact-options-dropdown {
    width: 322px !important;
    left: -1px !important;
    top: 60px !important;
    padding: 4px 0;
    border: 1px solid #e7e6eb;
    box-shadow: 0px 4px 16px rgb(35 30 36 / 10%);
    border-radius: 12px;
    .ant-select-item.ant-select-item-option {
      padding: 9px 8px 9px 20px;
      + .ant-select-item {
        box-shadow: inset 0px 0.5px 0px #cfccd5;
      }
    }
    .ant-select-item.ant-select-item-option-selected {
      background: transparent;
      color: #9e99ab;
    }
    .rc-virtual-list-holder {
      overflow-y: scroll !important;
      max-height: 415px !important;
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    }
  }
`;
export const FooterBtnWrapper = styled.div`
  display: flex;
  padding: 22px 30px 24px;
  row-gap: 4px;
  column-gap: 30px;
  justify-content: center;
  > .ant-btn {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    &:hover {
      border-color: inherit;
      background: #f9f9fa;
    }
  }
  > .ant-btn.make-call {
    background: #4c9610;
    &:focus,
    &:hover {
      background: #39810b;
    }
  }
  > .ant-btn.end-call {
    background: red;
  }
`;
